import { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BigNumber } from "ethers";
import { useQuery } from "react-query";

import { Context as MarketContext } from "src/contexts/Market";
import useApprove from "src/hooks/useApprove";
import useIsMobile from "src/hooks/useIsMobile";

import Close from "src/components/Close";

import ExternalModal from "src/components/ExternalModal";
import ShoppingContent from "src/components/ShoppingCart/ShoppingContent";

import { getQuickRuneAuction, RuneAuction } from "src/services/auctions";
import Loader from "src/components/Loader";

import { limit } from "src/config";
import { bn0 } from "src/utils/index";

import empty from "src/images/shopping/empty.svg";
import buyBtnPng from "src/images/buy_button.png";
import buyPng from "src/images/buy.png";
import di_3 from "src/images/di_3.png";
import type { ReferralUserInfo } from "src/services/referral";
import useTiny from "src/hooks/useTiny";
import CartSpiritInfo from "../ShoppingCart/CartSpiritInfo";
import QuickCartSpiritInfo from "./QuickCartSpiritInfo";
import useUserWallet from "src/hooks/useUserWallet";

export type txStatus = "warning" | "confirm";

interface Props {
  className?: string;
  onDismiss: () => void;
  handleBuy: (list: RuneAuction[], onDIsmiss: () => void) => void;
  account: string;
  inBuying: boolean;
  userInfo: ReferralUserInfo;
  spiritShoppingList: RuneAuction[];
  addToSpiritList: (detail: RuneAuction) => void;
  deleteFromSpiritList: (id: number) => void;
  clearSpiritList: () => void;
}

export default function SpiritShoppingCartModal({
  className,
  onDismiss,
  handleBuy,
  account,
  inBuying,
  spiritShoppingList,
  addToSpiritList,
  deleteFromSpiritList,
  clearSpiritList,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { busdBalance } = useUserWallet();
  const [visible, setVisible] = useState(false);

  const tiny = useTiny();
  const BUSD = tiny.externalTokens.BUSD;

  const [approveStatus, approve] = useApprove(
    BUSD,
    tiny.contracts.RuneMarket.address,
    busdBalance
  );

  // console.log(spiritShoppingList,
  //   addToSpiritList,
  //   deleteFromSpiritList,
  //   clearSpiritList,)

  const { data } = useQuery(
    ["spirit_quickbuy", account],
    async ({ signal }) => {
      return getQuickRuneAuction(account, { signal });
    },
    { refetchInterval: 5_000, enabled: typeof account === "string" }
  );

  const cards = useMemo(() => {
    const list = data?.list
      .filter((x) => x.seller !== account)
      .slice(0, limit.MAX_SHOPPINGCART_NUMBER);
    return list?.map((ele) => {
      const { id, referencePrice } = ele;
      const inShoppingList =
        spiritShoppingList.findIndex((ele) => ele.id === id) > -1;
      return (
        <QuickCartSpiritInfo
          key={id}
          id={id}
          price={referencePrice}
          info={ele}
          onDelete={() => deleteFromSpiritList(id)}
          onAdd={() => addToSpiritList(ele)}
          inShoppingList={inShoppingList}
          isFull={spiritShoppingList.length === limit.MAX_SHOPPINGCART_NUMBER}
          approve={approve}
          approveStatus={approveStatus}
        />
      );
    });
  }, [
    data,
    deleteFromSpiritList,
    addToSpiritList,
    spiritShoppingList,
    approve,
    approveStatus,
    account,
  ]);

  const sellList = useMemo(() => {
    return spiritShoppingList.map((ele) => {
      const { id, referencePrice } = ele;
      return (
        <CartSpiritInfo
          key={id}
          id={id}
          price={referencePrice}
          info={ele}
          onDelete={() => deleteFromSpiritList(id)}
        />
      );
    });
  }, [deleteFromSpiritList, spiritShoppingList]);

  const priceSum = useMemo(() => {
    const sum = spiritShoppingList.reduce(
      (pre, cur) => pre.add(BigNumber.from(cur.referencePrice)),
      BigNumber.from(0)
    );
    return sum || bn0;
  }, [spiritShoppingList]);

  return (
    <StyledPopoverContent className={className}>
      <StyledTitle className="rfs-11">
        <span>{t("quick buy")}</span>
      </StyledTitle>
      <StyledListContainer>
        {cards === undefined ? (
          <Loader size={isMobile ? 60 : 80} />
        ) : cards.length === 0 ? (
          <StyledEmpty>
            <img src={empty} alt="" />
            <span>{t("quickbuyEmptyTip")}</span>
          </StyledEmpty>
        ) : (
          cards
        )}
      </StyledListContainer>

      <StyledFooter>
        <StyledDesc>
          <StyledExclamation />
          <span>{t("quickbuywarn")}</span>
        </StyledDesc>

        <div className="text-center">
          <StyledShoppingCartButton
            className="shopping-cart-btn"
            onClick={() => setVisible(true)}
          >
            <img width="30" src={buyPng} alt="" />
          </StyledShoppingCartButton>
          <div>
            {spiritShoppingList.length}/{limit.MAX_SHOPPINGCART_NUMBER}
          </div>
        </div>
      </StyledFooter>

      <Close onClick={onDismiss} />

      {visible && (
        <ExternalModal
          onClose={() => setVisible(false)}
          style={{ zIndex: 103 }}
        >
          {({ handleClose }) => (
            <StyledShopContent
              shoppingType={1}
              onClose={handleClose}
              sellList={sellList || []}
              priceSum={priceSum}
              handleBuy={() => handleBuy(spiritShoppingList, onDismiss)}
              onClear={clearSpiritList}
              inBuying={inBuying}
              approveAddress={tiny.contracts.RuneMarket.address}
            />
          )}
        </ExternalModal>
      )}
    </StyledPopoverContent>
  );
}

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPopoverContent = styled.div`
  position: relative;
  padding: 15px;
  padding-bottom: 5px;
  background-image: url(${di_3}), linear-gradient(#fccd48, #f79905);
  border-radius: 1rem;
  box-shadow: 24px 24px 48px -24px ${(props) => props.theme.color.grey[900]};
`;

const StyledTitle = styled.div`
  text-align: center;
  font-weight: 600;
  position: relative;
`;

const StyledEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  color: #696969;
  font-weight: bold;

  > img {
    height: 100px;
    width: auto;
    margin-left: -1rem;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      height: 80px;
    `}
  }

  > span {
    justify-self: center;
    width: 40%;
    text-align: center;
    margin-top: 1rem;
  }
`;

const StyledListContainer = styled.div`
  margin: 10px 0;
  padding: 4px 8px;
  height: 32rem;
  background-color: #fff2d0;
  border-radius: 7px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fee397;
    border-radius: 5px;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 33.5rem;
  `}
`;

const StyledDesc = styled.ol`
  position: relative;
  margin-top: 0;
  padding-left: 18px;
  list-style: none;
  font-size: 0.75rem;
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1rem;
  `}
`;

const StyledExclamation = styled.div`
  position: absolute;
  font-weight: bold;
  width: 12px;
  height: 12px;
  left: 0px;
  top: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #514b45;
  &::before {
    content: "i";
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 3px;
  `}
`;

const StyledShopContent = styled(ShoppingContent)`
  margin: 0 1rem;
  width: 25rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 27rem;
  `}
`;

const StyledShoppingCartButton = styled.button`
  padding: 5px 15px 0;
  height: 40px;
  width: 66px;
  background-image: url(${buyBtnPng});

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5px 11px 0 9px;
    height: 34.5px;
    width: 57px;
  `}
`;
