import { PropsWithChildren } from "react";
import styled from "styled-components";
import BaseButton, { BaseProps } from "./BaseButton";

interface ButtonProps extends BaseProps {
  loading?: boolean;
  text?: string;
}

export default function Button(props: PropsWithChildren<ButtonProps>) {
  const { children, text, size, ...rest } = props;
  return (
    <StyledButton size={size} {...rest}>
      {children || text}
    </StyledButton>
  );
}

const StyledButton = styled(BaseButton)`
  position: relative;
  background-color: #514b45;
  border-radius: 20px;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  /* min-width: ${(props) => (props.size === "sm" ? 80 : 110)}px; */
  &:active {
    background-color: #514b45aa;
  }
  ::after {
    display: ${(props) => (props.disabled ? "block" : "none")};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #11111177;
    border-radius: 20px;
  }
`;
