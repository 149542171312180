import { createContext } from "react";
import { noFunc } from "src/utils";
import { IRangeConfig } from "./ReplenishModal";

interface IReplenishContext {
  rangeConfigs: IRangeConfig[];
  setRangeConfigs: React.Dispatch<React.SetStateAction<IRangeConfig[]>>;
}

export const ReplenishContext = createContext<IReplenishContext>({
  rangeConfigs: [],
  setRangeConfigs: noFunc,
});

// 体力恢复1点所需要的数量
// 例如: n卡恢复1点体力需要1瓶体力药水
export const propsArr = [100, 200, 300, 1000, 3000, 10000];
