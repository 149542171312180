import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";

import { shortenAddress } from "src/utils/index";
import config from "src/config";
import switchChain from "src/utils/switchChain";
import useModal from "src/hooks/useModal";
import WalletProviderModal from "src/components/WalletProviderModal";
import LogoutModal from "src/components/LogoutModal";
import useIsMobile from "src/hooks/useIsMobile";

import di3 from "src/images/di_3.png";

import Popover from "src/components/CommonPopover";
import WalletDetail from "src/components/LogoutModal/WalletDetail";
import DownIcon from "src/components/Svg/DownIcon";
import { ConnectorNames, connectorsByName, findConnectorId } from "src/wallet";
import useSidSdk from "src/hooks/useSidSdk";

function WalletButton() {
  const { chainId, account, connector, activate } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const { data: loginUser, refetch } = useSidSdk();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);
  // const activating = currentConnector === activatingConnector;
  // console.log('chainId', chainId);

  const isNetworkCorrect = config.chainId === chainId;

  // 体力恢复道具价格
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [showPersonCenter, setShowPersonCenter] = useState(false);

  const [showLogout] = useModal(
    <LogoutModal setShowPersonCenter={setShowPersonCenter} />,
    [setShowPersonCenter]
  );

  const changeNetwork = useCallback(() => {
    const connectorId = findConnectorId(connector);
    // console.log('switchChain connector:', connector);
    if (
      connectorId === ConnectorNames.Injected ||
      connectorId === ConnectorNames.WalletLink
    ) {
      switchChain(connectorId);
    } else {
      showLogout();
    }
  }, [connector, showLogout]);

  const [showWalletList] = useModal(<WalletProviderModal />);

  useEffect(() => {
    const connectorId = localStorage.getItem("connectorId");
    // console.log('localStorage connectorId', connectorId);

    let currentConnector = null;
    if (connectorId && connectorId in ConnectorNames) {
      const connector = connectorsByName[connectorId as ConnectorNames];
      currentConnector = connector;
    } else if (window.ethereum) {
      currentConnector = connectorsByName[ConnectorNames.Injected];
    }

    if (currentConnector) {
      setActivatingConnector(currentConnector);
      activate(currentConnector, (error) => {
        if (error) {
          setActivatingConnector(undefined);
        }
      });
    }
  }, [activate]);

  // const loginUser = useMemo(() => {
  //   const user = getPathName({
  //     address: account ? account : "0x9a5323072ca95c3951c93099127cc9821b5b5e64",
  //   });
  //   console.log({
  //     user,
  //   });
  //   return user;
  // }, [account, getPathName]);

  return (
    <>
      {!account ? (
        <NetworkError onClick={showWalletList}>
          {t("Connect")}
          {/* {status === "connecting" && "..."} */}
        </NetworkError>
      ) : isNetworkCorrect ? (
        isMobile ? (
          <>
            <StyledAccount
              onClick={() => {
                // setShowPersonCenter(true);
                showLogout();
              }}
            >
              {loginUser ? loginUser : shortenAddress(account, 2)}
              <DownIcon
                isRotate={true}
                //  isRotate={showPersonCenter}
                className="ml-4"
                style={{ height: 8 }}
              />
            </StyledAccount>
          </>
        ) : (
          <Popover
            visible={showPersonCenter}
            placement={"bottom"}
            style={{
              zIndex: 99,
            }}
            boxStyle={{
              minWidth: isMobile ? "18rem" : "17rem",
              marginTop: isMobile ? 0 : 3,
              background: `url(${di3}),rgb(255, 189, 74)`,
              border: 0,
              transform: "translate(-58%, 48px)",
            }}
            arrowClass="popover-arrow-no-border"
            arrowStyle={{
              position: "absolute",
              transform: "translate(-400%, 3px)",
              right: 0,
              left: "unset",
            }}
            triggerClose={() => {
              setShowPersonCenter(false);
            }}
            diy={isMobile ? "auto" : "auto"}
            handleClick={() => setShowPersonCenter(true)}
            content={<WalletDetail></WalletDetail>}
          >
            <StyledAccount>
              {loginUser ? loginUser : shortenAddress(account)}
              <DownIcon
                isRotate={true}
                className="ml-4"
                style={{ height: 8, marginTop: 2 }}
              />
            </StyledAccount>
          </Popover>
        )
      ) : (
        <NetworkError onClick={changeNetwork}>{t("NetworkError")}</NetworkError>
      )}
    </>
  );
}

export default WalletButton;

const StyledBorderButton = styled.button`
  box-sizing: border-box;
  padding: 4px 12px 4px 8px;
  height: 2.4rem;
  border-radius: 1.2rem;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 4px;
  `}
`;

const StyledAccount = styled(StyledBorderButton)`
  padding: 0.25rem 1rem;
  background-color: #f18f24;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  white-space: nowrap;

  display: flex;
  align-items: center;
`;

const NetworkError = styled(StyledAccount)`
  background-color: #fdd770;
  color: #514b45;
`;
