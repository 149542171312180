import clsx from "clsx";
import React, { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import SpecialFontText from "../SpecialFontText";

interface ModalTitleProps {
  className?: string;
  text?: ReactNode;
  titleSize?: "sm" | "lg";
  style?: CSSProperties;
}

const ModalTitle: React.FC<ModalTitleProps> = ({
  text,
  className,
  titleSize,
  style,
}) => {
  switch (titleSize) {
    case "lg":
      return (
        <StyledModalTitle
          style={style}
          className={clsx(className, "special-ff")}
        >
          {text}
        </StyledModalTitle>
      );
    case "sm":
      return (
        <StyledModalTitleSm
          style={style}
          className={clsx(className, "special-ff")}
        >
          {text}
        </StyledModalTitleSm>
      );
    default:
      return (
        <StyledModalTitle
          style={style}
          className={clsx(className, "special-ff")}
        >
          {text}
        </StyledModalTitle>
      );
  }
};

const StyledModalTitle = styled(SpecialFontText)`
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  display: flex;
  justify-content: center;
  height: 3.5rem;
  align-items: center;
  color: #514b45;
  font-size: 1.6rem;
  font-weight: 500;
`;

const StyledModalTitleSm = styled(StyledModalTitle)`
  font-size: 1.4rem;
`;

export default ModalTitle;
