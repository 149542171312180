import { useCallback, useState, useMemo, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import clsx from "clsx";

import useIsMobile from "src/hooks/useIsMobile";
import DownIcon from "src/components/Svg/DownIcon";
import useModal from "src/hooks/useModal";
import TinyToolModal from "src/components/TinyToolModal";

import tool from "src/images/tool.png";
import { useHistory } from "react-router";

interface OptionProps {
  keepActive: boolean;
  handleClick(): void;
}

export function Option({
  keepActive = true,
  handleClick,
  children,
}: PropsWithChildren<OptionProps>) {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={handleClick} isActive={keepActive}>
      {children}
    </StyledButton>
  );
}

interface DropDown {
  alignLeft?: boolean;
  className?: string;
}

export default function DropDown({
  className,
  alignLeft = false,
  children,
}: PropsWithChildren<DropDown>) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => setShow((pre) => !pre), []);
  const history = useHistory();
  const isMobile = useIsMobile();

  const [showToolModal, onToolModalDismiss] = useModal(<TinyToolModal />);

  return (
    <StyledContainer className={className}>
      <StyledOptionButton isShow={show} style={{ visibility: "hidden" }}>
        <img className="mr-5" src={tool} height={22} alt="Tiny Tool" />
        {t("Tiny Tool")}
        <DownIcon
          style={{ height: 8, marginLeft: 5 }}
          color="#FDCE4F"
          isRotate={true}
        />
      </StyledOptionButton>
      <StyledContent isShow={show} onClick={handleClick} alignLeft={alignLeft}>
        <StyledOptionButton isShow={show}>
          <img className="mr-5" src={tool} height={22} alt="Tiny Tool" />
          {t("Tiny Tool")}
          <DownIcon
            style={{ height: 8, marginLeft: 5 }}
            color="#FDCE4F"
            isRotate={true}
          />
        </StyledOptionButton>
        <Option
          keepActive={false}
          handleClick={() => {
            localStorage.getItem("ToolWarning") === "true"
              ? window.open("https://www.tinyworlds.tools")
              : showToolModal();
          }}
        >
          {t("Tiny Helper")}
        </Option>
        <Option
          keepActive={false}
          handleClick={() => {
            if (isMobile) {
              history.push("/mynfts/pets/mock");
            } else {
              history.push("/pets/mock");
            }
          }}
        >
          {t("Pets Mock")}
        </Option>
        <Option
          keepActive={false}
          handleClick={() => {
            history.push("/guide");
            // if (isMobile) {
            //   history.push("/mynfts/pets/mock");
            // } else {
            //   history.push("/pets/mock");
            // }
          }}
        >
          {t("Tiny Tutorial")}
        </Option>
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
  height: 36px;
  width: auto;
  z-index: 8;
  margin-left: 20px;
  margin-right: 20px;
`;

const StyledContent = styled.div<{
  isShow: boolean;
  alignLeft: boolean;
}>`
  position: absolute;
  top: ${({ alignLeft }) => (alignLeft ? "calc(-1 * calc(2.6rem + 15px))" : 0)};
  right: 0;
  width: max-content;
  height: auto;
  max-height: ${({ isShow }) => (isShow ? "120px" : "36px")};
  background-color: #514b45;
  color: #fdce4f;
  border-radius: 1.3rem;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
  transition: max-height 0.2s;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 0;
  `}
`;

const StyledBaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  color: #fdce4f;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
`;

const StyledOptionButton = styled(StyledBaseButton)<{ isShow: boolean }>`
  height: 36px;
  padding: 0px 12px;
  justify-content: space-around;
  ${({ isShow }) => isShow && `border-bottom: 1px solid #707070`};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0;
    padding: 2px 6px 5px;
  `}
`;

const StyledButton = styled(StyledBaseButton)<{ isActive: boolean }>`
  padding: 0px 10px;
  height: 28px;
  justify-content: center;
  word-break: keep-all;

  @media (any-hover: hover) {
    &:hover {
      background-color: #fdce4f;
      color: #514b45;

      > svg > g > path {
        fill: #fff2d0 !important;
      }
    }
  }

  &.active {
    background-color: #fdce4f;
    pointer-events: none;
    color: #514b45;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 2px 6px;
  `}
`;
