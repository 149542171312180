import {
  FC,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  CSSProperties,
} from "react";
import clsx from "clsx";
import styled from "styled-components";
import { useLocation } from "react-router";
import { CSSTransition } from "react-transition-group";

import useIsMobile from "src/hooks/useIsMobile";
import Drawer from "src/components/Drawer";

/**
 * @param {string} className
 * @param {number} ml
 * @param {boolean} mpt mobile padding-top
 * @returns
 */
const PageContent: FC<{
  className?: string;
  style?: CSSProperties;
  ml?: number;
  mpt?: boolean;
}> = ({ children, className, ml, mpt, style }) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const pageRef = useRef<HTMLDivElement>(null);
  const [animateIn, setIn] = useState(false);

  useEffect(() => {
    if (isMobile && pageRef.current) {
      pageRef.current.scrollTo(0, 0);
    }
  }, [location]);

  useLayoutEffect(() => {
    setIn(true);
  }, []);

  return (
    <>
      <CSSTransition in={animateIn} classNames="fade" timeout={500}>
        <div className={clsx(className, "page-container")}>
          <StyledContent style={style} ml={ml} mpt={mpt} ref={pageRef}>
            {children}
          </StyledContent>
          {isMobile && <Drawer />}
        </div>
      </CSSTransition>
    </>
  );
};

export default PageContent;

// const StyledContainer = styled.div`
//   position: relative;
//   overflow-y: auto;
//   overflow-x: hidden;
//   height: 100%;
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     height: calc(100% - 72px);
//     padding-bottom: 4.5rem;
//   `};
// `;

const StyledContent = styled.div<{
  ml?: number;
  mpt?: boolean;
}>`
  padding: 20px 20px 2rem;
  height: 100%;
  overflow-y: auto;
  overflow-y: auto;
  box-sizing: border-box;
  margin-left: ${(props) => (props.ml ? props.ml + "px" : null)};
  ${({ theme, mpt }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: ${mpt ? "3.6rem" : 0};
    height: 100%;
    // padding-bottom: 4.5rem;
    padding-bottom: 3rem;
    overflow-x: hidden;
  `};
`;
