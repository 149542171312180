import React, { CSSProperties, useMemo } from "react";
import PrefilledImage from "src/components/Images/PrefilledImage";
import PetsEmptyIcon from "src/components/Svg/PetsIcon/PetsEmptyIcon";
import styled from "styled-components";
import { PetDetail } from "../PetCard/type";
import egg from "src/images/pet/egg.png";
import genesisEgg from "src/images/pet/genesis-egg.png";
import { brindle, ear, eye, foot, ornament, tail } from "./const";
import PrefilledImageExtend from "src/components/Images/PrefilledImageExtend";
import { chainId } from "src/config";

interface Props {
  info: PetDetail;
  style?: CSSProperties & {
    width?: number;
    height?: number;
  };
  margin?: CSSProperties["margin"];
  boxStyle?: CSSProperties;
  isCracking?: boolean;
}

export const getPetStandImg = (id: number) => {
  return chainId === 56
    ? `https://assets.tinyworlds.io/pet-png/${id}.png`
    : `https://images.tinyworlds.io/pet-test-png/${id}.png`;
};

export const getPetStandTrimImg = (id: number) => {
  return chainId === 56
    ? `https://assets.tinyworlds.io/cdn-cgi/image/trim=65;60;50;60,width=100,height=100/pet-png/${id}.png`
    : `https://images.tinyworlds.io/cdn-cgi/image/trim=65;60;50;60,width=100,height=100/pet-test-png/${id}.png`;
};

function PetStand({ info, style, margin, boxStyle, isCracking }: Props) {
  const { id, genes, element, parts, femaleId, maleId } = info;
  const standElement = (element || 0) + 1;
  const eyeKey = parts?.part_1[0].key;
  const tailKey = parts?.part_2[0].key;
  const ornamentKey = parts?.part_3[0].key;
  const earKey = parts?.part_4[0].key;
  const footKey = parts?.part_5[0].key;
  const brindleKey = parts?.part_6[0].key;

  const petImgs = useMemo(() => {
    return typeof element === "number" &&
      typeof eyeKey === "number" &&
      typeof tailKey === "number" &&
      typeof ornamentKey === "number" &&
      typeof earKey === "number" &&
      typeof footKey === "number" &&
      typeof brindleKey === "number"
      ? [
          {
            img: `/images/petStand/9/${eye[eyeKey]}_0.png`,
            zIndex: 9,
          },
          {
            img: `/images/petStand/1/${tail[tailKey]}_${standElement}.png`,
            zIndex: 1,
          },
          {
            img: `/images/petStand/7/${ornament[ornamentKey]}_0.png`,
            zIndex: 7,
          },
          {
            img: `/images/petStand/5/${ear[earKey]}_${standElement}_1.png`,
            zIndex: 5,
          },
          {
            img: `/images/petStand/10/${ear[earKey]}_${standElement}_2.png`,
            zIndex: 10,
          },
          {
            img: `/images/petStand/4/${foot[footKey]}_0.png`,
            zIndex: 4,
          },
          {
            img: `/images/petStand/3/${brindle[brindleKey]}_0_1.png`,
            zIndex: 3,
          },
          {
            img: `/images/petStand/8/${brindle[brindleKey]}_0_2.png`,
            zIndex: 8,
          },
          {
            img: `/images/petStand/2/body_${standElement}.png`,
            zIndex: 2,
          },
          {
            img: `/images/petStand/6/head_${standElement}.png`,
            zIndex: 6,
          },
        ]
      : undefined;
  }, [
    brindleKey,
    earKey,
    element,
    eyeKey,
    footKey,
    ornamentKey,
    standElement,
    tailKey,
  ]);

  const petStand = useMemo(() => {
    return (
      <>
        {petImgs?.map(({ img, zIndex }, index) => {
          return (
            <StyledPetsStandImg key={index} zIndex={zIndex} src={img} alt="" />
          );
        })}
      </>
    );
  }, [petImgs]);

  return (
    <>
      {/* {petImgs && (
            <StyledPetStandImgs
              style={{
                ...style,
                ...boxStyle,
                margin,
              }}
              src={petImgs.map((ele) => ele.img)}
              isBreath
              preSrc={
                <PetsEmptyIcon
                  style={{
                    width: style?.width ? style.width * 0.6 : 128,
                  }}
                />
              }
            >
              <StyledPetsStandBox id={"NFT-PETS-" + id} style={style}>
                {petStand}
              </StyledPetsStandBox>
            </StyledPetStandImgs>
          )} */}
      {genes ? (
        petImgs && (
          <StyledPetsStandBox id={"NFT-PETS-" + id} style={style}>
            <StyledPetStandImgs
              style={{
                ...style,
                ...boxStyle,
                margin,
              }}
              src={getPetStandImg(id)}
              isBreath
              preSrc={
                <PetsEmptyIcon
                  style={{
                    width: style?.width ? style.width * 0.6 : 128,
                  }}
                />
              }
            />
          </StyledPetsStandBox>
        )
      ) : (
        <StyledNFTImg
          isBreath
          genes={genes}
          isCracking={isCracking}
          style={style}
          preSrc={
            <PetsEmptyIcon
              style={{
                width: style?.width ? style.width : 128,
              }}
            />
          }
          src={
            typeof femaleId === "number" && typeof maleId === "number"
              ? egg
              : genesisEgg
          }
        />
      )}
    </>
  );
}

export default PetStand;

// const StyledPetStandImgs = styled(PrefilledImageExtend)`
//   height: 200px;
//   width: 200px;
//   margin: -30px auto 0;
// `;

const StyledPetStandImgs = styled(PrefilledImage)`
  height: 200px;
  width: 200px;
  margin: -30px auto 0;

  span {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledPetsStandImg = styled.img<{ zIndex: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
  left: 0;
  top: 0;
`;

const StyledPetsStandBox = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
`;

// height: ${({ genes }) => (genes ?  : )}px;
//  width: ${({ genes }) => (genes ? 128 : 100)}px;

const StyledNFTImg = styled(PrefilledImage)<{
  genes: string | undefined;
  isCracking?: boolean;
}>`
  border-radius: 5px;
  object-fit: contain;
  width: 112px;
  height: 172px;
  justify-content: center;
  align-items: center;
  padding-right: ${({ genes }) => (genes ? 0.8 : 0)}rem;
  margin: 0 auto 1.2rem;
  display: flex;
  ${({ isCracking }) => {
    return isCracking ? "animation: eggRotate2 1.5s infinite linear;" : "";
  }}
  transform-origin: 50% 100%;
  span {
    position: absolute;
    left: 0;
  }

  ${({ genes }) =>
    genes
      ? `svg {
    display: block;
    margin: 0 auto;
  };`
      : `svg {
    position: absolute;
    left: 50%;
    /* right: 50%; */
    top: 50%;
    transform: translate(-50%, -55%);
    margin: 0 auto;
  }`}

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;
