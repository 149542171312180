import { CSSProperties } from "react";
import styled from "styled-components";
import footNote from "src/images/guide/foot_note.png";
import { NavLink } from "react-router-dom";
import PrefilledImage from "src/components/Images/PrefilledImage";

export const StyledPrefilledImage = styled(PrefilledImage)``;

export const StyledGuideLink = styled(NavLink)<{ family: boolean }>`
  color: #9d6c08 !important;
  text-decoration: underline;
  font-family: ${({ family }) => (family ? "unset" : "guideLink")};
  font-weight: ${({ family }) => (family ? "bold" : "unset")};
`;

export const StyledDetailFootNote = styled.div`
  font-size: 12px;
  font-family: Hiragino;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: #fff;
  white-space: nowrap;

  img {
    display: block;
    width: 60%;
    margin: 0 auto;
  }

  a {
    color: #514b45;
  }

  > span {
    margin-right: 8px;
  }
`;

export const imgBoxShadow: CSSProperties = {
  display: "flex",
  boxShadow: "rgb(142 108 73 / 40%) 0px 0px 10px 3px",
  borderRadius: "12px",
  overflow: "hidden",
};

export const StyledCreateMatamaskImgBox = styled.div`
  width: 100%;
  background: #e8d7a0;
  height: 88px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledGuideDetail = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  font-family: Hiragino;
  padding: 12px;
  padding-top: 10px;
  margin-top: 2px;
  box-sizing: border-box;
  max-height: 88%;
  overflow-y: auto;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  // justify-content: flex-start;
  max-height: none;
  padding: 0px;
  overflow-y: unset;
`}
`;

export const StyledDetailTitle = styled.div`
  font-size: 17px;
  font-family: Hiragino;
  height: 44px;
  display: flex;
  align-items: center;
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
// font-size: 14px;
`}
`;

export const StyledDetailItem = styled.div`
  font-size: 13px;
  font-family: Hiragino;
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  // flex-wrap: wrap;
  position: relative;
  color: #696969;
  padding-right: 10px;
  font-weight: Regular;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding-right: 0;
  margin-top: 4px;
`}

  img {
    display: block;
    width: 60%;
    margin: 0 auto;
  }

  a {
    color: #514b45;
  }

  // span {
  //   margin-right: 4px;
  // }

  > span {
    margin-right: 8px;
  }
`;

export const StyledDetailLink = styled.div`
  width: 100%;
  font-family: Hiragino;
  // font-size: 16px;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  // margin: 2.6rem auto;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
// font-size: 14px;
`}
`;

export const StyledDetailNote = styled.div`
  margin-top: 50px;
  font-size: 12px;
  font-family: Hiragino;
  line-height: 14px;
  color: #696969;
  width: 100%;
  border-top: 0.92px solid rgba(179, 141, 56, 0.2);
  padding: 16.4px 0 0;
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  gap: 4px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 12px;
    border-top: 0;
  `}
`;

export const StyledDetailStep = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 550;
  height: 26px;
  line-height: 26px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
  `}
`;

export const StyledDetailLogo = styled.img`
  width: 18%;
  // position: absolute;
  // top: -30%;
  // right: 12px;
`;

export const StyledDetailAccount = styled.span`
  width: 100%;
  font-size: 12px;
  background: #e8d7a0;
  padding: 4px 8px;
  border-radius: 4px;
  word-break: break-all;
  margin-top: 4px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  zoom: 0.9;
  `}
`;

export const StyledDetailHelpMBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  position: fixed;
  bottom: 10rem;
  left: 0rem;
  flex-wrap: wrap;
  padding-left: 8rem;
`;

export const StyledDetailHelpM = styled.a`
  height: 40px;
  box-sizing: border-box;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(0 0 0 / 50%);
  color: #fff;
  border-radius: 29px;
  padding: 4px;
  padding-right: 14px;
  width: auto;
  min-width: 9.4rem;
`;

export const StyledDetailHelp = styled.a`
  position: absolute;
  bottom: -4.5%;
  right: 12%;
  color: #fff;
  background-image: url(${footNote});
  background-size: 100%;
  background-repeat: no-repeat;
  height: 13%;
  width: 14%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-top: 4px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    bottom: -20px;
    right: 0;
    height: 54px;
    width: 158px;
    transform: translate(-58%,0);
  `}
`;

export const StyledGameDirect = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  .tiny-kingdom {
    filter: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.33));
  }

  :active {
    .tiny-kingdom {
      transform: translateY(4px);
      filter: none;
    }
  }
`;

export const StyledPointerImg = styled.img`
  position: absolute;
  right: -10px;
  bottom: -10%;
  width: 30%;
`;

export const StyledGameButton = styled.img`
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: -1;
`;

export const StyledPlayGameBox = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      zoom:0.9;
  `}
`;

export const StyledDetailRightImgBox = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: self-start;
  margin-top: 4px;
  img {
    height: 140px;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    zoom:0.9;
    flex-wrap: wrap;

    img{
      height:156px;
    }
  `}
`;
