// access token key
export const ATKey = "ACCESS_TOKEN_";

interface SignInfo {
  signedMessage: string;
  timestamp: number;
  message: string;
}

const cache: {
  [key: string]: SignInfo | null;
} = {};

export function saveAT(account: string, t: string, m: string) {
  const info = {
    signedMessage: t,
    message: m,
    timestamp: new Date().getTime(),
  };
  cache[ATKey + account] = info;
  return localStorage.setItem(ATKey + account, JSON.stringify(info));
}

export function deleteAT(account: string) {
  cache[ATKey + account] = null;
  return localStorage.removeItem(ATKey + account);
}

export function getAT(account: string, m: string): string | null {
  const cached = cache[ATKey + account];
  if (cached) return cached.signedMessage;
  const infoStr = localStorage.getItem(ATKey + account);
  if (infoStr) {
    try {
      const info: SignInfo = JSON.parse(infoStr);
      if (Date.now() - info.timestamp >= 12 * 3600 * 1000) {
        localStorage.removeItem(ATKey + account);
        return null;
      }
      if (info.message !== m) {
        localStorage.removeItem(ATKey + account);
        return null;
      }
      return info.signedMessage;
    } catch (error) {
      localStorage.removeItem(ATKey + account);
      return null;
    }
  }
  localStorage.removeItem(ATKey + account);
  return null;
}

export interface LocalStorageUserParams {
  user: string;
  key: string;
  value?: string;
}

export const setLocalStorageByUser = ({
  user,
  key,
  value,
}: LocalStorageUserParams) => {
  if (value) {
    const data =
      localStorage.getItem(user) !== null
        ? JSON.parse(localStorage.getItem(user) || "")
        : "";
    let save: any = {};
    if (data) {
      save = data;
      save[key] = value;
      localStorage.setItem(user, JSON.stringify(save));
    } else {
      save[key] = value;
      localStorage.setItem(user, JSON.stringify(save));
    }
  } else {
    console.error("LocalStorage Value Error");
  }
};

export const getLocalStorageByUser = ({
  user,
  key,
}: LocalStorageUserParams) => {
  const data =
    localStorage.getItem(user) !== null
      ? JSON.parse(localStorage.getItem(user) || "")
      : "";
  return data ? data[key] : undefined;
};

export const setSessionStorageByUser = ({
  user,
  key,
  value,
}: LocalStorageUserParams) => {
  try {
    if (value) {
      const data =
        sessionStorage.getItem(user) !== null
          ? JSON.parse(localStorage.getItem(user) || "")
          : "";

      let save: any = {};

      if (data) {
        save = data;
        save[key] = value;
        sessionStorage.setItem(user, JSON.stringify(save));
      } else {
        save[key] = value;
        sessionStorage.setItem(user, JSON.stringify(save));
      }
    } else {
      console.error("LocalStorage Value Error");
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSessionStorageByUser = ({
  user,
  key,
}: LocalStorageUserParams) => {
  const data =
    sessionStorage.getItem(user) !== null
      ? JSON.parse(sessionStorage.getItem(user) || "")
      : "";
  return data ? data[key] : undefined;
};
