import styled from "styled-components";

import hashkey from "src/images/investor/hashkey.png";
import snz from "src/images/investor/snz.png";
import sky9 from "src/images/investor/sky9.png";
import everest from "src/images/investor/everest.png";
import TokenPocket from "src/images/logos/TokenPocket.png";
import cipholio from "src/images/investor/cipholio.png";
import foresight from "src/images/investor/foresight.png";
import cocos from "src/images/investor/cocos.png";
import wyng from "src/images/investor/wyng.png";
import bns from "src/images/investor/bns.png";
import zerox from "src/images/investor/zerox.png";

import { useTranslation } from "react-i18next";
import LoadingSkeleton from "src/components/LoadingSkeleton";
import useIsMobile from "src/hooks/useIsMobile";

interface Investor {
  src: string;
  height: number;
  mobileHeight: number;
  right?: number;
}

export default function Investors() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const imgPaths: Investor[] = [
    {
      src: hashkey,
      height: 76,
      mobileHeight: 45,
    },
    {
      src: snz,
      height: 80,
      mobileHeight: 45,
    },
    {
      src: sky9,
      height: 76,
      mobileHeight: 50,
    },
    {
      src: everest,
      height: 70,
      mobileHeight: 60,
    },
    {
      src: TokenPocket,
      height: 80,
      right: 12,
      mobileHeight: 52,
    },
    {
      src: cipholio,
      height: 90,
      mobileHeight: 53,
    },
    {
      src: foresight,
      height: 90,
      mobileHeight: 68,
    },
    /* {
      src: cocos,
      height: 68,
      mobileHeight: 53,
    }, */
    {
      src: wyng,
      height: 86,
      mobileHeight: 60,
    },
    {
      src: bns,
      height: 70,
      mobileHeight: 55,
    },
    {
      src: zerox,
      height: 100,
      mobileHeight: 70,
    },
  ];

  return (
    <StyledContent>
      <span className="rfs-18 mb-10 fw-600">{t("Investors")}</span>
      <StyledDivider />
      <StyledInvestorContainer>
        {imgPaths.map(({ src, height, right, mobileHeight }, index) => (
          <StyledItem key={index}>
            <LoadingSkeleton
              style={{ width: "90%", height: 75, margin: "0 auto" }}
              src={src}
            >
              <StyledAnchor>
                <img
                  style={{ maxHeight: isMobile ? mobileHeight : height }}
                  src={src}
                />
              </StyledAnchor>
            </LoadingSkeleton>
          </StyledItem>
        ))}
      </StyledInvestorContainer>
    </StyledContent>
  );
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 15px;
    margin: 30px auto;
  `}
`;

const StyledInvestorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 2.6rem 0;
  box-sizing: border-box;

  > div {
    width: 20%;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`

    > div {
      width: 25%;
    }
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    > div {
      width: 33.3%;
    }
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    > div {
      width: 40%;
    }

    img {
      // height: 50px;
    }
    justify-content: center;
  `}
`;

const StyledDivider = styled.div`
  width: 100%;
  border-top: 1px solid #707070;
  margin-bottom: 30px;
`;

const StyledItem = styled.div`
  grid-column: span 1 / span 1;
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
`;

const StyledAnchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  border-radius: 4px;
`;
