import React, { useCallback, useContext, useEffect } from "react";
import { X } from "react-feather";
import styled, { ThemeContext } from "styled-components";
import { useSpring, animated } from "react-spring";
import { PopupContent } from "../../state/application/actions";
import { useRemovePopup } from "../../state/application/hooks";
import TransactionPopup from "./TransactionPopup";
import ErrorPopup from "./ErrorPopup";

export const StyledClose = styled(X)`
  color: #A8A8A8;
  :hover {
    cursor: pointer;
  }
`;
export const Popup = styled.div`
  /* display: inline-block; */
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em;
  background-color: ${({ theme }) => theme.color.grey[700]};
  position: relative;
  border-radius: 10px;
  padding: 20px;
  padding-right: 35px;
  overflow: hidden;

  @media (max-width: 768px) {
    min-width: 290px;
    min-height: 40px;
  }
`;
const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.grey[400]};
`;

const AnimatedFader = animated(Fader);

export default function PopupItem({
  removeAfterMs,
  content,
  popKey,
}: {
  removeAfterMs: number | null;
  content: PopupContent;
  popKey: string;
}) {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(() => removePopup(popKey), [
    popKey,
    removePopup,
  ]);
  useEffect(() => {
    if (removeAfterMs === null) return undefined;

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, removeAfterMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [removeAfterMs, removeThisPopup]);

  const theme = useContext(ThemeContext);

  let popupContent;
  if (content.txn) {
    const { hash, success, summary } = content.txn;
    popupContent = (
      <TransactionPopup hash={hash} success={success} summary={summary} />
    );
  }
  if (content.error) {
    const { message, stack } = content.error;
    popupContent = <ErrorPopup message={message} stack={stack} />;
  }

  const faderStyle = useSpring({
    from: { width: "100%" },
    to: { width: "0%" },
    config: { duration: removeAfterMs ?? undefined },
  });

  return (
    <Popup>
      
      {popupContent}
      {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
      <StyledCloseContainer>
        <StyledClose className="popup-close" onClick={removeThisPopup} />
      </StyledCloseContainer>
    </Popup>
  );
}

const Divider = styled.div`
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 100%;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(255, 255, 255);
`

const StyledCloseContainer = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid;
  border-image: linear-gradient(to bottom, transparent 16%, #707070 16% 82%, transparent 82%) 5;

  :hover {
    background: #FDCE4F;
    border: none;

    .popup-close {
      color: #514B45 !important;
    }
  }
`