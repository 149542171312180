import { CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// import Button from "src/components/Button/BgButton";
import back from "src/images/back.png";

interface Props {
  handleBack?(): void;
  style?: CSSProperties;
}

const isMobile = window.innerWidth < 500;

export default function BackButton({ handleBack, style }: Props) {
  const history = useHistory();

  return (
    <StyledBack
      style={{ ...style }}
      onClick={() => {
        if (handleBack) {
          return handleBack();
        }
        // console.log({
        //   history,
        //   state: history.location,
        // });
        if (history.location.key || !!history.location.hash) {
          history.goBack();
        } else {
          if (
            history.action === "POP" &&
            history.location.pathname !== "/home"
          ) {
            history.replace("/home");
          }
        }

        return;
      }}
    >
      <img src={back} alt="" style={{ height: isMobile ? 25 : 37 }} />
    </StyledBack>
  );
}

const StyledBack = styled.div`
  display: inline-block;
  cursor: pointer;
  padding-right: 0.75rem;
  margin-right: 0.25rem;
  img {
    display: block;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-right: 4px;
    margin-right: 4px;
  `}
`;
