import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  // useContext,
  // useEffect,
  // useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import useIsMobile from "src/hooks/useIsMobile";
// import { Context } from "src/contexts/TinyProvider";
import Modal, { ModalProps } from "src/components/Modal";
import TButton from "src/components/Button/ParallelogramButton";
import { IHeroHead } from "src/components/HeadHeroCard/type";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { useLocation } from "react-router";
import StaminaCheckHero from "./StaminaCheckHero";
import useIsMobile from "src/hooks/useIsMobile";
import { HeadHeroCard } from "src/components/HeadHeroCard";
import { CheckedHeroButton } from "src/components/HeadHeroCard/HeadHeroCard";
import { HeadHeroAdd, HeadHeroPre } from "src/components/Svg/HeroPre";
import Close from "src/components/Close";

import { Pagination, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

interface ICheckHeroModelProps extends ModalProps {
  checkHero: IHeroHead[];
  setCheckHero: React.Dispatch<React.SetStateAction<IHeroHead[]>>;
  showReplenishModal: () => void;
  showRaisePowerModal: () => void;
}

const CheckHeroModal: FC<ICheckHeroModelProps> = ({
  checkHero,
  setCheckHero,
  showReplenishModal,
  showRaisePowerModal,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const windowHeight = window.innerHeight;
  const isMobile = useIsMobile();
  const [tabIndex, setTabIndex] = useState(0);

  const setHero = useCallback(
    (hero: IHeroHead) => {
      setCheckHero((pre) => {
        const checkHeroRe: IHeroHead[] = [...pre];
        if (pre.findIndex((check) => check?.id === hero.id) > -1) {
          return checkHeroRe.filter((ele) => {
            return ele.id !== hero.id;
          });
        }
        if (pre.length < 5) {
          checkHeroRe.push(hero);
          return checkHeroRe;
        }
        // 没有变化
        return pre;
      });
    },
    [setCheckHero]
  );

  const renderList: (IHeroHead | undefined)[] = useMemo(() => {
    return checkHero.length < 5
      ? checkHero.concat(Array.from({ length: 5 - checkHero.length }))
      : checkHero;
  }, [checkHero]);

  return (
    <Modal
      isNotBg
      style={
        isMobile
          ? {
              maxWidth: 430,
              // zoom: windowHeight < 680 ? 0.9 * (windowHeight / 680) : 0.9,
            }
          : {
              maxWidth: 1100,
            }
      }
      onDismiss={onDismiss}
    >
      <StyledTabs
        onSelect={(index) => {
          setCheckHero([]);
          setTabIndex(index);
        }}
        defaultIndex={tabIndex}
      >
        <div className={isMobile ? "display-flex flex-center" : ""}>
          <TabList
            style={{
              padding: 4,
            }}
          >
            <Tab>{t("Replenish Energy")}</Tab>
            <div className="mr-5"></div>
            <Tab>{t("raise power")}</Tab>
          </TabList>
        </div>
        <StyledSignContainer className="position-relative">
          {onDismiss && <Close onClick={onDismiss} />}
          <StyledSelectTabPanel>
            <StaminaCheckHero
              tabIndex={tabIndex}
              checkHero={checkHero}
              setHero={setHero}
            />
          </StyledSelectTabPanel>
          <StyledSelectTabPanel>
            <StaminaCheckHero
              tabIndex={tabIndex}
              checkHero={checkHero}
              setHero={setHero}
            />
          </StyledSelectTabPanel>
          <StyledContainerHeroBottom>
            <StyledSelectHeroBox>
              {isMobile ? (
                <StyledSwiper
                  slidesPerView={3}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  scrollbar={{
                    hide: true,
                  }}
                  loopFillGroupWithBlank={true}
                  // loop={true}
                  // navigation={true}
                  // Navigation
                  // Navigation
                  modules={[Pagination, Scrollbar]}
                >
                  {renderList.map((ele, index) => {
                    return (
                      <SwiperSlide>
                        <StyledSelectHeroBoxItem key={index}>
                          <CheckedHeroButton
                            index={0}
                            height={100}
                            boxStyle={{
                              // transform: "scale(1.7)",
                              alignItems: "flex-start",
                            }}
                          >
                            {ele ? (
                              <StyledLordHeroCard
                                onClick={setHero}
                                boxStyle={{
                                  // transform: "scale(1.7)",
                                  alignItems: "flex-start",
                                }}
                                height={92}
                                powerStyle={{
                                  marginTop: "0.5vh",
                                }}
                                {...ele}
                                showStamina
                                isCheckHero
                                isNotTinyLord
                                notClickItem={"id"}
                              ></StyledLordHeroCard>
                            ) : (
                              <>
                                <HeadHeroPre
                                  style={{
                                    height: "48%",
                                  }}
                                  color={"#A28847"}
                                ></HeadHeroPre>
                                <HeadHeroAdd
                                  style={{
                                    marginTop: 6,
                                  }}
                                ></HeadHeroAdd>
                              </>
                            )}
                          </CheckedHeroButton>
                        </StyledSelectHeroBoxItem>
                      </SwiperSlide>
                    );
                  })}
                </StyledSwiper>
              ) : (
                renderList.map((ele, index) => {
                  return (
                    <StyledSelectHeroBoxItem key={index}>
                      <CheckedHeroButton
                        index={0}
                        height={128}
                        boxStyle={{
                          // transform: "scale(1.7)",
                          alignItems: "flex-start",
                        }}
                      >
                        {ele ? (
                          <StyledLordHeroCard
                            onClick={setHero}
                            boxStyle={{
                              // transform: "scale(1.7)",
                              alignItems: "flex-start",
                            }}
                            height={120}
                            powerStyle={{
                              marginTop: "0.5vh",
                            }}
                            {...ele}
                            showStamina
                            isCheckHero
                            isNotTinyLord
                            notClickItem={"id"}
                          ></StyledLordHeroCard>
                        ) : (
                          <>
                            <HeadHeroPre color={"#A28847"}></HeadHeroPre>
                            <HeadHeroAdd
                              style={{
                                marginTop: 6,
                              }}
                            ></HeadHeroAdd>
                          </>
                        )}
                      </CheckedHeroButton>
                    </StyledSelectHeroBoxItem>
                  );
                })
              )}
            </StyledSelectHeroBox>

            <StyledButtonBox>
              <StyledButtonBoxDesc>
                {`${t("Select Hero")} ${checkHero.length}/5`}
              </StyledButtonBoxDesc>
              <TButton
                className="mt-10"
                style={{
                  width: "100%",
                }}
                disabled={checkHero.length === 0}
                onClick={() => {
                  // changeCheckedHeroList && changeCheckedHeroList(checkHeroM);
                  switch (tabIndex) {
                    case 0:
                      showReplenishModal();
                      break;
                    case 1:
                      showRaisePowerModal();
                      break;
                    default:
                      break;
                  }
                }}
              >
                {t("Confirm")}
              </TButton>
            </StyledButtonBox>
          </StyledContainerHeroBottom>
        </StyledSignContainer>
      </StyledTabs>
    </Modal>
  );
};

export default memo(CheckHeroModal);

const StyledSwiper = styled(Swiper)`
  width: 284px;
  height: 118px;

  --swiper-theme-color: #fccf4c;
  --swiper-pagination-bullet-inactive-color: #905419;

  --swiper-pagination-bullet-width: 11px;
  --swiper-pagination-bullet-height: 11px;

  .swiper-pagination-bullet {
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    border: 3px #905419 solid;
    box-sizing: border-box;
  }

  .swiper-pagination {
    // display:none;
    bottom: -2px;
  }

  .swiper-wrapper {
    .swiper-slide {
      // margin-right: 10px !important;
    }
  }
`;

const StyledButtonBoxDesc = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledTabs = styled(Tabs)`
  margin: 0 auto 2rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // padding: 0 10px;
  `}
`;

const StyledSelectTabPanel = styled(TabPanel)`
  width: 100%;
`;

const StyledSelectHeroBoxItem = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 74px;
  `}
`;

const StyledSelectHeroBox = styled.div`
  width: 60%;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
    height: 128px;
    width: 100%;
  `}
`;

const StyledLordHeroCard = styled(HeadHeroCard)`
  cursor: pointer;
`;

const StyledContainerHeroBottom = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  min-height: 14vh;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 1vh 0vw 0;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `}
`;

const StyledSignContainer = styled.div`
  display: flex;
  color: #fff;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const StyledButtonBox = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 40%;
    margin: 0px auto 0;
  `}
`;
