/**
 * 是之前不用的和游戏相关的请求，不知道以后还会不会有
 */

import { request } from "./util";
import {
  game_api_base,
  game_login_api_base,
  game_url,
  game_register_api_base,
  game_users_api_base,
  backend_url,
} from "../constants";
import config from "src/config";
import {
  getLoginToken,
  refreshLoginToken,
} from "src/views/Game/Dashboard/components/GameLogin/const";

export interface ChargeRecord {
  address?: string;
  amount: string;
  tokenSymbol?: "TINC" | "BUSD";
  tx: string;
  timestamp: number;
}

export function queryGameCharge(
  {
    address,
    token,
    signedMessage,
  }: {
    address: string;
    token: "BUSD" | "TINC";
    signedMessage: string;
  },
  options?: RequestInit
) {
  const params = new URLSearchParams({
    address: address,
    token: token,
    signedMessage: signedMessage,
  });
  return request<{
    list: ChargeRecord[];
    total_count: number;
  }>(game_api_base + "/charges?" + params.toString(), options);
}

export function queryGameChargeByAccessToken(
  {
    token,
  }: {
    token: "BUSD" | "TINC" | "SPIRIT";
  },
  options?: RequestInit
) {
  const params = new URLSearchParams({
    token: token,
  });
  return request<{
    list: ChargeRecord[];
    total_count: number;
  }>(game_api_base + "/charges2?" + params.toString(), options);
}

export interface TitleInfo {
  cityCapability?: number;
  maxHeroLevel?: number;
  veTINC?: number;
  rankId?: number;
}

export interface BalanceInfo {
  busdBalance?: number;
  tincBalance?: number;
  spiritBalance?: number;
}

interface InviteReward {
  busdAmount: number;
  tincAmount: number;
}

export interface GameInfo {
  titleInfo: TitleInfo;
  balanceInfo: BalanceInfo;
  inviteReward: InviteReward;
}

export interface TwitterTokenInfo {
  oauth_callback_confirmed: boolean;
  oauth_token: string;
}

export interface GameBannerInfo {
  totalCount: number;
  totalMoney: number;
}

export function getGameInfos(
  {
    address,
    signedMessage,
  }: {
    address: string;
    signedMessage: string;
  },
  options?: RequestInit
) {
  const params = new URLSearchParams({
    address: address,
    signedMessage: signedMessage,
  });
  // return fetch(
  //   game_api_base + "/infos?" + params.toString(),
  //   options
  // ).then(res => res.json())
  return request<GameInfo>(
    game_api_base + "/infos?" + params.toString(),
    options
  );
}

export function getGameBannerInfo(options?: RequestInit) {
  return request<GameBannerInfo>(game_api_base + "/transaction", options);
}

export interface IGameUserInfo {
  displayName: string;
  language: string;
  email: string;
  walletAdress: string;
  registerMethod: "Email" | "Facebook" | "Google" | "Twitter" | "Wallet"; //注册方式
  state: number;
  transactionAddress: string;
  passwordUpdatedUtc: string;
  stateEndTime: string;
}

export interface IToken {
  accessToken?: string;
  refreshToken?: string;
  refresh_token?: string;
  adress?: string;
}

export interface ITinyKingdomInfo {
  spiritAmount: number;
  playerId: number;
  crystalAmount: number;
  busdAmount: number;
  tkcAmount: number;
  tkcPledge: number;
  rankId: number;
  cityCapability: number;
  rankLevel: number;
  inviteRewardTincAmount: number;
  inviteRewardBusdAmount: number;
}

// IToken | IGameUserInfo | string
export interface GameLoginBaseResult<T> extends Response {
  errorCode?: string;
  errorMessage?: string;
  isOk?: boolean;
  data?: T;
}

// facebook第三方登录
export function facebookLoginAuth(
  {
    accessToken,
  }: {
    accessToken: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<IToken>> {
  const body = {
    accessToken: accessToken,
  };
  return fetch(game_url + game_login_api_base + "/facebook", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// 谷歌第三方登录
export function googleLoginAuth(
  {
    accessToken,
  }: {
    accessToken: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<IToken>> {
  const body = {
    accessToken: accessToken,
  };
  return fetch(game_url + game_login_api_base + "/google", {
    ...options,
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// 推特第三方登录
export function twitterLogin(
  {
    accessToken,
    tokenSecret,
  }: {
    accessToken: string;
    tokenSecret: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<IToken>> {
  const body = {
    accessToken: accessToken,
    tokenSecret: tokenSecret,
  };
  return fetch(game_url + game_login_api_base + "/twitter", {
    ...options,
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// /api/login/

// 刷新登录token
export function refreshTokenLogin(
  {
    refreshToken,
  }: {
    refreshToken: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<IToken>> {
  const body = {
    refreshToken: refreshToken,
  };
  return fetch(game_url + game_login_api_base + "/refresh_token", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// 获取 twitter 请求token
export async function getTwitterUser(
  options?: RequestInit
): Promise<TwitterTokenInfo> {
  return fetch(`${backend_url + game_api_base}/twitter`, {
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// 获取 twitter 用户信息
export async function getTwitterUserToken(
  {
    oauth_token,
    oauth_verifier,
  }: {
    oauth_token: string | null;
    oauth_verifier: string | null;
  },
  options?: RequestInit
): Promise<any> {
  if (oauth_token && oauth_verifier) {
    return fetch(
      `${
        backend_url + game_api_base
      }/twitterUser?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`,
      {
        ...options,
      }
    ).then((res) => {
      if (res.status === 404) {
        return null;
      }
      return res.json();
    });
  } else {
    return undefined;
  }
}

// 使用邮箱登录 over
export function loginEmail(
  {
    email,
    pwd,
    captcha = "",
    captchaKey = "",
  }: {
    email: string;
    pwd: string;
    captcha?: string;
    captchaKey?: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<IToken>> {
  const body = {
    email,
    pwd,
    captcha,
    captchaKey,
  };
  return fetch(game_url + game_login_api_base + "/email", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// /api/login/wallet 通过钱包授权  over
export function loginWallet(
  {
    address,
    sign,
  }: {
    address: string;
    sign: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<IToken>> {
  const body = {
    address,
    sign,
  };
  return fetch(game_url + game_login_api_base + "/wallet", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// /api/Register/Email   over
// 通过邮箱注册
export function registerEmail(
  {
    email,
    verificationCode,
    pwd,
    pwD2,
  }: {
    email: string;
    verificationCode: string;
    pwd: string;
    pwD2: string;
  },
  options?: RequestInit
): Promise<any> {
  const body = {
    email,
    verificationCode,
    pwd,
    pwD2,
  };
  return fetch(game_url + game_register_api_base + "/Email", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// /api/Register/SendEmailForRegister      over
// 发送邮箱注册验证码
export function registerSendEmailForRegister(
  {
    email,
  }: {
    email: string;
  },
  options?: RequestInit
): Promise<any> {
  const body = {
    email,
  };
  return fetch(game_url + game_register_api_base + "/SendEmailForRegister", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// /api/users/retrive-pwd-mail  over
// 发送找回密码的邮件
export function usersRetrivePwdEmail(
  {
    email,
  }: {
    email: string;
  },
  options?: RequestInit
): Promise<any> {
  const body = {
    email,
  };
  return fetch(game_url + game_users_api_base + "/retrive-pwd-mail", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/retrive-pwd   over
找回密码
*/
export function usersRetrivePwd(
  {
    token,
    password,
    email,
  }: {
    token: string;
    password: string;
    email: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    token,
    password,
    email,
  };
  return fetch(game_url + game_users_api_base + "/retrive-pwd", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/change-pwd   over
修改密码（通过旧密码）

  "oldPwd": "string",
  "newPwd": "string",
  "newPwd2": "string"
*/
export function usersChangePwd(
  {
    oldPwd,
    newPwd,
    newPwd2,
  }: {
    oldPwd: string;
    newPwd: string;
    newPwd2: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    oldPwd,
    newPwd,
    newPwd2,
  };
  const accessToken = getLoginToken().accessToken;
  return fetch(game_url + game_users_api_base + "/change-pwd", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/change-email 
改綁邮箱

  "email": "user@example.com",
  "verificationCode": "string",
  "pwd": "string",
  "pwd2": "string"
*/
export function usersChangeEmail(
  {
    email,
    verificationCode,
    pwd,
    pwd2,
  }: {
    email: string;
    verificationCode: string;
    pwd: string;
    pwd2: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    email,
    verificationCode,
    pwd,
    pwd2,
  };
  const accessToken = getLoginToken().accessToken;
  return fetch(game_url + game_users_api_base + "/change-email", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/change-email-code   over
改綁邮箱验证码发送

"email": "user@example.com"
*/
export function usersChangeEmailCode(
  {
    email,
  }: {
    email: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    email,
  };
  const accessToken = getLoginToken().accessToken;
  return fetch(game_url + game_users_api_base + "/change-email-code", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/bind-email  over
绑定邮箱

  "email": "user@example.com",
  "verificationCode": "string",
  "pwd": "string",
  "pwd2": "string"
*/
export function usersBindEmail(
  {
    email,
    verificationCode,
    pwd,
    pwd2,
  }: {
    email: string;
    verificationCode: string;
    pwd: string;
    pwd2: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    email,
    verificationCode,
    pwd,
    pwd2,
  };
  const accessToken = getLoginToken().accessToken;
  return fetch(game_url + game_users_api_base + "/bind-email", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/bind-email-code   over
绑定邮箱验证码发送

"email": "user@example.com"
*/
export function usersBindEmailCode(
  {
    email,
  }: {
    email: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    email,
  };
  return fetch(game_url + game_users_api_base + "/bind-email-code", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/profile   over
用户信息

@return {
  "errorCode": "string",
  "data": {
    "displayName": "string",
    "language": "string",
    "email": "string",
    "walletAdress": "string"
  },
  "errorMessage": "string",
  "isOk": true
}
*/
export function usersProfile(
  options?: RequestInit
): Promise<GameLoginBaseResult<IGameUserInfo>> {
  const body = {};
  return fetch(game_url + game_users_api_base + "/profile", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/bind-wallet  over
绑定钱包

    "address": "string",
    "sign": "string"
*/
export function usersBindWallet(
  {
    address,
    sign,
  }: {
    address: string;
    sign: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const body = {
    address,
    sign,
  };
  const accessToken = getLoginToken().accessToken;
  return fetch(game_url + game_users_api_base + "/bind-wallet", {
    method: "post",
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/users/tinykingdom  over
绑定钱包
*/

// ITinyKingdomInfo
export function usersTinykingdom(
  accessToken: string,
  options?: RequestInit
): Promise<GameLoginBaseResult<ITinyKingdomInfo>> {
  const body = {};
  // const accessToken = getLoginToken().accessToken;
  return fetch(game_url + game_users_api_base + "/tinykingdom", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      refreshLoginToken();
    }
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/login/vcode
生成验证码
*/
export function loginVcode(options?: RequestInit) {
  return new Promise<{
    captchaKey: string;
    file: string;
  }>((resolve, rejcet) => {
    fetch(game_url + game_login_api_base + "/vcode", {
      ...options,
    }).then(async (res) => {
      if (res.body) {
        const blob = await res.blob();
        if (blob) {
          URL.createObjectURL(blob);
          const file = new File([blob], "fileName", {
            type: "application/x-download",
          });
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = (base64) => {
            resolve({
              file: base64.target?.result?.toString() || "",
              captchaKey: res.headers.get("captchaKey") || "",
            });
          };
        }
      } else {
        rejcet(res);
      }
    });
  });
}

/* 
/api/login/exist-vcode    over
是否需要验证码

email: string;
*/
export function loginExistVcode(
  {
    email,
  }: {
    email: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  const params = new URLSearchParams({
    email,
  });
  return fetch(
    game_url + game_login_api_base + "/exist-vcode?" + params.toString(),
    {
      ...options,
    }
  ).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
/api/login/wallet-str   over
获取钱包授权字符串

adress: string;
*/

export function loginWalletStr(
  {
    address,
  }: {
    address: string;
  },
  options?: RequestInit
): Promise<GameLoginBaseResult<string>> {
  const params = new URLSearchParams({
    adress: address,
  });
  return fetch(
    game_url + game_login_api_base + "/wallet-str?" + params.toString(),
    {
      ...options,
    }
  ).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

/* 
  /api/sign-in/internal@2/{accessToken}/{web}

  // 游戏登录的接口 会返回重定向的url;
  */
export function gameSignIn(
  { accessToken }: { accessToken: string },
  options?: RequestInit
): Promise<GameLoginBaseResult<any>> {
  return fetch(config.gameApi2 + `${accessToken}/${"web"}`, {
    ...options,
  }).then(async (res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}
