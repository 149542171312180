import styled from "styled-components";

import presale_bg from "src/images/presale-market/card_sr.png";

function PresaleBg() {
  return (
    <StyledSuperContainer>
      <StyledCover />
      <StyledCardFrontImg width={230} src={presale_bg} alt="" />
    </StyledSuperContainer>
  );
}

export default PresaleBg;

const StyledSuperContainer = styled.div`
  position: absolute;
  width: 230px;
  height: 309px;
  box-sizing: border-box;
`;

const StyledCover = styled.div`
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: 7px;
  right: 7px;
  background-color: #fffaf2;
  border-radius: 13px;
  z-index: -1;
`;

const StyledCardFrontImg = styled.img`
  display: block;
`;
