import { CSSProperties } from "react";
import styled from "styled-components";

import hot_h from "src/images/game/HOT_H.png";
import hot_o from "src/images/game/HOT_O.png";
import hot_t from "src/images/game/HOT_T.png";

import new_n from "src/images/sidebar/new_n.png";
import new_e from "src/images/sidebar/new_e.png";
import new_w from "src/images/sidebar/new_w.png";

import beta_b from "src/images/sidebar/beta_b.png";
import beta_e from "src/images/sidebar/beta_e.png";
import beta_t from "src/images/sidebar/beta_t.png";
import beta_a from "src/images/sidebar/beta_a.png";

export const Hot = ({
  bottom,
  style,
}: {
  bottom: number;
  style?: CSSProperties;
}) => {
  return (
    <StyledContainer style={style} bottom={bottom}>
      <img src={hot_h} />
      <img src={hot_o} />
      <img src={hot_t} />
    </StyledContainer>
  );
};

export const New = ({
  bottom,
  style,
}: {
  bottom: number;
  style?: CSSProperties;
}) => {
  return (
    <StyledContainer style={style} bottom={bottom}>
      <img src={new_n} />
      <img src={new_e} />
      <img src={new_w} />
    </StyledContainer>
  );
};

export const Beta = ({
  bottom,
  style,
}: {
  bottom: number;
  style?: CSSProperties;
}) => {
  return (
    <StyledBeta style={style} bottom={bottom}>
      <img src={beta_b} />
      <img src={beta_e} />
      <img src={beta_t} />
      <img src={beta_a} />
    </StyledBeta>
  );
};

export const RedRadius = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: -2px;
  background-color: red;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  border: 2px solid #333;
`;

const StyledContainer = styled.div<{ bottom: number }>`
  display: inline-block;
  margin-left: 5px;

  img {
    margin-bottom: ${({ bottom }) => bottom}px;

    :nth-child(1) {
      animation: 1s 100ms infinite lePeek;
      margin-right: -1px;
    }

    :nth-child(2) {
      animation: 1s 250ms infinite lePeek;
      margin-right: -1px;
    }

    :nth-child(3) {
      animation: 1s 400ms infinite lePeek;
    }
  }
`;

const StyledBeta = styled.div<{ bottom: number }>`
  display: inline-block;
  margin-left: 5px;

  img {
    margin-bottom: ${({ bottom }) => bottom}px;

    :nth-child(1) {
      animation: 1s 100ms infinite lePeek;
      margin-right: -3px;
    }

    :nth-child(2) {
      animation: 1s 250ms infinite lePeek;
      margin-right: -2px;
    }

    :nth-child(3) {
      animation: 1s 400ms infinite lePeek;
      margin-right: -4px;
    }

    :nth-child(4) {
      animation: 1s 550ms infinite lePeek;
    }
  }
`;
