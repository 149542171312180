import { useQuery } from "react-query";
import useTiny from "./useTiny";

import type { BigNumber } from "ethers";
// import contractInfoMap from "../tiny/contractInfoMap";

/**
 * @param {Array<number|string>} ids
 * @returns
 */
export default function useStaminas(ids: (number | string)[]) {
  const tiny = useTiny();
  const staminaContract = tiny.contracts.TinyNFTStamina;

  return useQuery(
    ["NFT_STAMINA_INFOS", ids],
    async () => {
      // console.log('ids', ids);
      // const result1 = await staminaContract.HeroStaminaInfoMap(ids[0]);
      const results: [BigNumber] = await staminaContract.getHeroStaminaInfos(
        ids
      );
      // console.log("results", results);
      // return result1;
      return results;
    },
    {
      enabled: ids.length > 0,
    }
  );
}

export function useStamina(id: number | string, enabled?: boolean) {
  const tiny = useTiny();
  const staminaContract = tiny.contracts.TinyNFTStamina;
  return useQuery(
    ["SINGLE_NFT_STAMINA_INFO", id],
    async () => {
      // console.log('useStamina id', id);
      // const result1 = await staminaContract.HeroStaminaInfoMap(ids[0]);
      const results: {
        lastBlock: BigNumber;
        stamina: BigNumber;
      } = await staminaContract.getStaminaInfoById(id);
      // console.log("id, results", id, results);
      // return result1;
      return {
        lastBlock: results.lastBlock.toNumber(),
        stamina: results.stamina.toNumber(),
      };
    },
    {
      enabled,
    }
  );
}
