// const passwordCheck = /^(?=.*[0-9].*)(?=.*[a-zA-Z].*).{8,20}/;
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { TFunction } from "react-i18next";
import {
  refreshTokenLogin,
  GameLoginBaseResult,
  IToken,
} from "src/services/game";

// import iconError from "src/images/icon_error.svg";
// 密码必须包含 字母数字  符号数字   字母符号 等组合
const passwordCheck =
  /((?=.*[a-zA-Z])(?=.*\d)|(?=[a-zA-Z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-zA-Z\d#@!~%^&*]{8,}/;

// 必須包含 英文 數字 特殊字符
const passwordCheckStrict =
  /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/;

const msgErrorMsg = {
  FacebookTokenInvalid: "FacebookTokenInvalid",
  GoogleTokenInvalid: "GoogleTokenInvalid",
  TwitterTokenInvalid: "TwitterTokenInvalid",
  RefreshTokenInvalid: "RefreshTokenInvalid",
  ApplicationUserNotFound: "ApplicationUserNotFound",
  PasswordInvalid: "PasswordInvalid",
  RetrivePwdTokenInvalid: "RetrivePwdTokenInvalid",
  DrawTokenInvalid: "DrawTokenInvalid",
  EmailFormatInvalid: "EmailFormatInvalid",
  EmailRepeat: "EmailRepeat",
  PasswordStrengthInvalid: "PasswordStrengthInvalid",
  PasswordRepeat: "PasswordRepeat",
  EmailNotFound: "EmailNotFound",
  EmailNotBound: "EmailNotBound",
  EmailHasBound: "EmailHasBound",
  VerificationCodeInvalid: "VerificationCodeInvalid",
  CAPTCHAInvalid: "CAPTCHAInvalid",
  WalletAuthFail: "WalletAuthFail",
  WalletAdressRepeat: "WalletAdressRepeat",
  WalletAuthTimeout: "WalletAuthTimeout",
  WalletHasBound: "WalletHasBound",
  PasswordOld: "PasswordOld",
  RegisterMethodInvalid: "RegisterMethodInvalid",
};

export type msgErrorMsgKeys = keyof typeof msgErrorMsg;
// 组 30087@3x.png
const toastErrorConfig = {
  // icon: `${iconError}`,
  style: {
    borderRadius: "10px",
    background: "#514B45",
    color: "#FDCE4F",
  },
};

const msgError = (str: string) => {
  toast.error(str, toastErrorConfig);
  // if (msgErrorMsg.hasOwnProperty(str)) {
  //   toast.error(t(`${msgErrorMsg[str as msgErrorMsgKeys]}`), toastErrorConfig);
  // } else {
  //   toast.error(t(`${str.toString()}`), toastErrorConfig);
  // }
};

const msgPromise = async <T>(
  asyncMethod: Promise<GameLoginBaseResult<T>>,
  t: TFunction<"translation", undefined>,
  str?: string,
  noSendMsg?: boolean
) => {
  const id = toast.loading(t("Loading"));
  // console.log({
  //   str,
  // });
  return new Promise<GameLoginBaseResult<T>>(async (resolve, rej) => {
    try {
      asyncMethod
        .then((res) => {
          if (res.isOk) {
            toast.success(str ? t(`${str}`) : t("Success"), {
              id,
            });
          } else {
            // console.log({
            //   res,
            // });
            if (!noSendMsg) {
              toast.error(t(`${res!.errorCode as string}`), {
                ...toastErrorConfig,
                id,
              });
            } else {
              toast.dismiss(id);
            }
          }
          resolve(res);
        })
        .catch(() => {
          toast.error(t("ApiError"), {
            id,
          });
        });
    } catch (error) {
      rej({ error });
    }
  });
};

export const saveLoginToken = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  /* 
    accessToken就是登录token，每次请求接口的时候在请求头上携带
  Authorization: Bearer <你的accessToken>
  
  当accessToken过期，可以通过/api/login/refresh_token 接口，使用refresh_token获取新的accessToken
    */
  if (accessToken && refreshToken) {
    // console.log({
    //   login_accessToken: {
    //     accessToken,
    //     timeout: dayjs().add(1, "day").unix(),
    //   },
    //   login_refreshToken: {
    //     refreshToken,
    //     timeout: dayjs().add(30, "day").unix(),
    //   },
    // });
    localStorage.setItem(
      "login_accessToken",
      JSON.stringify({
        accessToken,
        timeout: dayjs().add(1, "day").unix(),
      })
    ); //持续一天
    localStorage.setItem(
      "login_refreshToken",
      JSON.stringify({
        refreshToken,
        timeout: dayjs().add(30, "day").unix(),
      })
    ); //持续三十天
  }
};

export const getLoginToken = () => {
  let loginToken = {
    accessToken: "",
    refreshToken: "",
  };
  const login_accessToken = JSON.parse(
    localStorage.getItem("login_accessToken") || JSON.stringify({})
  );
  const login_refreshToken = JSON.parse(
    localStorage.getItem("login_refreshToken") || JSON.stringify({})
  );
  if (login_accessToken && login_refreshToken) {
    // if()
    // console.log({
    //   login_accessToken,
    //   login_refreshToken,
    //   timer1: dayjs(login_accessToken.timeout * 1000).format(
    //     "YYYY-MM-DD HH:mm:ss"
    //   ),
    //   timer2: dayjs(login_refreshToken.timeout * 1000).format(
    //     "YYYY-MM-DD HH:mm:ss"
    //   ),
    //   timerBreak: dayjs(login_accessToken.timeout * 1000).diff(dayjs(), "d"),
    //   timerBreak2: dayjs(login_refreshToken.timeout * 1000).diff(dayjs(), "d"),
    // });
    if (dayjs(login_accessToken.timeout * 1000).diff(dayjs(), "d") >= 0) {
      loginToken.accessToken = login_accessToken.accessToken;
    }
    if (dayjs(login_refreshToken.timeout * 1000).diff(dayjs(), "d") >= 0) {
      loginToken.refreshToken = login_refreshToken.refreshToken;
    }
  }
  // console.log({
  //   loginToken,
  // });
  return loginToken;
};

export const clearLoginToken = () => {
  localStorage.removeItem("login_accessToken");
  localStorage.removeItem("login_refreshToken");
};

export const saveUserToken = (
  res: GameLoginBaseResult<IToken>,
  cb?: (userToken: IToken) => void
) => {
  if (res?.data) {
    const userToken = {
      accessToken: res?.data.accessToken as string,
      refreshToken: (res?.data.refresh_token
        ? res?.data.refresh_token
        : res?.data.refreshToken) as string,
    };
    saveLoginToken(userToken);
    cb && cb(userToken);
  }
};

export const refreshLoginToken = async () => {
  const refresh = await refreshTokenLogin({
    refreshToken: getLoginToken().refreshToken,
  });
  if (refresh.isOk) {
    saveLoginToken({
      accessToken: refresh.data?.accessToken as string,
      refreshToken: refresh.data?.refreshToken as string,
    });
  }
};

export {
  passwordCheck,
  passwordCheckStrict,
  msgErrorMsg,
  msgError,
  msgPromise,
};
