// 活动的hooks

import { BigNumber } from "ethers";
import { useQuery } from "react-query";
// import { getLastCheckIn } from "src/services/active";
import useTiny from "./useTiny";
import { getMulticallContract } from "../tiny/utils";

/* 
  // BAB Check-in  判断是否拥有BAB
  babBalanceOf(owner: string) {
    const Active = this.contracts.Active;
    return this.contractMethodSend(Active, "balanceOf", [owner]);
  }
*/

export function useBalanceOfBABByAddress() {
  const tiny = useTiny();
  const owner = tiny.myAccount;
  const babt = tiny.contracts.BABT;
  // const myAuction = list?.find((ele) => ele.bidder === account);
  // const _auctionId = myAuction?.auctionId;
  return useQuery(
    ["BABT_BALANCE", owner],
    async () => {
      const result: BigNumber = await babt.balanceOf(owner);
      return result.toNumber();
    },
    {
      // refetchInterval: 30_000,
      enabled: typeof owner === "string",
    }
  );
}

// export function useBABSignCountByAddress() {
//   const tiny = useTiny();
//   const account = tiny.myAccount;
//   const ActiveCheckIn = tiny.contracts.ActiveCheckIn;

//   return useQuery(
//     ["ACTIVE_CHECK_IN", account],
//     async () => {
//       const result: {
//         count: number;
//         lastCheck: BigNumber;
//       } = await ActiveCheckIn.checkInCount(account);
//       // console.log({
//       //   count: result.count,
//       //   lastCheck: result.lastCheck.toNumber(),
//       // });
//       return {
//         count: result.count,
//         lastCheck: result.lastCheck.toNumber(),
//       };
//     },
//     {
//       refetchInterval: 30_000,
//       enabled: typeof account === "string",
//     }
//   );
// }

export function useGloryCampaignCheckinInfo() {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const multicallContract = getMulticallContract(tiny);
  const GloryNFT = tiny.contracts.ActiveNFT;
  const GloryCheckIn = tiny.contracts.ActiveCheckIn;

  return useQuery(
    ["GLORY_NFT_CHECKIN_INFO", account],
    async () => {
      const calldata = [
        [
          GloryNFT.address.toLowerCase(),
          GloryNFT.interface.encodeFunctionData("balanceOf", [account]),
        ],
        [
          GloryCheckIn.address.toLowerCase(),
          GloryCheckIn.interface.encodeFunctionData("checkInCount", [account]),
        ],
      ];
      // console.log('calldata', calldata);

      const { returnData }: { returnData: string[] } =
        await multicallContract.aggregate(calldata);
      const nftBalance = returnData[0];
      // console.log('returnData[1]', returnData[1]);
      // 返回结果有多个值
      const [result2] = GloryCheckIn.interface.decodeFunctionResult(
        "checkInCount",
        returnData[1]
      );
      // console.log("nftBalance", nftBalance);
      // console.log(result2);
      return {
        nftBalance: Number(nftBalance),
        count: result2.count,
        lastCheck: result2.lastCheck.toNumber(),
      };
    },
    {
      refetchInterval: 30_000,
      enabled: typeof account === "string",
    }
  );
}

// export function useLastCheckInByAddress() {
//   const tiny = useTiny();
//   const account = tiny.myAccount;

//   const res = useQuery(
//     ["LAST_CHECKIN", account],
//     async ({ signal }) => {
//       if (account === undefined) {
//         return;
//       }
//       const result = await getLastCheckIn({ account }, { signal });
//       return result;
//     },
//     {
//       refetchInterval: 30_000,
//       enabled: typeof account === "string",
//     }
//   );

//   return {
//     data: res.data,
//     refetch: res.refetch,
//   };
// }
