import React, { ReactNode, CSSProperties } from "react";
// import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import Card from "../Card";
import CardContent from "../CardContent";
import Container from "../Container";
import ModalTitle from "../ModalTitle";
import Close from "../Close";

import sparkUp from "src/images/spark_up.png";
import sparkDown from "src/images/spark_down.png";
export interface ModalProps {
  onDismiss?: () => void;
  title?: ReactNode;
  size?: "es" | "sm" | "lite" | "md" | "lg";
  bgType?: 1 | 2;
  contentClx?: string;
  className?: string;
  isConfirmModal?: boolean;
  style?: CSSProperties;
  isNotBg?: boolean;
  disableScale?: boolean;
  cardStyle?: CSSProperties;
  titleSize?: "sm" | "lg";
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  size = "es",
  bgType,
  onDismiss,
  contentClx,
  className,
  isConfirmModal = false,
  style,
  isNotBg,
  disableScale,
  titleSize,
  cardStyle,
}) => {
  const sprProps = useSpring({
    to: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0.5 },
  });

  const sprPropsDisableScale = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  return (
    <Container size={size} className={className} style={style}>
      {isNotBg ? (
        <AnimatedCard
          bgType={bgType}
          style={
            disableScale
              ? {
                  ...sprPropsDisableScale,
                  background: "none",
                  boxShadow: "none",
                }
              : {
                  ...sprProps,
                  ...{
                    background: "none",
                    boxShadow: "none",
                  },
                }
          }
        >
          {isConfirmModal && (
            <>
              <img
                src={sparkUp}
                style={{ position: "absolute", top: -20, left: -25 }}
                alt=""
              />
              <img
                src={sparkDown}
                style={{ position: "absolute", right: -25, bottom: -15 }}
                alt=""
              />
            </>
          )}
          {typeof title !== "undefined" ? <ModalTitle text={title} /> : null}
          {children}
          {/* {onDismiss && <Close onClick={onDismiss} />} */}
        </AnimatedCard>
      ) : (
        <AnimatedCard
          bgType={bgType}
          style={disableScale ? sprPropsDisableScale : sprProps}
        >
          <CardContent
            className={contentClx + " position-relative"}
            style={cardStyle}
          >
            {isConfirmModal && (
              <>
                <img
                  src={sparkUp}
                  style={{ position: "absolute", top: -20, left: -25 }}
                  alt=""
                />
                <img
                  src={sparkDown}
                  style={{ position: "absolute", right: -25, bottom: -15 }}
                  alt=""
                />
              </>
            )}
            {typeof title !== "undefined" ? (
              <ModalTitle titleSize={titleSize} text={title} />
            ) : null}
            {children}
            {onDismiss && <Close onClick={onDismiss} />}
          </CardContent>
        </AnimatedCard>
      )}
    </Container>
  );
};

export default Modal;

const AnimatedCard = animated(Card);
