// import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useIsMobile from "src/hooks/useIsMobile";

import Modal from "src/components/Modal";
// import ModalActions from "src/components/ModalActions";
// import ParallelogramButton from "src/components/Button/ParallelogramButton";
// import ClaimButton from "src/components/ClaimButton";
import { getDisplayBalance } from "src/utils/formatBalance";

import type { ModalProps } from "src/components/Modal";
import type { BigNumber } from "ethers";
import { getTokenImgSrc } from "src/constants/imgPaths";
import ParallelogramButton from "src/components/Button/ParallelogramButton";
import ModalActions from "src/components/ModalActions";
import BaseTips from "src/components/BaseTips";

interface Props extends ModalProps {
  amount: BigNumber;
  claimAirdrop(): void;
}
export default function GasRefundModal({
  onDismiss,
  claimAirdrop,
  amount,
}: Props) {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  // const tipList = [t("gasDropTip1"), t("gasDropTip2"), t("gasDropTip3")];
  const tipList = [t("gasDropTip2"), t("gasDropTip3")];

  return (
    <Modal
      title={t("Gas Refund")}
      style={{ maxWidth: 430 }}
      onDismiss={onDismiss}
    >
      {/* <StyledRow className="mb-30">
        <div className="display-flex align-center">
          <span className="rfs-17">{}</span>
        </div>
      </StyledRow> */}
      <StyledContent>
        <StyledTotalRight>
          <img
            className="mr-5"
            src={getTokenImgSrc("BNB")}
            height={isMobile ? 22 : 27}
            alt="BNB"
          />
          <StyledAmount>{getDisplayBalance(amount, 18, 4)}</StyledAmount>
        </StyledTotalRight>
        <ModalActions
          style={{
            height: "4rem",
          }}
        >
          <ParallelogramButton onClick={claimAirdrop}>
            {t("Claim")}
          </ParallelogramButton>
        </ModalActions>
        <StyledDivider />
        <BaseTips list={tipList}></BaseTips>
      </StyledContent>
    </Modal>
  );
}

const StyledDivider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* padding: 24px 0; */
  min-height: 100px;
  border-radius: 9px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 15px;
  `}
`;

const StyledTotalRight = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 120px;
  font-size: 1.3rem;
`;

const StyledAmount = styled.span`
  height: 2.8rem;
  width: 7.8rem;
  border-radius: 11px;
  background-color: #fffbdd;
  padding: 11px 12px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  box-sizing: border-box;
`;
