import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useTiny from "src/hooks/useTiny";
import { noFunc } from "src/utils";
import { getBalance, getDisplayBalance } from "src/utils/formatBalance";
import { getLocalStorageByUser } from "src/utils/storage";
import useFluidity from "src/hooks/useFluidity";
import {
  getSwapHistory,
  ISwapHistoryItem,
} from "src/views/MarketPlace/Fluidity/hooks/useOperateHistory";

const MINIMUM_LIQUIDITY = 10 ** 3;

export type ExchangeToken = "TINC" | "SPIRIT";

export const addressDecimalsConfig = {
  TINC: { decimals: 18, fractionDigits: 6 },
  SPIRIT: { decimals: 0, fractionDigits: 0 },
} as const;

export interface FluidityContextProps {
  inputCurrencyAmount: string;
  setInputCurrencyAmount: Dispatch<SetStateAction<string>>;
  outputCurrencyAmount: string;
  setOutputCurrencyAmount: Dispatch<SetStateAction<string>>;

  exchange: ExchangeToken[];
  setExchange: Dispatch<SetStateAction<ExchangeToken[]>>;

  tincLiquidity: string;
  setTincLiquidity: Dispatch<SetStateAction<string>>;
  spiritLiquidity: string;
  setSpiritLiquidity: Dispatch<SetStateAction<string>>;

  slippageTolerance: number; //滑点容差（%）
  setSlippageTolerance: Dispatch<SetStateAction<number>>;

  tradeDeadline: number; //交易截止时间（分钟）
  setTradeDeadline: Dispatch<SetStateAction<number>>;

  nowInput: number;
  setNowInput: Dispatch<SetStateAction<number>>;

  liquidityInput: number;
  setLiquidityInput: Dispatch<SetStateAction<number>>;

  calcFlag: boolean;
  setCalcFlag: (flag: boolean) => void;

  tincAll: string;
  spiritAll: string;
  addLiquidityAmount?: string;
  freshUserLiquidity: () => void;

  swapHistory: ISwapHistoryItem[];
  setSwapHistory: Dispatch<SetStateAction<ISwapHistoryItem[]>>;
}

export let calcFlag = false;

export const setCalcFlag = (flag: boolean) => {
  calcFlag = flag;
};

export const initState: FluidityContextProps = {
  inputCurrencyAmount: "",
  setInputCurrencyAmount: noFunc,
  outputCurrencyAmount: "",
  setOutputCurrencyAmount: noFunc,
  tincLiquidity: "",
  setTincLiquidity: noFunc,
  spiritLiquidity: "",
  setSpiritLiquidity: noFunc,
  slippageTolerance: 0.5,
  setSlippageTolerance: noFunc,
  tradeDeadline: 20,
  setTradeDeadline: noFunc,

  calcFlag: calcFlag,
  setCalcFlag: setCalcFlag,

  exchange: [],
  setExchange: noFunc,

  nowInput: 0,
  setNowInput: noFunc,

  liquidityInput: 0,
  setLiquidityInput: noFunc,

  tincAll: "",
  spiritAll: "",
  freshUserLiquidity: noFunc,
  addLiquidityAmount: undefined,

  swapHistory: [],
  setSwapHistory: noFunc,
};

export const FluidityContext = createContext<FluidityContextProps>(initState);

export const FluidityContextProvider: FC = ({ children }) => {
  const { t } = useTranslation();

  const tiny = useTiny();
  const account = tiny.myAccount;

  const [inputCurrencyAmount, setInputCurrencyAmount] = useState(""); //tinc
  const [outputCurrencyAmount, setOutputCurrencyAmount] = useState(""); //spirit

  const [tincLiquidity, setTincLiquidity] = useState("");
  const [spiritLiquidity, setSpiritLiquidity] = useState("");
  const [liquidityInput, setLiquidityInput] = useState<number>(0);

  // [0]是输入  [1]是输出的token
  const [exchange, setExchange] = useState<ExchangeToken[]>(["TINC", "SPIRIT"]);

  const [swapHistory, setSwapHistory] = useState<ISwapHistoryItem[]>([]);

  const [historyLoaded, setHistoryLoaded] = useState(false);

  useEffect(() => {
    if (account && !historyLoaded) {
      setSwapHistory(
        getSwapHistory(account).map((ele: string) => JSON.parse(ele))
      );
      setHistoryLoaded(true);
    }
  }, [account, historyLoaded, swapHistory]);

  const {
    totalSupply,
    tincAmountAll,
    spiritAmountAll,
    pairContract,
    startFreshTINCBalance,
    startFreshSpiritBalance,
  } = useFluidity();

  // 0是输入  1是输出框(下面的)
  const [nowInput, setNowInput] = useState<number>(0);

  const [loaded, setLoaded] = useState(false);

  const Fluidity = useMemo(() => {
    const FluidityAny = getLocalStorageByUser({
      user: account || "",
      key: "Fluidity",
    });

    return FluidityAny ? FluidityAny : undefined;
  }, [account]);

  const [tradeDeadline, setTradeDeadline] = useState(
    Fluidity ? Fluidity.tradeDeadline : initState.tradeDeadline
  );

  const [slippageTolerance, setSlippageTolerance] = useState(
    Fluidity ? Fluidity.slippageTolerance : initState.slippageTolerance
  );

  useEffect(() => {
    if (Fluidity && !loaded) {
      setSlippageTolerance(Fluidity.slippageTolerance);
      setTradeDeadline(Fluidity.tradeDeadline);
      setLoaded(true);
    }
  }, [Fluidity, loaded]);

  // pairContract ERC20地址   ERC20.ts

  const addLiquidityAmount = useMemo(() => {
    if (totalSupply) {
      const _totalSupply = Number(totalSupply);
      if (_totalSupply === 0) {
        return parseFloat(
          (
            (Math.sqrt(
              Number(tincLiquidity) * Number(spiritLiquidity) * 1e18 * 1e18
            ) -
              MINIMUM_LIQUIDITY) /
            1e18
          ).toFixed(2)
        ).toString();
        // _mint(address(0xdead), MINIMUM_LIQUIDITY); // permanently lock the first MINIMUM_LIQUIDITY tokens
      } else {
        const tincTotal = Number(getDisplayBalance(tincAmountAll, 18, 6));
        const spiritTotal = spiritAmountAll.toNumber();
        // tincTotal 100
        return Math.min(
          (Number(tincLiquidity) * _totalSupply) / tincTotal,
          (Number(spiritLiquidity) * _totalSupply) / spiritTotal
        ).toString();
      }
    }
    return undefined;
  }, [
    spiritAmountAll,
    spiritLiquidity,
    tincAmountAll,
    tincLiquidity,
    totalSupply,
  ]);

  return (
    <FluidityContext.Provider
      value={{
        exchange,
        setExchange,
        inputCurrencyAmount,
        setInputCurrencyAmount,
        outputCurrencyAmount,
        setOutputCurrencyAmount,
        tincLiquidity,
        setTincLiquidity,
        spiritLiquidity,
        setSpiritLiquidity,
        tradeDeadline,
        setTradeDeadline,
        nowInput,
        setNowInput,
        tincAll: getDisplayBalance(tincAmountAll, 18, 18),
        spiritAll: getDisplayBalance(spiritAmountAll, 0, 0),
        liquidityInput,
        setLiquidityInput,
        addLiquidityAmount,
        freshUserLiquidity: () => {
          startFreshTINCBalance();
          startFreshSpiritBalance();
        },
        slippageTolerance,
        setSlippageTolerance,

        calcFlag,
        setCalcFlag,

        swapHistory,
        setSwapHistory,
      }}
    >
      {children}
    </FluidityContext.Provider>
  );
};
