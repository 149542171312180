import React, {
  CSSProperties,
  memo,
  useContext,
  useEffect,
  useMemo,
} from "react";
import styled from "styled-components";
import di_2 from "src/images/di_2.png";
import PetsIcon from "src/components/Svg/PetsIcon";
import {
  PartInfo,
  PetAttr,
  PetPartsGrade,
  PetsElement,
} from "src/components/NFTCard/PetCard/type";
import { useTranslation } from "react-i18next";
import {
  PartInfoEx,
  PetsMockContext,
  petsGenesProbability,
  petsGenesNotBreedGrade,
} from "../const";
import {
  PetDefaultGeneBoder,
  PetDefaultGeneColor,
  PetGeneBorder,
  PetGeneColor,
  PetTextColor,
} from "src/components/Svg/PetsIcon/const";
import PetParts from "src/components/NFTCard/PetCard/PetPartConfig";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  partProp: keyof PetAttr;
  mockPets: {
    element: PetsElement;
    parts: Record<keyof PetAttr, PartInfo[]>;
    id: number;
    power: number;
  }[];
}

interface TestProps extends PartInfoEx {
  index: number;
}

function PetsMockPart({ partProp, mockPets }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { femaleInfo, maleInfo, petProbabilitys, petsPartsGenes } =
    useContext(PetsMockContext);

  const renderPartsGemes = useMemo(() => {
    const partGenes = petsPartsGenes[partProp];
    const result: PartInfoEx[] = [];

    if (partGenes.length > 0) {
      let keyOjb: {
        [Key: string]: number;
      } = {};

      partGenes.map((ele) => {
        const key = `${ele.grade}-${ele.key}`;
        if (key in keyOjb) {
          keyOjb[key] += ele.probability;
        } else {
          keyOjb[key] = ele.probability;
        }
      });

      result.push(
        ...Object.keys(keyOjb)
          .map(
            (key) =>
              ({
                grade: Number(key.split("-")[0]) as PetPartsGrade,
                key: Number(key.split("-")[1]),
                probability: keyOjb[key],
              } as PartInfoEx)
          )
          .sort((a, b) => {
            return b.probability - a.probability;
          })
      );
      const item = { key: partProp, result };
      if (
        petProbabilitys.current.filter((ele) => ele.key === partProp).length ===
        0
      ) {
        petProbabilitys.current.push(item);
      }
    }
    return result;
  }, [partProp, petProbabilitys, petsPartsGenes]);

  // useEffect(() => {
  //   if (mockPets.length === 10000) {
  //     let item: TestProps[] = renderPartsGemes.map((ele) => {
  //       return {
  //         ...ele,
  //         index: 0,
  //       };
  //     });
  //     console.log({
  //       mockPets,
  //       renderPartsGemes,
  //     });
  //     item.map((itemEle, itemIndex) => {
  //       mockPets.map((ele, index) => {
  //         if (
  //           ele.parts[partProp][0].key === itemEle.key &&
  //           ele.parts[partProp][0].grade === itemEle.grade
  //         ) {
  //           itemEle.index += 1;
  //         }
  //       });
  //     });
  //     console.log({
  //       item,
  //     });
  //   }
  // }, [mockPets, partProp, renderPartsGemes]);

  const render = useMemo(() => {
    return (
      <StyledPartBox>
        <StyledPartBoxHeader>
          <PetsIcon lock={false} grade={1} prop={partProp} blackBg defaulted />
          <span>{t(partProp)}</span>
        </StyledPartBoxHeader>
        <StyledPetsPartsGenes>
          <StyledScrollBox>
            {renderPartsGemes.map(({ grade, key, probability }, index) => {
              return (
                grade !== petsGenesNotBreedGrade && (
                  <StyledGenesDetailItem key={index}>
                    <StyledGenesItemLeft>
                      <StyledGenesColor
                        color={PetGeneColor[grade - 1]}
                        borderColor={PetGeneBorder[grade - 1]}
                      />
                      <span
                        style={{
                          color: PetTextColor[grade - 1],
                        }}
                      >
                        {`${t(PetParts[partProp][key])}`}
                      </span>
                    </StyledGenesItemLeft>
                    <StyledGenesItemRight>{probability}%</StyledGenesItemRight>
                  </StyledGenesDetailItem>
                )
              );
            })}
            {/* {petsPartsGenes.length > 0 && (
              <StyledGenesDetailItem>
                <StyledGenesItemLeft>
                  <StyledGenesColor
                    color={PetDefaultGeneColor}
                    borderColor={PetDefaultGeneBoder}
                  />
                  <span
                    style={{
                      color: PetDefaultGeneColor,
                    }}
                  >
                    {t("mutation")}
                  </span>
                </StyledGenesItemLeft>
                <StyledGenesItemRight>0.05%</StyledGenesItemRight>
              </StyledGenesDetailItem>
            )} */}
          </StyledScrollBox>
        </StyledPetsPartsGenes>
      </StyledPartBox>
    );
  }, [partProp, renderPartsGemes, t]);

  return isMobile ? renderPartsGemes.length > 0 ? render : <></> : render;
}

export default memo(PetsMockPart);

const StyledScrollBox = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 100%;
  // overflow: auto;
  flex-direction: column;
`;

const StyledGenesItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 0 !important;
`;

const StyledGenesColor = styled.div<{
  color: CSSProperties["color"];
  borderColor: CSSProperties["color"];
}>`
  width: 12px;
  height: 12px;
  min-width: 12px;
  background-color: ${({ color }) => color};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 5px;
`;

const StyledGenesItemLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 0 !important;
  flex: 1;

  span {
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledGenesDetailItem = styled.div`
  width: 100%;
  height: 24px;
  border-radius: 12px;
  background: #fff8e2;
  border: 1px solid #707070;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const StyledPetsPartsGenes = styled.div`
  padding: 0.8rem;
  box-sizing: border-box;
  height: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem 1rem;
  `}
`;

const StyledPartBoxHeader = styled.div`
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 0;
  background: #fedb8d;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 100%;
  }

  span {
    margin-left: 0.5rem;
  }
`;

const StyledPartBox = styled.div`
  background: url(${di_2});
  box-sizing: border-box;
  border-radius: 1rem;
  user-select: none;
  overflow: hidden;
  position: relative;
  padding-top: 3rem;
`;
