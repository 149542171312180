import BigNumberJS from "bignumber.js";
import { Contract, BigNumber, constants, utils } from "ethers";
import config, { staminaCoefficient, gradeDurability } from "../config";
import { lordCastleLevelWeight, gradeType } from "src/config";
import type { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import type { HeroDesc, ownerflagType, PageResult } from "../services/typings";
import type { HeroStaminaInfo } from "../services/typings.d";
import type {
  DetailInfo as LordOccupiedDetailInfo,
  CastleLevel,
} from "src/services/lord";
import { TFunction } from "react-i18next";

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(
  address: string,
  startChars = 4,
  endChars = 4
): string {
  const parsed = utils.isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${address.substring(0, startChars + 2)}...${address.substring(
    42 - endChars
  )}`;
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!utils.isAddress(address) || address === constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function formatFontSize(fs: number | string | undefined) {
  if (!fs) return undefined;
  return typeof fs === "string" ? fs : fs + "px";
}

export function noFunc() {}

export function noFuncPromise(): Promise<void> {
  return new Promise<void>(() => {});
}

export const promiseString: Promise<string> = new Promise((res, rej) =>
  res("")
);

export const bn0 = BigNumber.from("0");

export const bn1e18 = BigNumber.from(10).pow(18);

// 后端提示x10 ^6 需要用这个处理
export function formatMarketPrice(price: string | number) {
  return new BigNumberJS(price).div(1e6).toNumber();
}

export function getNameFromDesc(lang: string, desc?: HeroDesc) {
  if (!desc) return;
  const nameStr = lang.includes("zh") ? "name_cn" : "name_en";
  return desc[nameStr];
}

export function getPageCount(result?: PageResult) {
  if (result) {
    return Math.ceil(result.total_count / result.page_size);
  }
  return 0;
}

export const BLOCKS_PER_DAY = (24 * 1200) / staminaCoefficient;

export function calcStamina(
  ownerflag: ownerflagType,
  StaminaInfo: HeroStaminaInfo | null | undefined,
  blockNumber: number | undefined,
  grade?: gradeType
) {
  // console.log('StaminaInfo', StaminaInfo);

  if (!StaminaInfo) return undefined;
  const { lastBlock, stamina } = StaminaInfo;
  // console.log('lastBlock, stamina', lastBlock, stamina);

  // 没有值
  if (
    lastBlock === undefined ||
    lastBlock === null ||
    stamina === undefined ||
    stamina === null
  ) {
    return undefined;
  }
  // 判断 lastblock 是不是 0
  if (lastBlock === 0 && grade) {
    return gradeDurability[grade];
  }
  // 首先判断是不是在矿池中
  if (ownerflag !== 1) {
    // 如果不在矿池中，直接返回现在的体力值
    return stamina / BLOCKS_PER_DAY;
  }
  // console.log('stamina', stamina);
  // 在矿池中
  if (!blockNumber) {
    return undefined;
  }
  const duration = blockNumber - lastBlock;
  // const duration = (blockNumber - lastBlock);
  // console.log("duration", duration);
  return Math.max((stamina - duration) / BLOCKS_PER_DAY, 0);
}

export function trimZero(number: string | number) {
  let a = typeof number === "string" ? number : number.toString();
  while (a.includes(".") && (a.endsWith("0") || a.endsWith("."))) {
    a = a.slice(0, -1);
  }
  return a;
}

/* 
处理耕作中排行榜的英雄滚动顺序
*/
export function ordinalSuffixOf(serialNum: number) {
  var j = serialNum % 10,
    k = serialNum % 100;
  if (j == 1 && k != 11) {
    return serialNum + "st";
  }
  if (j == 2 && k != 12) {
    return serialNum + "nd";
  }
  if (j == 3 && k != 13) {
    return serialNum + "rd";
  }
  return serialNum + "th";
}

export function toDDHH(
  secs: number | undefined,
  t?: TFunction<"translation", undefined>
): string {
  if (typeof secs === "number") {
    var days = parseInt((Math.floor(secs / 3600) / 24).toString());
    var hours = Math.floor(secs / 3600) % 24;
    var minutes = Math.floor(secs / 60) % 60;
    // var seconds = secs % 60;

    const renderString = (index: number) => {
      switch (index) {
        case 0:
          return t ? t("d") : "d";
        case 1:
          return t ? t("h") : "h";
        default:
          break;
      }
    };

    return [days, hours]
      .map((v, index) => {
        return (v < 10 ? "0" + v : v).toString() + renderString(index);
      })
      .join(" ");
  } else {
    return t ? `--${t("d")} --${t("h")}}` : `--d --h`;
  }
}

export function toDDHHMM(
  secs: number | undefined,
  t?: TFunction<"translation", undefined>
): string {
  if (typeof secs === "number") {
    var days = parseInt((Math.floor(secs / 3600) / 24).toString());
    var hours = Math.floor(secs / 3600) % 24;
    var minutes = Math.floor(secs / 60) % 60;
    // var seconds = secs % 60;

    const renderString = (index: number) => {
      switch (index) {
        case 0:
          return t ? t("d") : "d";
        case 1:
          return t ? t("h") : "h";
        case 2:
          return t ? t("m") : "m";
        default:
          break;
      }
    };

    return [days, hours, minutes]
      .map((v, index) => {
        return (v < 10 ? "0" + v : v).toString() + renderString(index);
      })
      .join(" ");
  } else {
    return t ? `--${t("d")} --${t("h")} --${t("m")}` : `--d --h --m`;
  }
}

export function toHHMMSS(secs: number | undefined): string {
  if (typeof secs === "number") {
    var hours = Math.floor(secs / 3600);
    var minutes = Math.floor(secs / 60) % 60;
    var seconds = secs % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  } else {
    return "--:--:--";
  }
}

export function toHHMM(secs: number | undefined): string {
  if (typeof secs === "number") {
    var hours = Math.floor(secs / 3600);
    // var minutes = Math.floor(secs / 60) % 60;
    // var minutes = secs > 0 ? (Math.floor(secs / 60) % 60) + 1 : 0;
    var minutes = secs < 60 && secs > 0 ? 1 : Math.floor(secs / 60) % 60;
    // var seconds = secs % 60;
    // .
    return [hours, minutes]
      .map((v, index) => {
        return (v < 10 ? "0" + v : v).toString() + (index === 0 ? "h" : "m");
      })
      .join(" ");
  } else {
    return "--h --m";
  }
}

const priceCoefficient = BigNumber.from(10).pow(16);

export function formatMarketTotalPrice(totalPrice: string) {
  return trimZero(
    (BigNumber.from(totalPrice).div(priceCoefficient).toNumber() / 100).toFixed(
      2
    )
  );
}

export function isPidOld(pid: number) {
  return pid >= 0;
}

export function prefixZero(n: number, m: number) {
  var _a = (Array(m).join("0") + n).slice(-m);
  return _a;
}

export function calcLordDayTINC(
  level: CastleLevel,
  occupiedDetail: LordOccupiedDetailInfo | undefined,
  isOccupied: boolean,
  lordTINCPerDay: number
) {
  const dailyRelease =
    (lordTINCPerDay * lordCastleLevelWeight[level - 1]) / 1000;
  if (!occupiedDetail) {
    return dailyRelease;
  }
  if (isOccupied) {
    return dailyRelease / occupiedDetail[level];
  }
  return dailyRelease / (occupiedDetail[level] + 1);
}

export function throttle<T = any>(func: (args: T) => any, interval = 1000) {
  let can = true;
  return (args: T) => {
    if (can) {
      can = false;
      setTimeout(function () {
        can = true;
      }, interval);
      return func(args);
    }
  };
}

export const thousandCalc = (num: number): string => {
  return num >= 1e3 ? Math.floor(num / 1e3) + "k" : num.toString();
};
