import { request } from "./util";
import { backend_url, referral_api_base } from "../constants";
import { PageParamBase, PageResult } from "./typings";

export interface ReferralUserInfo {
  referralCode: string;
  inviter: string | undefined;
  invitationTime: number | undefined;
  referralTotal: number;
  referralActive: number;
  rewardsTotal: {
    rewardType: 1 | 2 | 3;
    rewardTotal: number;
  }[];
  inviteReward: {
    tincAmount: string;
    busdAmount: string;
  };
  isKol: boolean;
  feePoint: number;
}

// 请求用户信息，如果是 404 则需要强制注册验签
export function getMyReferralUserInfo(
  { address }: { address: string },
  options?: RequestInit
): Promise<ReferralUserInfo | null> {
  return fetch(
    backend_url + referral_api_base + "/user?address=" + address,
    options
  ).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// 绑定邀请人
export function bindReferral(
  {
    address,
    referralCode,
    signedMessage,
  }: {
    address: string;
    referralCode: string | null;
    signedMessage: string;
  },
  options?: RequestInit
): Promise<ReferralUserInfo> {
  return fetch(backend_url + referral_api_base + "/register?", {
    ...options,
    method: "POST",
    headers: {
      contentType: "application/json",
    },
    body: JSON.stringify({
      address,
      referralCode,
      signedMessage,
      ts: Date.now(),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      const { inviter, referralCode, invitationTime } = res;
      const result: ReferralUserInfo = {
        inviter,
        invitationTime: invitationTime || 0,
        referralCode,
        referralTotal: 0,
        referralActive: 0,
        rewardsTotal: [],
        inviteReward: {
          tincAmount: "0",
          busdAmount: "0",
        },
        isKol: false,
        feePoint: 5,
      };
      return result;
    });
}

export interface InviteeInfo {
  createdAt: string;
  account: string;
  tinc: string;
  busd: string;
  restActiveTime: number; //剩余生效时间
}

// 获取自己邀请的所有地址信息
export function getMyInvitee(
  { address }: { address: string },
  options?: RequestInit
) {
  return request<{
    // total_count: number;
    list: InviteeInfo[];
  }>(referral_api_base + "/rewardReport?address=" + address, options);
}

interface RewardRecordParams extends PageParamBase {
  address: string;
}

export interface RewardInfo {
  id: number;
  calcAmount: string;
  timestamp: number;
  account: string;
  rewardType: 1 | 2;
  tx: string;
}

interface RewardRecordResult extends PageResult {
  records: RewardInfo[];
}
// 获得的邀请奖励的记录
export function getInvitationRewardRecords(
  { address, pageNum }: RewardRecordParams,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    address,
    pageNum: pageNum.toString(),
  });
  return request<RewardRecordResult>(
    referral_api_base + "/rewardRecords?" + params.toString(),
    options
  );
}
