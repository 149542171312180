import auction from "src/images/swiper/auction.jpeg";
import auctionPre from "src/images/swiper/auction-pre.jpeg";

import weekPre from "src/images/swiper/week_pre.jpeg";
import week from "src/images/swiper/week.jpeg";

import tinydao from "src/images/swiper/tinydao.jpeg";
import tinydaoPre from "src/images/swiper/tinydao-pre.jpeg";

import Activity from "src/images/swiper/Activity.jpeg";
import ActivityPre from "src/images/swiper/Activity-pre.jpeg";

import Updates from "src/images/swiper/Updates.jpeg";
import UpdatesPre from "src/images/swiper/Updates-pre.jpeg";

import advertise from "src/images/swiper/advertise.png";
import advertisePre from "src/images/swiper/advertise-pre.png";
import advertiseCN from "src/images/swiper/advertise-cn.png";
import advertisePreCN from "src/images/swiper/advertise-pre-cn.png";
import { ACTIVE_END_TIME } from "./SoulSpiritSwap/config";
import dayjs from "dayjs";

export const news_version = 55; //news version

export const AdvertiseConfig = {
  // url: "https://medium.com/@tinyworld.gamefi/tiny-world-celebrate-cny-campaigns-81b4d239760a",
  url: "/soul-spirit",
  img: advertise,
  preImg: advertisePre,
  // imgCN: advertiseCN,
  // preImgCN: advertisePreCN,
  // show: dayjs().unix() < ACTIVE_END_TIME,
  show: false,
  bottom: 98,
  target: "_self",
  type: "navlink", // 'navlink' | "a"
  endTime: ACTIVE_END_TIME,
} as const;

export const SwiperList: {
  href: string;
  src: string;
  alt: string;
  srcPre: string;
}[] = [
  {
    href: "https://medium.com/@tinyworld.gamefi/750a7db19999",
    src: week,
    alt: "Tiny World Update-60",
    srcPre: weekPre,
  },
  {
    href: "https://medium.com/@tinyworld.gamefi/exploring-the-tiny-world-a-new-journey-in-2023-e99bf21b2ba2",
    src: auction,
    alt: "Exploring the Tiny World: A New Journey in 2023",
    srcPre: auctionPre,
  },
  {
    href: "https://medium.com/@tinyworld.gamefi/tiny-dungeons-prologue-2-182838b8b6a2",
    src: tinydao,
    alt: "Tiny Dungeons Prologue 2",
    srcPre: tinydaoPre,
  },
  {
    href: "https://medium.com/@tinyworld.gamefi/tiny-dungeons-prologue-1-361f2409f8f0",
    src: Updates,
    alt: "Tiny Dungeons Prologue 1",
    srcPre: UpdatesPre,
  },
  {
    href: "https://medium.com/@tinyworld.gamefi/delightful-labor-day-festivities-at-tiny-kingdom-abfdbe10a3e3",
    src: Activity,
    alt: "Tiny World Updates Announcement",
    srcPre: ActivityPre,
  },
];
