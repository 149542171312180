import { getHeroExploreConfig, getHeroDescription } from "./jsonFile";
// import { chainId } from "src/config";

export type CacheKey = "HERO_EXPLORE_CONFIG" | "HERO_DECS_LIST";

const requestMap = {
  HERO_EXPLORE_CONFIG: getHeroExploreConfig,
  HERO_DECS_LIST: getHeroDescription,
};

export type JsonRes = ReturnType<typeof requestMap[CacheKey]>;

// 英雄圖鑒
const keyToRequestMap: Record<
  CacheKey,
  {
    version: number;
    request: (version: number, options?: RequestInit) => JsonRes;
  }
> = {
  HERO_EXPLORE_CONFIG: {
    version: 6,
    request: requestMap.HERO_EXPLORE_CONFIG,
  },
  HERO_DECS_LIST: {
    version: 6,
    request: requestMap.HERO_DECS_LIST,
  },
};

export default keyToRequestMap;

interface CacheDataWithVersion<T = any> {
  version: number;
  data: T;
}

export const dataCache: {
  [key: string]: CacheDataWithVersion | undefined;
} = {};

const promiseCache: {
  [key: string]: Promise<any> | undefined;
} = {};

export async function getCacheByVersionKey<T = any>(
  func: () => Promise<T>,
  key: CacheKey,
  version: number
): Promise<T> {
  const memoDataCache = dataCache[key];
  // 如果有数据缓存，返回数据缓存
  if (memoDataCache) {
    return memoDataCache.data;
  }
  // console.log("promiseCache", promiseCache);
  // 如果没有数据缓存，就去查找 promise 缓存
  const memoPromise = promiseCache[key];
  if (memoPromise) {
    return await memoPromise;
  }
  // 没有 promise 缓存，查找 localStorage 缓存
  function getData() {
    const promise = func();
    promiseCache[key] = promise;
    promise.then((data) => {
      dataCache[key] = {
        version,
        data,
      };
      // console.log('localStorage.setItem');
      localStorage.setItem(key, JSON.stringify(dataCache[key]));
      return data;
    });
    return promise;
  }
  try {
    const str = localStorage.getItem(key);
    // 如果有 localStorage 缓存，则获取，并判断 version
    if (str) {
      const localData: CacheDataWithVersion = JSON.parse(str);
      // version 一致才可以返回本地缓存
      if (localData.version === version) {
        // 同时在空闲时调用一下请求更新缓存
        getData();
        // window.requestIdleCallback(getData);
        return localData.data;
      }
    }
  } catch (error) {
    console.error("Get local cache error", error);
  }
  return getData();
}
