import { ReactText } from "react";
import styled from "styled-components";

import attackImg from "../../images/props/icon_1.png";
import defenseImg from "../../images/props/icon_2.png";
import hpImg from "../../images/props/icon_3.png";
import luckImg from "../../images/props/icon_4.png";
import exploreTimeImg from "../../images/props/icon_5.png";
import crystalImg from "../../images/props/icon_6.png";
import momentumImg from "../../images/props/icon_7.png";

import drop1Img from "../../images/props/drop_icon_1.png";
import drop2Img from "../../images/props/drop_icon_2.png";
import drop3Img from "../../images/props/drop_icon_3.png";
import drop4Img from "../../images/props/drop_icon_4.png";
import drop5Img from "../../images/props/drop_icon_5.png";
import drop6Img from "../../images/props/drop_icon_6.png";

import type { gradeType } from "src/config";

// symbol 目前就 攻击、防御、生命、幸运、士气
interface Props {
  size?: ReactText;
  width?: ReactText;
  height?: ReactText;
  symbol: string;
  className?: string;
  grade?: gradeType;
}

const imgSrcMap: {
  [title: string]: string;
} = {
  attack: attackImg,
  defense: defenseImg,
  hp: hpImg,
  luck: luckImg,
  exploreTime: exploreTimeImg,
  crystal: crystalImg,
  momentum: momentumImg,
};

const dropImgMap = {
  1: drop1Img,
  2: drop2Img,
  3: drop3Img,
  4: drop4Img,
  5: drop5Img,
  6: drop6Img,
};

export default function PropIcon(props: Props) {
  const {
    size = 18,
    width,
    height,
    symbol,
    className,
    grade,
    ...restProps
  } = props;
  let src = imgSrcMap[symbol];

  if (symbol.includes("drop") && grade) {
    src = dropImgMap[grade];
  }
  if (!src) {
    throw new Error("Invalid Logo symbol:" + symbol);
  }

  return (
    <StyledImg
      src={src}
      width={width ?? size}
      height={height ?? size}
      className={className}
      alt={symbol + " logo"}
      {...restProps}
    />
  );
}

const StyledImg = styled.img`
  cursor: pointer;
`;
