import { memo } from "react";
// import { detect } from "detect-browser";
// import { useWeb3React } from '@web3-react/core';
import useIsMobile from "src/hooks/useIsMobile";

import PageContent from "../../components/PageContent";
import Container from "src/components/Container";
// import Detail from "./Detail";

import Carousel from "./Carousel";
import Banner from "./Banner";
import BannerMobile from "./BannerMobile";
import Investors from "./Investors";
import Partners from "./Partner";
import EconomyInfo from "./EconomyInfo";

import "./index.css";
import DestoryBack from "./DestroyBack";
import BurnedList from "./components/BurnedList";

// import CarouselM from "./CarouselM";

function Home() {
  const isMobile = useIsMobile();

  return (
    <PageContent>
      <Container
        className={isMobile ? "mobileBox" : ""}
        size="lg"
        style={{ maxWidth: 1492 }}
      >
        {isMobile ? <BannerMobile /> : <Banner />}
        <EconomyInfo />
        {/* {isMobile ? <CarouselM /> : <Carousel />} */}
        <Carousel />
        <DestoryBack />
        <BurnedList />
        <Investors />
        <Partners />
      </Container>
    </PageContent>
  );
}

export default memo(Home);
