import { useQuery } from "react-query";
import { getGamingNFTs } from "src/services";
import { NFTInfo } from "src/services/typings";

const cacheKey = "GAMING_NFTS";

/**
 * all gaming nfts
 * @returns
 */
export default function useGamingNFTList(account: string | undefined) {
  return useQuery<NFTInfo[] | undefined>(
    [cacheKey, account],
    async ({ signal }) => {
      if (!account) {
        console.error("Can't find account");
        return;
      }
      const res = await getGamingNFTs(account, { signal });
      return res.nfts.map((ele) => {
        return {
          stamina: ele.StaminaInfo.stamina || undefined,
          ...ele,
        };
      });
    },
    {
      enabled: typeof account === "string",
    }
  );
}
