import manorImg1 from "src/images/lord/manor1.svg";
import manorImg2 from "src/images/lord/manor2.svg";
import manorImg3 from "src/images/lord/manor3.svg";
import manorImg1Pre from "src/images/lord/manor1_pre.png";
import manorImg2Pre from "src/images/lord/manor2_pre.png";
import manorImg3Pre from "src/images/lord/manor3_pre.png";

import manorBottom1 from "src/images/lord/manor1_bottom.png";
import manorBottom2 from "src/images/lord/manor2_bottom.png";
import manorBottom3 from "src/images/lord/manor3_bottom.png";
import manorBottom1Pre from "src/images/lord/manor1_bottom_pre.png";
import manorBottom2Pre from "src/images/lord/manor2_bottom_pre.png";
import manorBottom3Pre from "src/images/lord/manor3_bottom_pre.png";
import CastleCardBg0 from "src/images/lord/castle_card_bg0.png";
import CastleCardBg1 from "src/images/lord/castle_card_bg1.png";
import CastleCardBg2 from "src/images/lord/castle_card_bg2.png";
import CastleCardBg3 from "src/images/lord/castle_card_bg3.png";
import CastleCardBg0Pre from "src/images/lord/castle_card_bg0_pre.png";
import CastleCardBg1Pre from "src/images/lord/castle_card_bg1_pre.png";
import CastleCardBg2Pre from "src/images/lord/castle_card_bg2_pre.png";
import CastleCardBg3Pre from "src/images/lord/castle_card_bg3_pre.png";

import level1 from "src/images/lord/card/level-1.png";
import level2 from "src/images/lord/card/level-2.png";
import level3 from "src/images/lord/card/level-3.png";

import castleCode1 from "src/images/lord/camp/A.png";
import castleCode2 from "src/images/lord/camp/B.png";
import castleCode3 from "src/images/lord/camp/C.png";

import rank1Img from "src/images/lord/camp/rank1.png";
import rank2Img from "src/images/lord/camp/rank2.png";
import rank3Img from "src/images/lord/camp/rank3.png";

import { OverviewInfo } from "src/services/lord";
import { ILordCastleItem } from "./TinyLordTerritory/LordCastleCard";
import dayjs from "./dayjsConfig";
import type { gradeType } from "src/config";
import { LevelCastleInfo, MyLevelCastleInfo } from "src/services/lordOnline";
import { Address } from "src/services/typings";

export const animationConfig = {
  duration: 500,
  countDuration: 1,
};

// 占领上限
export const MAX_OCCUPY_LIMIT = 20;

export const manorImg = [manorImg1, manorImg2, manorImg3];

export const manorCardImg = [level1, level2, level3];

export const manorCardCodeImg = [castleCode1, castleCode2, castleCode3];

export const manorImgPre = [manorImg1Pre, manorImg2Pre, manorImg3Pre];

export const manorBottom = [manorBottom1, manorBottom2, manorBottom3];

export const manorBottomPre = [
  manorBottom1Pre,
  manorBottom2Pre,
  manorBottom3Pre,
];

export const titleList = ["Post", "City", "Fortress"];

export const monsterCodes = ["004", "005", "006"];

export const getDailyEarnings = ({
  list,
  detail,
}: {
  list: MyLevelCastleInfo[] | undefined;
  detail: OverviewInfo | undefined;
}) => {
  let dailyRelease = 0;
  if (detail && list) {
    dailyRelease = list.reduce((pre, ele) => pre + ele.rewardPerDay, 0);
  }
  return dailyRelease.toFixed(2);
};

export const CastleCardImgList = [
  CastleCardBg0,
  CastleCardBg1,
  CastleCardBg2,
  CastleCardBg3,
];

export const CastleCardImgPreList = [
  CastleCardBg0Pre,
  CastleCardBg1Pre,
  CastleCardBg2Pre,
  CastleCardBg3Pre,
];

interface ICastleType extends LevelCastleInfo {
  loginAccount: string | undefined;
  isCastleMax: boolean;
}
// 0 | 1 | 2 | 3 | 4 | 5; // 0 可挑战  |  1 保护中 |  2  我的城堡  | 3  战斗中  |  4  占领已达上限  |  5  我的城堡保护中
export const getCastleType = (
  castleInfo: ICastleType
): ILordCastleItem["castleType"] => {
  const {
    index,
    tokenInfos,
    protectUntil,
    account,
    timestamp,
    loginAccount,
    isCastleMax,
  } = castleInfo;

  // account === "0x9e89f2bD4B84BA83003669E6Ad489D0cf99AD8A2" &&
  // console.log({
  //   ...castleInfo,
  //   protectTime:
  //     protectUntil && dayjs.unix(protectUntil).format("YYYY-MM-DD HH:mm:ss"), //保护时间
  //   tsTime: timestamp && dayjs.unix(timestamp).format("YYYY-MM-DD HH:mm:ss"), //什么时候占领的时间
  // });
  if (account === loginAccount) {
    // console.log({
    //   type: typeof protectUntil === "number",
    //   protectUntilTime:
    //     protectUntil && dayjs(new Date()).diff(dayjs.unix(protectUntil)) < 0,
    // });
    if (
      typeof protectUntil === "number" &&
      dayjs(new Date()).diff(dayjs.unix(protectUntil)) < 0
    ) {
      // 我的城堡保护中 不可以退出
      return 5;
    } else {
      // 我的城堡 可以退出
      return 2;
    }
  }

  if (isCastleMax) {
    return 4;
  }

  if (
    typeof protectUntil === "number" &&
    dayjs(new Date()).diff(dayjs.unix(protectUntil)) < 0
  ) {
    return 1;
  }

  // castleType: 0 | 1 | 2 | 3 | 4 | 5;
  // 0 可挑战  |  1 保护中 |  2  我的城堡  | 3  战斗中  |  4  占领已达上限 |  5  我的城堡保护中 不可以退出

  return 0;
};

export const maxAttackCount = {
  defaultLevel: 2,
  normal: 1,
  highLevel: 2,
};

export function findHeroCode(itemCode: number): gradeType {
  if (itemCode < 500) {
    return Math.floor(itemCode / 100) as gradeType;
  }
  if (itemCode < 700) {
    return 5;
  }
  return 6;
}

//  阵营排名挖矿
export const CampAdditionArr = [50, 20, 0];
export const CampImgArr = [rank1Img, rank2Img, rank3Img];
//  阵营排名战斗加成
export const CampFightArr = [0, 10, 20];

// 英雄职介克制战斗加成百分比
export const CampFightUp = 30;

// 阵营排名的挖矿加成百分比字体颜色
export const CampRankAdditionsColor = ["#61DB41", "#FDCE4F", "#FFFFFF"];
