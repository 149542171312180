import { useCallback, useMemo } from "react";
import useDaoTotalVeTINC from "./useDaoTotalVeTINC";
import useDaoVeTINC from "./useDaoVeTINC";
import { getDisplayBalance } from "src/utils/formatBalance";
import { useQuery } from "react-query";
import useTiny from "./useTiny";
import { getMulticallContract } from "src/tiny/utils";
import { BigNumber } from "ethers";
import { chainId } from "src/config";

export default function useDaoVeData(user?: string): {
  veValue: BigNumber | undefined;
  veShare: string;
  totalVeTINC: BigNumber | undefined;
  veTinc: string;
  refetch: () => void;
} {
  // const [veValue, veRefresh] = useDaoVeTINC({ interval: false }); //1000 veTINC = 10min
  const tiny = useTiny();
  const account = user ? user : tiny.myAccount;
  const VotingEscrow = tiny.contracts.VotingEscrow;
  const multicallContract = getMulticallContract(tiny);

  const { data: veValueInfo, refetch: veRefresh } = useQuery(
    ["DAO_VETINC", account],
    async () => {
      const calldata = [
        [
          VotingEscrow.address.toLowerCase(),
          VotingEscrow.interface.encodeFunctionData("balanceOf", [account]),
        ],
        // [
        //   VotingEscrow.address.toLowerCase(),
        //   VotingEscrow.interface.encodeFunctionData("locked", [account]),
        // ],
      ];

      const {
        returnData,
        blockNumber,
      }: { returnData: string[]; blockNumber: BigNumber } =
        await multicallContract.aggregate(calldata);

      const [value] = VotingEscrow.interface.decodeFunctionResult(
        "balanceOf",
        returnData[0]
      );

      // console.log("value", value);
      // console.log("blockNumber", blockNumber.toNumber());
      // const timestamp = await getTimestamp(blockNumber.toNumber());
      return {
        value: value,
        blockNumber: blockNumber.toNumber(),
        // timestamp,
      };
    },
    {
      refetchInterval: 10_000,
      enabled: typeof account === "string",
    }
  );
  const { data: totalVeTINC, refetch: totalRefetch } = useDaoTotalVeTINC();

  const veValue = veValueInfo?.value;
  const veShare = useMemo(() => {
    if (veValue && totalVeTINC) {
      return (veValue.mul(1000000).div(totalVeTINC).toNumber() / 10000).toFixed(
        2
      );
    }
  }, [veValue, totalVeTINC]);

  const refetch = useCallback(() => {
    totalRefetch();
    veRefresh();
  }, [totalRefetch, veRefresh]);

  return {
    veValue,
    veShare: veShare ? veShare + "%" : "0%",
    totalVeTINC,
    veTinc: veValue ? getDisplayBalance(veValue, 18, 2, true) : "--",
    refetch,
  };
}
