import { CSSProperties, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useTiny from "src/hooks/useTiny";
import useMyNFTList from "src/hooks/useMyNFTList";

import styled from "styled-components";
import ParallelogramButton from "src/components/Button/ParallelogramButton";

import blindboxIcon from "src/images/sidebar/blindbox.png";
import marketIcon from "src/images/sidebar/market.png";
import useIsMobile from "src/hooks/useIsMobile";
import PetsEmptyIcon from "src/components/Svg/PetsIcon/PetsEmptyIcon";
import { useMyAllPets } from "src/hooks/usePetsHooks";

interface Props {
  type: "buy" | "select" | "empty"; // 一种是引导购买，一种是引导选择
  onClick?(): void;
  bgWhite?: boolean;
  style?: CSSProperties;
  notZoom?: boolean;
}
export default function NFTEmpty({
  type,
  onClick,
  bgWhite,
  style,
  notZoom,
}: Props) {
  const isMobile = useIsMobile();
  const history = useHistory();
  const { t } = useTranslation();
  // console.log(onClick);
  // console.log(type);
  const tiny = useTiny();
  const { data: result } = useMyAllPets();
  return (
    <StyledContainer
      style={{
        ...{
          zoom: notZoom ? 1 : isMobile ? 0.76 : 1,
        },
        ...style,
      }}
      type={type}
      bgWhite={bgWhite}
      onClick={onClick}
    >
      <StyledPetsEmptyIcon
        style={{
          width: 88,
        }}
      />
      {(type !== "empty" && type === "buy") || result.list.length === 0 ? (
        <Fragment>
          <StyledText className="special-ff">{t("You have no NFT")}</StyledText>
          <ParallelogramButton
            className="mt-10"
            style={{ width: "10.5rem" }}
            onClick={(e) => {
              e.stopPropagation();
              history.push("/marketplace/pets");
            }}
          >
            <StyledIconImg style={{ marginRight: 5 }} src={marketIcon} />
            {t("Marketplace")}
          </ParallelogramButton>
        </Fragment>
      ) : (
        type !== "empty" && (
          <StyledFuncBox>
            <StyledText className="special-ff mb-5">
              {t("Select Pets")}
            </StyledText>
            <StyledAdd />
          </StyledFuncBox>
        )
      )}
    </StyledContainer>
  );
}

const StyledFuncBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // transform: translate(0, -30px);
`;

const StyledContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 10px;

  width: 230px;
  height: 309px;
  border: ${({ type }) => (type === "select" ? "unset" : "2px solid #fdd456")};
  border-radius: 13px;
  // background-color: #;
  background-color: ${({ type, bgWhite }) =>
    type === "select" || bgWhite ? "#fffbdd" : "#fff2d0"};
  text-align: center;
  box-sizing: border-box;
  cursor: ${(props) => (props.type === "select" ? "pointer" : null)};

  > button + button {
    margin-top: 10px;
  }
`;

const StyledPetsEmptyIcon = styled(PetsEmptyIcon)`
  margin-bottom: 15px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 0;
  `}
`;

const StyledText = styled.div`
  font-size: 1.25rem;
`;

const StyledAdd = styled.div`
  /* margin: 20px auto 0; */
  box-sizing: border-box;
  position: relative;
  width: 36px;
  height: 36px;
  border: 1px solid #707070;
  border-radius: 0.3rem;
  ::before,
  ::after {
    content: " ";
    position: absolute;
    background-color: #514b45;
    border-radius: 2px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  ::before {
    width: 28px;
    height: 5px;
  }
  ::after {
    width: 5px;
    height: 28px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 0.4rem;
  `}
`;

const StyledIconImg = styled.img`
  margin-right: 2px;
  width: 24px;
  vertical-align: middle;
`;
