import React, { useRef, useEffect, useCallback, CSSProperties } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

interface Props {
  onClose?(): void;
  className?: string;
  style?: React.CSSProperties;
  disableScale?: boolean;
  children:
    | React.ReactElement
    | ((props: { handleClose(): void }) => React.ReactElement);
  boxStyle?: React.CSSProperties;
  background?: CSSProperties["background"];
}
export default function ExternalModal(props: Props) {
  const {
    onClose,
    className,
    style,
    children,
    disableScale,
    boxStyle,
    background,
  } = props;
  const rootRef = useRef(document.createElement("div"));
  const [sprProps, api] = useSpring(() => ({
    to: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0.5 },
  }));
  useEffect(() => {
    const element = rootRef.current;
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, []);
  const handleClose = useCallback(() => {
    if (onClose) {
      api({
        opacity: 0,
        scale: 0.8,
        config: {
          friction: 12,
          clamp: true,
        },
        onResolve: () => {
          onClose();
        },
      });
    }
  }, [onClose, api]);
  const content = (
    <StyledContainer className={className} style={style}>
      <AnimatedBackdrop
        onClick={handleClose}
        style={
          background
            ? { opacity: sprProps.opacity, background }
            : { opacity: sprProps.opacity }
        }
      />
      <AnimatedContent
        style={{
          ...(disableScale ? { opacity: sprProps.opacity } : sprProps),
          ...boxStyle,
        }}
      >
        {React.isValidElement(children) ? children : children({ handleClose })}
      </AnimatedContent>
    </StyledContainer>
  );
  return ReactDOM.createPortal(content, rootRef.current);
}

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  // z-index: 101;
`;

const StyledModalBackdrop = styled.div`
  background-color: #00000088;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

const AnimatedBackdrop = animated(StyledModalBackdrop);

const StyledModalContainer = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 3rem;
  `}
`;

const AnimatedContent = animated(StyledModalContainer);
