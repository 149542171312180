const Part_2 = [
  "2-part1",
  "2-part2",
  "2-part3",
  "2-part4",
  "2-part5",
  "2-part6",
  "2-part7",
  "2-part8",
  "2-part9",
  "2-part10",
  "2-part11",
  "2-part12",
  "2-part13",
  "2-part14",
  "2-part15",
  "2-part16",
  "2-part17",
  "2-part18",
  "2-part19",
  "2-part20",
  "2-part21",
  "2-part22",
  "2-part23",
  "2-part24",
  "2-part25",
  "2-part26",
  "2-part27",
  "2-part28",
  "2-part29",
  "2-part30",
  "2-part31",
  "2-part32",
  "2-part33",
  "2-part34",
  "2-part35",
];

export default Part_2;
