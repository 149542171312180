import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Range, getTrackBackground } from "../Range";
import { HeadHeroCard } from "src/components/HeadHeroCard";
import styled from "styled-components";
import { IHeroHead } from "../HeadHeroCard/type";
import useIsMobile from "src/hooks/useIsMobile";
import PrimaryButton from "../Button/PrimaryButton";
import { useTranslation } from "react-i18next";
import { IRangeConfig } from "./RaisePowerModal";
import { BigNumber } from "ethers";
import upgradePng from "src/images/upgrade.png";

import powerL from "src/images/power.svg";
import { getSpiritDeffer, getSpiritExpend } from "./const";
import { UpgradeConfigItem } from "src/services/cache";
import calcPower from "src/utils/calcPower";
import useUserWallet from "src/hooks/useUserWallet";

interface IHeroStaminaProps {
  index: number;
  hero: IHeroHead;
  config: IRangeConfig;
  setRangeConfigs: React.Dispatch<React.SetStateAction<IRangeConfig[]>>;
  allPropAmount: number;
  staminaItemNum: BigNumber;
  allConfig: UpgradeConfigItem[] | undefined;
}

function RaisePowerRange({
  index,
  hero,
  config,
  setRangeConfigs,
  allPropAmount,
  staminaItemNum,
  allConfig,
}: IHeroStaminaProps) {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();
  const [rangeDisabled, setRangeDisabled] = useState(!config.loaded);
  const upgradeConfig = useMemo(() => {
    return allConfig?.find((ele) => ele.grade === hero.gradeType);
  }, [allConfig, hero.gradeType]);
  const { lockSpiritBalance } = useUserWallet();

  const maxLevelPowerRange = useMemo(() => {
    return (
      upgradeConfig?.power.find((ele) => ele.level === 20) || {
        level: 0,
        lower: 0,
        upper: 0,
      }
    );
  }, [upgradeConfig]);

  const nowLevelPowerRange = useMemo(() => {
    return (
      upgradeConfig?.power.find((ele) => ele.level === hero.itemLevel) || {
        level: 0,
        lower: 0,
        upper: 0,
      }
    );
  }, [hero.itemLevel, upgradeConfig]);

  const setRangeValue = useCallback(
    (values: number[], isAmount?: boolean) => {
      if (!rangeDisabled) {
        setRangeConfigs((prevState) => {
          const propAmount = hero.initPower
            ? getSpiritDeffer({
                grade: hero.gradeType,
                initPower: hero.initPower,
                laferPower: hero.initPower + values[0],
              })
            : 0;
          return [
            ...prevState.slice(0, index),
            {
              ...prevState[index],
              values: values,
              propAmount,
              isMax: lockSpiritBalance
                ? !(
                    allPropAmount +
                      (isAmount ? propAmount - allPropAmount : 0) <=
                    staminaItemNum.toNumber() + lockSpiritBalance?.toNumber()
                  )
                : false,
            },
            ...prevState.slice(index + 1),
          ];
        });
      }
    },
    [
      allPropAmount,
      hero.gradeType,
      hero.initPower,
      index,
      lockSpiritBalance,
      rangeDisabled,
      setRangeConfigs,
      staminaItemNum,
    ]
  );

  const RenderRange = useMemo(() => {
    return (
      config && (
        <Range
          values={config.values}
          step={config.step}
          min={config.min}
          max={config.max}
          disabled={false}
          onChange={(values) => setRangeValue(values, true)}
          onFinalChange={(values) => setRangeValue(values)}
          renderTrack={({ props, children }) => (
            <StyledRenderTrack
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
              }}
            >
              <StyledRenderTrackChild
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: config.values,
                    // isMax={}
                    colors: [config.isMax ? "#CE3D3D" : "#FDDA7A", "#DBB87A"],
                    min: config.min,
                    max: config.max,
                  }),
                }}
              >
                {children}
              </StyledRenderTrackChild>
            </StyledRenderTrack>
          )}
          renderThumb={({ props, isDragged }) => {
            return (
              props.style.cursor !== "inherit" && (
                <StyledRenderButtonBox
                  {...props}
                  isMax={config.isMax}
                  style={{
                    ...props.style,
                  }}
                >
                  <StyledRenderTip isMax={config.isMax}>
                    <img
                      style={{
                        height: 18,
                      }}
                      className="mr-4"
                      src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
                      alt=""
                    />
                    {config.propAmount}
                  </StyledRenderTip>
                </StyledRenderButtonBox>
              )
            );
          }}
        />
      )
    );
  }, [config, setRangeValue]);

  const handlerMax = useCallback(() => {
    const spiritNum =
      staminaItemNum.toNumber() +
      (lockSpiritBalance ? lockSpiritBalance?.toNumber() : 0);

    if (allPropAmount - config.propAmount < spiritNum) {
      const balance = spiritNum - allPropAmount + config.propAmount;
      // console.log({
      //   balance,
      // });
      const toMax = (power: number) => {
        const sipritNum = getSpiritDeffer({
          grade: hero.gradeType,
          initPower: hero?.initPower || 0,
          laferPower: power,
        });
        // console.log({
        //   power: power - (hero?.initPower || 0) - 1,
        // });
        if (power < (hero?.initPower || 0) + config.max + 1) {
          if (sipritNum < balance) {
            power++;
            toMax(power);
          } else {
            setRangeValue(
              [power ? power - (hero?.initPower || 0) - 1 : 0],
              true
            );
          }
        } else {
          return setRangeValue(
            [power ? power - (hero?.initPower || 0) - 1 : 0],
            true
          );
        }
      };
      if (hero.initPower) toMax(hero.initPower);
    }
  }, [
    allPropAmount,
    config,
    hero,
    lockSpiritBalance,
    setRangeValue,
    staminaItemNum,
  ]);

  const addConfigValue = useCallback(() => {
    if (config.values[0] < config.max) {
      setRangeValue([config.values[0] + 1], true);
    }
  }, [config, setRangeValue]);

  const subConfigValue = useCallback(() => {
    if (config.values[0] > 0) {
      setRangeValue([config.values[0] - 1], true);
    }
  }, [config, setRangeValue]);

  return (
    <StyledContainer>
      <StyledHeroInfo>
        <HeadHeroCard
          style={{
            margin: "auto 0",
          }}
          height={isMobile ? 80 : undefined}
          {...hero}
          power={undefined}
        />
        <StyledPowerTransition className={isMobile ? "ml-4" : "ml-20"}>
          <div>
            <label
              style={{
                minWidth: "4.1rem",
                textAlign: "right",
              }}
            >
              Lv.1
            </label>
            <img src={powerL} alt="" />
            <label>{hero.initPower}</label>
            <img
              style={{
                height: 15,
                marginLeft: 10,
                marginRight: 10,
              }}
              className="ml-5 mr-5"
              src={upgradePng}
              alt=">"
            />
            <label htmlFor="">
              {(hero.initPower || 0) + (rangeDisabled ? 0 : config.values[0])}
            </label>
            <span className="ml-5">
              (+{rangeDisabled ? 0 : config.values[0]})
            </span>
          </div>
          {upgradeConfig && (
            <div>
              <label
                style={{
                  minWidth: "4.1rem",
                  textAlign: "right",
                }}
              >
                {t("Current")}
              </label>
              <img src={powerL} alt="" />
              <label>
                {calcPower(
                  hero.initPower || 0,
                  upgradeConfig?.base_power_lower || 0,
                  upgradeConfig?.base_power_upper || 0,
                  nowLevelPowerRange
                )}
              </label>
              <img
                style={{
                  height: 15,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                className="ml-5 mr-5"
                src={upgradePng}
                alt=">"
              />
              <label>
                {calcPower(
                  (hero.initPower || 0) +
                    (rangeDisabled ? 0 : config.values[0]),
                  upgradeConfig?.base_power_lower || 0,
                  upgradeConfig?.base_power_upper || 0,
                  nowLevelPowerRange
                )}
              </label>
              <span className="ml-5">
                (+
                {calcPower(
                  (hero.initPower || 0) +
                    (rangeDisabled ? 0 : config.values[0]),
                  upgradeConfig?.base_power_lower || 0,
                  upgradeConfig?.base_power_upper || 0,
                  nowLevelPowerRange
                ) -
                  calcPower(
                    hero.initPower || 0,
                    upgradeConfig?.base_power_lower || 0,
                    upgradeConfig?.base_power_upper || 0,
                    nowLevelPowerRange
                  )}
                )
              </span>
            </div>
          )}
          {upgradeConfig && (
            <div>
              <label
                style={{
                  minWidth: "4.1rem",
                  textAlign: "right",
                }}
              >
                Lv.Max
              </label>
              <img src={powerL} alt="" />
              <label>
                {calcPower(
                  hero.initPower || 0,
                  upgradeConfig?.base_power_lower || 0,
                  upgradeConfig?.base_power_upper || 0,
                  maxLevelPowerRange
                )}
              </label>
              <img
                style={{
                  height: 15,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                className="ml-5 mr-5"
                src={upgradePng}
                alt=">"
              />
              <label>
                {calcPower(
                  (hero.initPower || 0) +
                    (rangeDisabled ? 0 : config.values[0]),
                  upgradeConfig?.base_power_lower || 0,
                  upgradeConfig?.base_power_upper || 0,
                  maxLevelPowerRange
                )}
              </label>
              <span className="ml-5">
                (+
                {calcPower(
                  (hero.initPower || 0) +
                    (rangeDisabled ? 0 : config.values[0]),
                  upgradeConfig?.base_power_lower || 0,
                  upgradeConfig?.base_power_upper || 0,
                  maxLevelPowerRange
                ) -
                  calcPower(
                    hero.initPower || 0,
                    upgradeConfig?.base_power_lower || 0,
                    upgradeConfig?.base_power_upper || 0,
                    maxLevelPowerRange
                  )}
                )
              </span>
            </div>
          )}
        </StyledPowerTransition>
      </StyledHeroInfo>

      <StyledRangeBox>
        <StyledStaminaRange>
          <StyledStaminaRangeBox
            style={{
              width: "60%",
            }}
          >
            {RenderRange}
          </StyledStaminaRangeBox>
          <StyledStaminaRangeBox
            style={{
              display: "flex",
              alignItems: "flex-start",
              paddingTop: 2,
            }}
            className="ml-10"
          >
            <PrimaryButton
              onClick={subConfigValue}
              style={{
                width: 30,
                position: "unset",
                padding: 0,
              }}
              className="ml-10"
            >
              -
            </PrimaryButton>
            <PrimaryButton
              onClick={addConfigValue}
              style={{
                width: 30,
                position: "unset",
                padding: 0,
              }}
              className="ml-4"
            >
              +
            </PrimaryButton>
            <PrimaryButton
              onClick={handlerMax}
              style={{
                position: "unset",
              }}
              className="ml-4"
              width={isMobile ? "7rem" : "6rem"}
            >
              MAX
            </PrimaryButton>
          </StyledStaminaRangeBox>
        </StyledStaminaRange>
      </StyledRangeBox>
    </StyledContainer>
  );
}

export default RaisePowerRange;

const StyledHeroInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-start;
  // padding-left: 26px;
`;

const StyledPowerTransition = styled.div`
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-gap: 2px;
  // width: 80%;
  font-size: 1.2rem;

  div + div {
    margin-top: 4px;
  }

  div {
    display: flex;
    align-items: center;
    height: 1.6rem;

    img {
      height: 24px;
      margin-left: 4px;
      margin-right: 2px;

      ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        height: 19px;
      `}
    }

    label {
      min-width: 3.8rem;
      white-space: nowrap;
      text-align: center;
      // font-size: 1.1rem;
    }

    span {
      min-width: 3.8rem;
      color: rgb(45, 139, 63);
      white-space: nowrap;
      text-align: left;
      // font-size: 1.1rem;
    }
  }
`;

const StyledStaminaRangeBox = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 4px;
`;

const StyledStaminaRange = styled.div`
  display: flex;
`;

const StyledStamina = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.img`
  height: 30px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 20px;
  `}
`;

const StyledRangeBox = styled.div`
  width: 100%;
  // padding-left: 6px;
  min-height: 4.5rem;
  box-sizing: border-box;
  margin-top: 10px;
`;

const StyledRenderTip = styled.div<{ isMax: boolean }>`
  position: absolute;
  top: 20px;
  color: ${({ isMax }) => (isMax ? "#FFFFFF" : "#815920")};
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ isMax }) => (isMax ? "#D10000" : "#FDD770")};
  display: flex;
  align-items: center;
  :before {
    position: absolute;
    visibility: visible;
    content: "";
    background: inherit;
    width: 10px;
    height: 10px;
    top: 0;
    left: 50%;
    transform: rotate(45deg) translate(-55%, 19%);
    z-index: -1;
  }
`;

const StyledRenderButtonBox = styled.div<{ isMax: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 10px !important;
  background-color: ${({ isMax }) => (isMax ? "#D10000" : "#fdce4f")};
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid ${({ isMax }) => (isMax ? "#740000" : "#bc7a4a")};

  :focus-visible {
    outline: unset;
  }
`;

const StyledRenderTrackChild = styled.div`
  height: 7px;
  width: 100%;
  border-radius: 7px;
  align-self: center;
`;

const StyledRenderTrack = styled.div`
  display: flex;
  height: 36px;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 1rem 0 0.5rem 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem 0 1.5rem 0;
  `}
`;
