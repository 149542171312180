import { my_api_base, farm_api_base, overview_api_base } from "../constants";
import { request } from "./util";
import type {
  NFTBaseDetail,
  NFTInfo,
  NFTCoreInfo,
  HeroStaminaInfo,
  NFTDetail,
  PageParamBase,
  PageResult,
} from "./typings";
import { news_version } from "src/views/Home/const";

export type { NFTBaseDetail };

/* -------------- 从后端查询 NFT 相关信息 START ---------------- */

interface IdelNFTDetail extends NFTCoreInfo {
  StaminaInfo: HeroStaminaInfo;
}
/**
 * 查询对应 account 的闲置 nft（即不包含质押的等等）
 * @param address 要查询的地址
 * @param type type 1 是英雄
 */
export function getMyIdelNFTs(
  { address }: { address: string | undefined },
  options?: RequestInit
) {
  return request<{
    total_count: number;
    nfts: IdelNFTDetail[];
  }>(`${my_api_base}/nfts/idle?address=${address}`, options);
}

// interface AllNFTsParams extends ListParams {
//   address: string;
// }
// export function getAllNFTsByAccount(
//   params: AllNFTsParams,
//   options?: RequestInit
// ) {
//   const { address, grade, sort, pageNum, pageSize } = params;
//   const a = new URLSearchParams();
//   if (address) a.append("address", address);
//   if (grade) a.append("grade", grade.toString());
//   if (pageNum) a.append("pageNum", pageNum.toString());
//   if (pageSize) a.append("pageSize", pageSize.toString());
//   if (sort) {
//     a.append("sortBy", sort.key);
//     a.append("sortType", sort.order);
//   }
//   return request(`${my_api_base}/allnfts?${a.toString()}`, options);
// }

export function getAllNFTsByAccount(address: string, options?: RequestInit) {
  return request(`${my_api_base}/nfts/all?account=${address}`, options);
}

/**
 * 根据 id 查询 nft
 * @param id tokenId
 */
export function getNFTById(id: string | number, options?: RequestInit) {
  return request<NFTDetail>(`${my_api_base}/nfts/hero?id=${id}`, options);
}

interface NFTListRes {
  total_count: number;
  nfts: NFTInfo[];
}

/**
 * 这是质押挖矿中的 NFT，包含 LP 质押和 小队质押
 * @param address 要查询的地址
 */
export function getStakedNFTs(address: string, options?: RequestInit) {
  return request<NFTListRes>(
    `${my_api_base}/nfts/staked?address=${address}`,
    options
  );
}

export function getFarmingNFTs(address: string, options?: RequestInit) {
  return request<{
    total_count: number;
    nfts: (NFTCoreInfo & { StaminaInfo?: HeroStaminaInfo })[];
  }>(`${my_api_base}/nfts/farming?address=${address}`, options);
}

export function getBoostNFT(address: string, options?: RequestInit) {
  return request<NFTCoreInfo>(
    `${my_api_base}/nfts/boost?address=${address}`,
    options
  );
}

/**
 * 质押到游戏中的 NFT
 * @param address 要查询的地址
 */
export function getGamingNFTs(address: string, options?: RequestInit) {
  return request<NFTListRes>(
    `${my_api_base}/nfts/gaming?address=${address}`,
    options
  );
}

// 各个池子的质押情况
export interface PoolStakeInfo {
  pid: number;
  tvl: number;
  wantLockedTotal: string;
  baseApy: number;
  cakeApr: {
    // cakeRewardsApr: number;
    lpRewardsApr?: string;
  };
}

export interface StakeInfos {
  [pid: number]: PoolStakeInfo;
}

export interface LPFarmPowerInfo {
  max_power: number;
  mid_power: number;
  max_bonus: number;
  mid_bonus: number;
}
interface FarmInfos {
  stakeInfos: StakeInfos;
  tvlTotal: number;
  price: {
    [tokenSymbol: string]: number;
  };
  burned: string;
  burnedFor30Days: string;
  pool: LPFarmPowerInfo;
  tincAvgPrice?: number;
  tincAvgPriceFor3Days?: number;
  userCount: number;
  farmTincPerDay: number; //TINC产量 领主 40% NFTFarm 60%
}

/**
 * 查询 LP 矿池信息
 */
export function getFarmInfos() {
  return request<FarmInfos>(`${farm_api_base}/pools`);
}

export interface NewData {
  title: string;
  contents: string[];
}

export interface NewsRes {
  version: number;
  data: {
    upgrade: NewData[];
    activity: NewData[];
  };
}

const promiseCache: {
  news: Promise<NewsRes> | null;
  whitelist: Promise<string[]> | null;
} = {
  news: null,
  whitelist: null,
};

/**
 * 获取游戏补充白名单
 */
export function getWhitelist() {
  if (promiseCache.whitelist) {
    return promiseCache.whitelist;
  }
  const promise: Promise<string[]> = fetch("/data/whitelist.json").then((res) =>
    res.json()
  );
  promiseCache.whitelist = promise;
  return promise;
}

/**
 * 获取最新新闻
 */
export function getNews() {
  if (promiseCache.news) {
    return promiseCache.news;
  }
  const promise: Promise<NewsRes> = fetch(
    `/data/news.json?version=${news_version}`
  ).then((res) => res.json());
  promise.then((res) => {
    console.log(res);
  });
  promiseCache.news = promise;
  return promise;
}

export function getLiquidations(options?: RequestInit) {
  return request("/liquidations", options);
}

export function getMyCollections(address: string, options?: RequestInit) {
  return request<number[]>(
    `${my_api_base}/nfts/ownscode?address=${address}`,
    options
  );
}

// 获取我的nft兑换历史
// 获取回购销毁记录
/* 
  NFTInfo: {id: 20353, itemCode: 403, itemGrade: 4, itemLevel: 1, power: 112, initPower: 112}
  blockNumber: 28083828
  createdAt: "2023-03-16T04:02:23.000Z"
  id: 2
  lockedSpirit: 10
  tokenId: 20353
  tx: "0x8a4be123b628dc5ff82c557efd2ac932e0ba2b0effb28cc6320f553108b6b2bd"
*/
export function getExchangeRecords(
  { pageNum, pageSize, address }: { address: string } & PageParamBase,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    pageSize: pageSize ? pageSize.toString() : "12",
    pageNum: pageNum.toString(),
    address,
  });
  return request<
    {
      list: {
        createdAt: string;
        lockedSpirit: number;
        ssr: number;
        sssr: number;
        tx: string;
      }[];
    } & PageResult
  >(`${my_api_base}/nfts/exchange-records?${params.toString()}`, options);
}

export interface BuybacksItem {
  calcAmount: string;
  price: number;
  timestamp: number;
  tx: string;
  type: 1 | 2 | 3;
}

// 获取回购销毁记录
export function getBuybacks(
  { pageNum, pageSize }: PageParamBase,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    pageSize: pageSize ? pageSize.toString() : "10",
    pageNum: pageNum.toString(),
  });
  return request<
    {
      list: BuybacksItem[];
    } & PageResult
  >(`${overview_api_base}/buybacks?${params.toString()}`, options);
}

/**
 * 查询 TINC-SPIRIT LP 矿池信息
 */

//  52 * volumeUSD7Days * 0.03 * 0.1 / amountUSD * 100 === apr   13.13% === apr

// (volumeUSD7Days / 7 * 0.03 * 0.1 / amountUSD + 1) ** 365 * 100 = 114.07 === apy

//  amountUSD
export function getTincSpiritLp() {
  return request<{
    amountUSD: number;
    volumeUSD7Days: number;
  }>(`${farm_api_base}/swap-pool-info`);
}
