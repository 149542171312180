import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import Container from "src/components/Container";
import PageContent from "src/components/PageContent";
import GuideStep, { StepButton } from "./components/GuideStep";
import StepDetails from "./components/StepDetails";

import useUrlState from "@ahooksjs/use-url-state";

export type GuideContext = {
  select: number;
  setSelect: Dispatch<SetStateAction<number>>;
};

export const Context = createContext<GuideContext>({} as GuideContext);

const Guide: FC = () => {
  const [search, setSearch] = useUrlState<{ select: number }>({ select: 0 });
  const [selected, setSelected] = useState<number>(
    search.select ? Number(search.select) : 0
  );
  // const select = Number(new URLSearchParams(location.search).get("select"));
  // const [selected, setSelected] = useState<number>(select ? select : 0);
  const [showFixed, setShowFixed] = useState<boolean>(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowFixed(true);
    }, 540);
  }, []);

  useEffect(() => {
    setSearch({
      select: selected,
    });
  }, [selected, setSearch]);

  return (
    <Context.Provider value={{ select: selected, setSelect: setSelected }}>
      <PageContent>
        <Container
          style={{ position: "relative" }}
          className="display-flex flex-center flex-column"
          isZoom
        >
          <GuideStep showFixed={showFixed}></GuideStep>
          <StepDetails>
            <StepButton></StepButton>
          </StepDetails>
        </Container>
      </PageContent>
    </Context.Provider>
  );
};

export default Guide;
