import React, { useContext, useEffect, useMemo, useState } from "react";
import ExternalModal from "src/components/ExternalModal";
import Modal from "src/components/Modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  getSessionStorageByUser,
  setSessionStorageByUser,
} from "src/utils/storage";
import useTiny from "src/hooks/useTiny";
import historyIcon from "src/images/fluidity/history-icon.png";
import { ExternalLink } from "react-feather";
import useIsMobile from "src/hooks/useIsMobile";
import { FluidityContext } from "src/contexts/Market/FluidityContext";

export interface ISwapHistoryItem {
  value: string;
  url: string;
}

const swapHistorySessionKey = "swap_history";

export const getSwapHistory = (user: string) => {
  const swapHistoryCache = getSessionStorageByUser({
    user,
    key: swapHistorySessionKey,
  });
  console.log({
    swapHistoryCache,
  });
  const swapHistory = swapHistoryCache ? JSON.parse(swapHistoryCache) : [];

  return swapHistory;
};

export const setSwapHistoryLocaltion = ({
  user,
  value,
}: {
  user: string;
  value: ISwapHistoryItem;
}) => {
  // console.log({
  //   user,
  //   key: swapHistorySessionKey,
  //   value1: JSON.stringify(value),
  //   value2: [...getSwapHistory(user)],
  //   value: JSON.stringify([JSON.stringify(value), ...getSwapHistory(user)]),
  // });
  setSessionStorageByUser({
    user,
    key: swapHistorySessionKey,
    value: JSON.stringify([JSON.stringify(value), ...getSwapHistory(user)]),
  });
};

function useOperateHistory() {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const tiny = useTiny();
  const account = tiny.myAccount;

  const { swapHistory } = useContext(FluidityContext);

  return {
    component: (
      <>
        {open && (
          <ExternalModal
            disableScale
            boxStyle={{
              width: 450,
            }}
            onClose={() => setOpen(false)}
          >
            {({ handleClose }) => (
              <Modal
                title={t("Recent Transactions")}
                style={isMobile ? { maxWidth: 340 } : { maxWidth: 450 }}
                onDismiss={handleClose}
              >
                <StyledContainer>
                  {swapHistory.length > 0 ? (
                    swapHistory.map(({ value, url }, index) => {
                      return (
                        <a
                          key={index}
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={historyIcon} alt="" />
                          <span>{value}</span>
                          <ExternalLink size={14} />
                        </a>
                      );
                    })
                  ) : (
                    <>{t("No recent transactions")}</>
                  )}
                </StyledContainer>
              </Modal>
            )}
          </ExternalModal>
        )}
      </>
    ),
    open,
    setOpen,
  };
}

export default useOperateHistory;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
  max-height: 223px;
  overflow-y: auto;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 12px;
  color: #514b45;
  align-items: center;
  justify-content: flex-start;
  // font-family: HiraginoSans;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    background: none;
    padding: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fee397;
    border-radius: 5px;
  }

  > a {
    height: 20px;
    width: 100%;
    color: #514b45;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  > a:hover {
    span {
      text-decoration: underline;
    }
  }
`;
