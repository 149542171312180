import type { Reducer } from "react";
import type { Action } from "./typings";
import type { AuctionDetail, RuneAuction } from "src/services/auctions";
import { limit } from "src/config";
import { PetMarketInfo } from "src/services/pets";

export interface State<T> {
  list: T[];
}

export function addAction<T>(payload: T) {
  return { type: "add", payload };
}

export function deleteAction(id: number) {
  return { type: "delete", payload: { id } };
}

export function clearAction() {
  return { type: "clear", payload: null };
}

export const heroReducer: Reducer<State<AuctionDetail>, Action> = (
  state: State<AuctionDetail>,
  action: Action
) => {
  switch (action.type) {
    case "add":
      return {
        list:
          state.list.length === limit.MAX_SHOPPINGCART_NUMBER
            ? state.list
            : state.list.concat(action.payload),
      };
    case "delete":
      return {
        list: state.list.filter((ele) => ele.id !== action.payload.id),
      };
    case "clear":
      return {
        list: [],
      };
    default:
      throw new Error("type error");
  }
};

export const spiritReducer: Reducer<State<RuneAuction>, Action> = (
  state: State<RuneAuction>,
  action: Action
) => {
  switch (action.type) {
    case "add":
      return {
        list:
          state.list.length === limit.MAX_SHOPPINGCART_NUMBER
            ? state.list
            : state.list.concat(action.payload),
      };
    case "delete":
      return {
        list: state.list.filter((ele) => ele.id !== action.payload.id),
      };
    case "clear":
      return {
        list: [],
      };
    default:
      throw new Error("type error");
  }
};

export const petsReducer: Reducer<State<PetMarketInfo>, Action> = (
  state: State<PetMarketInfo>,
  action: Action
) => {
  switch (action.type) {
    case "add":
      return {
        list:
          state.list.length === limit.MAX_SHOPPINGCART_NUMBER
            ? state.list
            : state.list.concat(action.payload),
      };
    case "delete":
      return {
        list: state.list.filter((ele) => ele.id !== action.payload.id),
      };
    case "clear":
      return {
        list: [],
      };
    default:
      throw new Error("type error");
  }
};
