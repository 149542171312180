import config from "../config";

const multicallAbi = require("./abis/multicall.json");
const airdropNewAbi = require("./abis/airdropNew.json");
const lordRewardAbi = require("./abis/lordReward.json");
const nftStaminaAbi = require("./abis/TinyNFTStaminaAbi.json");
const checkinRewardAbi = require("./abis/checkinReward.json");
const nftLogicAbi = require("./abis/nftLogic.json");
const petsAbi = require("./abis/pets.json");
const petLogicAbi = require("./abis/petLogic.json");
const petMarketAbi = require("./abis/petMarket.json");
const babtAbi = require("./abis/BABT.json");
const petPresaleAbi = require("./abis/PetPresale.json");
const tinyLordAbi = require("./abis/TinyLord.json");
const petsFarmingAbi = require("./abis/petsFarming.json");
const tinyNFTRuneAbi = require("./abis/TinyNFTRune.json");
const lordOnlineRewardAbi = require("./abis/lordOnlineReward.json");
const MasterChefV2Abi = require("./abis/MasterChefV2.json");
const ITinySwapRouterAbi = require("./abis/ITinySwap.json");
const TinyLPBoostMintAbi = require("./abis/TinyLPBoostMint.json");

const contractAddresses = {
  masterChefV2: {
    97: "",
    56: "0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652",
  },
  // sousChef: {
  //   56: "0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95",
  //   97: "0xeBcc46C801284c92AF72afA7840296C022d78c11",
  // },
  multiCall: {
    56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
  },
  airdropNew: {
    97: "0x98894742ff17a579591219F02Df181832a85d376",
    56: "0x0E1BdA3A62Bbb7644b750C00F8cD01beA59C9E28",
  },
  lordReward: {
    97: "0xeF003BdfFEaaF919577ff96041B4E21CDB8851d9",
    56: "0x1cA3b8638708B026e004c389986e94aB145fF07e",
  },
  TinyNFTStamina: {
    97: "0xd6552594bcC5b591F7fa8E14A39daA9a99555672",
    56: "0x7Cd5ACCb3f7EB56972CefC1607d9E4CAbDa806Cb",
  },
  checkinReward: {
    56: "0x90b399A69eC07Ba3b2D9735bD2EE86014f2b936C",
    97: "0x57c02FcE6f2821f1fF807494F3c8d0DD07C9e07f",
  },
  nftLogic: {
    56: "0xa8A10882f9043389b7E0e09bb830bB541a69e3Bb",
    97: "0x0750647764992D30Dbd732A93285E97770Aa1CA7",
  },
  pets: {
    56: "0x3FD9651b584BB026000787003B387C7199f66a78",
    97: "0x8CDBa1FBBEa1e364DA5e7cf027098fFF8b4d9138",
  },
  petLogic: {
    97: "0xb168F27aE04846807662DaE03f8CE00827ecafb4",
    56: "0x8d81a65f82e2c6e78CDC6B29D298ADC81278c27d",
  },
  petMarket: {
    56: "0xc1028cf806803793C13c423611fB52708b1B7F53",
    97: "0xEa11C3A3eFB8a7Da3c749742DAaB108bb5b9282A",
  },
  petsFarming: {
    56: "0x9f1eb65a53b854b6860517F1A0aDD5CCed36C52F",
    97: "0x2EbE168f3a263F94F1eC59a14A38ceca38Bb509a",
  },
  babt: {
    97: "0xc177Dab961b7731E2D2F5D81538fbe7fEe480DD1",
    56: "0x2B09d47D550061f995A3b5C6F0Fd58005215D7c8",
  },
  petPresale: {
    56: "0x146C52DC41724FD498Fc2C35b76D6985d33832b6",
    97: "0xd6853b7777f739385AbC37bc264B0137FB45278a",
  },
  tinyLord: {
    97: "0x6fBdCA7dC9CDe341B93FA6A023a84d3324C08978",
    56: "0xd0f13Ec60b509a111ce59aAe73d239aCD807C914",
  },

  // SPIRIT 地址
  tinyNFTRune: {
    97: "0x15cc9036189263528BA509aA8CE20A17f69a94D9",
    56: "0x37e678d782d38a75EB16130b39C4ce2d7E3cC808",
  },
  lordOnlineReward: {
    97: "0xe55a0D222740ec6bADA63B2886e4798AE141F723",
    56: "0x2343dA4BdffB67B1396dFbA2194E6a9265F4eF90",
  },
  ITinySwapRouter: {
    97: "0xc9bb1C18968100E932277A05f04A1d6579B70722",
    56: "0x72376eE645bC50E4B23Fd57864Da483f8C0112Ce",
  },
  TinyLPBoostMint: {
    97: "0x29a92F269075ea48855EcF29c6abd454ddaeC863",
    56: "0x1428A515a559234DD5620Dcb5938C0f0cc898A90",
  },
};

const contractInfoMap: {
  [key: string]: {
    abi: any;
    address: string;
  };
} = {
  ...config.deployments,
  multicall: {
    abi: multicallAbi,
    address: contractAddresses.multiCall[config.chainId],
  },
  airdropNew: {
    abi: airdropNewAbi,
    address: contractAddresses.airdropNew[config.chainId],
  },
  // 老的 lord 排行榜奖励
  lordReward: {
    abi: lordRewardAbi,
    address: contractAddresses.lordReward[config.chainId],
  },
  TinyNFTStamina: {
    abi: nftStaminaAbi,
    address: contractAddresses.TinyNFTStamina[config.chainId],
  },
  CheckinReward: {
    abi: checkinRewardAbi,
    address: contractAddresses.checkinReward[config.chainId],
  },
  TinyNFTLogic: {
    abi: nftLogicAbi,
    address: contractAddresses.nftLogic[config.chainId],
  },
  Pets: {
    abi: petsAbi,
    address: contractAddresses.pets[config.chainId],
  },
  PetLogic: {
    abi: petLogicAbi,
    address: contractAddresses.petLogic[config.chainId],
  },
  PetsMarket: {
    abi: petMarketAbi,
    address: contractAddresses.petMarket[config.chainId],
  },
  PetsFarming: {
    abi: petsFarmingAbi,
    address: contractAddresses.petsFarming[config.chainId],
  },
  BABT: {
    abi: babtAbi,
    address: contractAddresses.babt[config.chainId],
  },
  PetPresale: {
    abi: petPresaleAbi,
    address: contractAddresses.petPresale[config.chainId],
  },
  TinyLord: {
    abi: tinyLordAbi,
    address: contractAddresses.tinyLord[config.chainId],
  },
  TinyNFTRune: {
    abi: tinyNFTRuneAbi,
    address: contractAddresses.tinyNFTRune[config.chainId],
  },
  // 新的 LordOnline 奖励领取
  LordOnlineReward: {
    abi: lordOnlineRewardAbi,
    address: contractAddresses.lordOnlineReward[config.chainId],
  },
  // TinySwap 用于
  ITinySwapRouter: {
    abi: ITinySwapRouterAbi,
    address: contractAddresses.ITinySwapRouter[config.chainId],
  },
  TinyLPBoostMint: {
    abi: TinyLPBoostMintAbi,
    address: contractAddresses.TinyLPBoostMint[config.chainId],
  },
};

if (config.chainId == 56) {
  contractInfoMap.MasterChefV2 = {
    abi: MasterChefV2Abi,
    address: contractAddresses.masterChefV2[config.chainId],
  };
}

// console.log('contractInfoMap', contractInfoMap);

export default contractInfoMap;
