import part_1 from "./Part_1";
import part_2 from "./Part_2";
import part_3 from "./Part_3";
import part_4 from "./Part_4";
import part_5 from "./Part_5";
import part_6 from "./Part_6";

const PetParts = { part_1, part_2, part_3, part_4, part_5, part_6 };

export default PetParts;
