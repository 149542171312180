/**
 * 用来说明LP质押NFT的加成规则的
 */

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal, { ModalProps } from "src/components/Modal";
import GradeIcon from "../GradeIcon";

const iconHeight = "100%";

export default function LPStakeRuleModal(props: ModalProps) {
  const { onDismiss } = props;
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Modal size="sm" title={t("Help")} onDismiss={onDismiss}>
        <StyledLPStakeContainer>
          <p className="mt-10 mb-10 rfs-10">{t("LP desc1")}</p>

          <StyledTableContainer>
            <StyledHeader>
              <StyledLeft>{t("heroGrade")}</StyledLeft>
              <div>{t("LP bonus rules")}</div>
            </StyledHeader>
            <StyledContent>
              <div>
                <GradeIcon height={iconHeight} grade={1} />
              </div>
              <div>{`0.05*Power/11`}</div>
              <div>
                <GradeIcon height={iconHeight} grade={2} />
              </div>
              <div>{`0.02+0.08*Power/46`}</div>
              <div>
                <GradeIcon height={iconHeight} grade={3} />
              </div>
              <div>{`0.05+0.1*Power/180`}</div>
              <div>
                <GradeIcon height={iconHeight} grade={4} />
              </div>
              <div>{`0.1+0.45*(Power-90)/1910`}</div>
              <div>
                <GradeIcon height={iconHeight} grade={5} />
              </div>
              <div>{`0.35+0.65*(Power-400)/5800`}</div>
              <div>
                <GradeIcon height={iconHeight} grade={6} />
              </div>
              <div>{`0.65+0.95*(Power-1200)/14800`}</div>
            </StyledContent>
          </StyledTableContainer>
          <div className="mt-30">
            {/* <div className="text-center special-ff rfs-14 mb-10">{t("Example")}</div> */}
            <div>{t("LP desc2")}</div>
          </div>
        </StyledLPStakeContainer>
      </Modal>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  max-width: 600px;
`;

const StyledTableContainer = styled.div`
  background-color: #26221f;
  border-radius: 1rem;
  line-height: 1.4;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: #fffbdd;
  overflow-y: hidden;
`;

const StyledLPStakeContainer = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 12px;
  `}
`;

const StyledGridBase = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  text-align: center;
  > div:nth-child(odd) {
    border-right: 1px solid ${(props) => props.theme.color.border};
  }
  > div {
    height: 1.3rem;
    padding: 4px;
  }
`;

const StyledHeader = styled(StyledGridBase)`
  padding: 3px 0;
  background-color: #fedb8d;
`;

const StyledLeft = styled.div``;

const StyledContent = styled(StyledGridBase)`
  padding: 5px 0;
  border-top: 1px solid ${(props) => props.theme.color.border};
`;
