import { useMemo } from "react";
import { useQuery } from "react-query";
import { runeAuctionSortList } from "src/components/SortNft/sortList";
import { ExchangeToken } from "src/contexts/Market/FluidityContext";
import useFarmPrice from "src/hooks/useFarmPrice";
import useFluidity from "src/hooks/useFluidity";
import { getAllRuneAuction, RuneAuction } from "src/services/auctions";
import { formatMarketPrice, trimZero } from "src/utils";
import { getDisplayBalance } from "src/utils/formatBalance";

// 获取体力回复道具最低价格
export default function useSpiritPrice() {
  const { getAmountsOut } = useFluidity();

  const requestExchange: ExchangeToken[] = ["SPIRIT", "TINC"];

  const [price] = useFarmPrice();

  const { data, refetch: spiriteRefresh } = useQuery<any>(
    "StaminaItemMarketPrice",
    async ({ signal }) => {
      return getAmountsOut({
        amountIn: "1",
        addressArr: requestExchange,
        inType: requestExchange[0],
      });
    },
    { keepPreviousData: true, refetchInterval: 60_000 }
  );

  const tincExchangeUnitSpirit = data
    ? Number(getDisplayBalance(data[1], 18, 18))
    : 0;

  const tincPrice = price.TINC;

  const spiritPrice = useMemo(() => {
    return tincExchangeUnitSpirit > 0
      ? trimZero((tincPrice * tincExchangeUnitSpirit).toFixed(5))
      : "--";
  }, [tincExchangeUnitSpirit, tincPrice]);

  // console.log({
  //   tincExchangeUnitSpirit,
  //   tincPrice,
  //   spiritPrice,
  // });

  return {
    spiritPrice,
    spiriteRefresh,
  };
}
