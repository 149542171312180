import dayjs from "dayjs";
import { useContext } from "react";
import { useQuery } from "react-query";
import { getFarmInfos } from "src/services";
import { _endOfWeek } from "src/utils/daoWeekCalc";
import { Context } from "../contexts/TinyProvider";

const useTiny = () => {
  const { tiny } = useContext(Context);
  return tiny;
};

const farmCacheKey = "FARM_POOL_INFO";

export const ActiveEndTime = 1677139200;

export const useTINCPerDay = () => {
  const { data: farmInfos } = useQuery(farmCacheKey, getFarmInfos, {
    refetchInterval: 300_000,
  });

  // 当前时间
  const timestamp = dayjs().unix();

  return {
    nftFarmTINCPerDay: Math.floor((farmInfos?.farmTincPerDay || 0) * 0.6),
    lordTINCPerDay:
      Math.floor((farmInfos?.farmTincPerDay || 0) * 0.4) *
      (timestamp >= ActiveEndTime ? 1 : 2), //奖励临时加倍
  };
};

export default useTiny;
