import { CSSProperties, ReactNode } from "react";
import { PetAttr, PetPartsGrade } from "src/components/NFTCard/PetCard/type";
import { BaseSvgProps } from "../types";

export interface Props extends BaseSvgProps {
  grade?: PetPartsGrade;
  prop: keyof PetAttr | string;
  lock?: boolean;
  blackBg?: boolean;
  boxStyle?: CSSProperties;
  defaulted?: boolean;
}

type PetPropNodes = Record<keyof PetAttr, ReactNode>;

export const petPropNodes: PetPropNodes = {
  part_1: (
    <>
      <g opacity="0.35">
        <path
          d="M15.31,11.63A17.52,17.52,0,0,0,7.69,9.05a16.91,16.91,0,0,0-1.1,2.87H5.93A1.67,1.67,0,0,0,4.38,13a12.91,12.91,0,0,0-.73,3.14c.13.4.26.75.35,1,2.18,6.41,4.8,17.64,15.88,17.15,4.05-.18,9.51-2.51,14.76-5.74.26.06.54.1.82.14A26.05,26.05,0,0,0,37.36,26C30,22.6,20.63,15.38,15.31,11.63Z"
          fill="#0a210b"
        />
        <path
          d="M21.6,13.63l6.06,1.95a.77.77,0,0,0,1-.52l1.09-4.72a.72.72,0,0,0-1.19-.64L26,11.7a.8.8,0,0,1-.4.17l-3.84.46A.66.66,0,0,0,21.6,13.63Z"
          fill="#0a210b"
        />
        <path
          d="M27.62,18.08l4.87,1.54a.6.6,0,0,0,.78-.41l.9-3.8a.57.57,0,0,0-1-.5l-2,1.61a.7.7,0,0,1-.32.13L27.77,17A.54.54,0,0,0,27.62,18.08Z"
          fill="#0a210b"
        />
        <path
          d="M37.81,21.24,37,20.92c0-.35.09-.68.12-1l-.51.4a.56.56,0,0,1-.26.11l-2.49.31a.43.43,0,0,0-.12.85l3.91,1.24a.51.51,0,0,0,.64-.34l.18-.77A1.59,1.59,0,0,0,37.81,21.24Z"
          fill="#0a210b"
        />
      </g>
      <path
        d="M21.78,33.31c-9.94.28-12.32-10.89-14.26-17.17-.48-1.57-1.92-6-1.92-6,.2-1.24,1.67-.64,3.91-.7,4.66-.14,11.59,6.5,26,15.25l1.36,1.09-1.66,2C30.42,30.92,25.47,33.2,21.78,33.31Z"
        fill="#470b00"
      />
      <path
        d="M7.47,10.76C8,7.43,20.73,12.55,34.05,26.07,11.35,41.38,10.54,13.6,7.47,10.76Z"
        fill="#ffd560"
      />
      <path
        d="M27.84,29.44a10.26,10.26,0,0,0,3.88-5.13l.16-.38Q30,22.12,28.1,20.52l-.11,0h0a91.61,91.61,0,0,0-7.86-5.28c-1.48-.9-2.69-1.59-3.79-2.18l-.63-.34-.52.49A10.52,10.52,0,0,0,11.89,21a11.14,11.14,0,0,0,.19,1.84C14.7,28.32,19.12,32.89,27.84,29.44Z"
        fill="#ffe582"
      />
      <path
        d="M30.78,24a9.21,9.21,0,0,1-8.55,6.27A9.32,9.32,0,0,1,12.89,21a9.46,9.46,0,0,1,3-7c1.26.67,2.52,1.41,3.75,2.15a92.68,92.68,0,0,1,7.76,5.22c.7.53,2.48.18,2.67.4C30.51,22.18,29.89,23.5,30.78,24Z"
        fill="#ff7200"
      />
      <path
        d="M7.47,10.76c.65.6,1.2,2.32,1.88,4.51a15.26,15.26,0,0,1,8.88,3.59c2.92,2.67,6.94,7.48,11.47,9.75a36.34,36.34,0,0,0,4.35-2.54C20.73,12.55,8,7.43,7.47,10.76Z"
        fill="#231815"
        opacity="0.43"
      />
      <path
        d="M2.94,10a1,1,0,0,1,.24-1.9c4.54-.27,10.63,0,14.21,2.75,5.24,4,15.09,12.37,22.15,15.33a1,1,0,0,1-.28,1.87c-5.13.46-8.35-2.17-10-3.48C27.34,23.15,14.37,13.51,2.94,10Z"
        fill="#470b00"
      />
      <path
        d="M20.08,16.28s2,2.76,1.28,4.08-2.41.3-2.41.3.56,5.53-2.7,7c3.25-.17,5.42-3.27,8.12-2.67-1.46-3,2.25-5.28,3.6-4.48S20.08,16.28,20.08,16.28Z"
        fill="#470b00"
      />
      <ellipse
        cx="22.27"
        cy="27.43"
        rx="1.34"
        ry="1.87"
        transform="translate(-8.58 44.66) rotate(-80.1)"
        fill="#ffe582"
      />
      <path
        d="M18.57,26.45c1.21-.39-.55-2.16-1.29-4.42s-.16-4.17-1.36-3.78-1.59,2.54-.85,4.81S17.37,26.84,18.57,26.45Z"
        fill="#ffe582"
      />
      <path
        d="M23,12.85l5.45,1.93a.67.67,0,0,0,.87-.5l.88-4.62A.66.66,0,0,0,29.11,9L26.89,11a.66.66,0,0,1-.35.16l-3.42.43A.66.66,0,0,0,23,12.85Z"
        fill="#470b00"
      />
      <path
        d="M28.47,17.24l4.43,1.57a.53.53,0,0,0,.7-.4l.72-3.76a.54.54,0,0,0-.88-.5l-1.8,1.58a.5.5,0,0,1-.29.12l-2.77.36A.53.53,0,0,0,28.47,17.24Z"
        fill="#470b00"
      />
      <path
        d="M34.09,20.77l3.62,1.28a.43.43,0,0,0,.57-.33l.58-3.06a.44.44,0,0,0-.72-.41l-1.46,1.29a.43.43,0,0,1-.24.1l-2.26.29A.43.43,0,0,0,34.09,20.77Z"
        fill="#470b00"
      />
    </>
  ),
  part_2: (
    <>
      <path
        d="M38.68,22a1.64,1.64,0,0,0-.87-.79L37,20.92c.08-.52.12-1,.15-1.5L37,19.36a16.48,16.48,0,0,0,.17-1.91c0-.58-.08-1.12-.14-1.6a10.52,10.52,0,0,0-1-1.51.5.5,0,0,0-.39-.16.62.62,0,0,0-.42.18L33.64,16A14.3,14.3,0,0,0,25,9.8c-3.79-.91-8-.28-12.56,1.84a16.49,16.49,0,0,0-6.74,5.5,17.05,17.05,0,0,0,.17,2.77l-.76.3a1.51,1.51,0,0,0-.68.54A7.32,7.32,0,0,0,4.53,23,22.13,22.13,0,0,0,6.08,25.6h0a.41.41,0,0,0,.31-.45,4.83,4.83,0,0,1,1-3.93A8.25,8.25,0,0,1,13,19.14a10.54,10.54,0,0,1,8.33,3.8,3.31,3.31,0,0,1,.25,4.57,6.38,6.38,0,0,1-5,2.2,2.13,2.13,0,0,1-2-1.57.37.37,0,0,0-.31-.26.58.58,0,0,0-.46.09,3.67,3.67,0,0,0-1.45,2.32,2.76,2.76,0,0,0,1.27,2.35,11.18,11.18,0,0,0,7.84,2.45,20.9,20.9,0,0,0,8.8-2.88A27.46,27.46,0,0,0,38.68,22Z"
        fill="#0a210b"
        opacity="0.35"
      />
      <path
        d="M5.07,21.36s-.49-3.09,1.17-5.08c2.41-2.89,9.55-4,14.31,3.27,4.57,6.94-6.31,11.89-7.47,6.15a3.33,3.33,0,0,0-.16,5.08c3.27,3.52,8.12,5.42,15.7.62,12.78-8.1,5-20.29,5-20.29L31.7,13.5S25.92-.63,11.79,6.05C.2,12,5.07,21.36,5.07,21.36Z"
        fill="#ffd560"
      />
      <path
        d="M5.22,15.9S9,6.66,20,9.46a12.15,12.15,0,0,1,8.18,6.91c1.3,3.36-1.28,6.72-1.28,6.72l-2.35-5.75-7.4-4.75-4.76-.95Z"
        fill="#ffe582"
      />
      <path
        d="M29.7,25.46c-.52,1.34-1.46,7.35-10.26,8.77-.27,0-.53-.06-.78-.1a9,9,0,0,1-2.17-.68,11.42,11.42,0,0,1-3.58-2.67,3.34,3.34,0,0,1,.17-5.09c0,.12.05.23.08.33a2.86,2.86,0,0,0,1.1,1.62c3,2.18,10.14-2.25,6.29-8.09-4.76-7.25-11.9-6.16-14.31-3.27C4.68,18.15,5,21,5.06,21.33c-.15-.58-2.37-10,8.58-11.06,9.77-1,15,10.51,13.27,12.82C29.38,21.92,30.06,24.56,29.7,25.46Z"
        fill="#ff7200"
      />
      <path
        d="M20.3,35.28a10.84,10.84,0,0,1-8.12-3.82A4.5,4.5,0,0,1,10.8,28a4.74,4.74,0,0,1,1.65-3.12,1,1,0,0,1,1-.17,1,1,0,0,1,.64.75,1.91,1.91,0,0,0,1.55,1.66A4.71,4.71,0,0,0,20,25.08c.69-1,1.21-2.73-.27-5-2-3.07-4.74-4.85-7.66-5A6.24,6.24,0,0,0,7,16.92c-1.33,1.6-1,4.26-1,4.28a1,1,0,0,1-1.88.62,11.88,11.88,0,0,1-.61-8.48C4.63,10,7.24,7.26,11.33,5.16,15.84,3,20,2.68,23.73,4.13a16.82,16.82,0,0,1,8.17,7.54l1-1.19a1,1,0,0,1,.84-.37,1,1,0,0,1,.78.47c.15.22,3.55,5.67,2.16,11.9-.86,3.88-3.38,7.16-7.48,9.76A16.84,16.84,0,0,1,20.3,35.28ZM12.89,27.7a1.93,1.93,0,0,0-.09.49,2.59,2.59,0,0,0,.85,1.91c3.78,4.07,8.51,4.21,14.44.45,9.57-6.06,6.87-14.47,5.38-17.64l-1,1.22a1,1,0,0,1-1.7-.25c0-.06-2.45-5.83-7.78-7.89-3.19-1.24-6.81-.91-10.77,1-5.5,2.83-7,6.43-7.17,9.27.13-.2.27-.39.42-.58a8.18,8.18,0,0,1,6.71-2.56c3.56.21,6.83,2.31,9.21,5.92a6.26,6.26,0,0,1,.25,7.19,6.7,6.7,0,0,1-6.42,2.93A3.8,3.8,0,0,1,12.89,27.7Z"
        fill="#470b00"
      />
    </>
  ),
  part_3: (
    <>
      <path
        d="M33.92,17.52a4.51,4.51,0,0,0-.11-4.94c-.79-1.13-2.41-2.45-5.76-2.71a2.44,2.44,0,0,0-.88-1.1,20.34,20.34,0,0,0-2.82-1.62l-1.16.13,0,0-.33,0-.78-1.06a18.54,18.54,0,0,0-3.26-.75L17.51,7.28l-.49,0,0,0a19.48,19.48,0,0,1-2.67-.38,3.11,3.11,0,0,1-.75-.73l-1.71-1a1.68,1.68,0,0,0-2.14.47,20.89,20.89,0,0,0-3.12,6.25H5.92a1.65,1.65,0,0,0-1.54,1,15.85,15.85,0,0,0-.8,6.93,2.5,2.5,0,0,0,.46.57C2.41,23,2.92,24.9,3.76,26.11s2.31,2.37,5.42,2.7c3.59,3,7.42,4.72,12,4.58a15.94,15.94,0,0,0,6.17-1.78C33,28.94,35.83,23.35,33.92,17.52Z"
        fill="#0a210b"
        opacity="0.35"
      />
      <path
        d="M36.65,11.76c-.53-1.2-1.84-2.6-5-2.9A2,2,0,0,0,31,7.71,14.45,14.45,0,0,0,21.33,4a12.94,12.94,0,0,0-1.77.12,4.92,4.92,0,0,0-7.37,2.28,4.54,4.54,0,0,0-.27,1.07A14.56,14.56,0,0,0,6.83,18.39a2,2,0,0,0,.43,1.22C5.35,22.13,5.48,24,6,25.26s1.67,2.42,4.41,2.82A14.5,14.5,0,0,0,21.34,33a14.34,14.34,0,0,0,5.84-1.24A14.57,14.57,0,0,0,35.73,17C36.93,15.2,37.38,13.42,36.65,11.76Z"
        fill="#470b00"
      />
      <circle cx="16.78" cy="8.19" r="2.93" fill="#ffd560" />
      <path
        d="M18.47,12a29.48,29.48,0,0,0-9.64,6.4A12.5,12.5,0,0,1,29.67,9.2,29.64,29.64,0,0,0,18.47,12Z"
        fill="#ffd560"
      />
      <path
        d="M26.38,30a12.49,12.49,0,0,1-16-5.42c3,.33,7.61-.62,12.41-2.74s8.59-4.87,10.38-7.29A12.49,12.49,0,0,1,26.38,30Z"
        fill="#ffd560"
      />
      <ellipse
        cx="21.34"
        cy="18.51"
        rx="14.73"
        ry="5.35"
        transform="translate(-5.65 10.18) rotate(-23.79)"
        fill="#ffd560"
      />
      <path
        d="M34.82,12.57c-.77-1.75-4-2.17-8.13-1.37,1.86-.28,2.61.66,2.7,1.38.1.89-.17,1.47-1.05,2.92a26.84,26.84,0,0,1-1.65,9.14l.3,5a12.49,12.49,0,0,0,6.66-13.24C34.81,15,35.27,13.6,34.82,12.57Z"
        fill="#ff7200"
      />
      <path
        d="M27.32,23.73l.85-3.25a1.65,1.65,0,0,0-2.26-1.93l-3.83,1.69a1.65,1.65,0,0,0-.1,3l3,1.56A1.66,1.66,0,0,0,27.32,23.73Z"
        fill="#470b00"
      />
      <path
        d="M25.23,22.2a15.17,15.17,0,0,1,1.31,8.11"
        fill="none"
        stroke="#470b00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <ellipse
        cx="13.52"
        cy="11.8"
        rx="2.15"
        ry="1.54"
        transform="translate(-4.13 15.01) rotate(-51.4)"
        fill="#ffe582"
      />
      <path
        d="M31.6,16.24s-7-.91-14.83,5.89c4.45-6.69,12.9-8.45,12.9-8.45l4.51,1.73Z"
        fill="#ff7200"
      />
      <path
        d="M26.41,16.81c1.18-.31,1.95.18,1.83,1.33-.1.95,1.19-1.17,1.19-1.17s-.9-1.47-1.09-1.47S25.24,17.12,26.41,16.81Z"
        fill="#ff7200"
      />
      <path
        d="M28.21,14.08s1.09-.23,1.19-.95.66,1.61.66,1.61H28.11Z"
        fill="#ff7200"
      />
      <path
        d="M14.94,25.26c.6-1.35,1.67.57,3.38,1.33s3.05,1,2.45,2.38-2.1.87-3.81.1S14.34,26.6,14.94,25.26Z"
        fill="#ffe582"
      />
    </>
  ),
  part_4: (
    <>
      <path
        d="M35.06,29.17c-.16-.29-.3-.58-.46-.87l-.25-.45c-.19-.34-.38-.68-.58-1l-.24-.38c-.21-.34-.42-.69-.64-1l-.21-.31c-.23-.35-.46-.7-.71-1.05l-.17-.24c-.25-.36-.51-.71-.77-1.06l-.13-.16c-.28-.38-.57-.74-.86-1.1L30,21.42c-.31-.38-.62-.75-1-1.14A51.31,51.31,0,0,0,11.42,7.08L11.3,7,9.24,6.35a20.5,20.5,0,0,0-2.65,5.58H5.93a1.63,1.63,0,0,0-1.1.43,22.53,22.53,0,0,0,.08,2.41,1.5,1.5,0,0,0-1,.7c-.22.39-.8,1.44,1.65,6.65l-.21.06A1.32,1.32,0,0,0,4.53,24c1.59,3.9,7.56,8.8,8.61,9.64a2.3,2.3,0,0,0,.69.43,27.39,27.39,0,0,0,10.9,1.75h.35a25,25,0,0,0,2.55-.22A30.92,30.92,0,0,0,35.06,29.17Z"
        fill="#0a210b"
        opacity="0.35"
      />
      <path
        d="M25.16,35.81c-4,0-8.08-1-10.45-2.41a1.91,1.91,0,0,1-.66-.57c-.91-1-5.82-6.91-6.68-11.47A2,2,0,0,1,8.6,19.13l.06,0C7.24,13.17,8,12,8.26,11.49a1.89,1.89,0,0,1,1-.84,32.5,32.5,0,0,1,1.12-6.27L9.87,2.55a2,2,0,0,1,.54-2A2,2,0,0,1,11.8,0a1.86,1.86,0,0,1,.61.1c.09,0,2.14.7,4.45,1.71l.13.07c.35.18,8.7,4.67,15,15.46l0,.09c.23.39.48.83.72,1.28l.06.11c.23.42.46.86.68,1.3l.09.19c.21.42.41.85.61,1.28l.14.3c.18.4.36.82.54,1.24l.16.38c.16.4.32.81.47,1.22l.18.48c.15.4.29.82.43,1.24l.18.51c.18.56.36,1.14.52,1.73,0,0,0,.1,0,.11a2.07,2.07,0,0,1,0,1.13c-1.19,3.65-5.33,5.79-11.36,5.87Z"
        fill="#470b00"
      />
      <path
        d="M15.65,31.63S10.16,25.37,9.33,21a2.61,2.61,0,0,0,2.25,1.26S9.13,13.9,10,12.52a5.31,5.31,0,0,0,1.38,2.55A27.67,27.67,0,0,1,12.48,4.39L11.8,2s2,.66,4.25,1.64C17.62,5,15.65,31.63,15.65,31.63Z"
        fill="#ffd560"
      />
      <path
        d="M16.05,3.64S29.73,10.84,35,29.31c-2,6-14.88,5.18-19.31,2.32C15.65,31.63,6,15.26,16.05,3.64Z"
        fill="#ffd560"
      />
      <path
        d="M15.65,4.58S20.49,8,21.51,10.34s1.76,4.26,2.13,2.07c.2-1.17,2.4,5.51,1.24,8.66.56,5,.66,8.94-3,10.57C16.66,26.34,15.65,4.58,15.65,4.58Z"
        fill="#ffe582"
      />
      <path
        d="M23.51,27.14c1.33-7.25-9.25-22.28-9.25-22.28C9,18,15.65,31.64,15.65,31.64,19,32.25,22.58,32.16,23.51,27.14Z"
        fill="#ed7980"
      />
      <path
        d="M17.66,26.63a11.2,11.2,0,0,0,3.41,4.58c1.15-.68,1.94-1.94,2.44-4.07C26.2,15.48,14.26,4.86,14.26,4.86S14.05,18.9,17.66,26.63Z"
        fill="#f54044"
      />
      <path
        d="M20.63,30.86c.15.13.3.24.44.35a5.53,5.53,0,0,0,2.44-4.07,17.77,17.77,0,0,0-.92-7.66l-.42-.19S18.9,23.6,20.63,30.86Z"
        fill="#bc211c"
      />
      <path
        d="M23.18,29.35s2.12-4,.46-5.48a1.74,1.74,0,0,0-.69-.44,1,1,0,0,0-1.5,1.1L21.69,26l-2.6-1.45a.79.79,0,0,0-1.11,1L19.5,29.1l-2.43-.29a.58.58,0,0,0-.6.8C17.18,31.23,19.57,33.16,23.18,29.35Z"
        fill="#ffe582"
      />
      <path
        d="M21,30.6l-1.77-3.89a.35.35,0,0,1,.55-.4L23.51,29Z"
        fill="#ffd560"
      />
      <path
        d="M21.87,28.25l-.6-2a.52.52,0,0,1,.85-.53l1.67,1.58Z"
        fill="#ffd560"
      />
      <path
        d="M20.9,30.86,19.79,30a.35.35,0,0,1,.25-.62l1.53.2Z"
        fill="#ffd560"
      />
      <path
        d="M23.18,29.35c-3.61,3.81-6,1.88-6.71.26a.57.57,0,0,1,.05-.54c.93,1.46,3.19,2.68,6.44-.75A9.61,9.61,0,0,0,24,24.4C24.89,26.12,23.18,29.35,23.18,29.35Z"
        fill="#ffd560"
      />
      <path
        d="M35,29.31a46.07,46.07,0,0,0-5.43-12.22c1.78,18.34-13.87,14.54-13.88,14.54C20.08,34.49,33,35.28,35,29.31Z"
        fill="#ff7200"
      />
      <path
        d="M26.73,17.63a7.42,7.42,0,0,1,3.4,5.72c-.72,3.44-3.69,6.66-3,5.07a25.52,25.52,0,0,0-.89-10.29C26.12,17.77,26.45,17.44,26.73,17.63Z"
        fill="#ff7200"
      />
      <path
        d="M26.34,24.62a5.68,5.68,0,0,1,1.48,5c-1.3,2.29-4.08,2.08-3.22,1.11a11.43,11.43,0,0,0,1.28-5.89A.26.26,0,0,1,26.34,24.62Z"
        fill="#ff7200"
      />
      <path
        d="M25.37,28.81a4.67,4.67,0,0,1-2.57,2.58c-1.85.65,4.27.33,4.27.33l.75-2.13Z"
        fill="#ff7200"
      />
      <path
        d="M19.65,31.53a5,5,0,0,0,3.86-4.39c1.57-10.32-9.37-22.52-9.37-22.52"
        fill="none"
        stroke="#470b00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </>
  ),
  part_5: (
    <>
      <path
        d="M35.58,19.7s-.06-.22-.08-.26l-.06-.18,0-.1-.15-.27a3.3,3.3,0,0,0-.22-.32L35,18.5a3.55,3.55,0,0,0-.47-.48l-.16-.12a3,3,0,0,0-.54-.34l-.14-.07-.09,0a2.46,2.46,0,0,0-.58-.18l-.19,0a3.13,3.13,0,0,0-.56,0h-.2a3.2,3.2,0,0,0-.47.09l-.15.05-.12,0a2,2,0,0,0-.42.25l-.11.08-.12.12a2,2,0,0,0-.2.28l0,.05a3.72,3.72,0,0,0-.67,0,4.89,4.89,0,0,0-.55.05,5.13,5.13,0,0,0,.14-4.38c0-.06.05-.37.05-.37v-.09a5.16,5.16,0,0,0-.07-.83,1.42,1.42,0,0,0,0-.2,5.76,5.76,0,0,0-.22-.77l-.07-.2c-.11-.28-.24-.57-.39-.88l-.18-.35L28.31,10a3.66,3.66,0,0,0-.59-.59l-.13-.12L27.4,9.2a3.15,3.15,0,0,0-1.1-.46L26,8.69l-.23,0h-.13a2.55,2.55,0,0,0-.72.1l-.19.06-.12.06a1.75,1.75,0,0,0-.48.32L24,9.3l-.33.64v.12l0,.2a1.55,1.55,0,0,1-.05.23,4.62,4.62,0,0,0-3.13,2.06,7.31,7.31,0,0,0-1.33-2.41c-.17-.19-.33-.37-.51-.54a6.23,6.23,0,0,0-.79-.67,1.91,1.91,0,0,1,0-.24,2.37,2.37,0,0,0-.39-1.4,18.35,18.35,0,0,1-2.47-.37L13.28,6a1.57,1.57,0,0,0-.38.3,4.21,4.21,0,0,0-1.36,2.83,4.83,4.83,0,0,0-.7,4.25,7.59,7.59,0,0,0-1.22-.66,3.15,3.15,0,0,0-1.09-1.56,3.71,3.71,0,0,0-1.45-.71A12.67,12.67,0,0,0,6.59,12H5.93a1.67,1.67,0,0,0-1.55,1,14.59,14.59,0,0,0-.83,4.23,6.91,6.91,0,0,0,.71,1.65,5.89,5.89,0,0,0,.48.71,7,7,0,0,0,.62.73,8,8,0,0,0,3,1.93A5.19,5.19,0,0,0,7.82,24a6.16,6.16,0,0,0,.24,2.47h0a7.86,7.86,0,0,0,.51,1.22,7.73,7.73,0,0,0,.61,1l.08.1a8.28,8.28,0,0,0,1.87,1.82l.17.11a7.27,7.27,0,0,0,1,.58l.21.1a7.61,7.61,0,0,0,1.2.43,6.36,6.36,0,0,0,1.91.28h.16a6.52,6.52,0,0,0,1.43-.25,3.22,3.22,0,0,1,.7-.14,2.27,2.27,0,0,1,.5.06,2.68,2.68,0,0,1,1.17.67,7,7,0,0,0,3.29,1.66,6.67,6.67,0,0,0,1.32.19h.45a5.21,5.21,0,0,0,5.27-4.72,6.07,6.07,0,0,0-.11-1.85l.26,0q.33,0,.66,0h0l.32,0a5.31,5.31,0,0,0,.67-.08l.25-.05a6.06,6.06,0,0,0,.91-.3l.14-.06a5.65,5.65,0,0,0,.8-.48l.16-.13.19-.16h.1l.36-.46.12-.11.06-.13a4,4,0,0,0,.43-.71,4.88,4.88,0,0,0,.29-.89l0-.13a3.25,3.25,0,0,0,.06-.86v-.14c0-.14,0-.26,0-.39l.06-.16.06-.21,0-.15,0-.15c0-.1,0-.21,0-.38A5.25,5.25,0,0,0,35.58,19.7Z"
        fill="#0a210b"
        opacity="0.35"
      />
      <path
        d="M39.05,17.77l0-.2v-.11l-.09-.29a2,2,0,0,0-.15-.36l0-.07a2.54,2.54,0,0,0-.36-.54l-.13-.13a2.35,2.35,0,0,0-.48-.38l-.12-.07-.08,0a3.79,3.79,0,0,0-.54-.2l-.19,0a2.38,2.38,0,0,0-.56-.05h-.2a2,2,0,0,0-.5.1l-.17,0-.13.06a3,3,0,0,0-.49.27l-.13.08-.14.13a2.06,2.06,0,0,0-.28.31l0,0a4.92,4.92,0,0,0-.67,0,5.51,5.51,0,0,0-.57.06,6.63,6.63,0,0,0,1.21-4.8l.13-.4,0-.1a4.94,4.94,0,0,0,.14-.92V9.92a6.25,6.25,0,0,0,0-.85l0-.22a7.29,7.29,0,0,0-.18-1l-.09-.39L34,7.38a2.53,2.53,0,0,0-.45-.65l-.11-.13-.15-.12a2.61,2.61,0,0,0-1-.51L32,5.91l-.22,0h-.14a2.4,2.4,0,0,0-.74.11l-.21.07-.13.06a2.3,2.3,0,0,0-.56.35l-.19.09-.49.7,0,.1,0,0-.07.22a2.56,2.56,0,0,1-.11.24A6.15,6.15,0,0,0,25.5,10.1a6.63,6.63,0,0,0-.76-2.63c-.11-.2-.24-.4-.37-.59a5.42,5.42,0,0,0-.63-.72,2.38,2.38,0,0,1,.05-.26,2.53,2.53,0,0,0-.43-2.24,2.63,2.63,0,0,0-3.85-.35,6.33,6.33,0,0,0-2,3.07A6.6,6.6,0,0,0,15.82,11a5.53,5.53,0,0,0-1-.72,2.68,2.68,0,0,0-.71-1.69,2.73,2.73,0,0,0-2-.88,2.67,2.67,0,0,0-2.08,1A5.71,5.71,0,0,0,8.53,12a6,6,0,0,0-.41,4.81,5.12,5.12,0,0,0,.3.77,5.62,5.62,0,0,0,.44.79,5.76,5.76,0,0,0,2.43,2.1,7.63,7.63,0,0,0-.89,1.84A7.49,7.49,0,0,0,10.07,25h0a6.72,6.72,0,0,0,.21,1.32,6.84,6.84,0,0,0,.36,1.08l0,.12a6.57,6.57,0,0,0,1.4,2,1.58,1.58,0,0,0,.15.12,6.25,6.25,0,0,0,.86.65l.17.1a5.18,5.18,0,0,0,1.09.49,5,5,0,0,0,1.8.34h.16a6.52,6.52,0,0,0,1.46-.24,4.54,4.54,0,0,1,.72-.13,1.61,1.61,0,0,1,.48.08,1.85,1.85,0,0,1,1,.74,5.23,5.23,0,0,0,2.86,1.86,5.58,5.58,0,0,0,1.27.24l.45,0a6.93,6.93,0,0,0,6.4-5,8.37,8.37,0,0,0,.34-2l.25,0c.21,0,.43,0,.65,0h.34a6.26,6.26,0,0,0,.7-.08l.26-.05a5.73,5.73,0,0,0,1-.31l.15-.06a8.62,8.62,0,0,0,.92-.51l.2-.14.23-.17h.1l.48-.5.15-.11.09-.14a6.44,6.44,0,0,0,.61-.77,6.83,6.83,0,0,0,.51-1l.06-.15a6,6,0,0,0,.27-.94l0-.15c0-.15,0-.29.05-.42a1.62,1.62,0,0,0,.1-.18l.12-.23.09-.17.05-.16a2.68,2.68,0,0,0,.13-.42,5.93,5.93,0,0,0,.23-2.1S39.05,17.82,39.05,17.77Z"
        fill="#470b00"
      />
      <path
        d="M28.05,23.19h0a7.44,7.44,0,0,1-2.13-3.83h0a3.63,3.63,0,0,0-6.42-1.55,7.82,7.82,0,0,1-3.57,1.59h0a5,5,0,0,0-3.62,3.5,4.68,4.68,0,0,0,2.7,6c1.93.74,2.51-.59,4.58,0s1.78,2.1,3.79,2.59A4.7,4.7,0,0,0,29,28.13,5.07,5.07,0,0,0,28.05,23.19Z"
        fill="#ffd560"
      />
      <path
        d="M35.73,17.63a3.64,3.64,0,0,1-.8,1,3.49,3.49,0,0,0-2.84,0,3.91,3.91,0,0,0-2.58,4.32c.45,1.63,2.33,2.35,4.19,1.6a4.05,4.05,0,0,0,2.66-3.81,3.71,3.71,0,0,0,.7-2.76A.72.72,0,0,0,35.73,17.63Z"
        fill="#ffd560"
      />
      <path
        d="M17.82,11.23c.13,2.14,1.51,3.67,3.09,3.43s2.74-2.16,2.61-4.29a3.85,3.85,0,0,0-1.7-3.17,3.77,3.77,0,0,1,0-1.82.6.6,0,0,0-1-.58,3.89,3.89,0,0,0-1.52,2.61A4.46,4.46,0,0,0,17.82,11.23Z"
        fill="#ffd560"
      />
      <path
        d="M14.88,18.33a3.9,3.9,0,0,0,.35-5,3.52,3.52,0,0,0-2.35-1.61,3.67,3.67,0,0,1-.1-1.24.72.72,0,0,0-1.26-.52,3.65,3.65,0,0,0-1,2.67,4,4,0,0,0,0,4.65C11.64,18.94,13.59,19.42,14.88,18.33Z"
        fill="#ffd560"
      />
      <path
        d="M18.13,12.68a2.69,2.69,0,0,0,2.78,2c1.57-.23,2.74-2.16,2.61-4.29A3.87,3.87,0,0,0,21.83,7.2S22.89,12.08,18.13,12.68Z"
        fill="#ff7200"
      />
      <path
        d="M13.07,17.36A3,3,0,0,1,10,16.18a4.26,4.26,0,0,0,.51,1.08c1.11,1.68,3.06,2.16,4.35,1.07a3.9,3.9,0,0,0,.35-5S16.57,16.72,13.07,17.36Z"
        fill="#ff7200"
      />
      <path
        d="M19.42,20.78c1.47,1.36-1.35,1.79-3,3.54s-1.4,4.15-2.87,2.8-1.36-3.87.25-5.62S18,19.43,19.42,20.78Z"
        fill="#ffe582"
      />
      <path
        d="M28.05,23.19a8.59,8.59,0,0,1-.87-1c.73,5-3.32,6-4.19,5.27a5.44,5.44,0,0,0-6.5-.32c-2.09,1.18-3.63-.81-4.44-2.28A4.41,4.41,0,0,0,15,28.93c1.93.74,2.52-.59,4.59,0s1.77,2.1,3.78,2.59A4.7,4.7,0,0,0,29,28.13a5,5,0,0,0-1-4.94Z"
        fill="#ff7200"
      />
      <path
        d="M37,19.37a4.64,4.64,0,0,1-.16.53l-.14.28a3.67,3.67,0,0,1-.33.51,3.68,3.68,0,0,1-1.11,2.77,4.51,4.51,0,0,1-1.54,1,3.16,3.16,0,0,1-3.81-.8,2.46,2.46,0,0,1-.38-.8,3.27,3.27,0,0,1-.09-.79,2.74,2.74,0,0,0,3.35-.32,4.65,4.65,0,0,0,1.86-3.3l.3.1.28-.26a3.11,3.11,0,0,0,.23-.28l.12-.16a2.33,2.33,0,0,0,.17-.26.72.72,0,0,1,1.3.13h0l0,.29A4.65,4.65,0,0,1,37,19.37Z"
        fill="#ff7200"
      />
      <path
        d="M31.19,15.4c-1.3,1.69-3.31,2.19-4.48,1.11s-1.07-3.33.24-5a3.84,3.84,0,0,1,3.18-1.68,3.65,3.65,0,0,0,1-1.53.61.61,0,0,1,1.16.08,4,4,0,0,1-.21,3A4.49,4.49,0,0,1,31.19,15.4Z"
        fill="#ffd560"
      />
      <path
        d="M32.07,11.37a0,0,0,0,0,0,0,4.48,4.48,0,0,1-.92,4,3.5,3.5,0,0,1-3.78,1.51,1.85,1.85,0,0,1-.65-.4A2.51,2.51,0,0,1,26,15.24c2.23.33,5.21-.8,4.1-5.43h0l.15-.15a3.53,3.53,0,0,0,.25-.28,3.37,3.37,0,0,0,.65-1.24.61.61,0,0,1,1.09.23,4.6,4.6,0,0,1,.1,2.22A3.5,3.5,0,0,1,32.07,11.37Z"
        fill="#ff7200"
      />
    </>
  ),
  part_6: (
    <>
      <path
        d="M33.49,25c-.6-2.67-2.86-5.36-6.47-7.6A32.45,32.45,0,0,0,10,12.9a22.87,22.87,0,0,0-5.87.85,17.13,17.13,0,0,0-.42,7.17l-.81.32a1.64,1.64,0,0,0-.92.89,1.67,1.67,0,0,0,0,1.28,26.16,26.16,0,0,0,6.64,8.8,32.77,32.77,0,0,0,13.76,2h0a17.44,17.44,0,0,0,7.84-2.33C32.9,30.21,34.1,27.71,33.49,25Z"
        fill="#0a210b"
        opacity="0.35"
      />
      <path
        d="M18.45,33.09A17,17,0,0,1,6.69,24.68,12.77,12.77,0,0,1,6,13.92a14.15,14.15,0,0,1,8.57-8A17.8,17.8,0,0,1,24,5.25a17.06,17.06,0,0,1,11.76,8.42,12.84,12.84,0,0,1,.73,10.76,14.16,14.16,0,0,1-8.58,8,17.76,17.76,0,0,1-9.47.65Z"
        fill="#470b00"
      />
      <path
        d="M34,14.6h0C30.68,8.31,22.26,5.25,15.21,7.78h0c-7,2.53-10.08,9.68-6.75,16h0C11.78,30,20.2,33.09,27.25,30.57h0C34.3,28,37.33,20.89,34,14.6Z"
        fill="#ffd560"
      />
      <path
        d="M10.48,14.65c-.45.53-1.28,6.7,1.88,12.15C9.74,23.64,8.24,20,8.88,17.44A6.77,6.77,0,0,1,10.48,14.65Z"
        fill="#ffe582"
      />
      <path
        d="M17.47,12.23s-6.55-.05-7.6,5.88A9.83,9.83,0,0,0,11.48,25C9.26,16.3,17.47,12.23,17.47,12.23Z"
        fill="#ffe582"
      />
      <path
        d="M13.91,19S12.77,25.3,18,28.2C14.52,27.83,10.91,25.4,13.91,19Z"
        fill="#ffe582"
      />
      <path
        d="M15.55,21.86s-1.55,2.22,1,5.27c-2.43-1.16-2.66-2.78-2.66-2.78A5.25,5.25,0,0,1,15.55,21.86Z"
        fill="#ffe582"
      />
      <path
        d="M18.78,18.67c-1-.38-1.8-2.29-1.8-2.29s-.19,4.59,4.53,4.67a4.62,4.62,0,0,1-1.46.34,7.11,7.11,0,0,1-2.11-.32c2.76,2.19,6.2,1,8.35-1.49-4.79,1.63-8.14-2.06-6.5-7.07C19.79,12.51,16.74,16.68,18.78,18.67Z"
        fill="#ffe582"
      />
      <path
        d="M24,15.72s1-.29,1,.45c-.09,1.13-1.9.7-2.46-.36a2.62,2.62,0,0,0,1.89,2.39,14.29,14.29,0,0,0,1.93-.66c.11-.14.82-.82.65-1.45a6.5,6.5,0,0,0-.46-1.44c-.14-.14-1.11-.11-1.23-.12S24.17,15.14,24,15.72Z"
        fill="#ffe582"
      />
      <path
        d="M35.14,21.94a11.76,11.76,0,0,1-7.9,8.63,15.8,15.8,0,0,1-8.39.56,16.07,16.07,0,0,1-3.67-1.19,14.8,14.8,0,0,0,8.14-.1,9.39,9.39,0,0,1-1.81-.55c-5.69-2.5-4-6.37-4-6.37s.7,3.75,5.55,2.75a6.58,6.58,0,0,0,2.61-1.26,4.52,4.52,0,0,1-3.13-.32c6.93-1.64,6.57-7.77,6.57-7.77a5.44,5.44,0,0,1,1.58,1c-.46-1.35-2-4.88-5.41-5.22-4.28-.42-3.78,3.78-2.21,4.77,2,1.24,2.46.2,2.52-.83a1,1,0,0,0-.9-1.13,1,1,0,0,0-.93.56s-.24-2.39,2.55-1.49c1.78.57,2,2.54.76,3.89a3.72,3.72,0,0,1-4.63.86c-2-1.18-3.07-4.25-1.84-6.36,2.24-3.85,7.82-1.84,7.82-1.84s.53-.69,1-.26a9.24,9.24,0,0,1,.84,1.19,8.34,8.34,0,0,0-.57-1.59l.21.1A13.44,13.44,0,0,1,34,14.6,11,11,0,0,1,35.14,21.94Z"
        fill="#ff7200"
      />
      <path
        d="M34,14.6h0c-.16-.3-.34-.59-.52-.87C27.47,33.7,13.05,28,11,27.08a16.14,16.14,0,0,0,16.27,3.49h0C34.3,28,37.33,20.89,34,14.6Z"
        fill="#231815"
        opacity="0.29"
      />
    </>
  ),
};

export const PetTextColor = [
  "#499B71",
  "#4787C7",
  "#9571AC",
  "#BC6603",
  "#B1000A",
];

// 底部#858585 描边#444444
export const PetDefaultColor = "#858585";

export const PetImgColor = [
  "#a6d299",
  "#91C2E0",
  "#B79CC8",
  "#F18D00",
  "#E61E58",
];

export const PetDefaultBoder = "#444444";

export const PetImgBorder = [
  "#458f69",
  "#4787C7",
  "#805D97",
  "#943C1F",
  "#79090F",
];

export const PetDefaultGeneColor = "#858585";

export const PetDefaultGeneBoder = "#444444";

export const PetGeneColor = [
  "#6EC392",
  "#55BADC",
  "#B4A7E5",
  "#FFA723",
  "#FC0049",
];

export const PetGeneBorder = [
  "#0F7644",
  "#084C95",
  "#4C3C8A",
  "#94561F",
  "#661214",
];
