import { FC, memo, useCallback, useEffect, useMemo, useRef } from "react";
// import QuestionIcon from "src/components/QuestionIcon";
// import Container from "src/components/Container";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import useIsMobile from "src/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import useTiny from "src/hooks/useTiny";
import useAllMyNFT from "src/hooks/useAllMyNFT";
// import SortNft, { useSort, sortList } from "src/components/SortNft";
// import { useGradeFilter } from "src/components/GradeFilter";
// import useMyPagination from "src/hooks/useMyPagination";
// import useDescByCode from "src/hooks/useDescByCode";
// import dayjs from "dayjs";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid, GridChildComponentProps } from "react-window";

import { IHeroHead } from "src/components/HeadHeroCard/type";
import { HeadHeroCard } from "src/components/HeadHeroCard";
import useLordHeroGridProps from "src/hooks/useLordHeroGridProps";
import type { NFTInfo } from "src/services/typings";
import { _endOfDay } from "src/utils/endOfDay";
import { BLOCKS_PER_DAY } from "src/utils";
import { gradeDurability } from "src/config";
import { heroImgHeight } from "src/components/HeadHeroCard/const";

// 1 根据 power
// 2 attackCount
// 3 gameIndex
function sortStaminaNFT(list: NFTInfo[]) {
  const staminaArr: NFTInfo[] = [];
  const maxArr: NFTInfo[] = [];

  list.forEach((ele) => {
    const stamina = (ele.stamina || 0) / BLOCKS_PER_DAY;
    if (stamina === gradeDurability[ele.itemGrade]) {
      maxArr.push(ele);
    } else {
      staminaArr.push(ele);
    }
  });

  staminaArr.sort((a, b) => {
    return b.itemGrade - a.itemGrade;
  });

  maxArr.sort((a, b) => {
    return b.itemGrade - a.itemGrade;
  });

  return staminaArr.concat(maxArr);
}

function sortPowerNFT(list: NFTInfo[]) {
  list.sort((a, b) => {
    return b.itemGrade - a.itemGrade;
  });
  return list;
}

interface IHeroHeadRender extends IHeroHead {
  isStationing: boolean;
}

const HeroList: FC<{
  checkHero: IHeroHead[];
  setHero: (hero: IHeroHead) => void;
  tabIndex: number;
}> = ({
  // setCheckHero,
  checkHero,
  // isFree,
  // showWin,
  setHero,
  tabIndex,
}) => {
  const tiny = useTiny();
  const account = tiny.myAccount;
  // const { sort, compareFn, setSort } = useSort(sortList[0]);
  const isMobile = useIsMobile();

  const { data: middleResults, refetch: refetchAllNFT } = useAllMyNFT(account);

  const results: IHeroHeadRender[] = useMemo(() => {
    if (middleResults) {
      switch (tabIndex) {
        case 0:
          return sortStaminaNFT(middleResults).map((result) => {
            return {
              ...result,
              freePlayTime: _endOfDay(Math.floor(Date.now() / 1000)),
              gradeType: result.itemGrade,
              isStationing:
                checkHero && !!checkHero.find((hero) => hero?.id === result.id),
              notClickItem: "StaminaInfo",
            };
          });
        case 1:
          return (
            sortPowerNFT(middleResults)
              // .filter((ele) => ele.itemGrade > 3)
              .map((result) => {
                return {
                  ...result,
                  freePlayTime: _endOfDay(Math.floor(Date.now() / 1000)),
                  gradeType: result.itemGrade,
                  isStationing:
                    checkHero &&
                    !!checkHero.find((hero) => hero?.id === result.id),
                  notClickItem: "gradeType",
                };
              })
          );
        default:
          return [];
      }
    }
    return [];
  }, [checkHero, middleResults, tabIndex]);

  const boxRef = useRef<HTMLDivElement>(null);

  const heroHeight = isMobile ? 86 : 114;

  const gridProps = useLordHeroGridProps({
    boxRef,
    itemHeight: heroHeight + 10,
    itemWidth: heroHeight * heroImgHeight,
    row: isMobile ? 4 : 10,
    columnBreak: isMobile ? 1 : 3,
  });

  const COLUMN_GAP = isMobile ? gridProps.left : gridProps.left;
  const ROW_GAP = isMobile ? 4 : 6;

  const Cell = useCallback<FC<GridChildComponentProps<IHeroHeadRender[]>>>(
    ({ columnIndex, rowIndex, style, data }) => {
      const columnCount = gridProps.columnCount;
      const index = rowIndex * columnCount + columnIndex;
      const ele = data[index];
      if (ele === undefined) return null;
      // console.log("ele", ele);
      // const showStatus = !isSelected && isDisabled;
      const left = ((style.left as number) ?? 0) + COLUMN_GAP;
      const top = ((style.top as number) ?? 0) + ROW_GAP;
      const { isStationing } = ele;
      return (
        <HeadHeroCard
          height={heroHeight}
          key={ele.id}
          // ref={index === 0 ? itemRef : undefined}
          onClick={setHero}
          boxStyle={{
            position: "absolute",
            left: left,
            top: top,
            background: isStationing ? "#178CD2" : "rgb(149 125 62 / 70%)",
            margin: "0 auto",
            cursor: "pointer",
            borderRadius: "12px 12px 6px 6px",
          }}
          {...ele}
          isCheckHero
          showStamina
          isNotTinyLord
        ></HeadHeroCard>
      );
    },
    [gridProps.columnCount, COLUMN_GAP, ROW_GAP, heroHeight, setHero]
  );

  return (
    <StyledHeroContainer>
      <StyledHerobox ref={boxRef}>
        <AutoSizer>
          {({ height }) => (
            <Grid
              className={"hero-box"}
              // className={isMobile ? "select-grid select-box" : "select-box"}
              style={{
                margin: "0 auto",
                position: "relative",
              }}
              columnWidth={
                isMobile ? gridProps.columnWidth : gridProps.columnWidth
              }
              rowHeight={isMobile ? gridProps.rowHeight : gridProps.rowHeight}
              columnCount={gridProps.columnCount}
              rowCount={Math.ceil(results.length / gridProps.columnCount)}
              width={gridProps.width}
              height={isMobile ? gridProps.height + 20 : gridProps.height}
              itemData={results}
            >
              {Cell}
            </Grid>
          )}
        </AutoSizer>
      </StyledHerobox>
    </StyledHeroContainer>
  );
};

function TinyLordCheckHero(props: {
  checkHero: IHeroHead[];
  setHero: (hero: IHeroHead) => void;
  tabIndex: number;
}) {
  // console.log("TinyLordCheckHero", props);
  const { t } = useTranslation();

  // console.log("checkHero", checkHero);

  // const tiny = useTiny();
  // const account = tiny.myAccount;
  // const [isFree, setIsFree] = useState(false);

  return (
    <StyledContainer>
      <div>
        {props.tabIndex === 0 ? t("addStaminaTips") : t("addPowerTips")}
      </div>
      <HeroList {...props}></HeroList>
    </StyledContainer>
  );
}

export default memo(TinyLordCheckHero);

const StyledContainer = styled.div`
  width: 100%;
  background: #fbcc4e;
  border-radius: 1rem;
  padding: 1rem;
  box-sizing: border-box;

  > div {
    width: 100%;
    color: #514b45;
  }
`;

const StyledHerobox = styled.div`
  background: #ffe7a7;
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-radius: 0.6rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  box-sizing: border-box;
  gap: 2.28rem;
  padding: 1.9rem 6px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 3.28rem;
  `}

  .hero-box>div:first-child {
    margin: 0 auto;
    position: relative;
  }
`;

const StyledHeroContainer = styled.div`
  width: 100%;
  height: 60vh;
  box-sizing: border-box;
  padding-top: 1rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 32vh;
    padding-top: 0.5rem;
  `}
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    ul {
      margin: 0;
    }
  `}
`;
