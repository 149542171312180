import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useIsMobile from "src/hooks/useIsMobile";
import useTiny from "src/hooks/useTiny";

import Modal from "src/components/Modal";

import type { ModalProps } from "src/components/Modal";
// import ModalActions from "src/components/ModalActions";
import PrimaryButton from "src/components/Button/PrimaryButton";
import {
  useInvestorClaimed,
  useInvestorRewards,
} from "src/hooks/useInvestorRewards";
import { getDisplayBalance } from "src/utils/formatBalance";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import { Context } from "src/contexts/TinyProvider";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
import useLimitedInterval from "src/hooks/useLimitedInterval";

export default function VestingModal({ onDismiss }: ModalProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const tiny = useTiny();
  const account = tiny.myAccount;
  const handleTransactionReceipt = useHandleTransactionReceipt();

  const { data: pendingReward, refetch: refetchReward } = useInvestorRewards();
  const { data: claimed, refetch: refetchClaimed } = useInvestorClaimed();

  const { startFreshTINCBalance } = useContext(Context);
  const handleTxConfirm = useHandleTxConfirm();

  const startRefreshReward = useLimitedInterval(refetchReward);
  const startRefreshClaimed = useLimitedInterval(refetchClaimed);

  const handleVesting = useCallback(() => {
    if (account) {
      handleTransactionReceipt(
        tiny.claimInvestReward(account),
        t("Vesting")
      ).then((tx) => {
        if (tx) {
          handleTxConfirm(tx.hash).then(() => {
            startFreshTINCBalance();
            startRefreshReward();
            startRefreshClaimed();
          });
        }
      });
    }
  }, [
    tiny,
    handleTransactionReceipt,
    account,
    startFreshTINCBalance,
    handleTxConfirm,
    startRefreshClaimed,
    startRefreshReward,
    t,
  ]);

  return (
    <Modal style={{ maxWidth: isMobile ? 340 : 450 }} onDismiss={onDismiss}>
      <StyledContent>
        <StyledLabel className="rfs-10 mb-10 mt-10">{t("Claimed")}</StyledLabel>
        <StyledTotalRight>
          <StyledIcon
            className="mr-5"
            src="//images.tinyworlds.io/tokens/TINC.png"
            alt=""
          />
          <StyledAmount className="mr-5">
            {claimed ? getDisplayBalance(claimed, 18, 2) : "--"}
          </StyledAmount>{" "}
          TINC
        </StyledTotalRight>
        <StyledLabel className="rfs-10 mb-10 mt-10">
          {t("Claimable")}
        </StyledLabel>
        <StyledTotalRight>
          <StyledIcon
            className="mr-5"
            src="//images.tinyworlds.io/tokens/TINC.png"
            alt=""
          />
          <StyledAmount className="mr-5">
            {pendingReward ? getDisplayBalance(pendingReward, 18, 2) : "--"}
          </StyledAmount>{" "}
          TINC
        </StyledTotalRight>
        <PrimaryButton
          // style={{
          //   marginBottom: "0.4rem",
          // }}
          onClick={handleVesting}
          className="mt-20 mb-10 fw-600 rfs-11"
          size={isMobile ? "sm" : "md"}
          width={isMobile ? 75 : undefined}
          disabled={!account || !pendingReward || pendingReward.isZero()}
        >
          {t("Claim")}
          {/* {unclaimed ? getDisplayBalance(unclaimed) : 0} */}
        </PrimaryButton>
      </StyledContent>
      {/* <StyledVeTINCInfo>
        <StyledItem>
          <StyledLabel className="rfs-10 mb-10 mt-10">
            {t("累计已领取")}
          </StyledLabel>
          <StyledAmount>
            <StyledIcon src="//images.tinyworlds.io/tokens/TINC.png" alt="" />
            12312312321 TINC
          </StyledAmount>
        </StyledItem>
        <StyledItem>
          <StyledLabel className="rfs-10 mb-10">
            {t("当前可领取")}
          </StyledLabel>
          <StyledAmount>
            <StyledIcon src="//images.tinyworlds.io/tokens/TINC.png" alt="" />
            123123123123213 TINC
          </StyledAmount>
        </StyledItem>
      </StyledVeTINCInfo>
      <ModalActions>
        <ParallelogramButton
          // size={isMobile ? "sm" : "md"}
          onClick={() => onDismiss && onDismiss()}
        >
          {t("Claim")}
        </ParallelogramButton>
      </ModalActions> */}
    </Modal>
  );
}

// const StyledButtonBox = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   padding-top: 1rem;
// `;

// const StyledVeTINCInfo = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   row-gap: 0.75rem;

//   &::after {
//     content: "";
//     height: 90%;
//     border-right: 1.5px solid #fffbdd;
//     position: absolute;
//     margin: auto;
//     right: 0;
//     top: 0;
//     bottom: 0;
//   }

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     padding: 20px;
//     flex-basis: 100%;

//     &::after {
//       content: '';
//       margin: 0 auto;
//       height: 100%;
//       width: 90%;
//       left: 0;
//       border-bottom: 1.5px solid #FFFBDD;
//       border-right: none;
//     }
//   `}
// `;

// const StyledItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     gap: 5px;
//     max-width: 142px;
//   `}
// `;

const StyledLabel = styled.span`
  text-align: center;
  white-space: nowrap;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.1rem;
  `}
`;

// const StyledAmount = styled.div`
//   border-radius: 11px;
//   background-color: #fffbdd;
//   height: 42px;
//   // 230px
//   width: 90%;
//   min-width: 230px;
//   font-weight: 400;
//   display: flex;
//   gap: 3px;
//   align-items: center;
//   // justify-content: flex-start;
//   justify-content: center;
//   font-size: 1.1rem;
//   white-space: nowrap;
//   // padding-left: 20%;
//   box-sizing: border-box;

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     height: 32px;
//     width: 142px;
//   `}
// `;

const StyledIcon = styled.img`
  height: 26px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 20px;
  `}
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* padding: 24px 0; */
  min-height: 100px;
  border-radius: 9px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 15px;
  `}
`;

const StyledTotalRight = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 120px;
  font-size: 1.3rem;
`;

const StyledAmount = styled.span`
  height: 2.8rem;
  // width: 7.8rem;
  min-width: 11rem;
  border-radius: 11px;
  background-color: #fffbdd;
  padding: 11px 12px;
  font-weight: 600;
  font-size: 1.5rem;
  // line-height: 1.5rem;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
