import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTable, Column } from "react-table";
import { ExternalLink } from "react-feather";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import config from "src/config";
import { getBuybacks, BuybacksItem } from "src/services";

import di2 from "src/images/di_2.png";
import clsx from "clsx";
import Pagination from "src/components/Pagination";
import { getPageCount } from "src/utils";

function TxRender({ value = "" }) {
  return (
    <StyledLink
      href={config.scanUrl + "tx/" + value}
      target="__blank"
      rel="noreferrer"
    >
      <ExternalLink size={14} />
    </StyledLink>
  );
}

function getRowId(row: BuybacksItem) {
  return row.tx;
}

export default function BurnedList() {
  // console.log("symbol", symbol);
  // const tiny = useTiny();
  const { t } = useTranslation();
  const [pageNum, setPageNum] = useState(0);

  const [moreActive, setMoreActive] = useState(false);

  useEffect(() => {
    if (!moreActive) setPageNum(0);
  }, [moreActive]);

  const { data } = useQuery(
    ["BUYBACK_LIST", pageNum],
    ({ signal }) => getBuybacks({ pageNum }, { signal }),
    {
      enabled: typeof pageNum === "number",
      refetchInterval: 60_000,
      keepPreviousData: true,
    }
  );

  const renderData = useMemo(() => {
    if (data) {
      return moreActive ? data.list : data.list.slice(0, 5);
    } else return [];
  }, [data, moreActive]);

  const columns: Column<BuybacksItem>[] = useMemo(
    () => [
      {
        Header: t("time") as string,
        accessor: "timestamp", // accessor is the "key" in the data
        minWidth: 90,
        Cell: ({ value = 0 }) =>
          dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        Header: t("Type") as string,
        accessor: "type",
        Cell: ({ value = 1 }) =>
          t(
            ["Buy1", "Open", "Auction Burn", "Breeding Burn", "Game Burn"][
              value - 1
            ]
          ),
      },
      {
        Header: t("Amount") as string,
        accessor: "calcAmount",
        Cell: ({ value = "0" }) => (Number(value) / 1e6).toFixed(2) + " TINC",
      },
      {
        Header: t("price") as string,
        accessor: "price",
        Cell: ({ value = "0" }) => "$ " + (Number(value) / 1e6).toFixed(4),
      },
      {
        Header: `Tx`,
        accessor: "tx",
        Cell: TxRender,
      },
    ],
    [t]
  );

  const tableInstance = useTable({ columns, data: renderData, getRowId });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  return (
    <>
      <StyledContainer>
        <StyledTitle>{t("TINC Burned Details")}</StyledTitle>

        <StyledTable {...getTableProps()}>
          <StyledTHead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      // console.log("column", column);
                      return (
                        // Apply the header cell props
                        <th {...column.getHeaderProps()}>
                          {
                            // Render the header
                            column.render("Header")
                          }
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </StyledTHead>
          <StyledTBody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </StyledTBody>
        </StyledTable>
        {/* pageCount */}
        {moreActive && (
          <StyledOpacity>
            <Pagination
              simple
              pageCount={data?.total_count ? getPageCount(data) : 0}
              pageIndex={pageNum}
              nextPage={() => setPageNum((n) => n + 1)}
              previousPage={() => setPageNum((n) => n - 1)}
            />
          </StyledOpacity>
        )}
      </StyledContainer>
      <StyledMore>
        <span
          onClick={() => {
            setMoreActive(!moreActive);
          }}
          className={clsx(moreActive && "active")}
        >
          more
        </span>
      </StyledMore>
    </>
  );
}

const StyledOpacity = styled.div`
  animation: elementShow 500ms linear;
  padding: 14px 0;
`;

const StyledMore = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 10px;

  span {
    cursor: pointer;
    user-select: none;

    &.active {
      ::after {
        transform: rotate(-180deg) translateY(1px);
      }
    }

    ::after {
      content: " ";
      box-sizing: border-box;
      margin-left: 2px;
      display: inline-block;
      transform: translateY(2px);
      border-width: 6px 4px 2px;
      border-style: solid;
      border-color: #514b45 transparent transparent transparent;
      transition: transform 500ms;
    }
  }
`;

const StyledContainer = styled.div`
  --pc-table-space: 25px;
  --pc-table-space-negative: -25px;
  padding: 20px var(--pc-table-space) 0;
  background-image: url(${di2});
  text-align: center;
  margin: 20px auto 0;
  border-radius: 9px;
  // box-shadow: 0 2px 2px rgb(0 0 0 / 16%);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    --pc-table-space: 12px;
    --pc-table-space-negative: -12px;
    margin-left: 15px;
    margin-right: 15px;
  `}
`;

const StyledTitle = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 6px 5px 6px;
  }
`;

const StyledTHead = styled.thead`
  position: relative;
  height: 2.5rem;
  background-color: #fedb8d;
  border-radius: 9px;
  ::before {
    position: absolute;
    content: " ";
    height: 2.5rem;
    width: var(--pc-table-space);
    left: var(--pc-table-space-negative);
    background-color: #fedb8d;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  ::after {
    position: absolute;
    content: " ";
    height: 2.5rem;
    top: 0;
    width: var(--pc-table-space);
    right: var(--pc-table-space-negative);
    background-color: #fedb8d;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const StyledTBody = styled.tbody`
  /* color: ${(props) => props.theme.color.yellow}; */
  text-align: center;
  > tr:not(:last-child) {
    height: 2.8rem;
    border-bottom: 1px solid rgb(112, 112, 112);
  }
  td {
    max-width: 300px;
    line-break: anywhere;
  }
  td {
    width: 20%;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: auto;
      line-break: auto;
      /* 折行问题 */
      font-family: Arial;
    `}
  }
  td:nth-child(3) {
    min-width: 26px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    td:first-child {
      width: 6.3rem;
    }
  `}
`;

const StyledLink = styled.a`
  color: black;
`;
