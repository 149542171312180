import { useCallback, useState, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useIsMobile from "src/hooks/useIsMobile";
import DownIcon from "src/components/Svg/DownIcon";
import useModal from "src/hooks/useModal";
import { ReactComponent as CheckinIconMobile } from "src/images/topbar/checkin_1.svg";
import { ReactComponent as CheckinIcon } from "src/images/topbar/checkin_2.svg";

import GameSignModal from "./GameSignModal";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
import useTiny from "src/hooks/useTiny";
import { useGloryCampaignCheckinInfo } from "src/hooks/useActiveHooks";
import ActiveSignModal from "./Active/ActiveSignModal";
import useCheckinRestTime from "src/hooks/useCheckinRestTime";
import dayjs from "dayjs";
import useUrlState from "@ahooksjs/use-url-state";
import { _endOfCustomGapTime } from "src/utils/endOfDay";
import { Hot, RedRadius } from "src/components/Hint";
import { chainId } from "src/config";
import ExternalModal from "src/components/ExternalModal";

interface OptionProps {
  keepActive: boolean;
  handleClick(): void;
}

export function Option({
  keepActive = true,
  handleClick,
  children,
}: PropsWithChildren<OptionProps>) {
  return (
    <StyledButton
      className={"position-relative"}
      onClick={handleClick}
      isActive={keepActive}
    >
      {children}
    </StyledButton>
  );
}

interface DropDown {
  alignLeft?: boolean;
  className?: string;
}

export default function CheckinDropDown({
  className,
  alignLeft = false,
  children,
}: PropsWithChildren<DropDown>) {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => setShow((pre) => !pre), []);
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();
  const tiny = useTiny();
  const [signEnd, setSignEnd] = useState(true);
  const [loading, setLoading] = useState(false);
  const account = tiny.myAccount;
  const [babCheckinOpen, setBabCheckinOpen] = useState(false);

  const [showGameSignModal] = useModal(<GameSignModal />, [], {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  });

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        document.addEventListener(
          "click",
          function () {
            if (show) {
              setShow(false);
            }
          },
          {
            once: true,
          }
        );
      }, 100);
    }
  }, [show]);

  const { data: campaignCheckinInfo, refetch: refetchCampaignCheckinInfo } =
    useGloryCampaignCheckinInfo();

  const isHoldActiveNFT =
    campaignCheckinInfo && campaignCheckinInfo.nftBalance > 0;

  const [search] = useUrlState<{ checkin: boolean }>({
    checkin: false,
  });

  const [restTime] = useCheckinRestTime(100_000);

  const handleActiveCheckIn = useCallback(
    (successCb: () => void) => {
      return handleTransactionReceipt(
        tiny.activeCheckIn(),
        t("ActiveCheckIn"),
        "",
        true
      ).then((tx) => {
        if (tx) {
          setLoading(true);
          return handleTxConfirm(tx.hash)
            .then(() => {
              setSignEnd(true);
              refetchCampaignCheckinInfo();
              successCb();
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setLoading(false);
            });
        }
      });
    },
    [
      tiny,
      handleTransactionReceipt,
      handleTxConfirm,
      refetchCampaignCheckinInfo,
      t,
    ]
  );

  useEffect(() => {
    if (search.checkin === "true" && account) {
      setTimeout(() => {
        showGameSignModal();
      }, 500);
    }
  }, [account, search.checkin, showGameSignModal]);

  useEffect(() => {
    const signEnd: {
      day: Date;
      sign: boolean;
    } = JSON.parse(
      localStorage.getItem("signEnd") ||
        JSON.stringify({
          day: dayjs().format("YYYY-MM-DD"),
          sign: false,
        })
    );
    if (isHoldActiveNFT) {
      if (
        typeof campaignCheckinInfo?.lastCheck === "number" &&
        _endOfCustomGapTime(
          campaignCheckinInfo?.lastCheck,
          chainId === 56 ? 24 * 3600 : 600
        ) < Math.floor(Date.now() / 1000) &&
        campaignCheckinInfo?.count < 5
      ) {
        setSignEnd(false);
        setBabCheckinOpen(true);
      } else {
        setSignEnd(true);
      }
    } else {
      if (
        !signEnd.sign ||
        Math.abs(dayjs().diff(dayjs(signEnd.day), "day")) > 0
      ) {
        if (typeof restTime === "number" && restTime === 0) {
          showGameSignModal();
        }
      }
    }
  }, [
    account,
    isHoldActiveNFT,
    restTime,
    showGameSignModal,
    setBabCheckinOpen,
    campaignCheckinInfo,
  ]);

  // 只有一个
  // if (!isHoldActiveNFT) {
  if (isMobile) {
    return (
      <StyledImgBox show={false} onClick={showGameSignModal} className="mr-5">
        <CheckinIconMobile height={20} />
      </StyledImgBox>
    );
  }

  return (
    <StyledNormalButton
      className={"position-relative"}
      onClick={showGameSignModal}
    >
      <CheckinIcon className="mr-5" height={18} />
      {t("check in")}
      {/* <Hot
        style={{
          position: "absolute",
          right: -1,
          top: -12,
        }}
        bottom={-3}
      /> */}
    </StyledNormalButton>
  );
  // }

  // if (isMobile) {
  //   // 移动端多个
  //   return (
  //     <StyledMobileContainer onClick={handleClick} className="mr-5">
  //       <StyledImgBox
  //         className="position-relative"
  //         show={show}
  //         style={{ padding: 6 }}
  //       >
  //         <CheckinIconMobile height={20} />
  //         <DownIcon
  //           style={{ height: 6, marginLeft: 3 }}
  //           color="#FDCE4F"
  //           isRotate={true}
  //         />
  //         <Hot
  //           style={{
  //             position: "absolute",
  //             left: 20,
  //             top: -9,
  //             whiteSpace: "nowrap",
  //           }}
  //           bottom={-3}
  //         />
  //       </StyledImgBox>
  //       <StyledOptionContainer
  //         isShow={show}
  //         style={{ width: i18n.languages.includes("zh") ? 80 : 80 }}
  //       >
  //         <Option keepActive={false} handleClick={showGameSignModal}>
  //           {t("gameSign2")}
  //         </Option>
  //         {/* {isHoldActiveNFT && (
  //           <Option keepActive={false} handleClick={showActiveSignModal}>
  //             {t("Active checkin")}
  //           </Option>
  //         )} */}
  //         {/* {isHoldActiveNFT && ( */}
  //         <Option
  //           keepActive={false}
  //           handleClick={() => {
  //             setBabCheckinOpen(true);
  //           }}
  //         >
  //           <span className="position -relative">
  //             {t("Glory Pass")}
  //             <RedRadius
  //               style={{
  //                 top: -2,
  //                 right: -9,
  //               }}
  //             ></RedRadius>
  //           </span>
  //         </Option>
  //         {/* )} */}
  //       </StyledOptionContainer>

  //       {babCheckinOpen && (
  //         <ExternalModal
  //           onClose={() => setBabCheckinOpen(false)}
  //           style={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
  //         >
  //           {({ handleClose }) => (
  //             <ActiveSignModal
  //               onDismiss={handleClose}
  //               signCount={campaignCheckinInfo?.count || 0}
  //               signCountRefetch={refetchCampaignCheckinInfo}
  //               handleBabCheckIn={handleActiveCheckIn}
  //               signEnd={signEnd}
  //               loading={loading}
  //             />
  //           )}
  //         </ExternalModal>
  //       )}
  //     </StyledMobileContainer>
  //   );
  // }

  // return (
  //   // PC端多个
  //   <StyledContainer className={className}>
  //     <StyledOptionButton
  //       className="position-relative"
  //       isShow={show}
  //       onClick={handleClick}
  //     >
  //       <CheckinIcon className="mr-5" height={18} />
  //       CHECK IN
  //       <DownIcon
  //         style={{ height: 8, marginLeft: 5 }}
  //         color="#514b45"
  //         isRotate={true}
  //       />
  //       <Hot
  //         style={{
  //           position: "absolute",
  //           right: -1,
  //           top: -12,
  //         }}
  //         bottom={-3}
  //       />
  //     </StyledOptionButton>
  //     <StyledContent isShow={show} alignLeft={alignLeft}>
  //       <Option keepActive={false} handleClick={showGameSignModal}>
  //         {t("gameSign2")}
  //       </Option>
  //       {/* {isHoldActiveNFT && (
  //         <Option keepActive={false} handleClick={showActiveSignModal}>
  //           {t("Active checkin")}
  //         </Option>
  //       )} */}
  //       {/* {isHoldActiveNFT && ( */}
  //       <Option
  //         keepActive={false}
  //         handleClick={() => {
  //           setBabCheckinOpen(true);
  //         }}
  //       >
  //         <span className="position-relative">
  //           {t("Glory Pass")}
  //           <RedRadius
  //             style={{
  //               right: -30,
  //             }}
  //           ></RedRadius>
  //         </span>
  //       </Option>
  //       {/* )} */}
  //     </StyledContent>

  //     {babCheckinOpen && (
  //       <ExternalModal
  //         onClose={() => setBabCheckinOpen(false)}
  //         style={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
  //       >
  //         {({ handleClose }) => (
  //           <ActiveSignModal
  //             onDismiss={handleClose}
  //             signCount={campaignCheckinInfo?.count || 0}
  //             signCountRefetch={refetchCampaignCheckinInfo}
  //             handleBabCheckIn={handleActiveCheckIn}
  //             signEnd={signEnd}
  //             loading={loading}
  //           />
  //         )}
  //       </ExternalModal>
  //     )}
  //   </StyledContainer>
  // );
}

const StyledContainer = styled.div`
  position: relative;
  height: 36px;
  width: auto;
  z-index: 8;
  margin-right: 15px;
`;

const StyledContent = styled.div<{
  isShow: boolean;
  alignLeft: boolean;
}>`
  padding: 0;
  margin-top: 6px;
  width: max-content;
  height: auto;
  max-height: ${({ isShow }) => (isShow ? "110px" : "0px")};
  background-color: #fdce4f;
  color: #514b45;
  border-radius: 18px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
  transition: max-height 0.2s;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 0;
  `}
`;

const StyledBaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  color: #514b45;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
`;

const StyledOptionButton = styled(StyledBaseButton)<{ isShow: boolean }>`
  height: 36px;
  padding: 0px 12px;
  justify-content: space-around;
  position: relative;
  background-color: #fdce4f;
  border-radius: 18px;
  ${({ isShow }) =>
    isShow
      ? `
    transform: translateY(3px);
  `
      : `
    box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  `}
  transition: box-shadow 0.2s, transform 0.2s;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0;
    padding: 2px 6px 5px;
  `}
`;

const StyledButton = styled(StyledBaseButton)<{ isActive: boolean }>`
  padding: 0 16px;
  height: 28px;
  min-width: 140px;
  justify-content: start;
  word-break: keep-all;
  white-space: nowrap;

  @media (any-hover: hover) {
    &:hover {
      background-color: #514b45;
      color: #fdce4f;

      > svg > g > path {
        fill: #fff2d0 !important;
      }
    }
  }

  &.active {
    background-color: #514b45;
    pointer-events: none;
    color: #514b45;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 6px;
    color: #fdce4f;
    min-width: auto;
  `}
`;

const StyledMobileContainer = styled.div`
  position: relative;
`;

const StyledImgBox = styled.div<{ show: boolean }>`
  background-color: #514b45;
  height: 2.4rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  box-sizing: border-box;
  transition: box-shadow 0.2s, transform 0.2s;
  ${({ show }) =>
    show
      ? `
    transform: translateY(3px);
  `
      : `
    box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  `}
`;

const StyledOptionContainer = styled.div<{
  isShow: boolean;
}>`
  position: absolute;
  top: calc(2.4rem + 5px);
  left: 0;
  padding: 0;
  width: 70px;
  height: auto;
  max-height: ${({ isShow }) => (isShow ? "150px" : "0")};
  background-color: #514b45;
  color: #fdce4f;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
  transition: max-height 0.2s;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  z-index: 110;
`;

const StyledNormalButton = styled(StyledBaseButton)`
  height: 36px;
  background-color: #fdce4f;
  color: #514b45;
  margin-right: 15px;
  border-radius: 1.3rem;
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  position: relative;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  width: auto;
  min-width: 7.8rem;
`;
