import { createContext } from "react";
import { FarmsContext } from "./types";
import { noFunc } from "src/utils";
import { tiny } from "../TinyProvider/TinyProvider";
import { getFarms } from "../../tiny/utils";

export const initState: FarmsContext = {
  farms: getFarms(tiny),
  stakeInfos: {},
  tvlTotal: 0,
  burned: "0",
  burnedFor30Days: "0",
  allStakedValue: [],
  price: {
    BNB: 0,
    BUSD: 1,
    USDT: 1,
    TINC: 0,
    LP: 0,
  },
  freshAllStakedValue: noFunc,
  freshLpFarmPoolInfo: noFunc,
  lpFarmNFTPowerInfo: {
    max_power: 0,
    mid_power: 0,
    max_bonus: 0,
    mid_bonus: 0,
  },
  calcApyByBonus: () => 0,
  tincAvgPrice: 900000,
  tincAvgPriceFor3Days: 900000,
  userCount: 0,
};

const context = createContext<FarmsContext>(initState);

export default context;
