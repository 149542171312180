import useMutationObserver from "@rooks/use-mutation-observer";
import { useEffect, useRef, useState } from "react";
import useIsMobile from "./useIsMobile";

export default function useBullentinHeight() {
  const isMobile = useIsMobile();
  const [migrationHeight, setMigrationHeight] = useState(0);
  const noticeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    noticeRef.current = document.getElementById("migration-notice");
    setMigrationHeight(noticeRef.current ? (isMobile ? 40 : 50) : 0);
  });

  useMutationObserver(
    noticeRef,
    () => {
      if (noticeRef && noticeRef.current?.style.display === "none") {
        setMigrationHeight(0);
      }
    },
    { attributes: true, attributeFilter: ["style"], attributeOldValue: true }
  );

  return migrationHeight;
}
