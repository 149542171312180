import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { formatFontSize } from "../../utils";

export interface BaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: number | string;
  height?: number;
  size?: "md" | "sm";
  fontSize?: number | string;
  ref?: React.RefObject<HTMLButtonElement>;
}

export default function BaseButton(props: PropsWithChildren<BaseProps>) {
  const {
    children,
    className,
    width,
    height,
    size = "md",
    fontSize,
    ref,
    ...rest
  } = props;
  return (
    <StyledBaseButton
      ref={ref}
      className={clsx(className, size)}
      width={width}
      height={height}
      type="button"
      fontSize={formatFontSize(fontSize)}
      {...rest}
    >
      {children}
    </StyledBaseButton>
  );
}

const { detect } = require("detect-browser");
const browser = detect();

const specialNames = ["safari", "ios", "ios-webview"];

interface BaseStyledProps {
  width?: number | string;
  height?: number;
  fontSize?: string;
  disabled?: boolean;
}

export const StyledBaseButton = styled.button<BaseStyledProps>`
  /* &.md {
    height: 32px;
    line-height: 1.2rem;
    font-size: 1.02rem;
  }
  &.sm {
    height: 28px;
    font-size: 14px;
  } */
  /* padding: ${specialNames.includes(browser.name) ? "2px 15px" : "4px 15px"};
  width: ${(props) => (props.width ? formatFontSize(props.width) : "auto")};
  height: ${(props) => (props.height ? formatFontSize(props.height) : "auto")};
  font-size: ${(props) => props.fontSize}; */
  &.md {
    height: 30px;
    line-height: normal;
    font-size: 18px;
    width: 6.3125rem;
  }
  &.sm {
    height: 28px;
    font-size: 16px;
  }

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 18px;
  color: white;
  background: #6a9cd9;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);

  font-weight: 600;

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #6a6a6a;
    color: #9f9f9f;

    > img {
      filter: grayscale(1);
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    /* height: 28px;
    font-size: 1rem; */

    &.md {
      height: 26px;
      line-height: normal;
      font-size: 12px;
      width: 6.6rem;
    }
    &.sm {
      width: 50px;
      height: 19px;
      font-size: 12px;
      padding: 0 10px;
    }

    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.21);
  `}
`;
