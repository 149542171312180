const Part_5 = [
  "5-part1",
  "5-part2",
  "5-part3",
  "5-part4",
  "5-part5",
  "5-part6",
  "5-part7",
  "5-part8",
  "5-part9",
  "5-part10",
  "5-part11",
  "5-part12",
  "5-part13",
  "5-part14",
  "5-part15",
  "5-part16",
  "5-part17",
  "5-part18",
  "5-part19",
  "5-part20",
];

export default Part_5;
