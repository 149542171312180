import React, { CSSProperties } from "react";

function IconBlive({
  fill,
  style,
}: {
  fill: CSSProperties["color"];
  style?: CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill={fill}
      style={style}
    >
      <path d="M11.7891 43.8722H17.2598V42.6243H13.1595V36.0728H11.7891V43.8722Z" />
      <path d="M18.7655 36.0728V43.8722H20.136V36.0728H18.7655Z" />
      <path d="M25.4216 42.0672L23.0706 36.0728H21.5553L24.7976 43.9279H26.001L29.2433 36.0728H27.7614L25.4216 42.0672Z" />
      <path d="M36.363 36.0728H30.5803V43.8722H36.4188V42.6465H31.9508V40.5518H35.8617V39.3262H31.9508V37.2984H36.363V36.0728Z" />
      <path d="M24.0068 26.2532L18.4401 20.6865L15.1917 23.9349L24.0068 32.75L32.822 23.9349L29.5736 20.6865L24.0068 26.2532ZM9.625 18.3818L12.8734 21.6302L16.1218 18.3818L12.8734 15.1334L9.625 18.3818ZM20.7516 18.3818L24 21.6302L27.2484 18.3818L24 15.1334L20.7516 18.3818ZM31.8782 18.3818L35.1266 21.6302L38.375 18.3818L35.1334 15.1266L31.8782 18.3818ZM24.0068 4L15.1917 12.8151L18.4401 16.0635L24.0068 10.4968L29.5736 16.0635L32.822 12.8151L24.0068 4Z" />
    </svg>
  );
}

export default IconBlive;
