import {
  useState,
  useCallback,
  useContext,
  Fragment,
  useEffect,
  memo,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router";

import useModal from "src/hooks/useModal";
import useFarmPrice from "src/hooks/useFarmPrice";
import useIsMobile from "src/hooks/useIsMobile";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
// import useLimitedInterval from "src/hooks/useLimitedInterval";
import { Context } from "src/contexts/TinyProvider";
import { PageContext } from "src/contexts/Page";

import SideBar from "../SideBar";
import AirdropModal from "./AirdropModal";
import StatusButtons from "./components/StatusButtons";
import Notification from "src/components/Notification";
import MigrationNotice from "./components/MigrationNotice";
import BourseModal from "../SideBar/components/BourseModal";
import GuideButton from "./components/GuideButton";

import airdrop from "src/images/airdrop.png";

import bg1 from "src/images/upgrade/background1.png";
import bg2 from "src/images/upgrade/background2.png";

import type { BigNumber } from "ethers";
import GasReturn from "src/components/Svg/GasReturn";
import TinyToolModal from "src/components/TinyToolModal";
import HelperDropDown from "./components/HelperDropDown";
import AirdropDropDown from "./components/AirdropDropdown";

function Topbar() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { tiny, startFreshTINCBalance } = useContext(Context);
  const { newAirdropData, showGasRefundModal, showSpiritAirdropModal } =
    useContext(PageContext);

  const account = tiny.myAccount;
  const airdropContract = tiny.contracts.TinyAirdrop;
  // console.log("chainId", chainId);
  const [price] = useFarmPrice();

  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  const [airdropTotal, setAirdropTotal] = useState<{
    tincAmount: BigNumber;
    runeValue: BigNumber;
  }>();

  // console.log('airdropTotal', airdropTotal);
  const getTotal = useCallback(() => {
    if (!account) return;
    // console.log('airdropContract', airdropContract);
    airdropContract.getAirdrops().then((res: [BigNumber, BigNumber]) => {
      // console.log("getAirdrops res", res);
      const [tincAmount, runeValue] = res;
      if (!tincAmount.isZero() || !runeValue.isZero()) {
        setAirdropTotal({ tincAmount, runeValue });
      }
    });
  }, [airdropContract, account]);

  const bnbGasAmount = newAirdropData?.bnbAmount;
  const spiritValue = newAirdropData?.spiritValue;

  // const [_] = useAutoFreshRequest(async () => getTotal(), [getTotal]);
  const claimAirdrop = useCallback(() => {
    const message = t("Claim Airdrop Rewards");
    return handleTransactionReceipt(tiny.claimAirdrop(), message).then((tx) => {
      if (tx) {
        handleTxConfirm(tx.hash).then(() => {
          startFreshTINCBalance();
        });
      }
    });
  }, [
    tiny,
    handleTransactionReceipt,
    startFreshTINCBalance,
    handleTxConfirm,
    t,
  ]);

  const [showAirdropModal] = useModal(
    <AirdropModal
      total={airdropTotal}
      claimAirdrop={claimAirdrop}
      fresh={getTotal}
    />,
    [airdropTotal, getTotal]
  );

  useEffect(() => {
    if (account) {
      getTotal();
    }
  }, [account, getTotal]);

  useEffect(() => {
    if (airdropTotal) {
      showAirdropModal();
    }
  }, [airdropTotal, showAirdropModal]);

  const [showBourseModal, onBourseModalDismiss] = useModal(<BourseModal />, []);

  return (
    <Fragment>
      <StyledHeader>
        {/* <MigrationNotice>
          <span>{t("christmas.event.notice")}</span>
        </MigrationNotice> */}
        <StyledSubHeader>
          {!isMobile && (
            <StyledAuditBox>
              {/* <GuideButton to={"/guide"}></GuideButton> */}
              <HelperDropDown />
            </StyledAuditBox>
          )}
          <StyledLeft className="display-flex align-center pl-10 fw-500">
            <StyledImg
              width={360}
              height={240}
              className="mr-5"
              src="//images.tinyworlds.io/logo/tinyworld.png?version=4"
              alt="TinyWorld"
              onClick={() => isMobile && history.push("/home")}
            />
            <StyledTINCPriceBox
              // onClick={() =>
              //   isMobile &&
              //   window.open(
              //     "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x05aD6E30A855BE07AfA57e08a4f30d00810a402e"
              //   )
              // }
              onClick={showBourseModal}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <span className="mobile-hidden">BUY</span> TINC
              </div>
              <StyledPrice>${price["TINC"].toFixed(4)}</StyledPrice>
            </StyledTINCPriceBox>
          </StyledLeft>
          <StyledRight>
            <StyledPCNews>
              <Notification />
            </StyledPCNews>

            <StatusButtons />
          </StyledRight>
        </StyledSubHeader>
      </StyledHeader>
      <SideBar />
    </Fragment>
  );
}

export default memo(Topbar);

const StyledPCNews = styled.div`
  display: block;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`;

const StyledHeader = styled.header`
  position: absolute;
  /* position: fixed; */
  top: 0;
  left: 0;
  padding-left: 12.5rem;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: static;
    padding-left: 0;
  `}
`;

const StyledSubHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 5.3rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: space-between;
    align-items: normal;
    height: 6rem;
    padding-left: 0;
    background-image: url(${bg2}), url(${bg1}), linear-gradient(#F9BE3A, #F79905);
    background-color: #F79905;
    background-size: 110%, 35%;
    background-repeat: no-repeat;
    background-position: left -30px top -10px,right 0% top 50%;
  `};
`;

const StyledLeft = styled.div`
  display: none;
  /* font-size: 1.3rem; */
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: inline-flex;
    height: inherit;
  `}
`;

const StyledAuditBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledAudit = styled.a`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const StyledImg = styled.img`
  position: relative;
  height: 60px;
  width: 90px;
  object-fit: contain;
  z-index: 1;
  margin-top: -6px;
`;

const StyledTINCPriceBox = styled.div`
  margin-left: -45px;
  padding: 2px 12px 2px 40px;
  color: ${(props) => props.theme.color.yellow};
  font-weight: 600;
  background-color: #905419;
  border: 2px solid #fdce4f;
  border-radius: 36px;
  line-height: 15px;
  min-width: 3rem;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 1rem; */
`;

const StyledPrice = styled.span`
  color: white;
`;

const StyledRight = styled.div`
  display: inline-flex;
  align-items: center;
  padding-right: 1rem;
`;

const StyledDrop = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  // width: 7.8rem;
  height: 2.4rem;
  background-color: #5194e5;
  border-radius: 33px;
  text-align: center;
  font-size: 1.1rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.21);

  width: auto;
  padding: 0 1rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 3.2rem;
    background-size: 100% 100%;
    > span {
      display: none;
    }
    margin-right: 5px;
  `};
`;
