import React, { CSSProperties } from "react";
import styled from "styled-components";

const ModalActions: React.FC<{ style?: CSSProperties }> = ({
  children,
  style,
}) => {
  const l = React.Children.toArray(children).length;
  return (
    <StyledModalActions style={style}>
      {React.Children.map(children, (child, i) => (
        <>
          <StyledModalAction>{child}</StyledModalAction>
          {i < l - 1 && <StyledSpacer />}
        </>
      ))}
    </StyledModalActions>
  );
};

const StyledModalActions = styled.div`
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.grey[100]}00;
  display: flex;
  height: 6rem;
  margin: ${(props) => props.theme.spacing[1]}px
    ${(props) => -props.theme.spacing[2]}px
    ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: ${-theme.spacing[2]}px ${-theme.spacing[2]}px;
    padding: ${theme.spacing[2]}px;
  `}
`;

const StyledModalAction = styled.div`
  /* flex: 1; */
`;

const StyledSpacer = styled.div`
  width: ${(props) => props.theme.spacing[4]}px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // width:${theme.spacing[3]}px;
  `}
`;

export default ModalActions;
