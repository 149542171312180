import { useCallback } from "react";
import useTiny from "./useTiny";
import useModal from "src/hooks/useModal";
import WalletProviderModal from "src/components/WalletProviderModal";

const useCheckAuth = () => {
  const [showWalletList] = useModal(<WalletProviderModal />);
  const tiny = useTiny();
  const account = tiny.myAccount;

  const checkAuth = useCallback(
    (fn?: () => void, cb?: () => void) => {
      if (account) {
        return fn && fn();
      }
      showWalletList(() => {
        // cb && cb();
      });
    },
    [account, showWalletList]
  );

  return { checkAuth };
};

export default useCheckAuth;
