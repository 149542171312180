import { fileFetch } from "./util";
import type { gradeType } from "src/config";
import type { HeroDesc } from "./typings";

function getJson(url: string, options?: RequestInit) {
  return fetch(url, options).then((res) => res.json());
}

export interface ExploreConfigDetail {
  grade: gradeType;
  crystal: number[];
  drop?: number[];
  exptime: number;
}
export function getHeroExploreConfig(
  version: number,
  options?: RequestInit
): Promise<ExploreConfigDetail[]> {
  return getJson("/data/explore.json?version=" + version, options);
}

export function getHeroDescription(
  version: number,
  options?: RequestInit
): Promise<HeroDesc[]> {
  return fileFetch("/json/hero.json?version=" + version, options).then((res) =>
    res.json()
  );
}
