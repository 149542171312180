export type ChainId = 56 | 97;

export type Configuration = {
  chainId: ChainId;
  scanUrl: string;
  defaultProvider: string[];
  // defaultProvider: Ethereum | string;
  externalTokens: { [contractName: string]: string };
  refreshInterval: number;
  gasLimitMultiplier: number;
  chargeAddress: string;
  deployments: any;
  gameApi: string;
  gameApi2: string;
  zeroAddress: string;
  appUrl: string;
  appVersion: string;
};

export const chainId = 56 as ChainId;

console.log("chainId", chainId);

export const SPIRIT_CLOSE = false;

const providerMap = {
  97: [
    // "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "https://data-seed-prebsc-1-s2.binance.org:8545/",
    "https://data-seed-prebsc-1-s3.binance.org:8545/",
    // "https://data-seed-prebsc-2-s1.binance.org:8545/",
    // "https://data-seed-prebsc-2-s2.binance.org:8545/",
    // "https://data-seed-prebsc-2-s3.binance.org:8545/",
  ],
  56: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
    "https://bsc.nodereal.io",
  ],
};

const externalTokenMap = {
  97: {
    BNB: "0x485813Ea110C62CDd6A6a47854E0C4Dd1aC51519",
    TINC: "0xa1122A74240d799a21C1B2e6cBD60Fb2c29B10FF",
    BUSD: "0xB7486B62B16aCd1e688693d1486Cc81D750010F5", // 测试用 BUSD
    USDT: "0xD9FB2B16B7D93f8EC0CDfFe07c98281ac3bBF11D",
    LP: "0x1925c7552561017395e8fc366a2dbb80f13e9694",
    "TINC-BUSD LP": "0xDaf859F41a4473BA37B05BB55Ba386122011701B",
    "BUSD-USDT LP": "0x1925c7552561017395e8fc366a2dbb80f13e9694",
    "BNB-BUSD LP": "0xd70a868971CeeAf1de5aE77D7aD789974991d208",
    SPIRIT: "0x15cc9036189263528BA509aA8CE20A17f69a94D9",
    "TINC-SPIRIT LP": "0xB75237646D73E4b51c4D50957fD483722ee59840",
  },
  56: {
    BNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    TINC: "0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
    BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    BTCB: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    CAKE: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    LP: "0x0d5b9A0f4315a4bcE36D1Ea7d6B6d3123167aFAa",
    "TINC-BNB LP": "0x0d5b9A0f4315a4bcE36D1Ea7d6B6d3123167aFAa",
    "BUSD-USDT LP": "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
    "BNB-BUSD LP": "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
    "CAKE-BNB LP": "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
    "ETH-BNB LP": "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc",
    "BTCB-BNB LP": "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082",
    "BTCB-ETH LP": "0xD171B26E4484402de70e3Ea256bE5A2630d7e88D",
    "USDC-BUSD LP": "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1",
    "USDC-USDT LP": "0xEc6557348085Aa57C72514D67070dC863C0a5A8c",
    SPIRIT: "0x37e678d782d38a75EB16130b39C4ce2d7E3cC808",
    "TINC-SPIRIT LP": "0x137594d4b7cb34EfC3692D5AFF5883fc244691A1",
  },
};

const chargeAddressMap = {
  97: "0xEeB1E8Fcd51a6D4E720efD25dEf0d95cbD789bdc",
  56: "0x06066a6e7a165DF8989A4CEAA1b2104439BB2cEB",
};

const gameApiMap = {
  56: "https://tkapi.tinyworlds.io/api/sign-in/internal/",
  97: "https://tkapi-test.tinyworlds.io/api/sign-in/internal/",
};

const gameApiMap2 = {
  56: "https://tkapi.tinyworlds.io/api/sign-in/internal@2/",
  97: "https://tkapi-test.tinyworlds.io/api/sign-in/internal@2/",
};

const deployments = (function () {
  if (chainId === 56) {
    const abis1 = require("./tiny/abis/deployments.fe.core.bscm.json");
    const abis2 = require("./tiny/abis/deployments.fe.per.bscm.json");
    const abis3 = require("./tiny/abis/deployments.fe.camp.bscm.json");
    return { ...abis1, ...abis2, ...abis3 };
  } else if (chainId === 97) {
    const abis1 = require("./tiny/abis/deployments.fe.core.bsct.json");
    const abis2 = require("./tiny/abis/deployments.fe.per.bsct.json");
    const abis3 = require("./tiny/abis/deployments.fe.camp.bsct.json");
    return { ...abis1, ...abis2, ...abis3 };
  }
})();

const config: Configuration = {
  chainId: chainId,
  defaultProvider: providerMap[chainId],
  scanUrl:
    chainId === 56 ? "https://bscscan.com/" : "https://testnet.bscscan.com/",
  externalTokens: externalTokenMap[chainId],
  refreshInterval: 10000,
  gasLimitMultiplier: 1.4,
  // lordTINCPerDay: 19208 * 2, //奖励临时加倍
  chargeAddress: chargeAddressMap[chainId],
  deployments,
  gameApi: gameApiMap[chainId],
  gameApi2: gameApiMap2[chainId],
  zeroAddress: "0x0000000000000000000000000000000000000000",
  appUrl:
    chainId === 56
      ? "https://app.tinyworlds.io"
      : "https://test.tiny-farm.pages.dev",
  appVersion: "1.0.11",
};

// console.log("config", config);

export type nftType = 1 | 2; // 2 目前不会用到，是预留用，不确定之后会不会用到

export type gradeType = 1 | 2 | 3 | 4 | 5 | 6; // hero 有 6 个档次

export type heroRole = 1 | 2 | 3; // hero 有 3 种职业，1:战士 2:游侠 3:法师

// 游戏的基础属性
export type propSymbol = "attack" | "defense" | "hp" | "luck";
export type propGrowSymbol = "gattack" | "gdefense" | "ghp" | "gluck";
export type exploreSymbol = "exploreTime" | "drop" | "crystal" | "drop2";

// 英雄拥有的属性排序
export const heroPropNameSort: propSymbol[] = [
  "attack",
  "defense",
  "hp",
  "luck",
];

// NFT 可以筛选的属性
export enum filterKeyEnum {
  grade = "grade",
  role = "role",
  code = "code",
  stamina = "stamina",
}

export interface FilterItemGradeEnum {
  key: filterKeyEnum.grade;
  value: gradeType | undefined;
}
export interface FilterItemClassEnum {
  key: filterKeyEnum.role;
  value: heroRole | undefined;
}
export interface FilterItemStaminaEnum {
  key: filterKeyEnum.stamina;
  value: string;
}

export interface FilterItemCodeEnum {
  key: filterKeyEnum.code;
  value: string;
}

export type filtersType =
  | FilterItemGradeEnum
  | FilterItemClassEnum
  | FilterItemStaminaEnum;

export type filterParamType = filtersType | FilterItemCodeEnum;

// NFT 可以排序的属性

export enum SortOrder {
  DESC = "DESC",
  ASC = "ASC",
}

export enum SortKey {
  grade = "grade",
  price = "price",
  startAt = "startAt",
  power = "power",
  energy = "energy",
  level = "level",
  unitPrice = "unitPrice",
  referencePrice = "referencePrice",
  breed = "breed",
  point = "point",
}

export interface SortInfo {
  key: SortKey;
  order: SortOrder;
}

export default config;

// 游戏稀有度等级对应的称号
export const gradeTitle: {
  [key in gradeType]: string;
} = {
  1: "normal",
  2: "rare",
  3: "unique",
  4: "legendary",
  5: "epic",
  6: "supergod",
};

export const jobKey: {
  [key in heroRole]: string;
} = {
  1: "Warrior",
  2: "Ranger",
  3: "Wizard",
};

// 英雄最大等级
// nftlogic.setMaxLevel([1,2,3,4,5], [8,10,10,20,20]);
export const heroMaxLevelMap = {
  1: 8,
  2: 10,
  3: 10,
  4: 20,
  5: 20,
  6: 20,
};

export const heroGradeTitle: {
  [key in gradeType]: string;
} = {
  1: "N",
  2: "R",
  3: "SR",
  4: "SSR",
  5: "SSSR",
  6: "UR",
};

export const gradeDurability: {
  [key in gradeType]: number;
} = {
  1: 60,
  2: 90,
  3: 120,
  4: 180,
  5: 210,
  6: 240,
};

export const staminaCoefficient = 1;

const rewardList = [750, 500, 300, 200, 150, 100, 50, 30, 20];
export const getReward = (ranking: number) => {
  if (ranking === 1) {
    return rewardList[0];
  } else if (ranking === 2) {
    return rewardList[1];
  } else if (ranking === 3) {
    return rewardList[2];
  } else if (ranking === 4) {
    return rewardList[3];
  } else if (ranking === 5) {
    return rewardList[4];
  } else if (ranking <= 10) {
    return rewardList[5];
  } else if (ranking <= 30) {
    return rewardList[6];
  } else if (ranking <= 50) {
    return rewardList[7];
  }
  return rewardList[8];
};

export const bonusLevelAmountMap = {
  1: 4,
  2: 5,
  3: 6,
  4: 7,
  5: 8,
  6: 10,
  7: 12,
  8: 14,
  9: 16,
  10: 20,
  11: 24,
};

export const MAX_FARMING_NFT_NUMBER = 12;

console.log("Tiny Farm App Version:", config.appVersion);

export const heroCodeMap: {
  [key in number]: [number, number];
} = {
  1: [101, 112],
  2: [201, 212],
  3: [301, 312],
  4: [401, 424],
  5: [601, 624],
};

export const checkinCrystalMap: {
  [day: number]: number;
} = {
  1: 300,
  2: 900,
  3: 450,
  4: 450,
  5: 1500,
  6: 450,
  7: 450,
};

export const heroPrefillColor: string[] = [
  "#ff1e52",
  "#ff6600",
  "#9c6dde",
  "#73b8d8",
  "#85c196",
  "#949393",
];

export const limit: {
  [key in string]: number;
} = {
  MAX_SHOPPINGCART_NUMBER: 10,
};

// Date.UTC(year,month,day,hours,minutes,seconds,ms)
export const boostPoolAdjustTime = Date.UTC(2023, 8, 6, 8, 0, 0, 0);
export const NFTPoolAdjustTime = Date.UTC(2023, 8, 4, 8, 0, 0, 0);

export const lordCastleLevelWeight = [300, 300, 400];

export const proposalBaseUrl = "https://snapshot.org/#/tinygames.eth/proposal/";
