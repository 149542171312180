import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

const relativeTimeObj = {
  future: "%s",
  past: "%s",
  s: function (number: number, withoutSuffix: any, key: any, isFuture: any) {
    return withoutSuffix ? number + " s" : "1 m";
  },
  m: function (number: number, withoutSuffix: any, key: any, isFuture: any) {
    return Math.ceil(number / 60) + " m";
  },
  mm: "%d m",
  h: function (number: number, withoutSuffix: any, key: any, isFuture: any) {
    return number < 60 ? number + " m" : Math.ceil(number / 60) + " h";
  },
  hh: "%d h",
  d: function (number: number, withoutSuffix: any, key: any, isFuture: any) {
    return number < 24 ? number + " h" : Math.ceil(number / 24) + " days";
  },
  dd: "%d days",
  M: "1 month",
  MM: "%d months",
  y: "1 year",
  yy: "%d years",
};

const dayjsConfig = {
  zh: {
    relativeTime: relativeTimeObj,
  },
  en: {
    relativeTime: relativeTimeObj,
  },
};

const getTimeFromNow = (time: string | number) => {
  return dayjs()
    .second(Math.abs(dayjs().diff(dayjs(time))))
    .format("HH:mm:ss");
};

export { dayjsConfig, getTimeFromNow };

export default dayjs;
