const Part_1 = [
  "1-part1",
  "1-part2",
  "1-part3",
  "1-part4",
  "1-part5",
  "1-part6",
  "1-part7",
  "1-part8",
  "1-part9",
  "1-part10",
  "1-part11",
  "1-part12",
  "1-part13",
  "1-part14",
  "1-part15",
  "1-part16",
  "1-part17",
  "1-part18",
  "1-part19",
  "1-part20",
  "1-part21",
  "1-part22",
  "1-part23",
  "1-part24",
  "1-part25",
  "1-part26",
  "1-part27",
  "1-part28",
  "1-part29",
  "1-part30",
  "1-part31",
  "1-part32",
  "1-part33",
  "1-part34",
  "1-part35",
];

export default Part_1;
