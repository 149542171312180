import React, { useCallback } from "react";
import styled from "styled-components";
import metamaskIcon from "src/images/connect/metamask.png";
import { importTINC } from "src/tiny/utils";

function LinkMetaMask() {
  return (
    <StyledContainer onClick={importTINC}>
      <span className="mr-4 ml-4">+</span>
      <img src={metamaskIcon} alt="" />
    </StyledContainer>
  );
}

export default LinkMetaMask;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  border: 1.4px solid #c6b27e;
  box-sizing: border-box;
  border-radius: 3rem;
  max-height: 25px;
  cursor: pointer;
  user-select: none;
  background: #f5e4b6;
  font-size: 1rem;

  &:hover {
    background: #fff2d0;
  }

  &:clicked {
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 16px;
  }
`;
