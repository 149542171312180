export default function getErrorMsgKey(message: string) {
  if (message.includes("transfer amount exceeds balance")) {
    return "error.Insufficient Balance";
  }
  if (message.includes("network error")) {
    return "NetworkError";
  }
  if (message.includes("defending")) {
    return "AttackFailureWarning";
  }
  return undefined;
}
