import useTiny from "./useTiny";
import { useQuery } from "react-query";
import { BigNumber } from "ethers";
import { useState } from "react";
import { getDefaultProvider } from "src/utils/provider";
import { getMulticallContract } from "src/tiny/utils";
const privider = getDefaultProvider();

function useUserWallet() {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const TINC = tiny.externalTokens.TINC;
  const BUSD = tiny.externalTokens.BUSD;

  const runeContract = tiny.contracts.TinyNFTRune;
  const runev2Contract = tiny.contracts.TinyRuneNFTV2;
  const multicallContract = getMulticallContract(tiny);

  // const [updateTinc, setUpdateTinc] = useState<BigNumber>();
  // const [updateBusd, setUpdateBusd] = useState<BigNumber>();
  // const [updateSpirit, setUpdateSpirit] = useState<BigNumber>();
  // const [updateBspirit, setUpdateBspirit] = useState<BigNumber>();

  const { data, refetch } = useQuery(
    ["USER_WALLET"],
    async () => {
      try {
        if (account) {
          let tincBalance = await TINC.balanceOf(account);
          let busdBalance = await BUSD.balanceOf(account);
          // let spiritBalance: BigNumber = await runeContract.balanceOf(
          //   account,
          //   2
          // );
          // let lockSpiritBalance: BigNumber = await runeContract.balanceOf(
          //   account,
          //   3
          // );
          let bnbBalance = await privider.getBalance(account);

          const calldata = [
            [
              runeContract.address, // 要查询的合约的地址
              runeContract.interface.encodeFunctionData("balanceOf", [
                account,
                1,
              ]),
            ],
            [
              runeContract.address, // 要查询的合约的地址
              runeContract.interface.encodeFunctionData("balanceOf", [
                account,
                2,
              ]),
            ],
            [
              runeContract.address, // 要查询的合约的地址
              runeContract.interface.encodeFunctionData("balanceOf", [
                account,
                3,
              ]),
            ],
            [
              runev2Contract.address, // 要查询的合约的地址
              runev2Contract.interface.encodeFunctionData("balanceOf", [
                account,
              ]),
            ],
          ];

          const { returnData }: { returnData: string[] } =
            await multicallContract.aggregate(calldata);

          const [redeemedRuneNum, spiritBalance, lockSpiritBalance, runev2Num] =
            returnData.map((ele) => BigNumber.from(ele));

          return {
            tincBalance,
            busdBalance,
            spiritBalance,
            lockSpiritBalance,
            bnbBalance,
            runeBalance: redeemedRuneNum.add(runev2Num),
          };
        }
      } catch (error) {}
    },
    {
      refetchInterval: 30_000,
      enabled: typeof account === "string",
    }
  );

  return {
    ...data,
    refetch,
  };
}

export default useUserWallet;
