import { useEffect, FC, useMemo } from "react";
import styled from "styled-components";
import { change, removeZoom } from "src/utils/changeZoom";
import veinR from "src/images/vein-right.png";
import useBullentinHeight from "src/hooks/useBullentinHeight";

const Page: FC = ({ children }) => {
  useEffect(() => {
    window.addEventListener("resize", change);
    change();
    return () => {
      window.removeEventListener("resize", change);
      removeZoom();
    };
  }, []);

  const migrationHeight = useBullentinHeight();

  return (
    <StyledContainer migrationHeight={migrationHeight}>
      {children}
      <StyledImg src={veinR} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ migrationHeight: number }>`
  height: 100%;
  width: 100%;
  padding-left: 12.5rem;
  box-sizing: border-box;
  overflow: hidden;
  & > * {
    box-sizing: border-box;
  }
  // 5.5rem
  padding-top: ${(props) => `calc(5.5rem + ${props.migrationHeight}px)`};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
    flex-direction: column;
    padding: 0;
  `};
`;

const StyledImg = styled.img`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(25%, -50%) scale(1.4);
  z-index: -10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    transform: translate(35%,-45%);
  `}
`;

export default Page;
