const Part_4 = [
  "4-part1",
  "4-part2",
  "4-part3",
  "4-part4",
  "4-part5",
  "4-part6",
  "4-part7",
  "4-part8",
  "4-part9",
  "4-part10",
  "4-part11",
  "4-part12",
  "4-part13",
  "4-part14",
  "4-part15",
  "4-part16",
  "4-part17",
  "4-part18",
  "4-part19",
  "4-part20",
  "4-part21",
  "4-part22",
  "4-part23",
  "4-part24",
  "4-part25",
  "4-part26",
  "4-part27",
  "4-part28",
  "4-part29",
  "4-part30",
];

export default Part_4;
