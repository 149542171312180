import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import BackButton from "src/components/BackButton";
import Container from "src/components/Container";
import useIsMobile from "src/hooks/useIsMobile";
import styled from "styled-components";
import di_2 from "src/images/di_2.png";
import refresh from "src/images/pet/filter/refresh.png";
import PetsMockBox from "./components/PetsMockBox";
import TButton from "src/components/Button/ParallelogramButton";
import breedBorder from "src/images/pet/mock_breed_border.png";
import { usePetsDetail } from "src/hooks/usePetsHooks";
import {
  PartInfo,
  PetAttr,
  PetDetail,
  PetsElement,
} from "src/components/NFTCard/PetCard/type";
import PetsMockPart from "./components/PetsMockPart";
import { petGrade } from "src/components/NFTCard/PetCard/const";
import {
  PartInfoEx,
  PetsMockContext,
  getMockPetsPower,
  petsGenesMockRandom,
  petsGenesProbability,
  petsGenesNotBreedGrade,
} from "./const";
import clsx from "clsx";
import PetsMockDetail from "./components/PetsMockDetail";
import Button from "src/components/Button";
import clearIcon from "src/images/pet/filter/clear-icon.png";

function MockBreed() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [femaleId, setFemaleId] = useState<string>("");
  const [maleId, setMaleId] = useState<string>("");
  const [mockPets, setMockPets] = useState<
    {
      element: PetsElement;
      parts: Record<keyof PetAttr, PartInfo[]>;
      id: number;
      power: number;
    }[]
  >([]);
  const { data: femaleInfo, refetch: femaleRefetch } = usePetsDetail(
    Number(femaleId)
  );
  const { data: maleInfo, refetch: maleRefetch } = usePetsDetail(
    Number(maleId)
  );
  const [moreActive, setMoreActive] = useState(true);

  /* 
  {
    result: PartInfoEx[];
    key: keyof PetAttr;
  }[]
  */
  const petProbabilitys = useRef<
    {
      result: PartInfoEx[];
      key: keyof PetAttr;
    }[]
  >([]);

  const petsPartsGenes: Record<keyof PetAttr, PartInfoEx[]> = useMemo(() => {
    const partGenes: Record<keyof PetAttr, PartInfoEx[]> = {
      part_1: [],
      part_2: [],
      part_3: [],
      part_4: [],
      part_5: [],
      part_6: [],
    };

    if (femaleInfo?.parts) {
      Object.keys(femaleInfo?.parts).map((ele, index) => {
        const partProp = ele as keyof PetAttr;

        if (femaleInfo.parts![partProp] !== undefined) {
          partGenes[partProp].push(
            ...femaleInfo.parts![partProp].map((ele, index) => ({
              ...ele,
              probability:
                ele.grade !== petsGenesNotBreedGrade
                  ? petsGenesProbability[index]
                  : 0,
            }))
          );
        }
      });
    }

    if (maleInfo?.parts) {
      Object.keys(maleInfo?.parts).map((ele, index) => {
        const partProp = ele as keyof PetAttr;

        if (maleInfo.parts![partProp] !== undefined) {
          partGenes[partProp].push(
            ...maleInfo.parts![partProp].map((ele, index) => ({
              ...ele,
              probability:
                ele.grade !== petsGenesNotBreedGrade
                  ? petsGenesProbability[index]
                  : 0,
            }))
          );
        }
      });
    }
    return partGenes;
  }, [femaleInfo, maleInfo]);

  const petsPartsInfo = useMemo(() => {
    const petsParts = femaleInfo?.parts || maleInfo?.parts;

    return petsParts
      ? Object.keys(petsParts).map((ele, index) => {
          const key = ele as keyof PetAttr;
          return (
            <PetsMockPart mockPets={mockPets} partProp={key} key={index} />
          );
        })
      : Object.keys(petGrade).map((key, index) => (
          <PetsMockPart
            mockPets={mockPets}
            partProp={key as keyof PetAttr}
            key={index}
          />
        ));
  }, [femaleInfo?.parts, maleInfo?.parts, mockPets]);

  const petsRef = useRef(document.createElement("div"));

  const handlerMockBreed = useCallback(() => {
    const parts = petsGenesMockRandom({
      petsPartsGenes,
      femaleBreedCnt: femaleInfo?.breedCnt || 0,
      maleBreedCnt: maleInfo?.breedCnt || 0,
      femaleOrigin:
        typeof femaleInfo?.femaleId !== "number" &&
        typeof femaleInfo?.maleId === "number",
      maleOrigin:
        typeof maleInfo?.femaleId !== "number" &&
        typeof maleInfo?.maleId === "number",
    });
    setMockPets([
      ...mockPets,
      {
        id: mockPets.length + 1,
        parts,
        element: [femaleInfo?.element, maleInfo?.element][
          Math.round(Math.random() * 1)
        ] as PetsElement,
        power: getMockPetsPower({ parts }),
      },
    ]);
  }, [femaleInfo, maleInfo, mockPets, petsPartsGenes]);

  const canMockBreed = useMemo(() => {
    if (femaleInfo && femaleInfo?.genes && maleInfo && maleInfo?.genes) {
      const femaleArr = [
        femaleInfo?.femaleId,
        femaleInfo?.maleId,
        Number(femaleId),
      ];
      const maleArr = [maleInfo?.maleId, maleInfo?.femaleId, Number(maleId)];
      const check = femaleArr.filter((ele, index) => {
        return typeof ele === "number" && maleArr.includes(ele);
      });
      return check.length > 0 ? false : true;
    }
    return true;
  }, [femaleId, femaleInfo, maleId, maleInfo]);

  const partsRender = useMemo(() => {
    return (
      <>
        {isMobile ? (
          moreActive && (
            <StyledOpacity>
              <StyledPetsParts>{petsPartsInfo}</StyledPetsParts>
            </StyledOpacity>
          )
        ) : (
          <StyledPetsParts>{petsPartsInfo}</StyledPetsParts>
        )}
      </>
    );
  }, [isMobile, moreActive, petsPartsInfo]);

  // useEffect(() => {
  //   if (mockPets.length < 10000 && femaleInfo?.genes && maleInfo?.genes) {
  //     handlerMockBreed();
  //   } else {
  //     console.log({
  //       petProbabilitys,
  //       mockPets,
  //     });
  //   }
  // }, [
  //   femaleInfo?.genes,
  //   handlerMockBreed,
  //   maleInfo?.genes,
  //   mockPets,
  //   mockPets.length,
  // ]);

  const petsRender = useMemo(() => {
    return (
      <StyledMockResult>
        {mockPets.map(({ element, parts, id, power }, index) => {
          try {
            if (Object.keys(parts).length > 0) {
              return (
                <PetsMockDetail
                  key={id}
                  element={element}
                  parts={parts}
                  power={power}
                  id={id}
                />
              );
            } else {
              return <></>;
            }
          } catch (error) {
            console.error({ error });
          }
        })}
      </StyledMockResult>
    );
  }, [mockPets]);

  return (
    <PetsMockContext.Provider
      value={{
        femaleInfo,
        maleInfo,
        petProbabilitys,
        petsPartsGenes,
      }}
    >
      <Container size="lg">
        <BackButton //over
        />
        <StyledContainer>
          <StyledMockPetsBox>
            <StyledMockPetsBoxHeader>
              <StyledRefreshBtn
                onClick={() => {
                  setFemaleId("");
                  setMaleId("");
                }}
                src={refresh}
                alt=""
              />
              {t("Pets Mock Breed")}
            </StyledMockPetsBoxHeader>
            <StyledPetsMain>
              <StyledPetsBox ref={petsRef}>
                <PetsMockBox
                  setId={setFemaleId}
                  id={femaleId}
                  petsRef={petsRef}
                  info={femaleInfo?.genes ? femaleInfo : undefined}
                ></PetsMockBox>
                <PetsMockBox
                  setId={setMaleId}
                  id={maleId}
                  petsRef={petsRef}
                  info={maleInfo?.genes ? maleInfo : undefined}
                ></PetsMockBox>
              </StyledPetsBox>
              <StyledLine>
                <StyledSpiritSolidImg src={breedBorder} />
              </StyledLine>
              <div className="flex">
                <StyledMockBreedButton
                  canMockBreed={canMockBreed}
                  style={{
                    width: "auto",
                    margin: "0 auto",
                    userSelect: "none",
                  }}
                  onClick={() => {
                    handlerMockBreed();
                  }}
                  disabled={
                    !(femaleInfo?.genes && maleInfo?.genes && canMockBreed)
                  }
                >
                  {t(canMockBreed ? "Let’s Breed!" : "Cannot Mock Breed")}
                </StyledMockBreedButton>
              </div>
            </StyledPetsMain>
          </StyledMockPetsBox>
          {(femaleInfo || maleInfo) && isMobile && (
            <StyledMore>
              <span
                onClick={() => {
                  setMoreActive(!moreActive);
                }}
                className={clsx(moreActive && "active")}
              >
                {t("Genetic")}
              </span>
            </StyledMore>
          )}
          {partsRender}
          <StyledMockResultBox>
            {mockPets.length > 0 && (
              <Button
                onClick={() => {
                  setMockPets([]);
                }}
                style={{
                  width: "auto",
                  padding: "0 1rem",
                  color: "#FDCE4F",
                  margin: isMobile ? "0 auto" : "unset",
                }}
              >
                <img className="mr-6" src={clearIcon} alt="" />
                Clear All
              </Button>
            )}
            {petsRender}
          </StyledMockResultBox>
        </StyledContainer>
      </Container>
    </PetsMockContext.Provider>
  );
}

export default MockBreed;

const StyledMockBreedButton = styled(TButton)<{ canMockBreed: boolean }>`
  width: auto;
  margin: 0 auto;
  userselect: none;

  &[disabled] {
    ${({ canMockBreed }) => {
      return !canMockBreed ? "background: #C34343;color: #FFFFFF;" : "";
    }}
  }
`;

const StyledMockResult = styled.div`
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.4rem;
  flex-wrap: wrap;
`;

const StyledOpacity = styled.div`
  animation: elementShow 500ms linear;
  padding: 14px 0;
  width: 100%;
`;

const StyledMore = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 10px;
  width: 100%;

  span {
    cursor: pointer;
    user-select: none;

    &.active {
      ::after {
        transform: rotate(-180deg) translateY(1px);
      }
    }

    ::after {
      content: " ";
      box-sizing: border-box;
      margin-left: 2px;
      display: inline-block;
      transform: translateY(2px);
      border-width: 6px 4px 2px;
      border-style: solid;
      border-color: #514b45 transparent transparent transparent;
      transition: transform 500ms;
    }
  }
`;

const StyledPetsBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: unset;
    grid-gap:unset;
    gap: 12px;
  `}
`;

const StyledLine = styled.div`
  display: flex;
`;

const StyledSpiritSolidImg = styled.img`
  width: 52%;
  margin: 6px auto;
  user-select: none;
`;

const StyledPetsMain = styled.div`
  padding: 1.6rem 1.6rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.5rem;
    padding-top: 2rem;
  `}
`;

const StyledRefreshBtn = styled.img`
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
`;

const StyledMockPetsBoxHeader = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  font-size: 1.3rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 2rem;
  `}
`;

const StyledMockResultBox = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
    justify-content: center;
    flex-direction: column;
  `}
`;

const StyledPetsParts = styled.div`
  height: 540px;
  flex: 1;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: calc(270px - 0.7rem) auto;
  grid-gap: 1.4rem;
  user-select: none;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
    width: 100%;
    height: auto;
  `}
`;

const StyledMockPetsBox = styled.div`
  height: 540px;
  width: 44%;
  padding: 1rem;
  background: url(${di_2});
  box-sizing: border-box;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  user-select: none;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    height: auto;
    padding: 0.75rem;
    padding-bottom: 20px;
  `}
`;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 35px;
  padding-top: 2rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 16px 10px;
    box-sizing: border-box;
    gap: 1px;
  `}
`;
