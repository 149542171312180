import { getStrFromOptions } from "src/components/ResizedImage";
// const isProduction = process.env.NODE_ENV === "production";

interface Size {
  width?: number;
  height?: number;
}

const imgUrl = "//images.tinyworlds.io";

const imgPathMap = {
  BNB: "/tokens/BNB.png",
};

export function getTokenImgSrc(
  tokenSymbol: keyof typeof imgPathMap,
  sizes?: Size
) {
  const src = imgPathMap[tokenSymbol];
  if (!sizes) {
    return imgUrl + src;
  }
  const optStr = getStrFromOptions(sizes);
  return imgUrl + "/cdn-cgi/image/" + optStr + src;
}
