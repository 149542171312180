import styled from "styled-components";
import { memo, useMemo, useRef } from "react";

import Nav from "./Nav/index";
import Info from "./Info/index";
import Bottom from "./Bottom";
import Advertise from "./components/Advertise";

function SideBar() {
  return (
    <StyledContainer>
      <Info />
      <Nav />
      <Bottom />
    </StyledContainer>
  );
}

export default memo(SideBar);

const StyledContainer = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 12.5rem;
  background-image: linear-gradient(#fcca42, #fdd456);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 12.2rem;
  `}
`;
