import { PropsWithChildren } from "react";
import styled from "styled-components";
import BaseButton, { BaseProps } from "./BaseButton";

interface ButtonProps extends BaseProps {
  loading?: boolean;
  text?: string;
}

export default function PrimaryButton(props: PropsWithChildren<ButtonProps>) {
  const { children, disabled, text, size, ...rest } = props;
  return (
    <StyledButton size={size} disabled={disabled} {...rest}>
      {children || text}
    </StyledButton>
  );
}

const StyledButton = styled(BaseButton)`
  position: relative;
  background-color: #fdd770;
  color: #815920;
  border-radius: 20px;
  /* min-width: ${(props) => (props.size === "sm" ? 60 : 110)}px; */

  // @media (any-hover: hover) {
  //   :hover {
  //     opacity: 0.65;
  //   }
  // }

  :active {
    opacity: 0.85;
  }
  transition: background-color 0.2s;
  white-space: nowrap;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  `}
`;
