import React from "react";

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

function DeleteSvg({ fill = "#815920", width = 22, height = 19.5 }: Props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 20.6 18.3"
    >
      <style type="text/css"></style>
      <g>
        <path
          fill={fill}
          d="M17.3,3.4h-2.8V0.8c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H7c-0.4,0-0.7,0.3-0.7,0.7v2.6H3.4
        C3,3.4,2.6,3.7,2.6,4.1s0.2,0.8,0.6,0.8c0.1,0,0.1,0,0.2,0h0.8l1,12.6c0,0.4,0.3,0.7,0.7,0.7h8.7c0.4,0,0.7-0.3,0.7-0.7l1-12.6h0.8
        c0.4,0,0.8-0.2,0.8-0.6C18.1,3.8,17.8,3.5,17.3,3.4C17.3,3.4,17.3,3.4,17.3,3.4L17.3,3.4z M7.7,1.6H13v1.8H7.7V1.6z M14,16.7H6.7
        l-1-11.8H15L14,16.7z"
        />
        <path
          fill={fill}
          d="M8.8,6.7C8.4,6.7,8.1,7,8.1,7.4v6.1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V7.4C9.6,7,9.2,6.7,8.8,6.7z"
        />
        <path
          fill={fill}
          d="M11.9,6.7c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v6.1c0,0.4,0.2,0.8,0.6,0.8c0.4,0,0.8-0.2,0.8-0.6
        c0-0.1,0-0.1,0-0.2V7.4C12.6,7,12.3,6.7,11.9,6.7C11.9,6.7,11.9,6.7,11.9,6.7z"
        />
      </g>
    </svg>
  );
}

export default React.memo(DeleteSvg);
