import { useQuery } from "react-query";

import { getRuneAuctionDashboard } from "src/services/auctions";

export function useRuneAuctionDashboard() {
  const { data: runeOverview } = useQuery(
    "RUNE_AUCTION_DASHBOARD_1",
    async ({ signal }) => {
      return getRuneAuctionDashboard(
        {
          tokenId: 1,
        },
        { signal }
      );
    },
    {
      refetchInterval: 30_000,
    }
  );

  const { data: spiritOverview } = useQuery(
    "RUNE_AUCTION_DASHBOARD_2",
    async ({ signal }) => {
      return getRuneAuctionDashboard(
        {
          tokenId: 2,
        },
        { signal }
      );
    },
    {
      refetchInterval: 30_000,
    }
  );

  return {
    runeOverview,
    spiritOverview,
  };
}
