import {
  FC,
  ReactChild,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styled from "styled-components";
import useIsMobile from "src/hooks/useIsMobile";
import { Context as GuideContext } from "src/views/Guide";

import setpDetailsBook from "src/images/guide/step_details_book.png";
import setpDetailsBookPre from "src/images/guide/step_details_book_pre.png";
import play from "src/images/play.png";
import playM from "src/images/guide/playM.png";

import GuideDetailLeft from "./GuideDetailLeft";
import GuideDetailRight from "./GuideDetailRight";

import { CSSTransition } from "react-transition-group";
import {
  StyledDetailFootNote,
  StyledDetailHelp,
  StyledDetailHelpM,
  StyledDetailHelpMBox,
  StyledDetailItem,
  StyledDetailTitle,
} from "./GuideDetailStyled";
import useGuideDetail from "../hooks/useGuideDetail";
import { useTranslation } from "react-i18next";
import PrefilledImage from "src/components/Images/PrefilledImage";

interface IStepDetailsProps {
  children?: ReactChild;
}

const StepDetails: FC<IStepDetailsProps> = ({ children = <></> }) => {
  const isMobile = useIsMobile();
  const [showAnime, setShowAnime] = useState(false);
  const { GuideList } = useGuideDetail();
  const { t, i18n } = useTranslation();

  useLayoutEffect(() => {
    setShowAnime(true);
  }, []);

  const { select, setSelect } = useContext(GuideContext);
  const {
    detailTitleM,
    detailTitle,
    setpTitleM,
    setpTitle,
    setpSpan,
    firstPageTitle,
    lastPageTitle,
  } = GuideList[select];

  useEffect(() => {
    setShowAnime(false);
    setTimeout(() => {
      setShowAnime(true);
    });
  }, [select]);
  return (
    <StyledStepDetails>
      {!isMobile && children && (
        //
        <StyledStepDetailsImg
          style={{
            minHeight: 500,
          }}
          preSrc={setpDetailsBookPre}
          src={setpDetailsBook}
          alt=""
        />
      )}
      <CSSTransition
        key={select}
        in={showAnime}
        classNames="guide"
        timeout={1500}
      >
        <StyledStepDetailsBox>
          {isMobile && (
            <StyledStepDetailsTitle>
              {isMobile && detailTitleM ? detailTitleM : detailTitle}
            </StyledStepDetailsTitle>
          )}
          <StyledStepDetailsBoxItem
            style={isMobile ? {} : { paddingRight: "3.1rem" }}
          >
            <StyledStepDetailsBoxTitle isZh={i18n.language === "zh"}>
              {firstPageTitle}
            </StyledStepDetailsBoxTitle>
            <GuideDetailLeft showAnime={showAnime}></GuideDetailLeft>
          </StyledStepDetailsBoxItem>
          <StyledStepDetailsBoxItem
            style={isMobile ? {} : { paddingLeft: "3.1rem" }}
          >
            <StyledStepDetailsBoxTitle
              isZh={i18n.language === "zh"}
              style={isMobile ? {} : { textAlign: "right" }}
            >
              {lastPageTitle}
            </StyledStepDetailsBoxTitle>
            <GuideDetailRight showAnime={showAnime}></GuideDetailRight>
          </StyledStepDetailsBoxItem>
          {isMobile && (
            <>
              <StyledDetailHelpMBox>
                <StyledDetailHelpM
                  href="https://www.youtube.com/channel/UCmm9JeIkqes3sS6NZ2YGA0w"
                  target={"_blank"}
                >
                  <img
                    style={{
                      width: "auto",
                      height: "90%",
                      marginRight: 6,
                    }}
                    loading="lazy"
                    src={playM}
                    alt=""
                  />
                  <div>
                    <StyledDetailTitle
                      style={{
                        height: "auto",
                        fontSize: 12,
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("Still need help?")}
                    </StyledDetailTitle>
                    <StyledDetailFootNote style={{ whiteSpace: "nowrap" }}>
                      {t("Watch Turorial")}
                    </StyledDetailFootNote>
                  </div>
                </StyledDetailHelpM>
              </StyledDetailHelpMBox>
            </>
          )}
        </StyledStepDetailsBox>
      </CSSTransition>
      {!isMobile && (
        <StyledDetailHelp
          href="https://www.youtube.com/channel/UCmm9JeIkqes3sS6NZ2YGA0w"
          target={"_blank"}
        >
          <StyledDetailTitle
            style={{
              height: 20,
              fontSize: 12,
              color: "#FFF",
              fontWeight: 600,
              width: "100%",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}
          >
            {t("Still need help?")}
          </StyledDetailTitle>
          <StyledDetailFootNote
            style={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "auto",
                height: "73%",
                margin: 0,
                marginRight: 2,
              }}
              loading="lazy"
              src={play}
              alt=""
            />
            {t("Watch Turorial")}
          </StyledDetailFootNote>
        </StyledDetailHelp>
      )}
      {!isMobile && children}
    </StyledStepDetails>
  );
};

export default StepDetails;

const StyledStepDetails = styled.div`
  top: -20px;
  width: 92%;
  min-height: 500px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding-bottom: 10rem;
  width: 92%;
  top: 0;
  `}
`;

const StyledStepDetailsImg = styled(PrefilledImage)`
  width: 100%;
  display: contents;
`;

const StyledStepDetailsBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 3.5% 8%;
  top: 0;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: relative;
    padding: 0 2.4rem;
    right: -29px;
    width: 97%;
    flex-wrap: wrap;
  `}
`;

const StyledStepDetailsTitle = styled.div`
  position: absolute;
  left: 0;
  bottom: -2rem;
  width: 100%;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  // font-family: Hiragino;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: relative;
    text-align: left;
    bottom: 0;
    margin-top: 16px;
    font-size: 20px;
  `}
`;

const StyledStepDetailsBoxItem = styled.div`
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.6rem 1.6rem 0 1.6rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    padding: 0;
    margin-top: 1rem;

    & + &{
      margin-top:3rem;
    }
  `}
`;

const StyledStepDetailsBoxTitle = styled.div<{ isZh: boolean }>`
  width: 100%;
  border-bottom: 2px solid #c9aa60;
  line-height: 32px;
  font-size: ${({ isZh }) => (isZh ? "18px" : "20px")};
  color: #9d6c08;
  font-weight: ${({ isZh }) => (isZh ? "600" : "500")};
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    padding: 0;
    font-size:18px;
    margin-top:12px;
  `}
`;
