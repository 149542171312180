import { BigNumber, Contract, utils } from "ethers";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import config from "src/config";
import {
  addressDecimalsConfig,
  ExchangeToken,
  FluidityContext,
  initState,
} from "src/contexts/Market/FluidityContext";
import useHandleTransactionReceipt from "./useHandleTransactionReceipt";
import useHandleTxConfirm from "./useHandleTxConfirm";
import useMyRuneBalance from "./useMyRuneBalance";
import { Context } from "src/contexts/TinyProvider";
import useApprove, { ApprovalState } from "./useApprove";
import useNFTApprove, {
  ApprovalState as SpiritApprovalState,
} from "./useNFTApprove";
import { Address } from "src/services/typings";
import { useQuery } from "react-query";
import { getBalance, getDisplayBalance } from "src/utils/formatBalance";
import { getDefaultProvider } from "src/utils/provider";
import { ABI as ERC20ABI, TINC_SPIRIT_LP_ABI } from "src/tiny/ERC20";
import useTokenBalance, { usePairbalanceOf } from "./useTokenBalance";
import useLimitedInterval from "./useLimitedInterval";
import {
  ISwapHistoryItem,
  setSwapHistoryLocaltion,
} from "src/views/MarketPlace/Fluidity/hooks/useOperateHistory";
import { bigNumber_sqrt } from "src/tiny/utils";
import { getTincSpiritLp } from "src/services";
import { getLocalStorageByUser } from "src/utils/storage";
import useTiny from "./useTiny";
import useUserWallet from "./useUserWallet";
import { bn0 } from "src/utils";

export const useTincSpiritLp = () => {
  return useQuery("TINC_SPIRIT_LP", getTincSpiritLp, {
    refetchInterval: 300_000,
  });
};

export const useLpInfo = () => {
  const { tiny, tincBalance } = useContext(Context);

  const account = tiny.myAccount;

  const tincAddress = config.externalTokens["TINC"];
  const spiritAddress = config.externalTokens["SPIRIT"];

  const { data: tincSpiritLp, refetch: tincSpiritLpRefetch } =
    useTincSpiritLp();

  const { data: pair, refetch: pairRefetch } = useQuery<string | undefined>(
    ["PAIR_TINC_SPIRIT"],
    async () => {
      try {
        const result = await tiny.getTincSpiritPair(tincAddress, spiritAddress);
        return result;
      } catch (error) {
        console.log({
          error,
        });
      }
    },
    {
      refetchInterval: 60_000,
    }
  );

  const [tincAmountAll, freshTINCBalanceAll] = useTokenBalance(
    tiny.externalTokens.TINC,
    pair
  );

  const [spiritAmountAll, startFreshSpiritBalance] = usePairbalanceOf(pair);
  const startFreshTINCBalance = useLimitedInterval(freshTINCBalanceAll);

  const iTinySwapRouterContract = tiny.contracts.ITinySwapRouter;

  const { data: myLiquidity, refetch: myLiquidityRefetch } = useQuery<
    BigNumber[]
  >(
    ["TINC_SPIRIT_LIQUIDITY", account],
    async () => {
      try {
        const result = await iTinySwapRouterContract.getLiquidity(
          tincAddress,
          spiritAddress,
          account
        );
        return result;
      } catch (error) {
        console.log({
          error,
        });
      }
    },
    {
      refetchInterval: 60_000,
      enabled: typeof account === "string",
    }
  );

  const { data: pairData, refetch: pairDataRefetch } = useQuery(
    ["ADD_LIQUIDITY_AMOUNT"],
    async () => {
      try {
        if (pair) {
          const pairContract = new Contract(
            pair,
            ERC20ABI,
            getDefaultProvider()
          );
          const result = await pairContract.totalSupply();

          return {
            totalSupply: result ? getDisplayBalance(result, 18, 18) : "0",
            pairContract,
            reTotalSupply: result as BigNumber,
          };
        } else {
          return {
            totalSupply: "0",
            pairContract: undefined,
            reTotalSupply: undefined,
          };
        }
      } catch (error) {
        console.log({
          error,
        });
      }
    },
    {
      refetchInterval: 60_000,
      enabled: typeof pair === "string",
    }
  );

  const { data: kLast, refetch: kLastRefetch } = useQuery(
    ["REMOVE_LIQUIDITY_KLAST"],
    async () => {
      try {
        const pairContract = new Contract(
          config.externalTokens["TINC-SPIRIT LP"],
          TINC_SPIRIT_LP_ABI,
          getDefaultProvider()
        );
        const result = await pairContract.kLast();
        return result as BigNumber;
      } catch (error) {
        console.log({
          error,
        });
      }
    },
    {
      refetchInterval: 60_000,
      enabled: typeof pair === "string",
    }
  );

  const totalSupplyValue = useMemo(() => {
    if (kLast && pairData?.reTotalSupply) {
      const res0 = tincAmountAll;
      const res1 = utils.parseUnits(spiritAmountAll.toString(), 18);
      const rootK = bigNumber_sqrt(res0.mul(res1));
      const rootKLast = bigNumber_sqrt(kLast);

      if (rootK.gt(rootKLast)) {
        const numberatorK = pairData?.reTotalSupply
          .mul(rootK.sub(rootKLast))
          .mul(20);
        const denominator = rootK.mul(30).add(rootKLast.mul(20));
        return pairData?.reTotalSupply.add(numberatorK.div(denominator));
      }
    }

    return pairData?.reTotalSupply;
  }, [kLast, pairData, spiritAmountAll, tincAmountAll]);

  const { apr, apy } = useMemo(() => {
    if (tincSpiritLp) {
      const { amountUSD, volumeUSD7Days } = tincSpiritLp;

      const apr = ((52 * volumeUSD7Days * 0.03) / amountUSD) * 100;

      const apy = (((volumeUSD7Days / 7) * 0.03) / amountUSD + 1) ** 365 * 100;

      return {
        apr,
        apy,
      };
    }
    return {
      apr: 0,
      apy: 0,
    };
  }, [tincSpiritLp]);

  return {
    pair,
    totalSupplyValue,
    myLiquidity,
    pairData,
    tincAmountAll,
    spiritAmountAll,
    iTinySwapRouterContract,
    tincAddress,
    spiritAddress,
    apr,
    apy,
    shareTradingPair:
      myLiquidity && pairData
        ? (Number(getDisplayBalance(myLiquidity[0], 18, 18)) /
            Number(pairData?.totalSupply)) *
          100
        : 0,
    pairRefetch,
    startFreshSpiritBalance,
    startFreshTINCBalance,
    kLastRefetch,
    pairDataRefetch,
    myLiquidityRefetch,
  };
};

export const useFluidityApprove = () => {
  const { tiny } = useContext(Context);
  const account = tiny.myAccount;
  const [staminaItemNum, startRefresh] = useMyRuneBalance(3);
  const { pairData, myLiquidity } = useLpInfo();
  const { setSwapHistory, swapHistory } = useContext(FluidityContext);
  const { tincBalance } = useUserWallet();

  const [isApprovedSwap, approveSwap] = useApprove(
    tiny.externalTokens.TINC,
    tiny.contracts.ITinySwapRouter.address,
    tincBalance
  );

  const [isApprovedLiquidity, approveLiquidity] = useApprove(
    tiny.externalTokens["TINC-SPIRIT LP"],
    tiny.contracts.ITinySwapRouter.address,
    myLiquidity ? myLiquidity[0] : bn0
  );

  const [isApprovedSwapSpirit, approveSwapSpirit] = useNFTApprove(
    tiny.contracts.TinyNFTRune,
    tiny.contracts.ITinySwapRouter.address
  );

  return {
    approveDisabledTinc: isApprovedSwap !== ApprovalState.NOT_APPROVED,
    approveDisabledSpirit:
      isApprovedSwapSpirit !== SpiritApprovalState.NOT_APPROVED,

    approveDisableLiquidity:
      isApprovedLiquidity !== ApprovalState.NOT_APPROVED ||
      !pairData?.pairContract ||
      !(
        isApprovedLiquidity === ApprovalState.NOT_APPROVED ||
        isApprovedLiquidity !== ApprovalState.PENDING
      ),

    ApprovalStateTinc: ApprovalState,
    ApprovalStateSpirit: SpiritApprovalState,

    isApprovedSwapTinc: isApprovedSwap,
    isApprovedSwapSpirit: isApprovedSwapSpirit,
    isApprovedLiquidity,
    approveSwapTinc: () => {
      approveSwap().then((res) => {
        if (res && account) {
          const saveHisotry: ISwapHistoryItem = {
            value: "approve TINC For Swap",
            url: config.scanUrl + "tx/" + res.hash,
          };
          if (account) {
            setSwapHistoryLocaltion({ user: account, value: saveHisotry });
            setSwapHistory([saveHisotry, ...swapHistory]);
          }
        }
      });
    },
    approveSwapSpirit: () => {
      approveSwapSpirit().then((res) => {
        if (res && account) {
          const saveHisotry: ISwapHistoryItem = {
            value: "approve Spirit for Swap",
            url: config.scanUrl + "tx/" + res.hash,
          };
          if (account) {
            setSwapHistoryLocaltion({ user: account, value: saveHisotry });
            setSwapHistory([saveHisotry, ...swapHistory]);
          }
        }
      });
    },
    approveLiquidity: () => {
      approveLiquidity().then((res) => {
        if (res && account) {
          const saveHisotry: ISwapHistoryItem = {
            value: "approve Tinc and Spirit for Liquidity",
            url: config.scanUrl + "tx/" + res.hash,
          };
          if (account) {
            setSwapHistoryLocaltion({ user: account, value: saveHisotry });
            setSwapHistory([saveHisotry, ...swapHistory]);
          }
        }
      });
    },
  };
};

let lastExchangePrice = "";
let lastPriceImpact = "";
let lastChangeAmount = { text: "", value: "0%" };

export const useCalcSwapInfo = () => {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const { t } = useTranslation();
  const {
    exchange,
    outputCurrencyAmount,
    inputCurrencyAmount,
    nowInput,
    calcFlag,
    slippageTolerance,
  } = useContext(FluidityContext);

  const { tincAmountAll, spiritAmountAll } = useLpInfo();

  const exchangePrice = useMemo(() => {
    if (calcFlag) {
      return lastExchangePrice;
    }
    const price =
      exchange[0] === "TINC"
        ? `${
            Number(outputCurrencyAmount) > 0
              ? parseFloat(
                  (
                    Number(inputCurrencyAmount) / Number(outputCurrencyAmount)
                  ).toFixed(4)
                )
              : 0
          }`
        : `${
            Number(inputCurrencyAmount) > 0
              ? parseFloat(
                  (
                    Number(outputCurrencyAmount) / Number(inputCurrencyAmount)
                  ).toFixed(4)
                )
              : 0
          }`;

    lastExchangePrice = `${price} ${exchange.join("/")}`;

    return `${price} ${exchange.join("/")}`;
  }, [calcFlag, exchange, outputCurrencyAmount, inputCurrencyAmount]);

  const priceImpact = useMemo(() => {
    if (calcFlag) {
      return lastPriceImpact;
    }
    const inputAmount = Number(
      exchange[0] === "TINC" ? inputCurrencyAmount : outputCurrencyAmount
    );
    const outAmount =
      exchange[0] === "TINC" ? outputCurrencyAmount : inputCurrencyAmount;

    const inputAmountAll =
      exchange[0] === "TINC"
        ? getDisplayBalance(tincAmountAll, 18, 18)
        : getDisplayBalance(spiritAmountAll, 0, 0);
    const outAmountAll =
      exchange[0] === "TINC"
        ? getDisplayBalance(spiritAmountAll, 0, 0)
        : getDisplayBalance(tincAmountAll, 18, 18);

    const makert_price = Number(inputAmount) / Number(outAmount);
    const mid_price = Number(inputAmountAll) / Number(outAmountAll);

    const priceImpact =
      Number((1 - mid_price / makert_price).toFixed(18)) * 100 - 5;

    const result = `${
      priceImpact > 0.01 ? parseFloat(priceImpact.toFixed(2)) : `< 0.01`
    } %`;

    lastPriceImpact = result;
    return result;
  }, [
    calcFlag,
    exchange,
    inputCurrencyAmount,
    outputCurrencyAmount,
    spiritAmountAll,
    tincAmountAll,
  ]);

  const consumeGetAmount = useMemo(() => {
    if (calcFlag) {
      return lastChangeAmount;
    }
    const key = exchange[Number(!nowInput)];

    const value =
      ((key === "TINC"
        ? Number(inputCurrencyAmount)
        : Number(outputCurrencyAmount)) *
        (100 + (nowInput ? slippageTolerance : -slippageTolerance))) /
      100;

    // lastChangeAmount, setLastChangeAmount

    const result = {
      text: nowInput ? t("Maximum sales") : t("minimum amount"),
      value: `${
        key === "TINC" ? parseFloat(value.toFixed(4)) : Math.floor(value)
      } ${key}`,
      // val: key === "TINC" ? parseFloat(value.toFixed(4)) : Math.floor(value),
    };

    lastChangeAmount = result;
    return result;
  }, [
    calcFlag,
    exchange,
    inputCurrencyAmount,
    nowInput,
    outputCurrencyAmount,
    slippageTolerance,
    t,
  ]);

  return {
    exchangePrice,
    priceImpact,
    consumeGetAmount,
  };
};

function useFluidity() {
  const { t } = useTranslation();
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  const [staminaItemNum, startRefresh] = useMyRuneBalance(3);

  const { tiny, tincBalance } = useContext(Context);
  const account = tiny.myAccount;

  const {
    spiritLiquidity,
    tincLiquidity,
    setSpiritLiquidity,
    setTincLiquidity,
    setSwapHistory,
    swapHistory,
  } = useContext(FluidityContext);

  const {
    pair,
    totalSupplyValue,
    myLiquidity,
    pairData,
    tincAmountAll,
    spiritAmountAll,
    iTinySwapRouterContract,
    tincAddress,
    spiritAddress,
    apr,
    apy,
    pairRefetch,
    startFreshSpiritBalance,
    startFreshTINCBalance,
    kLastRefetch,
    pairDataRefetch,
    myLiquidityRefetch,
  } = useLpInfo();

  const {
    approveDisabledTinc,
    approveDisabledSpirit,
    approveDisableLiquidity,
    ApprovalStateTinc,
    ApprovalStateSpirit,
    isApprovedSwapTinc,
    isApprovedSwapSpirit,
    isApprovedLiquidity,
    approveSwapTinc,
    approveSwapSpirit,
    approveLiquidity,
  } = useFluidityApprove();

  const swapTokensForExactTokens = ({
    amountOut,
    amountInMax,
    deadline,
    outType,
    address,
    cb,
    startCb,
    finallyCb,
  }: {
    amountOut: string;
    amountInMax: string;
    deadline: number; //交易过期时间  = 当前时间 + 设置过期时间的时间戳
    outType: "TINC" | "SPIRIT";
    address: string[];
    cb?: () => void;
    startCb?: () => void;
    finallyCb?: () => void;
  }) => {
    if (account) {
      // ${account}
      const msg = `Swap max. ${
        outType === "TINC"
          ? Math.floor(Number(amountInMax))
          : parseFloat(Number(amountInMax).toFixed(4))
      } ${outType === "TINC" ? "SPIRIT" : "TINC"} for ${
        outType === "TINC"
          ? parseFloat(Number(amountOut).toFixed(4))
          : Math.floor(Number(amountOut))
      } ${outType}`;

      return handleTransactionReceipt(
        tiny.swapTokensForExactTokens(
          amountOut,
          amountInMax,
          address,
          account,
          deadline,
          outType
        ),
        `${msg}`,
        [amountOut, amountInMax, address, account, deadline].toString()
      )
        .then((tx) => {
          startCb && startCb();
          if (tx) {
            handleTxConfirm(tx.hash)
              .then(() => {
                const saveHisotry: ISwapHistoryItem = {
                  value: msg,
                  url: config.scanUrl + "tx/" + tx.hash,
                };
                if (account) {
                  setSwapHistoryLocaltion({
                    user: account,
                    value: saveHisotry,
                  });
                  setSwapHistory([saveHisotry, ...swapHistory]);
                }
                cb && cb();
              })
              .finally(finallyCb);
          } else {
            finallyCb && finallyCb();
          }
        })
        .catch((err) => {
          console.error("handleBuy err:", err);
        });
    }
  };

  const swapExactTokensForTokens = ({
    amountIn,
    amountOutMin,
    deadline,
    inType,
    address,
    cb,
    finallyCb,
    startCb,
  }: {
    amountIn: string;
    amountOutMin: string;
    deadline: number; //交易过期时间  = 当前时间 + 设置过期时间的时间戳
    inType: "TINC" | "SPIRIT";
    address: string[];
    cb?: () => void;
    startCb?: () => void;
    finallyCb?: () => void;
  }) => {
    if (account) {
      const msg = `Swap ${
        inType === "TINC"
          ? parseFloat(Number(amountIn).toFixed(4))
          : Math.floor(Number(amountIn))
      } ${inType} for min. ${
        inType === "TINC"
          ? Math.floor(Number(amountOutMin))
          : parseFloat(Number(amountOutMin).toFixed(4))
      } ${inType === "TINC" ? "SPIRIT" : "TINC"}`;

      return handleTransactionReceipt(
        tiny.swapExactTokensForTokens(
          amountIn,
          amountOutMin,
          address,
          account,
          deadline,
          inType
        ),
        msg,
        [amountIn, amountOutMin, address, account, deadline].toString()
      )
        .then((tx) => {
          startCb && startCb();
          if (tx) {
            handleTxConfirm(tx.hash)
              .then(() => {
                const saveHisotry: ISwapHistoryItem = {
                  value: msg,
                  url: config.scanUrl + "tx/" + tx.hash,
                };
                if (account) {
                  setSwapHistoryLocaltion({
                    user: account,
                    value: saveHisotry,
                  });
                  setSwapHistory([saveHisotry, ...swapHistory]);
                }
                cb && cb();
              })
              .finally(finallyCb);
          } else {
            finallyCb && finallyCb();
          }
        })
        .catch((err) => {
          console.error("handleBuy err:", err);
        });
    }
  };

  const liquidityRefetch = useCallback(() => {
    startFreshTINCBalance();
    startFreshSpiritBalance();
    myLiquidityRefetch();
    kLastRefetch();
    pairDataRefetch();
  }, [
    kLastRefetch,
    myLiquidityRefetch,
    pairDataRefetch,
    startFreshSpiritBalance,
    startFreshTINCBalance,
  ]);

  const addLiquidity = useCallback(
    ({
      tincToken,
      spiritToken,
      tincDesired,
      spiritDesired,
      tincMin,
      spiritMin,
      account,
      deadline,
      startCb,
      successCb,
      finallyCb,
    }: {
      tincToken: Address; //tokenA
      spiritToken: Address; //tokenB
      tincDesired: string; //TINC
      spiritDesired: string; //SPIRIT
      tincMin: string;
      spiritMin: string;
      account: Address; //转账给谁
      deadline: number; //交易过期时间  = 当前时间 + 设置过期时间的时间戳
      startCb?: () => void;
      successCb?: () => void;
      finallyCb?: () => void;
    }) => {
      if (account) {
        const msg = `Add min. ${parseFloat(
          Number(tincMin).toFixed(4)
        )} TINC and min. ${Math.floor(Number(spiritMin))} SPIRIT for liquidity`;

        return handleTransactionReceipt(
          tiny.addTincSpiritLiquidity(
            tincToken,
            spiritToken,
            tincDesired,
            spiritDesired,
            tincMin,
            spiritMin,
            account,
            deadline
          ),
          msg,
          [
            tincToken,
            spiritToken,
            tincDesired,
            spiritDesired,
            tincMin,
            spiritMin,
            account,
            deadline,
          ].toString()
        )
          .then((tx) => {
            startCb && startCb();
            if (tx) {
              handleTxConfirm(tx.hash)
                .then(() => {
                  const saveHisotry: ISwapHistoryItem = {
                    value: msg,
                    url: config.scanUrl + "tx/" + tx.hash,
                  };
                  setSwapHistoryLocaltion({
                    user: account,
                    value: saveHisotry,
                  });
                  setSwapHistory([saveHisotry, ...swapHistory]);
                  liquidityRefetch();
                  successCb && successCb();
                })
                .finally(finallyCb);
            } else {
              finallyCb && finallyCb();
            }
          })
          .catch((err) => {
            console.error("handleBuy err:", err);
          });
      }
    },
    [
      handleTransactionReceipt,
      handleTxConfirm,
      liquidityRefetch,
      setSwapHistory,
      swapHistory,
      tiny,
    ]
  );

  const removeLiquidity = useCallback(
    ({
      tincToken,
      spiritToken,
      liquidity,
      tincMin,
      spiritMin,
      account,
      deadline,
      startCb,
      successCb,
      finallyCb,
    }: {
      tincToken: Address; //tokenA
      spiritToken: Address; //tokenB
      liquidity: BigNumber; //要减去多少流动性就传多少流动性
      tincMin: BigNumber;
      spiritMin: BigNumber;
      account: Address; //转账给谁
      deadline: number; //交易过期时间  = 当前时间 + 设置过期时间的时间戳
      startCb?: () => void;
      successCb?: () => void;
      finallyCb?: () => void;
    }) => {
      if (account) {
        const msg = `Remove min. ${getDisplayBalance(
          tincMin,
          18,
          6
        )} TINC and min. ${spiritMin.toNumber()} SPIRIT for liquidity`;

        return handleTransactionReceipt(
          tiny.removeTincSpiritLiquidity(
            tincToken,
            spiritToken,
            liquidity,
            tincMin,
            spiritMin,
            account,
            deadline
          ),
          msg,
          [
            tincToken,
            spiritToken,
            liquidity,
            tincMin,
            spiritMin,
            account,
            deadline,
          ].toString()
        )
          .then((tx) => {
            startCb && startCb();
            if (tx) {
              handleTxConfirm(tx.hash)
                .then(() => {
                  const saveHisotry: ISwapHistoryItem = {
                    value: msg,
                    url: config.scanUrl + "tx/" + tx.hash,
                  };
                  setSwapHistoryLocaltion({
                    user: account,
                    value: saveHisotry,
                  });
                  setSwapHistory([saveHisotry, ...swapHistory]);
                  liquidityRefetch();
                  successCb && successCb();
                })
                .finally(finallyCb);
            } else {
              finallyCb && finallyCb();
            }
          })
          .catch((err) => {
            console.error("handleBuy err:", err);
          });
      }
    },
    [
      handleTransactionReceipt,
      handleTxConfirm,
      liquidityRefetch,
      setSwapHistory,
      swapHistory,
      tiny,
    ]
  );

  const myLiquidityCalc = myLiquidity
    ? getDisplayBalance(myLiquidity[0], 18, 8)
    : 0;

  return {
    approveDisabledTinc,
    approveDisabledSpirit,
    approveDisableLiquidity,
    ApprovalStateTinc,
    ApprovalStateSpirit,
    isApprovedSwapTinc,
    isApprovedSwapSpirit,
    isApprovedLiquidity,
    approveSwapTinc,
    approveSwapSpirit,
    approveLiquidity,

    reMyLiquidity: myLiquidity,
    myLiquidity: myLiquidityCalc,

    spiritLiquidityAmount:
      myLiquidity && totalSupplyValue && !totalSupplyValue.isZero()
        ? myLiquidity[0].mul(spiritAmountAll).div(totalSupplyValue)
        : utils.parseUnits("0", 0),

    tincLiquidityAmount:
      myLiquidity && totalSupplyValue && !totalSupplyValue.isZero()
        ? myLiquidity[0].mul(tincAmountAll).div(totalSupplyValue)
        : utils.parseUnits("0", 18),

    getAmountsOut: async ({
      amountIn,
      addressArr,
      inType,
    }: {
      amountIn: string;
      addressArr: ExchangeToken[];
      inType: "TINC" | "SPIRIT";
    }) => {
      const address = addressArr.map((key) => {
        return config.externalTokens[key];
      });

      const res = await tiny.getAmountsOut(amountIn, address, inType);
      return await tiny.getAmountsOut(amountIn, address, inType);
    },
    getAmountsIn: async ({
      amountOut,
      addressArr,
      outType,
    }: {
      amountOut: string;
      addressArr: ExchangeToken[];
      outType: "TINC" | "SPIRIT";
    }) => {
      const address = addressArr.map((key) => {
        return config.externalTokens[key];
      });

      const res = await tiny.getAmountsIn(amountOut, address, outType);
      if (res) {
      }
      return res;
    },
    swapTokensForExactTokens,
    swapExactTokensForTokens,
    pair,
    pairRefetch,

    myLiquidityRefetch,
    // getOutAmountQuote: ()=>{},
    getOutAmountQuote: async ({
      // inputAmountAddress,
      // outAmountAddress,
      inputAmount,
      inputType,
    }: {
      // inputAmountAddress:string;
      // outAmountAddress:string;
      inputAmount: string;
      inputType: ExchangeToken;
    }) => {
      const calcOutAmountQuote = async ({
        inputAmount,
        inputType,
      }: {
        inputAmount: string;
        inputType: ExchangeToken;
      }) => {
        try {
          const inputAmountAddress =
            inputType === "TINC" ? tincAddress : spiritAddress;
          const outAmountAddress =
            inputType === "TINC" ? spiritAddress : tincAddress;

          const value = utils.parseUnits(
            inputAmount !== "" ? inputAmount : "0",
            inputType === "TINC"
              ? addressDecimalsConfig.TINC.decimals
              : addressDecimalsConfig.SPIRIT.decimals
          );

          iTinySwapRouterContract
            .getQuoteB(inputAmountAddress, outAmountAddress, value)
            .then((outAmount: BigNumber) => {
              if (inputType === "TINC") {
                const outVal = Number(
                  getDisplayBalance(
                    outAmount,
                    addressDecimalsConfig.SPIRIT.decimals,
                    addressDecimalsConfig.SPIRIT.fractionDigits
                  )
                );
                const maxVal = Number(staminaItemNum.toNumber());

                setSpiritLiquidity(outVal.toString());

                // if (outVal <= maxVal) {
                //   setSpiritLiquidity(outVal.toString());
                // }
                //  else {
                //   setSpiritLiquidity(maxVal.toString());

                //   calcOutAmountQuote({
                //     inputAmount: maxVal.toString(),
                //     inputType: "SPIRIT",
                //   });
                // }
              } else {
                const outVal = Number(
                  getDisplayBalance(
                    outAmount,
                    addressDecimalsConfig.TINC.decimals,
                    2
                  )
                );
                const maxVal = Number(
                  getDisplayBalance(
                    tincBalance,
                    addressDecimalsConfig.TINC.decimals,
                    2
                  )
                );

                setTincLiquidity(outVal.toString());

                // if (outVal <= maxVal) {
                //   setTincLiquidity(outVal.toString());
                // } else {
                //   setTincLiquidity(maxVal.toString());

                //   calcOutAmountQuote({
                //     inputAmount: maxVal.toString(),
                //     inputType: "TINC",
                //   });
                // }
              }
            });
        } catch (error) {
          console.log({
            error,
          });
        }
      };

      calcOutAmountQuote({ inputAmount, inputType });
    },
    addLiquidity,
    removeLiquidity,
    rateTinc: `1 TINC = ${
      Number(tincLiquidity) > 0
        ? parseFloat(
            (Number(spiritLiquidity) / Number(tincLiquidity)).toFixed(8)
          )
        : 0
    } SPIRIT`, //tinc汇率
    rateSpirit: `1 SPIRIT = ${
      Number(spiritLiquidity) > 0
        ? parseFloat(
            (Number(tincLiquidity) / Number(spiritLiquidity)).toFixed(8)
          )
        : 0
    } TINC`, //spirit汇率
    totalSupply: pairData?.totalSupply,
    pairContract: pairData?.pairContract,
    reTotalSupply: pairData?.reTotalSupply,
    tincAmountAll,
    startFreshTINCBalance,
    spiritAmountAll,
    startFreshSpiritBalance,
    totalSupplyValue,

    apr,
    apy,
  };
}

export default useFluidity;
