import { CSSProperties } from "react";
import styled from "styled-components";

interface ShadowFontProps {
  value: string | number;
  shadowColor?: string;
  style?: CSSProperties;
  shadowWidth?: number;
}

// 外描边文字
export default function ShadowFont({
  value,
  shadowColor = "#000",
  style,
  shadowWidth = 2,
}: ShadowFontProps) {
  return (
    <StyledShadowFont
      data-text={value}
      shadowColor={shadowColor}
      style={style}
      shadowWidth={shadowWidth}
    >
      {value}
    </StyledShadowFont>
  );
}

const StyledShadowFont = styled.span<{
  shadowColor: string;
  shadowWidth: number;
}>`
  position: relative;
  z-index: 0;
  font-weight: 600;

  &::before {
    content: attr(data-text);
    position: absolute;
    -webkit-text-stroke: ${({ shadowColor, shadowWidth }) =>
      `${shadowColor} ${shadowWidth}px`};
    z-index: -1;
  }
`;
