// import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
// import { useTranslation } from "react-i18next";
import jiantou from "./jiantou.png";

export default function Info() {
  // const tiny = useTiny();
  // const { t } = useTranslation();
  return (
    <StyledInfo>
      <StyledLogo
        width={360}
        height={240}
        src="//images.tinyworlds.io/logo/tinyworld.png?version=4"
        alt=""
      />
      <StyledArrow src={jiantou} alt="" />
    </StyledInfo>
  );
}

const StyledInfo = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 5px 0px;
  background: linear-gradient(#f9be3a, #f79f0e) no-repeat;
  text-align: center;
  background-size: 100% 95%;
  box-sizing: border-box;
  margin-bottom: 36px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const StyledLogo = styled.img`
  margin-top: 1.5rem;
  width: 8.75rem;
  height: auto;
  object-fit: contain;
  ${({ theme }) => theme.mediaWidth.upToLargeBp`
    // width: 160px;
  `};
`;

const StyledArrow = styled.img`
  position: absolute;
  top: 94%;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 35%;
`;
