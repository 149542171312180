import { useTranslation } from "react-i18next";
import {
  exploreSymbol,
  gradeDurability,
  gradeType,
  propSymbol,
} from "src/config";
import useIsMobile from "src/hooks/useIsMobile";
import styled, { css } from "styled-components";
import PropIcon from "../AttrHeroMainItem/PropIcon";

import powerIcon from "src/images/power.svg";
import levelIcon from "src/images/props/icon_10.png";
import classIcon from "src/images/props/icon_11.png";
import GradeIcon from "../GradeIcon";
import { propsArr } from "../ReplenishStamina/const";
import lightning from "src/images/durability/lightning-gold.png";

export enum BaseAttributes {
  Level = "Level",
  Class = "Class",
  InitialPower = "Initial Power",
  CurrentPower = "Current Power",
  Energy = "Energy",
  Spirit = "Spirit",
}

export type UpgradeHelp = "help1" | "help2" | "";

const isBaseAttributes = (attr: any): attr is BaseAttributes =>
  Object.values(BaseAttributes).includes(attr);

interface TooltipsProps {
  type: propSymbol | exploreSymbol | BaseAttributes | UpgradeHelp;
  grade?: gradeType;
  icon?: boolean;
  title?: boolean;
  placement?: "left" | "right" | "top" | "bottom";
}

export default function Tooltip({
  type,
  grade,
  placement,
  icon = true,
  title = true,
}: TooltipsProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const despSrcMap: {
    [title: string]: string;
  } = {
    attack: `${t("attackTip")}`,
    defense: `${t("defenseTip")}`,
    hp: `${t("hpTip")}`,
    luck: `${t("luckTip")}`,
    exploreTime: `${t("expeditionTimeTip")}`,
    crystal: `${t("expeditionCrystalsTip")}`,
    drop: `${t("dropRateTip")}`,
    drop2: `${t("dropBonusTip")}`,
    help1: `${t("upgradeHelpTip1")}`,
    help2: `${t("upgradeHelpTip2")}`,
  };

  const BaseAttrContent = (type: BaseAttributes) => {
    const initialPower = ["1", "4", "15", "90-140", "400-800", "1200-1400"];

    switch (type) {
      case BaseAttributes.Level:
        return (
          <>
            <StyledTitle>
              <StyledBaseAttrIcon
                src={levelIcon}
                className="mr-5"
                height={25}
                alt={type + " logo"}
              />
              {t(type)}
            </StyledTitle>
            <StyledLine>
              Max Lv8: &nbsp;&nbsp;&nbsp;
              <GradeIcon grade={1} height={18} />
            </StyledLine>
            <StyledLine>
              Max Lv10: &nbsp;
              <GradeIcon grade={2} height={18} />
              &nbsp;
              <GradeIcon grade={3} height={18} />
            </StyledLine>
            <StyledLine>
              <span style={{ whiteSpace: "nowrap" }}>Max Lv20: </span>&nbsp;
              <GradeIcon grade={4} height={18} />
              &nbsp;
              <GradeIcon grade={5} height={18} />
              &nbsp;
              <GradeIcon grade={6} height={18} />
            </StyledLine>
          </>
        );
      case BaseAttributes.Class:
        return (
          <>
            <StyledTitle>
              <StyledBaseAttrIcon
                src={classIcon}
                className="mr-5"
                height={25}
                alt={type + " logo"}
              />
              {t(type)}
            </StyledTitle>
            <StyledLine>
              <GradeIcon grade={1} height={18} />
              &nbsp;&lt;&nbsp;
              <GradeIcon grade={2} height={18} />
              &nbsp;&lt;&nbsp;
              <GradeIcon grade={3} height={18} />
              &nbsp;&lt;&nbsp;
              <GradeIcon grade={4} height={18} />
              &nbsp;&lt;&nbsp;
              <GradeIcon grade={5} height={18} />
              &nbsp;&lt;&nbsp;
              <GradeIcon grade={6} height={18} />
            </StyledLine>
          </>
        );
      case BaseAttributes.InitialPower:
        return (
          <>
            <StyledTitle>
              <StyledBaseAttrIcon
                src={powerIcon}
                className="mr-5"
                height={25}
                alt={type + " logo"}
              />
              {t(type)}
            </StyledTitle>
            <StyledLine className="justify-center">
              <StyledTable style={{ borderSpacing: "20px 4px" }}>
                <tbody>
                  {initialPower.map((power, index) => (
                    <tr key={index + "initial-power"}>
                      <td className="pr-20">
                        {
                          <GradeIcon
                            grade={(index + 1) as gradeType}
                            height={18}
                          />
                        }
                      </td>
                      <td>{power}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </StyledLine>
          </>
        );
      case BaseAttributes.CurrentPower:
        return (
          <>
            <StyledTitle>
              <StyledBaseAttrIcon
                src={powerIcon}
                className="mr-5"
                height={25}
                alt={type + " logo"}
              />
              {t(type)}
            </StyledTitle>
            <StyledLine>{t("CPTip")}</StyledLine>
          </>
        );
      case BaseAttributes.Spirit:
        return (
          <>
            <span>{t(type)}</span>
            <StyledLine className="justify-center">
              <StyledTable style={{ borderSpacing: "20px 4px" }}>
                <tbody>
                  {propsArr.map((props, index) => (
                    <tr key={index + "initial-power"}>
                      <td className="pr-20">
                        {
                          <GradeIcon
                            grade={(index + 1) as gradeType}
                            height={18}
                          />
                        }
                      </td>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <StyledIcon
                          style={{ height: 20 }}
                          className="mr-2 "
                          src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
                        />
                        {propsArr[index]} =
                        <StyledIcon
                          style={{ height: 20 }}
                          className="mr-2 ml-3"
                          src={lightning}
                        />
                        1
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </StyledLine>
          </>
        );
      default: // Energy
        return (
          <>
            <StyledTitle>
              <StyledBaseAttrIcon
                src={lightning}
                className="mr-5"
                height={25}
                alt={type + " logo"}
              />
              {t(type)}
            </StyledTitle>
            <StyledLine>{t("energyTip")}</StyledLine>
            <StyledLine className="justify-center">
              <StyledTable style={{ borderSpacing: "30px 4px" }}>
                <tbody>
                  {[0, 1, 2, 3, 4, 5].map((_, index) => (
                    <tr key={index + "energy"}>
                      <td className="pr-20">
                        {
                          <GradeIcon
                            grade={(index + 1) as gradeType}
                            height={18}
                          />
                        }
                      </td>
                      <td className="text-right">
                        {gradeDurability[(index + 1) as gradeType]}&nbsp; days
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </StyledLine>
          </>
        );
    }
  };

  return type !== "" ? (
    <>
      {isBaseAttributes(type) ? (
        BaseAttrContent(type)
      ) : (
        <>
          {title && (
            <StyledTitle>
              {icon && (
                <PropIcon
                  size={isMobile ? 18 : 25}
                  className="mr-5"
                  symbol={type}
                  grade={grade}
                />
              )}
              {t(type)}
            </StyledTitle>
          )}
          <span>{despSrcMap[type]}</span>
        </>
      )}
    </>
  ) : (
    <></>
  );
}

const StyledIcon = styled.img`
  height: 30px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 20px;
  `}
`;

const StyledTooltip = styled.div<{ placement: string }>`
  position: relative;
  max-width: 230px;
  min-height: 50px;
  background: #ffbd4a;
  border-radius: 15px;
  border: 1px solid #bc6c1e;
  padding: 15px;

  margin-left: ${(props) => (props.placement === "right" ? 20 : 0)}px;
  margin-top: ${(props) => (props.placement === "bottom" ? 20 : 0)}px;
  margin-right: ${(props) => (props.placement === "left" ? 20 : 0)}px;

  .triangle-container {
    position: absolute;
    ${(props) =>
      props.placement === "bottom" &&
      css`
        top: -20px;
        left: 50%;
      `}
    ${(props) =>
      props.placement === "right" &&
      css`
        top: 50%;
        left: -20px;
        transform: rotate(-90deg);
      `}
    ${(props) =>
      props.placement === "left" &&
      css`
        top: 50%;
        right: -20px;
        transform: rotate(90deg);
      `}
  }

  .triangle {
    position: relative;

    ::before {
      position: absolute;
      top: 0;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 20px 10px;
      border-color: transparent transparent #bc6c1e transparent;
      transform: translate(-50%, 0);
    }

    ::after {
      position: absolute;
      top: 2px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 20px 10px;
      border-color: transparent transparent #ffbd4a transparent;
      transform: translate(-50%, 0);
    }
  }
`;

const StyledTooltipContainer = styled.div``;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
`;

const StyledLine = styled.div<{ width?: number }>`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  margin-bottom: 5px;
  align-items: center;
`;

const StyledTable = styled.table`
  tbody > tr {
    padding: 5px;
  }

  tbody > tr > td.pr-20 > img {
    vertical-align: middle;
  }
`;

const StyledBaseAttrIcon = styled.img``;
