import { BigNumber } from "ethers";

export const getFloorFixed = (value: number, fractionDigits = 2) => {
  return (
    Math.floor(value * (10 ^ fractionDigits)) /
    (10 ^ fractionDigits)
  ).toFixed(2);
};

export const getDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 3,
  locale = false
) => {
  const number = getBalance(balance, decimals - fractionDigits);
  if (locale) {
    return (Number(number) / 10 ** fractionDigits).toLocaleString(undefined, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }
  return (Number(number) / 10 ** fractionDigits).toFixed(fractionDigits);
};

export const getFullDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 6
) => {
  return Number(
    getDisplayBalance(
      balance,
      decimals,
      fractionDigits > decimals ? decimals : fractionDigits
    )
  );
};

/**
 * @param {BigNumber} balance
 * @param {number} [decimals]
 * @returns
 */
export function getBalance(balance: BigNumber, decimals = 18): string {
  return balance.div(BigNumber.from(10).pow(decimals)).toString();
}
