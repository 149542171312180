const Part_3 = [
  "3-part1",
  "3-part2",
  "3-part3",
  "3-part4",
  "3-part5",
  "3-part6",
  "3-part7",
  "3-part8",
  "3-part9",
  "3-part10",
  "3-part11",
  "3-part12",
  "3-part13",
  "3-part14",
  "3-part15",
  "3-part16",
  "3-part17",
  "3-part18",
  "3-part19",
  "3-part20",
  "3-part21",
  "3-part22",
  "3-part23",
  "3-part24",
  "3-part25",
  "3-part26",
  "3-part27",
  "3-part28",
  "3-part29",
  "3-part30",
];

export default Part_3;
