import React, { CSSProperties } from "react";
import styled from "styled-components";

function RecoverIcon({
  isHover,
  style,
}: {
  isHover: boolean;
  style?: CSSProperties;
}) {
  return (
    <StyledSvg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.6 17.4"
    >
      <defs>
        <linearGradient
          id="click"
          x1="1"
          y1="8.51"
          x2="16.32"
          y2="8.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f9d63a" />
          <stop offset="1" stop-color="#ffc000" />
        </linearGradient>
      </defs>

      <path
        d="M8.66,17.32a8.66,8.66,0,1,1,8.66-8.66A8.67,8.67,0,0,1,8.66,17.32Z"
        fill="#af5320"
      />
      <circle cx="8.66" cy="8.87" r="7.66" fill="#f79905" />
      <circle
        cx="8.66"
        cy="8.51"
        r="7.66"
        fill={isHover ? "#ffe54a" : "url(#click)"}
      />
      <path
        d="M16.32,8.38v.39A7.66,7.66,0,0,0,1,8.77c0-.13,0-.26,0-.39a7.66,7.66,0,1,1,15.31,0Z"
        fill={isHover ? "#fff4b5" : "#ffe54a"}
      />
      <path
        d="M12.7,6.93H10.25V4.48a.78.78,0,0,0-.78-.78H7.86a.78.78,0,0,0-.78.78h0V6.93H4.63a.78.78,0,0,0-.78.78V9.32a.78.78,0,0,0,.78.78H7.08v2.45a.78.78,0,0,0,.78.78H9.47a.78.78,0,0,0,.78-.78h0V10.1H12.7a.77.77,0,0,0,.77-.78V7.71a.77.77,0,0,0-.77-.78Z"
        fill={isHover ? "#ba6d02" : "#815920"}
      />
    </StyledSvg>
  );
}

export default RecoverIcon;

const StyledSvg = styled.svg``;
