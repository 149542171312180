import { CSSProperties, FC, useEffect, useState } from "react";
import styled from "styled-components";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import ContentLoader from "react-content-loader";

interface IProps {
  src: string | string[];
  style?: CSSProperties;
  boxStyle?: CSSProperties;
}

const LoadingSkeleton: FC<IProps> = ({
  src,
  children,
  style = {},
  boxStyle = {},
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const defautStyle = {
    width: "100%",
    height: "100%",
    lineHeight: 1,
    zIndex: -1,
  };
  useEffect(() => {
    if (typeof src === "string") {
      const img = new Image();
      img.src = src;
      img.decode().then(() => {
        // setTimeout(()=>{
        setLoaded(true);
        // },200)
      });
      img.onerror = (err) => console.log(`${src} 图片加载失败`, err);
    } else if (Array.isArray(src)) {
      const imgPromise: Promise<unknown>[] = [];
      src.map((i) => {
        imgPromise.push(
          new Promise((res, rej) => {
            const img = new Image();
            img.src = i;
            img.decode().then(() => {
              res(i);
            });
            // img.onload = () => {
            // };
            img.onerror = (err) => {
              rej();
            };
          })
        );
      });
      Promise.all(imgPromise).then((res) => {
        // setTimeout(()=>{
        setLoaded(true);
        // },200)
        if (res.length !== src.length) {
          console.log(`${src} ${res} 图片加载失败`);
        }
      });
    }
  }, [src]);
  return (
    <div style={{ position: "relative", ...boxStyle }}>
      {!loaded && (
        <>
          <ContentLoader
            // speed={1}
            backgroundColor={"rgba(253,206,76,.25)"}
            foregroundColor={"rgba(238,145,43,.25)"}
            style={{
              ...defautStyle,
              ...style,
              background: "rgba(253,206,76,.25)",
            }}
          >
            <rect />
          </ContentLoader>
        </>
      )}
      <div
        style={{
          opacity: loaded ? 1 : 0,
          height: loaded ? "100%" : "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    </div>
    // <Skeleton
    //   baseColor="rgba(253,206,76,.25)"
    //   highlightColor="rgba(238,145,43,.25)"
    //   // borderRadius='0'
    //   style={{
    //     ...defautStyle,
    //     ...style,
    //   }}
    // />
  );
};

export default LoadingSkeleton;
