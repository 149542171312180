import { auction_api_base, rune_auction_api_base } from "../constants";
import { request } from "./util";
import { filterParamType, SortOrder } from "../config";
import type {
  HeroListParams,
  RuneItemListParams,
  NFTBaseDetail,
  NFTInfo,
  PageParamBase,
} from "./typings";

/** --------------- 交易市场相关 ----------------- */
interface AuctionBase {
  id: number;
  seller: string;
  startingPrice: string;
  endingPrice: string;
  duration: number;
  startAt: number;
  referencePrice: number;
  priceEnd: boolean;
  buyer: null | string;
  price: null | string;
  bidTime: null | number;
  TinyHeros: NFTInfo[];
  bestGrade: number;
}

interface SuccessAuction {
  buyer: string;
  price: string;
  bidTime: number;
}

export interface AuctionDetailSingle extends AuctionBase {
  tokenId: number;
  tokenIds: null;
}

export interface AuctionDetailPack extends AuctionBase {
  tokenIds: string;
  tokenId: null;
}

export type AuctionDetail = AuctionDetailSingle | AuctionDetailPack;

export type AuctionSuccessDetail = AuctionDetail & SuccessAuction;

export interface AuctionsData {
  total_count: number;
  page_number: number;
  page_size: number;
  list: AuctionDetail[];
}

export interface RuneAuction {
  id: number;
  seller: string;
  totalPrice: string;
  unitPrice: number;
  referencePrice: number;
  tokenId: number;
  amount: number;
}

export interface RuneAuctionData {
  total_count: number;
  list: RuneAuction[];
}

/**
 * 交易市场列表
 */
export async function getAllAuction(
  {
    pageNum,
    pageSize = 12,
    grade,
    sort,
    filters,
    code,
    levels,
  }: HeroListParams,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    pageSize: pageSize.toString(),
    pageNum: pageNum.toString(),
  });

  if (sort) {
    const { key, order } = sort;
    params.set("sortBy", key);
    params.set("sortType", order);
  }

  if (grade) {
    params.set("grade", grade.toString());
  }
  if (filters) {
    filters.forEach((filter: filterParamType) => {
      params.append("filter", [filter.key, filter.value].toString());
    });
  }

  if (code) {
    params.append("filter", ["code", code].toString());
  }

  if (levels && levels.length === 2) {
    params.append("levels", levels.toString());
  }

  return request<AuctionsData>(
    `${auction_api_base}/list?${params.toString()}`,
    options
  );
}

interface QuickBuyParams {
  filters: filterParamType[];
  quickBuy: string[];
}

export async function getQuickAuction(
  { filters, quickBuy }: QuickBuyParams,
  options?: RequestInit
) {
  const params = new URLSearchParams();
  filters.forEach((filter: filterParamType) => {
    params.append("filter", [filter.key, filter.value].toString());
  });
  // console.log('quickBuy', quickBuy);
  params.append("quickBuy", quickBuy.toString());
  return request<AuctionsData>(
    `${auction_api_base}/list?${params.toString()}`,
    options
  );
}

interface MyListParams {
  address: string;
  sortBy?: string;
  sortType?: SortOrder;
}
export async function getMyAuction(
  { address, sortBy = "startedAt", sortType = SortOrder.DESC }: MyListParams,
  options?: RequestInit
) {
  return request<AuctionsData>(
    `${auction_api_base}/myAuctions?address=${address}&sortBy=${sortBy}&sortType=${sortType}`,
    options
  );
}

/**
 * 通过 id 查找拍卖
 */
export async function getAuctionById(id: number, options?: RequestInit) {
  return request<AuctionDetail>(`${auction_api_base}/${id}`);
}

export interface AuctionDashboard {
  nftSum: number;
  priceSum: string;
}
export async function getAuctionDashboard(
  last?: number,
  options?: RequestInit
) {
  return request<AuctionDashboard>(
    `${auction_api_base}/dashboard?last=${last}`,
    options
  );
}

export async function getRecentBids() {
  return request<AuctionSuccessDetail[]>(`${auction_api_base}/recent`);
}

export interface BidsData {
  total_count: number;
  page_size: number;
  page_number: number;
  list: AuctionSuccessDetail[];
}

interface BidsParams extends PageParamBase {
  address: string;
}

export async function getMyBids(
  { address, pageNum, pageSize }: BidsParams,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    address,
    pageSize: pageSize ? pageSize.toString() : "20",
    pageNum: pageNum.toString(),
  });

  return request<BidsData>(`${auction_api_base}/myBids?${params.toString()}`);
}

// 预售交易
export async function getAllRuneAuction(
  { pageNum, pageSize = 12, sort, tokenId }: RuneItemListParams,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    pageSize: pageSize.toString(),
    pageNum: pageNum.toString(),
    tokenId: tokenId.toString(),
  });

  if (sort) {
    const { key, order } = sort;
    params.set("sortBy", key);
    params.set("sortType", order);
  }

  return request<RuneAuctionData>(
    `${rune_auction_api_base}/list?${params.toString()}`,
    options
  );
}

export async function getQuickRuneAuction(
  account: string,
  options?: RequestInit
) {
  return request<RuneAuctionData>(
    `${rune_auction_api_base}/list?quickBuy=${account}&tokenId=2`
  );
}

export async function getMyRuneAuction(
  {
    address,
    tokenId,
    sortBy = "startedAt",
    sortType = SortOrder.DESC,
  }: MyListParams & { tokenId: 1 | 2 },
  options?: RequestInit
) {
  return request<RuneAuctionData>(
    `${rune_auction_api_base}/myAuctions?address=${address}&sortBy=${sortBy}&sortType=${sortType}&tokenId=${tokenId}`
  );
}

export interface RuneSuccessDetail extends RuneAuction {
  buyer: string;
  price: string;
  bidTime: number;
  bidPrice: string;
}

export interface RuneBidsData {
  total_count: number;
  list: RuneSuccessDetail[];
}

export async function getMyRuneBids(
  params: {
    address: string;
    tokenId: 1 | 2;
  },
  options?: RequestInit
) {
  const { address, tokenId } = params;
  console.log("params", params);
  console.log("tokenId", tokenId);

  return request<RuneBidsData>(
    `${rune_auction_api_base}/myBids?address=${address}&tokenId=${tokenId}`,
    options
  );
}

export async function getRuneRecentBids(
  tokenId?: 1 | 2,
  options?: RequestInit
) {
  return request<RuneSuccessDetail[]>(
    `${rune_auction_api_base}/recent?tokenId=${tokenId}`,
    options
  );
}

export interface RuneAuctionDashboard {
  nftSum: number;
  bidPriceSum: string | null;
}

export async function getRuneAuctionDashboard(
  params: {
    tokenId: 1 | 2;
    last?: number;
  },
  options?: RequestInit
) {
  const { tokenId, last } = params;
  return request<RuneAuctionDashboard>(
    `${rune_auction_api_base}/dashboard?tokenId=${tokenId}&last=${last}`,
    options
  );
}

export interface floorPriceRes {
  floorPrice: string | null;
}
export async function getFloorPrice(
  code: NFTBaseDetail["itemCode"],
  options?: RequestInit
) {
  return request<floorPriceRes>(
    `${auction_api_base}/floorPrice?code=${code}`,
    options
  );
}
