import config from "../config";
import { getTokenImgSrc } from "../constants/imgPaths";
// const chainId = 1337
const chainId = config.chainId;

// HETH/HT    3X
// HUSD/HT    3X
// HLTC/HT    3X

// const tokens = config.externalTokens;

export interface BasePoolInfo {
  pid: number;
  lpAddress?: string; // 如果是 LP 就会有 lpAddress 字段
  name: string; // 是用来在前端展示的
  tokenSymbol: string;
  quoteTokenSymbol?: string; // 如果是 LP 就会有 quoteTokenSymbol 字段
  multiplying: number;
  icon: string[];
  liquidityUrl?: string;
}

export const poolMap = {
  97: [
    {
      pid: -1,
      name: "TINC-BUSD Pool",
      tokenSymbol: "TINC-BUSD LP",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/TINC.png",
        "//images.tinyworlds.io/tokens/BUSD.png",
      ],
    },
    {
      pid: 0,
      lpAddress: config.externalTokens["BUSD-USDT LP"],
      name: "BUSD-USDT LP",
      tokenSymbol: "BUSD-USDT LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/BUSD.png",
        "//images.tinyworlds.io/tokens/USDT.png",
      ],
    },
    {
      pid: 2,
      lpAddress: config.externalTokens["BNB-BUSD LP"],
      name: "BNB-BUSD LP",
      tokenSymbol: "BNB-BUSD LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 1,
      icon: [getTokenImgSrc("BNB"), "//images.tinyworlds.io/tokens/BUSD.png"],
    },
    {
      pid: 3,
      lpAddress: config.externalTokens["BUSD-USDT LP"],
      name: "BUSD-USDT LP V2",
      tokenSymbol: "BUSD-USDT LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 2,
      icon: [
        "//images.tinyworlds.io/tokens/BUSD.png",
        "//images.tinyworlds.io/tokens/USDT.png",
      ],
    },
  ],
  56: [
    {
      pid: -1,
      name: "TINC-BNB Pool",
      tokenSymbol: "TINC-BNB LP",
      multiplying: 15,
      icon: [
        "//images.tinyworlds.io/tokens/TINC@2x.png",
        getTokenImgSrc("BNB"),
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/BNB/0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
    },
    {
      pid: 0,
      lpAddress: config.externalTokens["BNB-BUSD LP"],
      name: "BNB-BUSD LP",
      tokenSymbol: "BNB-BUSD LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 3,
      icon: [getTokenImgSrc("BNB"), "//images.tinyworlds.io/tokens/BUSD.png"],
      liquidityUrl:
        "https://pancakeswap.finance/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      pid: 1,
      lpAddress: config.externalTokens["CAKE-BNB LP"],
      name: "CAKE-BNB LP",
      tokenSymbol: "CAKE-BNB LP",
      quoteTokenSymbol: "CAKE",
      multiplying: 3,
      icon: ["//images.tinyworlds.io/tokens/CAKE.svg", getTokenImgSrc("BNB")],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/BNB",
    },
    {
      pid: 7,
      lpAddress: config.externalTokens["BTCB-ETH LP"],
      name: "BTCB-ETH LP",
      tokenSymbol: "BTCB-ETH LP",
      quoteTokenSymbol: "ETH",
      multiplying: 2,
      icon: [
        "//images.tinyworlds.io/tokens/BTCB.svg",
        "//images.tinyworlds.io/tokens/ETH.svg",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    },
    {
      pid: 2,
      lpAddress: config.externalTokens["ETH-BNB LP"],
      name: "ETH-BNB LP",
      tokenSymbol: "ETH-BNB LP",
      quoteTokenSymbol: "ETH",
      multiplying: 2,
      icon: ["//images.tinyworlds.io/tokens/ETH.svg", getTokenImgSrc("BNB")],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/BNB",
    },
    {
      pid: 3,
      lpAddress: config.externalTokens["BTCB-BNB LP"],
      name: "BTCB-BNB LP",
      tokenSymbol: "BTCB-BNB LP",
      quoteTokenSymbol: "BTCB",
      multiplying: 2,
      icon: ["//images.tinyworlds.io/tokens/BTCB.svg", getTokenImgSrc("BNB")],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/BNB",
    },
    {
      pid: 4,
      lpAddress: config.externalTokens["USDC-USDT LP"],
      name: "USDC-USDT LP",
      tokenSymbol: "USDC-USDT LP",
      quoteTokenSymbol: "USDC",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/USDC.svg",
        "//images.tinyworlds.io/tokens/USDT.png",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x55d398326f99059fF775485246999027B3197955",
    },
    {
      pid: 5,
      lpAddress: config.externalTokens["USDC-BUSD LP"],
      name: "USDC-BUSD LP",
      tokenSymbol: "USDC-BUSD LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/USDC.svg",
        "//images.tinyworlds.io/tokens/BUSD.png",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      pid: 6,
      lpAddress: config.externalTokens["BUSD-USDT LP"],
      name: "BUSD-USDT LP",
      tokenSymbol: "BUSD-USDT LP",
      quoteTokenSymbol: "USDT",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/BUSD.png",
        "//images.tinyworlds.io/tokens/USDT.png",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059fF775485246999027B3197955",
    },
    // {
    //   pid: 8,
    //   lpAddress: config.externalTokens["BUSD-USDT LP"],
    //   name: "BUSD-USDT LP",
    //   tokenSymbol: "BUSD-USDT LP",
    //   quoteTokenSymbol: "USDT",
    //   multiplying: 0.01,
    //   icon: ["//images.tinyworlds.io/tokens/BUSD.png", "//images.tinyworlds.io/tokens/USDT.png"],
    //   liquidityUrl:
    //     "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059fF775485246999027B3197955",
    // },
    {
      pid: 9,
      lpAddress: config.externalTokens["BNB-BUSD LP"],
      name: "BNB-BUSD LP",
      tokenSymbol: "BNB-BUSD LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 3,
      icon: [getTokenImgSrc("BNB"), "//images.tinyworlds.io/tokens/BUSD.png"],
      liquidityUrl:
        "https://pancakeswap.finance/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      pid: 10,
      lpAddress: config.externalTokens["CAKE-BNB LP"],
      name: "CAKE-BNB LP",
      tokenSymbol: "CAKE-BNB LP",
      quoteTokenSymbol: "CAKE",
      multiplying: 3,
      icon: ["//images.tinyworlds.io/tokens/CAKE.svg", getTokenImgSrc("BNB")],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/BNB",
    },
    {
      pid: 11,
      lpAddress: config.externalTokens["BTCB-ETH LP"],
      name: "BTCB-ETH LP",
      tokenSymbol: "BTCB-ETH LP",
      quoteTokenSymbol: "ETH",
      multiplying: 2,
      icon: [
        "//images.tinyworlds.io/tokens/BTCB.svg",
        "//images.tinyworlds.io/tokens/ETH.svg",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    },
    {
      pid: 12,
      lpAddress: config.externalTokens["ETH-BNB LP"],
      name: "ETH-BNB LP",
      tokenSymbol: "ETH-BNB LP",
      quoteTokenSymbol: "ETH",
      multiplying: 2,
      icon: ["//images.tinyworlds.io/tokens/ETH.svg", getTokenImgSrc("BNB")],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/BNB",
    },
    {
      pid: 13,
      lpAddress: config.externalTokens["BTCB-BNB LP"],
      name: "BTCB-BNB LP",
      tokenSymbol: "BTCB-BNB LP",
      quoteTokenSymbol: "BTCB",
      multiplying: 2,
      icon: ["//images.tinyworlds.io/tokens/BTCB.svg", getTokenImgSrc("BNB")],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/BNB",
    },
    {
      pid: 14,
      lpAddress: config.externalTokens["USDC-USDT LP"],
      name: "USDC-USDT LP",
      tokenSymbol: "USDC-USDT LP",
      quoteTokenSymbol: "USDC",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/USDC.svg",
        "//images.tinyworlds.io/tokens/USDT.png",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x55d398326f99059fF775485246999027B3197955",
    },
    {
      pid: 15,
      lpAddress: config.externalTokens["USDC-BUSD LP"],
      name: "USDC-BUSD LP",
      tokenSymbol: "USDC-BUSD LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/USDC.svg",
        "//images.tinyworlds.io/tokens/BUSD.png",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      pid: 16,
      lpAddress: config.externalTokens["BUSD-USDT LP"],
      name: "BUSD-USDT LP",
      tokenSymbol: "BUSD-USDT LP",
      quoteTokenSymbol: "USDT",
      multiplying: 1,
      icon: [
        "//images.tinyworlds.io/tokens/BUSD.png",
        "//images.tinyworlds.io/tokens/USDT.png",
      ],
      liquidityUrl:
        "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059fF775485246999027B3197955",
    },
  ],
};

export const supportedPools: BasePoolInfo[] = poolMap[chainId];
