// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Modal, { ModalProps } from "src/components/Modal";
import ParallelogramButton from "src/components/Button/ParallelogramButton";

interface Props extends ModalProps {
  text?: string;
  onConfirm?: () => void;
}
export default function NoticeModal(props: Props) {
  const { onDismiss, text, onConfirm } = props;

  const { t } = useTranslation();

  return (
    <Modal
      size="es"
      isConfirmModal={true}
      onDismiss={onDismiss}
      title={<StyledTitle color="#FF7824">{t("notice")}</StyledTitle>}
    >
      <StyledContent className="display-flex flex-center">{text}</StyledContent>

      <div className="mt-30 text-center">
        <ParallelogramButton
          className="mb-5"
          onClick={() => {
            onDismiss && onDismiss();
            onConfirm && onConfirm();
          }}
        >
          {t("Confirm")}
        </ParallelogramButton>
      </div>
    </Modal>
  );
}

const StyledTitle = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;

const StyledContent = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 2rem;
  `}
`;
