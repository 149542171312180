import { useState } from "react";
import useTiny from "src/hooks/useTiny";
import { useQuery } from "react-query";
import { bn0 } from "src/utils";
import type { BigNumber } from "ethers";

export function useInvestorRewards() {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const investorContract = tiny.contracts.InvestorRelease;

  const [enabled, setEnabled] = useState(true);

  return useQuery(
    ["INVESTOR_PENDING_REWARD", account],
    async () => {
      return investorContract.callStatic
        .claimReward(account)
        .then((result: BigNumber) => {
          return result;
        })
        .catch((err) => {
          if (err.data && typeof err.data === "object") {
            const message = err.data.message;
            if (message === "execution reverted: no reward") {
              setEnabled(false);
              return bn0;
            }
          }
          throw err;
        });
    },
    {
      enabled: enabled && !!account,
      refetchInterval: 10_000,
    }
  );
}

export function useInvestorClaimed() {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const investorContract = tiny.contracts.InvestorRelease;

  return useQuery(
    ["INVESTOR_CLAIMED_REWARD", account],
    async () => {
      const res = await investorContract.userInfos(account);
      const totalClaimed: BigNumber = res.totalClaimed;
      return totalClaimed;
    },
    {
      enabled: !!account,
      refetchInterval: 10_000,
    }
  );
}
