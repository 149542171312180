import { useCallback } from "react";
import { useQuery } from "react-query";
import useTiny from "./useTiny";
import type { BigNumber } from "ethers";

export default function useDaoTotalLockedTINC() {
  const tiny = useTiny();
  const votingEscrow = tiny.contracts.VotingEscrow;

  const fetchTotalLocked = useCallback(async () => {
    const value: BigNumber = await votingEscrow.totalLocked();
    // console.log("value.toString()", value);
    return value;
  }, [votingEscrow]);

  return useQuery("DAO_TOTAL_LOCKED_TINC", fetchTotalLocked, {
    refetchInterval: 30_000,
  });
}
