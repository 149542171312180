const Part_6 = [
  "6-part1",
  "6-part2",
  "6-part3",
  "6-part4",
  "6-part5",
  "6-part6",
  "6-part7",
  "6-part8",
  "6-part9",
  "6-part10",
  "6-part11",
  "6-part12",
  "6-part13",
  "6-part14",
  "6-part15",
  "6-part16",
  "6-part17",
  "6-part18",
  "6-part19",
  "6-part20",
];

export default Part_6;
