import {
  injected,
  bsc,
  bitKeep,
  $onekey,
  trustwallet,
  // network,
  walletconnect,
  walletlink,
  okxwallet,
  // ledger,
  // trezor,
  // lattice,
  // frame,
  // authereum,
  // fortmatic,
  // magic,
  // portis,
  // torus
} from "./connectors";

export enum ConnectorNames {
  Injected = "Injected",
  // Network = "Network",
  WalletConnect = "WalletConnect",
  WalletLink = "WalletLink",
  bsc = "bsc",
  BitKeep = "BitKeep",
  $onekey = "$onekey",
  TrustWallet = "TrustWallet",
  okxwallet = "okxwallet",
  // Ledger = "Ledger",
  // Trezor = "Trezor",
  // Lattice = "Lattice",
  // Frame = "Frame",
  // Authereum = "Authereum",
  // Fortmatic = "Fortmatic",
  // Magic = "Magic",
  // Portis = "Portis",
  // Torus = "Torus",
}

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  // [ConnectorNames.Network]: network,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
  [ConnectorNames.bsc]: bsc,
  [ConnectorNames.BitKeep]: bitKeep,
  [ConnectorNames.$onekey]: $onekey,
  [ConnectorNames.TrustWallet]: window?.ethereum?.isTrust
    ? injected
    : trustwallet,
  [ConnectorNames.okxwallet]: okxwallet,
  // [ConnectorNames.Ledger]: ledger,
  // [ConnectorNames.Trezor]: trezor,
  // [ConnectorNames.Lattice]: lattice,
  // [ConnectorNames.Frame]: frame,
  // [ConnectorNames.Authereum]: authereum,
  // [ConnectorNames.Fortmatic]: fortmatic,
  // [ConnectorNames.Magic]: magic,
  // [ConnectorNames.Portis]: portis,
  // [ConnectorNames.Torus]: torus,
};

export function findConnectorId(connector: any): ConnectorNames | undefined {
  if (connector == undefined) {
    return undefined;
  }
  for (const key in connectorsByName) {
    if (Object.prototype.hasOwnProperty.call(connectorsByName, key)) {
      const element = connectorsByName[key as ConnectorNames];
      if (element === connector) {
        return key as ConnectorNames;
      }
    }
  }
}
