import { MutableRefObject, createContext } from "react";
import PetParts from "src/components/NFTCard/PetCard/PetPartConfig";
import {
  PartInfo,
  PetAttr,
  PetDetail,
  PetPartsGrade,
} from "src/components/NFTCard/PetCard/type";

interface IPetsMockContext {
  femaleInfo: PetDetail | undefined;
  maleInfo: PetDetail | undefined;
  petProbabilitys: React.MutableRefObject<
    {
      result: PartInfoEx[];
      key: keyof PetAttr;
    }[]
  >;
  petsPartsGenes: Record<keyof PetAttr, PartInfoEx[]>;
}

export interface PartInfoEx extends PartInfo {
  probability: number;
}

export const PetsMockContext = createContext<IPetsMockContext>({
  femaleInfo: undefined,
  maleInfo: undefined,
  petProbabilitys: {
    current: [],
  },
  petsPartsGenes: {
    part_1: [],
    part_2: [],
    part_3: [],
    part_4: [],
    part_5: [],
    part_6: [],
  },
});

/* 
"基因
1.显性；2.隐性；3.次隐性；4.突变"
4. 15%
*/
export const petsGenesProbability = [25, 8, 2];

export const petsGenesNotBreedGrade = 5;

//   grade: PetPartsGrade;
interface IMutationProbability {
  grade: PetPartsGrade;
  probability: number;
}
const mutationProbabilityOrigin: IMutationProbability[][] = [
  [
    { grade: 1, probability: 65 },
    { grade: 2, probability: 90 },
    { grade: 3, probability: 100 },
  ],
  [
    { grade: 1, probability: 55 },
    { grade: 2, probability: 85 },
    { grade: 3, probability: 100 },
  ],
  [
    { grade: 1, probability: 45 },
    { grade: 2, probability: 75 },
    { grade: 3, probability: 95 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 35 },
    { grade: 2, probability: 65 },
    { grade: 3, probability: 90 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 30 },
    { grade: 2, probability: 60 },
    { grade: 3, probability: 85 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 20 },
    { grade: 2, probability: 50 },
    { grade: 3, probability: 80 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 10 },
    { grade: 2, probability: 40 },
    { grade: 3, probability: 70 },
    { grade: 4, probability: 90 },
    { grade: 5, probability: 100 },
  ],
];
const mutationProbability: IMutationProbability[][] = [
  [
    { grade: 1, probability: 70 },
    { grade: 2, probability: 95 },
    { grade: 3, probability: 100 },
  ],
  [
    { grade: 1, probability: 60 },
    { grade: 2, probability: 90 },
    { grade: 3, probability: 100 },
  ],
  [
    { grade: 1, probability: 50 },
    { grade: 2, probability: 80 },
    { grade: 3, probability: 97 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 45 },
    { grade: 2, probability: 75 },
    { grade: 3, probability: 95 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 35 },
    { grade: 2, probability: 65 },
    { grade: 3, probability: 90 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 20 },
    { grade: 2, probability: 55 },
    { grade: 3, probability: 85 },
    { grade: 4, probability: 100 },
  ],
  [
    { grade: 1, probability: 10 },
    { grade: 2, probability: 45 },
    { grade: 3, probability: 75 },
    { grade: 4, probability: 95 },
    { grade: 5, probability: 100 },
  ],
];

// 基因突变方法
const geneMutation = ({
  femaleBreedCnt,
  maleBreedCnt,
  femaleOrigin,
  maleOrigin,
  partProp,
}: {
  femaleBreedCnt: number;
  maleBreedCnt: number;
  femaleOrigin: boolean;
  maleOrigin: boolean;
  partProp: keyof PetAttr;
}): PartInfoEx => {
  const random = Math.round(Math.random() * 200);
  const femaleRandomPartGrade = femaleOrigin
    ? mutationProbabilityOrigin[femaleBreedCnt]
    : mutationProbability[femaleBreedCnt];
  const maleRandomPartGrade = maleOrigin
    ? mutationProbabilityOrigin[maleBreedCnt]
    : mutationProbability[maleBreedCnt];

  const randomProbabilitys = [...femaleRandomPartGrade];

  randomProbabilitys.push(
    ...maleRandomPartGrade.map((ele) => {
      return {
        ...ele,
        probability: ele.probability + 100,
      };
    })
  );

  const result = randomProbabilitys.find((ele) => {
    return ele.probability > random;
  });

  const calcKey: number[] = [];

  let i = result?.grade || 1;

  PetParts[partProp].forEach((ele, index) => {
    if (index + 1 === i) {
      calcKey.push(index);
      i += 5;
    }
  });

  const partRandom = Math.round(Math.random() * (calcKey.length - 1));

  // if (calcKey[partRandom] === undefined) {
  //   console.log({
  //     partRandom,
  //     calcKey,
  //     randomTop: calcKey.length - 1,
  //     i,
  //     PetParts,
  //   });
  // }

  return {
    key: calcKey[partRandom],
    grade: result?.grade || 1,
    probability: 0,
  };
};

// 宠物基因遗传方法
export const petsGenesMockRandom = ({
  petsPartsGenes,
  femaleBreedCnt,
  maleBreedCnt,
  femaleOrigin,
  maleOrigin,
}: {
  petsPartsGenes: Record<keyof PetAttr, PartInfoEx[]>;
  femaleBreedCnt: number;
  maleBreedCnt: number;
  femaleOrigin: boolean;
  maleOrigin: boolean;
}): Record<keyof PetAttr, PartInfo[]> => {
  // }): void => {

  const mockPetsGenes = Object.keys(petsPartsGenes).map((gEle, gIndex) => {
    const key = gEle as keyof PetAttr;
    const result = petsPartsGenes[key];
    const random = Math.round(Math.random() * 100);
    const probabilitys: number[] = [];

    result.forEach((ele, index) => {
      let probability = 0;
      for (let i = 0; i <= index; i++) {
        probability += result[i].probability;
      }
      probabilitys.push(probability);
    }, []);
    if (random > probabilitys[probabilitys.length - 1]) {
      return {
        key,
        result: geneMutation({
          partProp: key,
          femaleBreedCnt,
          maleBreedCnt,
          femaleOrigin,
          maleOrigin,
        }),
      };
    } else {
      return {
        key,
        result: result.find((ele, index) => {
          return probabilitys[index] >= random;
        }),
      };
    }
  });

  let res: {
    [part: string]: PartInfoEx[];
  } = {};

  mockPetsGenes.map(({ key, result }, index) => {
    if (result) {
      res[key] = [result, result, result];
    }
  });
  return res as unknown as Record<keyof PetAttr, PartInfo[]>;
};

const petsPowerConst = [
  { min: 8, max: 12 },
  { min: 16, max: 24 },
  { min: 32, max: 48 },
  { min: 112, max: 168 },
  { min: 1360, max: 2040 },
];

// 获取宠物模拟战力
export const getMockPetsPower = ({
  parts,
}: {
  parts: Record<keyof PetAttr, PartInfo[]>;
}): number => {
  let power = 0;
  Object.keys(parts).map((ele, index) => {
    if (index < 4) {
      const { grade } = parts[ele as keyof PetAttr][0];
      const { min, max } = petsPowerConst[Number(grade) - 1];
      power += min + Math.round(Math.random() * (max - min));
    }
  }, []);
  return power;
};
