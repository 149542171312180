import { useCallback } from "react";
import { shortenAddress } from "src/utils";
import Web3 from "web3";
import useIsMobile from "./useIsMobile";
import { useQuery } from "react-query";
import useTiny from "./useTiny";

const SID = require("@siddomains/sidjs").default;
const SIDfunctions = require("@siddomains/sidjs");

function useSidSdk() {
  const isMobile = useIsMobile();
  const { myAccount: account } = useTiny();
  // const account = '0x9a5323072ca95c3951c93099127cc9821b5b5e64';
  // 0x9a5323072ca95c3951c93099127cc9821b5b5e64
  return useQuery<string>(
    ["SID_USER", account],
    async () => {
      try {
        if (account) {
          const rpc =
            "https://bsc-mainnet.nodereal.io/v1/d0c3ef1cdb0247f4b6fae228aa76c8b8"; //bsc
          // const rpc = "https://data-seed-prebsc-1-s1.binance.org:8545/";  //bscTest
          const provider = new Web3.providers.HttpProvider(rpc);
          // chainId
          const sid = new SID({
            provider,
            // sidAddress: SIDfunctions.getSidAddress(chainId),
            sidAddress: SIDfunctions.getSidAddress(56),
          });
          // const address = await sid.name(path).getAddress(); // 0x123
          const path = await sid.getName(account); // 0x123
          return path.name
            ? path.name
            : shortenAddress(account, isMobile ? 2 : 4);
        }
      } catch (error) {
        return account ? shortenAddress(account, isMobile ? 2 : 4) : undefined;
      }
    },
    {
      enabled: typeof account === "string",
    }
  );
}

export default useSidSdk;
