import {
  FC,
  useCallback,
  useContext,
  useMemo,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BigNumber, utils } from "ethers";

import useIsMobile from "src/hooks/useIsMobile";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
import { Context } from "src/contexts/TinyProvider";
import useCheckinRewardInfo from "src/hooks/useCheckinRewardInfo";

import Modal, { ModalProps } from "src/components/Modal";

import { checkinCrystalMap } from "src/config";
import { getDisplayBalance } from "src/utils/formatBalance";

// import type { ModalProps } from "src/components/Modal";

import hero from "src/images/game/sign_hero.png";
import heroPre from "src/images/game/sign_hero_pre.png";
import signButtonImg from "src/images/game/sign_button.png";
import bergCrystal1 from "src/images/game/bergCrystal1.png";
import bergCrystal2 from "src/images/game/bergCrystal2.png";
import bergCrystal3 from "src/images/game/bergCrystal3.png";
// import bergCrystalTinc from "src/images/game/bergCrystalTinc.png";
import spirits from "src/images/game/spirits.png";
// import sign from "src/images/game/sign.png";

import { toHHMM } from "src/utils";

import "./GameSignModal.css";
import useCheckinRestTime from "src/hooks/useCheckinRestTime";
import PrefilledImage from "src/components/Images/PrefilledImage";
import useCountdown from "src/hooks/useCountdown";
import dayjs from "dayjs";
import PrimaryButton from "src/components/Button/PrimaryButton";

interface SignItem {
  end: boolean;
  bergCrystal: number;
  tinc?: string;
  sign: boolean;
  signButton?: ReactElement;
  day: number;
}

interface ISignBox extends SignItem {
  onCheckin(): void;
}

const SignBox: FC<ISignBox> = ({
  day,
  bergCrystal,
  end,
  tinc,
  sign,
  signButton,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const bergCrystalImg = () => (day > 4 ? bergCrystal3 : bergCrystal2);

  const StyledSignItemBodyClass = useCallback((): string => {
    if (end && sign) {
      return "border-sign card_sign_disabled";
    } else if (!end && sign) {
      return "border-sign";
    } else if (end && !sign) {
      return "card_sign_disabled";
    }
    return "";
  }, [end, sign]);

  useEffect(() => {
    localStorage.setItem(
      "signEnd",
      JSON.stringify({
        day: dayjs().format("YYYY-MM-DD"),
        sign: true,
      })
    );
  }, []);

  return (
    <StyledSignItem onClick={(e) => e.stopPropagation()} day={day} style={{}}>
      <StyledSignItemHeader day={day}>Day{day}</StyledSignItemHeader>
      <StyledSignItemBody
        style={
          !sign && end
            ? {
                border: 0,
              }
            : {}
        }
        className={StyledSignItemBodyClass()}
        day={day}
      >
        {end && (
          <StyledSignEndBox>
            {t("claimed")}

            {/* sign ? t("checked in") :  */}
          </StyledSignEndBox>
        )}
        {tinc && (
          <>
            <StyledSignImgAdd>+</StyledSignImgAdd>
            <StyledSignItemBodyItem style={{ width: "50%" }}>
              <img
                // width={isMobile ? 167 : 167}
                // height={isMobile ? 167 : 167}
                style={{
                  width: isMobile ? "100%" : 167,
                  // height: isMobile ? '100%' : 167,
                }}
                src={spirits}
                alt=""
              />
              <StyledBergCrystal
              // style={{
              //   marginTop: sign ? -16 : -20,
              // }}
              >
                <img src={"//images.tinyworlds.io/tokens/SPIRIT.png"} alt="" />
                {tinc}
              </StyledBergCrystal>
            </StyledSignItemBodyItem>
          </>
        )}
        <StyledSignItemBodyItem style={tinc ? { width: "46%" } : {}}>
          <img
            // width={isMobile ? 167 : 167}
            // height={isMobile ? 167 : 167}
            style={{
              width: isMobile ? "100%" : 167,
              // height: isMobile ? '100%' : 167,
            }}
            src={bergCrystalImg()}
            alt=""
          />
          <StyledBergCrystal
            style={
              {
                // marginTop: sign ? -10 : -20,
              }
            }
          >
            <img src={bergCrystal1} alt="" />
            {bergCrystal}
          </StyledBergCrystal>
        </StyledSignItemBodyItem>
        {sign && !isMobile && signButton}
      </StyledSignItemBody>
    </StyledSignItem>
  );
};

export default function GameSignModal({ onDismiss }: ModalProps) {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const { tiny, startFreshTINCBalance } = useContext(Context);

  const handleTransactionReceipt = useHandleTransactionReceipt();

  const handleTxConfirm = useHandleTxConfirm();

  const [restTime, freshTime, setRestTime] = useCheckinRestTime(100_000);
  const todayCheckin = typeof restTime === "number" && restTime > 0;

  const countdownStart = useCountdown();

  const countdown = typeof countdownStart === "number" ? countdownStart : 1;

  const { data, refetch: startCheckinRefetch } = useCheckinRewardInfo();
  const tincRewards = data?.rewards[0];
  const spiritRewards = data?.rewards[1];
  // const [signEnd, setSignEnd] = useState<boolean>(false);
  const [checkinCount, setCheckinCount] = useState<number>();

  // console.log("data?.rewards", data?.rewards);

  const showTINCReward = tincRewards && !tincRewards.isZero();
  const showSpiritReward = spiritRewards && !spiritRewards.isZero();
  // console.log("showTINCReward", showTINCReward);
  // console.log("showSpiritReward", showSpiritReward);

  useEffect(() => {
    if (data) {
      setCheckinCount((pre) => {
        if (typeof pre === "number") {
          return Math.max(data.checkinCount, pre);
        }
        return data.checkinCount;
      });
    }
  }, [data]);

  const signItems: SignItem[] = useMemo(() => {
    return Object.keys(checkinCrystalMap).map((dayCount, index) => {
      const day = Number.parseInt(dayCount);
      const _checkinCount = checkinCount || 0;
      const checkinCountDay = todayCheckin ? _checkinCount - 1 : _checkinCount;
      return {
        end: typeof _checkinCount === "number" ? day <= _checkinCount : false,
        bergCrystal: checkinCrystalMap[day],
        sign: checkinCountDay === index,
        day,
        // 每日签到SPIRIT奖励的数量
        tinc: index === 6 ? "200-1000" : undefined,
      };
    });
  }, [checkinCount, todayCheckin]);
  // console.log("signItems", signItems);
  // console.log("countdown", countdown);

  useEffect(() => {
    if (countdown === 0) {
      setRestTime(0);
      freshTime();
    }
  }, [countdown, freshTime, setRestTime]);

  const handleClaimRewards = useCallback(() => {
    handleTransactionReceipt(
      tiny.claimCheckinRewards(),
      t("Claim Reward")
    ).then((tx) => {
      if (tx) {
        return handleTxConfirm(tx.hash).then(() => {
          startFreshTINCBalance();
          startCheckinRefetch();
        });
      }
    });
  }, [
    handleTransactionReceipt,
    tiny,
    t,
    handleTxConfirm,
    startFreshTINCBalance,
    startCheckinRefetch,
  ]);

  const SignHeroImg = useMemo(
    () => (
      <StyledImg
        height={isMobile ? 150 : 278}
        width={isMobile ? 369 : 684}
        imgStyle={{
          display: "block",
        }}
        style={
          isMobile
            ? {
                width: "90%",
                position: "absolute",
                zIndex: "-1",
                left: "-22%",
              }
            : {
                width: "50%",
              }
        }
        src={hero}
        preSrc={heroPre}
        isStroage
        alt=""
      />
    ),
    [isMobile]
  );

  const handleCheckin = useCallback(() => {
    // setCheckInDisable(true);
    handleTransactionReceipt(tiny.checkIn(), t("Check-in"))
      .then((tx) => {
        if (tx) {
          return handleTxConfirm(tx.hash).then(() => {
            if (data) {
              data.checkinCount = data.checkinCount + 1;
            }
            freshTime();
            startCheckinRefetch();
            setRestTime(1);
            setCheckinCount((pre) => {
              if (typeof pre === "number") {
                return pre + 1;
              }
              return pre;
            });
            const signEnd: {
              day: Date;
              sign: boolean;
            } = {
              ...JSON.parse(
                localStorage.getItem("signEnd") ||
                  JSON.stringify({
                    day: dayjs().format("YYYY-MM-DD"),
                    sign: true,
                  })
              ),
              sign: true,
            };
            localStorage.setItem("signEnd", JSON.stringify(signEnd));
          });
        } else {
          // setCheckInDisable(false);
        }
      })
      .catch(() => {
        // setCheckInDisable(false);
      });
  }, [
    handleTransactionReceipt,
    tiny,
    handleTxConfirm,
    t,
    freshTime,
    data,
    setRestTime,
    startCheckinRefetch,
  ]);

  const signButton = useMemo(() => {
    return (
      <>
        {isMobile ? (
          <StyledSignButton
            onClick={(e) => {
              e.stopPropagation();
              handleCheckin();
            }}
            className={todayCheckin ? "card_sign_disabled" : undefined}
          >
            <span>
              {todayCheckin
                ? t("checked in")
                : t("check in").toLocaleLowerCase()}
            </span>
          </StyledSignButton>
        ) : (
          signItems &&
          data && (
            <StyledSignButton
              onClick={(e) => {
                e.stopPropagation();
                handleCheckin();
              }}
              className={todayCheckin ? "card_sign_disabled" : undefined}
            >
              <span>
                {todayCheckin
                  ? t("checked in")
                  : t("check in").toLocaleLowerCase()}
              </span>
            </StyledSignButton>
          )
        )}
      </>
    );
  }, [isMobile, todayCheckin, t, signItems, data, handleCheckin]);

  const signItemDetail = useMemo(
    () =>
      signItems.map((signItem, index) => {
        return (
          <SignBox
            key={index}
            {...signItem}
            onCheckin={handleCheckin}
            signButton={signButton}
          ></SignBox>
        );
      }),
    [signItems, signButton, handleCheckin]
  );
  const windowHeight = window.innerHeight;
  return (
    <Modal
      isNotBg
      style={
        isMobile
          ? {
              maxWidth: 430,
              zoom: windowHeight < 680 ? 0.8 * (windowHeight / 680) : 0.8,
              // zoom: 1,
            }
          : {
              width: 1400,
              maxWidth: 1400,
              zoom: 0.86,
              top: "-3rem",
              position: "relative",
            }
      }
      onDismiss={onDismiss}
    >
      <StyledSignContainer onClick={() => onDismiss && onDismiss()}>
        <StyledSignTopContainer>
          <StyledDaySignBox>
            <img height={75} src={signButtonImg} alt="" />
            <StyledDaySingButton>{t("gameSign")}</StyledDaySingButton>
          </StyledDaySignBox>
          {SignHeroImg}
          <StyledCountDownBox>
            <StyledCountDownTextBox>
              <span
                style={
                  isMobile && i18n.languages.includes("zh")
                    ? undefined
                    : { fontSize: "1.6rem" }
                }
              >
                {t("Countdown")}
              </span>
              <label>
                {/* {toHHMMSS(countdown)} */}
                {typeof countdownStart === "number"
                  ? toHHMM(countdown)
                  : "--h --m"}
              </label>
            </StyledCountDownTextBox>
            {isMobile && signButton}
          </StyledCountDownBox>
        </StyledSignTopContainer>
        <StyledSignItemBox>{signItemDetail}</StyledSignItemBox>
        <div
          // className={isMobile ? "mt-5" : "mt-10"}
          style={{
            width: "100%",
            marginTop: isMobile ? "0.5rem" : "3rem",
            marginBottom: isMobile ? "0.5rem" : "3rem",
          }}
        >
          <StyledClaimContainer
            style={{
              zoom: 1.14,
            }}
            show={true}
            onClick={(e) => e.stopPropagation()}
          >
            <StyledBackground>
              {showTINCReward && (
                <StyledItem>
                  <img
                    src="https://images.tinyworlds.io/tokens/TINC.png"
                    height={isMobile ? 22 : 35}
                    width={isMobile ? 22 : 35}
                    alt="TINC"
                  />
                  <span>
                    {tincRewards
                      ? utils.commify(getDisplayBalance(tincRewards, 18, 2))
                      : "--"}
                  </span>
                </StyledItem>
              )}

              <StyledItem>
                <img
                  src="https://images.tinyworlds.io/tokens/SPIRIT.png"
                  height={isMobile ? 22 : 35}
                  width={isMobile ? 22 : 35}
                  alt="SPIRIT"
                />
                <span>{spiritRewards ? spiritRewards.toNumber() : "--"}</span>
              </StyledItem>

              <PrimaryButton
                disabled={
                  !tiny.myAccount || (!showSpiritReward && !showTINCReward)
                }
                onClick={handleClaimRewards}
              >
                {t("Claim")}
              </PrimaryButton>
            </StyledBackground>
          </StyledClaimContainer>
        </div>
        <div
          // className={isMobile ? "mt-10" : "mt-20"}
          style={{
            width: "100%",
            height: "1px",
            background: "#FDCE4F",
          }}
        ></div>
        {/* <div>当前可用高度为:{windowHeight}</div> */}
        <StyledSignDetailBox
          style={i18n.languages.includes("zh") ? {} : { fontSize: "1.3rem" }}
        >
          {/* <StyledSignDetailBox onClick={(e) => e.stopPropagation()}> */}
          <span>
            <label>1. </label>
            {t("gameSignDetail1")}
          </span>
          <span>
            <label>2. </label>
            {t("gameSignDetail2")}
          </span>
          <span>
            <label>3. </label>
            {t("gameSignDetail3")}
          </span>
        </StyledSignDetailBox>
      </StyledSignContainer>
    </Modal>
  );
}

const StyledSignContainer = styled.div`
  display: flex;
  color: #fff;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledSignTopContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
`;

const StyledDaySignBox = styled.div`
  position: relative;
  zoom: 0.86;
  height: 75px;
  margin-bottom: 25px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    zoom: 0.66;
  `}
`;

const StyledDaySingButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  font-size: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
`;

const StyledCountDownTextBox = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;

  > label {
    font-size: 2.7rem;
    line-height: 2.7rem;
    // margin-left: 10px;
    letter-spacing: 1px;
    font-weight: 550;
    // min-width: 14rem;
    text-align: end;
    width: 14rem;
    text-align: end;
    margin-left: 1rem;
  }

  > span {
    font-size: 1.2rem;
    font-weight: 500;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  flex-wrap: wrap;
  width:100%;
  justify-content: flex-end;
  
  > label {
      width:100%;
      text-align: end;
  }
  > span {
    width:100%;
    text-align: end;
    font-size: 1.3rem;
  }
`}
`;

const StyledCountDownBox = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-wrap: wrap;
    justify-content: flex-end;
    position: absolute; 
    right: 0;
    
    > label {
        width:100%;
        text-align: end;
    }
    > span {
      width:100%;
      text-align: end;
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  `}
`;

const StyledSignItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px 4px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 8px 4px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr;
    // grid-template: 
    //         "a b c d" auto
    //         "a b c d" 1fr 1fr 2fr;
    //         // "b b c" 2ch
  `}
`;

const StyledSignItem = styled.div<{ day: ISignBox["day"] }>`
  flex: 1;
  min-width: ${(props) => (props.day >= 7 ? 167 * 2 : 167)}px;

  ${({ theme, day }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
    flex-direction: column;
    min-width:auto;
    grid-column-start: ${() => (day >= 7 ? 3 : "auto")};
    grid-column-end: ${() => (day >= 7 ? 5 : "auto")};
    max-height: ${() => (day >= 7 ? "100%" : "auto")};
  `}
`;

const StyledSignItemHeader = styled.div<{ day: ISignBox["day"] }>`
  background: ${(props) => (props.day >= 7 ? "#541C62" : "#314B83")};
  height: 50px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 36px;
    width:100%;
  `}
`;

const StyledSignItemBody = styled.div<{ day: ISignBox["day"] }>`
  background: ${(props) => (props.day >= 7 ? "#7918D9" : "#4367B7")};
  border-radius: 0 0 30px 30px;
  // min-height: 190px;
  display: flex;
  flex-wrap: wrap;
  // padding-bottom: 10px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 0;
    padding-bottom: 0px;
    border: 2px solid #ffffff00;
    flex: 1;
  `}
`;

const StyledSignItemBodyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 167px;
  padding-bottom: 18px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-bottom: 30px;
    max-width: 100%;
  `}
`;

const StyledBergCrystal = styled.div`
  background: #253d72;
  // width: 100px;
  min-width: 7rem;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  font-size: 1.2rem;
  position: absolute;
  bottom: 12px;
  white-space: nowrap;
  padding: 0 12px;
  box-sizing: border-box;
  // margin-top:10px;
  // margin-bottom:10px;

  > img {
    height: 20px;
    margin-right: 4px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 80%;
    max-width: 100px;
    bottom: 6px;
  `}
`;

const StyledSignButton = styled.div`
  width: 100%;
  background: #ffce00;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // bottom: -10px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffee9a;
  cursor: pointer;
  // border-top: 2px #ffee9a solid;
  padding: 4px 10px;
  overflow: hidden;

  > span {
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.3));
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-top: 0;
    border-radius: 1.5rem;
    width: auto;
  `}
`;

const StyledClaimContainer = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, show }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    ${!show && "display: none;"}
  `}
`;

const StyledSignDetailBox = styled.div`
  // width:100%;
  display: flex;
  align-items: self-start;
  padding: 28px 0;
  flex-direction: column;
  font-size: 1.19rem;

  > span {
    // width:100%;
    display: flex;
    > label {
      margin-right: 4px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 12px;
  `}
`;

const StyledSignImgAdd = styled.span`
  display: flex;
  align-items: center;
  color: #490077;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
`;

const StyledSignEndBox = styled.div`
  position: absolute;
  width: 100%;
  // height: 100%;
  min-height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fdce4f;
  font-size: 2.3rem;
  font-weight: 600;
  z-index: 3;
  transform: rotate(-22deg);
  filter: drop-shadow(#615e22 0px 6px);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.7rem;
    min-height: 11rem;
  `}
`;

const StyledImg = styled(PrefilledImage)``;

const StyledBackground = styled.div`
  background-color: #263a67;
  border-radius: 24px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 7px;

  span {
    margin-right: 1rem;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 31px;
    padding: 0 4px;
  `}
`;

const StyledItem = styled.span`
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
`;
