import { FC, useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { utils, BigNumber } from "ethers";

import DestoryIcon1 from "src/images/destory_1.png";
import DestoryIcon2 from "src/images/destory_2.png";
import DestoryIcon3 from "src/images/destory_3.png";
import DestoryIcon4 from "src/images/destory_4.png";
import diImg from "src/images/di_2.png";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/hooks/useIsMobile";
import useTINCMarket, { useFundToken } from "src/hooks/useTINCMarket";
import { getDisplayBalance } from "src/utils/formatBalance";
import { Context as FarmsContext } from "src/contexts/Farms";

interface DestroyItem {
  icon: string;
  text: string;
  context: string;
  isPrice?: boolean;
  isTinc?: boolean;
  lightText?: string;
}

export const DestroyBackItem: FC<DestroyItem> = ({
  icon,
  text,
  context,
  lightText,
  isPrice = false,
  isTinc = false,
}) => {
  const isMobile = useIsMobile();
  return (
    <StyledContaineItem className="pb-10 pt-10">
      <img className={isMobile ? "" : "mb-5 mt-5"} src={icon} />
      <StyledContaineItemTitle className={isMobile ? "" : ""}>
        {/* {lightText && <span style={{ fontWeight: 600 }}>{lightText}</span>} */}
        <span>{text}</span>
      </StyledContaineItemTitle>
      <span className={isMobile ? "" : "mb-5"}>
        {isPrice && <>$ </>}
        {context}
        {isTinc && <> TINC</>}
      </span>
    </StyledContaineItem>
  );
};

const DestroyBack: FC = () => {
  const { t } = useTranslation();
  const market = useTINCMarket();
  const { tincAvgPriceFor3Days, burnedFor30Days } = useContext(FarmsContext);
  const { buybackPool } = useFundToken();

  const tincPriceFor3Days = useCallback(
    (tincAvgPriceFor3Days: number | undefined) => {
      return tincAvgPriceFor3Days
        ? (tincAvgPriceFor3Days / 1e6).toFixed(4)
        : "--";
    },
    []
  );

  const destoryList = useMemo((): DestroyItem[] => {
    return [
      {
        icon: DestoryIcon1,
        text: t("Buyback Pool"),
        context:
          // getFullDisplayBalance(buybackPool, 18, 2).toFixed(2).toLocaleString() || "--",
          utils.commify(getDisplayBalance(buybackPool, 18, 0)) || "--",
        isPrice: true,
      },
      {
        icon: DestoryIcon2,
        text: t("TINC 72h Avg Price"),
        context: tincPriceFor3Days(tincAvgPriceFor3Days),
        isPrice: true,
      },
      {
        icon: DestoryIcon3,
        text: t("Total Burned"),
        context: market.burnBalance || "--",
        isTinc: true,
      },
      {
        icon: DestoryIcon4,
        text: t("Burned this Week"),
        context: burnedFor30Days
          ? utils.commify(BigNumber.from(burnedFor30Days).div(1e6).toString())
          : "--",
        isTinc: true,
      },
    ];
  }, [
    t,
    market,
    tincPriceFor3Days,
    burnedFor30Days,
    tincAvgPriceFor3Days,
    buybackPool,
  ]);
  return (
    <StyledContainer>
      {destoryList.map((item, index) => {
        return <DestroyBackItem {...item} key={index} />;
      })}
    </StyledContainer>
  );
};

export default DestroyBack;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 20px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 15px;
    box-sizing: border-box;
    gap: 0rem;

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  `}
`;

const StyledContaineItem = styled.div`
  display: flex;
  background-image: url(${diImg});
  width: 18rem;
  min-width: 20%;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  flex-wrap: wrap;
  box-shadow: 0 2px 2px rgb(0 0 0 / 16%);

  > span {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
  }
  > img {
    width: 55px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      width: 45px;
    `}
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`;

const StyledContaineItemTitle = styled.div`
  width: 100%;
  text-align: center;
`;
