import saber from "src/images/job/job_1.png";
import archer from "src/images/job/job_2.png";
import caster from "src/images/job/job_3.png";
import n from "src/images/quality/n.png";
import r from "src/images/quality/r.png";
import sr from "src/images/quality/sr.png";
import ssr from "src/images/quality/ssr.png";
import sssr from "src/images/quality/sssr.png";
import ur from "src/images/quality/ur.png";
import normalBorder from "src/images/lord/hero/n_r_sr_border.png";
import ssrBorder from "src/images/lord/hero/ssr_border.png";
import sssrBorder from "src/images/lord/hero/sssr_border.png";
import urBorder from "src/images/lord/hero/ur_border.png";
import heroPrefill from "src/images/hero/hero_pre.png";
import saberBg from "src/images/lord/mobile/saber_bg.png";
import archerBg from "src/images/lord/mobile/archer_bg.png";
import casterBg from "src/images/lord/mobile/caster_bg.png";
import iconIdle from "src/images/status/icon_idle.png";
import iconFarming from "src/images/status/icon_farming.png";
import powerL from "src/images/power.svg";
import heroWin from "src/images/lord/camp/hero_win.png";
import heroLost from "src/images/lord/camp/hero_lost.png";

import { ICardHero, IHeroHead } from "./type";
import { NFTInfo } from "src/services/typings";
import { RestraintState } from "src/views/Mine/TinyLord/TinyLordTerritory/components/CastleState";

export const getHeroInfo = (
  nftInfo: NFTInfo | undefined
): IHeroHead | undefined => {
  return nftInfo
    ? {
        id: nftInfo.id,
        ownerflag: nftInfo.ownerflag,
        itemCode: nftInfo.itemCode,
        itemLevel: nftInfo.itemLevel,
        gradeType: nftInfo.itemGrade,
        initPower: nftInfo.initPower,
        power: nftInfo.power,
      }
    : undefined;
};

export const getHeroHeadImg = (itemCode: ICardHero["itemCode"]) => {
  if (itemCode < 10) {
    return `//images.tinyworlds.io/hero-head/B00${itemCode}.png`;
  }
  return `//images.tinyworlds.io/hero-head/B${itemCode}.png`;
};

export const getHeroHeadImgPre = () => {
  return heroPrefill;
};

export const gradeImg = [n, r, sr, ssr, sssr, ur];

export const roleImg = [saber, archer, caster];

export const avatarBorders = [normalBorder, ssrBorder, sssrBorder, urBorder];

export const getRoleBg = [saberBg, archerBg, casterBg];

export const statusImg = [iconIdle, iconFarming];

export const heroImgHeight = 0.74;

export const restraintImgs: Record<RestraintState, string> = {
  normal: powerL,
  win: heroWin,
  lost: heroLost,
};

export const restraintPowerColor: Record<RestraintState, string> = {
  normal: "#FFFFFF",
  win: "#61DB41",
  lost: "#F00000",
};
