import { useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";

import restore from "src/images/props/restore.png";
import DeleteSvg from "./DeleteSvg";
import { RuneAuction } from "src/services/auctions";
import { formatMarketPrice, trimZero } from "src/utils";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  id: number;
  price: string | number;
  info: RuneAuction;
  onDelete(id: number): void;
}
export default function CartSpiritInfo(props: Props) {
  const { id, price, info, onDelete } = props;
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const displayPrice = useMemo(() => {
    return new BigNumber(price).div(1e6).toNumber();
  }, [price]);

  return (
    <StyledContainer key={id}>
      <StyledLeft>
        <StyledNFTImg src={restore} alt="Spirit" />
        <div className="display-flex flex-column">
          <div className="display-flex flex-column mb-5">
            <StyledName>SPIRIT</StyledName>
            <StyledAmount className="fw-600 text-outline-07">
              x{info.amount}
            </StyledAmount>
          </div>
          <StyledUnitPrice>
            <StyledBorderCurrency
              className="mr-2"
              src="//images.tinyworlds.io/tokens/BUSD.png"
              width="18"
              alt="BUSD"
            />
            {trimZero(formatMarketPrice(info.unitPrice).toFixed(5))} /{" "}
            {t("spirit unit price")}
          </StyledUnitPrice>
        </div>
      </StyledLeft>
      <StyledRight>
        <div className="display-flex flex-center">
          <StyledBorderCurrency
            src="//images.tinyworlds.io/tokens/BUSD.png"
            width="22"
            alt="BUSD"
          />
          <StyledPrice>{displayPrice}</StyledPrice>
        </div>
        <StyleDeleteButton
          className="display-flex flex-center"
          onClick={() => onDelete(id)}
        >
          <DeleteSvg fill="#fdd456" />
        </StyleDeleteButton>
      </StyledRight>
    </StyledContainer>
  );
}

const StyledNFTImg = styled.img`
  object-fit: contain;
  width: 70px;
  margin: 0 1rem 0 0.75rem;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 53px;
  `}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  height: 94px;
  box-sizing: border-box;

  & + & {
    border-top: 1px solid #707070;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 79px;
  `}
`;

const StyledAmount = styled.div`
  font-size: 1.3rem;
  color: #fff;
  line-height: 1;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;

const StyledPrice = styled.span`
  margin-right: 6px;
  margin-left: 6px;
  /* color: ${(props) => props.theme.color.yellow}; */
  font-size: 1.2rem;
  /* font-weight: 500; */
`;

const StyleDeleteButton = styled.button`
  padding: 4px 9px 3px;
  margin-right: 6px;
  background-color: #514b45;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  border-radius: 19px;
`;

const StyledBorderCurrency = styled.img`
  border: 1px solid #514b45;
  border-radius: 20px;
`;

const StyledName = styled.span`
  font-size: 1.1rem;
  line-height: 1.1rem;
`;

const StyledUnitPrice = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-left: -1px;
`;
