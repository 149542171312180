import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TButton from "src/components/Button/ParallelogramButton";
import PetCard from "src/components/NFTCard/PetCard";
import { PetDetail } from "src/components/NFTCard/PetCard/type";
import PetEmpty from "src/components/NFTCard/PetEmpty";
import useIsMobile from "src/hooks/useIsMobile";
import styled from "styled-components";

interface Props {
  setId: React.Dispatch<React.SetStateAction<string>>;
  info: PetDetail | undefined;
  id: string;
  petsRef: React.MutableRefObject<HTMLDivElement>;
}

// const height =  width * 309 / 230;
let Timer: NodeJS.Timeout;

function PetsMockBox({ info, setId, id, petsRef }: Props) {
  const { t } = useTranslation();
  const boxRef = useRef(document.createElement("div"));
  const [reId, setReId] = useState("");
  const [boxWidth, setBoxWidth] = useState(0);
  const isMobile = useIsMobile();

  useEffect(() => {
    setReId(id);
  }, [id]);

  const calcWidth = useCallback(() => {
    setBoxWidth(petsRef.current.offsetWidth);
  }, [petsRef]);

  useEffect(() => {
    if (petsRef.current) {
      window.addEventListener("resize", calcWidth);
      calcWidth();
    }
    return () => {
      window.removeEventListener("resize", calcWidth);
    };
  }, [calcWidth, petsRef]);

  useEffect(() => {
    if (Timer) {
      clearTimeout(Timer);
    }

    Timer = setTimeout(() => {
      setId(reId);
    }, 300);

    return () => {
      clearTimeout(Timer);
    };
  }, [reId, setId]);

  const cardZoom = useMemo(() => {
    return isMobile
      ? (boxWidth - 12) / (230 * 2) > 1
        ? 1
        : (boxWidth - 12) / (230 * 2)
      : boxWidth / (230 * 2) > 1
      ? 1
      : boxWidth / (230 * 2);
  }, [boxWidth, isMobile]);

  return (
    <StyledContainer ref={boxRef}>
      {info ? (
        <PetCard
          style={{
            zoom: cardZoom,
          }}
          info={info}
        ></PetCard>
      ) : (
        <PetEmpty
          style={{
            zoom: cardZoom,
          }}
          type={"empty"}
          bgWhite
        ></PetEmpty>
      )}
      <StyledInput
        style={{
          width: isMobile ? "32vw" : boxRef.current.clientWidth * 0.75,
        }}
        onChange={(e) => setReId(e.target.value)}
        value={reId}
        placeholder={t("input Pets Number")}
      />
    </StyledContainer>
  );
}

export default PetsMockBox;

const StyledInput = styled.input`
  border: 1px solid #707070;
  height: 2rem;
  margin-top: 0.8rem;
  border-radius: 1rem;
  background: #fffbdd;
  box-sizing: border-box;
  padding: 4px 8px;
  outline: none;
  text-align: center;
  user-select: none;
  width: 0px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
`;
