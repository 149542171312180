import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { getDefaultProvider } from "src/utils/provider";
import { noFunc } from "src/utils";
import { chainId } from "src/config";

const SETTLEMENT_TIME = 8 * 3600;
// const day = chainId === 56 ? 86400 : 180;
const day = 86400;

function getTimeCountdown(now: number) {
  const gap = BigNumber.from(day);
  return gap.sub(BigNumber.from(now - SETTLEMENT_TIME).mod(gap)).toNumber();
}

export default function useCountdown(cb = noFunc) {
  const [data, setData] = useState<number>();

  const getBlockInfo = useCallback(() => {
    const provider = getDefaultProvider();
    return provider.getBlock("latest").then((res) => {
      const timestamp = getTimeCountdown(res.timestamp);
      // console.log("从链上请求 timestamp", timestamp);
      setData(timestamp);
    });
  }, []);

  useEffect(() => {
    getBlockInfo();
    let timer = setInterval(() => {
      setData((pre) => {
        if (pre === undefined) {
          return undefined;
        }
        if (pre > 0) {
          return pre - 1;
        }
        cb();
        return day;
      });
    }, 1_000);
    return () => {
      window.clearInterval(timer);
    };
  }, [getBlockInfo, cb]);
  return data;
}
