// import { AuthereumConnector } from '@web3-react/authereum-connector'
// import { FortmaticConnector } from '@web3-react/fortmatic-connector'
// import { FrameConnector } from '@web3-react/frame-connector'
import { InjectedConnector } from "@web3-react/injected-connector";
// import { LatticeConnector } from '@web3-react/lattice-connector'
// import { LedgerConnector } from '@web3-react/ledger-connector'
// import { MagicConnector } from '@web3-react/magic-connector'
// import { NetworkConnector } from '@web3-react/network-connector'
// import { PortisConnector } from '@web3-react/portis-connector'
// import { TorusConnector } from '@web3-react/torus-connector'
// import { TrezorConnector } from '@web3-react/trezor-connector'
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { BitKeepConnector } from "bitKeep-connector";
import { InjectedCommonConnector } from "./injected-connector-base";
import { InjectedReplaceConnector } from "./injected-connector-replace";

import config from "src/config";

// const POLLING_INTERVAL = 12000
// const RPC_URLS: { [chainId: number]: string } = {
//   1: process.env.RPC_URL_1 as string,
//   4: process.env.RPC_URL_4 as string
// }

export const injected = new InjectedConnector({
  supportedChainIds: [config.chainId],
});

export const bsc = new BscConnector({
  supportedChainIds: [config.chainId],
});

export const bitKeep = new BitKeepConnector({
  supportedChainIds: [config.chainId],
});

export const $onekey = new InjectedCommonConnector(
  {
    supportedChainIds: [config.chainId],
  },
  "$onekey"
);

export const trustwallet = new InjectedCommonConnector(
  {
    supportedChainIds: [config.chainId],
  },
  "trustwallet"
);

export const okxwallet = new InjectedReplaceConnector(
  {
    supportedChainIds: [config.chainId],
  },
  "okxwallet"
);

// export const network = new NetworkConnector({
//   urls: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
//   defaultChainId: 1,
// });

export const walletconnect = new WalletConnectConnector({
  rpc: {
    [config.chainId]: config.defaultProvider[0],
  },
  chainId: config.chainId,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: config.defaultProvider[0],
  appName: "Tiny Farm",
  supportedChainIds: [config.chainId],
});

// export const ledger = new LedgerConnector({
//   chainId: 1,
//   url: RPC_URLS[1],
//   pollingInterval: POLLING_INTERVAL,
// });

// export const trezor = new TrezorConnector({
//   chainId: 1,
//   url: RPC_URLS[1],
//   pollingInterval: POLLING_INTERVAL,
//   manifestEmail: "dummy@abc.xyz",
//   manifestAppUrl: "http://localhost:1234",
// });

// export const lattice = new LatticeConnector({
//   chainId: 4,
//   appName: "web3-react",
//   url: RPC_URLS[4],
// });

// export const frame = new FrameConnector({ supportedChainIds: [1] });

// export const authereum = new AuthereumConnector({ chainId: 42 });

// export const fortmatic = new FortmaticConnector({
//   apiKey: process.env.FORTMATIC_API_KEY as string,
//   chainId: 4,
// });

// export const magic = new MagicConnector({
//   apiKey: process.env.MAGIC_API_KEY as string,
//   chainId: 4,
//   email: "hello@example.org",
// });

// export const portis = new PortisConnector({
//   dAppId: process.env.PORTIS_DAPP_ID as string,
//   networks: [1, 100],
// });

// export const torus = new TorusConnector({ chainId: 1 });
