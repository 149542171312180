import styled from "styled-components";

export const StyledNav = styled.span`
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  color: #fff;
  text-decoration: none;
`;

const getListContainerZoom = () => {
  const zoomMaxWidth = 450;
  const width = window.innerWidth;
  return width >= zoomMaxWidth ? 1 : width / zoomMaxWidth;
};

// 比较通用的
export const ListContainer = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, 230px);
  justify-content: start;
  column-gap: 2rem;
  row-gap: 1.5em;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: center;
    column-gap: 0.7rem;
    row-gap: 1em;
    zoom: ${getListContainerZoom()}
  `};
  ${({ theme }) => theme.mediaWidth.upToXS`
    // zoom: 0.8;
  `}
`;

export const StyledSelectContainerZoom = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    zoom: 0.85;
  `};
  ${({ theme }) => theme.mediaWidth.upToXS`
    zoom: 0.75;
  `}
`;

// 这个是需要有选择框的卡片用的
export const BigListContainer = styled(StyledSelectContainerZoom)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 242px);
  overflow-y: auto;
`;

export const SelectListContainer = styled.div<{ height: string }>`
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, 242px);
  grid-auto-rows: 320px;
  justify-content: start;
  column-gap: 2rem;
  row-gap: 1.5em;
  height: ${({ height }) => height};
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ theme }) => theme.mediaWidth.upToMediumLarge`
    column-gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: repeat(2, 242px);
    height: calc(100% - 8rem);
    padding: 0.5rem 0 0.8rem;
    justify-content: center;
    column-gap: 0.4rem;
    row-gap: 1em;
    zoom: 0.9;
  `};
  ${({ theme }) => theme.mediaWidth.upToXS`
    margin: 0;
    zoom: 0.8;
  `}
`;

export const StyledMaxButton = styled.button`
  padding: 0.25rem 0.35rem;
  width: 4rem;
  background-image: linear-gradient(#e0b081, #5f3e05);
  border-radius: 1rem;
  color: #e8e8e8;
  font-weight: 600;
  font-size: 1.2rem;
`;

// 只在 Modal 中用到
export const StyledHeroCardList = styled.div`
  display: grid;
  column-gap: 10px;
  row-gap: 1em;
  padding-right: 1px;
  grid-template-columns: repeat(4, 1fr);
  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    zoom: 0.75;
  `};
  ${({ theme }) => theme.mediaWidth.upToXS`
    zoom: 0.7;
  `}
`;
