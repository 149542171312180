import React, { useState, useEffect, useCallback, ReactNode } from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import { getStrFromOptions, Options } from "../ResizedImage";

const isProduction = process.env.NODE_ENV === "production";

const cache: {
  [url: string]: boolean;
} = {};

interface Prop {
  width?: number;
  height?: number;
  src: string;
  preSrc: string | ReactNode;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
  srcset?: string; // 响应式图片
  imgStyle?: React.CSSProperties;
  loadingStyle?: React.CSSProperties;
  resizeOpt?: Options;
  autoHeight?: boolean;
  isBreath?: boolean; //是否为呼吸灯
  imgId?: string;
  isStroage?: boolean; //是否走緩存  false 走緩存 true 不走緩存
  outLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
  ref?: React.MutableRefObject<any>;
}

export default function PrefilledImage(props: Prop) {
  const {
    style,
    className,
    width,
    height,
    alt,
    src,
    preSrc,
    srcset,
    imgStyle,
    resizeOpt,
    autoHeight = false,
    loadingStyle,
    isBreath,
    imgId,
    isStroage = false,
    outLoaded,
    ref,
  } = props;

  let imgSrc = src;

  if (isProduction && resizeOpt) {
    const optionStr = getStrFromOptions(resizeOpt);
    if (window.location.host.includes("tinyworlds.io")) {
      imgSrc = `/cdn-cgi/image/${optionStr}` + src;
    }
  }

  const [loaded, setLoaded] = useState(isStroage ? !isStroage : cache[imgSrc]);
  const [visible, setVisible] = useState(
    isStroage ? isStroage : !cache[imgSrc]
  );

  const handleLoaded = useCallback(() => {
    setLoaded(true);
    cache[imgSrc] = true;
  }, [imgSrc]);

  useEffect(() => {
    let timer: number | null = null;
    if (loaded) {
      timer = window.setTimeout(() => {
        setVisible(false);
      }, 500);
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [loaded]);

  useEffect(() => {
    if (outLoaded) {
      outLoaded(loaded);
    }
  }, [loaded, outLoaded]);

  return (
    <div className={clsx(styles.box, className)} style={style}>
      {visible && !outLoaded && (
        <span
          className={clsx(
            styles.size,
            styles.pre_box,
            isBreath && styles.preBreath
          )}
          style={{ opacity: loaded ? 0 : 0.8 }}
        >
          {typeof preSrc === "string" ? (
            <img
              className={styles.size}
              style={loadingStyle}
              src={preSrc}
              width={width}
              height={height}
              key={preSrc}
              alt=""
            />
          ) : (
            preSrc
          )}
        </span>
      )}
      <img
        ref={ref}
        id={imgId}
        className={styles.size}
        src={imgSrc}
        srcSet={srcset}
        width={width}
        height={height}
        alt={alt}
        key={imgSrc}
        style={{
          height: autoHeight ? "auto" : undefined,
          ...imgStyle,
          opacity: loaded ? 1 : 0,
        }}
        onLoad={handleLoaded}
      />
    </div>
  );
}
