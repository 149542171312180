import {
  useCallback,
  useState,
  useMemo,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import clsx from "clsx";
import { Context as TinyContext } from "src/contexts/TinyProvider";

import useIsMobile from "src/hooks/useIsMobile";
import DownIcon from "src/components/Svg/DownIcon";
import useModal from "src/hooks/useModal";
import airdropSvg from "src/images/airdrop.svg";
import airdropMobile from "src/images/airdrop_mobile.png";

import SpiritAirdropModal from "src/contexts/Page/components/SpiritAirdropModal";
import { useNewAirdrops } from "src/contexts/Page/hooks/airdropHooks";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
import useTiny from "src/hooks/useTiny";
import AirdropModal from "../AirdropModal";
import { BigNumber } from "ethers";
import GasRefundModal from "src/contexts/Page/components/GasRefundModal";
import { bn0 } from "src/utils";

interface OptionProps {
  keepActive: boolean;
  handleClick(): void;
}

export function Option({
  keepActive = true,
  handleClick,
  children,
}: PropsWithChildren<OptionProps>) {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={handleClick} isActive={keepActive}>
      {children}
    </StyledButton>
  );
}

interface DropDown {
  alignLeft?: boolean;
  className?: string;
}

export default function AirdropDropDown({
  className,
  alignLeft = false,
  children,
}: PropsWithChildren<DropDown>) {
  const isMobile = useIsMobile();
  const { tiny, startFreshTINCBalance } = useContext(TinyContext);
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => setShow((pre) => !pre), []);

  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  const { data: newAirdropData } = useNewAirdrops();
  const bnbGasAmount = newAirdropData?.bnbAmount;
  const spiritValue = newAirdropData?.spiritValue;

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        document.addEventListener(
          "click",
          function () {
            if (show) {
              setShow(false);
            }
          },
          {
            once: true,
          }
        );
      }, 100);
    }
  }, [show]);

  const [showSpiritAirdropModal, onSpiritModalDismiss] = useModal(
    <SpiritAirdropModal
      spiritValue={spiritValue || 0}
      claimAirdrop={() => {
        const message = t("Claim Airdrop Rewards");
        return handleTransactionReceipt(tiny.claimSpirit(), message).then(
          (tx) => {
            if (tx) {
              handleTxConfirm(tx.hash).then(() => {
                onSpiritModalDismiss();
              });
            }
          }
        );
      }}
    />,
    [spiritValue]
  );

  const [airdropTotal, setAirdropTotal] = useState<{
    tincAmount: BigNumber;
    runeValue: BigNumber;
  }>();
  const account = tiny.myAccount;
  const airdropContract = tiny.contracts.TinyAirdrop;
  const getTotal = useCallback(() => {
    if (!account) return;
    airdropContract.getAirdrops().then((res: [BigNumber, BigNumber]) => {
      // console.log("getAirdrops res", res);
      const [tincAmount, runeValue] = res;
      if (!tincAmount.isZero() || !runeValue.isZero()) {
        setAirdropTotal({ tincAmount, runeValue });
      }
    });
  }, [airdropContract, account]);

  const claimAirdrop = useCallback(() => {
    const message = t("Claim Airdrop Rewards");

    return handleTransactionReceipt(tiny.claimAirdrop(), message).then((tx) => {
      if (tx) {
        handleTxConfirm(tx.hash).then(() => {
          startFreshTINCBalance();
        });
      }
    });
  }, [
    tiny,
    handleTransactionReceipt,
    startFreshTINCBalance,
    handleTxConfirm,
    t,
  ]);

  const [showAirdropModal] = useModal(
    <AirdropModal
      total={airdropTotal}
      claimAirdrop={claimAirdrop}
      fresh={getTotal}
    />,
    [airdropTotal, getTotal]
  );

  const [showGasRefundModal, onGasModalDismiss] = useModal(
    <GasRefundModal
      amount={bnbGasAmount || bn0}
      claimAirdrop={() => {
        const message = t("Claim Gas Rewards");
        return handleTransactionReceipt(tiny.claimGas(), message).then((tx) => {
          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              onGasModalDismiss();
            });
          }
        });
      }}
    />,
    [bnbGasAmount]
  );

  const showAirdrop = Boolean(airdropTotal);
  const showSpirit = typeof spiritValue === "number" && spiritValue > 0;
  const showGasRefund = bnbGasAmount && !bnbGasAmount.isZero();

  if (!showAirdrop && !showSpirit && !showGasRefund) {
    return null;
  }

  // 只有一个空投
  if (showAirdrop && !showSpirit && !showGasRefund) {
    if (isMobile) {
      return (
        <StyledImgBox show={false} onClick={showAirdropModal} className="mr-5">
          <img height={20} src={airdropMobile} alt="" />
        </StyledImgBox>
      );
    }

    return (
      <StyledNormalButton onClick={showAirdropModal}>
        <img
          className="mr-5"
          src="//images.tinyworlds.io/tokens/TINC.png"
          height={18}
          alt=""
        />
        {t("claim_airdrop_tinc")}
      </StyledNormalButton>
    );
  }

  if (!showAirdrop && showSpirit && !showGasRefund) {
    if (isMobile) {
      return (
        <StyledImgBox
          show={false}
          onClick={showSpiritAirdropModal}
          className="mr-5"
        >
          <img height={20} src={airdropMobile} alt="" />
        </StyledImgBox>
      );
    }

    return (
      <StyledNormalButton onClick={showSpiritAirdropModal}>
        <img
          className="mr-5"
          src="//images.tinyworlds.io/tokens/SPIRIT.png"
          height={18}
          alt=""
        />
        {t("claim_airdrop_spirit")}
      </StyledNormalButton>
    );
  }

  if (!showAirdrop && !showSpirit && showGasRefund) {
    if (isMobile) {
      return (
        <StyledImgBox
          show={false}
          onClick={showGasRefundModal}
          className="mr-5"
        >
          <img height={20} src={airdropMobile} alt="" />
        </StyledImgBox>
      );
    }

    return (
      <StyledNormalButton onClick={showGasRefundModal}>
        <img
          className="mr-5"
          src="//images.tinyworlds.io/tokens/BNB.png"
          height={18}
          alt=""
        />
        {t("claim_airdrop_gas")}
      </StyledNormalButton>
    );
  }

  if (isMobile) {
    return (
      <StyledMobileContainer onClick={handleClick} className="mr-5">
        <StyledImgBox show={show} style={{ padding: 6 }}>
          <img height={20} src={airdropMobile} alt="" />
          <DownIcon
            style={{ height: 6, marginLeft: 3 }}
            color="#FDCE4F"
            isRotate={true}
          />
        </StyledImgBox>
        <StyledOptionContainer
          isShow={show}
          style={{ width: i18n.languages.includes("zh") ? 80 : 70 }}
        >
          {
            // TINC + Mystery Rune
            showAirdrop && (
              <Option keepActive={false} handleClick={showAirdropModal}>
                {t("claim_airdrop_tinc")}
              </Option>
            )
          }
          {
            // Spirit
            showSpirit && (
              <Option keepActive={false} handleClick={showSpiritAirdropModal}>
                {t("claim_airdrop_spirit")}
              </Option>
            )
          }
          {
            // gas fee refund
            showGasRefund && (
              <Option keepActive={false} handleClick={showGasRefundModal}>
                {t("claim_airdrop_gas")}
              </Option>
            )
          }
        </StyledOptionContainer>
      </StyledMobileContainer>
    );
  }

  // PC端多个
  return (
    <StyledContainer className={className}>
      <StyledOptionButton isShow={show} onClick={handleClick}>
        <img className="mr-5" height={18} src={airdropSvg} alt="" />
        {t("Claim Airdrop")}
        <DownIcon
          style={{ height: 8, marginLeft: 5 }}
          color="#514b45"
          isRotate={true}
        />
      </StyledOptionButton>
      <StyledContent isShow={show} alignLeft={alignLeft}>
        {
          // TINC + Mystery Rune
          showAirdrop && (
            <Option keepActive={false} handleClick={showAirdropModal}>
              {t("claim_airdrop_tinc")}
            </Option>
          )
        }
        {
          // Spirit
          showSpirit && (
            <Option keepActive={false} handleClick={showSpiritAirdropModal}>
              {t("claim_airdrop_spirit")}
            </Option>
          )
        }
        {
          // gas fee refund
          showGasRefund && (
            <Option keepActive={false} handleClick={showGasRefundModal}>
              {t("claim_airdrop_gas")}
            </Option>
          )
        }
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
  height: 36px;
  width: auto;
  z-index: 8;
  margin-right: 15px;
  font-weight: 600;
`;

const StyledContent = styled.div<{
  isShow: boolean;
  alignLeft: boolean;
}>`
  padding: 0;
  margin-top: 6px;
  width: max-content;
  height: auto;
  max-height: ${({ isShow }) => (isShow ? "150px" : "0px")};
  background-color: #fdce4f;
  color: #514b45;
  border-radius: 18px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
  transition: max-height 0.2s;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 0;
  `}
`;

const StyledBaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  color: #514b45;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
`;

const StyledOptionButton = styled(StyledBaseButton)<{ isShow: boolean }>`
  height: 36px;
  padding: 0px 12px;
  justify-content: space-around;
  position: relative;
  background-color: #fdce4f;
  border-radius: 18px;
  ${({ isShow }) =>
    isShow
      ? `
    transform: translateY(3px);
  `
      : `
    box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  `}
  transition: box-shadow 0.2s, transform 0.2s;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0;
    padding: 2px 6px;
  `}
`;

const StyledButton = styled(StyledBaseButton)<{ isActive: boolean }>`
  padding: 0 16px;
  height: 28px;
  min-width: 135px;
  justify-content: start;
  word-break: keep-all;
  white-space: nowrap;

  @media (any-hover: hover) {
    &:hover {
      background-color: #514b45;
      color: #fdce4f;

      > svg > g > path {
        fill: #fff2d0 !important;
      }
    }
  }

  &.active {
    background-color: #514b45;
    pointer-events: none;
    color: #514b45;
  }

  > img {
    margin-right: 5px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 6px;
    color: #fdce4f;

    &.active {
      background-color: #fdce4f;
      pointer-events: none;
      color: #514b45;
    }
  `}
`;

const StyledMobileContainer = styled.div`
  position: relative;
`;

const StyledImgBox = styled.div<{ show: boolean }>`
  background-color: #514b45;
  height: 2.4rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  box-sizing: border-box;
  transition: box-shadow 0.2s, transform 0.2s;

  ${({ show }) =>
    show
      ? `
    transform: translateY(3px);
  `
      : `
    box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  `}
`;

const StyledOptionContainer = styled.div<{
  isShow: boolean;
}>`
  position: absolute;
  top: calc(2.4rem + 5px);
  left: 0;
  padding: 0;
  width: 70px;
  height: auto;
  max-height: ${({ isShow }) => (isShow ? "150px" : "0")};
  background-color: #514b45;
  color: #fdce4f;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  font-weight: 600;
  transition: max-height 0.2s;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  z-index: 110;
`;

const StyledNormalButton = styled(StyledBaseButton)`
  height: 36px;
  background-color: #fdce4f;
  color: #514b45;
  padding: 0 12px;
  margin-right: 15px;
  border-radius: 1.3rem;
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  position: relative;
  box-shadow: 0px 3px 0px rgb(0 0 0 / 15%);
  width: auto;
  /* min-width: 7.8rem; */
`;
