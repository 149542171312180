import React, { CSSProperties, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Close from "src/components/Close";
import PrefilledImage from "src/components/Images/PrefilledImage";
import useIsMobile from "src/hooks/useIsMobile";
import { AdvertiseConfig } from "src/views/Home/const";
import styled from "styled-components";

function Advertise({
  style,
  advertiseRef,
}: {
  style?: CSSProperties;
  advertiseRef?: React.MutableRefObject<HTMLAnchorElement>;
}) {
  const isMobile = useIsMobile();
  const { i18n } = useTranslation();
  const [close, setClose] = useState(false);

  return AdvertiseConfig.show && !close ? (
    AdvertiseConfig.type === "navlink" ? (
      <StyledContainerNavLink
        ref={advertiseRef}
        style={style}
        to={AdvertiseConfig.url}
      >
        {isMobile && (
          <Close
            style={{
              top: -9,
              right: -9,
              zIndex: 1,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setClose(true);
            }}
          ></Close>
        )}
        {i18n.languages.includes("zh") ? (
          <PrefilledImage
            height={256}
            width={512}
            preSrc={AdvertiseConfig.preImg}
            src={AdvertiseConfig.img}
            alt=""
          />
        ) : (
          <PrefilledImage
            height={256}
            width={512}
            preSrc={AdvertiseConfig.preImg}
            src={AdvertiseConfig.img}
            alt=""
          />
        )}
      </StyledContainerNavLink>
    ) : (
      <StyledContainerA
        ref={advertiseRef}
        style={style}
        rel="noreferrer"
        target={AdvertiseConfig.target ? AdvertiseConfig.target : "_blank"}
        href={AdvertiseConfig.url}
      >
        {isMobile && (
          <Close
            style={{
              top: -9,
              right: -9,
              zIndex: 1,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setClose(true);
            }}
          ></Close>
        )}
        <PrefilledImage
          preSrc={AdvertiseConfig.preImg}
          src={AdvertiseConfig.img}
          alt=""
        />
      </StyledContainerA>
    )
  ) : (
    <></>
  );
}

export default Advertise;

const StyledContainerNavLink = styled(NavLink)`
  display: block;
  width: 80%;
  margin: 0 auto;
  position: relative;
  border-radius: 6px;
  // animation: elementShow 1.2s linear;
  margin-bottom: 14px;
  margin-top: 1rem;
  z-index: 10;

  img {
    width: 100%;
    display: block;
    height: auto;
    border-radius: 6px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: absolute;
    width: 41vw;
    right: 6vw;
    bottom: 15vh;
    border: 2px solid #FFFFFF;
    margin-bottom: 0;

    img {
      border-radius: 0px;
      // width: 41vw;
      // height: auto;
    }
  `};
`;

const StyledContainerA = styled.a`
  display: block;
  width: 80%;
  margin: 0 auto;
  position: relative;
  border-radius: 6px;
  // animation: elementShow 1.2s linear;
  margin-bottom: 14px;
  margin-top: 1rem;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: absolute;
    width: 41vw;
    right: 6vw;
    bottom: 15vh;
    border: 2px solid #FFFFFF;
    margin-bottom: 0;
    img {
      border-radius: 0px;
    }
  `};

  img {
    width: 100%;
    display: block;
    border-radius: 6px;
  }
`;
