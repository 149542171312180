import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LoadingSkeleton from "src/components/LoadingSkeleton";
import useIsMobile from "src/hooks/useIsMobile";

import bnbChain from "src/images/partner/bnb-chain.png";
import binanceLive from "src/images/partner/Binance-Live.png";
import Coinmarketcap from "src/images/partner/coinmarketcap.png";
import Coingecko from "src/images/partner/coingecko.png";
import Metamask from "src/images/partner/metamask.png";
import BitKeep from "src/images/partner/BitKeep.png";
import huobi from "src/images/partner/huobi.png";
import TokenPocket from "src/images/logos/TokenPocket.png";
import PancakeSwap from "src/images/partner/PancakeSwap.png";
import SecondLive from "src/images/partner/SecondLive.png";
import dappradar from "src/images/partner/dappradar.png";
import certik from "src/images/partner/certik.png";

interface Partner {
  imgUrl: string;
  link: string;
  height?: number;
}

export default function Partners() {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const partners: Partner[] = [
    {
      imgUrl: bnbChain,
      link: "https://bnbsmartchain.com/",
      height: 33,
    },
    {
      imgUrl: binanceLive,
      link: "https://www.binance.com/en/live",
      height: 33,
    },
    {
      imgUrl: Coinmarketcap,
      link: "https://coinmarketcap.com/currencies/tiny-coin/",
      height: 44,
    },
    {
      imgUrl: Coingecko,
      link: "https://www.coingecko.com/en/coins/tiny-coin",
      height: 50,
    },
    {
      imgUrl: huobi,
      link: "https://www.huobi.com/en-us/exchange/tinc_usdt/",
      height: 52,
    },
    {
      imgUrl: TokenPocket,
      link: "https://www.tokenpocket.pro/",
      height: 80,
    },
    {
      imgUrl: Metamask,
      link: "https://metamask.io/",
      height: 40,
    },
    {
      imgUrl: BitKeep,
      link: "https://bitkeep.com/",
      height: 46,
    },

    {
      imgUrl: PancakeSwap,
      link: "https://pancakeswap.finance/",
      height: 40,
    },
    {
      imgUrl: SecondLive,
      link: "https://secondlive.world/",
      height: 55,
    },
    {
      imgUrl: dappradar,
      link: "https://dappradar.com/",
      height: 33,
    },
    {
      imgUrl: certik,
      link: "https://www.certik.com/",
      height: 42,
    },
  ];

  return (
    <StyledContent>
      <span className="rfs-18 mb-10 fw-600">{t("partners")}</span>
      <StyledDivider />
      <StyledInvestorContainer>
        {partners.map(({ imgUrl, link, height }, index) => (
          <StyledItem key={index}>
            <LoadingSkeleton style={{ height: 100 }} src={imgUrl}>
              <StyledAnchor target={isMobile ? "_self" : "_blank"} href={link}>
                <img
                  style={
                    // isMobile
                    //   ? {}
                    //   :
                    {
                      ...(height
                        ? {
                            maxHeight: isMobile ? height * (55 / 64) : height,
                          }
                        : {}),
                    }
                  }
                  src={imgUrl}
                />
              </StyledAnchor>
            </LoadingSkeleton>
          </StyledItem>
        ))}
      </StyledInvestorContainer>
    </StyledContent>
  );
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px auto;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 15px;
    margin: 30px auto;
  `}
`;

const mediaWidth = window.innerWidth;

const StyledInvestorContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  gap: 1.7rem;
  padding: 0 1rem;
  box-sizing: border-box;

  img {
    max-width: 250px;
    max-height: 64px;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 0 2rem;
  
    img {
      max-width: 150px;
      max-height: 40px;
    }
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    gap: 0 1rem;
  `}
`;

const StyledDivider = styled.div`
  width: 100%;
  border-top: 1px solid #707070;
  margin-bottom: 30px;
`;

const StyledItem = styled.div`
  grid-column: span 1 / span 1;
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
`;

const StyledAnchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
`;
