import React from "react";

import styled from "styled-components";

interface WalletCardProps {
  icon: React.ReactNode;
  onConnect: () => void;
  title: string;
}

const WalletCard: React.FC<WalletCardProps> = ({ icon, onConnect, title }) => {
  return (
    <StyledItem onClick={onConnect}>
      <StyledImgBox>{icon}</StyledImgBox>
      <StyledTitle>{title}</StyledTitle>
    </StyledItem>
  );
};

export default WalletCard;

const StyledTitle = styled.span`
  line-height: 1rem;
  margin-top: 7px;
`;

const StyledItem = styled.div`
  display: flex;
  width: 7.5rem;
  height: 5.5rem;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    color: #696969;
    font-size: 1rem;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`   
    height: 6.8rem;
  `}
`;

const StyledImgBox = styled.span`
  svg {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
`;
