import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useIsMobile from "src/hooks/useIsMobile";

import { Context } from "src/contexts/TinyProvider";
import useNFTApprove, { ApprovalState } from "src/hooks/useNFTApprove";

import Modal, { ModalProps } from "src/components/Modal";
import ModalActions from "src/components/ModalActions";
import PrimaryButton from "../Button/PrimaryButton";
import Popover from "../CommonPopover";
import QuestionIcon from "../QuestionIcon";
import { IHeroHead } from "../HeadHeroCard/type";

import { gradeDurability, SPIRIT_CLOSE } from "src/config";
import RecoverIcon from "../Svg/RecoverIcon";
import useMyRuneBalance from "src/hooks/useMyRuneBalance";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import { highGradeType, maxPower } from "./const";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
import { useHistory, useLocation } from "react-router";
import Tooltip, { BaseAttributes } from "../Tooltip";
import RaisePowerRange from "./RaisePowerRange";
import { heroUpgradeCache, loadHeroUpgradeConfig } from "src/services/cache";
import { ownerflagType } from "src/services/typings";
import ExternalModal from "../ExternalModal";
import SpiritShoppingCartModal from "../SpiritShoppingCartModal";
import { BigNumber } from "ethers";
import { RuneAuction } from "src/services/auctions";
import {
  addAction,
  clearAction,
  deleteAction,
  spiritReducer,
} from "src/reducer/shopping";
import useUserWallet from "src/hooks/useUserWallet";

interface Props extends ModalProps {
  heros: IHeroHead[];
  disableScale: boolean;
}

export interface IRangeConfig {
  step: number;
  min: number;
  max: number;
  values: number[];
  propAmount: number;
  isMax: boolean;
  loaded: boolean;
  notRange: boolean;
}

const RaisePowerModal: React.FC<Props> = ({
  disableScale,
  heros,
  onDismiss,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const { tiny, userInfo } = useContext(Context);
  const [showTitleTip, setShowTitleTip] = useState(false);

  const [spiritData, SpiritDispatch] = useReducer(spiritReducer, { list: [] });
  const spiritList = spiritData.list;
  const spiritShoppingList = spiritList;
  const clearSpiritList = useCallback(() => SpiritDispatch(clearAction()), []);
  const addToSpiritList = (detail: RuneAuction) => {
    let list = spiritList;

    if (list.findIndex((ele) => ele.id === detail.id) === -1) {
      SpiritDispatch(addAction(detail));
    }
  };
  const deleteFromSpiritList = (id: number) => {
    SpiritDispatch(deleteAction(id));
  };

  const [rangeConfigs, setRangeConfigs] = useState<IRangeConfig[]>(() => {
    const configs: IRangeConfig[] = [];
    heros.forEach((hero) => {
      if (hero.gradeType < 4) {
        return;
      }
      let obj: IRangeConfig = {
        step: 1,
        min: 0,
        max: hero.initPower
          ? maxPower[hero.gradeType as highGradeType] - hero.initPower
          : 0,
        values: [0],
        propAmount: 0,
        isMax: false,
        loaded: true,
        notRange: false,
      };
      if (
        (hero?.initPower || maxPower[hero?.gradeType as highGradeType]) >=
        maxPower[hero?.gradeType as highGradeType]
      ) {
        configs.push({
          ...obj,
          max: 1,
          values: [1],
          loaded: false,
          notRange: true,
        });
      } else {
        configs.push(obj);
      }
    });
    return configs;
  });
  const [isHover, setIsHover] = useState(false);
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();
  const history = useHistory();
  const [staminaItemNum, startRefresh] = useMyRuneBalance(3);
  const [allConfig, setConfig] = useState(heroUpgradeCache.herolvuprule);
  const { lockSpiritBalance, refetch } = useUserWallet();

  useEffect(() => {
    if (allConfig === undefined) {
      loadHeroUpgradeConfig().then(setConfig);
    }
  }, []);

  const nftContract = tiny.contracts.TinyNFTRune;
  const tinyNFTLogicAddress = tiny.contracts.TinyNFTLogic.address;
  const [isApproved, approveNFT] = useNFTApprove(
    nftContract,
    tinyNFTLogicAddress
  );

  const allPropAmount = useMemo(() => {
    let max = 0;
    rangeConfigs.map((config, index) => {
      max += config.propAmount;
    });
    return max;
  }, [rangeConfigs]);

  const showlockSpiritAmount = useMemo(() => {
    if (lockSpiritBalance) {
      const lockSpiritAmount = lockSpiritBalance.toNumber();
      if (lockSpiritAmount > 0) {
        if (allPropAmount >= lockSpiritAmount) {
          return lockSpiritAmount;
        } else {
          return allPropAmount;
        }
      }
      return 0;
    }
    return 0;
  }, [allPropAmount, lockSpiritBalance]);

  // useEffect(() => {
  //   console.log({
  //     allPropAmount,
  //   });
  // }, [allPropAmount]);

  useEffect(() => {
    if (allPropAmount < staminaItemNum.toNumber()) {
      setRangeConfigs((prevState) => {
        const newStateValue = [
          ...prevState.map((ele) => {
            ele.isMax = false;
            return ele;
          }),
        ];
        return [...newStateValue];
      });
    }
  }, [allPropAmount, staminaItemNum]);

  const ModalTitle = useMemo(() => {
    return (
      <StyledTitle>
        {t("raise power")}
        <Popover
          style={{
            position: "absolute",
            right: -10,
            top: "50%",
            height: isMobile ? 13 : "1.2rem",
            transform: "translate(100%, -50%)",
          }}
          visible={showTitleTip}
          placement={"bottom"}
          boxStyle={{
            minWidth: isMobile ? "16rem" : "20rem",
            marginTop: isMobile ? 0 : 3,
          }}
          triggerClose={() => {
            setShowTitleTip(false);
          }}
          diy={isMobile ? "auto" : "auto"}
          handleClick={() => setShowTitleTip(true)}
          content={
            <StyledSignDetailBox>
              {/* <StyledSignDetailBox onClick={(e) => e.stopPropagation()}> */}
              <span>
                <label>1. </label>
                {t("raise power tips1")}
              </span>
              <span>
                <label>2. </label>
                {t("raise power tips2")}
              </span>
              <span>
                <label>3. </label>
                {t("raise power tips3")}
              </span>
              <span>
                <label>4. </label>
                {t("raise power tips4")}
              </span>
            </StyledSignDetailBox>
          }
        >
          <QuestionIcon onClick={() => setShowTitleTip(true)} />
        </Popover>
      </StyledTitle>
    );
  }, [isMobile, showTitleTip, t]);

  const handlerPowerUp = useCallback(() => {
    if (rangeConfigs.length === 1 && !rangeConfigs[0].notRange) {
      const id = heros[0].id;
      const ownerflag = heros[0].ownerflag;
      const amount = rangeConfigs[0].values[0];
      handleTransactionReceipt(
        tiny.powerUp(id, ownerflag, amount),
        t("raise power"),
        [id, ownerflag, amount].toString()
      )
        .then((tx) => {
          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              refetch();
              onDismiss && onDismiss();
            });
          }
        })
        .catch((err) => {
          console.error("handle add power err:", err);
        });
    } else {
      // 多个英雄的战力提升
      let ids: number[] = [];
      let ownerflags: ownerflagType[] = [];
      let points: number[] = [];

      heros.map((ele, index) => {
        if (
          rangeConfigs[index].values[0] > 0 &&
          !rangeConfigs[index].notRange
        ) {
          ids.push(ele.id);
          ownerflags.push(ele.ownerflag);
          points.push(rangeConfigs[index].values[0]);
        }
      });
      handleTransactionReceipt(
        tiny.powerUpBatch(ids, ownerflags, points),
        t("raise power"),
        [ids, ownerflags, points].toString()
      )
        .then((tx) => {
          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              onDismiss && onDismiss();
            });
          }
        })
        .catch((err) => {
          console.error("handle adds power err:", err);
        });
    }
  }, [
    handleTransactionReceipt,
    handleTxConfirm,
    heros,
    onDismiss,
    rangeConfigs,
    refetch,
    t,
    tiny,
  ]);

  const canSubmit = useMemo(() => {
    const maxFlag =
      rangeConfigs.length > 1
        ? false
        : rangeConfigs.filter((ele) => !ele.loaded).length > 0;

    return (
      !(
        rangeConfigs.filter((ele) => ele.values[0] !== 0 && !ele.notRange)
          .length > 0
      ) ||
      rangeConfigs.filter((ele) => ele.isMax || ele.max === ele.min).length >
        0 ||
      isApproved === ApprovalState.PENDING ||
      !(staminaItemNum.toNumber() > 0 || !lockSpiritBalance?.isZero()) ||
      maxFlag
    );
  }, [isApproved, lockSpiritBalance, rangeConfigs, staminaItemNum]);

  const account = tiny.myAccount;
  const [visible, setVisible] = useState(false);
  const [inBuying, setInBuying] = useState(false);
  const handleBuy = useCallback(
    async (shoppingList: RuneAuction[], onDismiss: () => void) => {
      const ids: number[] = [];
      const prices: BigNumber[] = [];
      shoppingList.forEach((ele) => {
        ids.push(ele.id);
        prices.push(BigNumber.from(ele.referencePrice).mul(1e12));
      });

      const referral = userInfo?.inviter;
      const ts = userInfo?.invitationTime;
      handleTransactionReceipt(
        ids.length === 1
          ? tiny.runeBid(ids[0], prices[0], referral, ts)
          : tiny.runeBidBatch(ids, prices, referral, ts),
        t("Buy NFT"),
        [ids, prices.toString(), referral, ts].toString()
      )
        .then((tx) => {
          setInBuying(true);

          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              clearSpiritList();
              setVisible(false);
              onDismiss();
            });
          }
        })
        .finally(() => {
          setInBuying(false);
        });
    },
    [
      handleTransactionReceipt,
      handleTxConfirm,
      userInfo,
      clearSpiritList,
      tiny,
      t,
    ]
  );

  return (
    <Modal
      title={ModalTitle}
      onDismiss={onDismiss}
      disableScale={disableScale}
      size="es"
      style={isMobile ? { minWidth: "100vw" } : { minWidth: "37rem" }}
    >
      <StyledContainer>
        {rangeConfigs.length > 0 &&
          heros.length > 0 &&
          heros.map((hero, index) => {
            return (
              <RaisePowerRange
                key={index}
                index={index}
                config={rangeConfigs[index]}
                setRangeConfigs={setRangeConfigs}
                hero={hero}
                allPropAmount={allPropAmount}
                staminaItemNum={staminaItemNum}
                allConfig={allConfig}
              ></RaisePowerRange>
            );
          })}
      </StyledContainer>

      <ModalActions
        style={{
          borderTop: "1px solid #E0D4B5",
          margin: 0,
          padding: "0 24px",
          height: "auto",
          paddingTop: "1.6rem",
        }}
      >
        <StyledPropsBox className="mr-10">
          {lockSpiritBalance && !lockSpiritBalance.isZero() && (
            <StyledPropsAmount
              isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
            >
              <StyledPropsAmountLeft>
                <StyledIcon
                  className="mr-4"
                  src={"//images.tinyworlds.io/tokens/LOCKED-SPIRIT.png"}
                />
                <StyledPropsAmountNum
                  isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
                >
                  {showlockSpiritAmount} /
                  {lockSpiritBalance ? lockSpiritBalance.toNumber() : 0}
                </StyledPropsAmountNum>
              </StyledPropsAmountLeft>
            </StyledPropsAmount>
          )}
          <StyledPropsAmount
            isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
          >
            <StyledPropsAmountLeft>
              <StyledIcon
                className="mr-4"
                src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
              />
              <StyledPropsAmountNum
                isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
              >
                {allPropAmount - showlockSpiritAmount} /
                {staminaItemNum.toNumber()}
              </StyledPropsAmountNum>
            </StyledPropsAmountLeft>
            <StyledSvg
              onClick={() => {
                setVisible(true);
                // onDismiss && onDismiss();
              }}
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <RecoverIcon
                style={{
                  width: isMobile ? 24 : 28,
                  // height: 25,
                }}
                isHover={isHover}
              ></RecoverIcon>
            </StyledSvg>
          </StyledPropsAmount>
        </StyledPropsBox>
        {isApproved === ApprovalState.NOT_APPROVED ||
        isApproved === ApprovalState.PENDING ? (
          <PrimaryButton
            // size="sm"
            disabled={isApproved !== ApprovalState.NOT_APPROVED}
            onClick={() => {
              approveNFT();
            }}
          >
            {t("Approve")}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            style={{
              position: "unset",
            }}
            disabled={canSubmit || SPIRIT_CLOSE}
            onClick={handlerPowerUp}
            width={isMobile ? "7rem" : "6rem"}
          >
            {t("Confirm")}
          </PrimaryButton>
        )}
      </ModalActions>
      {rangeConfigs.filter((ele) => ele.isMax).length > 0 && (
        <StyledNotes>
          <SttledError>{t("staminaMaxError")}</SttledError>
        </StyledNotes>
      )}

      {visible && userInfo && account && (
        <ExternalModal
          onClose={() => setVisible(false)}
          style={{ zIndex: 101 }}
        >
          {({ handleClose }) => (
            <StyledSpiritShoppingCartContent
              onDismiss={handleClose}
              handleBuy={handleBuy}
              userInfo={userInfo}
              account={account}
              inBuying={inBuying}
              spiritShoppingList={spiritShoppingList}
              addToSpiritList={addToSpiritList}
              deleteFromSpiritList={deleteFromSpiritList}
              clearSpiritList={clearSpiritList}
            />
          )}
        </ExternalModal>
      )}
    </Modal>
  );
};

export default RaisePowerModal;

const StyledSignDetailBox = styled.div`
  display: flex;
  align-items: self-start;
  flex-direction: column;
  font-size: 1;

  > span {
    display: flex;
    > label {
      margin-right: 4px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // padding: 12px 12px;
  `}
`;

const StyledPropsAmountLeft = styled.div`
  display: flex;
  align-items: center;
`;

const SttledError = styled.div`
  width: 100%;
  text-align: center;
  color: #d10000;
`;

const StyledPropsAmountNum = styled.div<{ isMax: boolean }>`
  white-space: nowrap;
  color: ${({ isMax }) => (isMax ? "#D10000" : "#514B45")};
`;

const StyledSvg = styled.div`
  margin-left: 10px;
  transform: translate(0px, -1px);

  svg {
    display: flex;
    filter: drop-shadow(0px 2px 0px #5e3e27);
  }

  :active {
    transform: translateY(2px);

    svg {
      filter: drop-shadow(0px 0px 0px #5e3e27);
    }
  }
`;

const StyledPropsAmount = styled.div<{ isMax: boolean }>`
  height: 2.6rem;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 1.3rem;
  background: #fffbdd;
  border: 1px solid ${({ isMax }) => (isMax ? "red" : "#2377b7")};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 14rem;
  `}
`;

const StyledIcon = styled.img`
  height: 30px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 20px;
  `}
`;

const StyledPropsBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  min-width: 13rem;
  margin-left: 10px;
`;

const StyledContainer = styled.div`
  width: 100%;
  max-height: 25.06rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  overflow-y: scroll;
  background: none;

  > div + div {
    border-top: 1px solid rgb(224, 212, 181);
  }

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    background: none;
    padding: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fee397;
    border-radius: 5px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    margin: 0 auto;
    min-height: 13rem;
    padding-left: 2rem;
    padding-right: 0;
  `}
`;

const StyledTitle = styled.div`
  position: relative;
  z-index: 9;
`;

const StyledNotes = styled.div`
  display: flex;
  padding: 0 9px;
  margin: 8px 0 -10px;
  font-size: 12px;
  color: #696969;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin: 8px auto;
      padding: 0 12px;
    `}
`;

const StyledSpiritShoppingCartContent = styled(SpiritShoppingCartModal)`
  min-width: 25rem;
  ${(props) => props.theme.mediaWidth.upToSmall`
    width: 27rem;
  `}
`;
