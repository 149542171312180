import React, {
  CSSProperties,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Context } from "src/contexts/TinyProvider";

// componenents
import BaseInfoCard from "./BaseInfoCard";

// images
import idle from "src/images/status/idle.png";
import farming from "src/images/status/Liquidity_farming.png";
import lp_boost from "src/images/status/lp_boost.png";
import gamingPng from "src/images/status/gaming.png";

import { PartInfo, PetAttr, PetDetail } from "./type";
import PetsIcon from "src/components/Svg/PetsIcon";
import { getGenesOpen } from "./const";
import { useBlockCountDown } from "src/hooks/useTimer";
import { useBlockNumber } from "src/state/application/hooks";
import { petsCrack } from "src/services/pets";
import babyImg from "src/images/pet/baby.png";
import PrimaryButton from "src/components/Button/PrimaryButton";
import useCheckAuth from "src/hooks/useCheckAuth";
import useIsMobile from "src/hooks/useIsMobile";
import { cachePetHatch } from "src/App";
import { PetImgBorder, PetImgColor } from "src/components/Svg/PetsIcon/const";

interface Props {
  info: PetDetail;
  onClick?(id: number): void;
  className?: string;
  showStatus?: boolean;
  notCrack?: boolean;
  style?: CSSProperties;
}

function PetCard(props: PropsWithChildren<Props>) {
  const {
    info,
    onClick,
    className,
    showStatus = false,
    children,
    notCrack,
    style,
  } = props;
  const {
    id,
    genes,
    parts,
    ownerflag,
    breedCnt,
    evolveCnt,
    cooldownEndBlock,
    isCracking,
    femaleId,
    maleId,
  } = info;
  const { t } = useTranslation();
  // 只有交易相关的接口返回的 NFT 信息里面有 stamina 字段
  const blockNow = useBlockNumber();
  const isMobile = useIsMobile();
  const petBreedCountDown = useBlockCountDown({
    block: cooldownEndBlock,
    blockNow,
  });

  let statusPath = idle;
  let statusText = t("Idle");
  let textColor: string | undefined = undefined;
  if (ownerflag === 2) {
    statusText = t("LPBattleground");
    textColor = "#54AAD0";
    statusPath = lp_boost;
  } else if (ownerflag === 1) {
    statusText = t("NFT Farming");
    textColor = "#CEAD24";
    statusPath = farming;
  } else if (ownerflag === 3) {
    statusPath = gamingPng;
    statusText = t("Tiny Kingdom");
  } else if (isCracking) {
    statusPath = babyImg;
    statusText = t("Hatching");
  }

  const { tiny, handleSign } = useContext(Context);
  const account = tiny.myAccount;
  const signer = tiny.signer;
  const { checkAuth } = useCheckAuth();

  const [cracking, setCracking] = useState(isCracking);

  const handlerHatch = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (account) {
        if (signer) {
          const signedStr = await handleSign(id, signer, account);
          if (signedStr) {
            setCracking(true);
            petsCrack({
              address: account,
              signature: signedStr,
              id,
            })
              .then((result) => {
                cachePetHatch.push(id);
              })
              .catch(() => {
                setCracking(false);
              });
          }
        }
      } else {
        checkAuth();
      }
    },
    [account, checkAuth, handleSign, id, signer]
  );

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(id);
    }
  }, [onClick, id]);

  const petStatus = useMemo(() => {
    return (
      <StyledPetStatus>
        {parts &&
          Object.keys(parts).map((prop, index) => {
            const key = prop as keyof PetAttr;
            const genesOpen = getGenesOpen({
              evolveCnt,
              genesNum: Object.keys(parts).length,
            });
            const grade = parts[key][0].grade;
            return (
              <StyledPetsIcon key={index}>
                <PetsIcon
                  lock={index >= genesOpen}
                  blackBg
                  grade={grade}
                  prop={key}
                />

                <span
                  style={{
                    borderColor: grade
                      ? PetImgBorder[grade - 1]
                      : PetImgBorder[0],
                    background: grade ? PetImgColor[grade - 1] : PetImgColor[0],
                  }}
                />
              </StyledPetsIcon>
            );
          })}
      </StyledPetStatus>
    );
  }, [evolveCnt, parts]);

  const addonPet = (
    // breedCnt
    <>
      {petStatus}
      {/* <StyledBreedCount>{`${t(
          "Breed Count"
        )} : ${breedCnt}`}</StyledBreedCount> */}
      {showStatus ? (
        <StyledStatusText className="display-flex flex-center mt-10">
          {statusText}
          <StyledStatusIcon
            style={{
              width: 24,
            }}
            className="ml-5"
            src={statusPath}
          />
        </StyledStatusText>
      ) : (
        <Spacer />
      )}
    </>
  );

  return (
    <BaseInfoCard
      style={style}
      info={info}
      className={className}
      onClick={handleClick}
      cracking={isCracking || cracking}
      addon={
        <>
          {children}
          {genes ? (
            addonPet
          ) : (
            <>
              <StyledName className="fw-600 text-outline">
                {t(
                  typeof maleId === "number" && typeof femaleId === "number"
                    ? "Tinymon Egg"
                    : "Genesis Egg"
                )}
              </StyledName>

              {!notCrack && (
                <PrimaryButton
                  className="mt-10"
                  disabled={
                    cracking || isCracking || cachePetHatch.includes(id)
                  }
                  size="sm"
                  style={isMobile ? { width: 60 } : {}}
                  onClick={handlerHatch}
                >
                  {t(
                    isCracking || cracking || cachePetHatch.includes(id)
                      ? "Cracking"
                      : "Crack"
                  )}
                </PrimaryButton>
              )}
            </>
          )}
        </>
      }
    />
  );
}

export default memo(PetCard);

const StyledPetsIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    width: 10px;
    height: 5px;
    border: 1px solid;
    margin-top: 2px;
    box-sizing: border-box;
    border-radius: 2.5px;
  }
`;

const StyledName = styled.div`
  height: 30px;
  color: ${(props) => props.theme.color.yellow};
  font-size: 1.4rem;
  font-weight: 800;
  opacity: 0.9;
  margin-top: -35px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: -25px;
    font-size: 1.8rem;
  `}
`;

const StyledPetStatus = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
  width: 10.6rem;
  margin: 0 auto;

  svg {
    display: block;
    height: 1.6rem;
  }
`;

const StyledBreedCount = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0.2rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    padding-left: 5px;
  `}
`;

const StyledStatusText = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 5px;
  `}
`;

const Spacer = styled.div`
  margin-bottom: 2em;
`;

const StyledStatusIcon = styled.img`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 1rem;
  `}
`;
