import React, { CSSProperties } from "react";
import styled from "styled-components";

interface Props {
  list: string[];
  style?: CSSProperties;
  className?: string;
}

function BaseTips({ list, style, className }: Props) {
  return (
    <StyledTips className={className} style={style}>
      {list.map((ele, index) => {
        return (
          <StyledTip key={index} index={index}>
            <span>{ele}</span>
          </StyledTip>
        );
      })}
    </StyledTips>
  );
}

export default BaseTips;

const StyledTip = styled.li<{ index: number }>`
  display: flex;
  ::before {
    content: "${({ index }) => `${index + 1}`}.";
    padding-right: 1em;
    height: 100%;
  }
`;

const StyledTips = styled.ul`
  //   margin: 0.8rem 0 -0.5rem;
  // margin: 0.8rem 0 -0.
  color: #777;
  font-size: 0.8rem;
  list-style: none;
  padding: 0;
`;
