import {
  CSSProperties,
  Dispatch,
  MouseEventHandler,
  ReactChild,
  SetStateAction,
} from "react";
import Question from "src/images/wenhao.png";
import styled from "styled-components";

interface Props {
  onClick: MouseEventHandler<HTMLSpanElement>;
  isHover?: boolean;
  onShow?: Dispatch<SetStateAction<boolean>> | null;
  children?: ReactChild;
  isMouse?: boolean;
  className?: string;
  size?: "md" | "sm" | "xs";
  style?: CSSProperties;
}
export default function QuestionIcon(props: Props) {
  const {
    onClick,
    children,
    onShow = null,
    isHover = false,
    size = "md",
    ...rest
  } = props;

  return (
    // <StyledSpan onMouseEnter={onClick} onClick={onClick} height={height} {...rest}>
    <StyledSpan
      onClick={(e) => {
        if (!onShow) onClick(e);
      }}
      onMouseLeave={() => {
        if (onShow) onShow(false);
      }}
      {...rest}
    >
      <img
        src={Question}
        onMouseEnter={(e) => {
          if (onShow) onShow(true);
        }}
        alt=""
        height="100%"
      />
      {children}
    </StyledSpan>
  );
}

const StyledSpan = styled.span`
  cursor: pointer;
  height: 1.2rem;

  img {
    display: block;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 13px;
  `}
`;
