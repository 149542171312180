import { FC, useContext, useState } from "react";
import useIsMobile from "src/hooks/useIsMobile";
import { Context as GuideContext } from "src/views/Guide";

import {
  imgBoxShadow,
  StyledDetailAccount,
  StyledDetailHelp,
  StyledDetailItem,
  StyledDetailLogo,
  StyledDetailNote,
  StyledDetailRightImgBox,
  StyledDetailStep,
  StyledDetailTitle,
  StyledGuideDetail,
  StyledGuideLink,
  StyledPrefilledImage,
} from "./GuideDetailStyled";
import { Context } from "src/contexts/TinyProvider";

import marketplace from "src/images/guide/marketplace.png";
import marketplacePre from "src/images/guide/marketplace_pre.png";
import upgrade from "src/images/guide/upgrade.png";
import upgradePre from "src/images/guide/upgrade_pre.png";
import farming from "src/images/guide/farming.png";
import farmingPre from "src/images/guide/farming_pre.png";
import huobi from "src/images/partner/huobi.png";
import playEarn from "src/images/guide/play_earn.png";
import playEarnPre from "src/images/guide/play_earn_pre.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ReferralSystemRight from "src/images/guide/ReferralSystemRight.png";
import ReferralSystemRightPre from "src/images/guide/ReferralSystemRightPre.png";
import tinyDaoRight1 from "src/images/guide/tinyDaoRight1.png";
import tinyDaoRight2 from "src/images/guide/tinyDaoRight2.png";
import tinyDaoRight1Pre from "src/images/guide/tinyDaoRight1Pre.png";
import tinyDaoRight2Pre from "src/images/guide/tinyDaoRight2Pre.png";

interface IGuideStepProps {
  showAnime: boolean;
}

const CreateMetamask = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: isMobile ? 0 : 50 }}>
      <StyledDetailTitle>
        {isMobile ? t("create Wallet whatM") : t("create Wallet what")}
      </StyledDetailTitle>
      <StyledDetailItem style={{ marginBottom: 0 }}>
        {t("create Wallet q1")}
      </StyledDetailItem>
      <StyledDetailItem style={{ marginBottom: 0 }}>
        {t("create Wallet q2")}
      </StyledDetailItem>
    </div>
  );
};

const BuyTinc = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  return (
    <>
      <StyledDetailStep>{t("step")}1:</StyledDetailStep>
      <StyledDetailItem style={{ paddingRight: "18%", flexWrap: "wrap" }}>
        <p style={{ width: "100%" }}>{t("Buy TINC3")}</p>
        <a
          href="https://www.huobi.com/en-us/exchange/tinc_usdt/"
          target={"_blank"}
          style={{ display: "contents" }}
        >
          <StyledDetailLogo
            style={{ width: "33%", margin: 0, marginTop: "10px" }}
            src={huobi}
            alt=""
          />
        </a>
        <StyledDetailNote style={{ marginTop: 12 }}>
          <span style={{ lineHeight: "18px", fontWeight: 600 }}>* </span>
          {t("Buy TINC4")}
        </StyledDetailNote>
      </StyledDetailItem>

      <StyledDetailStep style={{ marginTop: isMobile ? 12 : 60 }}>
        {t("step")}2:
      </StyledDetailStep>
      <StyledDetailItem> {t("Buy TINC5")}</StyledDetailItem>
    </>
  );
};

const ObtainTinyHero = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailStep>{t("step")}1:</StyledDetailStep>
      <StyledDetailItem style={{}}>
        <div>
          {t("Visit Tiny World")}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/marketplace/list"
          >
            {t("NFT marketplace")}
          </StyledGuideLink>
        </div>
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{
            height: 155,
          },
          ...imgBoxShadow,
          minHeight: 155,
        }}
        src={marketplace}
        preSrc={marketplacePre}
        alt=""
      />
      <StyledDetailStep style={{ marginTop: 20 }}>
        {t("step")}2:
      </StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Buy Tiny Hero with BUSD")}
      </StyledDetailItem>
    </>
  );
};

const UpgradeTinyHero = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledDetailStep>{t("step")}1:</StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Select the hero you want to upgrade")}
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{ ...{ width: "90%" }, ...imgBoxShadow, minHeight: 150 }}
        src={upgrade}
        preSrc={upgradePre}
        alt=""
      />
      <StyledDetailStep style={{ marginTop: 20 }}>
        {t("step")}2:
      </StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Select the required upgrade materials to finish upgrade")}
      </StyledDetailItem>
    </>
  );
};

const PlayTinyFarm = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>1.</span>
        <div>
          {t("Players can stake Tiny Hero NFTs to the ")}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/tinyvault/nft-farming"
          >
            {t("NFT Farming Pool")}
          </StyledGuideLink>
          {t("to mine TINC")}
        </div>
      </StyledDetailItem>

      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>2.</span>
        {t("Maximum staking cap. for N,R,SR is 12, no limit for SSR and SSSR")}
      </StyledDetailItem>

      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>3.</span>
        {t("Farming efficiency depends on the total power of the staked NFTs")}
      </StyledDetailItem>

      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>4.</span>
        <div>
          {i18n.language === "zh" ? (
            <>
              {t("There are 2 ways ")}
              <span
                onClick={() => {
                  history.push("/tinyvault/nft-farming/rule?tab=1");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#9D6C08",
                  fontFamily: i18n.language === "zh" ? "unset" : "guideLink",
                  fontWeight: i18n.language === "zh" ? "bold" : "unset",
                  cursor: "pointer",
                }}
              >
                {t("Power Bonus")}
              </span>
              {t("to get ")}
            </>
          ) : (
            <>
              {t("There are 2 ways ")}
              {t("to get ")}
              <span
                onClick={() => {
                  history.push("/tinyvault/nft-farming/rule?tab=1");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#9D6C08",
                  fontFamily: i18n.language === "zh" ? "unset" : "guideLink",
                  fontWeight: i18n.language === "zh" ? "bold" : "unset",
                  cursor: "pointer",
                }}
              >
                {t("Power Bonus")}
              </span>
            </>
          )}
        </div>
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{ width: isMobile ? "90%" : "62%", marginTop: 10, minHeight: 140 },
          ...imgBoxShadow,
        }}
        src={farming}
        preSrc={farmingPre}
        alt=""
      />
    </>
  );
};

const PlayTinyKingdom = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>1.</span>
        <div>
          {t(
            "Obtain materials by Combat & Expedition, selling materials or forged equipments to earn BUSD"
          )}
        </div>
      </StyledDetailItem>

      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>2.</span>
        {t(
          "Earn plenty of TINC rewards from 3 different weeklyleaderboard.(Castle/Expedition/Forging)"
        )}
      </StyledDetailItem>

      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>3.</span>
        {t(
          "Participate in different game events to earn TINC/material rewards"
        )}
      </StyledDetailItem>

      <StyledPrefilledImage
        style={{
          ...{ width: "88%", marginTop: 10 },
          ...imgBoxShadow,
          minHeight: 120,
        }}
        src={playEarn}
        preSrc={playEarnPre}
        alt=""
      />
    </>
  );
};

const TinyDao = () => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailStep>{t("step")}1:</StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Click 'Lock' button to start")}
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{
            width: isMobile ? "100%" : "52%",
            marginTop: isMobile ? 12 : 4,
            minHeight: 50,
          },
          ...imgBoxShadow,
        }}
        src={tinyDaoRight1}
        preSrc={tinyDaoRight1Pre}
        alt=""
      />
      <StyledDetailStep style={{ marginTop: 20 }}>
        {t("step")}2:
      </StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t(
          "Enter Lock Amount and Lock Duration, then click 'Lock' to obtain veTINC"
        )}
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...imgBoxShadow,
          ...{
            width: isMobile ? "100%" : "66%",
            marginTop: isMobile ? 12 : 4,
            minHeight: 120,
          },
        }}
        src={tinyDaoRight2}
        preSrc={tinyDaoRight2Pre}
        alt=""
      />
    </>
  );
};

const ReferralSystem = () => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();
  return (
    <>
      <StyledDetailStep>{t("step")}1:</StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Sign signature to generate your own unique referral link")}
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{
            width: isMobile ? "100%" : "80%",
            marginTop: isMobile ? 12 : 4,
            minHeight: 180,
          },
          ...imgBoxShadow,
        }}
        src={ReferralSystemRight}
        preSrc={ReferralSystemRightPre}
        alt=""
      />
      <StyledDetailStep style={{ marginTop: 20 }}>
        {t("step")}2:
      </StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Share referral link to invite your friends")}
      </StyledDetailItem>
    </>
  );
};

const GuideDetailRight: FC<IGuideStepProps> = ({}) => {
  const isMobile = useIsMobile();
  const { select, setSelect } = useContext(GuideContext);
  const { t } = useTranslation();

  const detailRender = () => {
    switch (select) {
      case 0:
        return <CreateMetamask></CreateMetamask>;
      case 1:
        return <BuyTinc></BuyTinc>;
      case 2:
        return <ObtainTinyHero></ObtainTinyHero>;
      case 3:
        return <UpgradeTinyHero></UpgradeTinyHero>;
      case 4:
        return <PlayTinyFarm></PlayTinyFarm>;
      case 5:
        return <PlayTinyKingdom></PlayTinyKingdom>;
      case 6:
        return <TinyDao></TinyDao>;
      case 7:
        return <ReferralSystem></ReferralSystem>;
        break;
    }
  };

  return <StyledGuideDetail>{detailRender()}</StyledGuideDetail>;
};

export default GuideDetailRight;
