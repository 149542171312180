import React, { CSSProperties } from "react";
import styled from "styled-components";

const CardContent: React.FC<{ className?: string; style?: CSSProperties }> = ({
  children,
  className,
  style,
}) => (
  <StyledCardContent style={style} className={className}>
    {children}
  </StyledCardContent>
);

export const StyledCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[4]}px 1.4rem;
  box-sizing: border-box;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: ${theme.spacing[3]}px 1.2rem;
  `};
`;

export default CardContent;
