import React, {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useMemo,
  PropsWithChildren,
  Children,
} from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import { getStrFromOptions, Options } from "../ResizedImage";
import styled from "styled-components";

const cache: {
  [url: string]: boolean;
} = {};

interface Prop {
  width?: number;
  height?: number;
  src: string[];
  preSrc: string | ReactNode;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
  srcset?: string; // 响应式图片
  imgStyle?: React.CSSProperties;
  loadingStyle?: React.CSSProperties;
  autoHeight?: boolean;
  isBreath?: boolean; //是否为呼吸灯
  imgId?: string;
  isStroage?: boolean; //是否走緩存  false 走緩存 true 不走緩存
  outLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * 
 *           {/* {petImgs && (
            <StyledPetStandImgs
              style={{
                ...style,
                ...boxStyle,
                margin,
              }}
              src={petImgs.map((ele) => ele.img)}
              isBreath
              preSrc={
                <PetsEmptyIcon
                  style={{
                    width: style?.width ? style.width * 0.6 : 128,
                  }}
                />
              }
            >
              <StyledPetsStandBox id={"NFT-PETS-" + id} style={style}>
                {petStand}
              </StyledPetsStandBox>
            </StyledPetStandImgs>
          )}
 * **/

export default function PrefilledImageExtend(props: PropsWithChildren<Prop>) {
  const {
    style,
    className,
    width,
    height,
    alt,
    src,
    preSrc,
    srcset,
    imgStyle,
    autoHeight = false,
    loadingStyle,
    isBreath,
    imgId,
    isStroage = false,
    outLoaded,
    children,
  } = props;

  const imgs = src;
  const imgLoaded = useMemo(
    () => imgs.filter((ele) => !!cache[ele]).length === imgs.length,
    [imgs]
  );
  const [loaded, setLoaded] = useState(isStroage ? !isStroage : imgLoaded);
  const [visible, setVisible] = useState(isStroage ? isStroage : !imgLoaded);

  const handleLoaded = useCallback(() => {
    setLoaded(true);
    imgs.map((ele) => {
      cache[ele] = true;
    });
  }, [imgs]);

  useEffect(() => {
    const imgPromise: Promise<unknown>[] = [];
    imgs.map((i) => {
      imgPromise.push(
        new Promise((res, rej) => {
          const img = new Image();
          img.src = i;
          img.decode().then(() => {
            res(i);
          });
          img.onerror = (err) => {
            rej();
          };
        })
      );
    });

    Promise.all(imgPromise).then((res) => {
      if (res.length !== imgs.length) {
        console.log(`${imgs} ${res} 图片加载失败`);
      } else {
        handleLoaded();
      }
    });
  }, [handleLoaded, imgs]);

  useEffect(() => {
    try {
      let timer: number | null = null;
      if (loaded) {
        timer = window.setTimeout(() => {
          setVisible(false);
        }, 500);
      }
      return () => {
        if (timer) {
          window.clearTimeout(timer);
        }
      };
    } catch (error) {
      console.log({
        error,
      });
    }
  }, [loaded]);

  useEffect(() => {
    if (outLoaded) {
      outLoaded(loaded);
    }
  }, [loaded, outLoaded]);

  return (
    <div className={clsx(styles.box, className)} style={style}>
      {visible && !outLoaded ? (
        <StyledPreBox
          className={clsx(
            styles.size,
            styles.pre_box,
            isBreath && styles.preBreath
          )}
          style={{ opacity: loaded ? 0 : 0.8 }}
        >
          {typeof preSrc === "string" ? (
            <img
              className={styles.size}
              style={loadingStyle}
              src={preSrc}
              width={width}
              height={height}
              alt=""
            />
          ) : (
            preSrc
          )}
        </StyledPreBox>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}

const StyledPreBox = styled.span`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;
