import { FC, useContext, useEffect } from "react";
import styled from "styled-components";
import useIsMobile from "src/hooks/useIsMobile";
import { Context as GuideContext } from "src/views/Guide";

import "./GuideStep.css";

import guideStep from "src/images/guide/guide_step.png";
import guideStepM from "src/images/guide/guide_step_m.png";
import selected from "src/images/guide/guide_selected.png";
import { PageContext } from "src/contexts/Page";
import lineA from "src/images/guide/lineA.png";
import lineB from "src/images/guide/lineB.png";
import { headerRef } from "src/views/PageComponents/Topbar/components/MigrationNotice";
import useGuideDetail, { GuideDetail } from "../hooks/useGuideDetail";

import PrefilledImage from "src/components/Images/PrefilledImage";
import { useTranslation } from "react-i18next";

interface IStepButtonProps {}

interface IGuideStepProps {
  showFixed: boolean;
}

export const StepButton: FC<IStepButtonProps> = ({}) => {
  const { select, setSelect } = useContext(GuideContext);
  const { GuideList } = useGuideDetail();

  const changeSelect = (num: number) => {
    select >= 0 &&
      select < GuideList.length &&
      setSelect(
        num < 0
          ? select === 0
            ? 0
            : select + num
          : select === GuideList.length - 1
          ? GuideList.length - 1
          : select + num
      );
  };
  return (
    <StyledStepBtnBox>
      <StyledStepBtn
        className="slideEnter"
        onClick={() => changeSelect(-1)}
        style={{
          left: -50,
          transform: "rotateY(-180deg) translate(0px, -50%)",
        }}
      />
      <StyledStepBtn
        className="slideEnter"
        onClick={() => changeSelect(1)}
        style={{ right: -50 }}
      />
    </StyledStepBtnBox>
  );
};

const GuideStep: FC<IGuideStepProps> = ({ showFixed }) => {
  const isMobile = useIsMobile();
  const { select, setSelect } = useContext(GuideContext);
  const { showNotice } = useContext(PageContext);
  const { GuideList } = useGuideDetail();
  const { t } = useTranslation();

  return (
    //
    <StyledGuideStep
      style={
        isMobile
          ? {
              top: showFixed
                ? 72 +
                  (showNotice
                    ? Number(headerRef.current?.clientHeight) + 12
                    : 8)
                : 0,
              opacity: showFixed ? "1" : 0,
            }
          : {}
      }
    >
      <StyledGuideStepImgBox>
        {!isMobile && (
          <img
            style={{ width: "83.6%", position: "absolute", top: -10 }}
            src={lineA}
            alt=""
          />
        )}
        <>
          {GuideList.map(({ setpSpan, setpTitle, setpTitleM }, index) => {
            if (!isMobile && index > 5) {
              return <></>;
            }
            return index === 6 ? (
              <div>
                <StyledGuideStepLineM></StyledGuideStepLineM>
                <StyledGuideStepBtnBox GuideList={GuideList} key={index}>
                  <StyledGuideStepImgM
                    style={{ marginTop: "1.3rem" }}
                    select={index}
                    onClick={() => {
                      if (select !== index) setSelect(index);
                    }}
                  >
                    {select === index && (
                      <StyledGuideSelected src={selected}></StyledGuideSelected>
                    )}
                  </StyledGuideStepImgM>
                  {!isMobile && (
                    <StyledGuideStepText>
                      {setpSpan && (
                        <StyledGuideStepTextSpan>
                          {setpSpan}
                        </StyledGuideStepTextSpan>
                      )}
                      {isMobile && setpTitleM ? setpTitleM : setpTitle}
                    </StyledGuideStepText>
                  )}
                </StyledGuideStepBtnBox>
              </div>
            ) : (
              <StyledGuideStepBtnBox GuideList={GuideList} key={index}>
                <StyledGuideStepImgM
                  select={index}
                  onClick={() => {
                    if (select !== index) setSelect(index);
                  }}
                >
                  {select === index && (
                    <StyledGuideSelected src={selected}></StyledGuideSelected>
                  )}
                </StyledGuideStepImgM>
                {!isMobile && (
                  <StyledGuideStepText>
                    {setpSpan && (
                      <StyledGuideStepTextSpan>
                        {setpSpan}
                      </StyledGuideStepTextSpan>
                    )}
                    {isMobile && setpTitleM ? setpTitleM : setpTitle}
                  </StyledGuideStepText>
                )}
              </StyledGuideStepBtnBox>
            );
          })}
        </>
      </StyledGuideStepImgBox>
      {!isMobile && GuideList.length > 6 && (
        <StyledGuideStepNote>
          <span>{t("Optional")}</span>
          <img
            style={{ width: "50%", position: "absolute", top: "0.8rem" }}
            src={lineB}
            alt=""
          />
          {GuideList.slice(6, 9).map(({ setpSpan, setpTitle }, index) => {
            const reIndex = 6 + index;
            return (
              <StyledGuideStepBtnBox
                GuideList={GuideList}
                style={{ width: "30%" }}
                key={reIndex}
              >
                <StyledGuideStepImgM
                  select={reIndex}
                  onClick={() => {
                    if (select !== reIndex) setSelect(reIndex);
                  }}
                >
                  {select === reIndex && (
                    <StyledGuideSelected src={selected}></StyledGuideSelected>
                  )}
                </StyledGuideStepImgM>
                {!isMobile && (
                  <StyledGuideStepText>
                    {setpSpan && (
                      <StyledGuideStepTextSpan>
                        {setpSpan}
                      </StyledGuideStepTextSpan>
                    )}
                    {setpTitle}
                  </StyledGuideStepText>
                )}
              </StyledGuideStepBtnBox>
            );
          })}
        </StyledGuideStepNote>
      )}
    </StyledGuideStep>
  );
};

export default GuideStep;

const StyledGuideStepLineM = styled.div`
  width: 160%;
  height: 1px;
  background: #707070;
  margin-top: 1.3rem;
  transform: scale(0.7) translate(-26%, 0);
`;

export const StyledGuideStepNote = styled.div`
  // width: 40%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 27%;

  > span {
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    top: -0.9rem;
    color: rgb(126 64 32 / 50%);
  }
`;

const StyledGuideStep = styled.div`
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  gap: 3rem;
  zoom: 0.9;
  top: -13px;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: absolute;
    float: left;
    left: 0;
    top: 0;
    width: auto;
    min-height: 93.7vh;
    height: 100%;
    // height: 110%;
    background:rgba(68,68,68,.9);
    z-index: 9;
    overflow-y: auto;
    gap: 0;
    transition: opacity 100ms; 
    zoom: 1;
    box-sizing: border-box;
  `}
`;

export const StyledGuideStepImgBox = styled.div`
  width: 80%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-around;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: auto;
    display: block;
    padding-bottom: 9rem;
  `}
`;

export const StyledGuideStepBtnBox = styled.div<{ GuideList: GuideDetail[] }>`
  width: ${({ GuideList }) => (GuideList.length > 6 ? "16%" : "14%")};
  display: flex;
  justify-content: center;
  align-content: start;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`;

export const StyledGuideStepImgM = styled.div<{
  select: number;
  location?: number;
}>`
  background-image: url(${guideStepM});
  width: 2.8rem;
  height: 3.6rem;
  background-size: 100%;
  background-position-y: ${({ select, location }) =>
    select * (location ? location : -4.9)}rem;
  margin-top: 1.6rem;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const StyledGuideStepImg = styled.img`
  width: 100%;
  display: block;
`;

export const StyledGuideSelected = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translate(-50%, 0) scale(1.2);
  left: 50%;

  &:hover {
    // transform: translate(-50%, 0) scale(1);
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // width: 114%;
    transform: translate(-50%, 0) scale(1.15);
  `}
`;

const StyledStepBtnBox = styled.div`
  width: 100%;
`;

const StyledStepBtn = styled.div`
  width: 43px;
  height: 64px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const StyledGuideStepTextBox = styled.div`
  width: 86%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
`;

export const StyledGuideStepText = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 18px;
  color: #9d6c08;
  margin-top: 4px;
  font-size: 17px;
  font-weight: bold;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 13px;
    font-weight: bold;
`}
`;

export const StyledGuideStepTextSpan = styled.span`
  width: 100%;
`;
