import React, { useState } from "react";
import styled from "styled-components";
import PrefilledImage from "../../Images/PrefilledImage";
import PresaleBg from "./PresaleBg";

import babyIcon from "src/images/pet/baby-icon.png";
import powerPng from "src/images/power.svg";

import { ReactComponent as SandGlass } from "src/images/countdown/sand.svg";
import { PetDetail } from "./type";
import { petsCardElementArr } from "./const";
import PetStand from "../PetStand";
import { useBlockNumber } from "src/state/application/hooks";

interface Props {
  info: PetDetail;
  onClick?(): void;
  className?: string;
  addon?: React.ReactNode;
  cracking?: boolean;
  style?: React.CSSProperties;
}

function BaseInfoCard(props: Props) {
  const { info, onClick, className, addon, cracking, style } = props;
  const blockNumber = useBlockNumber();

  const {
    id,
    genes,
    element,
    point,
    breedCnt,
    parts,
    maleId,
    femaleId,
    cooldownEndBlock,
    isCracking,
  } = info;

  const cooldown = blockNumber ? cooldownEndBlock > blockNumber : false;

  return (
    <StyledItemCard
      className={className}
      style={{
        ...{ cursor: onClick ? "pointer" : undefined },
        ...style,
      }}
      onClick={onClick}
    >
      <PresaleBg />
      <StyledNFTInfoContainer>
        <StyledInfos className="display-flex flex-between ff-black mt-5 text-outline-07">
          <StyledLeft
            style={{
              marginBottom:
                femaleId !== undefined && maleId !== undefined && !genes
                  ? 16
                  : 0,
            }}
          >
            {typeof element === "number" && (
              <img className="mr-5" src={petsCardElementArr[element]} alt="" />
            )}
            #{id}
          </StyledLeft>
          <StyledCenter>
            {point && <StyledPowerImage src={powerPng} alt="" />}
            <span>{point}</span>
          </StyledCenter>
        </StyledInfos>

        <StyledStatus className="display-flex flex-between">
          {femaleId === undefined && maleId === undefined ? (
            <StyledStatusCard
              style={{
                minWidth: "3.3rem",
              }}
            >
              ORIGIN
            </StyledStatusCard>
          ) : (
            <div></div>
          )}
          {genes && (
            <>
              <StyledCooldown>
                {cooldown && (
                  <SandGlass style={{ marginRight: 4 }} fill="#6a6a6a" />
                )}
                <StyledStatusCard bgChange={cooldown}>
                  <img src={babyIcon} alt="" /> {breedCnt}/7
                </StyledStatusCard>
              </StyledCooldown>
            </>
          )}
        </StyledStatus>

        <PetStand isCracking={isCracking || cracking} info={info} />
        {addon}
      </StyledNFTInfoContainer>
    </StyledItemCard>
  );
}

export default React.memo(BaseInfoCard);

const StyledCooldown = styled.div`
  width: 100%;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    height: 70%;
  }
`;

const StyledItemCard = styled.div`
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  width: 230px;
  height: 309px;
  text-align: center;
  overflow: hidden;
  border-radius: 13px;
  z-index: 1;
`;

const StyledNFTInfoContainer = styled.div`
  position: relative;
  padding: 14px 16px;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
`;

const StyledInfos = styled.div`
  color: white;
  font-size: 1.1em;
  font-weight: normal;
`;

const StyledStatus = styled.div`
  color: white;
  font-size: 1em;
  font-weight: normal;
`;

const StyledStatusCard = styled.div<{ bgChange?: boolean }>`
  background: ${({ bgChange }) => (bgChange ? "#6a6a6a" : "#9050b7")};
  height: 16px;
  line-height: 1rem;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 12px;
  width: auto;
  min-width: 3.2rem;
  box-sizing: border-box;
  justify-content: center;

  img {
    height: 70%;
    margin-right: 2px;
    margin-top: 1px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 13px;
  `}
`;

const StyledCenter = styled.span`
  white-space: nowrap;
  font-size: 1.5em;
  font-weight: 500;
  color: ${(props) => props.theme.color.grey[100]};
`;

const StyledLeft = styled.span`
  font-size: 1rem;
  color: #514b45;
  font-weight: unset;
  white-space: nowrap;
  text-shadow: none;

  display: flex;
  align-items: center;
  font-family: Hiragino;
  height: 30px;

  img {
    height: 20px;
  }
`;

const StyledNFTImg = styled(PrefilledImage)<{ genes: string | undefined }>`
  border-radius: 5px;
  object-fit: contain;
  width: ${({ genes }) => (genes ? 128 : 128)}px;
  height: ${({ genes }) => (genes ? 140 : 172)}px;
  justify-content: center;
  align-items: center;
  padding-right: ${({ genes }) => (genes ? 0.8 : 0)}rem;
  margin: 0 auto 0.8rem;
  span {
    position: absolute;
    left: 0;
  }

  ${({ genes }) =>
    genes
      ? `svg {
    display: block;
    margin: 0 auto;
  };`
      : `svg {
    position: absolute;
    left: 50%;
    /* right: 50%; */
    width: 128px !important;
    top: 50%;
    transform: translate(-50%, -55%);
    margin: 0 auto;
  }`}

  img {
    display: block;
    width: 128px;
    height: auto;
  }
`;

const StyledPowerImage = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
  width: 19px;
`;
