import { FC } from "react";
import { BaseSvgProps } from "../types";

interface Props extends BaseSvgProps {}

const index: FC<Props> = ({ className, style, color }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#9563a7"}
      viewBox="0 0 12.52 12.8"
    >
      <path d="M12.19,6.24a1.2,1.2,0,0,0-.87-.38,1.17,1.17,0,0,0-.79.31,1.35,1.35,0,0,0-.28,0,2.76,2.76,0,0,0,.65-1.74,2.5,2.5,0,0,0,.22-1.49,1.15,1.15,0,0,0-1.14-1,1.12,1.12,0,0,0-1,.63l0,0a2.53,2.53,0,0,0-1.42.59,2.56,2.56,0,0,0-.47-1.49s0,0,0,0A1.11,1.11,0,0,0,7,.52,1.13,1.13,0,0,0,6,0a1.16,1.16,0,0,0-.65.2,2.65,2.65,0,0,0-1,1.14A2.78,2.78,0,0,0,3.46,3a2.18,2.18,0,0,0-.22-.21A1.24,1.24,0,0,0,3,2a1.23,1.23,0,0,0-.94-.45,1.16,1.16,0,0,0-.8.32A2.43,2.43,0,0,0,.47,3.1,2.59,2.59,0,0,0,.26,5.74a2.48,2.48,0,0,0,.79,1,2.89,2.89,0,0,0-.39.63A3,3,0,0,0,.49,9.48,2.44,2.44,0,0,0,1.79,11a2.06,2.06,0,0,0,1,.28l.41,0,.19,0a.49.49,0,0,1,.23.06c.16.07.2.13.32.31A2.09,2.09,0,0,0,5,12.57a2.56,2.56,0,0,0,1.06.23,2.89,2.89,0,0,0,2.58-1.72,3.1,3.1,0,0,0,.23-.71,2.5,2.5,0,0,0,.61.09,2.72,2.72,0,0,0,.63-.08A2.59,2.59,0,0,0,12,8.52a2.36,2.36,0,0,0,.48-1.41A1.15,1.15,0,0,0,12.19,6.24Zm-.44,2.15a2.28,2.28,0,0,1-1.69,1.7,2.59,2.59,0,0,1-.55.07A1.93,1.93,0,0,1,8.64,10a2.71,2.71,0,0,1-.24,1A2.61,2.61,0,0,1,6.09,12.5a2.14,2.14,0,0,1-.93-.21,1.78,1.78,0,0,1-.93-.81,1,1,0,0,0-.44-.42A.75.75,0,0,0,3.43,11l-.24,0-.36,0a1.87,1.87,0,0,1-.9-.24,2.49,2.49,0,0,1-1-3.34,2.82,2.82,0,0,1,.62-.83,2,2,0,0,1-1-1A2.26,2.26,0,0,1,.75,3.23a2.08,2.08,0,0,1,.71-1.18.87.87,0,0,1,.59-.24.91.91,0,0,1,.7.34.86.86,0,0,1,.17.74,2.09,2.09,0,0,1,.78.88l0,.08a2,2,0,0,1,0-.55,2.51,2.51,0,0,1,.9-1.78A2.29,2.29,0,0,1,5.53.45.83.83,0,0,1,6,.3a.84.84,0,0,1,.71.38.83.83,0,0,1,.07.78,1.09,1.09,0,0,0-.07.24,2.31,2.31,0,0,1,.51,1.66,3.18,3.18,0,0,1-.18.78,2.69,2.69,0,0,1,.46-.62,2.27,2.27,0,0,1,1.55-.69h0a.74.74,0,0,0,.13-.21.85.85,0,0,1,1.6.31,2.29,2.29,0,0,1-.23,1.39,2.48,2.48,0,0,1-.77,1.84,2.72,2.72,0,0,1-.38.31A2.5,2.5,0,0,1,10,6.4a2.25,2.25,0,0,1,.64.1.89.89,0,0,1,.69-.34.92.92,0,0,1,.65.28.88.88,0,0,1,.24.65A2.11,2.11,0,0,1,11.75,8.39Z" />
      <path d="M7.74,8.67h0A3,3,0,0,1,7.1,7h0a1.51,1.51,0,0,0-.84-1.24,1.53,1.53,0,0,0-1.64.27,3.18,3.18,0,0,1-1.51.44h0A2,2,0,0,0,1.47,7.7a1.89,1.89,0,0,0,.75,2.55c.73.4,1-.1,1.83.26s.6.94,1.37,1.24A1.88,1.88,0,0,0,7.86,10.7,2.05,2.05,0,0,0,7.74,8.67Z" />
      <path d="M11.1,6.88a1.45,1.45,0,0,1-.37.34A1.43,1.43,0,0,0,9.6,7.05,1.57,1.57,0,0,0,8.34,8.64a1.19,1.19,0,0,0,1.58.86,1.62,1.62,0,0,0,1.27-1.37,1.52,1.52,0,0,0,.43-1.06A.29.29,0,0,0,11.1,6.88Z" />
      <path d="M4.31,3.35c-.07.85.4,1.54,1,1.53A1.56,1.56,0,0,0,6.63,3.31,1.56,1.56,0,0,0,6.13,2a1.45,1.45,0,0,1,.11-.73A.24.24,0,0,0,5.87,1a1.56,1.56,0,0,0-.75,1A1.8,1.8,0,0,0,4.31,3.35Z" />
      <path d="M2.75,6a1.58,1.58,0,0,0,.41-2,1.44,1.44,0,0,0-.85-.77,1.78,1.78,0,0,1,0-.5.28.28,0,0,0-.47-.27,1.47,1.47,0,0,0-.55,1,1.64,1.64,0,0,0-.25,1.85A1.2,1.2,0,0,0,2.75,6Z" />
      <path d="M9.41,5.74c-.61.6-1.43.69-1.84.19a1.54,1.54,0,0,1,.37-2,1.54,1.54,0,0,1,1.36-.5,1.36,1.36,0,0,0,.47-.56.25.25,0,0,1,.46.1A1.58,1.58,0,0,1,10,4.18,1.81,1.81,0,0,1,9.41,5.74Z" />
    </svg>
  );
};

export default index;
