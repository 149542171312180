import { useCallback, useMemo } from "react";
import { ethers } from "ethers";
import useTiny from "src/hooks/useTiny";
import { getMulticallContract } from "../tiny/utils";
import type { JsonFragment } from "@ethersproject/abi";
import type { BigNumber } from "ethers";

export interface CallItem {
  address: string;
  name: string;
  params: any[];
}

export interface CallResults {
  blockNumber: BigNumber;
  returnData: string[];
}

export default function useMulticall(
  abi: (string | JsonFragment)[],
  calls: CallItem[],
  options?: {
    decode?: boolean;
  }
) {
  const tiny = useTiny();
  const multiCallContract = getMulticallContract(tiny);
  const itf = useMemo(() => new ethers.utils.Interface(abi), [abi]);

  return useCallback(async () => {
    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);
    const { returnData, blockNumber }: CallResults =
      await multiCallContract.aggregate(calldata);

    // console.log("returnData", returnData);
    return { returnData, blockNumber };
  }, [calls, itf, multiCallContract]);
}
