import useIsMobile from "./useIsMobile";

interface HeroGridProps {
  boxRef: React.RefObject<HTMLDivElement>;
  itemWidth: number;
  itemHeight: number;
  row: number; //上下间距
  columnBreak: number; //根据item的数量自动排列
}

export default function useLordHeroGridProps({
  boxRef,
  itemWidth,
  itemHeight,
  columnBreak,
  row,
}: HeroGridProps) {
  const break1 = useIsMobile(800);

  let itemBreak = columnBreak;

  if (break1) {
    itemBreak = 1;
  }

  let width = boxRef.current ? boxRef.current.clientWidth - 12 : 0;
  let height = boxRef.current ? boxRef.current.clientHeight - 60.8 : 0;

  let columnWidth = itemWidth;

  let columnCount = Math.floor(width / columnWidth) - itemBreak;

  const breakColumn = (itemWidth * itemBreak) / columnCount;

  columnWidth = columnWidth + breakColumn;

  const left = breakColumn / 2;

  const rowHeight = itemHeight + row;

  return {
    width,
    height,
    columnCount,
    rowHeight,
    columnWidth,
    left,
  };
}
