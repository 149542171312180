import { useQuery } from "react-query";
import useTiny from "src/hooks/useTiny";
import type { BigNumber } from "ethers";

export interface AirdropResult {
  bnbAmount: BigNumber;
  spiritValue: number;
}

export function useNewAirdrops() {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const airdropNew = tiny.contracts.airdropNew;
  // console.log('airdropNew', airdropNew);

  return useQuery<AirdropResult | undefined>(
    ["AIRDROP_NEW", account],
    async () => {
      if (!account) return;
      // console.log("getAirdrops account:", account);
      // console.log('airdropContract', airdropContract);
      return airdropNew.callStatic
        .getAirdrops(account)
        .then((res: { bnbAmount: BigNumber; spiritValue: BigNumber }) => {
          // console.log("getAirdrops res", res);
          return {
            bnbAmount: res.bnbAmount,
            spiritValue: res.spiritValue.toNumber(),
          };
        });
    },
    {
      enabled: typeof account === "string",
      refetchInterval: 30_000,
    }
  );
}
