import React, { CSSProperties, useMemo } from "react";
import { PartInfo, PetAttr, PetsElement } from "../PetCard/type";
import { brindle, ear, eye, foot, ornament, tail } from "./const";
import styled from "styled-components";
import PrefilledImageExtend from "src/components/Images/PrefilledImageExtend";
import PetsEmptyIcon from "src/components/Svg/PetsIcon/PetsEmptyIcon";

interface Props {
  element: PetsElement;
  parts: Record<keyof PetAttr, PartInfo[]>;
  id: number;
  style?: CSSProperties & {
    width?: number;
    height?: number;
  };
  margin?: CSSProperties["margin"];
  boxStyle?: CSSProperties;
}

function MockPetsStand({ element, parts, style, margin, boxStyle, id }: Props) {
  const standElement = (element || 0) + 1;
  const eyeKey = parts?.part_1[0].key;
  const tailKey = parts?.part_2[0].key;
  const ornamentKey = parts?.part_3[0].key;
  const earKey = parts?.part_4[0].key;
  const footKey = parts?.part_5[0].key;
  const brindleKey = parts?.part_6[0].key;

  const petImgs = useMemo(() => {
    return typeof element === "number" &&
      typeof eyeKey === "number" &&
      typeof tailKey === "number" &&
      typeof ornamentKey === "number" &&
      typeof earKey === "number" &&
      typeof footKey === "number" &&
      typeof brindleKey === "number"
      ? [
          {
            img: `/images/petStand/9/${eye[eyeKey]}_0.png`,
            zIndex: 9,
          },
          {
            img: `/images/petStand/1/${tail[tailKey]}_${standElement}.png`,
            zIndex: 1,
          },
          {
            img: `/images/petStand/7/${ornament[ornamentKey]}_0.png`,
            zIndex: 7,
          },
          {
            img: `/images/petStand/5/${ear[earKey]}_${standElement}_1.png`,
            zIndex: 5,
          },
          {
            img: `/images/petStand/10/${ear[earKey]}_${standElement}_2.png`,
            zIndex: 10,
          },
          {
            img: `/images/petStand/4/${foot[footKey]}_0.png`,
            zIndex: 4,
          },
          {
            img: `/images/petStand/3/${brindle[brindleKey]}_0_1.png`,
            zIndex: 3,
          },
          {
            img: `/images/petStand/8/${brindle[brindleKey]}_0_2.png`,
            zIndex: 8,
          },
          {
            img: `/images/petStand/2/body_${standElement}.png`,
            zIndex: 2,
          },
          {
            img: `/images/petStand/6/head_${standElement}.png`,
            zIndex: 6,
          },
        ]
      : undefined;
  }, [
    brindleKey,
    earKey,
    element,
    eyeKey,
    footKey,
    ornamentKey,
    standElement,
    tailKey,
  ]);

  const petStand = useMemo(() => {
    return (
      <>
        {petImgs?.map(({ img, zIndex }, index) => {
          return (
            <StyledPetsStandImg key={index} zIndex={zIndex} src={img} alt="" />
          );
        })}
      </>
    );
  }, [petImgs]);

  return (
    <>
      {petImgs && (
        <StyledPetStandImgs
          style={{
            ...style,
            ...boxStyle,
            margin,
          }}
          src={petImgs.map((ele) => ele.img)}
          isBreath
          preSrc={
            <PetsEmptyIcon
              style={{
                width: style?.width ? style.width * 0.6 : 100,
              }}
            />
          }
        >
          <StyledPetsStandBox id={"NFT-PETS-" + id} style={style}>
            {petStand}
          </StyledPetsStandBox>
        </StyledPetStandImgs>
      )}
    </>
  );
}

export default MockPetsStand;

const StyledPetsStandBox = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
  transform: translateY(-30px);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 140px;
    width: 140px;
    transform: translateY(10px);
  `}
`;

const StyledPetStandImgs = styled(PrefilledImageExtend)`
  height: 150px;
  width: 200px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 140px;
    width: 140px;
  `}
`;

const StyledPetsStandImg = styled.img<{ zIndex: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
  left: 0;
  top: 0;
`;
