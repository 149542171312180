import React, { useEffect, useCallback, useState, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";

import Modal, { ModalProps } from "../Modal";
// import Spacer from "../Spacer";

import WalletCard from "./components/WalletCard";
// import useIsMobile from "src/hooks/useIsMobile";
import switchChain from "src/utils/switchChain";
// import { chainId } from "src/config";
import { ConnectorNames, connectorsByName } from "src/wallet";
import { moreCard, WalletCardList } from "./const";
import useIsMobile from "src/hooks/useIsMobile";

interface IWalletProviderModalProps extends ModalProps {
  cb?: () => void;
}

const WalletProviderModal: React.FC<IWalletProviderModalProps> = ({
  onDismiss,
  cb,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { account, activate, error, setError } = useWeb3React();

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (account && onDismiss) {
      onDismiss();
    }
  }, [account, onDismiss]);

  const handleConnect = useCallback(
    async (connectId: ConnectorNames) => {
      // console.log("connectId", connectId);
      activate(connectorsByName[connectId], (error) => {
        if (error) {
          // 没有 trustwallet 的变量
          if (
            error.message.includes(
              "No Ethereum provider was found on window.trustwallet"
            )
          ) {
            return activate(
              connectorsByName[ConnectorNames.Injected],
              (error) => {
                if (error) {
                  if (
                    error.message.includes(
                      "No Ethereum provider was found on window.ethereum"
                    )
                  ) {
                    return activate(
                      connectorsByName[ConnectorNames.WalletConnect],
                      (error) => {
                        if (error) {
                          setError(error);
                          localStorage.removeItem("connectorId");
                        } else {
                          localStorage.setItem(
                            "connectorId",
                            ConnectorNames.WalletConnect
                          );
                        }
                      }
                    );
                  }
                  setError(error);
                  localStorage.removeItem("connectorId");
                } else {
                  localStorage.setItem("connectorId", ConnectorNames.Injected);
                }
              }
            );
          }

          setError(error);
          localStorage.removeItem("connectorId");
          if (
            (ConnectorNames.Injected === connectId ||
              ConnectorNames.okxwallet === connectId ||
              // (ConnectorNames.TrustWallet === connectId && window.ethereum?.isTrust) ||
              ConnectorNames.WalletLink === connectId) &&
            error.message.includes("Unsupported chain id")
          ) {
            switchChain(connectId);
          }
        } else {
          localStorage.setItem("connectorId", connectId);
        }
      });
    },
    [activate, setError]
  );

  const renderList = useMemo(() => {
    return showMore ? WalletCardList : WalletCardList.slice(0, 5);
  }, [showMore]);

  return (
    <Modal
      size="es"
      style={isMobile ? { maxWidth: "90vw" } : { maxWidth: 500 }}
      onDismiss={onDismiss}
      titleSize={"sm"}
      title={t("Connect Wallet")}
    >
      <StyledWalletsWrapper>
        {renderList.map(({ src, alt, connector }, index) => {
          return (
            <WalletCard
              key={index}
              icon={typeof src === "string" ? <img src={src} alt={alt} /> : src}
              onConnect={() => handleConnect(connector)}
              title={alt}
            />
          );
        })}
        {!showMore && (
          <WalletCard
            icon={
              typeof moreCard.src === "string" ? (
                <img src={moreCard.src} alt={moreCard.alt} />
              ) : (
                moreCard.src
              )
            }
            onConnect={() => {
              setShowMore(true);
            }}
            title={t(moreCard.alt)}
          />
        )}
      </StyledWalletsWrapper>
      {error && (
        <div className="text-center">
          {error.message.includes("Unsupported chain id") && (
            <div>{t("NetworkError")}</div>
          )}
          {/* {error && chainId === 97 ? error.message : undefined} */}
          {error.message &&
          error.message.includes(
            "No Ethereum provider was found on window."
          ) ? (
            <span style={{ color: "#fe4b40" }}>{t("unlock")}</span>
          ) : (
            error.message
          )}
        </div>
      )}
    </Modal>
  );
};

const StyledWalletsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.56rem 0.6rem;
  height: 12.55rem;
  margin-bottom: 10px;
  overflow-y: scroll;

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    background-color: transparent;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      width: 6px;
    `}
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f8e08f;
    border-radius: 10px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`    
    margin: 0 auto 10px;
    height:15.21rem;
  `}
`;

export default WalletProviderModal;
