// 体力恢复1点所需要的数量
import { gradeType } from "src/config";

export type highGradeType = 4 | 5 | 6;

const initPower = {
  "4": 90,
  "5": 400,
  "6": 1200,
};
/* 
"90-140", "400-800", "1200-1400"
*/
// 计算方式是初始战力与最大战力差值的 0.8倍 + 初始战力 = 最大能提升到的战力
export const maxPower = {
  4: 130,
  5: 720,
  6: 1360,
};

const times = {
  "4": 10,
  "5": 50,
  "6": 5,
};

const dValue = {
  "4": 1,
  "5": 1,
  "6": 5,
};

const adds = {
  "4": 1,
  "5": 1,
  "6": 9,
};

const quotient = (a: number, b: number) => {
  return parseInt((a / b).toString());
};

const mod = (a: number, b: number) => {
  return parseInt((a % b).toString());
};

export const getSpiritExpend = (grade: gradeType, power: number) => {
  let timesNum: number;
  let initPowerNum: number;
  let addsNum: number;
  let dValueNum: number;
  switch (grade) {
    case 4:
      timesNum = times[4];
      initPowerNum = initPower[4];
      addsNum = adds[4];
      dValueNum = dValue[4];
      break;
    case 5:
      timesNum = times[5];
      initPowerNum = initPower[5];
      addsNum = adds[5];
      dValueNum = dValue[5];
      break;
    case 6:
      timesNum = times[6];
      initPowerNum = initPower[6];
      addsNum = adds[6];
      dValueNum = dValue[6];
      break;
    default:
      return;
  }
  //  50 * quotient(power - 400,50) * (1 + quotient(power - 400,50)) / 2  + mod(power - 400) * quotient(power-400,50) + 1
  // 50 * QUOTIENT((Z-400),50) *（1+QUOTIENT((Z-400),50)）/2+MOD((Z-400),50)*（QUOTIENT((Z-400),50)+1）
  return (
    ((timesNum *
      quotient(power - initPowerNum, timesNum) *
      (addsNum + quotient(power - initPowerNum, timesNum))) /
      2 +
      mod(power - initPowerNum, timesNum) *
        (quotient(power - initPowerNum, timesNum) + dValueNum)) *
    100
  );
};

export const getSpiritDeffer = ({
  grade,
  initPower,
  laferPower,
}: {
  grade: gradeType;
  initPower: number;
  laferPower: number;
}) => {
  return (
    (getSpiritExpend(grade, laferPower) || 0) -
    (getSpiritExpend(grade, initPower) || 0)
  );
};

// QUOTIENT 求商
// parseInt (7/3)
// MOD 取余数
// parseInt(4%3)

//  ssr  Sn=10*QUOTIENT((Z-90),10)*（1+QUOTIENT((Z-90),10)）/2+MOD((Z-90),10)*（QUOTIENT((Z-90),10)+1）
// const initPower =
// const num = 10 * parseInt(power-)

//  sssr  Sn=50*QUOTIENT((Z-400),50)*（1+QUOTIENT((Z-400),50)）/2+MOD((Z-400),50)*（QUOTIENT((Z-400),50)+1）

//  ur  Sn=5*QUOTIENT((Z-1200),5)*（9+QUOTIENT((Z-1200),5)）/2+MOD((Z-1200),5)*（QUOTIENT((Z-1200),5)+5）
