import { CSSProperties } from "react";
import styled from "styled-components";

interface Props {
  size?: number;
  color?: CSSProperties["color"];
  background?: CSSProperties["background"];
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  style?: CSSProperties;
}
export default function Close({
  size,
  onClick,
  color,
  background,
  style,
}: Props) {
  return (
    <Container
      size={size ?? 20}
      color={color}
      background={background}
      onClick={onClick}
      style={style}
    />
  );
}

interface StyledProps {
  size: number;
  color?: CSSProperties["color"];
  background?: CSSProperties["background"];
}

const Container = styled.div<StyledProps>`
  position: absolute;
  top: 5px;
  right: 5px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${({ background }) =>
    background ? background : "#a26511"};
  border-radius: ${(props) => props.size / 2}px;
  cursor: pointer;
  ::before,
  ::after {
    content: " ";
    position: absolute;
    width: ${(props) => props.size * 0.5}px;
    height: 0;
    border: 1.5px solid ${({ color }) => (color ? color : "#fccd48")};
    border-radius: 1.5px;
    top: 50%;
    left: 50%;
    background-color: ${({ color }) => (color ? color : "#fccd48")};
  }
  ::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  ::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
