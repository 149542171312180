import { useCallback } from "react";
// hooks
import useCacheByKey from "src/hooks/useCacheByKey";

import type { HeroDesc } from "src/services/typings";

function useDescByCode(code: number): HeroDesc | undefined;
function useDescByCode(): (code: number) => HeroDesc | undefined;

// 获取英雄属性
function useDescByCode(code?: number) {
  const descs = useCacheByKey<HeroDesc[]>("HERO_DECS_LIST");
  // console.log('descs', descs);

  const findDetail = useCallback(
    (code: number) => {
      if (!descs) return undefined;
      return descs.find((ele) => ele.code === code);
    },
    [descs]
  );

  if (typeof code === "number") {
    return findDetail(code);
  }
  return findDetail;
}

export default useDescByCode;
