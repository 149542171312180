import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useContext, useReducer } from "react";

import { Context as TinyContext } from "src/contexts/TinyProvider";
import { PageContext } from "src/contexts/Page";

import audit from "src/images/audit.png";

import home from "src/images/drawer/home.png";
import rankings from "src/images/drawer/rankings.png";
import dao from "src/images/drawer/dao.png";
import docs from "src/images/drawer/docs.png";
import tool from "src/images/drawer/tool.png";
import { ReactComponent as InviteIcon } from "src/images/sidebar/menu_invite.svg";
import { ReactComponent as FaceBook } from "src/images/bottom/fb.svg";
import { ReactComponent as Instagram } from "src/images/bottom/ins.svg";
import { ReactComponent as AirdropIcon } from "src/images/airdrop.svg";
import language from "src/images/drawer/lang.png";
// import stats from "src/images/drawer/govern.png";
import twPng from "src/images/drawer/icon_tw.png";
import tgPng from "src/images/drawer/icon_tg.png";
import dcPng from "src/images/drawer/icon_dc.png";
import mdPng from "src/images/drawer/icon_md.png";
// import youtubePng from "src/images/drawer/icon_youtube.png";
// import newImg from "src/images/drawer/new.png";
import VestingICON from "src/images/Vesting_ICON.svg";

// import AirdropModal from "src/views/PageComponents/Topbar/AirdropModal";
import useModal from "src/hooks/useModal";
// import { saveNF } from "src/utils/newFeaturePrompt";
import VestingModal from "src/views/PageComponents/Topbar/components/VestingModal";
import useIsInvestor from "src/hooks/useIsInvestor";
import IconBlive from "../IconBlive";
import useFarmPrice from "src/hooks/useFarmPrice";
import BourseModal from "src/views/PageComponents/SideBar/components/BourseModal";
import GasReturn from "../Svg/GasReturn";
import TinyToolModal from "../TinyToolModal";
import ExternalModal from "../ExternalModal";
import RaisePowerModal from "../RaisePower/RaisePowerModal";
import ReplenishModal from "../ReplenishStamina/ReplenishModal";
import CheckHeroModal from "src/views/MyNFT/NFTList/Components/CheckHeroModal";
import { IHeroHead } from "../HeadHeroCard/type";
import useMyRuneBalance from "src/hooks/useMyRuneBalance";
import addToShopSvg from "src/images/shopping/add.svg";
import addSpirit from "src/images/shopping/add-spirit.svg";

const configs: [string, string][] = [
  ["zh", "繁體中文"],
  ["en", "English"],
  ["ru", "русский язык"],
];
const configMap = new Map(configs);

function getLang(lang: string) {
  for (const iterator of configMap.entries()) {
    const [key, value] = iterator;
    if (lang.includes(key)) {
      return value;
    }
  }
  return "English";
}

const saveRemind = "tinydao";

export default function Drawer() {
  const [showDrawer, setShowDrawer] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [expand, setExpand] = useState(false);

  const { tiny } = useContext(TinyContext);
  const account = tiny.myAccount;

  const {
    newAirdropData,
    showGasRefundModal,
    showSpiritAirdropModal,
    spiritPrice, // 体力恢复道具价格
  } = useContext(PageContext);

  const [price] = useFarmPrice();
  const [showBourseModal] = useModal(<BourseModal />, []);

  const history = useHistory();
  const handleMoreClick = () => {
    setShowDrawer(true);
  };

  const handleLangChange = (symbol: string) => {
    i18n.changeLanguage(symbol);
    setExpand(false);
  };

  const handleDaoClick = () => {
    setShowDrawer(false);
    // saveNF(saveRemind);
  };
  const [showVestingModal] = useModal(<VestingModal />, []);

  const isInvestor = useIsInvestor(account);

  /* const bnbGasAmount = newAirdropData?.bnbAmount;
  const spiritValue = newAirdropData?.spiritValue; */

  const [showToolModal, onToolModalDismiss] = useModal(<TinyToolModal />, []);

  const [showCheckHeroModal, setShowCheckHeroModal] = useState(false);
  const [showReplenishModal, setShowReplenishModal] = useState(false);
  const [showRaisePowerModal, setShowRaisePowerModal] = useState(false);
  const [checkHero, setCheckHero] = useState<IHeroHead[]>([]);
  const [staminaItemNum, startRefresh] = useMyRuneBalance(3);

  return (
    <StyledContainer>
      {showCheckHeroModal && (
        <ExternalModal
          boxStyle={{
            width: "100vw",
          }}
          onClose={() => setShowCheckHeroModal(false)}
        >
          {({ handleClose }) => (
            <CheckHeroModal
              showReplenishModal={() => {
                setShowReplenishModal(true);
              }}
              showRaisePowerModal={() => {
                setShowRaisePowerModal(true);
              }}
              checkHero={checkHero}
              setCheckHero={setCheckHero}
              onDismiss={() => {
                setCheckHero([]);
                handleClose();
              }}
            />
          )}
        </ExternalModal>
      )}

      {showReplenishModal && (
        <ExternalModal
          disableScale
          boxStyle={{
            width: "100vw",
          }}
          onClose={() => setShowReplenishModal(false)}
        >
          {({ handleClose }) => (
            <ReplenishModal
              onDismiss={handleClose}
              setCheckHero={setCheckHero}
              disableScale
              onHandlerDismiss={() => {
                // setShowCheckHeroModal(false);
                handleClose();
              }}
              heros={checkHero ? [...checkHero] : []}
            />
          )}
        </ExternalModal>
      )}

      {showRaisePowerModal && (
        <ExternalModal
          disableScale
          boxStyle={{
            width: "100vw",
          }}
          onClose={() => setShowRaisePowerModal(false)}
        >
          {({ handleClose }) => (
            <RaisePowerModal
              onDismiss={handleClose}
              disableScale
              heros={checkHero ? [...checkHero] : []}
            />
          )}
        </ExternalModal>
      )}

      {!showDrawer && (
        <StyledMore onClick={handleMoreClick}>
          <StyledMoreContent>
            <StyledDot />
            <StyledDot />
            <StyledDot />
          </StyledMoreContent>
        </StyledMore>
      )}
      <StyledMask show={showDrawer} onClick={() => setShowDrawer(false)} />

      <StyledAsideContainer show={showDrawer}>
        {showDrawer && (
          <>
            <StyledCurrencyInfo>
              <StyledCurrencyItem onClick={showBourseModal}>
                <span>Buy</span>
                <StyledCurrencyIcon
                  loading="eager"
                  src={"//images.tinyworlds.io/tokens/TINC.png"}
                />
                <span>${price.TINC.toFixed(4)}</span>
              </StyledCurrencyItem>
              <StyledCurrencyItem
                onClick={() => {
                  history.push("/marketplace/tinyswapv1");
                }}
              >
                <span>Buy</span>
                <StyledCurrencyIcon
                  src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
                />
                <span>${spiritPrice}</span>
                <StyledShoppingButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (account) {
                      setCheckHero([]);
                      setShowCheckHeroModal(true);
                      setShowDrawer(false);
                    } else {
                      history.push("/marketplace/tinyswapv1");
                    }
                  }}
                >
                  <StyledAddSvg
                    style={{
                      marginTop: staminaItemNum.toNumber() === 0 ? 0 : 3,
                    }}
                    src={
                      // staminaItemNum.toNumber() === 0 ? addToShopSvg : addSpirit
                      addSpirit
                    }
                    alt="add"
                  />
                </StyledShoppingButton>
              </StyledCurrencyItem>
            </StyledCurrencyInfo>
            <StyledMenuContainer>
              <StyledMenuItem onClick={() => setShowDrawer(false)}>
                <NavLink to="/home" className="menu">
                  <StyledIconContainer>
                    <img src={home} height={25} alt="home" />
                  </StyledIconContainer>
                  {t("Home")}
                </NavLink>
              </StyledMenuItem>
              <StyledMenuItem
                className="position-relative"
                onClick={() => handleDaoClick()}
              >
                <NavLink to="/dao" className="menu">
                  <StyledIconContainer>
                    <img height={25} src={dao} alt="tiny dao" />
                  </StyledIconContainer>
                  {t("dao")}
                </NavLink>
              </StyledMenuItem>
              <StyledMenuItem
                className={"position-relative"}
                onClick={() => setShowDrawer(false)}
              >
                <NavLink to="/invitation" className="menu">
                  <StyledIconContainer>
                    <InviteIcon
                      style={{ marginLeft: 3 }}
                      fill="#fff"
                      height={19}
                    />
                  </StyledIconContainer>
                  {t("invitation")}
                </NavLink>
              </StyledMenuItem>
              <StyledMenuItem
                className={"position-relative"}
                onClick={() => {
                  localStorage.getItem("ToolWarning") === "true"
                    ? window.open("https://www.tinyworlds.tools")
                    : showToolModal();
                }}
              >
                <div className="menu">
                  <StyledIconContainer>
                    <img
                      height={20}
                      style={{ marginLeft: 3 }}
                      src={tool}
                      alt="tiny tool"
                    />
                  </StyledIconContainer>
                  {t("Tiny Tool")}
                </div>
              </StyledMenuItem>

              {isInvestor && (
                <StyledMenuItem>
                  <div className="menu" onClick={showVestingModal}>
                    <StyledIconContainer>
                      <img
                        style={{ marginLeft: 5 }}
                        src={VestingICON}
                        height={18}
                        alt="vesting"
                      />
                    </StyledIconContainer>
                    {t("Vesting")}
                  </div>
                </StyledMenuItem>
              )}
            </StyledMenuContainer>
            <a
              className="text-center mt-20 mb-20"
              target="_blank"
              href="https://www.certik.com/projects/tinyworld"
              rel="noreferrer"
            >
              <StyledAuditImg src={audit} alt="auditby" />
            </a>
            <div style={{ zIndex: 2 }}>
              <StyledContent>
                {/* <StyledDrop onClick={showAirdropModal}>
                        <img height="16" src={airdrop} alt="" />
                        <span className="ml-5">{t("getAirdrop")}</span>
                    </StyledDrop> */}

                <StyledLang className="position-relative">
                  <StyledLangContainer onClick={() => setExpand(!expand)}>
                    <StyledIconContainer>
                      <img src={language} height={20} alt="language" />
                    </StyledIconContainer>
                    {lang.toUpperCase()}
                    <StyledWTriangle
                      style={expand ? { transform: "rotate(180deg)" } : {}}
                    />
                  </StyledLangContainer>
                  {expand && (
                    <StyledSubMenu>
                      {configs.map((ele) => {
                        const [symbol, text] = ele;
                        if (symbol !== lang) {
                          return (
                            <StyledSubMenuItem
                              key={symbol}
                              onClick={() => handleLangChange(symbol)}
                            >
                              {text}
                            </StyledSubMenuItem>
                          );
                        }
                      })}
                    </StyledSubMenu>
                  )}
                </StyledLang>

                <StyledMediaContainer>
                  <a
                    target="_blank"
                    href="https://twitter.com/tinyworldgamefi"
                    rel="noreferrer"
                  >
                    <StyledImg src={twPng} alt="Twitter" />
                  </a>
                  <a
                    target="_blank"
                    href={
                      lang === "zh"
                        ? "https://t.me/tinyworld_cn"
                        : "https://t.me/tinyworld_en"
                    }
                    rel="noreferrer"
                  >
                    <StyledImg src={tgPng} alt="Telegram" />
                  </a>
                  <a
                    href={
                      lang === "zh"
                        ? "https://docs.tinyworlds.io/v/cn/"
                        : "https://docs.tinyworlds.io/"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StyledImg src={docs} alt="Docs" />
                  </a>
                  <a
                    target="_blank"
                    href="https://discord.gg/jXTRTz7B7N"
                    rel="noreferrer"
                  >
                    <StyledImg src={dcPng} alt="Discord" />
                  </a>
                  <a
                    target="_blank"
                    href="https://medium.com/@tinyworld.gamefi"
                    rel="noreferrer"
                  >
                    <StyledImg src={mdPng} alt="Medium" />
                  </a>
                  <a
                    target="_blank"
                    href={`https://www.binance.com/${
                      lang === "zh" ? "zh-CN" : lang
                    }/live/u/25851821`}
                    rel="noreferrer"
                  >
                    <IconBlive
                      fill="#fff"
                      style={{
                        width: 36,
                        height: 36,
                      }}
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100084851317037"
                    rel="noreferrer"
                  >
                    <FaceBook fill="#fff" height={33} />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/tinyworld.gamefi/"
                    rel="noreferrer"
                  >
                    <Instagram fill="#fff" height={33} />
                  </a>
                </StyledMediaContainer>
              </StyledContent>
            </div>
          </>
        )}
      </StyledAsideContainer>
    </StyledContainer>
  );
}

const StyledAddSvg = styled.img`
  height: 12px;
`;

const StyledShoppingButton = styled.button`
  width: 18px;
  height: 17px;
  border-radius: 50%;
  box-shadow: 0 1px 0 #bc7a4a;
  background-color: #fdce4f;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  :hover {
    background-color: #ffe08e;
  }
  :disabled {
    filter: grayscale(1);
  }
`;

const StyledContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  height: calc(100% - 4rem);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: flex;
      z-index: 101;
  `};
`;

const StyledAuditImg = styled.img`
  height: 27px;
  max-width: 120px;
`;

const StyledMask = styled.div<{ show: boolean }>`
  position: absolute;
  width: ${({ show }) => (show ? "100vw" : 0)};
  height: 100%;
  background-color: rgb(0, 0, 0);
  transition: opacity 0.4s ease 0s;
  opacity: ${({ show }) => (show ? 0.6 : 0)};
`;

const StyledAsideContainer = styled.div<{ show: boolean }>`
  position: absolute;
  right: -100vw;
  width: ${({ show }) => (show ? "41vw" : 0)};
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: linear-gradient(to bottom, #503e15f3 0%, #242424f3 100%);
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
`;

const StyledMenuContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  // gap: 10px;
  // flex-grow: 1;
  padding-left: 1rem;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
`;

const StyledMenuItem = styled.button`
  font-weight: 600;
  font-size: 1.2rem;

  .menu {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 25px;
    color: #fff;
    text-decoration: none;
  }
`;

const StyledIconContainer = styled.div`
  width: 25px;
  display: flex;
  align-items: center;

  > svg .cls-1 {
    fill: #fff;
  }
`;

const StyledImg = styled.img`
  display: block;
  height: 33px;
`;

const StyledMore = styled.button`
  z-index: 100; /* z-index 应该大于下边框position-relative */
  position: fixed;
  right: 0;
  bottom: 25%;
  height: 6rem;
  width: 2rem;
  display: none;
  align-items: center;
  justify-content: center;
  background: #275287;
  border-radius: 20px 0 0 20px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 21%);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        display: flex;
    `};
`;

const StyledMoreContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  position: relative;
`;

const StyledDot = styled.div`
  background: white;
  height: 5px;
  width: 5px;
  border-radius: 5px;
`;

const StyledMediaContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 45px 45px 45px;
  row-gap: 10px;
  width: 100%;
  padding: 1rem 0 3rem;
  justify-content: center;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  ::after {
    content: "";
    border-top: 1px solid #fff;
    position: absolute;
    right: 0;
    width: 90%;
    height: 100%;
    z-index: -1;
  }
`;

export const StyledTriangle = styled.div`
  transform: rotate(90deg);
  transition: transform 0.24s;
  ::after {
    content: "►";
  }
`;

const StyledWTriangle = styled.div`
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 0px 4px 4px 4px;
  height: 0px;
  width: 0px;
`;

// 第一个6和上面的menu button对齐，第二个6：下面的图标(45 - 33) /2
const StyledLang = styled.div`
  padding-left: calc(1rem + 6px + 6px);
  margin-bottom: 10px;
`;

const StyledLangContainer = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 25px;
  color: #fff;
`;

const StyledSubMenu = styled.div`
  z-index: 10;
  width: 7.5rem;
  position: absolute;
  left: 50px;
  top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: #4d3907;

  div:not(:last-child) {
    border-bottom: 1px solid #382a05;
  }
`;

const StyledSubMenuItem = styled.div`
  height: 2.1rem;
  line-height: 2.1rem;
  width: 100%;
  padding-left: 5px;
  background-color: #4d3907;
`;

const StyledContent = styled.div`
  width: 100%;
`;

const StyledCurrencyInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 7px;
  // padding-left: calc(1rem + 3px);
  margin: 1.5rem auto 1.5rem;
`;

const StyledCurrencyItem = styled.div`
  background-color: #2d230d;
  height: 27px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding-left: 8px;
  padding-right: 6px;
  width: auto;
  color: white;
`;

const StyledCurrencyIcon = styled.img`
  height: 21px;
  margin-right: 5px;
  margin-left: 5px;
`;
