import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Modal, { ModalProps } from "src/components/Modal";
import ParallelogramButton from "src/components/Button/ParallelogramButton";
import TCheckBox from "src/views/Game/Dashboard/components/GameLogin/TCheckBox";

interface Props extends ModalProps {
  title?: string;
  onConfirm?: () => void;
}
export default function TinyToolModal(props: Props) {
  const { onDismiss, title, onConfirm } = props;
  const [openWarning, setOpenWarning] = useState(false);
  const { t } = useTranslation();

  // 不再提示开关   false 为默认开启提示
  useEffect(() => {
    localStorage.setItem("ToolWarning", openWarning.toString());
  }, [openWarning]);

  return (
    <Modal size="sm" onDismiss={onDismiss} style={{ width: "32.5rem" }}>
      <StyledContent>{t("tinyToolTip")}</StyledContent>

      <div className="text-center">
        <ParallelogramButton
          onClick={() => {
            onDismiss && onDismiss();
            window.open("https://www.tinyworlds.tools");
          }}
        >
          {t("Open Helper")}
        </ParallelogramButton>
      </div>

      <StyledContentFooter>
        <TCheckBox checked={openWarning} setChecked={setOpenWarning} />
        <span>{t("noWarning")}</span>
      </StyledContentFooter>
    </Modal>
  );
}

const StyledContentFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.4rem;
  font-size: 14px;

  span {
    padding-top: 3px;
  }

  div {
    width: 1rem;
    height: 1rem;

    input {
      width: 1rem !important;
      height: 1rem !important;
    }
  }
`;

const StyledContent = styled.p`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 1rem;
  `}
`;
