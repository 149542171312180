import React from "react";
import { BaseSvgProps } from "../types";

interface Props extends BaseSvgProps {}

function PetLockIcon({ className, style, color }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      style={style}
    >
      <g transform="translate(-1127 -647)">
        <g
          transform="translate(1127 647)"
          fill="#514b45"
          stroke="#343434"
          strokeWidth="1"
        >
          <circle cx="9" cy="9" r="9" stroke="none" />
          <circle cx="9" cy="9" r="8.5" fill="none" />
        </g>
        <path
          d="M960.746,192.507a.947.947,0,0,0-.872-.578h-.022v-1.554a3.032,3.032,0,1,0-6.063,0v1.554h-.022a.946.946,0,0,0-.946.947v3.875a.937.937,0,0,0,.075.368.947.947,0,0,0,.872.578h6.108a.947.947,0,0,0,.872-.578.936.936,0,0,0,.075-.368v-3.875A.939.939,0,0,0,960.746,192.507Zm-3.149,3.275a.777.777,0,0,1-1.554,0v-1.938a.777.777,0,0,1,1.554,0Zm1.085-3.853h-3.724v-1.554a1.862,1.862,0,1,1,3.724,0Z"
          transform="translate(179.18 463.224)"
          fill="#d9d9d9"
        />
      </g>
    </svg>
  );
}

export default PetLockIcon;
