import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Range, getTrackBackground } from "../Range";
import { HeadHeroCard } from "src/components/HeadHeroCard";
import styled from "styled-components";
import { IHeroHead } from "../HeadHeroCard/type";
import useIsMobile from "src/hooks/useIsMobile";
import { propsArr, ReplenishContext } from "./const";
import lightning from "src/images/durability/lightning-gold.png";
import PrimaryButton from "../Button/PrimaryButton";
import { calcStamina, trimZero } from "src/utils";
import { useStamina } from "src/hooks/useStaminas";
import { useBlockNumber } from "src/state/application/hooks";
import { BigNumber } from "ethers";
import { gradeDurability } from "src/config";
import { IRangeConfig } from "./ReplenishModal";
import useUserWallet from "src/hooks/useUserWallet";

interface IHeroStaminaProps {
  index: number;
  hero: IHeroHead;
  allPropAmount: number;
  staminaItemNum: BigNumber;
}

const init = (currentStamina: number, hero: IHeroHead) => {
  return {
    step: 1,
    max:
      gradeDurability[hero.gradeType] -
      Math.floor(Number(trimZero(currentStamina.toFixed(1)))),
    loaded: true,
  };
};

function HeroStaminaRange({
  index,
  hero,
  allPropAmount,
  staminaItemNum,
}: IHeroStaminaProps) {
  const isMobile = useIsMobile();
  const { data: staminaInfo } = useStamina(hero.id, true);
  const blockNumber = useBlockNumber();
  const [rangeDisabled, setRangeDisabled] = useState(false);
  const { rangeConfigs, setRangeConfigs } = useContext(ReplenishContext);
  const { lockSpiritBalance } = useUserWallet();

  const currentStamina = calcStamina(
    hero.ownerflag,
    staminaInfo,
    blockNumber,
    hero.gradeType
  );

  const [config, setConfig] = useState<IRangeConfig | undefined>(() => {
    let config = rangeConfigs.find((_, i) => i === index);
    return config;
  });

  useEffect(() => {
    if (typeof currentStamina === "number") {
      setRangeConfigs((prevState) => {
        const newStateValue = [...prevState];
        const config = {
          ...prevState[index],
          ...init(currentStamina, hero),
        };
        newStateValue[index] = config;
        setRangeDisabled(false);
        // console.log({ config });
        setConfig(config);
        return [...newStateValue];
      });
    }
  }, [currentStamina, hero, index, setRangeConfigs]);

  const setRangeValue = useCallback(
    (values: number[]) => {
      if (config && lockSpiritBalance) {
        setRangeConfigs((prevState) => {
          const newStateValue = [...prevState];
          const next = {
            ...config,
            values: values,
            propAmount: values[0],
            isMax: !(
              allPropAmount <=
              staminaItemNum.toNumber() + lockSpiritBalance.toNumber()
            ),
          };
          newStateValue[index] = next;
          setConfig(next);
          return [...newStateValue];
        });
      }
    },
    [
      allPropAmount,
      config,
      index,
      lockSpiritBalance,
      setRangeConfigs,
      staminaItemNum,
    ]
  );

  const RenderRange = useMemo(() => {
    return config && typeof currentStamina === "number" ? (
      <Range
        values={config.values}
        step={config.step}
        min={config.min}
        max={config.max}
        disabled={rangeDisabled}
        onChange={(values) => {
          setRangeConfigs((prevState) => {
            const newStateValue = [...prevState];
            const next = {
              ...config,
              values: [values[0]],
              propAmount: parseInt(values[0].toString()),
            };
            newStateValue[index] = next;
            setConfig(next);
            return [...newStateValue];
          });
        }}
        onFinalChange={(values) => {
          // console.log({
          //   key: "change",
          //   values,
          // });
          setRangeValue(values);
        }}
        renderTrack={({ props, children }) => (
          <StyledRenderTrack
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <StyledRenderTrackChild
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: config.values,
                  // isMax={}
                  colors: [config.isMax ? "#CE3D3D" : "#FDDA7A", "#DBB87A"],

                  // ["#DBB87A", "#FDDA7A", "#DBB87A"]
                  min: config.min,
                  max: config.max,
                }),
              }}
            >
              {children}
            </StyledRenderTrackChild>
          </StyledRenderTrack>
        )}
        renderThumb={({ props, isDragged }) => {
          const staminaMax =
            parseInt(trimZero(currentStamina.toFixed(1))) ===
            gradeDurability[hero.gradeType];

          return (
            props.style.cursor !== "inherit" && (
              <StyledRenderButtonBox
                {...props}
                isMax={config.isMax}
                style={
                  staminaMax
                    ? {
                        ...props.style,
                        left: 0,
                        top: 9,
                      }
                    : {
                        ...props.style,
                      }
                }
              >
                <StyledRenderTip isMax={config.isMax}>
                  <img
                    style={{
                      height: 18,
                    }}
                    className="mr-4"
                    src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
                    alt=""
                  />
                  {config.propAmount * propsArr[hero.gradeType - 1]}
                </StyledRenderTip>
              </StyledRenderButtonBox>
            )
          );
        }}
      />
    ) : (
      <></>
    );
  }, [
    config,
    currentStamina,
    hero.gradeType,
    index,
    rangeDisabled,
    setRangeConfigs,
    setRangeValue,
  ]);

  const handlerMax = useCallback(() => {
    const spiritNum =
      staminaItemNum.toNumber() +
      (lockSpiritBalance ? lockSpiritBalance?.toNumber() : 0);

    if (
      config &&
      (spiritNum -
        allPropAmount +
        config.propAmount * propsArr[hero.gradeType - 1]) /
        config.step >
        0
    ) {
      const propAmount =
        spiritNum -
        allPropAmount +
        config.propAmount * propsArr[hero.gradeType - 1];
      const stamina =
        parseInt((propAmount / propsArr[hero.gradeType - 1]).toString()) *
        config.step;
      const maxStep = config.max;

      // console.log({
      //   propAmount,
      //   stamina,
      //   maxStep,
      // });

      if (maxStep < stamina) {
        setRangeConfigs((prevState) => {
          const newStateValue = [...prevState];
          const next = {
            ...config,
            values: [maxStep],
            propAmount: maxStep,
            isMax: false,
          };
          // console.log({
          //   key: "Max",
          //   next,
          // });
          newStateValue[index] = next;
          setConfig(next);
          return [...newStateValue];
        });
      } else {
        setRangeConfigs((prevState) => {
          const newStateValue = [...prevState];
          const next = {
            ...config,
            values: [stamina],
            propAmount: stamina,
            isMax: false,
          };
          // console.log({
          //   key: "notMax",
          //   next,
          // });
          newStateValue[index] = next;
          setConfig(next);
          return [...newStateValue];
        });
      }
    }
  }, [
    allPropAmount,
    config,
    hero.gradeType,
    index,
    lockSpiritBalance,
    setRangeConfigs,
    staminaItemNum,
  ]);

  return (
    <StyledContainer>
      <HeadHeroCard
        height={isMobile ? 80 : undefined}
        {...hero}
        power={undefined}
      />
      <StyledRangeBox>
        <StyledStaminaRange>
          <StyledStaminaRangeBox
            style={{
              width: "60%",
            }}
          >
            <StyledStamina
              style={{
                fontSize: "1.2rem",
              }}
            >
              <StyledIcon className="mr-5" src={lightning} />
              {/* {trimZero(currentStamina.toFixed(1))} / {gradeDurability[grade]} */}
              {config && (
                <>
                  {currentStamina
                    ? Number(trimZero(currentStamina.toFixed(1))).toFixed(2)
                    : 0}{" "}
                  / {gradeDurability[hero.gradeType]}
                  <span>( + {config.values[0] / config.step})</span>
                </>
              )}
            </StyledStamina>
            {RenderRange}
          </StyledStaminaRangeBox>
          <StyledStaminaRangeBox className="ml-10">
            <StyledStamina
              style={{
                justifyContent: isMobile ? "flex-end" : "center",
                fontSize: "1.2rem",
              }}
            >
              <StyledIcon
                style={{ height: 20 }}
                className="mr-2 "
                src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
              />
              {propsArr[hero.gradeType - 1]} ={" "}
              <StyledIcon
                style={{ height: 20 }}
                className="mr-2 ml-3"
                src={lightning}
              />
              1
            </StyledStamina>
            <PrimaryButton
              onClick={handlerMax}
              style={{
                position: "unset",
              }}
              className="ml-10"
              width={isMobile ? "7rem" : "6rem"}
            >
              MAX
            </PrimaryButton>
          </StyledStaminaRangeBox>
        </StyledStaminaRange>
      </StyledRangeBox>
    </StyledContainer>
  );
}

export default HeroStaminaRange;

const StyledStaminaRangeBox = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 4px;
`;

const StyledStaminaRange = styled.div`
  display: flex;
`;

const StyledStamina = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    margin-left: 1em;
    color: #2d8b3f;
    white-space: nowrap;
  }
`;

const StyledIcon = styled.img`
  height: 30px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 20px;
  `}
`;

const StyledRangeBox = styled.div`
  width: 80%;
  margin-left: 20px;
`;

const StyledRenderTip = styled.div<{ isMax: boolean }>`
  position: absolute;
  top: 20px;
  color: ${({ isMax }) => (isMax ? "#FFFFFF" : "#815920")};
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ isMax }) => (isMax ? "#D10000" : "#FDD770")};
  display: flex;
  align-items: center;
  :before {
    position: absolute;
    visibility: visible;
    content: "";
    background: inherit;
    width: 10px;
    height: 10px;
    top: 0;
    left: 50%;
    transform: rotate(45deg) translate(-55%, 19%);
    z-index: -1;
  }
`;

const StyledRenderButtonBox = styled.div<{ isMax: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 10px !important;
  background-color: ${({ isMax }) => (isMax ? "#D10000" : "#fdce4f")};
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid ${({ isMax }) => (isMax ? "#740000" : "#bc7a4a")};
  :focus-visible {
    outline: unset;
  }
`;

const StyledRenderTrackChild = styled.div`
  height: 7px;
  width: 100%;
  border-radius: 7px;
  align-self: center;
`;

const StyledRenderTrack = styled.div`
  display: flex;
  height: 36px;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  align-content: center;
  min-height: 7rem;
  padding: 1rem 0 0.5rem 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-height: 8.5rem;
    padding-left: 1.3rem;
  `}
`;
