import React, { createContext, useEffect, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { ethers, BigNumber, Signer } from "ethers";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useWeb3React } from "@web3-react/core";
import Tiny from "src/tiny";
import useTokenBalance from "src/hooks/useTokenBalance";
import useLimitedInterval from "src/hooks/useLimitedInterval";
import useURLRef from "src/hooks/useURLRef";

import config from "src/config";
import { bn0, noFunc } from "src/utils";
import { getAT, saveAT } from "src/utils/storage";

import {
  ReferralUserInfo,
  getMyReferralUserInfo,
  bindReferral,
} from "src/services/referral";
import { getLoginMessage } from "src/services/util";
import NoticeModal from "src/components/NoticeModal";
import { loginWalletStr } from "src/services/game";
import useBNBBalance from "src/wallet/useBNBBalance";

export interface TinyContext {
  tiny: Tiny;
  tincBalance: BigNumber;
  startFreshTINCBalance: () => void;
  bnbBalance: BigNumber;
  refreshBNBBalance: () => void;
  busdBalance: BigNumber;
  freshBUSDBalance: () => void;
  logout: () => void;
  userInfo: ReferralUserInfo | undefined;

  getUserInfo: (
    account: string,
    signOpen: boolean,
    force?: boolean
  ) => Promise<void>;
  handleSign: (
    id: number,
    signer: Signer,
    account: string
  ) => Promise<string | undefined>;
  handleGameSign: (
    signer: Signer,
    account: string,
    signOpen: boolean
  ) => Promise<string | undefined>;
  signedStr: string | null;
  signOpen: boolean | undefined;
}

export const tiny = new Tiny(config);

export const Context = createContext<TinyContext>({
  tiny,
  tincBalance: bn0,
  startFreshTINCBalance: noFunc,
  bnbBalance: bn0,
  refreshBNBBalance: noFunc,
  busdBalance: bn0,
  freshBUSDBalance: noFunc,
  logout: noFunc,
  userInfo: undefined,
  getUserInfo: () => Promise.resolve(),
  handleSign: () => Promise.resolve(undefined),
  handleGameSign: () => Promise.resolve(undefined),
  signedStr: null,
  signOpen: undefined,
});

let first = true;

let popSignAddressInfo: {
  address: string | null;
  ts: number;
} = {
  address: null,
  ts: 0,
};

export const TinyProvider: React.FC = ({ children }) => {
  const { library, chainId, account, deactivate } = useWeb3React();
  // console.log('useWeb3React library:', library);
  const { getRefCode, deleteRefCode } = useURLRef();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleDismiss = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (account && chainId === config.chainId) {
      // tiny.unlockWallet(library, "0x28c695Abc5f28b72b90110C704C36290BdACB7fa");
      tiny.unlockWallet(library, account);
    }
  }, [account, chainId, library]);

  const TINC = tiny.externalTokens.TINC;
  const BUSD = tiny.externalTokens.BUSD;

  const [tincBalance, freshTINCBalance] = useTokenBalance(TINC, account);
  const [busdBalance, freshBUSDBalance] = useTokenBalance(BUSD, account);

  const [bnbBalance, refreshBNBBalance] = useBNBBalance(account);

  const startFreshTINCBalance = useLimitedInterval(freshTINCBalance);

  const [signedStr, setSignStr] = useState<string | null>(null);

  const [signOpen, setSignOpen] = useState<boolean>();

  const handleSign = useCallback(
    async (id: number, signer: Signer, account: string, force = false) => {
      try {
        const msg = await getLoginMessage(account);
        // console.log("msg", msg);
        // const signKey = getAT(account, msg);
        // console.log("signKey", signKey);
        return signer.signMessage(id + "-" + msg).then((signedStr: string) => {
          setSignStr(signedStr);
          return signedStr;
        });
        // if (signKey == null || force) {
        //   // console.log("signer.signMessage", signer.signMessage, Date.now());

        // } else {
        //   setSignStr(signKey);
        // }
        // return signKey;
      } catch (error) {
        console.error("getMessage error:", error);
      }
    },
    []
  );

  const handleGameSign = useCallback(
    async (
      signer: Signer,
      account: string,
      signOpen: boolean,
      force = false
    ) => {
      try {
        const { data: msg } = await loginWalletStr({ address: account });
        // console.log("msg", msg);
        if (msg && !signOpen) {
          // const signKey = getAT(account, msg);
          // console.log("signKey", signKey);
          // if (signKey == null || force) {
          setSignOpen(true);
          return signer
            .signMessage(msg)
            .then((signedStr: string) => {
              // saveAT(account, signedStr, msg);
              setSignStr(signedStr);
              return signedStr;
            })
            .finally(() => {
              setSignOpen(false);
            });
          // } else {
          //   setSignStr(signKey);
          // }
          // return signKey;
        }
        return "";
      } catch (error) {
        console.error("getMessage error:", error);
      }
    },
    []
  );

  const logout = useCallback(() => {
    deactivate();
    tiny.logout();
    localStorage.removeItem("connectorId");
  }, [deactivate]);

  const [userInfo, setUserInfo] = useState<ReferralUserInfo>();

  const getUserInfo = useCallback(
    async (account: string, force: boolean = false) => {
      const newProvider = new ethers.providers.Web3Provider(
        library,
        config.chainId
      );
      const signer = newProvider.getSigner(0);

      const providerAddress = await signer.getAddress();
      if (providerAddress !== account) return;

      getMyReferralUserInfo({ address: account }).then(async (result) => {
        console.log("result", result);
        if (result == null) {
          if (popSignAddressInfo.address == null) {
            popSignAddressInfo.address = providerAddress;
            popSignAddressInfo.ts = Date.now();
          } else if (
            popSignAddressInfo.address === account &&
            Date.now() - popSignAddressInfo.ts < 30_000 &&
            !force
          ) {
            return;
          }
          // 新用户，强制验签
          // const msg = JSON.stringify({
          //   account: account,
          //   refCode: code,
          //   ts: Date.now()
          // })
          const msg = await getLoginMessage(account);

          if (!signOpen) {
            setSignOpen(true);
            signer
              .signMessage(msg)
              .then((signedStr: string) => {
                saveAT(account, signedStr, msg);
                setSignStr(signedStr);
                return signedStr;
              })
              .then((signedStr: string) => {
                const code = getRefCode();
                bindReferral({
                  address: account,
                  referralCode: code,
                  signedMessage: signedStr,
                }).then((result) => {
                  setUserInfo(result);
                  deleteRefCode();
                });
              })
              .finally(() => {
                setSignOpen(false);
              })
              .catch((error) => {
                console.error("handleSign error:", error);
                logout();
              });
          }
        } else {
          setUserInfo(result);
          const cacheKey = "NOT_NEW_USER_POP_" + account;
          const showing = localStorage.getItem(cacheKey);
          if (showing === null && first) {
            const code = getRefCode();
            if (code) {
              setIsOpen(true);
              localStorage.setItem(cacheKey, "true");
            }
          }
          if (typeof signOpen === "undefined") {
            setSignOpen(false);
          }
        }
        first = false;
      });
    },
    [deleteRefCode, getRefCode, library, logout, signOpen]
  );

  useQuery(
    ["UserInfo", { account, signOpen }],
    async () => {
      if (account && !signOpen) {
        getUserInfo(account);
      }
    },
    {
      enabled:
        document.visibilityState === "visible" &&
        chainId === config.chainId &&
        typeof account === "string" &&
        !signOpen,
      refetchInterval: 40_000,
    }
  );

  return (
    <Context.Provider
      value={{
        tiny,
        tincBalance: tincBalance,
        startFreshTINCBalance: startFreshTINCBalance,
        bnbBalance,
        refreshBNBBalance,
        busdBalance,
        freshBUSDBalance,
        logout,
        userInfo,
        getUserInfo,
        handleSign,
        handleGameSign,
        signedStr,
        signOpen,
      }}
    >
      {children}
      {isOpen && (
        <StyledModalWrapper>
          <StyledModalBackdrop onClick={handleDismiss} />
          <NoticeModal
            onDismiss={handleDismiss}
            text={t("oldUser")}
            onConfirm={deleteRefCode}
          />
        </StyledModalWrapper>
      )}
    </Context.Provider>
  );
};

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    bottom: 3rem;
  `};
`;

const StyledModalBackdrop = styled.div`
  background-color: #00000088;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;
