import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import styled from "styled-components";

import { useBlockNumber } from "src/state/application/hooks";
import { gradeDurability, gradeType, heroPrefillColor } from "src/config";

import commonplaceBorder from "src/images/lord/hero/n_r_sr_border.png";
import iconFight from "src/images/lord/icon_fight.png";

import powerL from "src/images/power.svg";
import heroPre from "src/images/hero/hero_pre.png";
import available from "src/images/lord/available.png";
import unavailable from "src/images/lord/unavailable.png";
import idle from "src/images/status/idle.png";
import lp_boost from "src/images/status/lp_boost.png";
import gamingPng from "src/images/status/gaming.png";
// import lordStationing from "src/images/status/lord_stationing.png";
import lp_lord from "src/images/status/lp_lord.png";
import addImg from "src/images/lord/card/add.png";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useDescByCode from "src/hooks/useDescByCode";
import PrefilledImage from "src/components/Images/PrefilledImage";
import useIsMobile from "src/hooks/useIsMobile";
import { ICardHero, ICheckHeroButton } from "./type";

import {
  avatarBorders,
  getHeroHeadImg,
  getHeroHeadImgPre,
  gradeImg,
  heroImgHeight,
  restraintImgs,
  restraintPowerColor,
  roleImg,
  statusImg,
} from "./const";
import ShadowFont from "../ShadowFont";
import { maxAttackCount } from "src/views/Mine/TinyLord/const";
import { BLOCKS_PER_DAY, calcStamina } from "src/utils";
import AvatarStamina from "src/views/MyNFT/Upgrade/components/AvatarStamina";

export const CheckedHeroButton: FC<PropsWithChildren<ICheckHeroButton>> = ({
  boxStyle,
  height = 94,
  children,
}) => {
  return (
    <StyledHeroCardChekced height={height} style={{ ...boxStyle }}>
      <StyledAddChecked height={height * heroImgHeight}>
        {children}
      </StyledAddChecked>
    </StyledHeroCardChekced>
  );
};

export const CheckHeroButton: FC<ICheckHeroButton> = ({
  style,
  boxStyle,
  // index,
  // checkIndex,
  showCheckText,
  height = 94,
  power,
  withAdd = false,
}) => {
  const { t } = useTranslation();
  return (
    <StyledHeroCard power={power} height={height} style={{ ...boxStyle }}>
      <StyledCheckHeroHead height={height * heroImgHeight} style={{ ...style }}>
        <StyledAddBox height={height * heroImgHeight}>
          <StyledAdd>
            <StyledHeroImg src={heroPre} alt="" />
            {showCheckText && <span>{t("Select Hero")}</span>}
            {withAdd && (
              <StyledAddImg src={addImg} height="20" width="20" alt="" />
            )}
          </StyledAdd>
        </StyledAddBox>
      </StyledCheckHeroHead>
    </StyledHeroCard>
  );
};

const HeadHeroCard: FC<ICardHero> = ({
  gradeType,
  itemCode,
  power,
  itemLevel,
  id,
  style,
  boxStyle,
  freePlayTime,
  ownerflag,
  showFree,
  initPower,
  onClick,
  isStationing,
  isAttacking = false,
  gameIndex,
  isMobileTouch,
  isCheckHero,
  openRole,
  powerStyle,
  height = 94,
  powerTextStyle,
  powerIconStyle,
  attackCount,
  className,
  showStamina,
  StaminaInfo,
  notClickItem,
  isNotTinyLord,
  disablePropagation = false,
  countUp,
  restraint,
  hideStatus,
  rotateHeroRole,
}) => {
  const { t } = useTranslation();
  const heroRole = useDescByCode(itemCode);
  const isMobile = useIsMobile();
  const blockNumber = useBlockNumber();
  const iconHeight = height * 0.25;

  const BusyBox = useMemo(() => {
    const size = height < 94 ? height / 94 : 1;
    switch (ownerflag) {
      case 0:
        return (
          <StyledBusy size={size}>
            <StyledIcon src={idle} />
            <span>{t("Idle")}</span>
          </StyledBusy>
        );
      case 2:
        return (
          <StyledBusy size={size}>
            <StyledIcon src={lp_boost} />
            <span>{t("LPBattleground")}</span>
          </StyledBusy>
        );
      case 3:
        return (
          <StyledBusy size={size}>
            <StyledIcon src={gamingPng} />
            <span>{t("Tiny Kingdom")}</span>
          </StyledBusy>
        );
      case 1:
        if (gameIndex) {
          return (
            <StyledBusy size={size}>
              <StyledIcon src={lp_lord} />
              <span>{t("In The Stationed")}</span>
            </StyledBusy>
          );
        }
        return;
      default:
        return;
    }
  }, [height, ownerflag, t, gameIndex]);

  const disableFight = useMemo(() => {
    /* 
    (itemLevel >= maxAttackCount.defaultLevel
                  ? maxAttackCount.highLevel
                  : maxAttackCount.normal) - (attackCount ?? 0)
    */

    return (
      attackCount ===
        (itemLevel >= maxAttackCount.defaultLevel
          ? maxAttackCount.highLevel
          : maxAttackCount.normal) && gameIndex !== 1
    );
  }, [attackCount, gameIndex, itemLevel]);

  const free = dayjs(freePlayTime).diff(dayjs(new Date()), "hour") <= -24;

  const stamina =
    calcStamina(ownerflag, StaminaInfo, blockNumber, gradeType) ?? 0;

  const canClick = useMemo(() => {
    switch (notClickItem) {
      case "StaminaInfo":
        return stamina !== gradeDurability[gradeType];
      case "gradeType":
        return gradeType > 3;
      default:
        return true;
    }
  }, [stamina, gradeType, notClickItem]);

  return (
    <StyledHeroCard
      className={className}
      height={height}
      style={boxStyle}
      power={power}
      onClick={(e) => {
        disablePropagation && e.stopPropagation();
        if (notClickItem) {
          canClick &&
            onClick &&
            onClick({
              id,
              gradeType,
              itemCode,
              power,
              itemLevel,
              freePlayTime,
              ownerflag,
              initPower,
              StaminaInfo,
            });
        } else {
          onClick &&
            ownerflag === 1 &&
            !gameIndex &&
            !disableFight &&
            !isAttacking &&
            onClick({
              id,
              gradeType,
              itemCode,
              power,
              itemLevel,
              freePlayTime,
              ownerflag,
            });
        }
      }}
    >
      {isStationing && !isAttacking && <StyledBorder></StyledBorder>}
      <StyledHeroHead style={style}>
        {isNotTinyLord ? (
          canClick ? (
            <></>
          ) : (
            <StyledBusy size={height < 94 ? height / 94 : 1}></StyledBusy>
          )
        ) : isStationing || isAttacking ? (
          <StyledBusy size={height < 94 ? height / 94 : 1}>
            <StyledIcon src={iconFight} />
            <span>{t("fighting")}</span>
          </StyledBusy>
        ) : disableFight ? (
          <StyledBusy size={height < 94 ? height / 94 : 1}></StyledBusy>
        ) : (
          showFree && BusyBox
        )}
        {isCheckHero && ownerflag === 1 && !hideStatus && (
          <StyledIcon
            height={iconHeight}
            style={{
              position: "absolute",
              bottom: isMobile ? 4 : 6,
              right: isMobile ? 4 : 6,
              zIndex: 4,
            }}
            src={statusImg[ownerflag]}
          />
        )}
        {free && showFree && (
          <StyledFree>
            {/* {console.log({
              value:
                (itemLevel >= maxAttackCount.defaultLevel
                  ? maxAttackCount.highLevel
                  : maxAttackCount.normal) - (attackCount ?? 0),
              flag: itemLevel >= maxAttackCount.defaultLevel,
              attackCount,
              itemLevel,
            })} */}
            {/* // background-image: url(${({ permit }) => (permit ? available : unavailable)}); */}
            <img src={!disableFight ? available : unavailable} alt="" />
            <ShadowFont
              style={{
                position: "absolute",
                transform: `scale(${height / 94})`,
              }}
              value={
                (itemLevel >= maxAttackCount.defaultLevel
                  ? maxAttackCount.highLevel
                  : maxAttackCount.normal) -
                (typeof attackCount === "number" && attackCount > 0
                  ? attackCount
                  : 0)
                // maxAttackCount[itemLevel > 4 ? "highGrade" : "normal"] -
                // (attackCount ?? 0)
              }
            />
          </StyledFree>
        )}
        <StyledHeroHeadImg
          prefillColor={heroPrefillColor[5 - gradeType]}
          imgHeight={Math.floor(height * heroImgHeight)}
          preSrc={getHeroHeadImgPre()}
          src={getHeroHeadImg(itemCode)}
          grade={gradeType}
          alt=""
          width={99}
          height={99}
        />
        {itemCode > 100 && (
          <>
            {!isMobileTouch && (
              <StyledHeroLevel>LV. {itemLevel}</StyledHeroLevel>
            )}
            {!isMobileTouch && (
              <StyledHeroRoleImg
                style={
                  rotateHeroRole
                    ? {
                        bottom: "-6%",
                        right: "-6%",
                        height: "38%",
                        top: "unset",
                        zIndex: 9,
                        filter: `brightness(${
                          disableFight || isStationing || isAttacking ? 0.5 : 1
                        })`,
                      }
                    : {}
                }
                src={roleImg[heroRole?.type ? heroRole.type - 1 : 0]}
                width={32}
                height={33}
                alt=""
              />
            )}
            <StyledHeroGrade>
              <img
                style={{
                  height: "100%",
                }}
                src={gradeImg[gradeType - 1]}
                alt=""
              />
            </StyledHeroGrade>
          </>
        )}
      </StyledHeroHead>

      {showStamina && (
        <StyledStamina
          style={{
            top: isMobile ? 60.5 : Math.floor(height * heroImgHeight),
            transform: "scaleY(0.91)",
          }}
        >
          <AvatarStamina grade={gradeType} stamina={stamina} />
        </StyledStamina>
      )}

      {countUp ? (
        <StyledPowerBox
          style={
            showStamina
              ? {
                  ...powerStyle,
                  height: "17%",
                }
              : powerStyle
          }
        >
          <StyledIcon
            style={
              showStamina
                ? {
                    ...powerIconStyle,
                    height: 16,
                  }
                : powerIconStyle
            }
            className={isMobile ? "mr-2" : "mr-5"}
            // src={powerL}
            src={restraint ? restraintImgs[restraint] : powerL}
          />
          <span
            style={{
              ...{
                marginTop: 1,
                ...powerTextStyle,
              },
              ...(restraint ? { color: restraintPowerColor[restraint] } : {}),
            }}
          >
            {countUp}
          </span>
        </StyledPowerBox>
      ) : (
        power && (
          <StyledPowerBox
            style={
              showStamina
                ? {
                    ...powerStyle,
                    height: "17%",
                  }
                : powerStyle
            }
          >
            <StyledIcon
              style={
                showStamina
                  ? {
                      ...powerIconStyle,
                      height: 16,
                    }
                  : powerIconStyle
              }
              className={isMobile ? "mr-2" : "mr-5"}
              src={restraint ? restraintImgs[restraint] : powerL}
            />
            <span
              style={{
                ...{
                  marginTop: 1,
                  ...powerTextStyle,
                },
                ...(restraint ? { color: restraintPowerColor[restraint] } : {}),
              }}
            >
              {power}
            </span>
          </StyledPowerBox>
        )
      )}
    </StyledHeroCard>
  );
};

export default HeadHeroCard;

const StyledAddChecked = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0%;
  border-radius: 2px;
  // padding-bottom: 9%;
  box-sizing: border-box;
  font-size: 2.4rem;
  text-shadow: 0px 3px 1px #182f42;
  background: #d8c081;

  border-radius: 1rem;
  border: 3px solid #ffe7a7;

  flex-direction: column;

  > img {
    height: 80%;
    position: absolute;
    top: -1rem;
  }

  span {
    font-size: 1rem;
    // transform: scale(0.57);
    white-space: nowrap;
  }
`;

const StyledHeroCardChekced = styled.div<{ height: number }>`
  display: flex;
  align-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
  width: ${({ height }) => height * heroImgHeight}px;
  height: ${({ height }) => height}px;
  color: #ffffff;
`;

const StyledStamina = styled.div`
  width: 100%;
  margin: 2px auto;
  position: absolute;
`;

const StyledBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -4%;
  border: 2px solid #00a7ff;
  border-radius: 6px;
  padding: 2px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: unset;
  `}
`;

const StyledBusy = styled.div<{ size: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  background: rgb(0 0 0 / 60%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 9;
  border-radius: 3px;

  img {
    height: 28%;
  }

  span {
    font-size: 12px;
    width: 100%;
    text-align: center;
    zoom: ${({ size }) => size};
  }
`;

const StyledFree = styled.div`
  position: absolute;
  left: -5%;
  top: -6%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  z-index: 9;

  img {
    height: 100%;
  }
`;

const StyledAddBox = styled.div<{ height: number }>`
  background: #86bedd;
  width: ${({ height }) => height * 0.85}px;
  height: ${({ height }) => height * 0.85}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0%;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, #3695c7 0%, #0e366d 60%, #00154e 100%);
  position: relative;
  top: 0%;
  border-radius: 2px;
  // padding-bottom: 9%;
  box-sizing: border-box;
  font-size: 2.4rem;
  text-shadow: 0px 3px 1px #182f42;

  span {
    font-size: 1rem;
    // transform: scale(0.57);
    white-space: nowrap;
  }
`;

const StyledHeroGrade = styled.div`
  position: absolute;
  bottom: 11%;
  left: 10%;
  height: 18%;

  img {
    display: block;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    bottom: 6%;
    height:20%;
  `}
`;

const StyledHeroRoleImg = styled.img`
  position: absolute;
  top: 4%;
  right: 6%;
  height: 30%;
  width: auto;
`;

const StyledHeroLevel = styled.div`
  position: absolute;
  top: 7%;
  left: 11%;
  font-size: 12px;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  font-family: AvenirNextLTPro;
  transform: scale(0.98);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    transform: scale(0.68);
    top: 0%;
    left: 0%;
  `}
`;

const StyledHeroCard = styled.div<{ height: number; power?: number }>`
  display: flex;
  align-items: center;
  align-content: ${({ power }) => (power ? "space-between" : "center")};
  justify-content: center;
  flex-wrap: wrap;
  width: ${({ height }) => height * heroImgHeight}px;
  height: ${({ height }) => height}px;
  color: #ffffff;
  position: relative;
`;

const StyledIcon = styled.img<{ height?: number }>`
  height: ${({ height }) => (height ? height : 20)}px;

  ${({ theme, height }) => theme.mediaWidth.upToExtraSmall`
    height: ${() => (height ? height : 20)}px;
  `}
`;

const StyledPowerBox = styled.div`
  width: 100%;
  height: 26%;
  font-size: 13px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // transform: scale(0.8);
    font-size: 13px;
  `}
`;

const StyledHeroHeadImg = styled(PrefilledImage)<{
  grade: gradeType;
  imgHeight: number;
  prefillColor: string;
}>`
  width: ${({ imgHeight }) => imgHeight}px;
  height: ${({ imgHeight }) => imgHeight}px;
  border: solid;
  border-image: ${({ grade }) =>
      grade < 4
        ? `url(${avatarBorders[0]})`
        : `url(${avatarBorders[(grade + 1) % 4]})`}
    8 8 / ${({ imgHeight }) => imgHeight * 0.08}px
    ${({ imgHeight }) => imgHeight * 0.08}px;

  box-sizing: border-box;
  filter: ${({ prefillColor }) => `drop-shadow(0px 0px ${prefillColor})`};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    display: block;
    // border-image-slice: 22;
    width: ${({ imgHeight }) => imgHeight * 0.87}px;
    height: ${({ imgHeight }) => imgHeight * 0.87}px;

    // width: 105%;
    // height: 105%;
    box-sizing: border-box;
    // border-radius:4px;
  }
`;

const StyledHeroHead = styled.div`
  width: 100%;
  position: relative;
`;

const StyledCheckHeroHead = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ height }) => height}px;
  height: ${({ height }) => height}px;
  border: solid;

  border-image: url(${commonplaceBorder}) 8 8 /
    ${({ height }) => height * 0.08}px ${({ height }) => height * 0.08}px;
  box-sizing: border-box;
  border-image-slice: 8;

  overflow: hidden;
`;

const StyledAddImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 14px;
    height: 14px;
  `}
`;

const StyledHeroImg = styled.img`
  width: 180%;
  height: auto;
  margin-top: -6px;
  margin-left: -2px;
  opacity: 0.3;
`;
