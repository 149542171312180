import React, { useMemo, useCallback } from "react";
import { BigNumber } from "ethers";
import { useQuery } from "react-query";

// import { getYieldFarmingContract, getEarned } from '../../sushi/utils'
import Context, { initState } from "./context";
import useTiny from "../../hooks/useTiny";
import useAutoFreshRequest from "../../hooks/useAutoFreshRequest";
import useAllStakedValue from "../../hooks/useAllStakedValue";
import useFarmTINCPerDay from "../../hooks/useFarmTINCPerDay";

import { getFarmInfos } from "src/services";

import { bn1e18 } from "src/utils/index";
// import { Farm } from './types'

const farmCacheKey = "FARM_POOL_INFO";

const Farms: React.FC = ({ children }) => {
  const tiny = useTiny();
  // const account = tiny.myAccount;

  const { data: farmInfos } = useQuery(farmCacheKey, getFarmInfos, {
    refetchInterval: 300_000,
  });

  // console.log('farmInfos', farmInfos);
  // console.log('useAllStakedValue', useAllStakedValue);
  const [allStakedValue, freshAllStakedValue] = useAllStakedValue(
    initState.farms
  );

  const lpFarm = tiny.contracts.TinyLPFarm;
  // const lpToken = tiny.externalTokens.LP;
  // console.log('lpToken', lpToken);

  const [lpFarmPoolInfo, freshInfo] = useAutoFreshRequest(lpFarm.poolInfo, [], {
    interval: 300_000,
  });

  const lpFarmTINCPerDay = useFarmTINCPerDay();

  // calc APY with bonus
  const calcApyByBonus = useCallback(
    (NFTBonus: number) => {
      if (!lpFarmPoolInfo) return 0;
      const farmAccShare: BigNumber = lpFarmPoolInfo.accShare;
      // console.log("farmAccShare", farmAccShare.toString());
      if (farmAccShare.isZero()) return 0;
      const price = farmInfos?.price;
      if (!price) return 0;
      const lpPrice = price["LP"];
      const tincPrice = price.TINC;
      // beta tinc price
      // console.log('tincPrice', tincPrice);

      const tincPerSharePerDay = BigNumber.from(lpFarmTINCPerDay * 100)
        .mul((1e8).toFixed())
        .mul(bn1e18)
        .div(farmAccShare);
      // console.log("NFTBonus", NFTBonus);
      // console.log("tincPerSharePerDay", tincPerSharePerDay);
      // console.log("lpPrice", lpPrice);
      const apy = BigNumber.from(NFTBonus)
        .mul(tincPerSharePerDay)
        .mul((365 * tincPrice * 1e4).toFixed())
        .div((lpPrice * 1e4).toFixed())
        .div(1e6) // 上面乘了 1e10 的系数，这里是返回 的是百分比，所以除以 1e8
        .toNumber();
      return apy / 100;
    },
    [lpFarmPoolInfo, farmInfos?.price, lpFarmTINCPerDay]
  );

  const stakeInfos = useMemo(() => {
    if (!farmInfos) return;
    const pool = farmInfos.pool;
    const stakeInfos = farmInfos.stakeInfos;
    const max_bonus = pool.max_bonus || 0;
    // console.log('max_bonus', max_bonus);
    if (max_bonus && stakeInfos[-1]) {
      stakeInfos[-1].baseApy = calcApyByBonus(max_bonus + 1000);
    }
    return stakeInfos;
  }, [farmInfos, calcApyByBonus]);

  const value = {
    farms: initState.farms,
    stakeInfos,
    allStakedValue: allStakedValue ?? [],
    tvlTotal: farmInfos?.tvlTotal || 0, // 一池的总 TVL
    price: farmInfos?.price || initState.price,
    burned: farmInfos?.burned || "0",
    burnedFor30Days: farmInfos?.burnedFor30Days || "0",
    freshAllStakedValue,
    freshLpFarmPoolInfo: freshInfo,
    lpFarmNFTPowerInfo: farmInfos?.pool || initState.lpFarmNFTPowerInfo,
    calcApyByBonus,
    tincAvgPrice: farmInfos?.tincAvgPrice,
    tincAvgPriceFor3Days: farmInfos?.tincAvgPriceFor3Days,
    userCount: farmInfos?.userCount || initState.userCount,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Farms;
