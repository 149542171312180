import { useMemo } from "react";

import useMyNFTList from "src/hooks/useMyNFTList";
import useStakedNFTList from "src/hooks/useStakedNFTList";
import useGamingNFTList from "src/hooks/useGamingNFTList";
import useDescByCode from "./useDescByCode";

import { filterKeyEnum, filtersType } from "src/config";
import type { NFTInfo } from "src/services/typings";

export default function useAllMyNFT(
  account: string | undefined,
  filters?: filtersType[]
): { data: NFTInfo[] | undefined; refetch: () => void } {
  const { data: results1, refetch: refetchMyNFTList } = useMyNFTList(account);
  // if (results1) {
  //   console.log(
  //     "results1",
  //     results1.filter((ele) => ele.itemGrade > 3).map((ele) => ele.id)
  //   );
  // }
  const { data: results2, refetch: refetchStakedNFTList } =
    useStakedNFTList(account);

  const { data: results3, refetch: refetchGamingNFTList } =
    useGamingNFTList(account);

  const refetchNFTList = () => {
    refetchMyNFTList();
    refetchStakedNFTList();
    refetchGamingNFTList();
  };

  // console.log('results2', results2);
  const middleResults = useMemo(() => {
    if (
      results1 === undefined &&
      results2 === undefined &&
      results3 === undefined
    )
      return undefined;
    return (results1 || []).concat(results2 || [], results3 || []);
  }, [results1, results2, results3]);

  const getDetail = useDescByCode();
  // console.log('middleResults', middleResults);
  return useMemo(() => {
    if (
      filters === undefined ||
      filters.length === 0 ||
      middleResults === undefined ||
      middleResults.length === 0
    ) {
      return { data: middleResults, refetch: refetchNFTList };
    }
    let finalResults = middleResults;
    for (let index = 0; index < filters.length; index++) {
      const element = filters[index];
      if (element.key === filterKeyEnum.grade) {
        const value = element.value;
        if (typeof value !== "undefined") {
          finalResults = middleResults.filter((ele) => ele.itemGrade === value);
        }
      } else if (element.key === filterKeyEnum.role) {
        const job = element.value;
        if (typeof job !== "undefined") {
          finalResults = middleResults.filter((ele) => {
            const detail = getDetail(ele.itemCode);
            return detail?.type === job;
          });
        }
      }
    }
    // console.log('finalResults', finalResults);

    return { data: finalResults, refetch: refetchNFTList };
  }, [middleResults, filters, getDetail]);
}
