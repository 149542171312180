import { useState, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { usePopperTooltip } from "react-popper-tooltip";

import langPng from "src/images/icon_lang.png";

const configs: [string, string][] = [
  ["en", "English"],
  ["ru", "русский язык"],
  ["zh", "繁體中文"],
];
const configMap = new Map(configs);

export default function Language() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [isShow, setShow] = useState(false);

  const showLang = useMemo(() => {
    const keys = configMap.keys();
    for (const key of keys) {
      if (lang.toLowerCase().includes(key)) {
        return key;
      }
    }
    return "en";
  }, [lang]);

  const {
    // getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    visible: isShow,
    onVisibleChange: setShow,
  });

  return (
    <div>
      <StyledGradeButton ref={setTriggerRef}>
        <img className="mr-5" src={langPng} alt="" />
        <span>{showLang.toUpperCase()}</span>
      </StyledGradeButton>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <StyledButtonList>
            {configs.map((ele) => {
              const [symbol, text] = ele;
              // console.log(text)
              if (symbol !== showLang) {
                return (
                  <StyledGradeSelect
                    key={symbol}
                    onClick={() => {
                      i18n.changeLanguage(symbol);
                      setShow(false);
                    }}
                  >
                    {text}
                  </StyledGradeSelect>
                );
              }
            })}
          </StyledButtonList>
        </div>
      )}
    </div>
  );
}

const StyledButtonList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f79f0e;
`;

const StyledGradeSelect = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  min-width: 10.7rem;
  :hover {
    color: ${(props) => props.theme.color.yellow};
    background-color: #414141;
  }
  border-top: 1px solid dimgray;
  :first-child {
    border-top: 0 none;
  }
`;

const StyledBorderButton = styled.button`
  box-sizing: border-box;
  padding: 4px 0;
  height: 33px;
`;

const StyledGradeButton = styled(StyledBorderButton)`
  display: inline-flex;
  align-items: center;
  font-size: 1.22rem;
  line-height: 1.5;
  color: #514b45;
  ::after {
    content: " ";
    box-sizing: border-box;
    margin-left: 10px;
    display: inline-block;
    transform: translateY(2px);
    border-width: 10px 8px 3px;
    border-style: solid;
    border-color: #514b45 transparent transparent transparent;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 2px 8px;
    height: 2.6rem;
    font-size: 1.12rem;
    line-height: 1.5;
    ::after {
      margin-left: .5rem;
    }
  `}
`;
