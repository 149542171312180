import { useRef, useEffect, useCallback } from "react";

/**
 * 自动设置定时器
 * @param {Function} func 需要定时调用的函数
 * @param {number} interval
 * @param {number} limit
 */
function useLimitedInterval(
  func: () => void,
  options?: {
    interval?: number;
    limit?: number;
    delay?: number;
  }
) {
  const { interval = 3_050, limit = 8, delay = 4_000 } = options || {};
  const timerRef = useRef<number | null>(null);
  const timerRef2 = useRef<number | null>(null);
  const countRef = useRef(limit);

  // 取消定时器
  const cancel = useCallback(() => {
    if (timerRef.current !== null) {
      window.clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  // 在组件卸载的时候取消定时器
  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        window.clearInterval(timerRef.current);
      }
      if (timerRef2.current !== null) {
        window.clearInterval(timerRef2.current);
      }
    };
  }, []);

  // 开始定时器
  const startFetch = useCallback(() => {
    // 调用的时候重置
    countRef.current = limit;
    cancel();

    timerRef2.current = window.setTimeout(() => {
      timerRef.current = window.setInterval(() => {
        // console.log("limit countRef.current", limit, countRef.current);
        if (countRef.current > 0) {
          func();
          countRef.current -= 1;
          return;
        }
        // 当 countRef.current === 0 的时候，取消定时器
        cancel();
      }, interval);
    }, delay);

    return cancel;
  }, [cancel, func, interval, limit, delay]);
  return startFetch;
}

export default useLimitedInterval;
