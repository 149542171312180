import { gradeDurability, gradeType } from "src/config";
import styled from "styled-components";

interface AvatarStaminaProps {
  stamina: number;
  grade: gradeType;
}

// #74d584, #004a00
// const normalStaminaGradient = ['74d584', '004a00']

const calculateRatio = (dividend: number, divisor: number) =>
  (dividend / divisor) * 100;

export default function AvatarStamina({ stamina, grade }: AvatarStaminaProps) {
  let normalStaminaRatio = 100,
    firstFullerWidth = 100,
    secondFullerWidth = 100,
    urWidth = 100;

  if (grade < 4) {
    normalStaminaRatio = calculateRatio(stamina, gradeDurability[grade]);
  } else if (grade < 5) {
    // 4
    if (stamina < gradeDurability[3]) {
      normalStaminaRatio = calculateRatio(stamina, gradeDurability[3]);
      firstFullerWidth = 0;
    } else {
      firstFullerWidth = calculateRatio(
        stamina - gradeDurability[3],
        gradeDurability[4] - gradeDurability[3]
      );
    }
  } else if (grade < 6) {
    // 5
    if (stamina < gradeDurability[3]) {
      normalStaminaRatio = calculateRatio(stamina, gradeDurability[3]);
      firstFullerWidth = 0;
      secondFullerWidth = 0;
    } else if (stamina < gradeDurability[4]) {
      secondFullerWidth = 0;
      firstFullerWidth = calculateRatio(
        stamina - gradeDurability[3],
        gradeDurability[4] - gradeDurability[3]
      );
    } else {
      secondFullerWidth = calculateRatio(
        stamina - gradeDurability[4],
        gradeDurability[5] - gradeDurability[4]
      );
    }
  } else {
    if (stamina < gradeDurability[3]) {
      normalStaminaRatio = calculateRatio(stamina, gradeDurability[3]);
    }

    urWidth = calculateRatio(
      stamina - gradeDurability[3],
      gradeDurability[6] - gradeDurability[3]
    );
  }

  return (
    <StyledContainer>
      <StyledNormal isUR={grade === 6} width={normalStaminaRatio} />
      {grade === 6 ? (
        <StyledURGrade width={urWidth < 0 ? 0 : urWidth} />
      ) : (
        <>
          {grade > 3 && <StyledSuperGrade width={firstFullerWidth} />}
          {grade > 4 && <StyledSuperGrade width={secondFullerWidth} />}
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledNormal = styled.div<{ width: number; isUR: boolean }>`
  position: relative;
  clip-path: polygon(4% 8%, 96% 8%, 99% 50%, 96% 96%, 4% 96%, 1% 50%);
  width: ${({ isUR }) => (isUR ? 53 : 72)}px;
  height: 8.7px;
  background-color: #534b48;
  z-index: 0;

  ::before {
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    clip-path: polygon(4% 8%, 96% 8%, 99% 50%, 96% 96%, 4% 96%, 1% 50%);
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1)
    );
    z-index: 2;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0.5px;
    bottom: 0.5px;
    left: 0.5px;
    right: 0.5px;
    clip-path: polygon(4% 8%, 96% 8%, 99% 50%, 96% 96%, 4% 96%, 1% 50%);
    background-image: linear-gradient(to right, #74d584 0%, #004a00 100%);
    background-size: ${({ width }) => width}% 100%;
    background-color: #b2b2b2;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;

const StyledSuperGrade = styled.div<{ width: number }>`
  position: relative;
  clip-path: polygon(
    74% 4%,
    94% 50%,
    74% 96%,
    6% 96%,
    32% 50%,
    6% 4%
  ); // 缩进留出'border'
  width: 10px;
  height: 8.7px;
  background-color: #534b48;
  z-index: 0;
  margin-left: -2px;

  ::before {
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    clip-path: polygon(74% 4%, 94% 50%, 74% 96%, 8% 96%, 36% 50%, 8% 4%);
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1)
    );
    z-index: 2;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0.5px;
    bottom: 0.5px;
    left: 0.5px;
    right: 0.5px;
    clip-path: polygon(74% 4%, 94% 50%, 74% 96%, 8% 96%, 36% 50%, 8% 4%);
    background-image: linear-gradient(
      to right,
      #fbff4e 0%,
      #ff6c00 60%,
      #ff0000
    );
    background-size: ${({ width }) => width}% 100%;
    background-color: #b2b2b2;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;

const StyledURGrade = styled.div<{ width: number }>`
  position: relative;
  clip-path: polygon(1% 8%, 86% 8%, 94% 50%, 86% 96%, 4% 96%, 7% 50%);
  width: 33px;
  height: 8.7px;
  background-color: #534b48;
  z-index: 0;
  margin-left: -1.5px;

  ::before {
    content: "";
    position: absolute;
    top: 1.5px;
    bottom: 1.5px;
    left: 1.5px;
    right: 1.5px;
    clip-path: polygon(4% 8%, 86% 8%, 94% 50%, 86% 96%, 4% 96%, 7% 50%);
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1)
    );
    z-index: 2;
  }

  ::after {
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    clip-path: polygon(4% 8%, 86% 8%, 92% 50%, 86% 96%, 4% 96%, 8% 50%);
    background-image: linear-gradient(
      to right,
      #ff0000 0%,
      #ff0000 60%,
      #520049
    );
    background-size: ${({ width }) => width}% 100%;
    background-color: #b2b2b2;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;

const StyledContent = styled.div``;
