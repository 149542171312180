import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import ERC20 from "../tiny/ERC20";

// const APPROVE_AMOUNT = ethers.constants.MaxUint256;

const allowanceCache: {
  [key: string]: BigNumber;
} = {};

function useAllowance(
  token: ERC20,
  spender: string,
  pendingApproval?: boolean
): [BigNumber | null, () => void] {
  const [allowance, setAllowance] = useState<BigNumber | null>(
    allowanceCache[token.address + spender] || null
  );
  const { account } = useWeb3React();
  const [preApproval, setPreApproval] = useState(pendingApproval);

  // console.log("allowance", allowance);
  const fetchAllowance = useCallback(async () => {
    const currentAllowance = await token.allowance(account as string, spender);
    console.log(
      `Allowance: ${currentAllowance.toString()} ${token.symbol} for ${spender}`
    );
    allowanceCache[token.address + spender] = currentAllowance;
    setAllowance(currentAllowance);
    return currentAllowance;
  }, [account, spender, token]);

  useEffect(() => {
    // console.log('fetchAllowance');
    if (account && spender && token) {
      fetchAllowance().catch((err) =>
        console.error(`Failed to fetch allowance: ${err.stack}`)
      );
    }
  }, [account, spender, token, fetchAllowance]);

  useEffect(() => {
    // 当 pendingApproval 变成 true ，将 preApproval 设为 true
    if (pendingApproval && !preApproval) {
      setPreApproval(pendingApproval);
    }
  }, [preApproval, pendingApproval]);

  const fetchDelayAllowance = useCallback(
    (cb?: Function) => {
      window.setTimeout(() => {
        fetchAllowance()
          .then((value) => {
            if (value.isZero()) {
              cb?.();
            }
          })
          .catch((err) =>
            console.error(`Failed to fetch allowance: ${err.stack}`)
          );
      }, 3_500);
    },
    [fetchAllowance]
  );

  useEffect(() => {
    if (
      account &&
      spender &&
      token &&
      pendingApproval === false &&
      preApproval
    ) {
      // 如果 pendingApproval 变成 false
      // console.log("pendingApproval", pendingApproval);
      // console.log("preApproval", preApproval);
      fetchDelayAllowance(() => {
        fetchDelayAllowance(() => {
          fetchDelayAllowance(fetchDelayAllowance);
        });
      });
    }
  }, [
    account,
    spender,
    token,
    fetchAllowance,
    pendingApproval,
    preApproval,
    fetchDelayAllowance,
  ]);

  return [allowance, fetchAllowance];
}

export function useAllowanceEx({
  token,
  address,
  spender,
  pendingApproval,
}: {
  token?: ERC20;
  address?: string;
  spender: string;
  pendingApproval?: boolean;
}): [BigNumber | null, () => void] {
  const [allowance, setAllowance] = useState<BigNumber | null>(
    allowanceCache[address + spender] || null
  );
  const { account } = useWeb3React();
  const [preApproval, setPreApproval] = useState(pendingApproval);
  const [loaded, setLoaded] = useState(false);

  // console.log("allowance", allowance);
  const fetchAllowance = useCallback(async () => {
    if (token) {
      const currentAllowance = await token.allowance(
        account as string,
        spender
      );
      console.log(
        `Allowance: ${currentAllowance.toString()} ${
          token.symbol
        } for ${spender} form ${account}`
      );
      allowanceCache[token.address + spender] = currentAllowance;
      setAllowance(currentAllowance);
      return currentAllowance;
    }
  }, [account, spender, token]);

  useEffect(() => {
    if (account && spender && token && !loaded) {
      console.log("fetchAllowance");
      fetchAllowance()
        .then(() => {
          setLoaded(true);
        })
        .catch((err) =>
          console.error(`Failed to fetch allowance: ${err.stack}`)
        );
    }
  }, [account, spender, token, fetchAllowance, loaded]);

  useEffect(() => {
    // 当 pendingApproval 变成 true ，将 preApproval 设为 true
    if (pendingApproval && !preApproval) {
      setPreApproval(pendingApproval);
    }
  }, [preApproval, pendingApproval]);

  const fetchDelayAllowance = useCallback(
    (cb?: Function) => {
      window.setTimeout(() => {
        fetchAllowance()
          .then((value) => {
            if (value) {
              if (value.isZero()) {
                cb?.();
              }
            }
          })
          .catch((err) =>
            console.error(`Failed to fetch allowance: ${err.stack}`)
          );
      }, 3_500);
    },
    [fetchAllowance]
  );

  useEffect(() => {
    if (
      account &&
      spender &&
      token &&
      pendingApproval === false &&
      preApproval
    ) {
      // 如果 pendingApproval 变成 false
      // console.log("pendingApproval", pendingApproval);
      // console.log("preApproval", preApproval);
      fetchDelayAllowance(() => {
        fetchDelayAllowance(() => {
          fetchDelayAllowance(fetchDelayAllowance);
        });
      });
    }
  }, [
    account,
    spender,
    token,
    fetchAllowance,
    pendingApproval,
    preApproval,
    fetchDelayAllowance,
  ]);

  return [allowance, fetchAllowance];
}

export default useAllowance;
