// import { detect } from "detect-browser";
import config from "src/config";
// import { logErrorToService } from "src/services/util";

// const browser = detect();

function isNeedAdd(error: any) {
  if (!error) {
    return;
  }
  const code: number = error.code;
  const message: string = error.message;
  if (code === 4902) {
    return true;
  }
  if (message && message.includes("Unrecognized chain ID")) {
    return true;
  }
}

function parseError(switchError: any) {
  const isNeed = isNeedAdd(switchError);
  if (isNeed) {
    return true;
  }
  const data: any = switchError.data;
  // firefox metamask
  if (data && data.originalError) {
    const isNeed = isNeedAdd(data.originalError);
    if (isNeed) {
      return true;
    }
  }
}

export default async function switchChain(
  connector: "Injected" | "WalletLink" | "okxwallet"
) {
  const ethereum =
    connector === "Injected" || "WalletLink"
      ? window.ethereum
      : window.okxwallet;
  if (ethereum) {
    let provider = ethereum;
    if (ethereum.providers?.length) {
      ethereum.providers.forEach(async (p) => {
        if (connector === "Injected" && p.isMetaMask) {
          provider = p;
        } else if (connector === "WalletLink" && p.isWalletLink) {
          provider = p;
        }
      });
    }
    try {
      // console.log("start wallet_switchEthereumChain");
      const response = await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: config.chainId === 56 ? "0x38" : "0x61" }],
      });
      // console.log("wallet_switchEthereumChain response:", response);
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (parseError(switchError as any)) {
        console.log("parseError true");
        try {
          const response = await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: config.chainId === 56 ? "0x38" : "0x61",
                chainName:
                  config.chainId === 56
                    ? "BNB Smart Chain"
                    : "BNB Testnet Smart Chain",
                rpcUrls: config.defaultProvider,
              },
            ],
          });
          // console.log("wallet_addEthereumChain response:", response);
        } catch (addError: any) {
          // handle "add" error
          console.error("addError:", addError);
          // const message =
          //   addError && addError.message ? addError.message : addError;
          // if (
          //   message.includes("already pending for") ||
          //   message.includes("User rejected")
          // ) {
          //   return;
          // }
          // logErrorToService({
          //   account: ethereum.selectedAddress,
          //   error: "wallet_addEthereumChain error:" + message,
          //   type: "tx",
          //   os: browser?.os,
          //   browsername: browser?.name,
          //   deviceType: browser?.type,
          // });
        }
      }
      // handle other "switch" errors
    }
  }
}
