import { SortInfo, SortKey, SortOrder } from "src/config";
import { UpgradeItemRuleHero } from "src/services/cache";
import { NFTInfo } from "src/services/typings";
import { calcStamina } from "src/utils";

const demoRuleLow = [10, 20, 30, 40, 50, 60, 70, 80];
const demoRuleHigh = [
  8300, 13000, 20000, 32800, 42200, 51800, 63900, 71200, 80900, 88300, 100900,
  119500, 123100, 130300, 136400, 138700, 151600, 170300, 184300, 193200,
];

const demoRuleSSSR = [
  80000, 89600, 100700, 120900, 135200, 152300, 172000, 192800, 211600, 220200,
  227900, 238500, 242500, 247700, 254400, 271000, 294000, 321700, 351500,
  395300,
];

export const ACTIVE_END_TIME = 1680768000;

export const SPIRIT_REWARD = {
  1: demoRuleLow,
  2: demoRuleLow.map((ele) => ele * 2),
  3: demoRuleLow.map((ele) => ele * 3),
  4: demoRuleHigh,
  5: demoRuleSSSR,
  6: demoRuleHigh.map((ele) => ele * 3),
} as const;

export interface CardInfo extends NFTInfo {
  isSelected: boolean;
  isDisabled: boolean;
  loading: boolean;
}

export function getInitConfig(
  upgradeRuleHeros: UpgradeItemRuleHero[],
  gradeStr: string | null
) {
  const init = upgradeRuleHeros.find(
    (ele) => ele.grade.toString() === gradeStr
  );
  // console.log('init', init);
  return init || upgradeRuleHeros[0];
}

export function nameSort(a: NFTInfo, b: NFTInfo) {
  if (a.itemCode - b.itemCode > 0) {
    return 1;
  }
  if (a.itemCode - b.itemCode < 0) {
    return -1;
  }
  return a.id - b.id;
}

export const spiritExchangeHeroFilter = ({
  blockNumber,
  sort,
  finalResults,
}: {
  blockNumber: number;
  sort: SortInfo;
  finalResults: CardInfo[] | null;
}) => {
  if (finalResults) {
    if (sort.key === SortKey.energy) {
      const noStaminaArrInit: CardInfo[] = [];
      const noStaminaArrUpgrade: CardInfo[] = [];
      const staminaArr: CardInfo[] = [];

      finalResults.map((ele) => {
        const stamina = calcStamina(
          ele.ownerflag,
          ele.StaminaInfo,
          blockNumber,
          ele.itemGrade
        );
        if (typeof stamina === "number" && stamina > 0) {
          staminaArr.push(ele);
        } else {
          if (ele.itemLevel === 1) {
            noStaminaArrInit.push(ele);
          } else {
            noStaminaArrUpgrade.push(ele);
          }
        }
      });

      noStaminaArrInit.sort((a, b) => {
        const sortPower = a.power - b.power;
        return SortOrder.ASC === sort.order ? sortPower : -sortPower;
      });
      noStaminaArrUpgrade.sort((a, b) => {
        const sortPower = a.power - b.power;
        return SortOrder.ASC === sort.order ? sortPower : -sortPower;
      });

      const noStaminaArr =
        SortOrder.ASC === sort.order
          ? noStaminaArrInit.concat(noStaminaArrUpgrade)
          : noStaminaArrUpgrade.concat(noStaminaArrInit);

      return SortOrder.ASC === sort.order
        ? noStaminaArr.concat(staminaArr)
        : staminaArr.concat(noStaminaArr);
    } else {
      return finalResults;
    }
  }
  return [];
};
