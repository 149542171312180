import React, { CSSProperties } from "react";
import {
  PartInfo,
  PetAttr,
  PetsElement,
} from "src/components/NFTCard/PetCard/type";
import MockPetsStand from "src/components/NFTCard/PetStand/MockPetsStand";
import styled from "styled-components";
import di_2 from "src/images/di_2.png";
import { petsCardElementArr } from "src/components/NFTCard/PetCard/const";
import powerPng from "src/images/power.svg";
import {
  PetGeneBorder,
  PetGeneColor,
  PetTextColor,
} from "src/components/Svg/PetsIcon/const";
import PetParts from "src/components/NFTCard/PetCard/PetPartConfig";
import { useTranslation } from "react-i18next";
import PetsIcon from "src/components/Svg/PetsIcon";
import PetLockIcon from "src/components/Svg/PetsIcon/PetLockIcon";

interface Props {
  parts: Record<keyof PetAttr, PartInfo[]>;
  element: PetsElement;
  id: number;
  power: number;
}

function PetsMockDetail({ parts, element, id, power }: Props) {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledPetsInfo>
        <MockPetsStand element={element} parts={parts} id={id} />
        <StyledPetsPowerInfo>
          <img src={petsCardElementArr[element]} alt="" />
          <div className="display-flex flex-between ff-black text-outline-07 align-center">
            <img src={powerPng} alt="" />
            <span>{power}</span>
          </div>
        </StyledPetsPowerInfo>
      </StyledPetsInfo>
      <StyledPetsPartsGenes>
        {Object.keys(parts).map((ele, index) => {
          const { grade, key } = parts[ele as keyof PetAttr][0];
          return (
            <StyledPetsPartItem key={index}>
              <PetsIcon
                boxStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                lock={false}
                grade={grade}
                prop={ele as keyof PetAttr}
              />
              <StyledGenesDetailItem key={index}>
                <StyledGenesItemLeft>
                  <span
                    style={{
                      color: PetTextColor[grade - 1],
                    }}
                  >
                    {`${t(PetParts[ele as keyof PetAttr][key])}`}
                  </span>
                </StyledGenesItemLeft>
                {index >= 4 && (
                  <StyledGenesItemRight>
                    <PetLockIcon style={{ height: 16, width: 16 }} />
                  </StyledGenesItemRight>
                )}
              </StyledGenesDetailItem>
            </StyledPetsPartItem>
          );
        })}
      </StyledPetsPartsGenes>
    </StyledContainer>
  );
}

export default PetsMockDetail;

const StyledGenesItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 0 !important;
  margin-right: -6px;
`;

const StyledPetsInfo = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
  `}
`;

const StyledPetsPartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 24px;
    margin-right: 6px;
  }
`;

const StyledGenesItemLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 0 !important;
`;

const StyledGenesDetailItem = styled.div`
  width: 100%;
  height: 24px;
  border-radius: 12px;
  background: #fff8e2;
  border: 1px solid #707070;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const StyledPetsPartsGenes = styled.div`
  padding: 0.8rem;
  box-sizing: border-box;
  display: flex;
  gap: 0.5rem;
  height: 100%;
  overflow: auto;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0;
    flex: 1;
  `}
`;

const StyledPetsPowerInfo = styled.div`
  width: 100%;
  height: 2rem;
  padding: 0.3rem 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fedb8d;

  img {
    height: 100%;
  }

  > div {
    height: 100%;
  }

  span {
    margin-left: 4px;
    white-space: nowrap;
    font-size: 1.3rem;
    font-weight: 500;
    color: ${(props) => props.theme.color.grey[100]};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 90%;
    border-radius: 1rem;
    justify-content: center;

    >img:first-child {
      height: 1.6rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      font-size: 1.6rem;
    }
  `}
`;

const StyledContainer = styled.div`
  background: url(${di_2});
  box-sizing: border-box;
  border-radius: 1rem;
  user-select: none;
  position: relative;
  width: 226px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    padding: 0.6rem;
    display: flex;
    gap: 1rem;
  `}
`;
