import { useCallback, useMemo } from "react";
import { ethers, BigNumber } from "ethers";

import useAutoFreshRequest from "./useAutoFreshRequest";
import useTiny from "./useTiny";
import useLimitedInterval from "./useLimitedInterval";
import useLPFarmUserInfo from "./useLPFarmUserInfo";
import useLPFarmPendingReward from "./useLPFarmPendingReward";

import { getYieldFarmingContract, getMulticallContract } from "../tiny/utils";
import { bn0 } from "src/utils";
import { CallItem } from "src/utils/multicall";

import type { StakedValue } from "src/contexts/Farms/types";
import type { Farm } from "src/contexts/Farms/types";

const getStakedAbi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_pid",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "stakedWantTokens",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_pid",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "pendingReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

function useAllStakedValue(
  farms: Farm[]
): [StakedValue[] | undefined, () => void] {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const yieldFarmingContract = getYieldFarmingContract(tiny);

  const multicallContract = getMulticallContract(tiny);

  const [lpFarmUserInfo] = useLPFarmUserInfo();
  const [lpFarmPendingReward] = useLPFarmPendingReward();

  const fetchAllStakedValue = useCallback(
    async (account: string | undefined) => {
      if (!account) return;
      // console.log("farms", farms);
      const yieldFarms = farms.filter((ele) => ele.pid > -1);
      const calls: CallItem[] = yieldFarms.reduce<CallItem[]>((pre, farm) => {
        pre.push({
          address: yieldFarmingContract.address,
          name: "stakedWantTokens",
          params: [farm.pid, account],
        });
        pre.push({
          address: yieldFarmingContract.address,
          name: "pendingReward",
          params: [farm.pid, account],
        });
        return pre;
      }, []);

      const itf = new ethers.utils.Interface(getStakedAbi);

      const calldata = calls.map((call) => [
        call.address.toLowerCase(),
        itf.encodeFunctionData(call.name, call.params),
      ]);

      const { returnData }: { returnData: string[] } =
        await multicallContract.aggregate(calldata);

      return yieldFarms.map((farm, index) => {
        return {
          pid: farm.pid,
          stakedBalance: BigNumber.from(returnData[index * 2]),
          pendingReward: BigNumber.from(returnData[index * 2 + 1]),
        };
      });
    },
    [farms, yieldFarmingContract, multicallContract]
  );

  const [yieldFarmingBalances, refresh] = useAutoFreshRequest(
    fetchAllStakedValue,
    [account]
  );
  // console.log("yieldFarmingBalances", yieldFarmingBalances);
  const startRefresh = useLimitedInterval(refresh);

  // 最后把 LPFarm 和 yieldFarming 的组合一下
  const balances = useMemo(() => {
    const lpFarmStakedValue = {
      pid: -1,
      stakedBalance: lpFarmUserInfo?.amount ?? bn0,
      pendingReward: lpFarmPendingReward ?? bn0,
    };
    if (yieldFarmingBalances) {
      return [lpFarmStakedValue].concat(yieldFarmingBalances);
    }
    return [lpFarmStakedValue];
  }, [lpFarmUserInfo, yieldFarmingBalances, lpFarmPendingReward]);

  // console.log("balances", balances);

  return [balances, startRefresh];
}

export default useAllStakedValue;
