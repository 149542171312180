import { useCallback } from "react";
import useAutoFreshRequest from "./useAutoFreshRequest";
import useTiny from "./useTiny";
import useLimitedInterval from "./useLimitedInterval";

import { bn0 } from "src/utils";
import type { BigNumber } from "ethers";

// const cache = {
//   lpStaked: bn0
// }

// 1 是之前的符石，2 是新的活动符石，3 是体力道具
function useMyRuneBalance(type: 1 | 2 | 3): [BigNumber, () => void] {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const runeContract = tiny.contracts.TinyNFTRune;
  const runev2Contract = tiny.contracts.TinyRuneNFTV2;
  // const [cacheData, setCache] = useDataCache("LP_FARM_STAKED", sessionStorage)

  const fetchRuneBalance = useCallback(async () => {
    if (!account) return bn0;
    if (type === 1) {
      return runeContract.balanceOf(account, 1);
    } else if (type === 2) {
      return runev2Contract.balanceOf(account);
    } else if (type === 3) {
      return runeContract.balanceOf(account, 2);
    }
  }, [runeContract, runev2Contract, type, account]);

  const [myRuneBalance, refresh] = useAutoFreshRequest(fetchRuneBalance, [], {
    initState: bn0,
  });

  const startRefresh = useLimitedInterval(refresh);

  return [myRuneBalance, startRefresh];
}

export default useMyRuneBalance;
