import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useIsMobile from "src/hooks/useIsMobile";
import useNFTApprove, { ApprovalState } from "src/hooks/useNFTApprove";

import { Context } from "src/contexts/TinyProvider";

import Modal, { ModalProps } from "src/components/Modal";
import ModalActions from "src/components/ModalActions";
import PrimaryButton from "../Button/PrimaryButton";
import Popover from "../CommonPopover";
import QuestionIcon from "../QuestionIcon";
import HeroStaminaRange from "./HeroStaminaRange";
import { IHeroHead } from "../HeadHeroCard/type";

import { gradeDurability, SPIRIT_CLOSE } from "src/config";
import RecoverIcon from "../Svg/RecoverIcon";
import useMyRuneBalance from "src/hooks/useMyRuneBalance";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import { propsArr, ReplenishContext } from "./const";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";
import Tooltip, { BaseAttributes } from "../Tooltip";
import ExternalModal from "../ExternalModal";
import SpiritShoppingCartModal from "../SpiritShoppingCartModal";
import { RuneAuction } from "src/services/auctions";
import { BigNumber } from "ethers";
import {
  addAction,
  clearAction,
  deleteAction,
  spiritReducer,
} from "src/reducer/shopping";
import useUserWallet from "src/hooks/useUserWallet";

interface Props extends ModalProps {
  heros: IHeroHead[];
  onHandlerDismiss?: () => void;
  disableScale?: boolean;
  setCheckHero?: React.Dispatch<React.SetStateAction<IHeroHead[]>>;
}

export interface IRangeConfig {
  step: number;
  min: number;
  max: number;
  values: number[];
  propAmount: number;
  isMax: boolean;
  loaded: boolean;
}

const ReplenishModal: React.FC<Props> = ({
  heros,
  onDismiss,
  onHandlerDismiss,
  disableScale,
  setCheckHero,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { tiny, userInfo } = useContext(Context);
  const [showTitleTip, setShowTitleTip] = useState(false);

  const [spiritData, SpiritDispatch] = useReducer(spiritReducer, { list: [] });
  const spiritList = spiritData.list;
  const spiritShoppingList = spiritList;
  const clearSpiritList = useCallback(() => SpiritDispatch(clearAction()), []);
  const addToSpiritList = (detail: RuneAuction) => {
    let list = spiritList;

    if (list.findIndex((ele) => ele.id === detail.id) === -1) {
      SpiritDispatch(addAction(detail));
    }
  };
  const deleteFromSpiritList = (id: number) => {
    SpiritDispatch(deleteAction(id));
  };

  const [rangeConfigs, setRangeConfigs] = useState<IRangeConfig[]>(() => {
    const configs: IRangeConfig[] = [];
    heros.map((hero, index) => {
      configs.push({
        step: 1,
        min: 0,
        max: gradeDurability[hero.gradeType],
        values: [0],
        propAmount: 0,
        isMax: false,
        loaded: false,
      });
    });
    return configs;
  });
  const [isHover, setIsHover] = useState(false);
  const [staminaItemNum, startRefresh] = useMyRuneBalance(3);
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  const { lockSpiritBalance, refetch } = useUserWallet();

  const account = tiny.myAccount;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const configs: IRangeConfig[] = [];
    if (heros.length > 0) {
      heros.map((hero, index) => {
        configs.push({
          step: 1,
          min: 0,
          max: gradeDurability[hero.gradeType],
          values: [0],
          propAmount: 0,
          isMax: false,
          loaded: true,
        });
      });
      setRangeConfigs(configs);
    }
  }, []);

  const allPropAmount = useMemo(() => {
    let max = 0;
    rangeConfigs.map((config, index) => {
      max += config.propAmount * propsArr[heros[index].gradeType - 1];
    });
    return max;
  }, [heros, rangeConfigs]);

  const showlockSpiritAmount = useMemo(() => {
    if (lockSpiritBalance) {
      const lockSpiritAmount = lockSpiritBalance.toNumber();
      if (lockSpiritAmount > 0) {
        if (allPropAmount >= lockSpiritAmount) {
          return lockSpiritAmount;
        } else {
          return allPropAmount;
        }
      }
      return 0;
    }
    return 0;
  }, [allPropAmount, lockSpiritBalance]);

  useEffect(() => {
    if (allPropAmount < staminaItemNum.toNumber()) {
      setRangeConfigs((prevState) => {
        const newStateValue = [
          ...prevState.map((ele) => {
            ele.isMax = false;
            return ele;
          }),
        ];
        return [...newStateValue];
      });
    }
  }, [allPropAmount, staminaItemNum]);

  const ModalTitle = useMemo(() => {
    return (
      <StyledTitle>
        {t("Replenish Energy")}
        <Popover
          style={{
            position: "absolute",
            right: -10,
            top: "50%",
            height: isMobile ? 13 : "1.2rem",
            transform: "translate(100%, -50%)",
          }}
          visible={showTitleTip}
          placement={"bottom"}
          boxStyle={{
            minWidth: isMobile ? "18rem" : "15rem",
            marginTop: isMobile ? 0 : 3,
          }}
          triggerClose={() => {
            setShowTitleTip(false);
          }}
          diy={isMobile ? "auto" : "auto"}
          handleClick={() => setShowTitleTip(true)}
          content={<Tooltip type={BaseAttributes.Spirit} />}
        >
          <QuestionIcon onClick={() => setShowTitleTip(true)} />
        </Popover>
      </StyledTitle>
    );
  }, [isMobile, showTitleTip, t]);

  const replenishStaimina = useCallback(() => {
    if (rangeConfigs.length === 1) {
      const id = heros[0].id;
      const amount = rangeConfigs[0].propAmount / rangeConfigs[0].step;
      handleTransactionReceipt(
        tiny.addStamina(id, amount),
        t("Replenish Energy"),
        [id, amount].toString()
      )
        .then((tx) => {
          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              startRefresh();
              refetch();
              onHandlerDismiss ? onHandlerDismiss() : onDismiss && onDismiss();
            });
          }
        })
        .catch((err) => {
          console.error("handle add stamina err:", err);
        });
    } else {
      // 多个英雄的恢复

      let ids: number[] = [];
      let amounts: number[] = [];

      heros.map((ele, index) => {
        if (rangeConfigs[index].values[0] > 0) {
          ids.push(ele.id);
          amounts.push(
            rangeConfigs[index].propAmount / rangeConfigs[index].step
          );
        }
      });

      handleTransactionReceipt(
        tiny.addStaminaBatch(ids, amounts),
        t("Replenish Energy"),
        [ids, amounts].toString()
      )
        .then((tx) => {
          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              onDismiss && onDismiss();
              // setCheckHero && setCheckHero([]);
              setTimeout(() => {
                setCheckHero && setCheckHero([]);
              }, 300);
            });
          }
        })
        .catch((err) => {
          console.error("handle adds power err:", err);
        });
    }
  }, [
    handleTransactionReceipt,
    handleTxConfirm,
    heros,
    onDismiss,
    onHandlerDismiss,
    rangeConfigs,
    refetch,
    setCheckHero,
    startRefresh,
    t,
    tiny,
  ]);

  const [inBuying, setInBuying] = useState(false);
  const handleBuy = useCallback(
    async (shoppingList: RuneAuction[], onDismiss: () => void) => {
      const ids: number[] = [];
      const prices: BigNumber[] = [];
      shoppingList.forEach((ele) => {
        ids.push(ele.id);
        prices.push(BigNumber.from(ele.referencePrice).mul(1e12));
      });

      const referral = userInfo?.inviter;
      const ts = userInfo?.invitationTime;
      handleTransactionReceipt(
        ids.length === 1
          ? tiny.runeBid(ids[0], prices[0], referral, ts)
          : tiny.runeBidBatch(ids, prices, referral, ts),
        t("Buy NFT"),
        [ids, prices.toString(), referral, ts].toString()
      )
        .then((tx) => {
          setInBuying(true);

          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              clearSpiritList();
              setVisible(false);
              onDismiss();
            });
          }
        })
        .finally(() => {
          setInBuying(false);
        });
    },
    [
      handleTransactionReceipt,
      handleTxConfirm,
      userInfo,
      clearSpiritList,
      tiny,
      t,
    ]
  );

  const canSubmit = useMemo(() => {
    return (
      !(
        rangeConfigs.filter((ele) => {
          return ele.values[0] !== 0;
        }).length > 0
      ) ||
      rangeConfigs.filter(
        (ele) => ele.isMax || ele.max === ele.min || !ele.loaded
      ).length > 0 ||
      !(staminaItemNum.toNumber() > 0 || !lockSpiritBalance?.isZero())
    );
  }, [lockSpiritBalance, rangeConfigs, staminaItemNum]);

  const tinyNFTStaminaAddress = tiny.contracts.TinyNFTStamina.address;

  const [isApproved, approveNFT] = useNFTApprove(
    tiny.contracts.TinyNFTRune,
    tinyNFTStaminaAddress
  );

  return (
    <ReplenishContext.Provider
      value={{
        setRangeConfigs,
        rangeConfigs,
      }}
    >
      <Modal
        title={ModalTitle}
        onDismiss={onDismiss}
        disableScale={disableScale}
        size="es"
        style={isMobile ? { minWidth: "100vw" } : { minWidth: 550 }}
      >
        <StyledContainer>
          {rangeConfigs.length > 0 &&
            heros.length > 0 &&
            heros.map((hero, index) => {
              return (
                <HeroStaminaRange
                  index={index}
                  key={index}
                  hero={hero}
                  allPropAmount={allPropAmount}
                  staminaItemNum={staminaItemNum}
                ></HeroStaminaRange>
              );
            })}
        </StyledContainer>

        <ModalActions
          style={{
            borderTop: "1px solid #E0D4B5",
            margin: "0",
            padding: "0 24px",
            height: "auto",
            paddingTop: "1.6rem",
          }}
        >
          <StyledPropsBox>
            {lockSpiritBalance && !lockSpiritBalance.isZero() && (
              <StyledPropsAmount
                isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
              >
                <StyledPropsAmountLeft>
                  <StyledIcon
                    className="mr-4"
                    src={"//images.tinyworlds.io/tokens/LOCKED-SPIRIT.png"}
                  />
                  <StyledPropsAmountNum
                    isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
                  >
                    {showlockSpiritAmount} /
                    {lockSpiritBalance ? lockSpiritBalance.toNumber() : 0}
                  </StyledPropsAmountNum>
                </StyledPropsAmountLeft>
              </StyledPropsAmount>
            )}
            <StyledPropsAmount
              isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
            >
              <StyledPropsAmountLeft>
                <StyledIcon
                  className="mr-4"
                  src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
                />
                <StyledPropsAmountNum
                  isMax={rangeConfigs.filter((ele) => ele.isMax).length > 0}
                >
                  {allPropAmount - showlockSpiritAmount} /
                  {staminaItemNum.toNumber()}
                </StyledPropsAmountNum>
              </StyledPropsAmountLeft>

              <StyledSvg
                onClick={() => {
                  setVisible(true);
                  // onDismiss && onDismiss();
                }}
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <RecoverIcon
                  style={{
                    width: isMobile ? 24 : 28,
                  }}
                  isHover={isHover}
                ></RecoverIcon>
              </StyledSvg>
            </StyledPropsAmount>
          </StyledPropsBox>

          {isApproved === ApprovalState.NOT_APPROVED ||
          isApproved === ApprovalState.PENDING ? (
            <PrimaryButton
              // size="sm"
              disabled={isApproved !== ApprovalState.NOT_APPROVED}
              onClick={() => {
                approveNFT();
              }}
            >
              {t("Approve")}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              style={{
                position: "unset",
              }}
              disabled={canSubmit || SPIRIT_CLOSE}
              onClick={replenishStaimina}
              width={isMobile ? "7rem" : "6rem"}
            >
              {t("Confirm")}
            </PrimaryButton>
          )}
        </ModalActions>
        {rangeConfigs.filter((ele) => ele.isMax).length > 0 && (
          <StyledNotes>
            <SttledError>{t("staminaMaxError")}</SttledError>
          </StyledNotes>
        )}

        {visible && userInfo && account && (
          <ExternalModal
            onClose={() => setVisible(false)}
            style={{ zIndex: 101 }}
          >
            {({ handleClose }) => (
              <StyledSpiritShoppingCartContent
                onDismiss={handleClose}
                handleBuy={handleBuy}
                userInfo={userInfo}
                account={account}
                inBuying={inBuying}
                spiritShoppingList={spiritShoppingList}
                addToSpiritList={addToSpiritList}
                deleteFromSpiritList={deleteFromSpiritList}
                clearSpiritList={clearSpiritList}
              />
            )}
          </ExternalModal>
        )}
      </Modal>
    </ReplenishContext.Provider>
  );
};

export default memo(ReplenishModal);

const StyledPropsAmountLeft = styled.div`
  display: flex;
  align-items: center;
`;

const SttledError = styled.div`
  width: 100%;
  text-align: center;
  color: #d10000;
`;

const StyledPropsAmountNum = styled.div<{ isMax: boolean }>`
  white-space: nowrap;
  color: ${({ isMax }) => (isMax ? "#D10000" : "#514B45")};
`;

const StyledSvg = styled.div`
  margin-left: 10px;
  transform: translate(0px, -1px);

  svg {
    display: flex;
    filter: drop-shadow(0px 2px 0px #5e3e27);
  }

  :active {
    transform: translateY(2px);

    svg {
      filter: drop-shadow(0px 0px 0px #5e3e27);
    }
  }
`;

const StyledPropsAmount = styled.div<{ isMax: boolean }>`
  height: 2.6rem;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 1.3rem;
  background: #fffbdd;
  border: 1px solid ${({ isMax }) => (isMax ? "red" : "#2377b7")};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 14rem;
  `}
`;

const StyledIcon = styled.img`
  height: 30px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 20px;
  `}
`;

const StyledPropsBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  min-width: 13rem;
  margin-left: 10px;
`;

const StyledContainer = styled.div`
  width: 100%;
  max-height: 23.06rem;
  box-sizing: border-box;
  overflow-y: scroll;
  background: none;

  > div + div {
    border-top: 1px solid rgb(224, 212, 181);
  }

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    background: none;
    padding: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fee397;
    border-radius: 5px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-height: 9rem;
  `}
`;

const StyledTitle = styled.div`
  position: relative;
  z-index: 9;
`;

const StyledNotes = styled.div`
  display: flex;
  padding: 0 9px;
  margin: 8px 0 -10px;
  font-size: 12px;
  color: #696969;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin: 8px auto;
      padding: 0 12px;
    `}
`;

const StyledSpiritShoppingCartContent = styled(SpiritShoppingCartModal)`
  min-width: 25rem;
  ${(props) => props.theme.mediaWidth.upToSmall`
    width: 27rem;
  `}
`;
