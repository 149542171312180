import { FC, useContext, useEffect, useLayoutEffect, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import useIsMobile from "src/hooks/useIsMobile";
import { Context as GuideContext } from "src/views/Guide";
import { Context } from "src/contexts/TinyProvider";
import GameSignModal from "src/views/PageComponents/Topbar/components/GameSignModal";

import metamask from "src/images/guide/metamask.png";
import huobi from "src/images/bourse/Hotbit.png";
import obtainHero from "src/images/guide/obtain_tiny_hero.png";
import obtainHeroPre from "src/images/guide/obtain_tiny_hero_pre.png";
import self from "src/images/upgrade/self.png";
import group from "src/images/upgrade/group.png";
import liquidity from "src/images/guide/liquidity.png";
import liquidityPre from "src/images/guide/liquidity_pre.png";
// import caster from "src/images/guide/caster.png";
// import archer from "src/images/guide/archer.png";
import tinyKingdom from "src/images/logos/logo_k.png";
import gameHover from "src/images/game-direct/hover.png";
import play from "src/images/game-direct/play.png";
import playActive from "src/images/game-direct/play_select.png";
import PancakeSwap from "src/images/partner/PancakeSwap.png";
import TokenPocket from "src/images/logos/TokenPocket.png";
import pointer from "src/images/guide/pointer.png";
import pointerCur from "src/images/guide/pointerCur.png";
import tinyDaoLeft from "src/images/guide/tinyDaoLeft.png";
import tinyDaoLeftPre from "src/images/guide/tinyDaoLeftPre.png";
import ReferralSystemLeft from "src/images/guide/ReferralSystemLeft.png";
import ReferralSystemLeftPre from "src/images/guide/ReferralSystemLeftPre.png";

import {
  StyledDetailLink,
  StyledDetailNote,
  StyledDetailItem,
  StyledDetailTitle,
  StyledGuideDetail,
  StyledDetailStep,
  StyledDetailLogo,
  StyledGameDirect,
  StyledGameButton,
  StyledPlayGameBox,
  StyledDetailAccount,
  StyledCreateMatamaskImgBox,
  imgBoxShadow,
  StyledPointerImg,
  StyledGuideLink,
  StyledPrefilledImage,
} from "./GuideDetailStyled";
import useModal from "src/hooks/useModal";
import LPStakeRuleModal from "src/components/LPStakeRuleModal";
import { useTranslation } from "react-i18next";
import useCheckAuth from "src/hooks/useCheckAuth";

interface IGuideStepProps {
  showAnime: boolean;
}

const CreateMetamask = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && (
        <StyledDetailTitle style={{ marginTop: isMobile ? 0 : 24 }}>
          {t("Extention")}
        </StyledDetailTitle>
      )}
      <StyledDetailItem style={{ flexWrap: "wrap", paddingRight: 0 }}>
        <StyledCreateMatamaskImgBox
          style={isMobile ? { background: "none" } : {}}
        >
          <a
            href={
              isMobile ? "https://www.tokenpocket.pro/" : "https://metamask.io/"
            }
            target={"_blank"}
          >
            <img
              loading="lazy"
              src={isMobile ? TokenPocket : metamask}
              alt=""
            />
          </a>
        </StyledCreateMatamaskImgBox>
        <StyledDetailLink>
          {isMobile ? "On iOS & Android" : "On Chrome"}
        </StyledDetailLink>
      </StyledDetailItem>
      <StyledDetailNote>
        <span style={{ lineHeight: "22px", fontWeight: 600 }}>* </span>
        {isMobile ? t("create Wallet supportM") : t("create Wallet support")}
      </StyledDetailNote>
    </>
  );
};

const BuyTinc = () => {
  const { tiny, logout } = useContext(Context);
  const account = tiny.myAccount;
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <>
      <StyledDetailStep> {t("Buy TINC1")}:</StyledDetailStep>
      <StyledDetailAccount>
        0x05aD6E30A8ffBE07AfA57e08a4f30d00810a402e
      </StyledDetailAccount>
      <StyledDetailItem
        style={{ marginTop: isMobile ? 20 : 90, flexWrap: "wrap" }}
      >
        <a
          href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x05aD6E30A855BE07AfA57e08a4f30d00810a402e"
          target={"_blank"}
          style={{ display: "contents" }}
          rel="noreferrer"
        >
          <StyledDetailLogo
            style={{ margin: "0", marginBottom: 6 }}
            src={PancakeSwap}
            alt=""
            loading="lazy"
          />
        </a>
        {t("Buy TINC2")}
      </StyledDetailItem>
    </>
  );
};

const ObtainTinyHero = () => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailStep>{t("step")}1:</StyledDetailStep>
      <StyledDetailItem style={{}}>
        <div>
          {i18n.language === "zh" ? (
            <>
              {t("with 100 TINC")}
              {t("buy")}
              <StyledGuideLink
                family={i18n.language === "zh"}
                // style={{ color: "#9D6C08" }}
                to="/mysterybox"
              >
                {t("Summon Rune")}
              </StyledGuideLink>{" "}
            </>
          ) : (
            <>
              {t("buy")}{" "}
              <StyledGuideLink
                family={i18n.language === "zh"}
                // style={{ color: "#9D6C08" }}
                to="/mysterybox"
              >
                {t("Summon Rune")}
              </StyledGuideLink>{" "}
              {t("with 100 TINC")}
            </>
          )}
        </div>
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{ width: isMobile ? "100%" : "80%" },
          minHeight: 200,
        }}
        src={obtainHero}
        preSrc={obtainHeroPre}
        alt=""
      />
      <StyledDetailStep style={{ marginTop: 10 }}>
        {t("step")}2:
      </StyledDetailStep>
      <StyledDetailItem style={{}}>
        {t("Summon Tiny Hero with rune")}
      </StyledDetailItem>
    </>
  );
};

const UpgradeTinyHero = () => {
  const { t, i18n } = useTranslation();

  const StyledDetailItemStyle: CSSProperties = {
    marginBottom: 6,
    justifyContent: "flex-start",
    paddingRight: 12,
    fontSize: 13,
  };
  return (
    <>
      <StyledDetailItem style={StyledDetailItemStyle}>
        <span>1.</span>
        {t("Upgrade Heroes will increase both mining power and game stats.")}
      </StyledDetailItem>
      <StyledDetailItem style={StyledDetailItemStyle}>
        <span>2.</span>
        {t(
          "Different class has different level cap. N,R,SR-Max lv8,SSR,SSSR-lv20"
        )}
      </StyledDetailItem>
      <StyledDetailItem style={StyledDetailItemStyle}>
        <span>3.</span>
        {t("Consume other Tiny Hero NFTs to upgrade your NFT.")}
      </StyledDetailItem>
      <StyledDetailItem style={StyledDetailItemStyle}>
        <span>4.</span>
        <div>
          {i18n.language === "zh" ? (
            <>
              {t("in collection.")}
              {t("Check")}
              <StyledGuideLink
                family={i18n.language === "zh"}
                style={{ textDecoration: "underline", color: "#9D6C08" }}
                to="/mynfts/upgrade/help"
              >
                {t("Upgrade Requirement")}
              </StyledGuideLink>
            </>
          ) : (
            <>
              {t("Check")}&nbsp;
              <StyledGuideLink
                family={i18n.language === "zh"}
                style={{ textDecoration: "underline", color: "#9D6C08" }}
                to="/mynfts/upgrade/help"
              >
                {t("Upgrade Requirement")}
              </StyledGuideLink>
              &nbsp;{t("in collection.")}
            </>
          )}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={StyledDetailItemStyle}>
        <span>5.</span>
        {t("Use Quickbuy to make your upgrade easier!")}
      </StyledDetailItem>
      <StyledDetailItem style={StyledDetailItemStyle}>
        <span>6.</span>
        {t(
          "There are some special levels requiring specific upgrading materials as follows"
        )}
      </StyledDetailItem>
      <StyledDetailNote
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          width: "92%",
          borderTop: 0,
        }}
      >
        <img
          loading="lazy"
          style={{ ...{ height: 24, marginRight: 8 } }}
          src={self}
        />

        {t("upgradeHelpTip1")}
      </StyledDetailNote>
      <StyledDetailNote
        style={{
          marginTop: -6,
          display: "flex",
          alignItems: "center",
          width: "92%",
          borderTop: 0,
        }}
      >
        <img
          loading="lazy"
          style={{ height: 24, marginRight: 8 }}
          src={group}
        />
        {t("upgradeHelpTip2")}
      </StyledDetailNote>
    </>
  );
};

const PlayTinyFarm = () => {
  const [showHelp] = useModal(<LPStakeRuleModal />);
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>1.</span>
        <div>
          {t("Players can stake TINC-BNB lp from PancakeSwap to the")}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/tinyvault/LP"
          >
            {t("LP Boost")}
          </StyledGuideLink>
          {t("to mine TINC")}
        </div>
      </StyledDetailItem>
      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>2.</span>
        {t("Staking Tiny Hero NFT will boost farming")}
      </StyledDetailItem>
      <StyledDetailItem
        style={{ marginBottom: 12, justifyContent: "flex-start" }}
      >
        <span>3.</span>
        <div>
          {i18n.language === "zh" ? (
            <>
              {t(" which will increase by upgrading.")}
              <span
                onClick={showHelp}
                style={{
                  textDecoration: "underline",
                  color: "#9D6C08",
                  fontFamily: "unset",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {t("Boost Effect")}
              </span>
              {t(" depends on the power of boost NFT")}
            </>
          ) : (
            <>
              <span
                onClick={showHelp}
                style={{
                  textDecoration: "underline",
                  color: "#9D6C08",
                  fontFamily: "guideLink",
                  cursor: "pointer",
                }}
              >
                {t("Boost Effect")}
              </span>
              {t(" depends on the power of boost NFT")}
              {t(" which will increase by upgrading.")}
            </>
          )}
        </div>
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{ width: "90%", marginTop: 10 },
          ...imgBoxShadow,
          minHeight: 180,
        }}
        src={liquidity}
        preSrc={liquidityPre}
        alt=""
      />
    </>
  );
};

const PlayTinyKingdom = () => {
  const [isClickDown, setIsClickDown] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();
  const { checkAuth } = useCheckAuth();

  const [pointerImg, setPointerImg] = useState(pointer);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pointerImg === pointer) {
        setPointerImg(pointerCur);
      } else {
        setPointerImg(pointer);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [pointer, pointerCur, pointerImg, setPointerImg]);

  const [showGameSignModal] = useModal(<GameSignModal />, [], {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  });

  return (
    <>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>1.</span>
        <div>
          {t(
            "Tiny Kingdom is a blockchain game universe with a combination of NFT and Gaming"
          )}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>2.</span>
        <div>
          {t("Players can ")}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/game/delivery"
          >
            {t("Transfer")}
          </StyledGuideLink>
          {t("guideDetailFreeHero")}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>3.</span>
        <div>
          {t("Players can 2")}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/game/dashboard?isCharge=ture"
          >
            {t("Deposit or Withdraw")}
          </StyledGuideLink>
          {t(" BUSD/TINC asset In Tiny Kingdom to purchase in game")}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>4.</span>
        <div>
          {i18n.language === "zh" ? (
            <>
              {t(" easier.")}
              <span
                onClick={() => checkAuth(() => showGameSignModal())}
                style={{
                  textDecoration: "underline",
                  color: "#9D6C08",
                  fontFamily: "unset",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {t("Daily check-in")}
              </span>
              {/* <StyledGuideLink
                family={i18n.language === "zh"}
                style={{ textDecoration: "underline", color: "#9D6C08" }}
                to="/game/dashboard"
              >
                {t("Daily check-in")}
              </StyledGuideLink> */}
              {t(
                " will give you rich crystal rewards which can make your journey"
              )}
            </>
          ) : (
            <>
              {/* <StyledGuideLink
                family={i18n.language === "zh"}
                style={{ textDecoration: "underline", color: "#9D6C08" }}
                to="/game/dashboard"
              >
                {t("Daily check-in")}
              </StyledGuideLink> */}
              <span
                onClick={() => checkAuth(() => showGameSignModal())}
                style={{
                  textDecoration: "underline",
                  color: "#9D6C08",
                  fontFamily: "unset",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {t("Daily check-in")}
              </span>
              {t(
                " will give you rich crystal rewards which can make your journey"
              )}
              {t(" easier.")}
            </>
          )}
        </div>
      </StyledDetailItem>
      <StyledPlayGameBox>
        {/* <img height={100} src={caster} alt="" /> */}
        <StyledGuideLink
          family={i18n.language === "zh"}
          style={isMobile ? { zoom: 0.7 } : {}}
          to="/game"
        >
          <StyledGameDirect
            // data-tip
            // data-for="gameDirect"
            onMouseDown={() => {
              setIsClickDown(true);
              setIsHover(false);
            }}
            onMouseUp={() => {
              setIsClickDown(false);
              setIsHover(false);
            }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <img
              className="tiny-kingdom"
              src={tinyKingdom}
              height={82}
              alt="tiny kingdom"
              loading="lazy"
            />
            <div
              className="position-relative"
              style={{ width: 135, marginLeft: -35 }}
            >
              <StyledGameButton
                loading="lazy"
                className="play"
                src={isHover ? gameHover : isClickDown ? playActive : play}
                height={61}
                alt="tiny kingdom"
              />
            </div>
            <StyledPointerImg loading="lazy" src={pointerImg} alt="" />
          </StyledGameDirect>
        </StyledGuideLink>
        {/* <img height={100} src={archer} alt="" /> */}
      </StyledPlayGameBox>
    </>
  );
};

const TinyDao = () => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>1.</span>
        <div>
          {t(
            "Most of the profits across the Tiny World platform will be directed into the "
          )}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/dao"
          >
            {t("dao")}
          </StyledGuideLink>
          {t(" Treasury")}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>2.</span>
        <div>
          {t("Players can 3")}
          <StyledGuideLink
            family={i18n.language === "zh"}
            style={{ textDecoration: "underline", color: "#9D6C08" }}
            to="/dao?isLock=true"
          >
            {t("Lock up")}
          </StyledGuideLink>
          {t(
            " TINC to obtain veTINC, which is the voting token for Tiny World."
          )}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>3.</span>
        <div>
          {t(
            "veTINC gives user treasury share rights, governance rights and unlocks Title system of Tiny Kingdom."
          )}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>4.</span>
        <div>
          {t(
            "Treasury Rewards will be released in real time according to the share of veTINC held by users in TinyDAO."
          )}
        </div>
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{ width: isMobile ? "100%" : "100%", minHeight: 120 },
        }}
        src={tinyDaoLeft}
        preSrc={tinyDaoLeftPre}
        alt=""
      />
    </>
  );
};

const ReferralSystem = () => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  return (
    <>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>1.</span>
        <div>
          {i18n.languages.includes("zh") ? (
            <>
              {t(
                "Players can invite their friends to register via the referral link and receive"
              )}
              <StyledGuideLink
                family={i18n.language === "zh"}
                style={{ textDecoration: "underline", color: "#9D6C08" }}
                to="/invitation/dashboard"
              >
                {t("Referral Program")}
              </StyledGuideLink>
              {t(" rewards from our ")}
            </>
          ) : (
            <>
              {t(
                "Players can invite their friends to register via the referral link and receive"
              )}
              {t(" rewards from our ")}
              <StyledGuideLink
                family={i18n.language === "zh"}
                style={{ textDecoration: "underline", color: "#9D6C08" }}
                to="/invitation/dashboard"
              >
                {t("Referral Program")}
              </StyledGuideLink>
            </>
          )}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>2.</span>
        <div>
          {t(
            "Referral rewards can be received from both Tiny Farm and Tiny Kingdom."
          )}
        </div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>3.</span>
        <div>{t("Each user can generate 1 unique referral link.")}</div>
      </StyledDetailItem>
      <StyledDetailItem style={{ justifyContent: "flex-start" }}>
        <span>4.</span>
        <div>
          {t(
            "Referral relationship will be established once the referee visits from the referral link and signs their signature."
          )}
        </div>
      </StyledDetailItem>
      <StyledPrefilledImage
        style={{
          ...{
            width: isMobile ? "100%" : "80%",
            marginTop: isMobile ? 12 : 4,
            minHeight: 130,
          },
          ...imgBoxShadow,
        }}
        src={ReferralSystemLeft}
        preSrc={ReferralSystemLeftPre}
        alt=""
      />
    </>
  );
};

const GuideDetailLeft: FC<IGuideStepProps> = ({ showAnime }) => {
  const isMobile = useIsMobile();
  const { select, setSelect } = useContext(GuideContext);

  const detailRender = () => {
    switch (select) {
      case 0:
        return <CreateMetamask></CreateMetamask>;
      case 1:
        return <BuyTinc></BuyTinc>;
      case 2:
        return <ObtainTinyHero></ObtainTinyHero>;
      case 3:
        return <UpgradeTinyHero></UpgradeTinyHero>;
      case 4:
        return <PlayTinyFarm></PlayTinyFarm>;
      case 5:
        return <PlayTinyKingdom></PlayTinyKingdom>;
      case 6:
        return <TinyDao></TinyDao>;
      case 7:
        return <ReferralSystem></ReferralSystem>;
      default:
        break;
    }
  };

  return (
    <StyledGuideDetail
      // && select !== 3
      style={select !== 5 ? {} : { overflowY: "unset" }}
    >
      {showAnime && detailRender()}
    </StyledGuideDetail>
  );
};

export default GuideDetailLeft;
