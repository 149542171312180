import styled from "styled-components";

import Modal, { ModalProps } from "src/components/Modal";
import useIsMobile from "src/hooks/useIsMobile";

import { BourseTop } from "./const";

// import deposit from "src/images/dao/deposit.png";
import Hotbit from "src/images/bourse/Hotbit.png";

export default function BourseModal({ onDismiss }: ModalProps) {
  const isMobile = useIsMobile();

  const ModalTitle = (
    <StyledContainerHeader>
      <StyledContainerHeaderImg
        src={"//images.tinyworlds.io/tokens/TINC.png"}
      ></StyledContainerHeaderImg>
      <span>Buy TINC</span>
    </StyledContainerHeader>
  );

  return (
    <Modal
      title={ModalTitle}
      onDismiss={onDismiss}
      size="es"
      style={
        isMobile
          ? {
              minWidth: "76vw",
              maxWidth: "92vw",
              width: "86vw",
              marginBottom: 50,
            }
          : { maxWidth: 430 }
      }
    >
      <StyledContainer>
        <StyledContainerBodyBox>
          <StyledBoxTop>Decentralized Exchanges</StyledBoxTop>
          <StyledBox>
            {BourseTop.map(({ href, target, text, src }) => {
              return (
                <StyledBoxItem key={text} href={href} target={target}>
                  <img width={60} height={60} src={src} alt={text} />
                  <span>{text}</span>
                </StyledBoxItem>
              );
            })}
          </StyledBox>
        </StyledContainerBodyBox>
        <StyledContainerBodyBox style={{ marginTop: 12 }}>
          <StyledBoxTop>Centralized Exchanges</StyledBoxTop>
          <StyledBox>
            <StyledBoxItem
              href="https://www.huobi.com/en-us/exchange/tinc_usdt/"
              target="_blank"
            >
              <img width={180} height={180} src={Hotbit} alt="Huobi Global" />
              <span>Huobi Global</span>
            </StyledBoxItem>
          </StyledBox>
        </StyledContainerBodyBox>
      </StyledContainer>
    </Modal>
  );
}
const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `}
`;
const StyledContainerHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  justify-content: center;

  span {
    white-space: nowrap;
  }
`;

const StyledContainerHeaderImg = styled.img`
  height: 60%;
  // position: absolute;
  left: -45px;
  margin-right: 8px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  margin-right: 8px;
  `};
`;

const StyledContainerBodyBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
  flex-wrap: wrap;
  // margin-bottom: 12px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: space-around;
  `};
`;

const StyledBoxTop = styled.div`
  font-size: 18px;
  width: 100%;
  text-align: center;
  line-height: 40px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
    line-height: 34px;
  `};
`;

const StyledBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: self-start;
  flex-wrap: wrap;
  gap: 24px;
  background: #fffbdd;
  padding: 12px;
  border-radius: 12px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: space-around;
  `};
`;

const StyledBoxItem = styled.a`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 48%;
  &:hover {
    color: #a26511;
    font-weight: 500;
  }

  > img {
    height: 60px;
    width: 60px;
    border-radius: 50%;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      height: 50px;
      width: 50px;
    `};
  }

  span {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    margin-top: 4px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      font-size: 16px;
      line-height: 18px;
    `};
  }
`;
