// import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useTiny from "src/hooks/useTiny";
import useIsMobile from "src/hooks/useIsMobile";

import Modal from "src/components/Modal";
// import ModalActions from "src/components/ModalActions";
// import ParallelogramButton from "src/components/Button/ParallelogramButton";
// import ClaimButton from "src/components/ClaimButton";
import { getDisplayBalance } from "src/utils/formatBalance";
import PrimaryButton from "src/components/Button/PrimaryButton";

import Question from "src/images/wenhao.png";
import runeIcon from "src/images/presale-market/rune-card.png";

import type { ModalProps } from "src/components/Modal";
import type { BigNumber } from "ethers";

interface Props extends ModalProps {
  total?: {
    tincAmount: BigNumber;
    runeValue: BigNumber;
  };
  fresh(): void;
  claimAirdrop(): void;
}
export default function AirdropModal({
  onDismiss,
  claimAirdrop,
  total,
  fresh,
}: Props) {
  const { t } = useTranslation();
  const tiny = useTiny();
  // const account = tiny.myAccount;
  const { tincAmount, runeValue } = total || {};
  // console.log('total', total);
  // console.log('tincAmount', tincAmount);

  const isMobile = useIsMobile();

  return (
    <Modal style={{ maxWidth: 450 }} onDismiss={onDismiss}>
      <StyledRow className="mb-30">
        <div className="display-flex align-center">
          <span className="rfs-17">{t("airDropTitle")}</span>
          <a
            className="ml-5"
            href="https://medium.com/@tinyworld.gamefi/the-tiny-world-airdrop-is-incoming-23be630d8d05"
            target="__blank"
          >
            <img src={Question} height={isMobile ? 28 : 35} alt="" />
          </a>
        </div>
      </StyledRow>
      <StyledContent>
        <StyledTotalRight>
          <img
            className="mr-5"
            src="//images.tinyworlds.io/tokens/TINC.png"
            height={isMobile ? 22 : 27}
            alt="TINC"
          />
          <StyledAmount>
            {tincAmount ? getDisplayBalance(tincAmount, 18, 1) : 0}
          </StyledAmount>
        </StyledTotalRight>
        <StyledTotalRight>
          <img
            className="mr-5"
            src={runeIcon}
            height={isMobile ? 28 : 35}
            alt="rune"
          />
          <StyledAmount>{runeValue ? runeValue.toNumber() : 0}</StyledAmount>
        </StyledTotalRight>
        <PrimaryButton
          onClick={claimAirdrop}
          className="mt-20 fw-600 rfs-11"
          size={isMobile ? "sm" : "md"}
          width={isMobile ? 75 : undefined}
        >
          {t("Claim")}
          {/* {unclaimed ? getDisplayBalance(unclaimed) : 0} */}
        </PrimaryButton>
      </StyledContent>
      <StyledDivider />
      <StyledTips>*{t("airDropTip")}</StyledTips>
      {/* <ModalActions>
        <ParallelogramButton onClick={onDismiss}>
          {t("Close")}
        </ParallelogramButton>
      </ModalActions> */}
    </Modal>
  );
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* padding: 24px 0; */
  min-height: 100px;
  border-radius: 9px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 15px;
  `}
`;

const StyledTotalRight = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 120px;
  font-size: 1.3rem;
`;

const StyledAmount = styled.span`
  height: 2.8rem;
  width: 7.8rem;
  border-radius: 11px;
  background-color: #fffbdd;
  padding: 11px 12px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  box-sizing: border-box;
`;

const StyledDivider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;

const StyledTips = styled.p`
  margin: 0.8rem 0 -0.5rem;
  color: #777;
  font-size: 0.8rem;
`;
