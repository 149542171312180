import React, { PropsWithChildren } from "react";
import PageContent from "src/components/PageContent";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import MockBreed from "./MockBreed";

interface Props {}

function Container({ children }: PropsWithChildren<Props>) {
  return (
    <PageContent
      style={{
        padding: 0,
      }}
      mpt
    >
      <SwitchTransition>
        <CSSTransition key={"pets/mock"} classNames="fade" timeout={500}>
          <MockBreed />
        </CSSTransition>
      </SwitchTransition>
    </PageContent>
  );
}

export default Container;
