import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { utils } from "ethers";

// import bannerImg from "../../images/home_banner.png";
import hero1 from "../../images/banner/hero_1.png";
import hero2 from "../../images/banner/hero_2.png";
import hero3 from "../../images/banner/hero_3.png";
import hero4 from "../../images/banner/hero_4.png";
import hero5 from "../../images/banner/hero_5.png";
import hero6 from "../../images/banner/hero_6.png";
import hero7 from "../../images/banner/hero_7.png";
import hero8 from "../../images/banner/hero_8.png";
import hero9 from "../../images/banner/hero_9.png";

import hero1Pre from "../../images/banner/hero_1_pre.png";
import hero2Pre from "../../images/banner/hero_2_pre.png";
import hero3Pre from "../../images/banner/hero_3_pre.png";
import hero4Pre from "../../images/banner/hero_4_pre.png";
import hero5Pre from "../../images/banner/hero_5_pre.png";
import hero6Pre from "../../images/banner/hero_6_pre.png";
import hero7Pre from "../../images/banner/hero_7_pre.png";
import hero8Pre from "../../images/banner/hero_8_pre.png";
import hero9Pre from "../../images/banner/hero_9_pre.png";

import bannerMobile from "../../images/banner/banner_s.png";
import bannerMobilePre from "../../images/banner/banner_s-pre.png";
import bannerMobileHero from "../../images/banner/banner_hero.png";
import bannerMobileHeroPre from "../../images/banner/banner_hero-pre.png";

import useAutoFreshRequest from "src/hooks/useAutoFreshRequest";
import {
  getAuctionDashboard,
  AuctionDashboard,
  getRuneAuctionDashboard,
  RuneAuctionDashboard,
} from "src/services/auctions";
import config from "src/config";
import { useRuneAuctionDashboard } from "src/hooks/runeAuctionHooks";

import FarmContext from "src/contexts/Farms/context";

import { commify } from "ethers/lib/utils";
import useTINCMarket from "src/hooks/useTINCMarket";

import button from "src/images/card/button.png";
import PrefilledImage from "src/components/Images/PrefilledImage";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";

import hot_h from "src/images/game/HOT_H.png";
import hot_o from "src/images/game/HOT_O.png";
import hot_t from "src/images/game/HOT_T.png";
import useDaoTotalLockedTINC from "src/hooks/useDaoTotalLockedTINC";
import { getDisplayBalance } from "src/utils/formatBalance";
import useIsMobile from "src/hooks/useIsMobile";
import { getPetsBidsDashboard } from "src/services/pets";

dayjs.extend(dayOfYear);

const chainId = config.chainId;

const heroImages = [
  { img: hero1, preImg: hero1Pre },
  { img: hero2, preImg: hero2Pre },
  { img: hero3, preImg: hero3Pre },
  { img: hero4, preImg: hero4Pre },
  { img: hero5, preImg: hero5Pre },
  { img: hero6, preImg: hero6Pre },
  { img: hero7, preImg: hero7Pre },
  { img: hero8, preImg: hero8Pre },
  { img: hero9, preImg: hero9Pre },
];

export default function BannerMobile() {
  const todayHero = heroImages[dayjs().dayOfYear() % 9];
  const history = useHistory();
  const isMobile = useIsMobile();

  const { t, i18n } = useTranslation();
  const isChinese = i18n.languages.includes("zh");

  const [overview] = useAutoFreshRequest<AuctionDashboard>(
    getAuctionDashboard,
    [],
    { interval: config.refreshInterval * 100 }
  );

  const { data: petMarketOverview } = useQuery(
    "PET_MARKET_OVERVIEW",
    ({ signal }) => {
      return getPetsBidsDashboard({ signal });
    },
    {
      refetchInterval: config.refreshInterval * 10,
    }
  );

  const { runeOverview, spiritOverview } = useRuneAuctionDashboard();

  const totalVolume = useMemo(() => {
    let sum = 0;
    // console.log('overview', overview);
    if (overview) {
      sum += Number.parseInt(overview.priceSum);
    }
    if (petMarketOverview) {
      sum += Number.parseInt(petMarketOverview.priceSum);
    }
    if (runeOverview && runeOverview.bidPriceSum) {
      sum += Number.parseInt(runeOverview.bidPriceSum);
    }
    if (spiritOverview && spiritOverview.bidPriceSum) {
      sum += Number.parseInt(spiritOverview.bidPriceSum);
    }
    if (chainId == 97) {
      return sum ? utils.commify((sum / 10).toFixed()) : "--";
    }
    return sum ? utils.commify((sum / 1e6).toFixed()) : "--";
  }, [overview, runeOverview, spiritOverview, petMarketOverview]);

  const { userCount } = useContext(FarmContext);

  const registeredUserNum = useMemo(() => {
    if (userCount > 0) {
      return commify(userCount);
    }
    return "--";
  }, [userCount]);

  const market = useTINCMarket();
  const { data: totalLockedValue, refetch } = useDaoTotalLockedTINC();

  const TINCMarket = [
    {
      title: `${isMobile ? (isChinese ? "TINC" : "TINC ") : ""}${t(
        "TINC Total Supply"
      )}`,
      value: market.totalSupply || "--",
    },
    {
      title: `${isMobile ? (isChinese ? "TINC" : "TINC in ") : ""}${t(
        "TINC In Circulation"
      )}`,
      value: market.supply || "--",
    },
    {
      title: `${isMobile && isChinese ? "TINC" : ""}${t("Total Locked")}${
        isMobile && !isChinese ? " TINC" : ""
      }`,
      value: totalLockedValue
        ? getDisplayBalance(totalLockedValue, 18, 0, true)
        : "--",
    },
    {
      title: `${isMobile && isChinese ? "TINC" : ""}${t("Total Burned")}${
        isMobile && !isChinese ? " TINC" : ""
      }`,
      value: market.burnBalance || "--",
    },
    /* {
      title: t("TINC MarketCap"),
      value: `$ ${market.marketCapStr || "--"}`,
    }, */
    {
      title: t("Treasury Fund"),
      value: `$ ${market.totalFund || "--"}`,
    },
  ];

  const [count, setCount] = useState(0);

  useEffect(() => {
    const calc = setInterval(() => {
      setCount(count + 1);
    }, 4000);

    return () => clearInterval(calc);
  }, [count]);

  return (
    <StyledContainer className="position-relative special-ff">
      <div style={{ height: 260, overflow: "hidden" }}>
        <StyledBannerBg
          imgStyle={{
            height: 260,
            objectFit: "cover",
            objectPosition: "10% 10%",
          }}
          width={430}
          height={316}
          src={bannerMobile}
          preSrc={bannerMobilePre}
          alt=""
        />
        <StyledBannerHero
          width={823}
          height={830}
          src={todayHero.img}
          preSrc={todayHero.preImg}
        />
      </div>
      <StyledBannerBtnBox>
        <StyledBannerButton
          // target="_blank"
          // href={'/catalogue'}
          style={{
            position: "relative",
          }}
          onClick={() => {
            history.push("/catalogue");
          }}
          img={button}
        >
          {/* mobile */}
          {t("guide")}
          {/* <Hot bottom={-3}></Hot> */}
        </StyledBannerButton>
        <StyledBannerButton
          // target="_blank"
          onClick={() => {
            history.push("/game");
          }}
          // href={t("Getting Started Link")}
          img={button}
        >
          {t("Getting Started")}
        </StyledBannerButton>
      </StyledBannerBtnBox>
      <StyledMobileInfoTop>
        <StyledMValueContainer>
          <StyledLabel style={{ color: "#FFC500", fontSize: 13 }}>
            {t("Registered Users")}
          </StyledLabel>
          <StyledValue
            style={{
              color: "#FFF",
              fontSize: 18,
              letterSpacing: -1.2,
            }}
            className="ff-black"
          >
            {registeredUserNum}
          </StyledValue>
        </StyledMValueContainer>
        <div
          style={{
            background: "#FFF",
            height: "100%",
            width: 1,
            position: "absolute",
            transform: "translate(-50%, 0)",
            left: "50%",
          }}
        ></div>
        <StyledMValueContainer style={{ position: "absolute", right: 0 }}>
          <StyledLabel style={{ color: "#FFC500", fontSize: 13 }}>
            {t("Total NFT Trading Volume")}
          </StyledLabel>
          <StyledValue
            style={{
              color: "#FFF",
              fontSize: 18,
              letterSpacing: -1.2,
            }}
            className="ff-black"
          >
            $ {totalVolume}
          </StyledValue>
        </StyledMValueContainer>
      </StyledMobileInfoTop>
      <StyledMovingTextContainer>
        <StyledMovingText isChinese={isChinese}>
          {TINCMarket.map((item) => (
            <StyledTINCItem key={item.title}>
              <span>{item.title}</span>
              <span className="ff-black pt-1">{item.value}</span>
            </StyledTINCItem>
          ))}
          <div style={{ width: 300 }}></div>
          <StyledTINCItem key={TINCMarket[0].title}>
            <span>{TINCMarket[0].title}</span>
            <span className="ff-black pt-1">{TINCMarket[0].value}</span>
          </StyledTINCItem>
        </StyledMovingText>
      </StyledMovingTextContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin: -10px 0 10px;
`;

const StyledBannerBg = styled(PrefilledImage)`
  width: 100%;
`;

const StyledBannerHero = styled(PrefilledImage)`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 260px;
  width: 257.8px;
  z-index: 2;
`;

const StyledMobileInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 21px;
  z-index: 9;
  width: 100%;
`;

const StyledMValueContainer = styled.div`
  width: 50%;
  min-width: 15vw;
  padding: 1% 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.05rem;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  justify-content: center;
`;

const StyledLabel = styled.div`
  color: white;
  font-size: 1.2rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1rem;
  `}
`;

const StyledMovingTextContainer = styled.div`
  position: relative;
  background-color: #e99648;
  height: 21px;
`;

const StyledMovingText = styled.div<{ isChinese: boolean }>`
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 20px;
  margin: 0;
  height: 22px;
  color: white;
  font-weight: 600;
  @media (any-hover: hover) {
    :hover {
      animation-play-state: paused;
    }
  }

  > div:last-child {
    position: absolute;
    right: 0;
    transform: translateX(100%);
  }

  ${({ theme, isChinese }) => theme.mediaWidth.upToExtraSmall`
    animation: ${isChinese ? 25 : 30}s rowLoop linear infinite normal;
  `}
`;

const StyledValue = styled.div`
  font-size: 1.8rem;
  color: #fccd48;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.5rem;
  `}
`;

const StyledTINCItem = styled.div`
  display: flex;
  color: #fff3ae;
  min-width: 160px;
  flex-shrink: 0;
  line-height: 22px;

  > span:first-child {
    font-size: 13px;
    margin-right: 8px;
  }

  > span:last-child {
    font-size: 16px;
    letter-spacing: -1px;
    font-weight: 400;
  }
`;

const StyledBannerBtnBox = styled.div`
  position: absolute;
  top: 47%;
  left: 15%;
  height: 60px;
  display: flex;
  align-content: space-between;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 104px;
  z-index: 3;
`;

const StyledBannerBtn = styled.a<{ img: string }>`
  height: 26px;
  width: 100%;
  background: url(${(props) => props.img}) no-repeat;
  background-size: 100% 100%;
  line-height: 24px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-shadow: #000 1px 1px 3px, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
`;

const StyledBannerButton = styled.button<{ img: string }>`
  height: 26px;
  width: 100%;
  background: url(${(props) => props.img}) no-repeat;
  background-size: 100% 100%;
  line-height: 24px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-shadow: #000 1px 1px 3px, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
`;
