import { BigNumber } from "ethers";

/**
 * NFT 升级的时候，根据等级计算 power
 * @param initPower
 * @param base_power_lower
 * @param base_power_upper
 * @param powerRange
 * @returns
 */
export default function calcPower(
  initPower: number,
  base_power_lower: number,
  base_power_upper: number,
  powerRange: {
    level: number;
    lower: number;
    upper: number;
  }
) {
  const gap = base_power_upper - base_power_lower;
  if (gap === 0) {
    return powerRange.lower;
  }

  const p = BigNumber.from(powerRange.lower).add(
    BigNumber.from(powerRange.upper - powerRange.lower)
      .mul(initPower - base_power_lower)
      .div(gap)
  );
  return p.toNumber();
}
