import { useContext, useMemo } from "react";
import { BigNumber, utils } from "ethers";
import {
  useTokenBatchBalance,
  BatchParamItem,
  useBatchTokenAndBatchBalance,
  useVenusTokenBalance,
} from "src/hooks/useTokenBatchBalance";
import { Context as FarmsContext } from "src/contexts/Farms";
import { bn0, bn1e18 } from "src/utils";
import useTiny from "./useTiny";
import { getBalance } from "src/utils/formatBalance";
import config, { chainId } from "src/config";
import useTokenBalance from "./useTokenBalance";
import useDaoTotalLockedTINC from "./useDaoTotalLockedTINC";
import useBNBBalance from "src/wallet/useBNBBalance";
import useFarmPrice from "./useFarmPrice";

const addresses = [
  "0x8B2a2D9dc7Bc31F4b15b3Bd9f319246E5F5a479A", // burnAddress
  "0xeEF160ED36FfDD33cB94D48B3E6B4b384D79844F", // supplierAddress
  "0xAB3352A6D42083dac743F5D25660E5D393997745", // airdrop address
  "0x94cd9C934e8f853c712cfD41Ffa10b71C38b5fb6", // supplier back address
  "0x1E82C43255423c448Ad6C05e206f0A57BbA90676",
  "0xF106396363F11c1c887DD3C19ee64529E100A5fA",
  "0xA108502ED4B76Ea867b8A95B2BD9920D81B370c1",
  "0x9C255715386d8e21BB00491580A151e1FA196259",
  "0x1897bc142572C36e204C17Acf306d22227907439",
  "0x5dFD1F21de8422E8507f9F1Cc4a01405e9916fA4",
  "0x000000000000000000000000000000000000dEaD", // results[10] deadAddress
  "0x2343dA4BdffB67B1396dFbA2194E6a9265F4eF90", // LordOnlineReward
];

const totalValue = BigNumber.from(1000000000).mul(bn1e18);

const specValue = BigNumber.from(100000000).mul(bn1e18);

const startBlock = 21467500;
const seasonReleaseEndBlock = 22010000;

const investR1 = BigNumber.from(1860834).mul(bn1e18); // 这个要作为已经释放完的
const investR2 = BigNumber.from(1985834).mul(bn1e18);
const investR3 = BigNumber.from(2110834).mul(bn1e18);
const investR4 = BigNumber.from(2921944).mul(bn1e18);
const restInvest = investR2.add(investR3).add(investR4);

export function useTINCInCirculation() {
  const tiny = useTiny();
  const TINC = tiny.externalTokens.TINC;

  const { data } = useTokenBatchBalance(TINC.address, addresses);

  // console.log('results', results);

  const burnBalance = useMemo(() => {
    const results = data?.results;
    return results ? results[0].add(results[10]) : bn0;
  }, [data]);

  // console.log('burnBalance', burnBalance);

  const supply = useMemo(() => {
    const results = data?.results;
    const blockNumber = data?.blockNumber.toNumber();

    const supplierBalance = results ? results[1] : bn0;
    const airRestValue = results ? results[2] : bn0;
    const backValue = results ? results[3] : bn0;
    const release1Value = results ? results[4] : bn0;
    const release2Value = results ? results[5] : bn0;
    const release3Value = results ? results[6] : bn0;
    // const release4Value = results ? results[7] : bn0;
    const release4Value = specValue;
    const hotwallet1Value = results ? results[8] : bn0;
    const hotwallet2Value = results ? results[9] : bn0;
    const lordRewardPoolValue = results ? results[11] : bn0;
    // 测试环境没有 supply
    if (chainId === 97) {
      return BigNumber.from(10000000).mul(bn1e18);
    }
    if (
      supplierBalance.isZero() ||
      airRestValue.isZero() ||
      burnBalance.isZero() ||
      !blockNumber
    ) {
      return bn0;
    }

    const investorRelease = BigNumber.from(
      Math.min(seasonReleaseEndBlock, blockNumber) - startBlock
    )
      .mul(restInvest)
      .div(seasonReleaseEndBlock - startBlock)
      .add(investR1);

    // console.log("investorRelease", investorRelease.div(bn1e18).toNumber());

    const supply = totalValue
      .sub(backValue)
      .sub(supplierBalance)
      .sub(airRestValue)
      .sub(release1Value)
      .sub(release2Value)
      .sub(release3Value)
      .sub(release4Value)
      .sub(hotwallet1Value)
      .sub(hotwallet2Value)
      .sub(lordRewardPoolValue)
      .sub(burnBalance)
      .add(investorRelease);
    return supply;
  }, [data, burnBalance]);

  return {
    supply,
    burnBalance,
  };
}

// Dao 使用的地址
const account =
  chainId == 56
    ? "0xCd8d9840685518aA0bC14928C5171A6E3100B779"
    : "0x202bCFb0B6F76017b592EF61F4D9Db32AeE8fBB6";

const buybackAddress =
  chainId == 56
    ? "0xe248F8c315c11f6EcAcD139b5CF8d975568d0114"
    : "0x145DaaD1EbB1Fff15c5975e801097679B18285b3";

const daoTokenList: BatchParamItem[] = [
  {
    account: account,
    token: config.externalTokens.BUSD,
  },
  {
    account: account,
    token: config.externalTokens.USDC,
  },
  {
    account: account,
    token: config.externalTokens.USDT,
  },
  {
    account: buybackAddress,
    token: config.externalTokens.BUSD,
  },
  {
    account: buybackAddress,
    token: config.externalTokens.USDC,
  },
  {
    account: account,
    token: config.externalTokens.BTCB,
  },
  {
    account: account,
    token: config.externalTokens.ETH,
  },
  // {
  //   account: account,
  //   token: config.externalTokens.BNB,
  // },
];

function useDaoAndBuybackTokenAmounts() {
  const { data: DaoAccountAssetData } = useBatchTokenAndBatchBalance(
    daoTokenList,
    { enabled: chainId === 56 }
  );

  // const  tiny.provider.getBalance(account);
  const [daoBNBBalance] = useBNBBalance(account);
  // console.log("daoBNBBalance", daoBNBBalance);

  // console.log(
  //   "DaoAccountAssetData",
  //   DaoAccountAssetData?.results.map((ele) => ele.div(1e12).toNumber() / 1e6)
  // );

  // const { data: accountVenusTokenAmountresults } = useVenusTokenBalance(account);
  // const accountVenusUSDC = accountVenusTokenAmountresults?.results[0] || bn0;

  // const accountPancakeV3USD = utils.parseEther("362503.68");

  const accountBusdBalance = DaoAccountAssetData?.results[0] || bn0;
  const accountUsdcBalance = DaoAccountAssetData?.results[1] || bn0;
  const accountUsdtBalance = DaoAccountAssetData?.results[2] || bn0;
  const buybackBusdBalance = DaoAccountAssetData?.results[3] || bn0;
  const buybackUsdcBalance = DaoAccountAssetData?.results[4] || bn0;
  const daoBTCBalance = DaoAccountAssetData?.results[5] || bn0;
  const daoETHcBalance = DaoAccountAssetData?.results[6] || bn0;

  const daoAccountUsdBalanceAmount = accountBusdBalance
    .add(accountUsdcBalance)
    .add(accountUsdtBalance);
  // .add(accountPancakeV3USD);
  const buybackBalanceAmount = buybackBusdBalance.add(buybackUsdcBalance);

  return {
    daoAccountUsdBalanceAmount,
    buybackBalanceAmount,
    daoBTCBalance,
    daoETHcBalance,
    daoBNBBalance,
  };
}

export function useFundToken() {
  const tiny = useTiny();
  const TINC = tiny.externalTokens.TINC;
  // const BUSD = tiny.externalTokens.BUSD;
  const { tincAvgPrice } = useContext(FarmsContext);

  const [tincBalance] = useTokenBalance(TINC, account);

  const [price] = useFarmPrice();
  const tincPrice = tincAvgPrice ? tincAvgPrice * 100 : 0;
  const BTCPrice = price.BTC ?? 0;
  const ETHPrice = price.ETH ?? 0;
  const BNBPrice = price.BNB ?? 0;

  // const [busdBalance2] = useTokenBalance(BUSD, buybackAddress);
  // 0x86 是矿池 pid 写死
  // const lpBusd = useMasterChefV2Busd(tiny, account);
  // console.log("lpBusd", lpBusd.toString());

  const {
    daoAccountUsdBalanceAmount,
    buybackBalanceAmount,
    daoBTCBalance,
    daoETHcBalance,
    daoBNBBalance,
  } = useDaoAndBuybackTokenAmounts();

  // console.log("daoBTCBalance", daoBTCBalance);
  // console.log("daoETHcBalance", daoETHcBalance);
  // console.log("daoBNBBalance", daoBNBBalance);

  const buybackPool = useMemo(() => {
    return daoAccountUsdBalanceAmount.add(buybackBalanceAmount);
  }, [daoAccountUsdBalanceAmount, buybackBalanceAmount]);

  const tincMoney =
    BigNumber.from(tincPrice.toFixed())
      .mul(tincBalance)
      .div(bn1e18)
      .toNumber() / 1e8;
  const BTCMoney =
    BigNumber.from((BTCPrice * 1e6).toFixed())
      .mul(daoBTCBalance)
      .div(bn1e18)
      .toNumber() / 1e6;
  const ETHMoney =
    BigNumber.from((ETHPrice * 1e6).toFixed())
      .mul(daoETHcBalance)
      .div(bn1e18)
      .toNumber() / 1e6;
  const BNBMoney =
    BigNumber.from((BNBPrice * 1e6).toFixed())
      .mul(daoBNBBalance)
      .div(bn1e18)
      .toNumber() / 1e6;

  const sumValue =
    buybackPool.div(bn1e18).toNumber() +
    tincMoney +
    BTCMoney +
    ETHMoney +
    BNBMoney;

  // console.log("sumValue", sumValue);
  return {
    tincBalance,
    busdBalance: buybackPool,
    totalFund: sumValue,
    buybackPool,
    daoBTCBalance,
    daoETHcBalance,
    daoBNBBalance,
    tincMoney,
    BTCMoney,
    ETHMoney,
    BNBMoney,
  };
}

export default function useTINCMarket() {
  const { price } = useContext(FarmsContext);

  const { supply, burnBalance } = useTINCInCirculation();
  const { data: totalLockedValue, refetch } = useDaoTotalLockedTINC();

  const marketCapStr = useMemo(() => {
    if (!price) return "--";
    const tincPrice = price.TINC;
    if (tincPrice) {
      const marketCapValue = BigNumber.from((tincPrice * 1e6).toFixed())
        .mul(supply)
        .div(1e6);
      return utils.commify(getBalance(marketCapValue));
    }
    return "--";
  }, [supply, price]);

  const { totalFund } = useFundToken();

  return {
    supply: utils.commify(getBalance(supply.sub(totalLockedValue ?? bn0))),
    marketCapStr,
    burnBalance: utils.commify(getBalance(burnBalance)),
    totalFund: totalFund ? utils.commify(Math.floor(totalFund)) : undefined,
    totalSupply: utils.commify(getBalance(supply.add(burnBalance))),
  };
}
