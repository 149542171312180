import { useCallback } from "react";
import { useQuery } from "react-query";
import useTiny from "./useTiny";
import type { BigNumber } from "ethers";

export default function useDaoTotalVeTINC(){
  const tiny = useTiny();
  const votingEscrow = tiny.contracts.VotingEscrow;
  const fetchBalance = useCallback(async () => {
    const value: BigNumber = await votingEscrow.totalSupply();
    // console.log("value.toString()", value);
    return value
  }, [votingEscrow]);

  return useQuery(
    "DAO_TOTAL_VE_TINC",
    fetchBalance,
    { refetchInterval: 30_000 }
  )
}
