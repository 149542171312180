import {
  PetAttr,
  PetDetail,
  PetPartsGrade,
  PetPartsTotal,
  PetsElement,
} from "./type";
import type { PetDetailInfo } from "src/services/typings";
// 火 1 水 2 木 3 雷 4 光 5 暗 6
import element0 from "src/images/pet/element0.svg";
import element1 from "src/images/pet/element1.svg";
import element2 from "src/images/pet/element2.svg";
import element3 from "src/images/pet/element3.svg";
import element4 from "src/images/pet/element4.svg";
import element5 from "src/images/pet/element5.svg";

import element0Card from "src/images/pet/element0Card.svg";
import element1Card from "src/images/pet/element1Card.svg";
import element2Card from "src/images/pet/element2Card.svg";
import element3Card from "src/images/pet/element3Card.svg";
import element4Card from "src/images/pet/element4Card.svg";
import element5Card from "src/images/pet/element5Card.svg";

const clacGene = (str: string) => {
  return [
    parseInt(str.slice(0, 2), 16),
    parseInt(str.slice(2, 4), 16),
    parseInt(str.slice(4, 6), 16),
  ];
};

export const evolveCntMax = 2 as const;

// 获取基因开放了多少个
export const getGenesOpen = ({
  genesNum,
  evolveCnt = 0,
}: {
  genesNum: number;
  evolveCnt?: number;
}) => {
  return genesNum - (evolveCntMax - evolveCnt);
};

// 获取宠物基因  [1,2,3]
// 第一位是D基因 显性基因
// 第一位是R1基因 隐性基因
// 第一位是R2基因 隐性基因
export const parseGeneByHex = (
  gene: string
): Record<keyof PetAttr, number[]> => {
  const genes = `0x${gene.slice(-36)}`;

  let part_1 = genes.slice(2, 8); // (0xf0f188 & 0xff0000) >> 16
  let part_2 = genes.slice(8, 14);
  let part_3 = genes.slice(14, 20);
  let part_4 = genes.slice(20, 26);
  let part_5 = genes.slice(26, 32);
  let part_6 = genes.slice(32, 38);

  return {
    part_1: clacGene(part_1),
    part_2: clacGene(part_2),
    part_3: clacGene(part_3),
    part_4: clacGene(part_4),
    part_5: clacGene(part_5),
    part_6: clacGene(part_6),
  } as const;
};

const clacGrade = (val: number): PetPartsGrade[] => {
  return Array.from(Array(val).keys()).map(
    (ele) => ((ele % 5) + 1) as PetPartsGrade
  );
};

// petGrade.body[partId-1]
export const petGrade: Record<keyof PetAttr, PetPartsGrade[]> = {
  part_1: clacGrade(PetPartsTotal.part_1),
  part_2: clacGrade(PetPartsTotal.part_2),
  part_3: clacGrade(PetPartsTotal.part_3),
  part_4: clacGrade(PetPartsTotal.part_4),
  part_5: clacGrade(PetPartsTotal.part_5),
  part_6: clacGrade(PetPartsTotal.part_6),
} as const;

export const getPetDetail = (pet: PetDetailInfo): PetDetail => {
  const { id, genes } = pet;
  const parts: PetDetail["parts"] = {
    part_1: [],
    part_2: [],
    part_3: [],
    part_4: [],
    part_5: [],
    part_6: [],
  };
  if (genes) {
    // const gene = parseGeneByHex(test);
    const gene = parseGeneByHex(genes);
    const element: PetsElement = parseInt(
      genes.slice(-38, -36),
      16
    ) as PetsElement;

    Object.keys(gene).map((ele, index) => {
      const key = ele as keyof PetAttr;
      const info = gene[key].map((val, index) => ({
        key: val,
        grade: petGrade[key][val],
      }));
      parts[key] = [...info];
    });
    return {
      ...pet,
      parts,
      element,
    };
  }
  return {
    ...pet,
    parts: undefined,
    element: undefined,
  } as const;
};

// 木 火 水
export const petsElementArr = [
  element0,
  element1,
  element2,
  element3,
  element4,
  element5,
] as const;

// 木 火 水
export const petsCardElementArr = [
  element0Card,
  element1Card,
  element2Card,
  element3Card,
  element4Card,
  element5Card,
] as const;

export const petsElementVal = [
  "element0",
  "element1",
  "element2",
  "element3",
  "element4",
  "element5",
] as const;

// Cost Tinc token based on breeding count
export const costTincs = [135, 162, 189, 216, 243, 270, 405];

// Cost Spirit token based on breeding count
export const costSpirits = [1100, 1300, 1600, 1800, 2000, 2200, 3400];
