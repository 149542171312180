import { request } from "./util";
import {
  pets_api_base,
  pet_market_api_base,
  backend_url,
  game_api_base,
} from "../constants";
import type {
  PageResult,
  PageParamBase,
  PageResultSort,
  PageListResult,
  PetBaseInfo,
  PetDetailInfo,
  PetDetailAndGameInfo,
} from "./typings";
import { SortInfo, SortOrder } from "src/config";
import { PetDetail, PetsElement } from "src/components/NFTCard/PetCard/type";
import { AuctionDashboard } from "./auctions";
/** --------------- 宠物相关 ---------------- */

export interface PetsResult {
  total_count: number;
  list: PetBaseInfo[];
}

/**
 * 我的空闲中的宠物
 * @param {string} account
 * @returns
 */
export async function getPetsByAddress(
  { account }: { account?: string },
  options?: RequestInit
): Promise<PetsResult> {
  return request(`${pets_api_base}/my?address=${account}`, options);
}

/**
 * 我的游戏中的宠物
 * @param {string} account
 * @returns
 */
export async function getGamingPetsByAddress(
  { account }: { account?: string },
  options?: RequestInit
): Promise<PetsResult> {
  return request(`${pets_api_base}/gaming?address=${account}`, options);
}

/**
 * 我的耕作中的宠物
 * @param {string} account
 * @returns
 */
export async function getStakingPetsByAddress(
  { account }: { account?: string },
  options?: RequestInit
): Promise<PetsResult> {
  return request(`${pets_api_base}/staking?address=${account}`, options);
}

/**
 * 获取宠物列表
 * @param {string} account
 * @returns /list?address=0x345345&pageNum=1&pageSize=12&sortBy=code&sortType=desc
 */
export async function getPetsList(
  {
    account,
    pageNum,
    pageSize,
    sortBy = "code",
    sortType = SortOrder.DESC,
  }: { account?: string } & PageParamBase & PageResultSort,
  options?: RequestInit
): Promise<PetsResult & PageResult> {
  const params = new URLSearchParams({
    pageSize: pageSize ? pageSize.toString() : "12",
    pageNum: pageNum.toString(),
    address: account ? account.toString() : "",
    sortBy: sortBy.toString(),
    sortType: sortType.toString(),
  });
  return request(`${pets_api_base}/list?${params.toString()}`, options);
}

/**
 * 获取宠物详情
 * @param {number} id
 * @returns IPets
 */
export async function getPetsDetail(
  { id }: { id?: number },
  options?: RequestInit
): Promise<PetDetailAndGameInfo | undefined> {
  if (id) {
    return request(`${pets_api_base}/${id}`, options);
  } else {
    return undefined;
  }
}

// 宠物孵化
export function petsCrack(
  params: {
    address: string;
    signature: string;
    id: number;
  },
  options?: RequestInit
): Promise<any> {
  return fetch(backend_url + pets_api_base + "/crack?", {
    ...options,
    method: "POST",
    headers: {
      contentType: "application/json",
    },
    body: JSON.stringify(params),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    /* 
     else {
      console.error(pets_api_base + "/crack");
    }
    */
    // try {
    //   console.log({ res });
    //   res.json().then((res) => {
    //     console.log({
    //       key: pets_api_base + "/crack",
    //       res,
    //     });
    //   });
    //   return res.json();
    // } catch (error) {
    //   console.log({
    //     key: pets_api_base + "/crack",
    //     error,
    //   });
    // }
  });
}

export interface PetMarketParam extends PageParamBase {
  sort: SortInfo;
  rangeValues: number[];
  isCracked?: 0 | 1; //是否是蛋
  isOrigin?: 0 | 1; //是否是origin宠物
  races: number[]; //0,1,2,3,4,5 PetsElement的逗号分割字符串
  isCD: 0 | 1; //是否在繁殖冷却中
  partsGrade2: number[];
  partsGrade3: number[];
  partsGrade4: number[];
  partsGrade5: number[];
  propsCounts: number[]; //要筛选的部位数量顺序 （气势/攻击/生命/防御/幸运）
  powers: (number | undefined)[]; //筛选的战力范围
}

interface PetMarketItem {
  referencePrice: number;
  tokenId: number;
  id: number;
  blockNumber: number;
  petInfo: PetDetailInfo;
}

export interface PetMarketSuccessItem {
  id: number;
  tokenId: number;
  seller: string;
  buyer: string;
  blockNumber: number;
  petInfo: PetDetailInfo;
  bidPrice: number;
  tx: string;
  timestamp: number;
}

export interface PetMarketInfo extends PetMarketItem {
  petInfo: PetDetailAndGameInfo;
}

export interface PetBidsInfo extends PetMarketSuccessItem {
  petInfo: PetDetail;
}

// 获取宠物交易市场列表
/* 
  isCracked?: 0 | 1; //是否是蛋
  isOrigin?: 0 | 1; //是否是origin宠物
  race?: PetsElement;
  isCD?: 0 | 1; //是否在繁殖冷却中
*/
export function getPetMarketList(
  {
    pageSize = 12,
    pageNum,
    sort,
    rangeValues,
    isCracked,
    isOrigin,
    races,
    isCD,
    partsGrade2,
    partsGrade3,
    partsGrade4,
    partsGrade5,
    propsCounts,
    powers,
  }: PetMarketParam,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    pageSize: pageSize.toString(),
    pageNum: pageNum.toString(),
    rangeValues: rangeValues.toString(),
  });

  /* 
    isOrigin,
    race,
    isCD,
    */
  if (typeof isCracked === "number") {
    params.set("isCracked", isCracked.toString());
  }

  if (isOrigin) {
    params.set("isOrigin", "1");
  }

  if (races && races.length > 0) {
    params.set("races", races.toString());
  }
  if (isCD) {
    params.set("isCD", "1");
  }

  /* 
        partsGrade2,
      partsGrade3,
      partsGrade4,
      partsGrade5,
  */
  if (partsGrade2 && partsGrade2.length === 2) {
    params.append("grade3parts", partsGrade2.toString());
  }
  if (partsGrade3 && partsGrade3.length === 2) {
    params.append("grade4parts", partsGrade3.toString());
  }
  if (partsGrade4 && partsGrade4.length === 2) {
    params.append("grade5parts", partsGrade4.toString());
  }
  if (partsGrade5 && partsGrade5.length === 2) {
    params.append("grade6parts", partsGrade5.toString());
  }

  if (propsCounts && propsCounts.length === 5) {
    params.append("prop0Count", propsCounts[0].toString());
    params.append("prop1Count", propsCounts[1].toString());
    params.append("prop2Count", propsCounts[2].toString());
    params.append("prop3Count", propsCounts[3].toString());
    params.append("prop4Count", propsCounts[4].toString());
  }

  if (powers.filter((ele) => ele === undefined).length < 2) {
    if (powers[0]) {
      params.append("minPower", powers[0].toString());
    }
    if (powers[1]) {
      params.append("maxPower", powers[1].toString());
    }
  }

  if (sort) {
    const { key, order } = sort;
    params.set("sortBy", key);
    params.set("sortType", order);
  }
  return request<PageListResult<PetMarketItem>>(
    `${pet_market_api_base}/list?${params.toString()}`,
    options
  );
}

// 获取我的宠物交易市场列表
export function getPetMarketListByAddress(
  { address }: { address: string },
  options?: RequestInit
) {
  return request<PageListResult<PetMarketItem>>(
    `${pet_market_api_base}/sellingList?address=${address}`,
    options
  );
}

// 获取宠物挂单详情
export function getPetAuction(id: number, options?: RequestInit) {
  return request<PetMarketItem>(
    `${pet_market_api_base}/detail?id=${id}`,
    options
  );
}

// 获取我的宠物交易市场列表
export function getPetBidsByAddress(
  {
    address,
    pageSize = 12,
    pageNum,
  }: PageParamBase & {
    address: string;
  },
  options?: RequestInit
) {
  const params = new URLSearchParams({
    address,
    pageSize: pageSize.toString(),
    pageNum: pageNum.toString(),
  });

  return request<PageListResult<PetMarketSuccessItem>>(
    `${pet_market_api_base}/myBids?${params.toString()}`,
    options
  );
}

// 获取宠物交易記錄
export async function getPetsRecentBids(options?: RequestInit) {
  return request<PetMarketSuccessItem[]>(
    `${pet_market_api_base}/recent`,
    options
  );
}

// 获取宠物交易数据
export async function getPetsBidsDashboard(options?: RequestInit) {
  return request<AuctionDashboard>(`${pet_market_api_base}/dashboard`, options);
}

// 获取某个宠物交易历史数据
export async function getPetAuctionHistory(
  { id }: { id: number | undefined },
  options?: RequestInit
) {
  return request<{ list: PetMarketSuccessItem[] }>(
    `${pet_market_api_base}/history/${id}`,
    options
  );
}

interface PetsGameProps {
  endure: number; //饱食度
  maxEndure: number; //最大饱食度
  tissues: {
    //部位详情
    additionType: 0 | 1 | 2 | 3 | 4; //  是附加的属性类型（气势/攻击/生命/防御/幸运）
    level: number; //部位等级
    metadata: {
      attack: number; //攻击
      health: number; //生命
      defense: number; //防御
      luck: number; //幸运
      momentum: number; //气势
      attr: {
        //部位描述
        descCn1: string; //部位特性中文名称
        descCn2: string; //部位特性中文描述
        descEn1: string; //部位特性英文名称
        descEn2: string; //部位特性英文描述
      };
    };
  }[];
}

export const PetsAdditionDetail = [
  "momentum",
  "attack",
  "health",
  "defense",
  "luck",
] as const;

export const PetsAdditionDesc = [
  "momentum",
  "attack",
  "hp",
  "defense",
  "luck",
] as const;

// 获取宠物游戏详情
export async function getPetGameInfo(
  {
    id,
  }: {
    id: number | undefined;
  },
  options?: RequestInit
): Promise<PetsGameProps> {
  return fetch(
    `${backend_url + game_api_base}/petGameInfo/${id}`,
    options
  ).then((res) => {
    if (res.status === 404) {
      return null;
    }
    return res.json();
  });
}

// 获取宠物游戏详情
export async function getPetIdByTx(
  {
    tx,
  }: {
    tx: string | undefined;
  },
  options?: RequestInit
): Promise<string | undefined> {
  const params = new URLSearchParams({});
  if (tx) {
    params.set("tx", tx.toString());
    return fetch(
      `${backend_url + pets_api_base}/egg?${params.toString()}`,
      options
    ).then((res) => {
      if (res.status === 404) {
        return null;
      }
      return res.json();
    });
  } else {
    return undefined;
  }
}
