import { useCallback, useState } from "react";
import { BigNumber } from "ethers";

import useAutoFreshRequest from "src/hooks/useAutoFreshRequest";
import { bn0 } from "src/utils";
import { getDefaultProvider } from "src/utils/provider";

const privider = getDefaultProvider();

const cache: {
  [address: string]: string | undefined;
} = {};

function getBNBCache(address: string | null | undefined) {
  if (!address) return bn0;
  const cacheBalanceStr = cache[address];
  if (!cacheBalanceStr) return bn0;
  return BigNumber.from(cacheBalanceStr);
}

function useBNBBalance(
  address: string | null | undefined
): [BigNumber, () => void] {
  const [balance, setBalance] = useState(getBNBCache(address));

  const fetchBalance = useCallback(
    async (address: string | null | undefined) => {
      if (!address) return;
      try {
        let balance = await privider.getBalance(address);
        // console.log("bnb balance", balance);
        setBalance((pre) => (pre.eq(balance) ? pre : balance));
        cache[address] = balance.toString();
      } catch (error) {
        console.error("fetchBalance error:", error);
      }
    },
    []
  );

  const [_, refreshBalance] = useAutoFreshRequest(fetchBalance, [address], {
    interval: 10_000,
  });

  return [balance, refreshBalance];
}

export default useBNBBalance;
