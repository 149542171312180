import dayjs from "dayjs";

const hours = 3600;

/**
 * @param {number} timestamp 当前的时间，单位秒
 * @returns {number}
 */
export function _endOfDay(timestamp: number) {
  return (
    Math.floor((timestamp - 8 * hours) / (hours * 24) + 1) * hours * 24 +
    8 * hours
  );
}

const hoursMs = 3600_000;
/**
 * @param {number} [timestamp] 当前的时间，单位毫秒
 * @returns {number}
 */
export function _endOfDayMs(timestamp = Date.now()) {
  return (
    Math.floor((timestamp - 8 * hoursMs) / (hoursMs * 24) + 1) * hoursMs * 24 +
    8 * hoursMs
  );
}

export function _endOfCustomGapTime(timestamp: number, gap: number) {
  return Math.floor((timestamp - 8 * hours) / gap + 1) * gap + 8 * hours;
}

// 判斷傳入時間是否大於當前時間
export function _diffTime(timestamp: number) {
  return dayjs().diff(timestamp * 1000) > 0;
}
