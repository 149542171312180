import { useState, useCallback } from "react";
import useTiny from "./useTiny";
import useLimitedInterval from "./useLimitedInterval";
import useAutoFreshRequest from "src/hooks/useAutoFreshRequest";
import type { BigNumber } from "ethers";

export default function useCheckinRestTime(
  timer: number = 300_000
): [
  number | undefined,
  () => void,
  React.Dispatch<React.SetStateAction<number | undefined>>
] {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const login = tiny.contracts.Login;

  const [restTime, setRestTime] = useState<number>();

  const fetchRestTime = useCallback(async () => {
    if (!account) return 0;
    const t: BigNumber = await login.restTime(account);
    // console.log("t", t);
    const time = t.toNumber();
    // console.log("restTime", time);
    setRestTime(time);
    return time;
  }, [account, login]);

  const [_, fetch] = useAutoFreshRequest(fetchRestTime, [account], {
    interval: timer,
  });

  const startFreshRestTime = useLimitedInterval(fetch, {
    limit: 3,
  });

  return [restTime, startFreshRestTime, setRestTime];
}
