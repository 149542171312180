import { PetDetailInfo } from "src/services/typings";

export type PetPartsGrade = 1 | 2 | 3 | 4 | 5;

// D:1,R1:2,R2:3
export enum PetGeneType {
  D = 1,
  R1 = 2,
  R2 = 3,
}

export type GeneType = 1 | 2 | 3;

export enum PetPartsTotal {
  part_1 = 35,
  part_2 = 35,
  part_3 = 30,
  part_4 = 30,
  part_5 = 20,
  part_6 = 20,
}

// 木 火 水
// 火 1 水 2 木 3 雷 4 光 5 暗 6
export type PetsElement = 0 | 1 | 2 | 3 | 4 | 5;

export interface PetAttr {
  part_1: PetPartsGrade;
  part_2: PetPartsGrade;
  part_3: PetPartsGrade;
  part_4: PetPartsGrade;
  part_5: PetPartsGrade;
  part_6: PetPartsGrade;
}

export interface PartInfo {
  key: number;
  grade: PetPartsGrade;
}

export interface PetDetail extends PetDetailInfo {
  element?: PetsElement; //宠物属性 火 1 水 2 木 3 雷 4 光 5 暗 6
  parts?: Record<keyof PetAttr, PartInfo[]>;
  femaleInfoDetail?: PetDetail;
  maleInfoDetail?: PetDetail;
  propCounts?: number[];
}
