import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Pagination, Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

import useIsMobile from "src/hooks/useIsMobile";
import { SwiperList } from "./const";
import PrefilledImage from "src/components/Images/PrefilledImage";

// 轮播图更新
export default function Carousel() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isXSMobile = useIsMobile(400);
  const imgWidth = isXSMobile ? 300 : 660;
  const imgHeight = isXSMobile ? 150 : 330;

  return (
    <StyledCarousel>
      <Swiper
        style={{ alignItems: "center" }}
        modules={
          isMobile
            ? [Pagination, Autoplay, Navigation]
            : [Pagination, Autoplay, EffectCoverflow]
        }
        pagination={{ clickable: true }}
        effect="coverflow"
        centeredSlides={true}
        centeredSlidesBounds={true}
        centerInsufficientSlides={true}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 200,
          modifier: 1,
        }}
        speed={1000}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        }}
        // navigation={isMobile}
        loop={true}
        spaceBetween={20}
        slidesPerView="auto"
      >
        {SwiperList.map(({ href, src, alt, srcPre }) => {
          const resizeOpt = {
            width: isXSMobile ? 600 : 660,
            height: isXSMobile ? 300 : 330,
          };
          return (
            <SwiperSlide key={alt}>
              <a href={href} target="_blank" rel="noreferrer">
                <StyledImg
                  width={imgWidth}
                  height={imgHeight}
                  src={src}
                  alt={alt}
                  preSrc={srcPre}
                  resizeOpt={resizeOpt}
                />
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <More
        href="https://medium.com/@tinyworld.gamefi"
        target="_blank"
        rel="noreferrer"
      >
        {t("MORE")}
      </More>
    </StyledCarousel>
  );
}

const StyledCarousel = styled.div`
  position: relative;
  margin-top: 3rem;
  .swiper {
    height: 380px;
    /* margin: 0 5%; */
    --swiper-theme-color: #fccf4c;
    --swiper-pagination-bullet-inactive-color: #905419;
    --swiper-pagination-bullet-width: 16px;
    --swiper-pagination-bullet-height: 16px;

    .swiper-pagination-bullet {
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      border: 3px #905419 solid;
      box-sizing: border-box;
    }
  }

  .swiper-slide {
    max-height: 330px;
    max-width: 660px;
    img {
      height: 100%;
      border-radius: 4px;
    }
  }

  .swiper-button-prev:after {
    display: none;
  }
  .swiper-button-next:after {
    display: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 2rem;
    .swiper {
      height: 200px;
      width: 100vw;
      --swiper-pagination-bullet-width: 11px;
      --swiper-pagination-bullet-height: 11px;
    }
  `}
  ${({ theme }) => theme.mediaWidth.upToXS`
    .swiper {
      height: 185px;
    }
  `}
`;

const StyledImg = styled(PrefilledImage)`
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 165px;
    width: 330px;
  `}
  ${({ theme }) => theme.mediaWidth.upToXS`
    height: 150px;
    width: 300px;
  `}
`;

const More = styled.a`
  position: absolute;
  bottom: 30px;
  right: 0;
  color: #514b45;
  z-index: 11;
  ::after {
    content: "►";
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    bottom: 11px;
    right: 35px;
  `}
`;
