// import { useCallback } from "react";
import styled from "styled-components";
import { useLocation, NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { StyledNav } from "src/components/StyledComponents";

import { ReactComponent as Home } from "src/images/sidebar/menu_home.svg";
import { ReactComponent as Farm } from "src/images/sidebar/menu_farm.svg";
import { ReactComponent as Rune } from "src/images/sidebar/menu_blindbox.svg";
import { ReactComponent as MyNft } from "src/images/sidebar/menu_my.svg";
import { ReactComponent as Market } from "src/images/sidebar/menu_market.svg";
import { ReactComponent as Ranking } from "src/images/sidebar/menu_rankings.svg";
import { ReactComponent as Dao } from "src/images/sidebar/menu_dao.svg";
import { ReactComponent as Invite } from "src/images/sidebar/menu_invite.svg";

import Menu, { SubMenu, MenuItem } from "rc-menu";
import "./styles.css";
import type { CSSMotionProps, MotionEventHandler } from "rc-motion";
import {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useDisplayBinanceTicket from "src/hooks/useDisplayBinanceTicket";
import { Hot, New } from "src/components/Hint";
import useOrderedRuneBalances from "src/hooks/useOrderedRuneBalance";
import Advertise from "../components/Advertise";
import { AdvertiseConfig } from "src/views/Home/const";

const collapseNode = () => {
  return { height: 0 };
};
const expandNode: MotionEventHandler = (node) => {
  return { height: node.scrollHeight };
};

const defaultMotion: CSSMotionProps = {
  motionName: "rc-menu-collapse",
  motionAppear: true,
  onAppearStart: collapseNode,
  onAppearActive: expandNode,
  onEnterStart: collapseNode,
  onEnterActive: expandNode,
  onLeaveStart: expandNode,
  onLeaveActive: collapseNode,
};

const menuKeys = [
  "/home",
  "/tinyvault",
  "/mysterybox",
  "/mynfts",
  "/marketplace/list",
  "/dao/dashboard",
  "/invitation/dashboard",
];

export default function Nav() {
  const location = useLocation();
  const { t } = useTranslation();
  const pathname = location.pathname;

  const [_, openKey] = pathname.split("/");
  const history = useHistory();

  const linkTo = (key: string) => {
    history.push(key);
  };

  const [currentOpenKeys, setCurrentOpenKeys] = useState<string[]>([]);

  const handleOpen = (keys: string[]) => {
    setCurrentOpenKeys(keys);
  };

  const itemSelect = (key: string) => {
    return pathname.includes(key) ? "rc-menu-item-selected" : "";
  };

  const [ulBoxHeight, setUlBoxHeight] = useState(0);
  const [liHeight, setliHeight] = useState(0);
  /*
  领取阶段放开
  const { data: orderedRuneNums = [0, 0], refetch: startFreshRune } = useOrderedRuneBalances()

  const showPresale = orderedRuneNums[0] + orderedRuneNums[1] > 0 */

  const ulRef = useRef(document.createElement("ul"));
  const aRef = useRef(document.createElement("a"));
  const advertiseRef = useRef(document.createElement("a"));
  const advertiseTop = useMemo(() => {
    const calcTop = menuKeys.length * liHeight + 20;
    const boxHeight = ulBoxHeight + 10;

    // console.log(
    //   boxHeight > calcTop && boxHeight > 10
    //     ? {
    //         key: "calcTop",
    //         value: calcTop,
    //       }
    //     : {
    //         key: "ulBoxHeight",
    //         value: ulBoxHeight + 30,
    //       }
    // );
    // console.log({
    //   boxHeight: boxHeight,
    //   calcTop: calcTop,
    //   flag1: boxHeight > calcTop,
    //   flag2: boxHeight > 10,
    // });
    return boxHeight > calcTop && boxHeight > 10 ? calcTop : boxHeight;

    //  + 20    ulBoxHeight + 30
  }, [liHeight, ulBoxHeight]);

  const resizeAdvertiseTop = useCallback(() => {
    setUlBoxHeight(ulRef.current.offsetHeight);
    setliHeight(aRef.current.offsetHeight + 16 + 5);
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (ulRef.current.offsetHeight > 0) {
        setUlBoxHeight(ulRef.current.offsetHeight);
      }

      if (aRef.current.offsetHeight > 0) {
        setliHeight(aRef.current.offsetHeight + 16 + 5);
      }
    }, 300);

    window.addEventListener("resize", resizeAdvertiseTop);

    return () => {
      window.removeEventListener("resize", resizeAdvertiseTop);
    };
  }, [resizeAdvertiseTop]);

  const AdvertiseRender = useMemo(() => {
    return (
      liHeight > 0 &&
      ulBoxHeight > 0 && (
        <>
          <Advertise
            advertiseRef={advertiseRef}
            // style={{
            //   top: advertiseTop,
            // }}
          />
        </>
      )
    );
  }, [liHeight, ulBoxHeight]);

  // advertiseRef

  // console.log({
  //   bottomHeight: advertiseBottom,
  //   flag: AdvertiseConfig.show,
  // });

  return (
    <StyledContainer
      bottomHeight={AdvertiseConfig.show ? AdvertiseConfig.bottom : 0}
    >
      <StyledMenuContainer>
        <Menu
          ref={ulRef}
          mode="inline"
          onClick={(info) => linkTo(info.key)}
          defaultMotions={{ inline: defaultMotion }}
          defaultSelectedKeys={[pathname]}
          selectedKeys={[pathname]}
          defaultOpenKeys={["/" + openKey]}
          onOpenChange={(openKeys) => handleOpen(openKeys)}
        >
          <MenuItem className={itemSelect(menuKeys[0])} key={menuKeys[0]}>
            <NavLink ref={aRef} to={menuKeys[0]}>
              <MenuIconImg>
                <Home className="menu-icon-img" fill={"#905419"} />
              </MenuIconImg>
              <span>{t("Home")}</span>
            </NavLink>
          </MenuItem>

          <MenuItem className={itemSelect(menuKeys[1])} key={"/tinyvault/LP"}>
            <NavLink to={menuKeys[1]}>
              <MenuIconImg>
                <Farm className="menu-icon-img" fill={"#905419"} />
              </MenuIconImg>
              <span>{t("Mine")}</span>
              <New bottom={-1} />
            </NavLink>
          </MenuItem>

          <MenuItem
            className={itemSelect(menuKeys[2])}
            key={"/mysterybox/runes"}
          >
            <NavLink to={menuKeys[2]}>
              <MenuIconImg>
                <Rune className="menu-icon-img" fill="#905419" />
              </MenuIconImg>
              <span>{t("summon NFT")}</span>
              {/* <New bottom={-1} /> */}
            </NavLink>
          </MenuItem>

          <MenuItem
            className={itemSelect(menuKeys[3])}
            itemIcon={""}
            key={menuKeys[3]}
          >
            <NavLink to={menuKeys[3]} strict>
              <MenuIconImg>
                <MyNft className="menu-icon-img" fill="#905419" />
              </MenuIconImg>
              {t("myNFTs")}
            </NavLink>
          </MenuItem>

          <MenuItem className={itemSelect("/marketplace")} key={menuKeys[4]}>
            <NavLink to="/marketplace/" strict>
              <MenuIconImg>
                <Market className="menu-icon-img" fill="#905419" />
              </MenuIconImg>
              {t("market")}
            </NavLink>
          </MenuItem>

          <MenuItem className={itemSelect(menuKeys[5])} key={menuKeys[5]}>
            <NavLink to={menuKeys[5]}>
              <MenuIconImg>
                <Dao className="menu-icon-img" fill="#905419" />
              </MenuIconImg>
              {t("dao")}
            </NavLink>
          </MenuItem>

          <MenuItem className={itemSelect(menuKeys[6])} key={menuKeys[6]}>
            <NavLink to={menuKeys[6]}>
              <MenuIconImg>
                <Invite className="menu-icon-img" fill="#905419" />
              </MenuIconImg>
              {t("invitation")}
            </NavLink>
          </MenuItem>
        </Menu>
      </StyledMenuContainer>

      {AdvertiseRender}
    </StyledContainer>
  );
}

function MenuIconImg(
  {
    children,
  }: PropsWithChildren<{ src?: string }> /* { src }: { src: string } */
) {
  return (
    <span className="position-relative menu-icon-img-box mr-5">
      {/* <img className="menu-icon-img" src={src} width="27" height="23" alt="" /> */}
      {children}
    </span>
  );
}

const StyledMenuContainer = styled.div`
  max-height: 371px;
  width: 100%;
  flex: 1;

  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    background-color: transparent;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      width: 6px;
    `}
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e69c10;
    border-radius: 10px;
  }
`;

const StyledContainer = styled.div<{ bottomHeight: number }>`
  position: relative;
  width: 100%;
  font-size: 1.1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 330px);

  ul {
    width: 100%;
  }
`;

const StyledCircleIcon = styled.div.attrs((_) => ({
  className: "menu-cirlce",
}))`
  display: inline-block;
  margin-right: 15px;
  width: 5px;
  height: 5px;
  border: 2px #905419 solid;
  border-radius: 50%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 3px;
    height: 3px;
  `}
`;

const StyledButton = styled(StyledNav)`
  display: inline-flex;
  align-items: center;
  margin-top: 0.2rem;
  padding-left: 4px;
  padding-right: 0.9rem;
  padding-bottom: 0.4rem;
  width: 11.7rem;
  height: 3.5rem;
  line-height: 3.2rem;
  font-size: 1.35rem;
  color: ${(props) => props.theme.color.white};
  background-position: 0% 50%;
  transform-origin: left;
  .active > & {
    text-shadow: 0px 4px 0px #00000055;
    > img {
      width: 31px;
      filter: drop-shadow(0 4px 0 #33333388);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    margin-top: 0.3rem;
    width: 11.5rem;
    height: 3.2rem;
    font-size: 1.3rem;
  `};
`;

const StyledNew = styled.div<{ bottom: number }>`
  display: inline-block;
  margin-left: 5px;

  img {
    margin-bottom: ${({ bottom }) => bottom}px;

    :nth-child(1) {
      animation: 1s 100ms infinite lePeek;
      margin-right: -2px;
    }

    :nth-child(2) {
      animation: 1s 250ms infinite lePeek;
      margin-right: -3px;
    }

    :nth-child(3) {
      animation: 1s 400ms infinite lePeek;
    }
  }
`;
