import { useMemo } from "react";
import { chainId } from "src/config";

export default function useIsInvestor(account: string | undefined) {
  return useMemo(() => {
    if (!account) return false;
    // console.log('account', account);
    const address = account.toLowerCase();
    const finalList = investorListMap[chainId];
    for (let index = 0; index < finalList.length; index++) {
      const element = finalList[index];
      if (address === element.toLowerCase()) {
        return true;
      }
    }
    return false;
  }, [account]);
}

const investorListMap = {
  97: [
    "0x19dBf7B58674594b7A0043bBDcED6E7C4fC59818",
    "0xEeB1E8Fcd51a6D4E720efD25dEf0d95cbD789bdc",
    "0x640f144a6b7B1191e43AF237935F05c2AdB92fe8",
    "0xD000c5e49E3eA33ddDAe6c4a933e50ab8ab8fEc8",
  ],
  56: [
    "0x81E374E0C013D4Ea5027AbA97A2505efd0d08F59",
    "0x1488618d70887be6875c081dd5d338bb44101e51",
    "0x3A2186209bACB987Bb183978545047b0A57a3b1b",
    "0xA34aF41b98e42FEdaCB93d66401593b2c9851DFe",
    "0x82Cdc41af60c7A4f40a2a378E7b48EeDCa69213B",
    "0x422cc389610258382F39ccB68ecEf50d2Ed709b6",
    "0x760484042a7856E62B627318796Ebb609C8131a1",
    "0x5092f5347a50A2992A4bDCA58Bec6A54D78b51Ef",
    "0x7D7b17d603bc6e7611c5C2b99Bf6A3bB8A81f708",
    "0x696575E974C1E715B046F083aCE9bbd3300D2EAF",
    "0x9661398c32f457c996F4c3123e9DCbD21280Fcb6",
    "0x2f700dEeD41639E1ae24D193f7d67453c4002Ee3",
    "0x1a0776e37F0537fd8ce7d6641cEB1FC20961dEEA",
    "0xB0443a6ee92D10A36d5E50663Ce83E97433a484F",
    "0xfE7A36875aF4013E0AfC0Ab78f42dee8fB038370",
    "0x207646E59574B56F560E3378d8C9B202A8125044",
    "0x070F7A6B2882DdEa1c1Dd674526753D3f2DfAD11",
    "0x0886494AC8E9Cd7f7023AaFcbEB25faFe2dBE58F",
    "0xc33D1C3b30e09E62fcBB9da9eE886c8BD3b4879c",
    "0xD205E26da61cb72842B8531fba2f7b44ec30D0c2",
    "0x75b90dFFBD7C75C42d1eF9513ff9BE66806FE232",
    "0x1F9d09c07d4da04EdC1549Ea1465DdacDF88F0b2",
    "0x1d97cb203468e6178b4Ea9bc4E68b70764b9CA37",
    "0x92c49986dbdda8e400ea35ba8c0735e11f8e8c17",
    "0xec849b830e63381fD4801170aEd5c7B0fAA8C432",
    "0xe4989e7B39a21089B128908E1603fdC9939DBB78",
    "0x97116fC08e002e79736f641Efb164114942c0732",
    "0x22ecCaFed308749A3B8830b725044fAB29702AD6",
    "0x505931d3e06BE7b2595764Cf1B39A12D51480729",
    "0x7b44a3002ec940b329d4b350c1dbfbd4fed5aefe",
    "0x79Bd407d66a485855cfd7EEc952087b521B1c051",
    "0xea5ABc1a1689984EbFDC41130886BdAEB5c24078",
    "0x0EB74CbB389634Ed3bA54926a2702b318C60b82A",
    "0x969a84269180db25Bd5634B5DBc70ce94Eb7bCE8",
    "0xeBeD2200b9aD455Fec52650D00aB86Fc2858fde4",
    "0xC512B6927CA73F5dE3655EAD8e00798873302ad7",
    "0x3b0D3BED6A455873D87aDb350330aD1A4580763E",
    "0x5AD55589c2cA4cb824A4680EfaBA9897f3129f1E",
    "0x5e76F0eCD840A9D582DabD1696f2a0AF2E07c46f",
  ],
};
