// import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useIsMobile from "src/hooks/useIsMobile";

import Modal from "src/components/Modal";
import ParallelogramButton from "src/components/Button/ParallelogramButton";
import ModalActions from "src/components/ModalActions";
// import ModalActions from "src/components/ModalActions";
import iconRestore from "src/images/props/icon-restore.png";

import type { ModalProps } from "src/components/Modal";

interface Props extends ModalProps {
  spiritValue: number;
  claimAirdrop(): void;
}
export default function SpiritAirdropModal({
  onDismiss,
  claimAirdrop,
  spiritValue,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  // console.log("spiritValue", spiritValue);

  return (
    <Modal title={t("Airdrop")} style={{ maxWidth: 400 }} onDismiss={onDismiss}>
      <StyledContent>
        <StyledTotalRight>
          <img
            className="mr-5"
            src={iconRestore}
            height={isMobile ? 22 : 27}
            alt="BNB"
          />
          <StyledAmount>{spiritValue}</StyledAmount>
        </StyledTotalRight>
        <ModalActions>
          <ParallelogramButton onClick={claimAirdrop}>
            {t("Claim")}
          </ParallelogramButton>
        </ModalActions>
      </StyledContent>
    </Modal>
  );
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* padding: 24px 0; */
  min-height: 100px;
  border-radius: 9px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 15px;
  `}
`;

const StyledTotalRight = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 120px;
  font-size: 1.3rem;
`;

const StyledAmount = styled.span`
  height: 2.8rem;
  width: 7.8rem;
  border-radius: 11px;
  background-color: #fffbdd;
  padding: 11px 12px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  box-sizing: border-box;
`;
