import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal, { ModalProps } from "../Modal";
import type { NewData, NewsRes } from "src/services/index";
import useIsMobile from "src/hooks/useIsMobile";
import noticeActivity from "src/images/topbar/notice-activity.png";
import noticeUpgrade from "src/images/topbar/notice-upgrade.png";
import clsx from "clsx";
interface NewsModalProps extends ModalProps {
  data:
    | undefined
    | {
        upgrade: NewData[];
        activity: NewData[];
      };
}

export default function NewsModal({ onDismiss, data }: NewsModalProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [active, setActive] = useState<keyof NewsRes["data"]>("upgrade");
  const tabArr = [
    {
      bg: noticeUpgrade,
      key: "upgrade",
    },
    {
      bg: noticeActivity,
      key: "activity",
    },
  ];

  return (
    <Modal
      size="sm"
      title={t("announcement") as string}
      onDismiss={onDismiss}
      style={
        isMobile
          ? {
              width: "90vw",
              maxWidth: "90vw",
            }
          : {
              width: 800,
            }
      }
    >
      <StyledNoticeTabBox>
        {tabArr.map(({ bg, key }, index) => {
          return (
            <StyledNoticeTabItem
              key={index}
              className={clsx(active === key && "active")}
              bg={bg}
              onClick={() => {
                setActive(key as keyof NewsRes["data"]);
              }}
            >
              <span>{t(`${key}`)}</span>
            </StyledNoticeTabItem>
          );
        })}
      </StyledNoticeTabBox>

      {data && (
        <StyledContent className="custom-scrollbar">
          {data[active]?.map((d, i) => (
            <li key={"news-" + i}>
              <span>{t(d.title)}</span>
              <div>
                {d.contents.map((content, index) => {
                  if (content.includes("$a")) {
                    // const arr = t(content).split(",");
                    const str = t(content);
                    return (
                      <p key={`news-${i}-${index}`}>
                        {/* <a target="_blank" href={arr[0]}>
                          {arr[1]} */}
                        <a target="_blank" href={str}>
                          {str}
                        </a>
                      </p>
                    );
                  }
                  if (content.includes("$label")) {
                    // const arr = t(content).split(",");
                    const str = t(content);
                    return (
                      <p key={`news-${i}-${index}`}>
                        {/* <label>　－　</label> */}
                        <label>{str}</label>
                      </p>
                    );
                  }
                  return <p key={`news-${i}-${index}`}>{t(content)}</p>;
                })}
              </div>
            </li>
          ))}
        </StyledContent>
      )}
    </Modal>
  );
}
// </StyledContainer>

const StyledNoticeTabItem = styled.div<{ bg: string }>`
  width: calc(100% - 16px);
  height: 72px;
  // max-width: 200px;
  margin: auto;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: 101%;
  border-radius: 9px;
  border: 0;
  outline: 0;
  color: #ffffff;
  font-size: 27px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  span {
    margin-right: 33px;
  }

  &.active {
    border: 4px solid #785d37;
    outline: solid 3px #f79905;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 3rem;
    font-size: 16px;

    span {
      margin-right: 12px;
    }

    &.active {
      border: 3px solid #785d37;
      outline: solid 2px #f79905;
    }
  `}
`;

const StyledNoticeTabBox = styled.div`
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  height: 88px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  height: 4rem;
`}
`;

const StyledContainer = styled.div`
  margin-left: 15rem;
  ${(props) => props.theme.mediaWidth.upToSmall`
    margin-left: 0;
  `}
`;

const StyledContent = styled.ol`
  max-height: 50vh;
  padding: 0 1rem;
  margin-bottom: 1rem;

  overflow-y: scroll;
  margin-top: 0.5rem;
  list-style: none;
  line-break: anywhere;
  white-space: pre-line;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 1rem;
  `}

  > li {
    margin-bottom: 1.5rem;
    width: 100%;

    span {
      font-weight: 600;
    }

    p {
      margin: 5px 0;
      width: 100%;
      word-break: break-all;

      label {
        display: flex;
        :before {
          content: "—";
          padding: 0 1em;
        }
      }
    }
  }

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    background-color: transparent;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      width: 6px;
    `}
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
`;
