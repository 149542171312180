// import { ContractName, TokenStat, TreasuryAllocationTime } from "./types";
import { BigNumber, Contract, ethers, Overrides, utils } from "ethers";
// import { decimalToBalance } from "./ether-utils";
import Web3 from "web3";

import ERC20 from "./ERC20";
// import { getDisplayBalance } from "../utils/formatBalance";
import { getDefaultProvider, testRPC } from "../utils/provider";
import contractInfoMap from "./contractInfoMap";
import { getTarget, setTxParamCache } from "./utils";
import { supportedPools, BasePoolInfo } from "./farms";
import { getWeb3 } from "../utils/provider";
import config, { Configuration, chainId } from "src/config";

import type {
  NFTInfo,
  CustomTxRes,
  ownerflagType,
  Address,
} from "src/services/typings";
// import { promiseCache } from '../services';
import { clearParamCache } from "./utils";
import type { Log } from "web3-core";
import type { Result } from "@ethersproject/abi";
import { getDisplayBalance } from "src/utils/formatBalance";
import { addressDecimalsConfig } from "src/contexts/Market/FluidityContext";

const UNIV2PairAbi = require("./abis/uni_v2_lp.json");
const StrategyAbi = require("./abis/IStrategy.json");

// console.log('depositAbi', depositAbi);

export class Tiny {
  myAccount?: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  boardroomVersionOfUser?: string;
  pools: (BasePoolInfo & {
    lpContract?: Contract;
  })[];

  web3Instance?: Web3;

  constructor(cfg: Configuration) {
    const { externalTokens } = cfg;
    const provider = getDefaultProvider();

    this.contracts = {
      StratPCS: new Contract(
        chainId === 56
          ? "0x0868c52533CD6F124C26d5ACBDb758F268D025ef"
          : "0xC87Dee373D6432E80b0C5AfA6DE37cF6E63D97e7",
        StrategyAbi,
        provider
      ),
    };

    for (const [name, deployment] of Object.entries(contractInfoMap)) {
      this.contracts[name] = new Contract(
        deployment.address,
        deployment.abi,
        provider
      );
    }

    this.externalTokens = {};
    for (const [symbol, address] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, 18);
    }

    this.pools = supportedPools.map((pool) => {
      const lpAddress = pool.lpAddress;
      if (lpAddress) {
        return Object.assign(pool, {
          lpContract: new Contract(lpAddress, UNIV2PairAbi, provider),
        });
      }
      return pool;
    });

    // console.log('this.externalTokens', this.externalTokens);

    this.config = cfg;
    this.provider = provider;
    // this.myAccount = "0xD000c5e49E3eA33ddDAe6c4a933e50ab8ab8fEc8";
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(
      provider,
      this.config.chainId
    );

    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    this.web3Instance = new Web3(provider);

    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = Object.values(this.externalTokens);
    for (const token of tokens) {
      token.connect(this.signer);
    }

    for (const pool of this.pools) {
      if (pool.lpContract) {
        pool.lpContract = pool.lpContract.connect(this.signer);
      }
    }

    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  logout() {
    delete this.myAccount;
    delete this.signer;
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  gasOptions(gas: number, gasPrice: number, nonce?: number): Overrides {
    const multiplied = Math.floor(gas * this.config.gasLimitMultiplier);
    console.log(`⛽️ Gas multiplied: ${gas} -> ${multiplied}`);
    return {
      gasLimit: BigNumber.from(multiplied),
      gasPrice: BigNumber.from(gasPrice),
      nonce,
    };
  }

  gasAmount(contract: Contract, hexString: string) {
    const web3 = getWeb3();
    const account = this.myAccount as string;
    return web3.eth
      .estimateGas({
        from: account,
        to: contract.address,
        data: hexString,
      })
      .then((gas) => {
        console.log("gas2", gas);
        setTxParamCache({
          gas2: gas,
        });
        return gas;
      })
      .catch((err) => {
        const message = err.message;
        if (message && message.includes("Invalid JSON RPC response")) {
          return testRPC().then(() => {
            return web3.eth
              .estimateGas({
                from: account,
                to: contract.address,
                data: hexString,
              })
              .then((gas) => {
                console.log("gas3", gas);
                setTxParamCache({
                  gas3: gas,
                });
                return gas;
              });
          });
        } else {
          throw err;
        }
      });
  }

  async getTxNonce() {
    let nonce: number | undefined;
    const account = this.myAccount;
    if (!this.signer || !account) return;
    try {
      nonce = await this.signer.getTransactionCount();
      console.log("nonce1", nonce);
      setTxParamCache({
        nonce1: nonce,
      });
    } catch (error) {
      console.error(error);
    }
    if (nonce === undefined) {
      try {
        const web3 = getWeb3();
        // const account = this.myAccount as string;
        nonce = await web3.eth.getTransactionCount(account);
        setTxParamCache({
          nonce2: nonce,
        });
        console.log("nonce2", nonce);
      } catch (error) {
        console.error(error);
      }
    }
    return nonce;
  }

  async contractMethodSend(
    contract: Contract,
    method: string,
    params: any[],
    useReceipt = false
  ): Promise<CustomTxRes> {
    let gasPrice = config.chainId === 56 ? 3000000000 : 10000000000;
    clearParamCache();

    const hexString = contract.interface.encodeFunctionData(method, params);
    let gas = await this.gasAmount(contract, hexString);
    let nonce = await this.getTxNonce();
    // console.log('gas, nonce', gas, nonce);
    const web3 = this.web3Instance;
    if (web3) {
      return new Promise((resolve, reject) => {
        return web3.eth
          .sendTransaction({
            from: this.myAccount,
            to: contract.address,
            data: hexString,
            gas: Math.floor(gas * this.config.gasLimitMultiplier),
            gasPrice: gasPrice,
            nonce,
          })
          .on("transactionHash", function (hash) {
            if (!useReceipt) {
              resolve({ hash });
            }
          })
          .on("receipt", function (receipt) {
            if (useReceipt) {
              console.log("receipt", receipt);
              const hash = receipt.transactionHash;
              const blockHash = receipt.blockHash;
              const logs = receipt.logs;
              resolve({ hash, blockHash, logs });
            }
          })
          .on("error", function (error) {
            reject(error);
          });
      });
    }

    try {
      if (!gas) {
        // estimateGas failed
        gas = await contract.estimateGas[method](...params).then((gas) => {
          console.log("gas1", gas);
          setTxParamCache({
            gas1: gas.toNumber(),
          });
          return gas.toNumber();
        });
      }

      return contract[method](...params, this.gasOptions(gas, gasPrice, nonce));
    } catch (error: any) {
      if (
        typeof error === "string" &&
        error.includes("invalid BigNumber value")
      ) {
        throw new Error(
          error + `gas:${gas},gasPrice:${gasPrice},nonce:${nonce}`
        );
      } else {
        throw error;
      }
    }
  }

  async transferToken(token: ERC20, recipient: string, amount: BigNumber) {
    return this.contractMethodSend(token.contract, "transfer", [
      recipient,
      amount,
    ]);
  }

  async transferSpiritToken(recipient: string, amount: number) {
    const TinyNFTRune = this.contracts.TinyNFTRune;
    return this.contractMethodSend(TinyNFTRune, "transferStaminaItem", [
      recipient,
      amount,
    ]);
  }

  /**
   * 质押 NFT 到 NFT 矿池加速挖矿
   * @param {number|string} id
   */
  async stakeNFTToLPFarm(id: number | string): Promise<CustomTxRes> {
    const farm = this.contracts.TinyLPFarm;
    const nftContract = this.contracts.TinyNFT;
    const from = this.myAccount;
    const to = farm.address;
    const data = "0x00";
    return this.contractMethodSend(nftContract, "safeTransferFrom", [
      from,
      to,
      id,
      1,
      data,
    ]);
  }

  async retrieveNFTFromLPFarm(): Promise<CustomTxRes> {
    const farm = this.contracts.TinyLPFarm;
    return this.contractMethodSend(farm, "retrieve", []);
  }

  /**
   * 单个质押 NFT 到 NFTFarming 合约挖矿
   * @param id
   */
  async stakeNFTToNFTFarming(id: number | string): Promise<CustomTxRes> {
    const nftContract = this.contracts.TinyNFT;
    const squadContract = this.contracts.TinyNFTFarm;
    // console.log("nftContract", nftContract);
    // console.log("squadContract.address", squadContract.address);
    const from = this.myAccount;
    const to = squadContract.address;
    const data = "0x00";
    return this.contractMethodSend(nftContract, "safeTransferFrom", [
      from,
      to,
      id,
      1,
      data,
    ]);
  }

  /**
   * 批量质押 NFT 到 NFTFarming 合约批量挖矿
   * @param ids
   */
  async stakeNFTsToNFTFarming(ids: (number | string)[]): Promise<CustomTxRes> {
    const nftContract = this.contracts.TinyNFT;
    const squadContract = this.contracts.TinyNFTFarm;
    // console.log("nftContract", nftContract);
    // console.log("squadContract.address", squadContract.address);

    const from = this.myAccount;
    const to = squadContract.address;
    const amounts = ids.map(() => 1);
    const data = "0x00";
    return this.contractMethodSend(nftContract, "safeBatchTransferFrom", [
      from,
      to,
      ids,
      amounts,
      data,
    ]);
  }

  async retrieveNFTFromNFTFarming(ids: number[]): Promise<CustomTxRes> {
    const nftFarm = this.contracts.TinyNFTFarm;
    return this.contractMethodSend(nftFarm, "retrieve", [ids]);
  }

  async retrieveAndStakeToNFTFarming(
    retrieveIds: number[],
    stakeIds: number[]
  ) {
    const nftFarm = this.contracts.TinyNFTFarm;
    return this.contractMethodSend(nftFarm, "retrieveAndEnter", [
      retrieveIds,
      stakeIds,
    ]);
  }

  // 宠物批量挖矿- 替换宠物
  async retrieveAndStakeToPetsFarming(
    retrieveIds: number[],
    stakeIds: number[]
  ) {
    const petFarm = this.contracts.PetsFarming;
    return this.contractMethodSend(petFarm, "retrieveAndEnter", [
      retrieveIds,
      stakeIds,
    ]);
  }

  // 质押 LP 到 farm
  async depositLPToFarm(amount: BigNumber): Promise<CustomTxRes> {
    const farm = this.contracts.TinyLPFarm;
    return this.contractMethodSend(farm, "deposit", [amount]);
  }

  // 提取 LP 到 farm
  async withdrawLPFromFarm(amount: BigNumber): Promise<CustomTxRes> {
    const farm = this.contracts.TinyLPFarm;
    return this.contractMethodSend(farm, "withdraw", [amount]);
  }

  // 收获 NFT 小分队的收益
  async claimSquadRewards(): Promise<CustomTxRes> {
    const squadContract = this.contracts.TinyNFTFarm;
    return this.contractMethodSend(squadContract, "claim", []);
  }

  // 收获 Pets 小分队的收益
  async claimSquadPetsRewards(): Promise<CustomTxRes> {
    const petsFarmingContract = this.contracts.PetsFarming;
    return this.contractMethodSend(petsFarmingContract, "claimReward", []);
  }

  // 质押资金到机枪池
  async stakeTokenToYieldFarming(
    pid: number,
    amount: BigNumber
  ): Promise<CustomTxRes> {
    const YieldFarming = this.contracts.YieldFarming;
    // console.log("pid", pid);
    // console.log("amount", amount);
    return this.contractMethodSend(YieldFarming, "deposit", [pid, amount]);
  }

  // async stakeBNBToYieldFarming(
  //   pid: number,
  //   amount: BigNumber
  // ): Promise<CustomTxRes> {
  //   const YieldFarming = this.contracts.YieldFarming;
  //   const gas = await YieldFarming.estimateGas.depositBNB(pid, {
  //     value: amount,
  //   });
  //   return YieldFarming.depositBNB(pid, {
  //     ...this.gasOptions(gas),
  //     value: amount,
  //   });
  // }

  async unstakeTokenFromYieldFarming(
    pid: number,
    amount: BigNumber
  ): Promise<CustomTxRes> {
    const masterChef = this.contracts.YieldFarming;
    return this.contractMethodSend(masterChef, "withdraw", [pid, amount]);
  }

  // TODO: 1 池挖矿奖励，一次性领取
  async claimAllRewards(pids: number[]): Promise<CustomTxRes> {
    const yieldFarmingContract = this.contracts.YieldFarming;
    // console.log('pids', pids);
    return this.contractMethodSend(yieldFarmingContract, "getRewards", [pids]);
  }

  /** ------------------ 盲盒系列 -------------------- */
  // 日常盲盒，使用 TINC 买盲盒
  async buyBlindBoxDaily(
    number: number | string,
    referral?: string,
    invitationTime?: number
  ): Promise<CustomTxRes> {
    const BoxContract = this.contracts.TinyNFTBlindBoxDaily;
    if (referral) {
      // console.log("buyBoxWithRef", referral, invitationTime);
      return this.contractMethodSend(BoxContract, "buyBoxWithRef", [
        number,
        referral,
        invitationTime,
      ]);
    }
    // console.log("buyBox");
    return this.contractMethodSend(BoxContract, "buyBox", [number]);
  }

  // 日常盲盒，开盲盒
  async openBlindBoxDaily(count: number): Promise<CustomTxRes> {
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxDaily;
    return this.contractMethodSend(TinyNFTBlindBoxContract, "openBox", [count]);
  }

  /**
   * 升级 NFT
   * _ownerflag 目标英雄所在的位置：0--NFT在钱包地址，1--NFT在质押挖矿，2--NFT在LP挖矿，默认0
   * @param id
   * @param nfts
   * @returns
   */
  async upgradeNFT(
    id: number | string,
    ownerflag: ownerflagType,
    nfts: NFTInfo[]
  ): Promise<CustomTxRes> {
    // const { id, masterAddress } = nft;
    const ids: number[] = [];
    const status: number[] = [];
    nfts.forEach((ele) => {
      ids.push(ele.id);
      status.push(ele.ownerflag);
    });

    const upgradeContract = this.contracts.TinyNFTLogic;
    return this.contractMethodSend(upgradeContract, "levelUpHero", [
      id,
      ownerflag,
      ids,
      status,
    ]);
  }

  /**
   * NFT 换成灵魂绑定的SPIRIT
   * _ownerflag 目标英雄所在的位置：0--NFT在钱包地址，1--NFT在质押挖矿，2--NFT在LP挖矿，默认0
   * @param id
   * @param nfts
   * @returns
   */
  async exchangeNFT(nfts: NFTInfo[]): Promise<CustomTxRes> {
    const ids: number[] = [];
    const status: number[] = [];
    nfts.forEach((ele) => {
      ids.push(ele.id);
      status.push(ele.ownerflag);
    });

    const TinyNFTLogicContract = this.contracts.TinyNFTLogic;
    return this.contractMethodSend(TinyNFTLogicContract, "exchange", [
      ids,
      status,
    ]);
  }

  /**
   * 提升 NFT 的 power
   * @param tokenId NFT 的 id
   * @param ownerflag
   * @param point
   */
  powerUp(tokenId: NumberLike, ownerflag: ownerflagType, point: number) {
    const TinyNFTLogic = this.contracts.TinyNFTLogic;
    return this.contractMethodSend(TinyNFTLogic, "powerUp", [
      tokenId,
      ownerflag,
      point,
    ]);
  }

  /**
   * 批量提升 NFT 的 power
   * @param tokenIds NFT 的 id
   * @param ownerflags
   * @param points
   */
  powerUpBatch(
    tokenIds: NumberLike[],
    ownerflags: ownerflagType[],
    points: number[]
  ) {
    const TinyNFTLogic = this.contracts.TinyNFTLogic;
    return this.contractMethodSend(TinyNFTLogic, "powerUpBatch", [
      tokenIds,
      ownerflags,
      points,
    ]);
  }

  // 领取空投的方法
  async claimAirdrop(): Promise<CustomTxRes> {
    const airdropContract = this.contracts.TinyAirdrop;
    return this.contractMethodSend(airdropContract, "claim", []);
  }

  // 领取gas的方法
  async claimGas(): Promise<CustomTxRes> {
    const airdropNewContract = this.contracts.airdropNew;
    return this.contractMethodSend(airdropNewContract, "claimGas", []);
  }

  // 领取Spirit(体力恢复道具)的方法
  async claimSpirit(): Promise<CustomTxRes> {
    const airdropNewContract = this.contracts.airdropNew;
    return this.contractMethodSend(airdropNewContract, "claimSpirit", []);
  }

  /** ---------------- 交易市场相关 ------------------ */

  /**
   * 出售 NFT
   * @param {number} ids
   * @param {number[]} ids
   * @param {BigNumber} _startingPrice
   * @param {BigNumber} _endingPrice
   * @param {number} _duration 时间，单位秒。默认值是 1 天
   * @returns
   */
  async createAuction(
    tokenId: number,
    ids: number[],
    _startingPrice: BigNumber,
    _endingPrice: BigNumber,
    _duration = 86400
  ): Promise<CustomTxRes> {
    const marketPlace = this.contracts.MarketPlace;
    return this.contractMethodSend(marketPlace, "createAuction", [
      _startingPrice,
      _endingPrice,
      _duration,
      tokenId,
      ids,
    ]);
  }

  /**
   * NFT 修改价格
   * @param {number} ids
   * @param {number[]} ids
   * @param {BigNumber} _startingPrice
   * @param {BigNumber} _endingPrice
   * @param {number} _duration 时间，单位秒。默认值是 1 天
   * @returns
   */
  async changeAuctionPrice(
    auctionId: number,
    _startingPrice: BigNumber,
    _endingPrice: BigNumber,
    _duration = 86400
  ): Promise<CustomTxRes> {
    const marketPlace = this.contracts.MarketPlace;
    return this.contractMethodSend(marketPlace, "changePrice", [
      auctionId,
      _startingPrice,
      _endingPrice,
      _duration,
    ]);
  }

  /**
   * PET 修改价格
   * @param {number} ids
   * @param {number[]} ids
   * @param {BigNumber} _startingPrice
   * @param {BigNumber} _endingPrice
   * @param {number} _duration 时间，单位秒。默认值是 1 天
   * @returns
   */
  async changePetAuctionPrice(
    auctionId: number,
    _startingPrice: BigNumber,
    _endingPrice: BigNumber,
    _duration = 86400
  ): Promise<CustomTxRes> {
    const marketPlace = this.contracts.PetsMarket;
    return this.contractMethodSend(marketPlace, "changePrice", [
      auctionId,
      _startingPrice,
    ]);
  }

  // 竞拍
  async bid(
    id: number,
    bidPrice: BigNumber,
    referral?: string,
    invitationTime?: number
  ) {
    const marketPlace = this.contracts.MarketPlace;
    if (referral && invitationTime) {
      // console.log("bidWithRef", referral, invitationTime);
      return this.contractMethodSend(marketPlace, "bidWithRef", [
        id,
        bidPrice,
        referral,
        invitationTime,
      ]);
    }
    // console.log("just bid:", id, bidPrice);
    return this.contractMethodSend(marketPlace, "bid", [id, bidPrice]);
  }

  // 竞拍宠物
  async petBid(
    id: number,
    bidPrice: BigNumber,
    referral?: string,
    invitationTime?: number
  ) {
    const marketPlace = this.contracts.PetsMarket;
    if (referral && invitationTime) {
      // console.log("bidWithRef", referral, invitationTime);
      return this.contractMethodSend(marketPlace, "bidWithRef", [
        id,
        bidPrice,
        referral,
        invitationTime,
      ]);
    }
    // console.log("just bid:", id, bidPrice);
    return this.contractMethodSend(marketPlace, "bid", [id, bidPrice]);
  }

  // 批量购买
  async bidBatch(
    idList: number[],
    priceList: BigNumber[],
    referral?: string,
    invitationTime?: number
  ) {
    const marketPlace = this.contracts.MarketPlace;
    if (referral) {
      return this.contractMethodSend(marketPlace, "bidBatchWithRef", [
        idList,
        priceList,
        referral,
        invitationTime,
      ]);
    }
    return this.contractMethodSend(marketPlace, "bidBatch", [
      idList,
      priceList,
    ]);
  }

  // 批量购买宠物
  async bidPetsBatch(
    idList: number[],
    priceList: BigNumber[],
    referral?: string,
    invitationTime?: number
  ) {
    const marketPlace = this.contracts.PetsMarket;
    console.log({
      idList,
      priceList,
      referral,
      invitationTime,
    });
    if (referral) {
      return this.contractMethodSend(marketPlace, "bidBatchWithRef", [
        idList,
        priceList,
        referral,
        invitationTime,
      ]);
    }
    return this.contractMethodSend(marketPlace, "bidBatch", [
      idList,
      priceList,
    ]);
  }

  // 取消拍卖
  async cancelAuction(auctionId: number): Promise<CustomTxRes> {
    const marketPlace = this.contracts.MarketPlace;
    return this.contractMethodSend(marketPlace, "cancelAuction", [auctionId]);
  }

  // 取消宠物拍卖
  async cancelPetAuction(auctionId: number): Promise<CustomTxRes> {
    const marketPlace = this.contracts.PetsMarket;
    return this.contractMethodSend(marketPlace, "cancelAuction", [auctionId]);
  }

  /// 预售交易
  // 预售出售
  async createRuneAuction(
    _price: BigNumber,
    tokenId: number,
    amount: number
  ): Promise<CustomTxRes> {
    const runeMarket = this.contracts.RuneMarket;
    // console.log("_price", _price);
    return this.contractMethodSend(runeMarket, "createAuction", [
      _price,
      tokenId,
      amount,
    ]);
  }

  // 预售改价
  async changeRuneAuctionPrice(
    auctionId: number,
    _price: BigNumber
  ): Promise<CustomTxRes> {
    const runeMarket = this.contracts.RuneMarket;
    return this.contractMethodSend(runeMarket, "changePrice", [
      auctionId,
      _price,
    ]);
  }

  // 预售竞拍
  async runeBid(
    auctionId: number,
    bidPrice: BigNumber,
    referral?: string,
    invitationTime?: number
  ) {
    const runeMarket = this.contracts.RuneMarket;
    if (referral) {
      // console.log("bidWithRef", referral);
      return this.contractMethodSend(runeMarket, "bidWithRef", [
        auctionId,
        bidPrice,
        referral,
        invitationTime,
      ]);
    }
    return this.contractMethodSend(runeMarket, "bid", [auctionId, bidPrice]);
  }

  // 批量购买
  async runeBidBatch(
    idList: number[],
    priceList: BigNumber[],
    referral?: string,
    invitationTime?: number
  ) {
    const marketPlace = this.contracts.RuneMarket;
    if (referral) {
      return this.contractMethodSend(marketPlace, "bidBatchWithRef", [
        idList,
        priceList,
        referral,
        invitationTime,
      ]);
    }
    return this.contractMethodSend(marketPlace, "bidBatch", [
      idList,
      priceList,
    ]);
  }

  // 取消预售拍卖
  async cancelRuneAuction(auctionId: number): Promise<CustomTxRes> {
    const runeMarket = this.contracts.RuneMarket;
    return this.contractMethodSend(runeMarket, "cancelAuction", [auctionId]);
  }

  /** 质押到游戏相关的操作 */

  // 将矿池中的NFTs质押到游戏中
  // depositHeroes
  async depositFarmHeroes(
    ids: number[],
    farmIds: number[]
  ): Promise<CustomTxRes> {
    const gatewayContract = this.contracts.TinyGateway;
    console.log({
      ids,
      farmIds,
    });
    return this.contractMethodSend(gatewayContract, "depositHeroes", [
      ids,
      farmIds,
    ]);
  }
  // 将 NFT 充到游戏中
  async depositNFTToGaming(tokenId: number | string): Promise<CustomTxRes> {
    const nftContract = this.contracts.TinyNFT;
    const gatewayContract = this.contracts.TinyGateway;
    return this.contractMethodSend(nftContract, "safeTransferFrom", [
      this.myAccount,
      gatewayContract.address,
      tokenId,
      1,
      "0x00",
    ]);
  }

  // 将 Pet 充到游戏中
  async depositPetToGaming(tokenId: number | string): Promise<CustomTxRes> {
    const nftContract = this.contracts.Pets;
    const gatewayContract = this.contracts.TinyGateway;
    return this.contractMethodSend(nftContract, "safeTransferFrom", [
      this.myAccount,
      gatewayContract.address,
      tokenId,
    ]);
  }

  // 将 NFTs 充到游戏中
  async batchDepositNFTsToGaming(ids: number[]): Promise<CustomTxRes> {
    const nftContract = this.contracts.TinyNFT;
    const gatewayContract = this.contracts.TinyGateway;
    const amounts = new Array(ids.length).fill(1);
    return this.contractMethodSend(nftContract, "safeBatchTransferFrom", [
      this.myAccount,
      gatewayContract.address,
      ids,
      amounts,
      "0x00",
    ]);
  }

  // 将 Pets 充到游戏中
  async batchDepositPetsToGaming(ids: number[]): Promise<CustomTxRes> {
    const nftContract = this.contracts.Pets;
    const gatewayContract = this.contracts.TinyGateway;
    return this.contractMethodSend(nftContract, "batchTransferFromByIds", [
      this.myAccount,
      gatewayContract.address,
      ids,
    ]);
  }

  // async retrieveNFTFromGame(ids: number[]): Promise<CustomTxRes> {
  //   const gatewayContract = this.contracts.TinyGateway;
  //   return this.contractMethodSend(gatewayContract, "retrieve", [ids]);
  // }

  // async retrieveAndStakeToGame(retrieveIds: number[], stakeIds: number[]) {
  //   const gatewayContract = this.contracts.TinyGateway;
  //   return this.contractMethodSend(gatewayContract, "retrieveAndEnter", [
  //     retrieveIds,
  //     stakeIds,
  //   ]);
  // }

  // buy presale runes
  async orderPresaleRune(
    amount: number,
    price: BigNumber
  ): Promise<CustomTxRes> {
    const presale = this.contracts.PetPresale;
    // return this.contractMethodSend(presale, "roundOrder", [])
    const gas = await presale.estimateGas.order(amount, {
      value: price.mul(amount),
    });
    let gasPrice = config.chainId === 56 ? 3000000000 : 10000000000;
    return presale.order(amount, {
      ...this.gasOptions(gas.toNumber(), gasPrice),
      value: price.mul(amount),
    });
  }

  // buy presale runes
  async redeemRune(): Promise<CustomTxRes> {
    const presale = this.contracts.PetPresale;
    return this.contractMethodSend(presale, "redeem", []);
  }

  async unsealPresaleRune(amount: number): Promise<CustomTxRes> {
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxDaily;
    return this.contractMethodSend(
      TinyNFTBlindBoxContract,
      "unsealPresaleRune",
      [amount]
    );
  }

  async unsealPresaleRuneV2(amount: number): Promise<CustomTxRes> {
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxDaily;
    return this.contractMethodSend(
      TinyNFTBlindBoxContract,
      "unsealPresaleRuneV2",
      [amount]
    );
  }

  /** Tiny Dao相关的操作 */
  // 锁仓
  async createLock(
    amount: BigNumber,
    unlockTime: BigNumber
  ): Promise<CustomTxRes> {
    const votingEscrow = this.contracts.VotingEscrow;
    return this.contractMethodSend(votingEscrow, "createLock", [
      amount,
      unlockTime,
    ]);
  }

  // 增加锁仓
  async increaseAmount(
    account: string,
    amount: BigNumber
  ): Promise<CustomTxRes> {
    const votingEscrow = this.contracts.VotingEscrow;
    // console.log("amount", amount, "account", account);
    return this.contractMethodSend(votingEscrow, "increaseAmount", [
      account,
      amount,
    ]);
  }

  // 延长锁仓时间
  async increaseUnlockTime(unlockTime: number): Promise<CustomTxRes> {
    const votingEscrow = this.contracts.VotingEscrow;
    console.log("unlockTime", unlockTime);
    return this.contractMethodSend(votingEscrow, "increaseUnlockTime", [
      unlockTime,
    ]);
  }

  // claim dao ve
  async claimDAOVe(): Promise<CustomTxRes> {
    const votingEscrow = this.contracts.VotingEscrow;
    return this.contractMethodSend(votingEscrow, "withdraw", []);
  }

  async checkIn() {
    const login = this.contracts.Login;
    return this.contractMethodSend(login, "checkin", []);
  }

  farmMigrate(pid: number) {
    const yieldFarming = this.contracts.YieldFarming;
    return this.contractMethodSend(yieldFarming, "migrate", [pid]);
  }

  farmMigrateByAccount(account: string) {
    const TinyLPFarm = this.contracts.TinyLPFarm;
    return this.contractMethodSend(TinyLPFarm, "migrate", []);
  }

  claimReferralRewards() {
    const referralContract = this.contracts.Referral;
    return this.contractMethodSend(referralContract, "claimRewards", []);
  }

  claimDaoReward(account: string) {
    const daoStaking = this.contracts.DaoStaking;
    return this.contractMethodSend(daoStaking, "claimReward", [account]);
  }

  claimLockedReward(account: string) {
    const lockedContract = this.contracts.LockedPosition;
    return this.contractMethodSend(lockedContract, "withdraw", [account]);
  }

  claimInvestReward(account: string) {
    const investorContract = this.contracts.InvestorRelease;
    return this.contractMethodSend(investorContract, "claimReward", [account]);
  }

  claimCheckinRewards() {
    const checkinReward = this.contracts.CheckinReward;
    return this.contractMethodSend(checkinReward, "claimRewards", []);
  }

  unsealTickets(ids: number[]) {
    const ticketOpen = this.contracts.TinyNFTTicketOpen;
    return this.contractMethodSend(ticketOpen, "unsealTickets", [ids]);
  }

  openTickets(count: number) {
    const ticketOpen = this.contracts.TinyNFTTicketOpen;
    return this.contractMethodSend(ticketOpen, "openTickets", [count]);
  }

  parsePrepareGameEvent(logs: Log[]): Result | undefined {
    const lord = this.contracts.TinyLord;
    const prepareLog = logs.find((ele) => ele.data.length > 130);
    if (!prepareLog) return;
    // console.log("prepareLog", prepareLog);
    const eventResult = lord.interface.decodeEventLog(
      "PrepareAttack",
      prepareLog.data,
      prepareLog.topics
    );
    return eventResult;
  }

  /**
   * 向城堡发起进攻
   * @param {number[]} tokenIds 需要传3个NFT的id
   * @param {number} target
   * @returns
   */
  prepareGame(tokenIds: number[], castleLevel: 1 | 2 | 3, castleIndex: number) {
    const lord = this.contracts.TinyLord;
    const target = getTarget(castleLevel, castleIndex);
    return this.contractMethodSend(lord, "prepareGame", [tokenIds, target]);
  }

  // 占领城堡
  perform(attackIndex: number) {
    const lord = this.contracts.TinyLord;
    return this.contractMethodSend(lord, "perform", [attackIndex]);
  }

  prepareBatch(tokenIds: number[][], targets: number[]) {
    const lord = this.contracts.TinyLord;
    return this.contractMethodSend(lord, "prepareBatch", [tokenIds, targets]);
  }

  // 批量占领城堡
  performBatch(attackIndexes: number[]) {
    const lord = this.contracts.TinyLord;
    return this.contractMethodSend(lord, "performBatch", [attackIndexes]);
  }

  // 收获所有城堡奖励
  claimLordAllRewards(targets: number[]) {
    const lord = this.contracts.TinyLord;
    return this.contractMethodSend(lord, "claimRewards", [targets]);
  }

  leaveCastle(castleLevel: 1 | 2 | 3, castleIndex: number) {
    const lord = this.contracts.TinyLord;
    const target = getTarget(castleLevel, castleIndex);
    return this.contractMethodSend(lord, "leaveCastle", [target]);
  }

  // 对当前拍卖的 UR 出价，不得低于上次出价的 110%
  bidURAuction(price: string) {
    const urAuction = this.contracts.URAuction;
    return this.contractMethodSend(urAuction, "bid", [price]);
  }

  // URAuction 的 id
  unsealUR(id: number) {
    const urAuction = this.contracts.URAuction;
    return this.contractMethodSend(urAuction, "unseal", [id]);
  }

  // URAuction 的 id
  claimUR(id: number) {
    const urAuction = this.contracts.URAuction;
    return this.contractMethodSend(urAuction, "claim", [id]);
  }

  parseClaimAuctionEvent(logs: Log[]): Result | undefined {
    const nft = this.contracts.TinyNFT;
    const prepareLog = logs.find((ele) => ele.data.length > 130);
    if (!prepareLog) return;
    // console.log("prepareLog", prepareLog);
    const eventResult = nft.interface.decodeEventLog(
      "Mint",
      prepareLog.data,
      prepareLog.topics
    );
    return eventResult;
  }

  // BAB Check-in
  activeCheckIn() {
    const ActiveCheckIn = this.contracts.ActiveCheckIn;
    return this.contractMethodSend(ActiveCheckIn, "checkIn", []);
  }

  addStamina(id: NumberLike, amount: NumberLike) {
    const TinyNFTStamina = this.contracts.TinyNFTStamina;
    return this.contractMethodSend(TinyNFTStamina, "recoverStamina", [
      id,
      amount,
    ]);
  }

  addStaminaBatch(ids: NumberLike[], amounts: NumberLike[]) {
    const TinyNFTStamina = this.contracts.TinyNFTStamina;
    return this.contractMethodSend(TinyNFTStamina, "batchRecover", [
      ids,
      amounts,
    ]);
  }

  claimRankReward(amount: string, nonce: number, signature: string) {
    const LordRankReward = this.contracts.lordReward;
    return this.contractMethodSend(LordRankReward, "claim", [
      amount,
      nonce,
      signature,
    ]);
  }

  petsBreed(maleId: number, femaleId: number) {
    const PetsBreed = this.contracts.PetLogic;
    return this.contractMethodSend(PetsBreed, "breed", [maleId, femaleId]);
  }

  // 出售宠物
  async createPetsAuction(
    price: BigNumber,
    tokenId: number
  ): Promise<CustomTxRes> {
    const petsMarket = this.contracts.PetsMarket;

    return this.contractMethodSend(petsMarket, "createAuction", [
      price,
      tokenId,
    ]);
  }

  // lordOnline
  async claimLordOnlineRewards(
    amount: string,
    nonce: number,
    signature: string
  ) {
    const lordOnlineRewardContract = this.contracts.LordOnlineReward;
    return this.contractMethodSend(lordOnlineRewardContract, "claim", [
      amount,
      nonce,
      signature,
    ]);
  }

  // startLordOnline
  async lordOnlineCheckin() {
    const lordOnlineRewardContract = this.contracts.LordOnlineReward;
    return this.contractMethodSend(
      lordOnlineRewardContract,
      "startLordOnline",
      []
    );
  }

  /* 
  @parmas 
    amountIn 交换的数值
    addressArr 货币地址   [tincAddress,SPIRITAddress]
  */
  async getAmountsOut(
    amountIn: string,
    addressArr: string[],
    inType: "TINC" | "SPIRIT"
  ) {
    try {
      const iTinySwapRouterContract = this.contracts.ITinySwapRouter;

      const value = utils.parseUnits(amountIn, inType === "TINC" ? 18 : 0);
      return iTinySwapRouterContract.getAmountsOut(
        utils.parseUnits(amountIn, inType === "TINC" ? 18 : 0),
        addressArr
      );
    } catch (error) {
      console.error(error);
    }
  }

  /* 
  @parmas 
    amountOut 交换的数值
    addressArr 货币地址   [tincAddress,SPIRITAddress]
  */
  async getAmountsIn(
    amountOut: string,
    addressArr: string[],
    outType: "TINC" | "SPIRIT"
  ) {
    try {
      const iTinySwapRouterContract = this.contracts.ITinySwapRouter;
      const value = utils.parseUnits(amountOut, outType === "TINC" ? 18 : 0);
      return iTinySwapRouterContract.getAmountsIn(value, addressArr);
    } catch (error) {
      console.error(error);
    }
  }

  async getTincSpiritPair(address: string, address2: string) {
    const iTinySwapRouterContract = this.contracts.ITinySwapRouter;
    return iTinySwapRouterContract.getPair(address, address2);
  }

  // 通过精确的输出货币 付出输入货币
  // 要获得 100spirit  可能需要 105 tinc 或者 100 tinc
  async swapTokensForExactTokens(
    amountOut: string, //输出的
    amountInMax: string, //最多要付出的
    addressArr: string[],
    address: Address, //转账给谁
    deadline: number, //交易过期时间  = 当前时间 + 设置过期时间的时间戳
    outType: "TINC" | "SPIRIT"
  ) {
    const iTinySwapRouterContract = this.contracts.ITinySwapRouter;
    const value = utils.parseUnits(
      amountOut,
      outType === "TINC"
        ? addressDecimalsConfig.TINC.decimals
        : addressDecimalsConfig.SPIRIT.decimals
    );

    const valueInMax = utils.parseUnits(
      outType === "TINC"
        ? Math.floor(Number(amountInMax)).toString()
        : amountInMax,
      outType === "TINC"
        ? addressDecimalsConfig.SPIRIT.decimals
        : addressDecimalsConfig.TINC.decimals
    );

    return this.contractMethodSend(
      iTinySwapRouterContract,
      "swapTokensForExactTokens",
      [value, valueInMax, addressArr, address, deadline]
    );
  }

  // 通过精确的输入货币 获取到 最少可获得的其他货币
  // 付出 100tinc 可能获得 95 spirit 或者 100spirit
  async swapExactTokensForTokens(
    amountIn: string, //输入的
    amountOutMin: string, //最少可以获得的
    addressArr: string[],
    address: Address, //转账给谁
    deadline: number, //交易过期时间  = 当前时间 + 设置过期时间的时间戳
    inType: "TINC" | "SPIRIT"
  ) {
    const iTinySwapRouterContract = this.contracts.ITinySwapRouter;

    const value = utils.parseUnits(
      amountIn,
      inType === "TINC"
        ? addressDecimalsConfig.TINC.decimals
        : addressDecimalsConfig.SPIRIT.decimals
    );

    const valueOutMin = utils.parseUnits(
      inType === "TINC"
        ? Math.floor(Number(amountOutMin)).toString()
        : amountOutMin,
      inType === "TINC"
        ? addressDecimalsConfig.SPIRIT.decimals
        : addressDecimalsConfig.TINC.decimals
    );

    return this.contractMethodSend(
      iTinySwapRouterContract,
      "swapExactTokensForTokens",
      [value, valueOutMin, addressArr, address, deadline]
    );
  }

  // addLiquidity  添加 tinc/spirit流动性
  async addTincSpiritLiquidity(
    tincToken: Address, //tokenA
    spiritToken: Address, //tokenB
    tincDesired: string, //TINC
    spiritDesired: string, //SPIRIT
    tincMin: string,
    spiritMin: string,
    account: Address, //转账给谁
    deadline: number //交易过期时间  = 当前时间 + 设置过期时间的时间戳
  ) {
    const iTinySwapRouterContract = this.contracts.ITinySwapRouter;

    const params = [
      tincToken,
      spiritToken,
      utils.parseUnits(tincDesired, addressDecimalsConfig.TINC.decimals),
      utils.parseUnits(spiritDesired, addressDecimalsConfig.SPIRIT.decimals),
      utils.parseUnits(tincMin, addressDecimalsConfig.TINC.decimals),
      utils.parseUnits(spiritMin, addressDecimalsConfig.SPIRIT.decimals),
      account,
      deadline,
    ];
    return this.contractMethodSend(
      iTinySwapRouterContract,
      "addLiquidity",
      params
    );
  }

  // addLiquidity  添加 tinc/spirit流动性
  async removeTincSpiritLiquidity(
    tincToken: Address, //tokenA
    spiritToken: Address, //tokenB
    liquidity: BigNumber, //要减去多少流动性就传多少流动性
    tincMin: BigNumber,
    spiritMin: BigNumber,
    account: Address, //转账给谁
    deadline: number //交易过期时间  = 当前时间 + 设置过期时间的时间戳
  ) {
    const iTinySwapRouterContract = this.contracts.ITinySwapRouter;

    // tincMin = '1'
    // spiritMin = '1'
    const params = [
      tincToken,
      spiritToken,
      liquidity,
      tincMin,
      spiritMin,
      account,
      deadline,
    ];

    return this.contractMethodSend(
      iTinySwapRouterContract,
      "removeLiquidity",
      params
    );
  }

  // 将流动性存入新的LP池
  // amount 传输入的流动性
  async depositLPv2(amount: BigNumber) {
    const tinyLPBoostMintContract = this.contracts.TinyLPBoostMint;

    const params = [amount];

    return this.contractMethodSend(tinyLPBoostMintContract, "deposit", params);
  }

  // 将流动性移除新的LP池
  // amount 传输入的流动性
  async withdrawLPv2(amount: BigNumber) {
    const tinyLPBoostMintContract = this.contracts.TinyLPBoostMint;

    const params = [amount];

    return this.contractMethodSend(tinyLPBoostMintContract, "withdraw", params);
  }
}
