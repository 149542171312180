import { useCallback, useState, useEffect } from "react";
import { useTransactionAdder } from "../state/transactions/hooks";
import useTiny from "./useTiny";
import type { TransactionResponse } from "@ethersproject/providers";
import type { Contract } from "ethers";

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

const cache: {
  [account: string]: ApprovalState;
} = {};

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useNFTApprove(
  nftContract: Contract, // ERC-1155专用
  approver: string
): [ApprovalState, () => Promise<void | TransactionResponse>] {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const [approvalState, setState] = useState(
    account ? cache[account] : ApprovalState.UNKNOWN
  );

  useEffect(() => {
    if (account) cache[account] = approvalState;
  }, [approvalState, account]);

  const getApproved = useCallback(() => {
    if (account) {
      nftContract.isApprovedForAll(account, approver).then((res: boolean) => {
        console.log("isApprovedForAll res", res);
        setState(res ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED);
      });
    }
  }, [account, nftContract, approver]);

  useEffect(() => {
    getApproved();
  }, [getApproved]);

  const addTransaction = useTransactionAdder();

  const approve = useCallback(async (): Promise<void | TransactionResponse> => {
    const response: TransactionResponse = await nftContract.setApprovalForAll(
      approver,
      true
    );
    // console.log("response", response);
    addTransaction(response, {
      summary: `Approve NFT`,
    });
    setState(ApprovalState.PENDING);

    await response.wait();
    getApproved();
  }, [nftContract, approver, addTransaction, getApproved]);

  return [approvalState, approve];
}

export default useNFTApprove;
