import { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { utils } from "ethers";

import useDescByCode from "src/hooks/useDescByCode";
import useIsMobile from "src/hooks/useIsMobile";
import { Context as TinyContext } from "src/contexts/TinyProvider";
import useTiny from "src/hooks/useTiny";
import { Context } from "src/contexts/Modals";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";

import PrimaryButton from "src/components/Button/PrimaryButton";
import StaminaModal from "src/components/StaminaWarnModal";
import DeleteSvg from "src/components/ShoppingCart/DeleteSvg";

import { getHeroUrl } from "src/utils/getNFTUrl";
import {
  BLOCKS_PER_DAY,
  formatMarketPrice,
  getNameFromDesc,
  trimZero,
} from "src/utils/index";
import { gradeDurability } from "src/config";
import { ApprovalState } from "src/hooks/useApprove";

import addSvg from "src/images/shopping/add.svg";
import restore from "src/images/props/restore.png";

import type { NFTInfo } from "src/services/typings";
import { RuneAuction } from "src/services/auctions";

const quickShoppingSpirit = (spiritId: string) => {
  const isMobile = window.innerWidth < 500;

  const spiritImg = document.getElementById(spiritId);

  if (!spiritImg) {
    return;
  }
  const origin = spiritImg.getBoundingClientRect();
  const shopElement = document.querySelector(".shopping-cart-btn");
  if (!shopElement) {
    return;
  }
  const target = shopElement.getBoundingClientRect();

  // 快捷购买弹窗没有缩放
  const originX = origin.left;
  const originY = origin.top;
  const targetX = target.left;
  const targetY = target.top;

  // 创建ufo
  const ufo = document.createElement("img");
  ufo.id = "ufo" + spiritId;
  ufo.src = restore;
  ufo.style.position = "absolute";
  ufo.style.zIndex = "1000";
  ufo.style.width = isMobile ? "53px" : "64px";
  ufo.style.height = isMobile ? "49px" : "59px";
  if (isMobile) {
    // ufo.style.transform = `scale(0.72)`;
  }
  document.body.appendChild(ufo);
  ufo.style.left = originX + "px";
  ufo.style.top = originY + "px";

  ufo.className = "shop-transition";

  // 结束删除
  ufo.ontransitionend = function () {
    if (ufo && ufo.parentElement == document.body) {
      document.body.removeChild(ufo);
    }
  };

  setTimeout(() => {
    // console.log("setTimeout", Date.now());
    ufo.style.left = targetX + "px";
    ufo.style.top = targetY + "px";
    ufo.style.transform = `scale(0.2)`;
    setTimeout(() => {
      if (ufo && ufo.parentElement == document.body) {
        document.body.removeChild(ufo);
      }
    }, 500);
  }, 16);
};

interface Props {
  id: number;
  price: string | number;
  info: RuneAuction;
  onDelete(id: number): void;
  onAdd(): void;
  inShoppingList: boolean;
  isFull: boolean;
  approve(): void;
  approveStatus: ApprovalState;
}
export default function QuickCartSpiritInfo(props: Props) {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const {
    id,
    price,
    info,
    onDelete,
    onAdd,
    inShoppingList,
    isFull,
    approve,
    approveStatus,
  } = props;

  const displayPrice = useMemo(() => {
    return new BigNumber(price).div(1e6).toNumber();
  }, [price]);

  const [inBuying, setInBuying] = useState(false);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const handleAdd = useCallback(() => {
    quickShoppingSpirit("SPIRIT-QUICK-BUY-" + info.id);
    onAdd();
  }, [info, onAdd]);

  const { userInfo } = useContext(TinyContext);
  const tiny = useTiny();

  const { onTxModalPresent, onTxModalDismiss } = useContext(Context);

  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  const referral = userInfo?.inviter;
  const invitationTime = userInfo?.invitationTime;

  const handleBuy = useCallback(() => {
    const message = t("Buy NFT");
    // console.log("referral", referral);
    return handleTransactionReceipt(
      tiny.runeBid(
        id,
        utils.parseUnits(String(price), 12),
        referral,
        invitationTime
      ),
      message,
      [id, price, referral, invitationTime].toString()
    )
      .then((tx) => {
        setInBuying(true);
        if (tx) {
          handleTxConfirm(tx.hash);
        }
      })
      .catch((err) => {
        console.error("handleBuy err:", err);
      })
      .finally(() => setInBuying(false));
  }, [
    tiny,
    handleTransactionReceipt,
    id,
    price,
    t,
    referral,
    invitationTime,
    handleTxConfirm,
  ]);

  const approveAndBuyButton =
    approveStatus === ApprovalState.PENDING ||
    ApprovalState.NOT_APPROVED === approveStatus ? (
      <PrimaryButton
        size="sm"
        disabled={approveStatus === ApprovalState.PENDING}
        onClick={approve}
      >
        {t("Approve")}
      </PrimaryButton>
    ) : (
      <PrimaryButton
        size="sm"
        disabled={approveStatus === ApprovalState.UNKNOWN || inBuying}
        onClick={handleBuy}
        // loading={isTINCDepositPending}
      >
        {t("Buy")}
      </PrimaryButton>
    );

  return (
    <StyledContainer key={id}>
      <StyledLeft>
        <StyledNFTImg
          id={"SPIRIT-QUICK-BUY-" + info.id}
          src={restore}
          alt="Spirit"
        />
        <div className="display-flex flex-column">
          <div className="display-flex flex-column mb-5">
            <StyledName>SPIRIT</StyledName>
            <StyledAmount className="fw-600 text-outline-07">
              x{info.amount}
            </StyledAmount>
          </div>
          <StyledUnitPrice>
            <StyledBorderCurrency
              className="mr-2"
              src="//images.tinyworlds.io/tokens/BUSD.png"
              width="18"
              alt="BUSD"
            />
            {trimZero(formatMarketPrice(info.unitPrice).toFixed(5))} /{" "}
            {t("spirit unit price")}
          </StyledUnitPrice>
        </div>
      </StyledLeft>
      <StyledRight>
        <div className="display-flex flex-center">
          <StyledBorderCurrency
            src="//images.tinyworlds.io/tokens/BUSD.png"
            width="22"
            alt="BUSD"
          />
          <StyledPrice>{displayPrice}</StyledPrice>
        </div>
        <StyledButtons>
          {approveAndBuyButton}
          <StyleDeleteButton
            className="display-flex flex-center"
            bgc={inShoppingList ? "#514b45" : "#fdce4f"}
            onClick={inShoppingList ? handleDelete : handleAdd}
            disabled={isFull && !inShoppingList}
          >
            {inShoppingList ? (
              <DeleteSvg fill="#fdd456" />
            ) : (
              <img height={isMobile ? 15 : 18} src={addSvg} alt="" />
            )}
          </StyleDeleteButton>
        </StyledButtons>
      </StyledRight>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  & + & {
    border-top: 1px solid #707070;
  }
  box-sizing: border-box;
  height: 87px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 69px;

  `}
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.5rem;
`;

const StyledNFTImg = styled.img`
  object-fit: contain;
  width: 64.8px;
  height: 62.5px;
  margin: 0 1rem 0 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 53px;
    height: 49px;
  `}
`;

const StyledName = styled.span`
  font-size: 1.1rem;
  line-height: 1.1rem;
`;

const StyledAmount = styled.div`
  font-size: 1.3rem;
  color: #fff;
  line-height: 1;
`;

const StyledUnitPrice = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-left: -1px;
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

const StyledPrice = styled.span`
  margin-right: 6px;
  margin-left: 6px;
  /* color: ${(props) => props.theme.color.yellow}; */
  font-size: 1.2rem;
  /* font-weight: 500; */
`;

const StyleDeleteButton = styled.button.attrs<{
  bgc: string;
  disabled: boolean;
}>((props) => ({
  style: {
    backgroundColor: props.bgc,
    filter: props.disabled ? "grayscale(1)" : null,
  },
}))<{ bgc: string; disabled: boolean }>`
  width: 2.5rem;
  padding: 4px 9px 3px;
  color: #514b45;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  border-radius: 19px;
  height: 28px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.21);
    width: 3rem;
    height: 19px;
  `}
`;

const StyledBorderCurrency = styled.img`
  border: 1px solid #514b45;
  border-radius: 20px;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 0.25rem;
`;
