import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import { PetAttr } from "src/components/NFTCard/PetCard/type";
import styled from "styled-components";
import {
  PetDefaultBoder,
  PetDefaultColor,
  PetImgBorder,
  PetImgColor,
  petPropNodes,
  Props,
} from "./const";
import LockIcon from "./LockIcon";
import PetLockIcon from "./PetLockIcon";

const index: FC<Props> = ({
  className,
  style,
  color,
  grade,
  prop,
  lock,
  boxStyle,
  blackBg,
  defaulted,
}) => {
  return (
    <StyledContainer style={boxStyle}>
      {/*  <StyledLock>
           <PetLockIcon></PetLockIcon>
         </StyledLock> */}
      {lock && (
        <PetLockIcon
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "50%",
            width: "50%",
          }}
        ></PetLockIcon>
      )}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // className={className}
        className={className}
        style={{
          ...style,
          ...{
            // filter: lock && blackBg ? "brightness(0.5)" : "unset",
          },
        }}
        viewBox="0 0 40.71 40.67"
      >
        <path
          d="M20.35,40.67a3.48,3.48,0,0,1-1.15-.2C5,35.5.44,24.56.25,24.1a3.6,3.6,0,0,1,0-2.7,3.56,3.56,0,0,1,1.45-1.64,17.65,17.65,0,0,1,1-7.48,3.49,3.49,0,0,1,2.55-2.12,23.12,23.12,0,0,1,3-5.58,3.53,3.53,0,0,1,2.81-1.41,3.46,3.46,0,0,1,1.69.44l2.82,1.54c.2,0,.58.11,1.09.18l.85-1.2a3.51,3.51,0,0,1,5.71,0l.85,1.2c.51-.07.88-.13,1.08-.18l2.7-1.48L28,3.6a3.52,3.52,0,0,1,4.5,1,23.49,23.49,0,0,1,3,5.58A3.48,3.48,0,0,1,38,12.28,17.64,17.64,0,0,1,39,19.76a3.52,3.52,0,0,1,1.49,4.34c-.19.46-4.74,11.4-18.95,16.37A3.49,3.49,0,0,1,20.35,40.67Z"
          fill={
            defaulted
              ? PetDefaultBoder
              : grade
              ? PetImgBorder[grade - 1]
              : PetImgBorder[0]
          }
        />
        <path
          d="M20.35,38.83a1.76,1.76,0,0,1-.55-.09C6.36,34,2.13,23.84,2,23.41a1.67,1.67,0,0,1,.94-2.17l.81-.32a16.31,16.31,0,0,1,.68-8,1.67,1.67,0,0,1,1.55-1.05h.66A21.18,21.18,0,0,1,9.71,5.68a1.68,1.68,0,0,1,2.14-.47l3.07,1.68a18.2,18.2,0,0,0,2.59.38L19,5.19a1.66,1.66,0,0,1,1.36-.7h0a1.66,1.66,0,0,1,1.36.7l1.48,2.08a16,16,0,0,0,2.66-.41l3-1.65A1.68,1.68,0,0,1,31,5.68a20.92,20.92,0,0,1,3.12,6.24h.67A1.66,1.66,0,0,1,36.32,13a16.31,16.31,0,0,1,.68,8l.81.32a1.65,1.65,0,0,1,.94,2.17c-.17.43-4.4,10.63-17.85,15.33A1.67,1.67,0,0,1,20.35,38.83Z"
          fill={
            defaulted
              ? PetDefaultColor
              : grade
              ? PetImgColor[grade - 1]
              : PetImgColor[0]
          }
        />
        {petPropNodes[prop as keyof PetAttr]}
      </svg>
    </StyledContainer>
  );
};

export default index;

const StyledLock = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  // background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: auto !important;
    width: 0.8rem;
    z-index: 2;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
`;
