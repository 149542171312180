/**
 * 请求的数据中，有一种是请求过一次之后，就可以一直缓存，不用再请求的
 * 这类数据都缓存起来
 */
import { fileFetch } from "./util";
import type { gradeType } from "src/config";

export type materialType = 1 | 2 | 3 | 4 | 5; // 材料是不会吃红色的
export interface UpgradeItemRuleHero {
  grade: materialType;
  count: number;
  type: 0 | 1 | 2;
}
export interface UpgradeItemRule {
  level: number;
  hero: UpgradeItemRuleHero[];
}
export interface UpgradeConfigItem {
  grade: gradeType;
  max_level: 10 | 20;
  base_power_lower: number;
  base_power_upper: number;
  rule: UpgradeItemRule[];
  power: { level: number; lower: number; upper: number }[];
}

// 先不用 localStorage
localStorage.removeItem("herolvuprule");

const herolvupruleCacheStr = sessionStorage.getItem("herolvuprule");
// 英雄升级配置
export const heroUpgradeCache: {
  herolvuprule: UpgradeConfigItem[] | undefined;
} = {
  herolvuprule: undefined,
};

try {
  if (herolvupruleCacheStr) {
    heroUpgradeCache.herolvuprule = JSON.parse(herolvupruleCacheStr);
  }
} catch (error) {
  sessionStorage.removeItem("herolvuprule");
}

let isFreshed = false;

const upgradeVersion = 5;

function fetchHeroUpgradeConfig() {
  return fileFetch(`/json/herolvuprule.json?version=` + upgradeVersion)
    .then((res) => res.json())
    .then((res: UpgradeConfigItem[]) => {
      heroUpgradeCache.herolvuprule = res;
      // console.log(res);
      sessionStorage.setItem("herolvuprule", JSON.stringify(res));
      return res;
    });
}

export async function loadHeroUpgradeConfig() {
  if (heroUpgradeCache.herolvuprule) {
    if (isFreshed === false) {
      fetchHeroUpgradeConfig();
      isFreshed = true;
    }
    return heroUpgradeCache.herolvuprule;
  }
  return fetchHeroUpgradeConfig();
}

// herolvuprule.json

// const exploreVersion = 1;
// const cache =
// export async function getHeroExploreValue() {}
