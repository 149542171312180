import { useMemo } from "react";
import { useQuery } from "react-query";
import { getPetDetail } from "src/components/NFTCard/PetCard/const";
import { PetDetail } from "src/components/NFTCard/PetCard/type";

import {
  getGamingPetsByAddress,
  getPetAuction,
  getPetAuctionHistory,
  getPetBidsByAddress,
  getPetGameInfo,
  getPetIdByTx,
  getPetMarketList,
  getPetMarketListByAddress,
  getPetsByAddress,
  getPetsDetail,
  getPetsList,
  getStakingPetsByAddress,
  PetMarketInfo,
  PetMarketParam,
  PetsResult,
} from "src/services/pets";
import {
  PageParamBase,
  PageResult,
  PageResultSort,
  PetDetailAndGameInfo,
  PetDetailInfo,
  PetGameInfo,
} from "src/services/typings";
import useTiny from "./useTiny";
import useAutoFreshRequest from "./useAutoFreshRequest";

export function useMyStakingPets() {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const { data, refetch } = useQuery(
    ["STAKING_PETS", account],
    async () => {
      const results: PetsResult = await getStakingPetsByAddress({ account });
      return results;
    },
    {
      enabled: typeof account === "string",
      refetchInterval: 10_000,
    }
  );

  const list: PetDetail[] | undefined = useMemo(() => {
    return data
      ? data?.list.map((ele) => {
          const item = {
            ...ele,
            masterAddress: account,
          };
          return getPetDetail(item as PetDetailInfo);
        })
      : undefined;
  }, [account, data]);

  return {
    data: {
      list,
      total: data?.total_count,
    },
    refetch: refetch,
  };
}

export function useMyGameingPets() {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const { data, refetch } = useQuery(
    ["GAMING_PETS", account],
    async () => {
      const results: PetsResult = await getGamingPetsByAddress({ account });
      return results;
    },
    {
      enabled: typeof account === "string",
      refetchInterval: 10_000,
    }
  );

  const list: PetDetail[] | undefined = useMemo(() => {
    return data
      ? data?.list.map((ele) => {
          const item = {
            ...ele,
            masterAddress: account,
          };
          return getPetDetail(item as PetDetailInfo);
        })
      : undefined;
  }, [account, data]);

  return {
    data: {
      list,
      total: data?.total_count,
    },
    refetch: refetch,
  };
}

export function useMyPets() {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const { data, refetch } = useQuery(
    ["MY_PETS", account],
    async () => {
      const results: PetsResult = await getPetsByAddress({ account });
      return results;
    },
    {
      enabled: typeof account === "string",
      refetchInterval: 10_000,
    }
  );

  const list: PetDetail[] | undefined = useMemo(() => {
    return data
      ? data?.list.map((ele) => {
          const item = {
            ...ele,
            masterAddress: account,
          };
          return getPetDetail(item as PetDetailInfo);
        })
      : undefined;
  }, [account, data]);

  return {
    data: {
      list,
      total: data?.total_count,
    },
    refetch: refetch,
  };
}

export function useMyAllPets() {
  const { data: idel, refetch: idelRefetch } = useMyPets();
  const { data: gaming, refetch: gamingRefetch } = useMyGameingPets();
  const { data: staking, refetch: stakingRefetch } = useMyStakingPets();

  const { list: idelList, total: idelTotal } = idel;
  const { list: gamingList, total: gamingTotal } = gaming;
  const { list: stakingList, total: stakingTotal } = staking;

  return {
    data: {
      list: [
        ...(idelList ? idelList : []),
        ...(stakingList ? stakingList : []),
        ...(gamingList ? gamingList : []),
      ],
      total: Number(idelTotal) + Number(gamingTotal) + Number(stakingTotal),
    },
    refetch: () => {
      idelRefetch();
      gamingRefetch();
      stakingRefetch();
    },
  };
}

interface PetsListParams extends PageParamBase {
  account: string | undefined;
}

export function usePetsByAddress({
  account,
  pageNum,
  pageSize,
  sortBy,
  sortType,
}: PetsListParams & PageResultSort) {
  const { data, refetch } = useQuery(
    ["PETS_LIST", account],
    async () => {
      const results: PetsResult & PageResult = await getPetsList({
        account,
        pageNum,
        pageSize,
        sortBy,
        sortType,
      });

      return results;
    },
    {
      enabled: typeof account === "string",
      refetchInterval: 10_000,
    }
  );

  return data;
}

export function usePetsDetail(id: number | undefined) {
  const { data: result, refetch } = useQuery(
    ["PETS_DETAIL", id],
    async () => {
      const results: PetDetailAndGameInfo | undefined = await getPetsDetail({
        id,
      });
      return results;
    },
    {
      enabled: typeof id === "number",
      refetchInterval: 30_000,
    }
  );

  const data: PetDetail | undefined = useMemo(() => {
    if (result) {
      const item: PetDetail = {
        ...getPetDetail({
          ...result,
        } as PetDetailInfo),
        maleInfoDetail: getPetDetail({
          ...result.maleInfo,
        } as PetDetailInfo),
        femaleInfoDetail: getPetDetail({
          ...result.femaleInfo,
        } as PetDetailInfo),
      };
      return item;
    }
    return undefined;
  }, [result]);

  const petGameData: PetGameInfo | undefined = useMemo(() => {
    if (result) {
      return result.petGameInfo;
    }
    return undefined;
  }, [result]);

  return {
    data,
    petGameData,
    refetch,
  };
}

export const usePetMarketList = ({
  pageNum,
  sort,
  rangeValues,
  isCracked,
  isOrigin,
  races,
  isCD,
  partsGrade2,
  partsGrade3,
  partsGrade4,
  partsGrade5,
  propsCounts,
  powers,
}: PetMarketParam) => {
  const params = useMemo(
    () => ({
      pageNum,
      sort,
      rangeValues,
      isCracked, //是否是蛋
      isOrigin, //是否是origin宠物
      races,
      isCD, //是否在繁殖冷却中
      partsGrade2,
      partsGrade3,
      partsGrade4,
      partsGrade5,
      propsCounts,
      powers,
    }),
    [
      pageNum,
      sort,
      rangeValues,
      isCracked,
      isOrigin,
      races,
      isCD,
      partsGrade2,
      partsGrade3,
      partsGrade4,
      partsGrade5,
      propsCounts,
      powers,
    ]
  );

  return useQuery(
    ["PET_MARKET_LIST", params],
    async ({ signal }) => {
      const results = getPetMarketList(params);
      return results;
    },
    {
      refetchInterval: 10_000,
    }
  );
};

export const useSellingPets = () => {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const params = useMemo(
    () => ({
      address: account || "",
    }),
    [account]
  );

  return useQuery(
    ["PET_SELLING_LIST", params],
    async ({ signal }) => {
      const results = getPetMarketListByAddress(params);
      return results;
    },
    {
      refetchInterval: 10_000,
    }
  );
};

export const usePetAuction = (id: number) => {
  const [data, refresh] = useAutoFreshRequest(getPetAuction, [id]);
  if (data) {
    return {
      data: {
        ...data,
        petInfo: getPetDetail(data?.petInfo),
      } as PetMarketInfo,
      refetch: refresh,
    };
  } else {
    return {
      data: undefined,
      refetch: () => {},
    };
  }
};

export const useMyBids = ({ pageNum, pageSize }: PageParamBase) => {
  const tiny = useTiny();
  const account = tiny.myAccount;

  const params = useMemo(
    () => ({
      address: account || "",
      pageNum,
      pageSize,
    }),
    [account, pageNum, pageSize]
  );

  return useQuery(
    ["PET_BIDS_LIST", params],
    async ({ signal }) => {
      const results = getPetBidsByAddress(params, { signal });
      return results;
    },
    {
      refetchInterval: 10_000,
    }
  );
};

export const usePetAuctionHistory = (id: number | undefined) => {
  const params = useMemo(
    () => ({
      id,
    }),
    [id]
  );

  return useQuery(
    ["PET_AUCTION_HISTORY", params],
    async ({ signal }) => {
      const results = getPetAuctionHistory(params, { signal });
      return results;
    },
    {
      refetchInterval: 60_000,
    }
  );
};

// 饱食度 ：endure/maxEndure
// 部位描述与特性： tissues
// 部位等级 tissues[n].level
// tissues[n].additionType 是附加的属性类型（气势/攻击/生命/防御/幸运）
// 附加的属性值为：
// tissues[n].metadata.attack 攻击
// tissues[n].metadata.health 生命
// tissues[n].metadata.defense 防御
// tissues[n].metadata.luck 幸运
// tissues[n].metadata.momentum 气势
export const usePetGameInfo = (id: number | undefined) => {
  const params = useMemo(
    () => ({
      id,
    }),
    [id]
  );

  return useQuery(
    ["PET_GAME_INFO", params],
    async ({ signal }) => {
      const results = getPetGameInfo(params, { signal });
      return results;
    },
    {
      refetchInterval: 60_000,
    }
  );
};

export const usePetIdByTx = (tx: string | undefined) => {
  const params = useMemo(
    () => ({
      tx,
    }),
    [tx]
  );

  return useQuery(
    ["PET_TX_ID", params],
    async ({ signal }) => {
      const results = getPetIdByTx(params, { signal });
      return results;
    },
    {
      refetchInterval: 600_000,
    }
  );
};
