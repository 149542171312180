import { useContext, useMemo } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import banner from "../../images/banner/banner.png";
import bannerPre from "../../images/banner/banner-pre.png";
import brandIcon from "../../images/brand.png";
import { useTranslation } from "react-i18next";
import { utils } from "ethers";

import useAutoFreshRequest from "src/hooks/useAutoFreshRequest";
import { getAuctionDashboard, AuctionDashboard } from "src/services/auctions";
import { useRuneAuctionDashboard } from "src/hooks/runeAuctionHooks";
import config from "src/config";

import FarmContext from "src/contexts/Farms/context";

import PrefilledImage from "src/components/Images/PrefilledImage";
import { getPetsBidsDashboard } from "src/services/pets";

const chainId = config.chainId;

export default function Banner() {
  const { t } = useTranslation();

  const [overview] = useAutoFreshRequest<AuctionDashboard>(
    getAuctionDashboard,
    [],
    { interval: config.refreshInterval * 100 }
  );

  const { data: petMarketOverview } = useQuery(
    "PET_MARKET_OVERVIEW",
    ({ signal }) => {
      return getPetsBidsDashboard({ signal });
    },
    {
      refetchInterval: config.refreshInterval * 10,
    }
  );

  const { runeOverview, spiritOverview } = useRuneAuctionDashboard();

  const totalVolume = useMemo(() => {
    let sum = 0;
    // console.log('overview', overview);
    if (overview) {
      sum += Number.parseInt(overview.priceSum);
    }
    if (petMarketOverview) {
      sum += Number.parseInt(petMarketOverview.priceSum);
    }
    if (runeOverview && runeOverview.bidPriceSum) {
      sum += Number.parseInt(runeOverview.bidPriceSum);
    }
    if (spiritOverview && spiritOverview.bidPriceSum) {
      sum += Number.parseInt(spiritOverview.bidPriceSum);
    }
    if (chainId == 97) {
      return sum ? utils.commify((sum / 10).toFixed()) : "--";
    }
    return sum ? utils.commify((sum / 1e6).toFixed()) : "--";
  }, [overview, runeOverview, petMarketOverview, spiritOverview]);

  const { userCount } = useContext(FarmContext);

  const registeredUserNum = useMemo(() => {
    if (userCount > 0) {
      return utils.commify(userCount);
    }
    return "--";
  }, [userCount]);

  return (
    <StyledContainer className="position-relative special-ff">
      <StyledBannerBg
        width={1460}
        height={387}
        src={banner}
        preSrc={bannerPre}
        alt=""
      />

      {/* <LoadingSkeleton
        src={banner}
        style={{ height: 320, marginBottom: 12 }}
        boxStyle={{ overflow: "hidden" }}
      >
      </LoadingSkeleton> */}
      <StyledBannerInfo>
        <StyledBrand>
          <img
            width={220}
            src={brandIcon}
            height={39}
            style={{ height: "auto", width: "auto" }}
            alt="TINY WORLD"
          />
        </StyledBrand>
        <div style={{ height: "70%" }}>
          <StyledValueContainer style={{ marginBottom: "4%" }}>
            {/* <StyledLabel>{t("Total Value Locked")}</StyledLabel>
            <StyledValue className="ff-black">$ {sum}</StyledValue> */}
            <StyledLabel>{t("Registered Users")}</StyledLabel>
            <StyledValue className="ff-black">{registeredUserNum}</StyledValue>
          </StyledValueContainer>
          <StyledValueContainer>
            <StyledLabel>{t("Total NFT Trading Volume")}</StyledLabel>
            <StyledValue className="ff-black">$ {totalVolume}</StyledValue>
          </StyledValueContainer>
        </div>
      </StyledBannerInfo>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin: -30px 0 5px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: -10px 0 10px;
    // padding-top: 20px;
  `}
`;

const StyledBannerBg = styled(PrefilledImage)`
  width: 100%;
  height: auto;
`;

const StyledBannerInfo = styled.div`
  position: absolute;
  left: 16%;
  top: 21%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  height: calc(76% - 10px);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
  `}
`;

const StyledBrand = styled.div`
  margin: 7% 0 4%;
`;
const StyledValueContainer = styled.div`
  width: auto;
  min-width: 15vw;
  height: 45%;
  padding: 3% 20px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 0.9rem
  `}
`;

const StyledLabel = styled.div`
  color: white;
  font-size: 1.2rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1rem;
  `}
`;

const StyledValue = styled.div`
  font-size: 1.8rem;
  color: #fccd48;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.5rem;
  `}
`;
