import Web3 from "web3";
import { ethers } from "ethers";
import config from "../config";

function web3ProviderFrom(endpoint: string) {
  return new Web3.providers.HttpProvider(endpoint, {
    timeout: 12000,
  });
}

const DEFAULT_RPC_PROVIDER = "DEFAULT_RPC_PROVIDER_" + config.chainId;
const firstProviderUrl = localStorage.getItem(DEFAULT_RPC_PROVIDER);
const provider = web3ProviderFrom(
  firstProviderUrl || config.defaultProvider[0]
);

const web3 = new Web3(provider);
// console.log("web3.currentProvider", web3.currentProvider);

export function getWeb3() {
  return web3;
}

export function getDefaultProvider(): ethers.providers.Web3Provider {
  return new ethers.providers.Web3Provider(provider as any, config.chainId);
}

export function testRPC() {
  let list = [];
  let finished = false;
  return new Promise<void>((resolve, reject) => {
    config.defaultProvider.forEach((url, index) => {
      let time1 = performance.now();
      const web3 = new Web3(url);
      web3.eth.getBlockNumber((error, blockNumber) => {
        if (error) {
          console.error("getBlockNumber", error);
          reject(error);
        } else {
          const delay = performance.now() - time1;
          list.push({
            url,
            index,
            delay,
          });
          if (finished === false) {
            finished = true;
            localStorage.setItem(DEFAULT_RPC_PROVIDER, url);
            const provider = web3ProviderFrom(url);
            if (process.env.NODE_ENV === "development") {
              console.log(
                "Your provider rpc:",
                url,
                delay.toFixed() + "ms",
                blockNumber
              );
            }
            web3.setProvider(provider);
            resolve();
          }
        }
      });
    });
  });
}

if (window.location.pathname !== "/tinykingdom") {
  setTimeout(testRPC, 1_000);
  setTimeout(testRPC, 15_000);
  setTimeout(testRPC, 35_000);

  setInterval(() => {
    testRPC();
  }, 60_000);
}
