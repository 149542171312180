const body = document.body;

export function change() {
  const width = window.innerWidth;
  if (width <= 1660 && width > 800) {
    // width - 5000
    const rate = 2 - 3760 / (width + 2100);
    window.requestAnimationFrame(function () {
      body.style.zoom = rate.toFixed(3);
    });
  } else if (body.style.zoom !== "") {
    body.style.zoom = "";
  }
}

export function removeZoom() {
  if (body.style.zoom !== "") {
    body.style.zoom = "";
  }
}

export function getZoomValue() {
  if (body.style.zoom !== "") {
    return Number(body.style.zoom);
  }

  return 1;
}
