import {
  createRef,
  CSSProperties,
  Dispatch,
  FC,
  ReactChild,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/hooks/useIsMobile";
import styled from "styled-components";

import notification from "src/images/header/notification.png";
import giftImg from "src/images/header/gift.svg";
import { PageContext } from "src/contexts/Page";

interface MProps {
  style?: CSSProperties;
  close?: boolean;
  warrning?: boolean;
  children?: ReactChild;
  cStyle?: CSSProperties;
}
export const headerRef = createRef<HTMLDivElement>();

const cacheKey = "SHOW_GIFT_NOTIFICATION";

const MigrationNotice: FC<MProps> = ({
  style = {},
  cStyle = {},
  close = true,
  warrning = true,
  children,
}) => {
  const { t } = useTranslation();
  const { showNotice, setShowNotice } = useContext(PageContext);
  const isMobile = useIsMobile();
  useEffect(() => {
    if (localStorage.getItem(cacheKey) === "false") {
      setShowNotice(false);
    }
  }, []);

  return showNotice ? (
    <StyledContainer
      ref={headerRef}
      id="migration-notice"
      style={{
        ...{ display: showNotice ? "flex" : "none" },
        ...style,
      }}
      onClick={() =>
        window.open(
          "https://medium.com/@tinyworld.gamefi/merry-christmas-happy-new-year-event-bb340ad72bc9"
        )
      }
    >
      {warrning && <img src={giftImg} width={isMobile ? 23 : 32} />}
      <StyledContent>
        {children ? (
          <h2 style={{ ...cStyle }}>
            <span>{children}</span>
            <span>{children}</span>
          </h2>
        ) : (
          <h2>
            <span>{t("ClosePoolTip")}</span>
            <span>{t("ClosePoolTip")}</span>
          </h2>
        )}
      </StyledContent>
      {close && (
        <StyledClose
          onClick={(e) => {
            e.stopPropagation();
            setShowNotice(false);
            localStorage.setItem(cacheKey, "false");
          }}
        >
          <svg
            viewBox="0 0 24 24"
            color="background"
            fill={isMobile ? "#fff" : "#514b45"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18.2987 5.70997C17.9087 5.31997 17.2787 5.31997 16.8887 5.70997L11.9988 10.59L7.10875 5.69997C6.71875 5.30997 6.08875 5.30997 5.69875 5.69997C5.30875 6.08997 5.30875 6.71997 5.69875 7.10997L10.5888 12L5.69875 16.89C5.30875 17.28 5.30875 17.91 5.69875 18.3C6.08875 18.69 6.71875 18.69 7.10875 18.3L11.9988 13.41L16.8887 18.3C17.2787 18.69 17.9087 18.69 18.2987 18.3C18.6887 17.91 18.6887 17.28 18.2987 16.89L13.4087 12L18.2987 7.10997C18.6787 6.72997 18.6787 6.08997 18.2987 5.70997Z"></path>
          </svg>
        </StyledClose>
      )}
    </StyledContainer>
  ) : (
    <></>
  );
};

export default MigrationNotice;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #ee912b 0%, #fdce4c 100%);
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: 50px;
  cursor: pointer;

  > img {
    margin: 0 2rem;
    height: 40px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      height: 30px;
      margin: 0 0.5rem;
    `}
  }

  h2 {
    color: white;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;

    @media (any-hover: hover) {
      :hover {
        animation-play-state: paused;
      }
    }

    > span:nth-child(2) {
      display: none;
    }

    > span:first-child {
      padding-left: 20vw;
      padding-right: 40vw;
      display: inline-block;
      animation: wordsLoop 60s -60s linear infinite normal;
    }

    > span:nth-child(2) {
      display: inline-block;
      padding-left: 20vw;
      padding-right: 40vw;
      animation: wordsLoop2 60s -30s linear infinite normal;
    }

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      > span:first-child {
        padding-left: 30px;
        padding-right: 40vw;
        display: inline-block;
        animation: wordsLoop 60s -60s linear infinite normal;

      }

      > span:nth-child(2) {
        display: inline-block;
        padding-left: 30px;
        padding-right: 40vw;
        animation: wordsLoop2 60s -30s linear infinite normal;

      }
    `}
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: start;
    height: 40px;
    background-image: linear-gradient(to right, #3b81d6 0%, #275185 100%);
  `};
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`

  `}
`;

const StyledClose = styled.div`
  margin: 2rem;
  cursor: pointer;

  > svg {
    vertical-align: middle;
    width: 24px;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      width: 18px;
    `}
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 0.5rem;
  `}
`;
