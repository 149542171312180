// import { useCallback } from "react";
import styled from "styled-components";
import clsx from "clsx";

import previousPng from "src/images/page/previous.png";
import nextPng from "src/images/page/next.png";

interface Props {
  className?: string;
  pageIndex: number;
  pageCount: number;
  onPageChange?(pageIndex: number): void;
  previousPage(): void;
  nextPage(): void;
  simple?: boolean;
}

/**
 * 这个组件是可以配合 hook 使用的，useMyPagination
 * @param {Props} props
 */
export default function Pagination(props: Props) {
  const {
    pageCount,
    pageIndex,
    onPageChange,
    previousPage,
    nextPage,
    className,
    simple,
  } = props;
  if (pageCount <= 1) return null;
  return (
    <StyledPaginate className={clsx("display-flex flex-center", className)}>
      <StyledPrevious
        simple={simple}
        aria-disabled={pageIndex < 1}
        onClick={previousPage}
      >
        {!simple && <StyledImg src={previousPng} alt="<" />}
      </StyledPrevious>

      {simple ? (
        <StyledSimple>{pageIndex + 1}</StyledSimple>
      ) : (
        <StyledOuter>
          <StyledPageBox className="ff-game text-outline-07">
            {pageIndex + 1}/{pageCount}
          </StyledPageBox>
        </StyledOuter>
      )}
      <StyledNext
        simple={simple}
        aria-disabled={pageIndex >= pageCount - 1}
        onClick={nextPage}
      >
        {!simple && <StyledImg src={nextPng} alt=">" />}
      </StyledNext>
    </StyledPaginate>
  );
}

const StyledSimple = styled.div`
  margin: 2px 2px;
  user-select: none;
  width: 24px;
  padding: 2px;
  text-align: center;
`;

const StyledTurnPage = styled.div<{ simple?: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;

  cursor: ${(props) => (props["aria-disabled"] ? "" : "pointer")};
  ${(props) => (props["aria-disabled"] ? "pointer-events: none;" : null)}

  ${({ simple }) => {
    return (
      simple &&
      `  ::after {
  content: " ";
  box-sizing: border-box;
  margin-left: 2px;
  display: inline-block;
  border-width: 18px 10px 2px;
  border-style: solid;
  border-color: #dec387 transparent transparent transparent;
}`
    );
  }}
`;

const StyledNext = styled(StyledTurnPage)<{ simple?: boolean }>`
  transform: ${({ simple }) =>
    simple ? "rotate(-90deg) translateX(-1px)" : "unset"};
`;

const StyledPrevious = styled(StyledTurnPage)<{ simple?: boolean }>`
  transform: ${({ simple }) =>
    simple ? "rotate(90deg) translateX(-1px)" : "unset"};
`;

const StyledPaginate = styled.div``;

const StyledOuter = styled.div`
  margin: 2px 4px;
  border: 2px solid #000000;
  padding: 2px;
  border-radius: 8px;
  background-color: #ffa200;
  box-sizing: border-box;
`;

const StyledPageBox = styled.div`
  padding: 2px 4px 3px;
  /* border: 3px solid #ffa200; */
  background-color: #a26511;
  border-radius: 8px;
  font-size: 1.5rem;
  color: white;
  box-sizing: border-box;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.2rem;
    padding: 1px 2px;
  `}
`;

const StyledImg = styled.img`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
  `}
`;
