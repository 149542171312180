import React, { useCallback } from "react";
import { useQuery } from "react-query";
import { BigNumber } from "ethers";

import useTiny from "./useTiny";
import useLimitedInterval from "./useLimitedInterval";
// import { getMulticallContract } from "../tiny/utils";

// import type { CallResults } from "./useMulticall";
// import type { Result } from "@ethersproject/abi"

// const roundTime = BigNumber.from(24 * 3600 * 7); // testnet
// // const roundTime = BigInt(2 * 3600); // testnet

// const _startWeek = BigNumber.from(1655107200);

// function _startOfWeek(timestamp: number) {
//   return BigNumber.from(timestamp).div(roundTime).mul(roundTime);
// }

// function getRoundByTime(timestamp: number) {
//   const _currentWeek = _startOfWeek(timestamp);
//   return Number((_currentWeek.sub(_startWeek)).div(roundTime)) + 1;
// }

// function getTimestamp() {
//   const now = new Date().getTime();
//   return Math.floor(now / 1000);
// }

// interface RoundRewardInfo extends Result {
//   amount: BigNumber;
//   checkinSum: number;
//   _seed: BigNumber;
// }

interface CheckinRewardInfo {
  0: BigNumber;
  1: BigNumber;
  reward: BigNumber;
  spiritCount: BigNumber;
}

export default function useCheckinRewardInfo() {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const checkinReward = tiny.contracts.CheckinReward;
  // const multicallContract = getMulticallContract(tiny);

  const getCheckinRewardInfos = useCallback(async () => {
    // const currentRound = getRoundByTime(getTimestamp());
    // console.log("currentRound", currentRound);
    // const calldata = [
    //   [
    //     checkinReward.address,
    //     checkinReward.interface.encodeFunctionData("getCurrentRound", []),
    //   ],
    //   // [
    //   //   checkinReward.address,
    //   //   checkinReward.interface.encodeFunctionData("_accountCheckinForRound", [
    //   //     currentRound - 1,
    //   //     account,
    //   //   ]),
    //   // ],
    //   // [
    //   //   checkinReward.address,
    //   //   checkinReward.interface.encodeFunctionData("_accountCheckinForRound", [
    //   //     currentRound,
    //   //     account,
    //   //   ]),
    //   // ],
    //   // [
    //   //   checkinReward.address,
    //   //   checkinReward.interface.encodeFunctionData("getRoundUserInfo", [
    //   //     account, currentRound
    //   //   ]),
    //   // ],
    // ];

    const [rewards, _currentRound]: [CheckinRewardInfo, BigNumber] =
      await Promise.all([
        checkinReward.callStatic.claimRewards(),
        checkinReward.callStatic.getCurrentRound(),
        // multicallContract.callStatic.aggregate(calldata),
      ]);

    const roundUserInfo = await checkinReward.callStatic.getRoundUserInfo(
      account,
      _currentRound
    );

    // console.log("result2", result2);

    // const { returnData, blockNumber } = result2;
    // console.log("returnData", returnData);

    // const _currentRound = checkinReward.interface.decodeFunctionResult(
    //   "getCurrentRound",
    //   returnData[0]
    // );

    // const info2 = checkinReward.interface.decodeFunctionResult(
    //   "getRewardInfoByRound",
    //   returnData[1]
    // );

    // const info3 = checkinReward.interface.decodeFunctionResult(
    //   "getRewardInfoByRound",
    //   returnData[1]
    // );

    // const userinfo2 = checkinReward.interface.decodeFunctionResult(
    //   "_accountCheckinForRound",
    //   returnData[5]
    // );

    // const userinfo3 = checkinReward.interface.decodeFunctionResult(
    //   "_accountCheckinForRound",
    //   returnData[2]
    // );

    // const roundUserInfo = checkinReward.interface.decodeFunctionResult(
    //   "getRoundUserInfo",
    //   returnData[1]
    // );

    // console.log("getCheckinRewardInfos rewards:", rewards);
    // console.log("getCheckinRewardInfos info1:", info1);
    // console.log("getCheckinRewardInfos roundUserInfo:", roundUserInfo);

    return {
      // currentRound: _currentRound,
      rewards: rewards,
      // info2: {
      //   amount: info2.amount,
      //   checkinSum: info2.checkinSum,
      //   _seed: info2._seed.toHexString(),
      //   compensation: info2.compensation.div(1e14).toNumber() / 1e4,
      // },
      // info3: {
      //   amount: info3.amount,
      //   checkinSum: info3.checkinSum,
      //   _seed: info3._seed.toHexString(),
      //   compensation: info3.compensation.div(1e14).toNumber() / 1e4,
      // },
      // userInfo2: {
      //   checkinCount: userinfo2.checkinCount,
      //   rewardIndex: userinfo2.rewardIndex,
      // },
      // userInfo3: {
      //   checkinCount: userinfo3.checkinCount,
      //   rewardIndex: userinfo3.rewardIndex,
      // },
      checkinCount: roundUserInfo.checkinCount,
    };
  }, [account]);

  const { data, refetch } = useQuery(
    ["CHECKIN_REWARD_INFOS", account],
    async () => {
      const data = await getCheckinRewardInfos();
      // console.log("getCheckinRewardInfos data:", data);
      return data;
    },
    {
      enabled: typeof account === "string",
      // refetchInterval: 20_000,
      // initialData: account ? pendingRewardCache.account : undefined,
    }
  );

  const start = useLimitedInterval(refetch, {
    limit: 4,
  });

  return { data, refetch: start };
}
