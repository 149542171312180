import { useContext, ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BigNumber } from "ethers";

import useApprove, { ApprovalState } from "src/hooks/useApprove";
import { Context as TinyContext } from "src/contexts/TinyProvider";
import BaseButton from "../Button/BaseButton";
import Close from "src/components/Close";

import di_3 from "src/images/di_3.png";
import clearBuy from "src/images/clearBuy.png";
import useCheckAuth from "src/hooks/useCheckAuth";
import { formatMarketPrice, trimZero } from "src/utils";

interface Props {
  shoppingType: 0 | 1 | 2; // hero | spirit | pet ...
  className?: string;
  onClose?: () => void;
  sellList: ReactElement[];
  priceSum: BigNumber;
  handleBuy(): void;
  onClear(): void;
  inBuying: boolean;
  approveAddress: string;
}

const shoppingDesc = [
  "shoppingcartdesc",
  "spiritshoppingcartdesc",
  "petsshoppingcartdesc",
];

export default function ShoppingContent(props: Props) {
  const {
    className,
    onClose,
    sellList,
    priceSum,
    handleBuy,
    onClear,
    inBuying,
    shoppingType,
    approveAddress,
  } = props;
  const { t } = useTranslation();

  const { tiny, userInfo } = useContext(TinyContext);
  const BUSD = tiny.externalTokens.BUSD;

  const { checkAuth } = useCheckAuth();
  // tiny.contracts.PetsMarket.address
  const [approveStatus, approve] = useApprove(
    BUSD,
    approveAddress,
    priceSum.mul(1e12)
  );

  return (
    <StyledContent className={className}>
      <StyledTitle className="rfs-11">
        {/* 清空购物车 */}
        <StyledClearBuy className="mr-10" onClick={onClear}>
          <img
            style={{
              height: 16,
              position: "relative",
              bottom: 0,
              left: 0,
              top: 0,
            }}
            src={clearBuy}
            alt=""
          />
          {t("Clear")}
        </StyledClearBuy>
        {t("Cart")}
      </StyledTitle>
      <StyledListContainer>{sellList}</StyledListContainer>
      <StyledDesc>
        <StyledExclamation />
        {t(shoppingDesc[shoppingType])}
      </StyledDesc>
      <div className="display-flex flex-between">
        <div className="display-flex flex-center">
          <span className="mr-5">{t("price sum")}</span>
          <img
            className="mr-5"
            src="//images.tinyworlds.io/tokens/BUSD.png"
            width="22"
            alt="BUSD"
            style={{
              border: "1px solid #666",
              borderRadius: 12,
              marginRight: 2,
            }}
          />
          <StyledPriceSum className="fw-500 text-outline rfs-14">
            {shoppingType === 1
              ? trimZero(formatMarketPrice(priceSum.toNumber()).toFixed(5))
              : priceSum.div(1e4).toNumber() / 100}
          </StyledPriceSum>
        </div>
        <div>
          {approveStatus === ApprovalState.PENDING ||
          ApprovalState.NOT_APPROVED === approveStatus ? (
            <StyledBuyButton
              disabled={approveStatus === ApprovalState.PENDING}
              onClick={() => checkAuth(() => approve())}
            >
              {t("Approve")}
            </StyledBuyButton>
          ) : (
            <StyledBuyButton
              disabled={
                approveStatus === ApprovalState.UNKNOWN ||
                inBuying ||
                sellList.length === 0 ||
                userInfo === undefined
              }
              onClick={() => checkAuth(() => handleBuy())}
            >
              {t("Confirm")}
            </StyledBuyButton>
          )}
        </div>
      </div>
      <Close onClick={onClose} />
    </StyledContent>
  );
}

const StyledContent = styled.div`
  position: relative;
  padding: 15px;
  background-image: url(${di_3}), linear-gradient(#fccd48, #f79905);
  border-radius: 10px;
  box-shadow: -3px -1px 5px 3px rgb(0 0 0 / 30%);
`;

const StyledTitle = styled.div`
  text-align: center;
  font-weight: 600;
  position: relative;
`;

const StyledListContainer = styled.div`
  margin: 10px 0;
  padding: 4px;
  min-height: 300px;
  max-height: 350px;
  background-color: #fff2d0;
  border-radius: 7px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fee397;
    border-radius: 5px;
  }
  ${(props) => props.theme.mediaWidth.upToSmall`
    max-height: 340px;
  `}
`;

const StyledPriceSum = styled.span`
  color: white;
`;

const StyledBuyButton = styled(BaseButton)`
  padding: 5px 24px;
  background-color: #fdd770;
  border-radius: 16px;
  color: #815920;
  box-shadow: 0 3px 0 #a2651133;
  :hover {
    background-color: #ffe8a9;
  }
`;

const StyledDesc = styled.div`
  position: relative;
  padding-left: 1.2rem;
  list-style: none;
  font-size: 0.75rem;
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1rem;
  `}
`;

const StyledExclamation = styled.div`
  position: absolute;
  font-weight: bold;
  width: 12px;
  height: 12px;
  left: 0px;
  top: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #514b45;
  &::before {
    content: "i";
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 1px;
  `}
`;

const StyledClearBuy = styled.div`
  min-width: 16px;
  background: #ab6100;
  position: absolute;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  height: 16px;
  border-radius: 8px;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding-right: 6px;
  zoom: 1.1;
  ${(props) => props.theme.mediaWidth.upToSmall`
    zoom: auto;
  `}
`;
