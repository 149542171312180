import { useQuery } from "react-query";
import { getMyIdelNFTs } from "src/services";
import type { NFTInfo } from "src/services/typings";

/**
 * query idel nfts with one account, no pooling by default
 * @param {string} account
 * @returns
 */
export default function useMyNFTList(
  account: string | undefined,
  refetch?: boolean
) {
  return useQuery<NFTInfo[] | undefined>(
    ["MY_NFT_LIST", account],
    async ({ signal }) => {
      if (!account) return;
      const res = await getMyIdelNFTs({ address: account }, { signal });
      // console.log("getMyIdelNFTs res", res);
      return res.nfts.map((ele) => {
        const { StaminaInfo } = ele;
        return {
          ...ele,
          stamina: StaminaInfo.stamina || undefined,
          ownerflag: 0,
          fromAddress: account,
          masterAddress: account,
        };
      });
    },
    {
      enabled: typeof account === "string",
      refetchInterval: refetch ? 10_000 : false,
    }
  );
}
