import {
  ReactNode,
  useState,
  ReactChild,
  useCallback,
  useEffect,
  useRef,
  Fragment,
  CSSProperties,
} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import styled from "styled-components";
import type { Placement } from "@popperjs/core";
import "./styles.css";
import clsx from "clsx";

type Diy = "left" | "auto" | "top" | "bottom"; //'left' | 'right' | 'top' | 'bottom'

interface Props {
  visible?: boolean;
  content?: ReactNode;
  children: ReactChild;
  placement?: Placement;
  className?: string;
  onVisibleChange?(visible: boolean): void;
  handleClick?(): void;
  triggerClose?(): void;
  diy?: Diy;
  delayHide?: number;
  isClick?: boolean;
  closeEvent?: string;
  boxStyle?: CSSProperties;
  boxClass?: string;
  style?: CSSProperties;
  clickStop?: boolean;
  arrowStyle?: CSSProperties;
  arrowClass?: string;
}

let Timer: NodeJS.Timeout;

export default function Popover(props: Props) {
  const {
    visible,
    children,
    placement = "top",
    content,
    className = "popover",
    onVisibleChange,
    handleClick,
    triggerClose,
    diy,
    delayHide,
    isClick = true,
    closeEvent = "click",
    boxStyle = {},
    boxClass,
    style = {},
    clickStop = false,
    arrowStyle,
    arrowClass,
  } = props;
  const [isShow, setShow] = useState(visible ?? false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );

  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "arrow",
        options: { element: arrowElement, padding: 16 },
      },
      {
        name: "offset",
        options: { offset: [0, 10] },
      },
    ],
    placement,
  });

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      if (clickStop) {
        return;
      }
      if (visible !== undefined) {
        console.log("visible", visible);
        if (delayHide) {
          clearTimeout(Timer);
          Timer = setTimeout(() => {
            triggerClose && triggerClose();
          }, delayHide);
        }
        return handleClick && handleClick();
      }
      setShow((isShow) => !isShow);
    },
    [clickStop, visible, delayHide, handleClick, triggerClose]
  );

  const moveEnter = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (!isClick) return;
      e.stopPropagation();
      if (visible !== undefined) {
        return handleClick && handleClick();
      }
      setShow(true);
    },
    [isClick, visible, handleClick]
  );

  const moveLeave = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (!isClick) return;
      e.stopPropagation();
      if (visible) {
        return triggerClose && triggerClose();
      }
      setShow(false);
    },
    [isClick, visible, triggerClose]
  );

  useEffect(() => {
    if (isShow || visible) {
      setTimeout(() => {
        document.addEventListener(
          closeEvent,
          function () {
            // console.log(`${closeEvent} close`, visible);
            if (visible) {
              triggerClose && triggerClose();
            } else {
              setShow(false);
            }
          },
          {
            once: true,
          }
        );
      }, 100);
    }
  }, [closeEvent, isShow, triggerClose, visible]);

  useEffect(() => {
    onVisibleChange && onVisibleChange(isShow);
  }, [isShow]);

  const destinationRef = useRef(document.createElement("div"));

  useEffect(() => {
    const destination = destinationRef.current;
    document.body.appendChild(destination);
    return () => {
      document.body.removeChild(destination);
    };
  }, []);

  if (!children) return null;
  // const transfrom = styles.popper.transform + "translate(0, -140%)";
  if (diy) {
    switch (diy) {
      case "left":
        styles.popper.transform = "translate(-100%,-35%)";
        styles.popper.left = "-9px";
        // arrowDiv.transform = 'translate(-50%, -50%)'
        // arrowDiv.top = '50%'
        // styles.arrow.right = '0 !important'
        break;
      case "top":
        styles.popper.bottom = "100%";
        break;
      default:
        break;
    }
  }

  const diyArrow = (diy: Diy | undefined): CSSProperties => {
    switch (diy) {
      case "left":
        return {
          transform: "translate(0%, -50%)",
          top: "50%",
          position: "absolute",
        };
      case "top":
        return {
          ...styles.arrow,
          bottom: "-7px",
        };
      default:
        return arrowStyle
          ? {
              ...styles.arrow,
              ...arrowStyle,
            }
          : styles.arrow;
    }
  };

  return (
    <Fragment>
      <span
        style={{
          ...{ position: "relative" },
          ...style,
        }}
        className={className}
        ref={setReferenceElement}
        onClick={onClick}
        // onMouseMove={()=>{console.log(1)}}
        onMouseEnter={moveEnter}
        onMouseLeave={moveLeave}
      >
        {children}
        {diy ? (
          <StyledMenuContent
            show={Boolean(visible)}
            isVisible={isShow || Boolean(visible)}
            className={clsx(boxClass, "popover-container")}
            ref={setPopperElement}
            style={{ ...styles.popper, ...boxStyle }}
            {...attributes.popper}
          >
            {content}
            <div
              className={clsx(arrowClass, "popover-arrow")}
              ref={setArrowElement}
              style={diyArrow(diy)}
            />
          </StyledMenuContent>
        ) : (
          createPortal(
            <StyledMenuContent
              show={Boolean(visible)}
              isVisible={isShow || Boolean(visible)}
              className={clsx(boxClass, "popover-container")}
              ref={setPopperElement}
              style={{ ...styles.popper, ...boxStyle }}
              {...attributes.popper}
            >
              {content}
              <div
                className={clsx(arrowClass, "popover-arrow")}
                ref={setArrowElement}
                style={styles.arrow}
              />
            </StyledMenuContent>,
            destinationRef.current
          )
        )}
      </span>
    </Fragment>
  );
}

const StyledMenuContent = styled.div<{ show: boolean; isVisible: boolean }>`
  display: flex;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  flex-direction: column;
  z-index: 3;
  background: #ffbd4a;
  border: 1px solid #bc6c1e;
  // background: #fffce2;
  // border: 2px solid #c7a472;
  box-shadow: 0px 5px 8px 2px rgb(0 0 0 / 30%);
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  text-align: left;
  font-size: 1rem;
  color: #514b45;
  font-weight: 400;
  white-space: normal;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // width: 178px;
    // min-height: 35px;
  `};
`;
