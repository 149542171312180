import React, { CSSProperties, FC } from "react";
import checkboxChecked from "src/images/game/checkbox_checked.png";
import styled from "styled-components";

interface TCheckBoxProps {
  className?: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  style?: CSSProperties;
}

const TCheckBox: FC<TCheckBoxProps> = ({
  className,
  checked,
  setChecked,
  style,
}) => {
  return (
    <StyledCheckoutBox style={style} className={className}>
      <input
        onClick={(e) => {
          setChecked(!checked);
        }}
        checked={checked}
        type={"checkbox"}
      />
    </StyledCheckoutBox>
  );
};

export default TCheckBox;

const StyledCheckoutBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #815920;
  border-radius: 4px;
  margin-right: 8px;
  overflow: hidden;

  input[type="checkbox"] {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 0;
  }

  input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fffbdd;
    width: 100%;
    height: 100%;
  }

  input[type="checkbox"]:checked::before {
    content: "";
    background: url(${checkboxChecked}) no-repeat center center;
    background-color: #fffbdd;
  }
`;
