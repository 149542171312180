import { useEffect, useRef, useState } from "react";

const daySeconds = 24 * 60 * 60;

export interface DaysCountDown {
  days: number;
  seconds: number;
}

export default function useTimer(time: number) {
  const [countdown, setCountDown] = useState<number>(
    Math.floor((time - Date.now()) / 1000)
  );

  useEffect(() => {
    const NFTPoolCountdown = setInterval(() => {
      setCountDown((pre) => {
        if (pre > 0) {
          return pre - 1;
        }
        return 0;
      });
    }, 1000);

    return () => {
      if (NFTPoolCountdown) {
        clearInterval(NFTPoolCountdown);
      }
    };
  }, [time]);

  return {
    days: Math.floor(countdown / daySeconds),
    seconds: countdown % daySeconds,
  };
}

export function useTimerSecond({
  time,
  options,
}: {
  time?: number;
  options?: {
    days: boolean;
  };
}) {
  const [countdown, setCountDown] = useState<number | undefined>(
    time ? Math.floor(time - Date.now() / 1000) : time
  );
  useEffect(() => {
    let timer: number;
    if (time) {
      setCountDown(time ? Math.floor(time - Date.now() / 1000) : time);
      timer = window.setInterval(() => {
        setCountDown((pre) => {
          if (pre === undefined) return;
          if (pre > 0) {
            return pre - 1;
          }
          return 0;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time]);

  return options?.days
    ? {
        days: Math.floor((countdown || 0) / daySeconds),
        seconds: (countdown || 0) % daySeconds,
      }
    : countdown;
}

export function useGetCountdown({
  time,
  reverse = false,
}: {
  time?: number;
  reverse?: boolean;
}) {
  const [countdown, setCountDown] = useState<number | undefined>(
    time ? time : 0
  );

  useInterval(() => {
    if (time) {
      setCountDown((pre) => {
        if (!pre) return;
        return reverse ? pre + 1 : pre - 1;
      });
    }
  }, 1000);

  return countdown ? countdown : undefined;
}

// https://overreacted.io/zh-hans/making-setinterval-declarative-with-react-hooks/
// 定时器异步刷新不及时
export function useInterval(callback: () => void, delay?: number) {
  const savedCallback = useRef<() => void>();

  // 保存新回调
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useGetTimeSecond({
  time,
  reverse = false,
}: {
  time?: number;
  reverse?: boolean;
}) {
  const [countdown, setCountDown] = useState<number | undefined>(
    time ? time : 0
  );
  // useEffect(()=>{
  //   time && time < 10000 && console.log({
  //     countdown
  //   })
  // },[countdown, time])

  useEffect(() => {
    let timer: number;
    if (time) {
      timer = window.setInterval(() => {
        setCountDown((pre) => {
          if (!pre) return;
          return reverse ? pre + 1 : pre - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [reverse, time]);

  return countdown ? countdown : undefined;
}

export function useBlockCountDown({
  block,
  blockNow,
  reverse = false,
}: {
  block?: number;
  blockNow: number | undefined;
  reverse?: boolean;
}) {
  const [loaded, setLoaded] = useState(false);
  const [countdown, setCountDown] = useState<number | undefined>(0);
  useEffect(() => {
    if (blockNow && block && !loaded) {
      setCountDown(() => {
        if (blockNow && block && block > blockNow) {
          return (block - blockNow) * 3;
        }
        return 0;
      });
      setLoaded(true);
    }
  }, [block, blockNow, loaded]);

  useEffect(() => {
    let timer: number;
    if (block && blockNow) {
      timer = window.setInterval(() => {
        setCountDown((pre) => {
          if (!pre) return;
          return reverse ? pre + 1 : pre - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [reverse, block, blockNow]);

  return typeof countdown === "number" ? countdown : undefined;
}
