import React, { ErrorInfo } from "react";
import { detect } from "detect-browser";
import { logErrorToService } from "src/services/util";
import { throttle } from "src/utils";

const browser = detect();

const throttledLog = throttle(logErrorToService);

interface Props {
  account: string | null | undefined;
  component?: string;
}
export default class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false, errStr: "" };

  static getDerivedStateFromError(error: any) {
    console.log("error", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errStr: JSON.stringify(error) };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const account = this.props.account;
    // You can also log the error to an error reporting service
    console.log("error", error);
    console.error("errorInfo", errorInfo);
    if (account) {
      throttledLog({
        account,
        error: error.message,
        type: "components",
        stack: errorInfo.componentStack.slice(0, 200),
        os: browser?.os,
        browsername: browser?.name,
      });
    }
  }

  render() {
    if (process.env.NODE_ENV === "development" && this.state.hasError) {
      return this.state.errStr;
    }
    return this.props.children;
  }
}
