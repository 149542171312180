import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export default function useURLRef() {
  const location = useLocation();
  const search = location.search;

  const getRefCodeFromSearch = useCallback(() => {
    const params = new URLSearchParams(search);
    // console.log("params ref", params.get("ref"));
    let refCode = params.get("ref");
    return refCode;
  }, [search]);

  const getRefCode = useCallback(() => {
    let refCode = getRefCodeFromSearch();
    if (refCode) {
      return refCode;
    }
    return sessionStorage.getItem("invitationCode");
  }, [getRefCodeFromSearch]);

  const setRefCode = useCallback((refCode: string) => {
    if (refCode) {
      sessionStorage.setItem("invitationCode", refCode);
    }
  }, []);

  const deleteRefCode = useCallback(() => {
    sessionStorage.removeItem("invitationCode");
  }, []);

  return {
    getRefCodeFromSearch,
    getRefCode,
    setRefCode,
    deleteRefCode,
  };
}
