import { useCallback } from "react";
import { BigNumber } from "ethers";

import useTiny from "./useTiny";
import useAutoFreshRequest from "./useAutoFreshRequest";
import { bn0 } from "src/utils";
import useLimitedInterval from "./useLimitedInterval";
import { chainId } from "../config";

interface UserInfoDetail {
  amount: BigNumber;
  NFTID: BigNumber;
  NFTBonus: BigNumber;
  NFTPower: number;
  depositTime: BigNumber;
}

const cache: {
  userInfo: UserInfoDetail;
} = {
  userInfo: {
    amount: bn0,
    NFTID: bn0,
    NFTBonus: bn0,
    NFTPower: 0,
    depositTime: bn0,
  },
};

const useLPFarmUserInfo = (): [UserInfoDetail, () => void] => {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const farm = tiny.contracts.TinyLPFarm;

  const getUserInfo = useCallback(
    async (account: string | undefined) => {
      if (!account) return cache.userInfo;
      // console.log("Squad tiny.myAccount", tiny.myAccount);
      const userInfo: UserInfoDetail = await farm.userInfo(account);
      let amount = await farm.userLpAmount(account);
      // console.log("userInfo res", userInfo);
      const info = {
        amount,
        NFTID: userInfo.NFTID,
        NFTBonus: userInfo.NFTBonus,
        NFTPower: userInfo.NFTPower,
        depositTime: userInfo.depositTime,
      };
      cache.userInfo = info;
      return info;
    },
    [farm]
  );

  const [userInfo, refresh] = useAutoFreshRequest<UserInfoDetail>(
    getUserInfo,
    [account],
    {
      initState: cache.userInfo,
    }
  );
  const startRefresh = useLimitedInterval(refresh);

  return [userInfo, startRefresh];
};

export default useLPFarmUserInfo;
