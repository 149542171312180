import { css } from "styled-components";
import { detect } from "detect-browser";
import { green, blue, black, purple, teal, grey, red, white } from "./colors";

const browser = detect();
const specialNames = ["safari", "ios", "ios-webview"];

const isSafari = browser && specialNames.includes(browser.name);

let textShadowWidth = 1;
if (browser && browser.name === "safari") {
  textShadowWidth = 1.5;
}

const MEDIA_WIDTHS = {
  upToXXXS: 320,
  upToXXS: 370,
  upToXS: 400,
  // upToExtraSmall: 500,
  upToExtraSmall: 600,
  upToSmall: 600,
  upToMedium: 960,
  upToMediumLarge: 1150,
  upToLarge: 1280,
  upToLargeBp: 1480,
  upToExtraLarge: 1550,
  upToMediumXs: 820,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const theme = {
  borderRadius: 8,
  color: {
    green,
    blue,
    black,
    red,
    grey,
    purple,
    primary: {
      light: red[200],
      main: blue[400],
    },
    secondary: {
      main: teal[200],
    },
    white,
    teal,
    yellow: "#FDD456",
    grade: ["#B8B8B8", "#71cea4", "#5db3ff", "#a073e0", "#ff6600", "#FF3F3A"],
    border: "#707070",
  },

  textShadow: `#000 ${textShadowWidth}px 0 0, #000 0 ${textShadowWidth}px 0, #000 -${textShadowWidth}px 0 0, #000 0 -${textShadowWidth}px 0`,
  boxShadow: "0 2px 2px rgba(0,0,0,0.16)",

  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },

  fontFamily: {
    fz: "",
    game: "",
    black: "",
    // fz: "方正综艺",
    // game: "Hiragino Sans, 冬青黑体简体中文",
    // black: "AvenirNextLTPro",
  },

  // media queries
  mediaWidth: mediaWidthTemplates,
  isSafari,
};

export default theme;
