import {
  useState,
  createContext,
  useContext,
  useEffect,
  Dispatch,
  FC,
} from "react";
import { useTranslation } from "react-i18next";

import { Context as TinyContext } from "src/contexts/TinyProvider";
import useModal from "src/hooks/useModal";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";

import { useNewAirdrops, AirdropResult } from "./hooks/airdropHooks";
import useSpiritPrice from "./hooks/useSpiritPrice";

import GasRefundModal from "./components/GasRefundModal";
import SpiritAirdropModal from "./components/SpiritAirdropModal";

import { noFunc, bn0 } from "src/utils";

interface IPageContext {
  showNotice: boolean;
  setShowNotice: Dispatch<React.SetStateAction<boolean>>;
  newAirdropData: AirdropResult | undefined;
  showGasRefundModal: () => void;
  showSpiritAirdropModal: () => void;
  spiritPrice: string;
}

export const PageContext = createContext<IPageContext>({
  showNotice: false,
  setShowNotice: noFunc,
  newAirdropData: undefined,
  showGasRefundModal: noFunc,
  showSpiritAirdropModal: noFunc,
  spiritPrice: "--",
});

const PageProvider: FC = ({ children }) => {
  const [showNotice, setShowNotice] = useState(true);
  const { tiny } = useContext(TinyContext);
  const { t } = useTranslation();

  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  const { data: newAirdropData } = useNewAirdrops();
  const bnbGasAmount = newAirdropData?.bnbAmount;
  const spiritValue = newAirdropData?.spiritValue;

  const [showGasRefundModal, onGasModalDismiss] = useModal(
    <GasRefundModal
      amount={bnbGasAmount || bn0}
      claimAirdrop={() => {
        const message = t("Claim Gas Rewards");
        return handleTransactionReceipt(tiny.claimGas(), message).then((tx) => {
          if (tx) {
            handleTxConfirm(tx.hash).then(() => {
              onGasModalDismiss();
            });
          }
        });
      }}
    />,
    [bnbGasAmount]
  );

  const [showSpiritAirdropModal, onSpiritModalDismiss] = useModal(
    <SpiritAirdropModal
      spiritValue={spiritValue || 0}
      claimAirdrop={() => {
        const message = t("Claim Airdrop Rewards");
        return handleTransactionReceipt(tiny.claimSpirit(), message).then(
          (tx) => {
            if (tx) {
              handleTxConfirm(tx.hash).then(() => {
                onSpiritModalDismiss();
              });
            }
          }
        );
      }}
    />,
    [spiritValue]
  );

  useEffect(() => {
    if (bnbGasAmount && !bnbGasAmount.isZero()) {
      showGasRefundModal();
    }
  }, [bnbGasAmount, showGasRefundModal]);

  useEffect(() => {
    if (typeof spiritValue === "number" && spiritValue > 0) {
      showSpiritAirdropModal();
    }
  }, [showSpiritAirdropModal, spiritValue]);

  const { spiritPrice } = useSpiritPrice();

  return (
    <PageContext.Provider
      value={{
        showNotice,
        setShowNotice,
        newAirdropData,
        showGasRefundModal,
        showSpiritAirdropModal,
        spiritPrice,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
