import { useQuery } from "react-query";
import { getStakedNFTs } from "src/services";
import type { NFTInfo } from "src/services/typings";
import { useBlockNumber } from "src/state/application/hooks";
import { calcStamina } from "src/utils";

const cacheKey = "STAKED_NFTS";

export interface NFTInfoEX extends NFTInfo {
  attackCount?: number;
}

/**
 * all staked nfts including lp and squad, no pooling
 * @returns
 */
export default function useStakedNFTList(
  account: string | undefined,
  refetch?: boolean
) {
  const blockNumber = useBlockNumber();
  return useQuery<NFTInfoEX[] | undefined>(
    [cacheKey, account],
    async ({ signal }) => {
      if (!account) {
        console.error("Can't find account");
        return;
      }
      const res = await getStakedNFTs(account, { signal });
      // console.log("getStakedNFTs res", res);
      const nfts = res.nfts;
      return nfts.map((ele) => {
        const stamina = calcStamina(
          ele.ownerflag,
          ele.StaminaInfo,
          blockNumber,
          ele.itemGrade
        );
        return {
          ...ele,
          stamina: stamina,
          masterAddress: account,
        };
      });
    },
    {
      refetchInterval: refetch ? 10_000 : false,
      enabled: typeof account === "string",
    }
  );
}
