import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import BaseButton, { BaseProps } from "./BaseButton";

import button_11_l from "src/images/button11_l.png";
import button_11_m from "src/images/button11_m.png";
import button_11_r from "src/images/button11_r.png";

const TButton: FC<PropsWithChildren<BaseProps>> = (props) => {
  const { children, disabled, ...rest } = props;

  return (
    <StyledTButton size="md" disabled={disabled} {...rest}>
      {children}
    </StyledTButton>
  );
};

export default TButton;

const StyledTButton = styled(BaseButton)<BaseProps>`
  &.md {
    height: 30px;
    line-height: normal;
    font-size: 18px;
    width: 6.3125rem;
  }
  &.sm {
    height: 28px;
    font-size: 16px;
  }

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  /* min-width: ${(props) => (props.size === "sm" ? 60 : 110)}px; */
  border-radius: 18px;
  color: white;
  background: #6a9cd9;
  white-space: nowrap;
  text-align: center;

  ${({ theme, size }) => theme.mediaWidth.upToExtraSmall`
    &.md {
      height: 26px;
      line-height: normal;
      font-size: 12px;
      width: 6.6rem;
    }
    &.sm {
      height: 19px;
      font-size: 12px;
    }
  `}
`;

export const GButton = styled(TButton)`
  background: #fdd770;
  height: 2rem !important;
  border-radius: 1rem;
  color: #815920;
  font-size: 1rem !important;

  :active {
    filter: drop-shadow(0 0px 0 #66666688);
    transform: translate(-1px, -1px);
    border: none;
  }

  :disabled {
    cursor: default;
    filter: drop-shadow(0 3px 0 #66666688) grayscale(1);
    transform: none;
  }
`;
