import { useContext, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Context as FarmsContext } from "src/contexts/Farms";
import useTINCMarket from "src/hooks/useTINCMarket";

import { GoButton } from "../Mine/YieldFarming/components/FarmCard";

import diImg from "src/images/di_2.png";
// import type { PoolStakeInfo } from "src/services/index";
import useIsMobile from "src/hooks/useIsMobile";
import PrimaryButton from "src/components/Button/PrimaryButton";
import { useHistory } from "react-router";
import { commify } from "ethers/lib/utils";
import useDaoTotalLockedTINC from "src/hooks/useDaoTotalLockedTINC";
import { getDisplayBalance } from "src/utils/formatBalance";
import useFarmingV2 from "src/hooks/useFarmingV2";

// CM Development Pool:  0x1E82C43255423c448Ad6C05e206f0A57BbA90676
// Eco Growth Pool: 0xF106396363F11c1c887DD3C19ee64529E100A5fA
// Team Pool: 0xA108502ED4B76Ea867b8A95B2BD9920D81B370c1
// Burn Pool: 0x8B2a2D9dc7Bc31F4b15b3Bd9f319246E5F5a479A
// Seed: 0x9C255715386d8e21BB00491580A151e1FA196259

function EconomyInfo() {
  const { lpFarmNFTPowerInfo, stakeInfos, calcApyByBonus } =
    useContext(FarmsContext);
  const history = useHistory();
  const pool = lpFarmNFTPowerInfo;
  const { t } = useTranslation();
  const max_bonus = pool.max_bonus || 0;
  const isMobile = useIsMobile();

  const { aprs } = useFarmingV2();

  const { data: totalLockedValue, refetch } = useDaoTotalLockedTINC();

  const market = useTINCMarket();

  return (
    <StyledContainer>
      {!isMobile && (
        <StyledTINCInfo className="shadow">
          <StyledTINCBox>
            <StyledTINCImg
              src="//images.tinyworlds.io/tokens/TINC.png"
              alt="TINC"
            />
            <span>TINC</span>
          </StyledTINCBox>
          <StyledTINCItem>
            <span>{t("TINC Total Supply")}</span>
            <span>{market.totalSupply}</span>
          </StyledTINCItem>
          <StyledTINCItem>
            <span>{t("TINC In Circulation")}</span>
            <span>{market.supply}</span>
          </StyledTINCItem>
          {/* <StyledTINCItem>
            <span>{t("TINC MarketCap")}</span>
            <span>$ {market.marketCapStr}</span>
          </StyledTINCItem> */}
          <StyledTINCItem>
            <span>{t("Total Locked")}</span>
            <span>
              {totalLockedValue
                ? getDisplayBalance(totalLockedValue, 18, 0, true)
                : "--"}
            </span>
          </StyledTINCItem>
          <StyledTINCItem>
            <span>{t("Total Burned")}</span>
            <span>{market.burnBalance || "--"}</span>
          </StyledTINCItem>
          <StyledTINCItem>
            <span>{t("Treasury Fund")}</span>
            <span>$ {market.totalFund}</span>
          </StyledTINCItem>
        </StyledTINCInfo>
      )}
      <StyledContent className="shadow">
        <span className="display-flex align-center">
          {t("Earn up to")}&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="white text-outline rfs-18 ff-black">
            {parseFloat(aprs.maxApr.toFixed(2))}%
          </span>
          &nbsp;&nbsp;APR
        </span>
        {/* <GoButton height={29} /> */}
        <PrimaryButton onClick={() => history.push("/tinyvault/LP")}>
          {"Go"}
        </PrimaryButton>
      </StyledContent>
    </StyledContainer>
  );
}

export default memo(EconomyInfo);

const StyledContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    padding: 0 15px;
  `}
`;

const StyledTINCInfo = styled.div`
  background: #fca948;
  color: white;
  padding: 10px;
  padding-left: 0;
  display: flex;
  // gap: 80px;
  font-size: 1rem;
  border-radius: 5px;
  width: 67%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100% - 20px);
    gap: 10px;
    flex-wrap: wrap;
  `}
`;

const StyledTINCItem = styled.div`
  border-left: 3px solid white;
  padding-left: 5px;
  // width: auto;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: start;
  > span:first-child {
    color: #815920;
    font-weight: 600;
  }
  > span:last-child {
    font-size: 1.2rem;
    font-weight: 600;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 45%;
  `}
`;

const StyledContent = styled.div`
  width: calc(33% - 12px);
  box-shadow: ${(props) => props.theme.boxShadow};
  background-image: url(${diImg});
  margin-left: 12px;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    height: 50px;
    margin-left: 0;
    margin-top: 5px;
  `}
`;

const StyledTINCBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 2rem;

  span {
    line-height: 1;
  }
`;

const StyledTINCImg = styled.img`
  height: 24px;
  /* margin-right: 5%; */
  border: 1.5px solid #514b45;
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    // margin-right: 5px;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 19px;
    // margin-right: 3px;
  `}
`;
