import { BigNumber } from "ethers";
import { useQuery } from "react-query";
import useTiny from "./useTiny";
import useDataCache from "./useDataCache";
import { bn1e18 } from "src/utils/index";

export default function useFarmTINCPerDay() {
  const tiny = useTiny();
  const supplier = tiny.contracts.TinySupplier;
  const lpfarm = tiny.contracts.TinyLPFarm;

  // const [cache, setCache] = useDataCache<number>(
  //   "LP_FARM_TINC_PER_DAY",
  //   localStorage,
  //   {
  //     isBigNumber: true,
  //     initData: 45000,
  //   }
  // );

  // useQuery(
  //   "LP_FARM_TINC_PER_DAY",
  //   () => {
  //     return supplier.callStatic.consumerInfo(lpfarm.address).then((res) => {
  //       const { rewardPerBlock }: { rewardPerBlock: BigNumber } = res;
  //       const result = rewardPerBlock.div(bn1e18).toNumber();
  //       setCache(result);
  //     });
  //   },
  //   {
  //     refetchInterval: 30_000,
  //   }
  // );

  // const cache = 33750;
  const cache = 25312.5;

  return cache;
}
