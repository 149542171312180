import { useContext, useEffect, useState, Fragment, memo } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";

import { PageContext } from "src/contexts/Page";
import { Context } from "src/contexts/TinyProvider";
import useIsInvestor from "src/hooks/useIsInvestor";

import useModal from "src/hooks/useModal";
import useIsMobile from "src/hooks/useIsMobile";
import VestingICON from "src/images/Vesting_ICON.svg";
import VestingModal from "./VestingModal";
import BourseModal from "../../SideBar/components/BourseModal";
import useFarmPrice from "src/hooks/useFarmPrice";
import { useHistory } from "react-router";
import WalletButton from "./WalletButton";
import CheckinDropDown from "./CheckinDropDown";
import AirdropDropDown from "./AirdropDropdown";
import useMyRuneBalance from "src/hooks/useMyRuneBalance";
// import addToShopSvg from "src/images/shopping/add.svg";
import addSpirit from "src/images/shopping/add-spirit.svg";
import ExternalModal from "src/components/ExternalModal";
import CheckHeroModal from "src/views/MyNFT/NFTList/Components/CheckHeroModal";
import { IHeroHead } from "src/components/HeadHeroCard/type";
import RaisePowerModal from "src/components/RaisePower/RaisePowerModal";
import ReplenishModal from "src/components/ReplenishStamina/ReplenishModal";
import { New } from "src/components/Hint";
// import { useOldLordReward } from "src/hooks/useLordOnline";

/**
 * 连接状态有
 * 1. 未连接
 * 2. 连接中
 * 3. 连接错误
 * 4. 已连接
 * @returns
 */
function StatusButtons() {
  const { connector } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState<any>();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const [showCheckHeroModal, setShowCheckHeroModal] = useState(false);
  const [showReplenishModal, setShowReplenishModal] = useState(false);
  const [showRaisePowerModal, setShowRaisePowerModal] = useState(false);
  const [checkHero, setCheckHero] = useState<IHeroHead[]>([]);
  const [staminaItemNum, startRefresh] = useMyRuneBalance(3);

  // 体力恢复道具价格
  const { t } = useTranslation();
  const { tiny } = useContext(Context);
  const { spiritPrice } = useContext(PageContext);
  const myAccount = tiny.myAccount;
  const isMobile = useIsMobile();

  const history = useHistory();

  // console.log("isNetworkCorrect", isNetworkCorrect);
  const isInvestor = useIsInvestor(myAccount);

  const [showBourseModal] = useModal(<BourseModal />, []);
  const [price] = useFarmPrice();

  // const { data: lastCheckIn, refetch: lastCheckInRefetch } =
  //   useLastCheckInByAddress();

  const [showVestingModal] = useModal(<VestingModal />, []);
  // const { t } = useTranslation();

  return (
    <Fragment>
      {isInvestor && (
        <StyledDrop
          className="mobile-hidden"
          style={{
            background: "#FDCE4F",
            overflow: "hidden",
          }}
          onClick={showVestingModal}
        >
          <img
            style={{
              filter: "drop-shadow(#514B45 100px 0)",
              position: "relative",
              left: -100,
            }}
            height={18}
            src={VestingICON}
            alt=""
          />
          <StyledText className="ml-5">{t("Vesting")}</StyledText>
        </StyledDrop>
      )}

      <AirdropDropDown />
      <CheckinDropDown />

      {showCheckHeroModal && (
        <ExternalModal
          boxStyle={{
            width: "100vw",
          }}
          onClose={() => setShowCheckHeroModal(false)}
        >
          {({ handleClose }) => (
            <CheckHeroModal
              showReplenishModal={() => {
                setShowReplenishModal(true);
              }}
              showRaisePowerModal={() => {
                setShowRaisePowerModal(true);
              }}
              checkHero={checkHero}
              setCheckHero={setCheckHero}
              onDismiss={() => {
                setCheckHero([]);
                handleClose();
              }}
            />
          )}
        </ExternalModal>
      )}

      {showReplenishModal && (
        <ExternalModal
          disableScale
          boxStyle={{
            width: "100vw",
          }}
          onClose={() => setShowReplenishModal(false)}
        >
          {({ handleClose }) => (
            <ReplenishModal
              onDismiss={handleClose}
              setCheckHero={setCheckHero}
              disableScale
              onHandlerDismiss={() => {
                // setShowCheckHeroModal(false);
                handleClose();
              }}
              heros={checkHero ? [...checkHero] : []}
            />
          )}
        </ExternalModal>
      )}

      {showRaisePowerModal && (
        <ExternalModal
          disableScale
          boxStyle={{
            width: "100vw",
          }}
          onClose={() => setShowRaisePowerModal(false)}
        >
          {({ handleClose }) => (
            <RaisePowerModal
              onDismiss={handleClose}
              disableScale
              heros={checkHero ? [...checkHero] : []}
            />
          )}
        </ExternalModal>
      )}

      {isMobile ? (
        <WalletButton />
      ) : (
        <>
          <StyledTINCPrice className="tinc-balance" onClick={showBourseModal}>
            <img
              className="mr-5"
              width={23}
              src="//images.tinyworlds.io/tokens/TINC.png"
              alt="TINC"
            />
            <StyledPrice className="color-price">
              ${price.TINC.toFixed(4)}
            </StyledPrice>
          </StyledTINCPrice>
          <StyledTINCPrice
            className="tinc-balance position-relative"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push("/marketplace/tinyswapv1");
            }}
          >
            {/* {!isMobile && (
              <New
                style={{
                  position: "absolute",
                  top: 0,
                  transform: "translate(0, -13px)",
                }}
                bottom={-1}
              />
            )} */}
            <img
              className="mr-5"
              width={26}
              src={"//images.tinyworlds.io/tokens/SPIRIT.png"}
              alt="SPIRIT"
            />
            <StyledPrice className="color-price">${spiritPrice}</StyledPrice>
            <StyledShoppingButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (myAccount) {
                  setCheckHero([]);
                  setShowCheckHeroModal(true);
                } else {
                  history.push("/marketplace/tinyswapv1");
                }
              }}
            >
              <StyledAddSvg
                style={{
                  marginTop: staminaItemNum.toNumber() === 0 ? 0 : 3,
                }}
                // src={staminaItemNum.toNumber() === 0 ? addToShopSvg : addSpirit}
                src={addSpirit}
                alt="add"
              />
            </StyledShoppingButton>
          </StyledTINCPrice>
          <WalletButton />
        </>
      )}
    </Fragment>
  );
}

export default memo(StatusButtons);

const StyledAddSvg = styled.img`
  height: 16px;
`;

const StyledShoppingButton = styled.button`
  width: 24px;
  height: 23px;
  border-radius: 50%;
  box-shadow: 0 2px 0 #bc7a4a;
  background-color: #fdce4f;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  :hover {
    background-color: #ffe08e;
  }
  :disabled {
    filter: grayscale(1);
  }
`;

const StyledPrice = styled.span`
  padding-bottom: 1px;
  margin-right: 3px;
  font-size: 1.1rem;
  min-width: 2rem;
`;

const StyledBorderButton = styled.button`
  box-sizing: border-box;
  padding: 4px 12px 4px 8px;
  height: 36px;
  border-radius: 1.2rem;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  font-weight: 600;
  font-size: 1.1rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 2.4rem;
    padding: 4px;
  `}
`;

const StyledTINCPrice = styled(StyledBorderButton)`
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  background-color: #514b45;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 5px;
    .tinc-balance {
      width: max-content
    }
  `}
`;

const StyledDrop = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  // width: 7.8rem;
  height: 2.4rem;
  background-color: #5194e5;
  border-radius: 33px;
  text-align: center;
  font-size: 1.1rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.21);
  font-weight: 600;

  width: auto;
  min-width: 7.8rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: auto;
    min-width: 3.2rem;
    background-size: 100% 100%;
    > span {
      display: none;
    }
    margin-right: 5px;
  `};
`;

const StyledText = styled.span`
  .zh & {
    font-size: 1.1rem;
  }
  color: #514b45;
`;
