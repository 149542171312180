// import useDescByCode from "./useDescByCode";

import { useTranslation } from "react-i18next";

export interface GuideDetail {
  setpTitle: string;
  setpSpan?: string;
  detailTitle: string;
  firstPageTitle?: string;
  lastPageTitle?: string;
  setpTitleM?: string;
  detailTitleM?: string;
}
// {t("Buy TINC1")}

export default function useGuideDetail() {
  const { t } = useTranslation();

  const GuideList: GuideDetail[] = [
    {
      setpTitle: t("create Wallet"),
      detailTitle: t("create Wallet"),
      firstPageTitle: t("create Wallet firstPageTitle"),
      lastPageTitle: t("create Wallet lastPageTitle"),
      setpTitleM: t("create Wallet M"),
      detailTitleM: t("create Wallet M"),
    },
    {
      setpTitle: t("Buy TINC"), //購買TINC
      detailTitle: t("Buy TINC"),
      firstPageTitle: t("Buy TINC on DEX"),
      lastPageTitle: t("Buy TINC on CEX"),
    },
    {
      setpSpan: t("Obtain"),
      setpTitle: t("Tiny Hero NFT"), //獲取小小英雄NFT
      detailTitle: t("Obtain") + "" + t("Tiny Hero NFT"),
      firstPageTitle: t("Summon Tiny NFT with TINC"),
      lastPageTitle: t("Buy Tiny NFT with BUSD"),
    },
    {
      setpSpan: t("Upgrade1"),
      setpTitle: t("Tiny Hero"), //升級英雄NFT
      detailTitle: t("Upgrade1") + "" + t("Tiny Hero"),
      firstPageTitle: t("Introduction"),
      lastPageTitle: t("How to upgrade NFTs"),
    },
    {
      setpSpan: t("Play"),
      setpTitle: t("Tiny Farm"), //遊玩小小農場
      detailTitle: t("Play") + "" + t("Tiny Farm"),
      firstPageTitle: t("Boost Pool Farming"),
      lastPageTitle: t("NFT Farming"),
    },
    {
      setpSpan: t("Play"),
      setpTitle: t("Tiny Kingdom"), //遊玩小小王國
      detailTitle: t("Play") + "" + t("Tiny Kingdom"),
      firstPageTitle: t("Introduction"),
      lastPageTitle: t("Play to Earn"),
    },
    {
      // setpSpan: "Upgrade",
      setpTitle: t("dao"),
      detailTitle: t("dao"),
      firstPageTitle: t("Guideline"),
      lastPageTitle: t("Lock TINC to Obtain veTINC"),
    },
    {
      setpTitle: t("Referral System"),
      detailTitle: t("Referral System"),
      firstPageTitle: t("Guideline"),
      lastPageTitle: t("Invite Friends"),
    },
    // {
    //   setpSpan: "Upgrade",
    //   setpTitle: "Tiny Hero",
    //   detailTitle: "Upgrade Your Hero",
    //   firstPageTitle: "Introduction",
    //   lastPageTitle: "How to upgrade NFTs",
    // },
  ];
  return { GuideList };
}
