import { lazy, Suspense, useEffect, useMemo, FC } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { SwitchTransition, CSSTransition } from "react-transition-group";
// TinyNFTRune SPIRIT合约

import store from "./state";
import Updaters from "./state/Updaters";
import theme from "./theme";
import ModalsProvider from "./contexts/Modals";
import TinyProvider from "./contexts/TinyProvider";
import PageProvider from "./contexts/Page";
import FarmsProvider from "./contexts/Farms";
// import useInGameTestList from "src/hooks/useInGameTestList";
import useURLRef from "./hooks/useURLRef";

import Home from "./views/Home";
import Popups from "./components/Popups";
import Page from "./components/Page";
import ErrorBoundary from "./components/ErrorBoundary";
import Topbar from "./views/PageComponents/Topbar";
import BottomBar from "./views/PageComponents/BottomBar";
import Loader from "./components/Loader";
// import { deleteAT } from "./utils/storage";
// import { getDefaultProvider } from "./utils/provider";
import config from "./config";
import { Toaster } from "react-hot-toast";

import "./App.css";
import GamePage from "./views/Game/GamePage";
import Guide from "./views/Guide";
import MockBreedContainer from "./views/MyNFT/NFTPets/MockBreedContainer";
import SoulSpiritSwap from "./views/Home/SoulSpiritSwap";
import useSidSdk from "./hooks/useSidSdk";

// import useModal from "./hooks/useModal";
// import BetaNoticeModal from "./components/BetaModal";
// import BetaNotification from "./components/BetaHeader";
// import { useBlockNumber } from "./state/application/hooks";

const MyNFT = lazy(() => import("./views/MyNFT"));
const Mine = lazy(() => import("./views/Mine"));
const Blind = lazy(() => import("./views/Blind"));
const MarketPlace = lazy(() => import("./views/MarketPlace"));
const Game = lazy(() => import("./views/Game"));
// const Rank = lazy(() => import("./views/Rank"));
const Dao = lazy(() => import("./views/Dao"));
const Invite = lazy(() => import("./views/Invite"));
const Catalogue = lazy(() => import("./views/Guide/Catalogue"));

// Create a client
const queryClient = new QueryClient();

export const cachePetHatch: number[] = [];

function Routers() {
  const { account } = useWeb3React();
  let location = useLocation();

  const { getRefCodeFromSearch, setRefCode } = useURLRef();

  useEffect(() => {
    const refCode = getRefCodeFromSearch();
    if (refCode) {
      setRefCode(refCode);
    }
  }, [getRefCodeFromSearch, setRefCode]);
  // const blockNumber = useBlockNumber()

  const pathname = location.pathname.split("/").slice(0, 2).join("/");
  // console.log("pathname", pathname);

  // 修复web版 tiny系列游戏框中打开了首页的异常
  if (window.self !== window.top) {
    window.parent.location.href = "/home";
  }

  return (
    <PageProvider>
      <ErrorBoundary account={account}>
        {/* <BetaNotification /> */}
        <Page>
          <Topbar />
          <Suspense fallback={<Loader />}>
            <SwitchTransition>
              <CSSTransition key={pathname} classNames="fade" timeout={500}>
                <Switch location={location}>
                  <Route path="/tinyvault">
                    <Mine />
                  </Route>
                  <Route path="/mysterybox">
                    <Blind />
                  </Route>
                  <Route path="/mynfts">
                    <MyNFT />
                  </Route>
                  <Route path="/marketplace">
                    <MarketPlace />
                  </Route>
                  <Route path="/game">
                    <Game />
                  </Route>
                  <Route path="/invitation">
                    <Invite />
                  </Route>
                  <Route path="/dao">
                    <Dao />
                  </Route>
                  <Route path="/guide">
                    <Guide />
                  </Route>
                  <Route path="/catalogue">
                    <Catalogue />
                  </Route>
                  <Route path="/pets/mock">
                    <MockBreedContainer />
                  </Route>
                  {/* <Route path="/soul-spirit">
                    <SoulSpiritSwap />
                  </Route> */}
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          </Suspense>
          <BottomBar />
        </Page>
        <Toaster></Toaster>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </ErrorBoundary>
    </PageProvider>
  );
}

const App: FC = () => {
  const gameUrl = useMemo(() => {
    return localStorage.getItem("gameUrl");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={null}>
          <Switch>
            <Route path={"/tinykingdom"}>
              {gameUrl ? (
                <GamePage gameSrc={JSON.parse(gameUrl)} />
              ) : (
                <Loader />
              )}
            </Route>
            <Provider store={store}>
              <Web3ReactProvider getLibrary={(ethereum) => ethereum}>
                <Updaters />
                <QueryClientProvider client={queryClient}>
                  <TinyProvider>
                    <ModalsProvider>
                      <FarmsProvider>
                        <Popups />
                        <Routers />
                      </FarmsProvider>
                    </ModalsProvider>
                  </TinyProvider>
                </QueryClientProvider>
              </Web3ReactProvider>
            </Provider>

            <Route children={<Redirect to="/" />} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;
