import React, { MutableRefObject, memo, useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFarmPrice from "src/hooks/useFarmPrice";

import Language from "./components/Language";

import { ReactComponent as FaceBook } from "src/images/bottom/fb.svg";
import { ReactComponent as Instagram } from "src/images/bottom/ins.svg";
import twPng from "src/images/bottom/icon_tw.png";
import tgPng from "src/images/bottom/icon_tg.png";
import dcPng from "src/images/bottom/icon_dc.png";
import mdPng from "src/images/bottom/icon_md.png";
import gbPng from "src/images/bottom/icon_gb.png";
import livePng from "src/images/bottom/icon_blive.svg";
import tinyKingdom from "src/images/logos/logo_k.png";
import gameHover from "src/images/game-direct/hover.png";
import play from "src/images/game-direct/play.png";
import playActive from "src/images/game-direct/play_select.png";
import BourseModal from "./components/BourseModal";
import useModal from "src/hooks/useModal";
import IconBlive from "src/components/IconBlive";
import Advertise from "./components/Advertise";
import useIsMobile from "src/hooks/useIsMobile";
import audit from "src/images/audit-new.png";

function Bottom() {
  const { i18n } = useTranslation();
  const [price] = useFarmPrice();
  const lang = i18n.language;
  const [isClickDown, setIsClickDown] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isMobile = useIsMobile();

  const [showBourseModal, onBourseModalDismiss] = useModal(<BourseModal />, []);

  return (
    <StyledBottom className="display-flex flex-column">
      <StyledTooltipButton>
        {/* <ReactTooltip
          id="gameDirect"
          effect="solid"
          border
          textColor="#935316"
          backgroundColor="#FFBD4A"
          borderColor="#BC6C1E"
        >
          coming soon
        </ReactTooltip> */}
        <NavLink to="/game">
          <StyledGameDirect
            // data-tip
            // data-for="gameDirect"
            onMouseDown={() => {
              setIsClickDown(true);
              setIsHover(false);
            }}
            onMouseUp={() => {
              setIsClickDown(false);
              setIsHover(false);
            }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <StyledKingdomImg
              className="tiny-kingdom"
              src={tinyKingdom}
              alt="tiny kingdom"
            />
            <div
              className="position-relative"
              style={{ width: "7.8rem", marginLeft: -30 }}
            >
              <StyledGameButton
                className="play"
                src={isHover ? gameHover : isClickDown ? playActive : play}
                alt="tiny kingdom"
              />
            </div>
          </StyledGameDirect>
        </NavLink>
      </StyledTooltipButton>

      <div className="display-flex flex-between align-center pl-10">
        <Language />
        {!isMobile && (
          <StyledAudit
            target="_blank"
            href="https://www.certik.com/projects/tinyworld"
          >
            <img src={audit} width={168} height={36} alt="Certik" />
          </StyledAudit>
        )}
      </div>
      <StyledLine />
      <StyledIconList>
        <a
          target="_blank"
          href="https://twitter.com/tinyworldgamefi"
          rel="noreferrer"
        >
          <img src={twPng} alt="Twitter" />
        </a>
        <a
          target="_blank"
          href={
            lang === "zh"
              ? "https://t.me/tinyworld_cn"
              : "https://t.me/tinyworld_en"
          }
          rel="noreferrer"
        >
          <img src={tgPng} alt="Telegram" />
        </a>
        <a
          target="_blank"
          href="https://discord.gg/jXTRTz7B7N"
          rel="noreferrer"
        >
          <img src={dcPng} alt="Discord" />
        </a>
        <a
          target="_blank"
          href="https://medium.com/@tinyworld.gamefi"
          rel="noreferrer"
        >
          <img src={mdPng} alt="Medium" />
        </a>
        <a
          target="_blank"
          href={
            lang === "zh"
              ? "https://docs.tinyworlds.io/v/cn/"
              : "https://docs.tinyworlds.io/"
          }
          rel="noreferrer"
        >
          <img src={gbPng} alt="Gitbook" />
        </a>
        <a
          className="binance-live"
          target="_blank"
          href={`https://www.binance.com/${
            lang === "zh" ? "zh-CN" : lang
          }/live/u/25851821`}
          rel="noreferrer"
        >
          <IconBlive fill="#514b45" />
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/profile.php?id=100084851317037"
          rel="noreferrer"
        >
          <FaceBook fill="#514b45" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/tinyworld.gamefi/"
          rel="noreferrer"
        >
          <Instagram fill="#514b45" />
        </a>
      </StyledIconList>
    </StyledBottom>
  );
}

export default memo(Bottom);

const StyledAuditBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledAudit = styled.a`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 1;

  img {
    width: 100%;
    height: auto;
  }
`;

const StyledAdvertise = styled(Advertise)<{ bottom?: number }>`
  bottom: ${({ bottom }) => (bottom ? 0 : 0)}px;
`;

const StyledBottom = styled.div`
  justify-content: flex-end;
  flex-shrink: 0;
  width: 100%;
`;

const StyledTooltipButton = styled.div`
  .__react_component_tooltip {
    border-radius: 15px;
    font-size: 1.2rem;
    max-width: 200px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
`;

const StyledGameDirect = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;

  .tiny-kingdom {
    filter: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.33));
  }

  :active {
    .tiny-kingdom {
      transform: translateY(4px);
      filter: none;
    }
  }
`;

const StyledGameButton = styled.img`
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: -1;
  height: 2.85rem;

  ${({ theme }) => theme.mediaWidth.upToLargeBp`
    left: 5px;
    bottom: 8px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    left: 5px;
    bottom: 5px;
  `}
`;

const StyledLine = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid #707070;
  width: 97%;
`;

const StyledIconList = styled.div`
  display: flex;
  padding: 0 13px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};

  ${({ theme }) => theme.mediaWidth.upToLargeBp`
    margin: 0 auto;
    transform: translate(-3px, -4px);
  `}

  a {
    overflow: hidden;
    height: fit-content;
  }

  img,
  svg {
    display: block;
    width: 24px;

    ${({ theme }) => theme.mediaWidth.upToLargeBp`
      width: 18px;
    `}
  }

  .binance-live svg {
    width: 32px;
    height: 32px;

    ${({ theme }) => theme.mediaWidth.upToLargeBp`
      width: 24px;
      height: 24px;
    `}
  }
`;

const StyledBalance = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  // margin: 2px auto 0;
  margin-top: 2px;
  cursor: pointer;
  padding: 2px 6px;
  color: ${(props) => props.theme.color.yellow};
  font-weight: 600;
  background-color: #ba742f;
  border: 2px solid #fdce4f;
  border-radius: 36px;
`;

const StyledPrice = styled.span`
  padding-bottom: 1px;
  margin-right: 3px;
  color: white;
  font-size: 1.1rem;
  min-width: 2rem;
`;

const StyledKingdomImg = styled.img`
  height: 70px;

  ${({ theme }) => theme.mediaWidth.upToLargeBp`
    height: 60px;
    margin-left: 18px;
  `}
`;
