import { useCallback, useMemo } from "react";
import { BigNumber, utils } from "ethers";
import { useQuery } from "react-query";
import useMulticall from "./useMulticall";
import { getMulticallContract } from "src/tiny/utils";
import { bn0 } from "src/utils";
import { chainId } from "src/config";

import type { CallItem } from "src/utils/multicall";
import type { Tiny } from "src/tiny/Tiny";

const balanceOfAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

function useFetchCall(abi: any[], calls: CallItem[]) {
  const multicall = useMulticall(abi, calls);

  const fetchBatchBalance = useCallback(async () => {
    // console.log('calls', calls);
    try {
      const { returnData, blockNumber } = await multicall();
      // console.log("returnData", returnData);
      return {
        results: returnData.map((ele) => BigNumber.from(ele)),
        blockNumber,
      };
      // setResult(returnData.map((ele) => BigNumber.from(ele)));
      // setBlockNumber(blockNumber.toNumber());
    } catch (error) {
      console.error("fetchBalance error:", error);
    }
  }, [multicall]);

  return fetchBatchBalance;
}

export function useTokenBatchBalance(
  tokenAddress: string,
  addresses: string[]
) {
  // const [results, setResult] = useState<BigNumber[]>();

  const calls = useMemo(() => {
    return addresses.map((address) => {
      return {
        address: tokenAddress,
        name: "balanceOf",
        params: [address],
      };
    });
  }, [tokenAddress, addresses]);

  const fetchBatchBalance = useFetchCall(balanceOfAbi, calls);

  return useQuery(
    ["TOKEN_BALANCE_BATCH", tokenAddress, addresses],
    () => fetchBatchBalance(),
    {
      refetchInterval: 600_000,
    }
  );
}

export interface BatchParamItem {
  account: string;
  token: string;
}
interface BatchParamOption {
  enabled: boolean;
}
export function useBatchTokenAndBatchBalance(
  list: BatchParamItem[],
  options?: BatchParamOption
) {
  const _options: BatchParamOption = options || { enabled: true };
  const enabled = _options ? _options.enabled : true;
  const calls = useMemo(() => {
    return list.map(({ account, token }) => {
      return {
        address: token,
        name: "balanceOf",
        params: [account],
      };
    });
  }, [list]);

  const fetchBatchBalance = useFetchCall(balanceOfAbi, calls);

  return useQuery(
    ["TOKEN_BATCH_BALANCE_BATCH", list],
    () => fetchBatchBalance(),
    {
      enabled,
      refetchInterval: 600_000,
    }
  );
}

const masterChefAbi = balanceOfAbi.concat({
  inputs: [],
  name: "totalSupply",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
});

export function useMasterChefV2Busd(tiny: Tiny, address: string) {
  const masterChefV2 = tiny.contracts.MasterChefV2;
  const multicallContract = getMulticallContract(tiny);
  const BUSD = tiny.externalTokens.BUSD;

  const itf = new utils.Interface(masterChefAbi);

  // BUSD-USDC LP
  const stableSwapLpAddress = "0x1A77C359D0019cD8F4d36b7CDf5a88043D801072";
  const stableSwapAddress = "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe";

  const calldata = [
    [
      stableSwapLpAddress.toLowerCase(),
      itf.encodeFunctionData("totalSupply", []),
    ],
    [
      BUSD.address.toLowerCase(),
      itf.encodeFunctionData("balanceOf", [stableSwapAddress]),
    ],
  ];

  const pid = 0x86;
  const { data } = useQuery<BigNumber>(
    ["MASTERCHEFV2_LP_BUSD", address, pid],
    async () => {
      const lpAmounts: { amount: BigNumber } = await masterChefV2.userInfo(
        pid,
        address
      );
      // 用户的 LP 的量
      const amount = lpAmounts.amount;
      // console.log("amount", amount.toString());
      // 获取 LP 的 totalSupply 和 拥有的 BUSD 的量
      const { returnData }: { returnData: string[] } =
        await multicallContract.aggregate(calldata);
      // console.log("returnData", returnData);
      const [lpTotalSupply, busdBalanceStr] = returnData;
      const busdBalance = BigNumber.from(busdBalanceStr)
        .mul(amount)
        .div(lpTotalSupply);
      return busdBalance.mul(2);
    },
    {
      enabled: chainId === 56,
      initialData: bn0,
    }
  );
  return data ?? bn0;
}

const vTokenAbi = balanceOfAbi.concat({
  name: "balanceOfUnderlying",
  inputs: [
    {
      internalType: "address",
      name: "owner",
      type: "address",
    },
  ],
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  type: "function",
  stateMutability: "view",
});

const vTokens = [
  "0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8", // vUSDC
];

// 获取某个地址在 venus 合约里面的 token 的 balance
export function useVenusTokenBalance(account: string) {
  // const _options: BatchParamOption = options || { enabled: true };
  // const enabled = _options ? _options.enabled : true;

  const calls = useMemo(() => {
    return vTokens.map((vTokenAddress) => {
      return {
        address: vTokenAddress,
        name: "balanceOfUnderlying",
        params: [account],
      };
    });
  }, [account]);

  const fetchBatchBalance = useFetchCall(vTokenAbi, calls);

  return useQuery(
    ["VENUS_TOKEN_BALANCE_BATCH", account],
    () => fetchBatchBalance(),
    {
      enabled: chainId === 56,
      refetchInterval: 600_000,
    }
  );
}
