import { detect } from "detect-browser";
import { backend_url, static_api_base } from "../constants";

const browser = detect();

export function request<T = any>(
  path: string,
  options?: RequestInit
): Promise<T> {
  return fetch(backend_url + path, options).then((res) => res.json());
}

export function fileFetch(path: string, options?: RequestInit) {
  return fetch(backend_url + static_api_base + path, options);
}

export interface ErrorBody {
  account: string | null | undefined;
  error: string | null | undefined;
  type: "tx" | "components" | "api";
  [key: string]: string | number | null | undefined;
}
//account,error,'api',[apiAddress]
export function logErrorToService(body: ErrorBody) {
  if (process.env.NODE_ENV !== "production") {
    return console.log("logErrorToService body:", body);
  }
  const account = window?.ethereum?.selectedAddress;
  const common: any = {
    pathname: window.location.pathname,
    os: browser?.os,
    browsername: browser?.name,
  };
  if (!body.account) {
    common.account = account;
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  return request("/api/dev/error", {
    headers,
    body: JSON.stringify(Object.assign({}, body, common)),
    method: "POST",
  });
}

export function getLoginMessage(account: string) {
  return fetch(backend_url + `/api/login/message?address=${account}`).then(
    (res) => {
      if (res.status == 200) {
        return res.text();
      } else {
        throw new Error(res.statusText);
      }
    }
  );
}
