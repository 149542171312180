import { FC, ReactChild, useMemo } from "react";
import styled from "styled-components";

import { gradeType, heroPrefillColor, heroRole } from "src/config";

import commonplaceBorder from "src/images/lord/hero/n_r_sr_border.png";
import iconFight from "src/images/lord/icon_fight.png";

import powerL from "src/images/power.svg";
import heroPre from "src/images/hero/hero_pre.png";
import freeIcon from "src/images/lord/available.png";

import lp_boost from "src/images/status/lp_boost.png";
import gamingPng from "src/images/status/gaming.png";
// import lordStationing from "src/images/status/lord_stationing.png";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useDescByCode from "src/hooks/useDescByCode";
import PrefilledImage from "src/components/Images/PrefilledImage";
import { ICardHero, ICheckHeroButton } from "./type";

import lp_lord from "src/images/status/lp_lord.png";
import {
  avatarBorders,
  getHeroHeadImg,
  getHeroHeadImgPre,
  getRoleBg,
  gradeImg,
  heroImgHeight,
  roleImg,
} from "./const";

interface IHeadHeroCardM extends ICardHero {
  isPowerTop?: boolean;
  powerNote?: ReactChild | undefined;
}

export const CheckHeroButton: FC<ICheckHeroButton> = ({
  style,
  boxStyle,
  // index,
  // checkIndex,
  height = 94,
}) => {
  return (
    <StyledHeroCard height={height} style={{ ...boxStyle }}>
      <StyledCheckHeroHead height={height * heroImgHeight} style={{ ...style }}>
        <StyledAddBox>
          <StyledAdd>
            <img src={heroPre} alt="" />
          </StyledAdd>
        </StyledAddBox>
      </StyledCheckHeroHead>
    </StyledHeroCard>
  );
};

const HeadHeroCardM: FC<IHeadHeroCardM> = ({
  gradeType,
  itemCode,
  power,
  itemLevel,
  id,
  style,
  boxStyle,
  freePlayTime,
  ownerflag,
  openRole,
  showFree,
  onClick,
  isStationing,
  isPowerTop,
  isMobileTouch,
  height = 94,
  gameIndex,
  className,
  powerNote,
}) => {
  const { t } = useTranslation();
  const heroRole = useDescByCode(itemCode);

  const BusyBox = useMemo(() => {
    switch (ownerflag) {
      // case 1:
      //   return (
      //     <StyledBusy>
      //       <StyledIcon src={farming} />
      //       <span>{t("NFT Farming")}</span>
      //     </StyledBusy>
      //   );
      case 2:
        return (
          <StyledBusy>
            <StyledIcon src={lp_boost} />
            <span>{t("LPBattleground")}</span>
          </StyledBusy>
        );
      case 3:
        return (
          <StyledBusy>
            <StyledIcon src={gamingPng} />
            <span>{t("Tiny Kingdom")}</span>
          </StyledBusy>
        );
      case 1:
        if (gameIndex) {
          return (
            <StyledBusy>
              <StyledIcon src={lp_lord} />
              <span>{t("In The Stationed")}</span>
            </StyledBusy>
          );
        }
        return;
      default:
        return;
    }
  }, [ownerflag, t]);

  const free = useMemo(() => {
    return dayjs(freePlayTime).diff(dayjs(new Date()), "hour") <= -24;
  }, [freePlayTime]);

  return (
    <StyledHeroCard
      className={className}
      height={height}
      style={boxStyle}
      onClick={(e) => {
        if (onClick && !gameIndex && ownerflag === 1) {
          e.stopPropagation();
          onClick({
            id,
            gradeType,
            itemCode,
            power,
            itemLevel,
            freePlayTime,
            ownerflag,
          });
        }
      }}
    >
      {openRole ? (
        <StyledHeroRoleBox boxHeight={height * heroImgHeight} style={style}>
          <img
            style={{ width: "100%" }}
            src={getRoleBg[heroRole?.type ? heroRole.type - 1 : 0]}
          />
          <StyleHeroRoleContainer>
            <StyledHeroLevel
              className="text-shadow"
              style={{
                position: "relative",
                top: "-10%",
                left: 0,
                fontSize: 16,
              }}
            >
              <span
                style={{
                  color: "#F9B535",
                  fontSize: 12,
                }}
              >
                LV.
              </span>
              {itemLevel}
            </StyledHeroLevel>
            <StyledHeroRole
              style={{
                top: "30%",
                right: "50%",
                transform: "translate(50%, 50%)",
                height: "50%",
              }}
            >
              <img
                style={{
                  height: "100%",
                }}
                src={roleImg[heroRole?.type ? heroRole.type - 1 : 0]}
                alt=""
              />
            </StyledHeroRole>
          </StyleHeroRoleContainer>
        </StyledHeroRoleBox>
      ) : (
        <>
          {(power || powerNote) && isPowerTop && (
            <StyledPowerBox className="text-shadow mt-2">
              <StyledIcon className="mr-5" src={powerL} />
              <span className="mt-2">{powerNote ? powerNote : power}</span>
            </StyledPowerBox>
          )}

          <StyledHeroHead style={style}>
            {isStationing ? (
              <StyledBusy>
                <StyledIcon src={iconFight} />
                <span>{t("fighting")}</span>
              </StyledBusy>
            ) : (
              showFree && BusyBox
            )}
            {free && showFree && (
              <StyledFree>
                <StyledIcon
                  style={{
                    height: "100%",
                    display: "block",
                  }}
                  src={freeIcon}
                />
              </StyledFree>
            )}
            <StyledHeroHeadImg
              prefillColor={heroPrefillColor[5 - gradeType]}
              imgHeight={Math.floor(height * heroImgHeight)}
              preSrc={getHeroHeadImgPre()}
              src={getHeroHeadImg(itemCode)}
              grade={gradeType}
              alt=""
              width={99}
              height={99}
            />
            {itemCode > 100 && (
              <>
                <StyledHeroLevel>LV. {itemLevel}</StyledHeroLevel>
                <StyledHeroRole>
                  <img
                    style={{
                      height: "100%",
                    }}
                    src={roleImg[heroRole?.type ? heroRole.type - 1 : 0]}
                    alt=""
                  />
                </StyledHeroRole>
                <StyledHeroGrade>
                  <img
                    style={{
                      height: "100%",
                    }}
                    src={gradeImg[gradeType - 1]}
                    alt=""
                  />
                </StyledHeroGrade>
              </>
            )}
          </StyledHeroHead>
        </>
      )}
      {(power || powerNote) && !isPowerTop && (
        <StyledPowerBox className="text-shadow mt-2">
          {isMobileTouch ? (
            <StyledPowerText>
              <StyledIcon className={"mr-2"} src={powerL} />
              <span>{power}</span>
            </StyledPowerText>
          ) : (
            <>
              <StyledIcon className="mr-5" src={powerL} />
              <span className="mt-2">{powerNote ? powerNote : power}</span>
            </>
          )}
        </StyledPowerBox>
      )}
    </StyledHeroCard>
  );
};

export default HeadHeroCardM;

const StyledPowerText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  background: rgb(0 0 0 / 30%);
  border-radius: 6px;

  padding: 2px 6px;
  margin-top: 4px;

  > span {
  }
`;

const StyleHeroRoleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeroRoleBox = styled.div<{ boxHeight: number }>`
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ boxHeight }) => boxHeight}px;
  height: ${({ boxHeight }) => boxHeight}px;
  position: relative;
`;

const StyledBusy = styled.div`
  position: absolute;
  width: 125%;
  height: 119%;
  background: rgb(0 0 0 / 60%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  transform: scale(0.7);
  z-index: 9;
  border-radius: 4px;

  img {
    height: 36%;
  }

  span {
    font-size: 13px;
    width: 100%;
    text-align: center;
  }
`;

const StyledFree = styled.div`
  position: absolute;
  left: -5%;
  top: -6%;
  height: 12px;
`;

const StyledAddBox = styled.div`
  background: #86bedd;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0%;
  border-radius: 3px;
  cursor: pointer;
  // left:-0.2%;
  img {
    width: 149%;
    filter: drop-shadow(-700px 0px rgb(255 255 255 / 30%));
    transform: translateX(700px);
    position: absolute;
  }
`;

const StyledAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 102%;
  width: 100%;
  background: linear-gradient(to top, #3695c7 0%, #0e366d 60%, #00154e 100%);
  position: relative;
  top: 1%;
  border-radius: 3px;
  padding-bottom: 9%;
  box-sizing: border-box;
  font-size: 2.4rem;
  text-shadow: 0px 3px 1px #182f42;
  overflow: hidden;
`;

const StyledHeroGrade = styled.div`
  position: absolute;
  bottom: 8%;
  left: 8%;
  height: 20%;

  img {
    display: block;
  }
`;

const StyledHeroRole = styled.div`
  position: absolute;
  top: 7%;
  right: 7%;
  height: 30%;
`;

const StyledHeroLevel = styled.div`
  position: absolute;
  top: 3%;
  left: 5%;
  font-size: 12px;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  font-family: AvenirNextLTPro;
  transform: scale(0.8);
`;

const StyledHeroCard = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: ${({ height }) => height * heroImgHeight}px;
  height: ${({ height }) => height}px;
  color: #ffffff;
`;

const StyledIcon = styled.img`
  height: 66%;
  border-radius: 15px;
`;

const StyledPowerBox = styled.div`
  width: 100%;
  height: 26%;
  font-size: 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  font-family: AvenirNextLTPro;
`;

/* 
  border-image-slice: ${(props) => {
    switch (props.grade) {
      case 4:
        return 22;
      default:
        return 8;
    }
  }};
*/
const StyledHeroHeadImg = styled(PrefilledImage)<{
  grade: gradeType;
  imgHeight: number;
  prefillColor: string;
}>`
  width: ${({ imgHeight }) => imgHeight}px;
  height: ${({ imgHeight }) => imgHeight}px;
  display: block;
  border: solid;
  border:${({ imgHeight }) => imgHeight * 0.08}px solid;
  border-image: ${({ grade }) =>
    grade < 4
      ? `url(${avatarBorders[0]})`
      : `url(${avatarBorders[(grade + 1) % 4]})`}
    8 8 / ${({ imgHeight }) => imgHeight * 0.08}px
    ${({ imgHeight }) => imgHeight * 0.08}px;

  box-sizing: border-box;
  filter: ${({ prefillColor }) => `drop-shadow(0px 0px ${prefillColor})`};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;


  > img {
    display: block;

    // border-image-slice: 22;
    width: ${({ imgHeight }) => imgHeight * 0.855 + 1}px;
    height: ${({ imgHeight }) => imgHeight * 0.85}px;
    box-sizing: border-box;

`;

const StyledHeroHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1.4%;
  width: 100%;
  // height: 3.6rem;
  position: relative;
`;

const StyledCheckHeroHead = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1.4%;
  position: relative;
  width: ${({ height }) => height}px;
  height: ${({ height }) => height}px;

  border: ${({ height }) => height * 0.08}px solid;
  border-image-source: url(${commonplaceBorder});
  box-sizing: border-box;
  border-image-slice: 8;
`;
