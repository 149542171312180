import { useEffect, useState } from "react";
import keyToRequestMap, {
  getCacheByVersionKey,
  dataCache,
  CacheKey,
  JsonRes,
} from "src/services/keyToRequestMap";

/**
 * 适用于只需要请求一次的数据，比如 json 格式的配置
 * @param {CacheKey} key
 * @returns
 */
function useCacheByKey<T = Awaited<JsonRes>>(key: CacheKey): T {
  const { version, request } = keyToRequestMap[key];
  const [data, setData] = useState<T>(() => {
    const cache = dataCache[key];
    if (cache && cache.version === version) {
      return cache.data;
    }
  });

  useEffect(() => {
    if (data !== undefined) return;
    let end = false;
    getCacheByVersionKey(() => request(version) as any, key, version)
      .then((res) => {
        if (!end) {
          setData(res);
        }
      })
      .catch((err) =>
        console.error("Fail to fetch " + key.toLocaleLowerCase(), err)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      end = true;
    };
  }, []);
  return data;
}

export default useCacheByKey;
