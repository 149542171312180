import React from "react";
import Quality_1 from "../../images/quality/n.png";
import Quality_2 from "../../images/quality/r.png";
import Quality_3 from "../../images/quality/sr.png";
import Quality_4 from "../../images/quality/ssr.png";
import Quality_5 from "../../images/quality/sssr.png";
import Quality_6 from "../../images/quality/ur.png";

import { heroGradeTitle, gradeType } from "src/config";

const img: {
  [grade in gradeType]: string;
} = {
  1: Quality_1,
  2: Quality_2,
  3: Quality_3,
  4: Quality_4,
  5: Quality_5,
  6: Quality_6,
};

interface Props {
  grade: gradeType;
  height?: number | string;
  className?: string;
}

export default function GradeIcon({ className, grade, height = 31 }: Props) {
  const src = img[grade];
  return (
    <img
      className={className}
      src={src}
      height={height}
      alt={heroGradeTitle[grade]}
    />
  );
}
