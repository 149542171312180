import { getDisplayBalance } from "src/utils/formatBalance";
import { BigNumber, utils } from "ethers";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { chainId } from "src/config";
import { poolMap, supportedPools } from "src/tiny/farms";
import { bn0 } from "src/utils";
import useApprove, { ApprovalState } from "./useApprove";
import useDaoVeData from "./useDaoVeData";
import useFarmTINCPerDay from "./useFarmTINCPerDay";
import useHandleTransactionReceipt from "./useHandleTransactionReceipt";
import useHandleTxConfirm from "./useHandleTxConfirm";
import useStakedValueByPid from "./useStakedValueByPid";
import useTiny from "./useTiny";
import useUserWallet from "./useUserWallet";
import useTokenBalance from "./useTokenBalance";
import useLPFarmUserInfo from "./useLPFarmUserInfo";
import useFarmPrice from "./useFarmPrice";
import { getMulticallContract } from "src/tiny/utils";

export const tokenSymbol = supportedPools[chainId === 97 ? 1 : 0].tokenSymbol;

export const showTokenSymbol = "TINC-BNB LP";

export const decimals = 18;
export const fractionDigits = 4;
export const inputMin = undefined;
export const liquidityUrl =
  "https://pancakeswap.finance/v2/add/BNB/0x05aD6E30A855BE07AfA57e08a4f30d00810a402e";

export const pid = supportedPools[chainId === 97 ? 1 : 0].pid;

export const symbolImg1 = poolMap[56][0].icon[0];
export const symbolImg2 = poolMap[56][0].icon[1];

export const poolType = 1;

interface Props {
  amount: BigNumber | undefined;
}

export const useFarmingApprove = ({ amount }: Props) => {
  const tiny = useTiny();

  const tokenContract = tiny.externalTokens[tokenSymbol];
  const [approveStatus, approve, currentAllowance] = useApprove(
    tokenContract,
    tiny.contracts.TinyLPBoostMint.address,
    amount
  );

  return {
    approveStatus,
    approve,
    currentAllowance,
    ApprovalState,
    needApprove:
      approveStatus === ApprovalState.PENDING ||
      ApprovalState.NOT_APPROVED === approveStatus,
  };
};

export const useFarmingPoolValues = ({ user }: { user?: string }) => {
  const tiny = useTiny();

  const multicallContract = getMulticallContract(tiny);

  const [userInfo, refreshUserInfo] = useLPFarmUserInfo();
  const lpBalance = userInfo.amount;

  // const account = "0x045f427a57b10FDC0E6B923951502000A4814fB5";
  const account = user ? user : tiny.myAccount;
  const tinyLPBoostMintContract = tiny.contracts.TinyLPBoostMint;
  const { t } = useTranslation();
  const { refetch: refetchWallet } = useUserWallet();

  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();
  const { veValue } = useDaoVeData();

  const farmTINCPerDay = useFarmTINCPerDay();
  const [price] = useFarmPrice();
  const tincPrice = price.TINC;
  const LPPrice = price[tokenSymbol];

  const { data: default_working_supply, refetch: working_supply_refetch } =
    useQuery<BigNumber>(
      ["working_supply"],
      async () => {
        let working_supply = await tinyLPBoostMintContract.working_supply(); //工作中的总量
        // let shareTotal = await tinyLPBoostMintContract.shareTotal(); //Total Staked

        return working_supply;
      },
      {
        refetchInterval: chainId === 56 ? 30_000 : 10_000,
      }
    );

  const { data, refetch } = useQuery<
    | undefined
    | {
        userLpAmount: BigNumber;
        shareTotal: BigNumber;
        working_balances: BigNumber;
        shareOf: BigNumber;
        working_supply: BigNumber;
        claimableReward: BigNumber;
        user_depositTime: BigNumber;
      }
  >(
    ["FARMING_LP_V2" + account],
    async () => {
      const lpBoostMintAddr = tinyLPBoostMintContract.address.toLowerCase();
      const calldata = [
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData("userLpAmount", [
            account,
          ]),
        ],
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData(
            "shareTotal",
            []
          ),
        ],
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData(
            "working_balances",
            [account]
          ),
        ],
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData("shareOf", [
            account,
          ]),
        ],
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData(
            "working_supply",
            []
          ),
        ],
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData(
            "claimableReward",
            [account]
          ),
        ],
        [
          lpBoostMintAddr, // 要查询的合约的地址
          tinyLPBoostMintContract.interface.encodeFunctionData(
            "user_depositTime",
            [account]
          ),
        ],
      ];
      // console.log('calldata', calldata);

      const { returnData }: { returnData: string[] } =
        await multicallContract.aggregate(calldata);
      const [
        userLpAmount,
        shareTotal,
        working_balances,
        shareOf,
        working_supply,
        claimableReward,
        user_depositTime,
      ] = returnData.map((ele) => BigNumber.from(ele));

      return {
        userLpAmount,
        shareTotal,
        working_balances,
        shareOf,
        working_supply,
        claimableReward,
        user_depositTime,
      };

      // if (account) {
      //   let userLpAmount = await tinyLPBoostMintContract.userLpAmount(account); //Your Staked
      //   let shareTotal = await tinyLPBoostMintContract.shareTotal(); //Total Staked
      //   let working_balances = await tinyLPBoostMintContract.working_balances(
      //     account
      //   ); //工作余额  （LP内占比）
      //   let shareOf = await tinyLPBoostMintContract.shareOf(account); //初始LP占比
      //   let working_supply = await tinyLPBoostMintContract.working_supply(); //工作中的总量

      //   let claimableReward = await tinyLPBoostMintContract.claimableReward(
      //     account
      //   ); //可以领取的Tinc

      //   let user_depositTime = await tinyLPBoostMintContract.user_depositTime(
      //     account
      //   ); //获取质押时间

      //   // shareOf, working_balances

      //   return {
      //     userLpAmount,
      //     shareTotal,
      //     working_balances,
      //     shareOf,
      //     working_supply,
      //     claimableReward,
      //     user_depositTime,
      //   };
      // }
    },
    {
      refetchInterval: 30_000,
      enabled: typeof account === "string",
    }
  );

  /* 
  基础的 = shareOf mul 40 div 120
  Your Boost Factor   =  working_balances div  (  shareOf mul 40 div 120 )
  */

  const yourBoostFactor = useMemo(() => {
    if (data) {
      try {
        const { shareOf, working_balances } = data;

        return !working_balances.isZero() && !shareOf.isZero()
          ? working_balances
              .mul(10000)
              .div(shareOf.mul(40 * 100).div(120))
              .toNumber() / 100
          : // ?
            undefined;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
    return undefined;
  }, [data]);

  const depositLPv2 = useCallback(
    ({
      amount,
    }: {
      amount: BigNumber; // amount 传输入的流动性
    }) => {
      if (account) {
        const msg = `${t("Deposit")} ${getDisplayBalance(
          amount,
          18,
          2
        )} ${showTokenSymbol}`;
        return handleTransactionReceipt(
          tiny.depositLPv2(amount),
          msg,
          [amount].toString()
        )
          .then((tx) => {
            if (tx) {
              handleTxConfirm(tx.hash).then(() => {
                refetch();
                refetchWallet();
              });
            } else {
            }
          })
          .catch((err) => {
            console.error("handleBuy err:", err);
          });
      }
    },
    [
      account,
      handleTransactionReceipt,
      handleTxConfirm,
      refetch,
      refetchWallet,
      t,
      tiny,
    ]
  );

  const withdrawLPv2 = useCallback(
    ({
      amount,
    }: {
      amount: BigNumber; // amount 传输入的流动性
    }) => {
      if (account) {
        const msg = `${t("Withdraw")} ${getDisplayBalance(
          amount,
          18,
          2
        )} ${showTokenSymbol}`;
        return handleTransactionReceipt(
          tiny.withdrawLPv2(amount),
          msg,
          [amount].toString()
        )
          .then((tx) => {
            if (tx) {
              handleTxConfirm(tx.hash).then(() => {
                refetch();
                refetchWallet();
              });
            } else {
            }
          })
          .catch((err) => {
            console.error("handleBuy err:", err);
          });
      }
    },
    [
      account,
      handleTransactionReceipt,
      handleTxConfirm,
      refetch,
      refetchWallet,
      t,
      tiny,
    ]
  );

  const claimReward = useCallback(() => {
    if (data && !data.claimableReward.isZero()) {
      const amount = BigNumber.from(0);
      return handleTransactionReceipt(
        tiny.depositLPv2(amount),
        t("Claim Rewards"),
        amount.toString()
      ).then((tx) => {
        if (tx) {
          return handleTxConfirm(tx.hash).then(() => {
            refetch();
            refetchWallet();
          });
        }
      });
    }
  }, [
    data,
    handleTransactionReceipt,
    tiny,
    t,
    handleTxConfirm,
    refetch,
    refetchWallet,
  ]);

  const migrateV1ToV2 = useCallback(() => {
    if (account) {
      return handleTransactionReceipt(
        tiny.farmMigrateByAccount(account),
        t("one-key migration"),
        account.toString()
      ).then((tx) => {
        if (tx) {
          return handleTxConfirm(tx.hash).then(() => {
            refetch();
            refetchWallet();
          });
        }
      });
    }
  }, [
    account,
    handleTransactionReceipt,
    handleTxConfirm,
    refetch,
    refetchWallet,
    t,
    tiny,
  ]);

  const aprs = useMemo(() => {
    if (data) {
      try {
        const { working_balances, working_supply, userLpAmount } = data;
        const working_balance = Number(
          getDisplayBalance(working_balances, 18, 10)
        );
        const working_supply_number = Number(
          getDisplayBalance(working_supply, 18, 10)
        );
        const userLpAmount_number = Number(
          getDisplayBalance(userLpAmount, 18, 10)
        );
        const calcUserLp = userLpAmount_number > 0 ? userLpAmount_number : 1;

        const yourApr =
          (((working_balance / working_supply_number) *
            (farmTINCPerDay * 365 * tincPrice)) /
            (LPPrice * userLpAmount_number)) *
          100;

        //  + LPPrice * userLpAmount_number

        //  APR = ((占比 / 总占比) * 年总奖励 * 货币价值) / 用户的LP价值

        const yearTINCPer = farmTINCPerDay * 365 * tincPrice;

        const minApr =
          calcUserLp === 1
            ? ((calcUserLp * 40) /
                120 /
                working_supply_number /
                (calcUserLp * LPPrice)) *
              farmTINCPerDay *
              365 *
              tincPrice *
              100
            : (((calcUserLp /
                3 /
                (working_supply_number -
                  working_balance +
                  (calcUserLp * 40) / 120)) *
                yearTINCPer) /
                (LPPrice * calcUserLp)) *
              100;

        const maxApr =
          calcUserLp === 1
            ? (calcUserLp / working_supply_number / (calcUserLp * LPPrice)) *
              farmTINCPerDay *
              365 *
              tincPrice *
              100
            : (((calcUserLp /
                (working_supply_number - working_balance + calcUserLp)) *
                yearTINCPer) /
                (LPPrice * calcUserLp)) *
              100;

        return {
          yourApr: Number.isNaN(yourApr) ? 0 : yourApr,
          minApr: minApr,
          maxApr: maxApr,
        };
      } catch (error) {
        console.log(error);
        return {
          yourApr: 0,
          minApr: default_working_supply
            ? ((1 * 40) /
                120 /
                Number(getDisplayBalance(default_working_supply, 18, 10)) /
                (1 * LPPrice)) *
              farmTINCPerDay *
              365 *
              tincPrice *
              100
            : 0,
          maxApr: default_working_supply
            ? (1 /
                Number(getDisplayBalance(default_working_supply, 18, 10)) /
                (1 * LPPrice)) *
              farmTINCPerDay *
              365 *
              tincPrice *
              100
            : 0,
        };
      }

      /* 
Your APR  = (working_balances  /  working_supply     *   (TINC Daily Emission  *  365 * TINCPrice)   + LPPrice)   /  LPPrice

minAPR = (1 * 40 / 120) / working_supply / (1 * LPPrice) * 25312.5 * 365 * TINCPrice * 100
maxAPR = 1 / working_supply / (1 * LPPrice) * 25312.5 * 365 * TINCPrice * 100

minAPR = (lpAmount * 40 / 120) / totalShare / (lpAmount * price) * 25312.5 * 365 * TINCPrice * 100
maxAPR = lpAmount / totalShare / (lpAmount * price) * 25312.5 * 365 * TINCPrice * 100

      */

      // 初始LP占比 shareOfNumber
      // 工作中的总量 working_supply_number
      // working_balance工作余额  （LP内占比）
      // farmTINCPerDay * 365  一年的总奖励
    }

    return {
      yourApr: 0,
      minApr: default_working_supply
        ? ((1 * 40) /
            120 /
            Number(getDisplayBalance(default_working_supply, 18, 10)) /
            (1 * LPPrice)) *
          farmTINCPerDay *
          365 *
          tincPrice *
          100
        : 0,
      maxApr: default_working_supply
        ? (1 /
            Number(getDisplayBalance(default_working_supply, 18, 10)) /
            (1 * LPPrice)) *
          farmTINCPerDay *
          365 *
          tincPrice *
          100
        : 0,
    };
  }, [LPPrice, data, default_working_supply, farmTINCPerDay, tincPrice]);

  return {
    data,
    refetch,
    yourBoostFactor,
    lpBalance,
    handleTransactionReceipt,
    handleTxConfirm,
    depositLPv2,
    withdrawLPv2,

    veValue,
    farmTINCPerDay,

    claimReward, // 领取LP中奖励的TINC
    migrateV1ToV2, // 一键迁移V1 到 V2
    aprs,
    LPPrice,
  };
};

function useFarmingV2() {
  const {
    lpBalance,
    data,
    refetch,
    yourBoostFactor,
    handleTransactionReceipt,
    handleTxConfirm,
    depositLPv2,
    withdrawLPv2,

    veValue,
    farmTINCPerDay,

    claimReward,

    migrateV1ToV2,
    aprs,
    LPPrice,
  } = useFarmingPoolValues({});

  return {
    handleTransactionReceipt,
    handleTxConfirm,

    lpBalance,

    data,
    refetch,
    yourBoostFactor,

    depositLPv2,
    withdrawLPv2,
    veValue,
    farmTINCPerDay,

    claimReward,

    migrateV1ToV2,

    aprs,
    LPPrice,
  };
}

export default useFarmingV2;
