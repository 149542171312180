// import Pancake from "src/images/bourse/PancakeWap.png";
// import BabySwap from "src/images/bourse/BabySwap.png";
// import Hoo from "src/images/bourse/Hoo.png";

export interface IBourse {
  src: string;
  href: string;
  target: "_blank";
  text: string;
}

export const BourseTop: IBourse[] = [
  {
    src: "//images.tinyworlds.io/tokens/CAKE.svg",
    href: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
    target: "_blank",
    text: "PancakeSwap",
  },
  // {
  //   src: BabySwap,
  //   href: "https://exchange.babyswap.io/#/swap?inputCurrency=BNB&outputCurrency=0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
  //   target: "_blank",
  //   text: "BabySwap",
  // },
];
